export function lbToKg(weightInLbs: number) {
  return weightInLbs / 2.205;
}

export function kgToLb(weightInKgs: number) {
  return weightInKgs * 2.205;
}

export function convertWeight(weight: number, currentUnit: string, unit: string) {
  if(currentUnit === unit) {
    return weight;
  } else if (currentUnit === 'kg' && unit === 'lb') {
    return kgToLb(weight);
  } else {
    return lbToKg(weight);
  }
}
