import { Component, HostBinding, Input } from '@angular/core';
import { ClarityConfig } from '../../config/clarity.config';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { NavController } from '@ionic/angular';
import { Validators, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'cl-footer',
  styleUrls: ['footer.component.scss'],
  templateUrl: 'footer.component.html'
})
export class FooterComponent {

  @Input() waves = false;

  public validations = {
    email: {
      validators: Validators.compose([Validators.required, Validators.email]), errors: {
        required: 'errors.user.email_required', email: 'errors.user.email_invalid'
      }
    }
  };
  public newsletterForm: FormGroup;
  private _currentYear;

  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  @HostBinding('class.waves') get bgWaves() {
    return this.waves;
  }

  constructor(
    private navCtrl: NavController,
    private store: Store<State>,
    public config: ClarityConfig
  ) {
    this.newsletterForm = new FormGroup({
      email: new FormControl('', this.validations.email)
    });
    this._currentYear = new Date().getFullYear();
  }

  navigateTo(tab: string): void {
    if (tab === 'tabs/dashboard' && this.config.program.programCode === 'ctq') {
      tab = 'tabs/home';
    }
    this.navCtrl.navigateForward(tab);
  }

  get currentYear() {
    return this._currentYear || 2022;
  }
}
