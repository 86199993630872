import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';

import { SmokeCravingTool } from '../../store/normalized/schemas/craving-tool.schema';
import { Store } from '@ngrx/store';
import { OnboardingService } from '../../services/onboarding.service';
import { getCurrentModule } from '../../store/session/selectors/program.selectors';
import { withLatestFrom , take } from 'rxjs/operators';
import { getAcceleratedTo } from '../../store/normalized/selectors/user-bootstrap.selectors';
import { SaveSmokingCravingTool } from '../../store/session/actions/tools.actions';
import { SessionState } from '../../store/session/session.reducers';
import { DatePipe } from '@angular/common';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { getCurrentUserProgram } from 'src/app/store/normalized/selectors/user.selectors';
import { Subscription } from 'rxjs';
import { SmokingType } from '../../store/normalized/schemas/user.schema';

@Component({
  selector: 'page-craving-tool-ctq',
  styleUrls: ['craving-tool-ctq.scss'],
  templateUrl: 'craving-tool-ctq.html'
})
export class SmokingCravingToolPage implements OnInit, OnDestroy {
  modal;
  private userProgram$ = this.store.select(getCurrentUserProgram);
  private userProgramSubscription: Subscription;

  constructor(
    private store: Store<SessionState>,
    private onboardingService: OnboardingService,
    private ref: ChangeDetectorRef
  ) {
    this.userProgramSubscription = this.userProgram$.pipe(take(1))
      .subscribe((userProgram) => {
        const onboardingType = userProgram.smoking_type === SmokingType.CIGARETTE ? 'smokeCigCravingTool' :
          'smokePuffCravingTool';
        this.onboardingService.checkShowingOnboarding({type: onboardingType});
      }
      );
  }

  get initialCravingTool(): SmokeCravingTool {
    const datePipe = new DatePipe('en-US');

    return {
      date: datePipe.transform(new Date(), 'yyyy-MM-dd'),
      recording_initial_at: new Date().toISOString(),
      satisfaction_before_smoking: 0,
      satisfaction_after_smoking: 0,
      smoke_exercise: false,
      rain_exercise: false,
      completed: false,
      skipped: false
    };
  }

  currentCravingTool: SmokeCravingTool = { ...this.initialCravingTool };

  simulation = true;
  afterSimulation = false;
  mindfulSmoking = false;
  rainExercise = false;
  afterSmokingSatisfaction = false;
  skippedSimulation = false;

  ngOnInit(): void {
    this.resetCravingTool();
    this.ref.detectChanges();
    this.userProgram$.subscribe((userProgram) => {
      this.currentCravingTool.smokingType = userProgram.smoking_type;
    });
  }

  resetCravingTool() {
    this.simulation = true;
    this.afterSimulation = false;
    this.mindfulSmoking = false;
    this.afterSmokingSatisfaction = false;
  }

  onClose() {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }

  toAfterSimulation(skipped = false) {
    this.simulation = false;
    this.afterSimulation = true;
    this.skippedSimulation = skipped;
    if (skipped) {
      this.currentCravingTool.skipped = true;
    }
  }

  toMindfulSmokingExercise() {
    this.mindfulSmoking = true;
    this.afterSimulation = false;
  }

  toRainExercise() {
    this.store.select(getCurrentModule)
      .pipe(withLatestFrom(this.store.select(getAcceleratedTo)))
      .pipe(take(1))
      .subscribe(([currentModule, acceleratedTo]) => {
        if (currentModule.number >= 4 || acceleratedTo >= 4) {
          this.afterSimulation = false;
          this.rainExercise = true;
        } else {
          this.store.dispatch(
            new navigationActions.ShowInterstitial({
              page: 'InterstitialPage',
              params: {
                type: 'locked',
                title: 'interstitials.rain_locked_title',
                notes: 'interstitials.rain_locked_module_4_ctq'
              }
            })
          );
        }
      });
  }

  toAfterSmokingSatisfactionStep() {
    this.mindfulSmoking = false;
    this.rainExercise = false;
    this.afterSmokingSatisfaction = true;
  }

  done() {
    this.store.dispatch(new SaveSmokingCravingTool(this.currentCravingTool));
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.userProgramSubscription && this.userProgramSubscription.unsubscribe();
  }
}
