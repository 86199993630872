import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-list-multiple-select',
  template: `
    <div class="select-button-item" *ngFor="let item of items">
      <cl-checkbox-list-item
        [label]="item.name"
        [checked]="selected.indexOf(item) > -1"
        [style]="checkboxStyle"
        (click)="onSelected(item)">
      </cl-checkbox-list-item>
    </div>
  `
})
export class ListMultipleSelectComponent {

  @Input() items: any[];
  @Input() selected = [];
  @Input() checkboxStyle: string;

  @Output() selectItem = new EventEmitter();

  onSelected(item) {
    const position = this.selected.indexOf(item);
    position > -1 ? this.selected.splice(position, 1) : this.selected.push(item);
    this.selectItem.emit(this.selected);
  }

}
