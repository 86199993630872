import { createReducer, on } from '@ngrx/store';
import * as WeightActivityActions from '../actions/weight-activities.actions';


export interface WeightActivitiesState {
  updatingWeight: boolean;
}

export const initialWeightActivitiesState: WeightActivitiesState = {
  updatingWeight: false
};

export function weightActivitiesReducer(state = initialWeightActivitiesState, action: WeightActivityActions.WeightActivitiesActions): WeightActivitiesState {
  switch (action.type) {
    case WeightActivityActions.REMOVE_USER_WEIGHT:
    case WeightActivityActions.ADD_USER_WEIGHT:{
      return {
        ...state,
        updatingWeight: true
      };
    }
    case WeightActivityActions.REMOVE_USER_WEIGHT_FAIL:
    case WeightActivityActions.REMOVE_USER_WEIGHT_SUCCESS:
    case WeightActivityActions.ADD_USER_WEIGHT_FAIL:
    case WeightActivityActions.ADD_USER_WEIGHT_SUCCESS: {
      return {
        ...state,
        updatingWeight: false
      };
    }
    default: {
      return state;
    }
  }

}
