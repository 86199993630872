// social selectors
import { createSelector } from '@ngrx/store';
import { createSchemaSelectors } from 'ngrx-normalizr';

import * as socialReducer from '../reducers/social.reducer';
import { getSocialState } from '../../../../app/store';
import { Post, PostFeed, postFeedSchema, postsSchema } from '../../normalized/schemas/social.schema';

export const getPostsFilter = createSelector(
  getSocialState,
  socialReducer.postsFilter
);

export const getPostsUserFilter = createSelector(
  getSocialState,
  socialReducer.postsUserFilter
);

export const getPostsPage = createSelector(
  getSocialState,
  socialReducer.postsPage
);

export const getTempPost = createSelector(
  getSocialState,
  socialReducer.tempPost
);

export const getPostFeedId = createSelector(
  getSocialState,
  socialReducer.postFeedId
);

export const getPostId = createSelector(
  getSocialState,
  socialReducer.postId
);

export const getPostsTimestamp = createSelector(
  getSocialState,
  socialReducer.postsTimestamp
);

export const getPostLoading = createSelector(
  getSocialState,
  socialReducer.getPostLoading
);

export const addPostSuccess = createSelector(
  getSocialState,
  socialReducer.addPostLoaded
);

export const addPostLoading = createSelector(
  getSocialState,
  socialReducer.addPostLoading
);

const postFeedSchemaSelector = createSchemaSelectors<PostFeed>(postFeedSchema);
const postsSchemaSelector = createSchemaSelectors<Post>(postsSchema);

export const getPosts = createSelector(
  postsSchemaSelector.getEntities,
  (posts) => {
    if (!posts) {
      return [];
    }

    return posts.sort((a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }
);

export const getPostById = createSelector(
  postsSchemaSelector.getNormalizedEntities,
  getPostId,
  postsSchemaSelector.entityProjector
);

export const getPostFeedById = createSelector(
  postFeedSchemaSelector.getNormalizedEntities,
  getPostFeedId,
  postFeedSchemaSelector.entityProjector
);

export const getPostsFromFeed = createSelector(
  getPostFeedById,
  (postFeed: PostFeed) => {
    if (!postFeed || !postFeed.list) {
      return [];
    }

    return postFeed.list.sort((a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );
  }
);

export const isAllPostsLoaded = createSelector(
  getPostFeedById,
  (postFeed: PostFeed) => postFeed && postFeed.list ? postFeed.list.length === postFeed.total_count : false
);

export const getLikePost = createSelector(
  getSocialState,
  socialReducer.likePostId
);

export const getLikeFailPost = createSelector(
  getSocialState,
  socialReducer.likeFailPost
);

export const getPostSuppressionId = createSelector(
  getSocialState,
  socialReducer.postSuppressionId
);

export const getPostCreation = createSelector(
  getSocialState,
  socialReducer.postCreation
);

export const getLikePostLoading = createSelector(
  getSocialState,
  socialReducer.likePostLoading
);

export const isFilterChanging = createSelector(
  getSocialState,
  socialReducer.filterChanging
);

export const getNewPostAvailable = createSelector(
  getSocialState,
  socialReducer.newPostAvailable
);

export const getNewLikeOnPost = createSelector(
  getSocialState,
  socialReducer.newLikeOnPost
);

export const getNewPost = createSelector(
  getSocialState,
  socialReducer.newPost
);

export const getQueuedPostsOnResume = createSelector(
  getSocialState,
  socialReducer.queuedPostsOnResume
);

export const getPostsReloaded = createSelector(
  getSocialState,
  socialReducer.getPostsReloaded
);
