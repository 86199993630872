<ion-row class="step step1 lateral-padding" *ngIf="currentStep === 1">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{'wizards.how_much_are_you_craving' | translate}}</h2>

    <!--<h4 class="step-instructions">{{'wizards.please_adjust_slider' | translate}}</h4>-->

    <cl-intensity-selector [sliderValue]="cravingIntensity"
                           (intensity)="onIntensityChange($event)"></cl-intensity-selector>
  </ion-col>
</ion-row>

<ion-row class="step step2 lateral-padding" *ngIf="currentStep === 2">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{'wizards.what_triggered_this_craving' | translate}}</h2>

    <cl-list-single-select
      [items]="triggers"
      [selected]="triggerChoice"
      (selectItem)="onTriggerSelected($event)">
    </cl-list-single-select>
  </ion-col>
</ion-row>

<ion-row class="step step3" *ngIf="currentStep === 3">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{header | translate}}</h2>

    <div class="exercises-list ion-text-left">
      <div class="exercises-container lateral-padding">
        <exercises-group
          [exercises]="[recommendedExercise$ | async]"
          [title]="'wizards.recommended' | translate"
          (playExercise)="playExercise($event)"
          *ngIf="!isStressExercise"
          [isWizard]="true">
        </exercises-group>

        <p class="exercises-block-title" *ngIf="isStressExercise">{{'wizards.recommended' | translate}}</p>

        <cl-stress-test-button
          *ngIf="isStressExercise"
          (click)="startStressTest()">
        </cl-stress-test-button>
      </div>

      <div class="exercises-container lateral-padding">
        <exercises-group
          [exercises]="otherExercises$ | async"
          (playExercise)="playExercise($event)"
          [title]="'wizards.other_exercises' | translate"
          [isWizard]="true">
        </exercises-group>

        <cl-stress-test-button
          *ngIf="!isStressExercise"
          (click)="startStressTest()">
        </cl-stress-test-button>
      </div>
    </div>
  </ion-col>
</ion-row>

<ion-row class="step lateral-padding" *ngIf="currentStep === 4">
  <ion-col class="ion-text-center">
    <cl-play-exercise
      *ngIf="exerciseToPlay"
      [exercise]="exerciseToPlay"
      [autoplay]="autoPlay"
      [isWizard]="true"
      [playerController]="playerController">
    </cl-play-exercise>
  </ion-col>
</ion-row>
