// user activity actions

import { Action } from '@ngrx/store';
import { QuitDate } from '../../normalized/schemas/user-activity.schema';


export const SET_QUIT_DATE = '[user activity] Quit date';
export const SET_QUIT_DATE_SUCCESS = '[user activity] Quit date Success';
export const SET_QUIT_DATE_FAIL = '[user activity] Quit date Fail';

export const TRACK_ACTIVITY = '[user activity] Custom';

export class SetQuitDate implements Action {
  readonly type = SET_QUIT_DATE;

  constructor(public payload: QuitDate) {
  }
}
export class SetQuitDateSuccess implements Action {
  readonly type = SET_QUIT_DATE_SUCCESS;
}

export class SetQuitDateFail implements Action {
  readonly type = SET_QUIT_DATE_FAIL;
}

export class TrackActivity implements Action {
  readonly type = TRACK_ACTIVITY;

  constructor(public payload: any) {

  }
}

export type UserActivityActions =
  | SetQuitDate
  | SetQuitDateSuccess
  | SetQuitDateFail
  | TrackActivity;
