import { schema } from 'normalizr';

export const getSelectedListItems = (listItems: ListItem[], selection: number[]) => {
  const selectionSet = new Set(selection);

  return listItems.map(listItem => ({
    ...listItem,
    checked: selectionSet.has(listItem.id)
  }));
};

export class ListItem {
  id: number;
  name: string;
  type: string;
  exercise_id?: number;
  position?: number;
}

export const listItemSchema = new schema.Entity<ListItem>('listItems');
