import { Injectable } from '@angular/core';

import { AlertController } from '@ionic/angular';

import { LoadingService } from './loading.service';
import { LoggerService } from './logger.service';
import { TranslateService } from '@ngx-translate/core';
import { from as fromPromise } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClarityConfig } from '../config/clarity.config';
import { Store } from '@ngrx/store';
import { State } from '../store';
import { PLAYER_ERROR_CODES } from '../components/players/player-errors.enum';
import { SharecareSSOAuthError, SharecareSSOService } from '@mindsciences/login-providers';

@Injectable({providedIn: 'root'})
export class AlertsService {
  private playerErrorDisplayed = false;

  constructor(
    public alertController: AlertController,
    private logger: LoggerService,
    private loading: LoadingService,
    private translate: TranslateService,
    private sharecareSSO: SharecareSSOService,
    private config: ClarityConfig,
    public store: Store<State>
  ) { }

  playerError(type: 'technical' | 'network' = 'technical', errorCode?: PLAYER_ERROR_CODES) {
    if(this.playerErrorDisplayed) {
      return;
    }

    this.playerErrorDisplayed = true;

    fromPromise(this.translate
      .get(
        ['errors.common.error', 'errors.common.player_error','errors.common.player_technical_error', 'common.dismiss', 'errors.common.player_error_code'],
        {code: errorCode}
      )
      .toPromise()
      .then(async translations => this.alertController.create({
        header: translations['errors.common.error'],
        subHeader: translations[type === 'technical' ? 'errors.common.player_technical_error': 'errors.common.player_error'],
        message: errorCode ? translations['errors.common.player_error_code'] : '',
        buttons: [translations['common.dismiss']]
      })
        .then(async (alert) => {
          alert.onWillDismiss().then(() => this.playerErrorDisplayed = false);
          await alert.present();
        })
      ));
  }

  managedBySharecare() {
    return fromPromise(this.translate.get(['sharecare_login.title', 'sharecare_login.message', 'common.continue', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['sharecare_login.title'],
          message: translations['sharecare_login.message'],
          buttons: [
            {
              text: translations['common.dismiss'],
              role: 'cancel'
            },
            {
              text: translations['common.continue'],
              handler: () => {
                this.sharecareSSO.init();
              }
            }
          ]
        });

        await alert.present();
      }));
  }

  localStorageNotification() {
    return fromPromise(this.translate.get(['common.storage', 'errors.storage.not_found', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['common.storage'],
          subHeader: translations['errors.storage.not_found'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  cookiesNotification() {
    return fromPromise(this.translate.get(['common.cookies', 'errors.cookies.blocked', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['common.cookies'],
          subHeader: translations['errors.cookies.blocked'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  offlineNotification() {
    return fromPromise(this.translate.get(['common.offline', 'errors.offline.no_internet_connection', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['common.offline'],
          subHeader: translations['errors.offline.no_internet_connection'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  genericError(error = null, logError = false, dismissLoading = true) {
    // TODO: For whatever reason this will throw a "Failed to fetch" error
    if (logError) {
      // this.logger.error('Generic error');
    }

    if (dismissLoading) {
      this.loading.hideLoadingOverlay();
    }

    return fromPromise(this.translate
      .get(['errors.common.unknown_error', 'errors.common.unknown_error_restart_current_week', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['errors.common.unknown_error'],
          subHeader: translations['errors.common.unknown_error_restart_current_week'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  connectionError(error, logError = false, dismissLoading = true) {
    if (logError) {
      this.logger.error(error);
    }

    if (dismissLoading) {
      this.loading.hideLoadingOverlay();
    }

    return fromPromise(this.translate
      .get(['errors.common.error', 'errors.common.just_network_error', 'common.dismiss'])
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['errors.common.error'],
          subHeader: translations['errors.common.just_network_error'],
          buttons: [translations['common.dismiss']]
        });

        await alert.present();
      }));
  }

  async sharecareError(error: SharecareSSOAuthError) {
    let errorMessage = '';
    let provider = '';

    switch (error) {
      case SharecareSSOAuthError.not_qualified_error:
        errorMessage = 'errors.auth.sharecare_not_qualified_error_message';
        break;
      case SharecareSSOAuthError.user_already_has_a_licence_subscription:
        errorMessage = 'errors.auth.sharecare_app_already_has_a_licence_subscription';
        break;
      case SharecareSSOAuthError.user_already_has_a_stripe_subscription:
        errorMessage = 'errors.auth.sharecare_app_already_has_a_paid_subscription';
        provider = 'a stripe';
        break;
      case SharecareSSOAuthError.user_already_has_a_itunes_subscription:
        errorMessage = 'errors.auth.sharecare_app_already_has_a_paid_subscription';
        provider = 'an Itunes';
        break;
      case SharecareSSOAuthError.user_already_has_a_googleplay_subscription:
        errorMessage = 'errors.auth.sharecare_app_already_has_a_paid_subscription';
        provider = 'a Google Play';
    }

    return fromPromise(this.translate
      .get(['errors.auth.sharecare_account', errorMessage, 'common.dismiss'], { program: this.config.program.name, subscription_provider: provider })
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alertController.create({
          header: translations['errors.auth.sharecare_account'],
          message: translations[errorMessage],
          buttons: [translations['common.dismiss']]
        });

        // for whatever reason calling this earlier, doesn't work
        alert.onWillDismiss()
          .then(() => {
            this.loading.hideLoadingOverlay();
          });

        await alert.present();
      }));
  }

  customError(error, title, text, logError = false, dismissLoading = true) {
    if (logError) {
      this.logger.error(error);
    }

    if (dismissLoading) {
      this.loading.hideLoadingOverlay();
    }

    return fromPromise(
      this.translate.get([title, text, 'common.dismiss'])
        .pipe(take(1))
        .toPromise()
        .then(async (translations) => {
          const alert = await this.alertController.create({
            header: translations[title],
            subHeader: translations[text],
            buttons: [translations['common.dismiss']]
          });

          await alert.present();
        }));
  }
}
