// authentication selectors

import { createSelector } from '@ngrx/store';

import * as authReducer from '../reducers/auth.reducer';
import { getAuthState } from '../..';

export const isAuthenticated = createSelector(
  getAuthState,
  authReducer.authenticated
);

export const isCheckingPassword = createSelector(
  getAuthState,
  authReducer.checkingPassword
);

export const isDeletingAccount = createSelector(
  getAuthState,
  authReducer.deletingAccount
);

export const isAuthenticating = createSelector(
  getAuthState,
  authReducer.authenticating
);

export const isResettingPassword = createSelector(
  getAuthState,
  authReducer.isResettingPassword
);

export const lastPasswordReset = createSelector(
  getAuthState,
  authReducer.lastPasswordReset
);

export const getAuthError = createSelector(
  getAuthState,
  authReducer.authError
);

export const getAuthToken = createSelector(
  getAuthState,
  authReducer.authToken
);

export const getOfficialVersion = createSelector(
  getAuthState,
  authReducer.officialVersion
);

export const getOfficialReleaseVersion = createSelector(
  getAuthState,
  authReducer.officialReleaseInfo
);

export const getIridiumToken = createSelector(
  getAuthState,
  authReducer.iridiumToken
);

export const getIridiumHost = createSelector(
  getAuthState,
  authReducer.iridiumHost
);
