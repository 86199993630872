interface InterstitialType {
  color: string;
  badge: string;
  title: string;
}

export const interstitialTypes: Record<string, InterstitialType> = {
  locked: {
    color: 'purple',
    badge: 'lock-nobg',
    title: 'interstitials.not_yet'
  },
  completedProgram: {
    color: 'green',
    badge: 'flag-greenbg',
    title: 'interstitials.strong_work'
  },
  // modules - when bonus exercises are unlocked
  greatJobSpecial: {
    color: 'navy',
    badge: 'cup-nobg',
    title: 'interstitials.great_job'
  },
  // craving tool - when reminding after 5/15 mins
  greatJob: {
    color: 'blue',
    badge: 'checked-nobg',
    title: 'interstitials.great_job'
  },
  // gets badge with avatar from effect
  firstWizard: {
    color: 'navy',
    badge: 'checked-nobg',
    title: 'interstitials.great_job'
  },
  // gets badge with avatar from effect
  firstBonusExercise: {
    color: 'blue',
    badge: 'checked-nobg',
    title: 'interstitials.well_done'
  },
  // checkins, morning motivation, night reflection
  checkedIn: {
    color: 'blue',
    badge: 'user-checkmark-nobg',
    title: 'interstitials.great_job'
  },
  // stress tests / stress meters / want-o-meters
  congratulations: {
    color: 'blue',
    badge: 'user-checkmark-nobg',
    title: 'interstitials.congratulations'
  },
  // modules 0, 1 and other normal modules (no bonuses, intros, etc.)
  goodJob: {
    color: 'blue',
    badge: 'user-checkmark-nobg',
    title: 'interstitials.good_job'
  },
  // nrt
  nrtThanks: {
    color: 'blue',
    badge: 'checked-nobg',
    title: 'interstitials.thanks'
  },
  nrtComplete: {
    color: 'navy',
    badge: 'user-checkmark-nobg',
    title: 'interstitials.thank_you'
  },
  lessonNotSupported: {
    color: 'purple',
    badge: 'lock-nobg',
    title: 'interstitials.app_outdated'
  }
};
