import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import * as navigationActions from '../../store/session/actions/navigation.actions';
import { ConnectivityService } from '../../services/connectivity.service';
import { LiveWeek } from '../../store/session/selectors/program.selectors';
import { State } from '../../store/state.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-theme-journey-week-card-web',
  styleUrls: ['theme-journey-week-card.web-component.scss'],
  template: `
    <div class="card-wrapper" [class.expanded]="expanded" (click)="onClick()" tappable>
      <div class="week-name">
        <ng-container *ngIf="moduleNumbers">
          <ion-icon
            *ngIf="_week.isCompleted"
            clTooltip
            [preventPropagation]="isExpanded"
            [tooltipText]="'tooltips.program_week.completed' | translate"
            src="/assets/icon/checkin_icon_orange.svg">
          </ion-icon>
          <ion-icon
            *ngIf="!_week.isCompleted"
            clTooltip
            [preventPropagation]="isExpanded"
            [tooltipText]="'tooltips.program_week.in_progress' | translate"
            src="/assets/icon/partially_completed_orange.svg">
          </ion-icon>
        </ng-container>

        {{ getWeekName() | translate}}
      </div>

      <div class="redo-button-holder" [class.hidden]="!expanded" *ngIf="_week.isCompleted">
        <cl-action-button
          label="{{'myjourney.redo_this_week' | translate}}"
          [canClick]="true"
          [small]="true"
          [noPadding]="true"
          (action)="onStartWeek($event, _week)">

        </cl-action-button>
      </div>

      <div class="modules-wrapper" [class.hidden]="!expanded" *ngIf="moduleNumbers">
        <cl-module-item-web
          *ngFor="let index of moduleNumbers"
          [isCustomPath]="isCustomPath"
          [module]="_week.modules ? _week.modules[index] : null"
          [theme]="true"
          [selectedModules]="selectedModules"
          [expanded]="isExpanded"
          (clicked)="onModuleClicked($event)">
        </cl-module-item-web>
      </div>

      <div class="modules-wrapper" [class.hidden]="!expanded" *ngIf="!moduleNumbers">
        <cl-action-button
          label="{{'myjourney.select_theme' | translate}}"
          [canClick]="true"
          [color]="'primary'"
          (click)="onStartWeek($event, _week)">
        </cl-action-button>
      </div>
    </div>
  `
})
export class ThemeJourneyWeekCardWebComponent {

  _week: LiveWeek;
  @Input()
  set week(value: LiveWeek) {
    this._week = {
      ...value,
      modules: value.modules?.filter((module) => module.type !== 'extend' && module.record?.title)
    };
  }

  @Input() expanded = null;
  @Input() isCustomPath = false;
  @Input() selectedModules = [];

  @Output() playModule = new EventEmitter();
  @Output() weekExpanded = new EventEmitter();
  @Output() weekCollpased = new EventEmitter();

  get isExpanded() {
    if (this.isCustomPath) {
      return true;
    }

    return this.expanded === null
      ? this._week.isCurrent && !this._week.isCompleted
      : this.expanded;
  }

  get showExpand() {
    return !this.isCustomPath;
  }

  get isLocked() {
    if (this.isCustomPath) {
      return false;
    }

    return !this._week.isCompleted && !this._week.isCurrent ? true : false;
  }

  get isCurrent() {
    if (this.isCustomPath) {
      return false;
    }

    return this._week.isCurrent && !this._week.isCompleted;
  }

  get isCompleted() {
    if (this.isCustomPath) {
      return true;
    }

    return this._week.isCompleted;
  }

  get moduleNumbers() {
    return this._week.modules && this._week.modules.length > 7
      ? this._week.modules.map((module, index) => index)
      : this._week.modules ? [0, 1, 2, 3, 4, 5, 6] : false;
  }

  constructor(private store: Store<State>, private connectivity: ConnectivityService) {}

  getWeekName() {
    return this._week.record && this._week.record.title;
  }

  onClick() {
    if (!this.moduleNumbers) {
      this.onStartWeek(null, this._week);
    } else {
      this.expanded = !this.expanded;
    }
  }

  onModuleClicked(module) {
    this.playModule.emit(module);
  }

  onStartWeek($event = null, week) {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    // keep opened block expanded
    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();
    }

    this.store.dispatch(new navigationActions.OpenModal('NewThemeWeekPage', {week}));
  }

}
