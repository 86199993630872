import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../store/state.reducer';
import { filter, map, switchMap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { isAuthenticated } from '../store/sensitive/selectors/auth.selectors';

import { ClarityConfig } from '../config/clarity.config';

@Injectable({providedIn: 'root'})
export class LoginGuard implements CanActivate {

  constructor(
    private nav: NavController,
    private store: Store<State>,
    private config: ClarityConfig
  ) {
  }

  // checks if the user is already logged in and redirects to home page -- used on authorize and provider login pages
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.select('hydrated')
      .pipe(
        filter((hydrated) => hydrated === true),
        switchMap(() => this.store.select(isAuthenticated)
          .pipe(map((isLoggedIn) => {
            // allow action (if user is not logged in)
            let response = true;

            // send user straight home if already logged in
            if (isLoggedIn) {
              console.log('||||| LoginGuard || IsLoggedIn - navigateRoot(dashboard)');
              if (this.config.program.programCode === 'ctq') {
                this.nav.navigateRoot('tabs/home');
              } else {
                this.nav.navigateRoot('tabs/dashboard');
              }

              response = false;
            }

            console.log('|||| LoginGuard || response -> ', response);

            return response;
          })))
      );
  }

}
