import { createSchemaSelectors } from 'ngrx-normalizr';
import { UserProfile, userProfileSchema } from '../schemas/user.schema';
import { createSelector } from '@ngrx/store';
import equal from 'fast-deep-equal';

// @todo rename selectors to getCommunityProfile instead of getUserProfile

const userSchemaSelector = createSchemaSelectors<UserProfile>(userProfileSchema);

let userProfilesCopy: UserProfile[] = [];

export const getUserProfiles = createSelector(
  userSchemaSelector.getEntities,
  (userProfiles) => {
    if (!equal(userProfiles, userProfilesCopy)) {
      userProfilesCopy = userProfiles;
    }

    return userProfiles;
  }
);

let currentUserProfileCopy: UserProfile;

export const getCurrentUserProfile = createSelector(
  userSchemaSelector.getNormalizedEntities,
  (entities) => {
    const userProfile = entities.userProfile && entities.userProfile.current;

    if (!equal(userProfile, currentUserProfileCopy)) {
      currentUserProfileCopy = userProfile;
    }

    return currentUserProfileCopy;
  }
);

export const getCurrentProfileId = createSelector(
  getCurrentUserProfile,
  (userProfile) => userProfile ? userProfile.id : null
);

export const getCurrentProfileAvatar = createSelector(
  getCurrentUserProfile,
  (userProfile) => userProfile ? userProfile.avatar : null
);

export const isCurrentProfileCoach = createSelector(
  getCurrentUserProfile,
  (userProfile) => {
    if (userProfile && userProfile.roles && userProfile.roles.indexOf('coach') > -1) {
      return true;
    }

    return false;
  }
);

export const hasAcceptedTerms = createSelector(
  getCurrentUserProfile,
  (userProfile: UserProfile) => userProfile ? userProfile.terms_agreed : null
);

export const isUserProfileComplete = createSelector(
  getCurrentUserProfile,
  (userProfile) => !!(userProfile && userProfile.avatar && userProfile.username)
);
