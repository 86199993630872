import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';

import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

import { take } from 'rxjs/operators';
import { ScriptLoaderService } from '../../services/script-loader.service';
import { ClarityConfig } from '../../config/clarity.config';

@Component({
  selector: 'cl-recaptcha',
  styleUrls: ['./recaptcha.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecaptchaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecaptchaComponent),
      multi: true
    }
  ],
  template: `
    <div [id]="captchaElementId" class="g-recaptcha"></div>
  `
})
export class RecaptchaComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public value: string;

  private readonly RECAPTCHA_SRC = 'https://www.google.com/recaptcha/enterprise.js?render=explicit';

  private captcha: any;
  private widgetId: number;
  private onChange: (string) => void;
  private onTouched: (string) => void;
  private onValidationChange: () => void;

  @Output() recaptchaSuccess = new EventEmitter<string>();
  @Output() recaptchaExpired = new EventEmitter<void>();
  @Output() recaptchaError = new EventEmitter<void>();

  @Input() captchaElementId = 'captchaElement'; // To handle multiple recaptcha

  constructor(
    private scriptLoaderService: ScriptLoaderService,
    private config: ClarityConfig
  ) {}

  ngOnInit() {
    this.initCaptcha();
  }

  validate({ value }: FormControl) {
    const isNotValid = !value && this.widgetId !== undefined; // Not valid if rendered and not validated by recaptcha

    return isNotValid && {
      invalid: true
    };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  ngOnDestroy() {
    this.widgetId = null;
  }

  render() {
    if(this.widgetId !== undefined) {
      return;
    }
    this.widgetId = this.captcha.enterprise.render(
      this.captchaElementId, // Element id in DOM
      {
        sitekey: this.config.env.recaptchaSiteKey,
        callback: this.onSuccessCallback.bind(this),
        'error-callback': this.onErrorCallback.bind(this),
        'expired-callback': this.onExpiredCallback.bind(this)
      }
    );

    // Needed for form validation
    this.onValidationChange();
  }

  private initCaptcha(): void {
    this.scriptLoaderService.load(
      {
        src: this.RECAPTCHA_SRC,
        async: true,
        defer: true
      }
    )
      .pipe(take(1))
      .toPromise()
      .then(() => {
        if (!window['grecaptcha']) {
          return false;
        }

        this.captcha = window['grecaptcha'];
      });
  }

  onSuccessCallback(token: string) {
    this.recaptchaSuccess.emit(token);
    // Needed for form validation
    this.onChange(token);
    this.onTouched(token);
  }

  onErrorCallback() {
    this.recaptchaError.emit();
  }

  onExpiredCallback() {
    this.recaptchaExpired.emit();
    // Needed for form validation
    this.onChange(null);
    this.onTouched(null);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }
}
