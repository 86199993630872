import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { UserProgram } from '../store/normalized/schemas/user.schema';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserProgramProvider {

  private userProgramEndpoint = '/user_program';

  constructor(
    private http: HttpProvider
  ) {

  }

  updateUserProgram(payload) {
    return this.http.patch(this.userProgramEndpoint, payload);
  }

  getUserProgram(): Observable<UserProgram> {
    return this.http.get<UserProgram>(this.userProgramEndpoint);
  }

}
