<cl-waves-background class="interstitial-waves-background interstitial-purple">

    <ion-content>

      <ion-header>
        <cl-header-nav-buttons
          class="page-header"
          color="blank"
          (click)="onCloseModalClick()"
          [backButton]="false">
        </cl-header-nav-buttons>
      </ion-header>

      <ion-grid class="page-wrapper">
        <ion-row class="icon-holder">
          <ion-col class="ion-text-center ion-no-padding">
            <img [src]="'assets/imgs/badge/lock-nobg.png'">
          </ion-col>
        </ion-row>

        <div class="content">
          <ion-grid fixed>
            <ion-row
              class="title-holder lateral-padding ion-padding"
              *ngIf="navParams.get('title') || interstitialSettings.title"
              [ngClass]="{'large-text': navParams.get('largeText'), 'no-vertical-padding': navParams.get('bonusExercisesLink')}"
            >
              <ion-col class="ion-no-padding">
                <h1>{{navParams.get('title') || interstitialSettings.title | translate}}</h1>
                <h2>{{ navParams.get('subTitle') | translate }}</h2>
              </ion-col>
            </ion-row>

            <ion-row class="instructions-holder lateral-padding ion-padding-horizontal">
              <ion-col>
                <p>{{navParams.get('notes') | translate}}</p>
                <ion-row class="appstore-buttons-wrapper">
                  <a *ngIf="config.isIos" href="{{config.program.appStoreUrl}}" target="_blank">
                    <img src="assets/imgs/stores/appstore.png">
                  </a>

                  <a *ngIf="config.isAndroid" href="{{config.program.googlePlayUrl}}" target="_blank">
                    <img src="assets/imgs/stores/googleplay.png">
                  </a>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <ion-row class="exit-info padding-bottom-iphone-x">
          <ion-col class="ion-text-center">
            <p (click)="onCloseModalClick()" class="dismiss-btn">
              {{ 'common.dismiss' | translate }}
            </p>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </cl-waves-background>
