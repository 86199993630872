import { Directive, ElementRef, Host, Renderer2, HostBinding } from '@angular/core';

@Directive({
  selector: 'button[clRippleEffect]'
})
export class RippleEffectDirective {

  @HostBinding('style.position') position = 'relative';
  @HostBinding('style.overflow') overflow = 'hidden';
  @HostBinding('attr.role') role = 'button';
  @HostBinding('attr.tappable') tappable = '';

  constructor(
    @Host() host: ElementRef,
      renderer: Renderer2
  ) {
    const div = renderer.createElement('div');
    renderer.addClass(div, 'button-effect');
    renderer.setStyle(div, 'display', 'block');
    renderer.appendChild(host.nativeElement, div);
  }
}
