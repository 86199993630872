export const getFormattedTimeInMinutesAndSeconds = (timeInSeconds): string => {
  const minutes = getFormattedTimeInMinutes(timeInSeconds, true);
  const seconds = getFormattedTimeInSeconds(Math.trunc(timeInSeconds % 60));

  return `${minutes}:${seconds}`;
};


export const getFormattedTimeInMinutes = (timeInSeconds, withSeconds): string | number => {
  const time = Math.max(0, timeInSeconds);
  const min = Math[withSeconds ? 'floor' : 'ceil'](time / 60);

  return min > 0 && min < 10 ? '0' + min : min ? min : '00';
};

export const getFormattedTimeInSeconds = (seconds): string => seconds < 10 ? `0${seconds}` : seconds;
