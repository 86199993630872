// flags selectors

import { createSelector } from '@ngrx/store';

import * as flagsReducer from './flags.reducer';
import { getFlagsState } from '../../../../app/store';

export const isWelcomeTourViewed = createSelector(
  getFlagsState,
  flagsReducer.welcomeTourViewed
);

export const isFirstLoginCompleted = createSelector(
  getFlagsState,
  flagsReducer.firstLoginCompleted
);

export const hasRejectedRating = createSelector(
  getFlagsState,
  flagsReducer.ratingRejected
);

export const hasCompletedRating = createSelector(
  getFlagsState,
  flagsReducer.ratingCompleted
);

export const hasCompletedWriteAccessCheck = createSelector(
  getFlagsState,
  flagsReducer.writeAccessCheckCompleted
);

export const hasRequestedPermissions = createSelector(
  getFlagsState,
  flagsReducer.requestedPermissions
);

export const isWorryToolViewed = createSelector(
  getFlagsState,
  (state) => state.worryToolViewed
);
