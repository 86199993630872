// post actions
import { Action } from '@ngrx/store';
import { Comment } from '../../normalized/schemas/social.schema';

export const OPEN_POST_SUCCESS = '[post] Open Post Success';

export const CLOSE_POST = '[post] Close Post';

// load post
export const LOAD_POST = '[post] Load Post';
export const LOAD_POST_SUCCESS = '[post] Load Post Success';
export const LOAD_POST_FAIL = '[post] Load Post Fail';

export const RELOAD_POST = '[post] Reload Post';

// load one comment
export const LOAD_COMMENT = '[post] Load Comment';
export const LOAD_COMMENT_SUCCESS = '[post] Load Comment Success';

// load comments
export const LOAD_LAST_COMMENTS = '[post] Load Last 10 Comments';
export const LOAD_LAST_COMMENTS_SUCCESS = '[post] Load Last 10 Comments Success';
export const LOAD_LAST_COMMENTS_FAIL = '[post] Load Last 10 Comments Fail';

export const LOAD_ALL_COMMENTS = '[post] Load All Comments';
export const LOAD_ALL_COMMENTS_SUCCESS = '[post] Load All Comments Success';
export const LOAD_ALL_COMMENTS_FAIL = '[post] Load All Comments Fail';

// send comment
export const SAVE_COMMENT = '[post] Save Comment';
export const SAVE_COMMENT_SUCCESS = '[post] Save Comment Success';
export const SAVE_COMMENT_FAIL = '[post] Save Comment Fail';

// delete comment
export const DELETE_COMMENT = '[social] Delete Comment';
export const DELETE_COMMENT_SUCCESS = '[social] Delete Comment Success';
export const DELETE_COMMENT_FAIL = '[social] Delete Comment Fail';

export const RESET_COMMENT_UPDATE = '[social] Reset Comment update';

// Like/Unlike comment
export const TOGGLE_LIKE_COMMENT = '[social] Toggle Like Comment';
export const TOGGLE_LIKE_COMMENT_SUCCESS = '[social] Toggle Like Comment Success';
export const TOGGLE_LIKE_COMMENT_FAIL = '[social] Toggle Like Comment Fail';

// cleaning
export const CLEAN_COMMENT_FEED = '[post] Clean Current Comment Feed';

export const RESET_LIKE_FAIL_COMMENT = '[post] Reset Like Fail Comment';

export const NOTIFY_COMMENT_LIKE = '[post] Notify Comment Like';
export const RESET_NEW_COMMENT_LIKE = '[post] Reset New Comment Like';
export const NOTIFY_NEW_COMMENT = '[post] Notify New Comment';

export class LoadPost implements Action {
  readonly type = LOAD_POST;
  constructor(public payload: any) {}
}

// Triggered when opening an existing post without having to load it
export class OpenPostSuccess implements Action {
  readonly type = OPEN_POST_SUCCESS;

  constructor(public payload: any) {}
}

export class ClosePost implements Action {
  readonly type = CLOSE_POST;
}

export class LoadPostSuccess implements Action {
  readonly type = LOAD_POST_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadPostFail implements Action {
  readonly type = LOAD_POST_FAIL;
  constructor(public payload: any) {}
}

export class LoadLastComments implements Action {
  readonly type = LOAD_LAST_COMMENTS;
  constructor(public payload: string) {}
}

export class LoadLastCommentsSuccess implements Action {
  readonly type = LOAD_LAST_COMMENTS_SUCCESS;
}

export class LoadLastCommentsFail implements Action {
  readonly type = LOAD_LAST_COMMENTS_FAIL;
  constructor(public payload: any) {}
}

export class LoadAllComments implements Action {
  readonly type = LOAD_ALL_COMMENTS;
  constructor(public payload: string) {}
}

export class LoadAllCommentsSuccess implements Action {
  readonly type = LOAD_ALL_COMMENTS_SUCCESS;
}

export class LoadAllCommentsFail implements Action {
  readonly type = LOAD_ALL_COMMENTS_FAIL;
  constructor(public payload: any) {}
}

export class ResetCommentUpdate implements Action {
  readonly type = RESET_COMMENT_UPDATE;
}

export class SaveComment implements Action {
  readonly type = SAVE_COMMENT;
  constructor(public payload: any) {}
}

export class SaveCommentSuccess implements Action {
  readonly type = SAVE_COMMENT_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveCommentFail implements Action {
  readonly type = SAVE_COMMENT_FAIL;
  constructor(public payload: any) {}
}

export class DeleteComment implements Action {
  readonly type = DELETE_COMMENT;

  constructor(public payload: {commentId: string; postId: string}) {
  }
}

export class DeleteCommentSuccess implements Action {
  readonly type = DELETE_COMMENT_SUCCESS;
  constructor(public payload: {
    commentUpdate: any;
    deletedCommentId: string;
  }) {
  }
}

export class DeleteCommentFail implements Action {
  readonly type = DELETE_COMMENT_FAIL;

  constructor(public payload: any) {
  }
}

export class CleanCurrentCommentFeed implements Action {
  readonly type = CLEAN_COMMENT_FEED;
}

export class ToggleLikeComment implements Action {
  readonly type = TOGGLE_LIKE_COMMENT;

  constructor(public payload: string) {
  }
}

export class ToggleLikeCommentSuccess implements Action {
  readonly type = TOGGLE_LIKE_COMMENT_SUCCESS;

  constructor(public payload: boolean) {}
}

export class ToggleLikeCommentFail implements Action {
  readonly type = TOGGLE_LIKE_COMMENT_FAIL;

  constructor(public payload: string, public error: any) {
  }
}

export class ResetLikeFailComment implements Action {
  readonly type = RESET_LIKE_FAIL_COMMENT;
}

export class NotifyCommentLike implements Action {
  readonly type = NOTIFY_COMMENT_LIKE;
  constructor(public payload: any) {}
}

export class ResetNewCommentLike implements Action {
  readonly type = RESET_NEW_COMMENT_LIKE;
}

export class NotifyNewComment implements Action {
  readonly type = NOTIFY_NEW_COMMENT;

  constructor(public payload: any) {}
}

export class LoadComment implements Action {
  readonly type = LOAD_COMMENT;

  constructor(public payload: number) {}
}

export class LoadCommentSuccess implements Action {
  readonly type = LOAD_COMMENT_SUCCESS;

  constructor(public payload: Comment) {}
}

export class ReloadPost implements Action {
  readonly type = RELOAD_POST;

  constructor(public payload: string) {}
}

export type PostActions =
  | ResetLikeFailComment
  | ResetCommentUpdate
  | OpenPostSuccess
  | ClosePost
  | LoadPost
  | LoadPostSuccess
  | LoadPostFail
  | LoadLastComments
  | LoadLastCommentsSuccess
  | LoadLastCommentsFail
  | LoadAllComments
  | LoadAllCommentsSuccess
  | LoadAllCommentsFail
  | DeleteComment
  | DeleteCommentSuccess
  | DeleteCommentFail
  | SaveComment
  | SaveCommentSuccess
  | SaveCommentFail
  | CleanCurrentCommentFeed
  | ToggleLikeComment
  | ToggleLikeCommentSuccess
  | ToggleLikeCommentFail
  | NotifyCommentLike
  | NotifyNewComment
  | LoadComment
  | LoadCommentSuccess
  | ResetNewCommentLike
  | ReloadPost;
