import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClarityConfig } from 'src/app/config/clarity.config';

@Component({
  selector: 'cl-language-picker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./language-picker.component.scss'],
  template: `
    <ion-icon name="globe-outline"></ion-icon>
    <div class="language-selector">
      <ion-select
        mode="ios"
        (ionChange)="handleLanguageChange($event)"
        [value]="translateService.currentLang">
        <ion-select-option
          *ngFor="let option of languageOptions"
          [value]="option.value">
          {{ option.label | translate }}
        </ion-select-option>
      </ion-select>
    </div>
  `
})

export class LanguagePickerComponent {
  languageOptions = this.config.getLanguageOptions();

  constructor(
    public translateService: TranslateService,
    public config: ClarityConfig
  ) {}

  setLanguage(lang) {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }

  handleLanguageChange(event) {
    this.setLanguage(event.detail.value);
  }
}
