// auth effects

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as HealthDevicesActions from '../actions/health-devices.actions';
import { HealthDevicesProvider, WeightScaleOrder } from 'src/app/providers/health-devices.provider';
import { AddData } from 'ngrx-normalizr';
import { weightScaleOrderSchema } from '../../normalized/schemas/health-devices.schema';
import { LoadingService } from 'src/app/services/loading.service';
import { LoggerService } from 'src/app/services/logger.service';
import { UndoOnboardingCompleted } from 'src/app/store/persistent/onboarding/onboarding.actions';
import { Store } from '@ngrx/store';
import { SessionState } from '../session.reducers';

@Injectable({providedIn: 'root'})
export class HealthDevicesEffects {
  orderWeightScale$ = createEffect(() => this.actions$.pipe(
    ofType(HealthDevicesActions.ORDER_WEIGHT_SCALE),
    switchMap((action: HealthDevicesActions.OrderWeightScale) => this.healthDevicesProvider.createWeightScaleOrder(action.payload)
      .pipe(
        // Make sure `dppwlScale` flag is set to false whenever scale order happens so that user can be prompted if it was received
        tap(() => this.store.dispatch(new UndoOnboardingCompleted('dppwlScale'))),
        map(result => new HealthDevicesActions.OrderWeightScaleSuccess(result)),
        catchError(error => of(new HealthDevicesActions.OrderWeightScaleFail(error)))
      ))
  ));

  fetchWeightScaleOrders$ = createEffect(() => this.actions$.pipe(
    ofType(HealthDevicesActions.FETCH_WEIGHT_SCALE_ORDERS),
    switchMap(() => this.healthDevicesProvider.fetchWeightScaleOrders()
      .pipe(
        map(result => new HealthDevicesActions.FetchWeightScaleOrdersComplete(result)),
        catchError(error => {
          this.logger.error('error at getWeightScaleOrders', JSON.stringify(error), 'fetchWeightScaleOrders');

          // return empty array
          return of(new HealthDevicesActions.FetchWeightScaleOrdersComplete([]));
        })
      ))
  ));

  fetchWeightScaleOrdersComplete$ = createEffect(() => this.actions$.pipe(
    ofType<HealthDevicesActions.FetchWeightScaleOrdersComplete>(HealthDevicesActions.FETCH_WEIGHT_SCALE_ORDERS_COMPLETE),
    tap(() => this.loading.hideLoadingOverlay()),
    map(({ payload }) => new AddData<WeightScaleOrder>({
      data: payload,
      schema: weightScaleOrderSchema
    }))
  ));

  constructor(
    private actions$: Actions,
    private loading: LoadingService,
    private healthDevicesProvider: HealthDevicesProvider,
    private logger: LoggerService,
    private store: Store<SessionState>
  ) {
  }
}
