import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MyCoachConversationPage } from './my-coach-conversation';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    MyCoachConversationPage
  ],
  imports: [
    IonicModule,
    RouterModule.forChild([{path: '', component: MyCoachConversationPage}]),
    TranslateModule.forChild(translateConfig),
    SharedModule,
    CommonModule,
    ReactiveFormsModule
  ]
})
export class MyCoachConversationPageModule {}
