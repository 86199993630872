import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ClarityConfig } from '../../config/clarity.config';

@Component({
  selector: 'cl-close-button',
  styleUrls: ['close-button.component.scss'],
  template: `
    <ion-buttons slot="primary" class="program-{{config.program.programCode}}">
      <ion-button (click)="onClick()" icon-only color="{{color}}" tappable>
        <ion-icon name="{{icon}}"></ion-icon>
      </ion-button>
    </ion-buttons>
  `
})
export class CloseButtonComponent {
  @Input() color: string;

  @Input() icon = 'close';

  @Output() closeClick = new EventEmitter();

  onClick() {
    this.closeClick.emit();
  }

  constructor(
    public config: ClarityConfig
  ) {}
}
