<ion-row class="step step1 lateral-padding" *ngIf="currentStep === 1">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{'wizards.stress_test_ua_header_1' | translate}}</h2>
    <p class="ion-text-left">{{'wizards.please_select_body_part_that_applies' | translate}}</p>

    <cl-list-single-select
      [items]="bodyParts"
      (selectItem)="onBodyPartSelected($event)"
      [selected]="bodyPartChoice">
    </cl-list-single-select>
  </ion-col>
</ion-row>

<ion-row class="step step2 lateral-padding" *ngIf="currentStep === 2">
  <ion-col class="ion-text-center">
    <cl-pick-sensation
      [sensations]="sensations"
      (selectItem)="onSensationSelected($event)"
      [selected]="sensationChoice">
    </cl-pick-sensation>
  </ion-col>
</ion-row>

<ion-row class="step step3 lateral-padding" *ngIf="currentStep === 3">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{'wizards.how_intense_is_this_sensation' | translate}}</h2>

    <cl-intensity-selector
      [sliderValue]="sensationIntensity"
      (intensity)="onIntensityChange($event)">
    </cl-intensity-selector>
  </ion-col>
</ion-row>

<ion-row class="step lateral-padding" *ngIf="currentStep === 4">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">
      {{'wizards.take_a_moment_to_explore_this_sensation' | translate}}
    </h2>

    <div *ngIf="showSpinner" class="spinner-holder">
      <h2>
        <cl-double-bounce color="#2794d0"></cl-double-bounce>
      </h2>
    </div>

    <cl-list-single-select
      *ngIf="!showSpinner"
      [items]="bodySides"
      (selectItem)="onBodySideSelected($event)"
      [selected]="bodySideChoice">
    </cl-list-single-select>
  </ion-col>
</ion-row>

<ion-row class="step lateral-padding" *ngIf="currentStep === 5">
  <ion-col class="ion-text-center" *ngIf="!showExerciseStep">
    <h2 class="step-intro intro-question">
      {{  'wizards.another_short_mindfullness_practice' | translate }}
    </h2>

    <div class="exercises-list ion-text-left">
      <exercises-group
        *ngIf="module2Completed"
        [exercises]="(breatheExercise$ | async)"
        [noLateralPadding]="true"
        [title]=""
        [isWizard]="true"
        (playExercise)="playExercise($event)">
      </exercises-group>

      <cl-im-feeling-lucky-button
        (click)="imFeelingLucky()">
      </cl-im-feeling-lucky-button>
    </div>
  </ion-col>

  <ion-col class="ion-text-center" *ngIf="showExerciseStep">
    <cl-play-exercise
      *ngIf="!checkinExercise"
      [isWizard]="true"
      [exercise]="exerciseToPlay">
    </cl-play-exercise>

    <cl-checkin-exercise
      *ngIf="checkinExercise"
      [graphic]="exerciseToPlay.graphic"
      [title]="exerciseToPlay.title"
      [instructions]="exerciseToPlay.instructions">
    </cl-checkin-exercise>
  </ion-col>
</ion-row>
