import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { ClarityConfig } from '../config/clarity.config';
import { ReleasesProvider, Release } from '../providers/releases.provider';
import { SetOfficialReleaseInfo, SetOfficialVersion } from '../store/sensitive/actions/auth.actions';
import { SessionState } from '../store/session/session.reducers';
import { getOfficialVersion, isAuthenticated } from '../store/sensitive/selectors/auth.selectors';
import { getCurrentUserProgram } from '../store/normalized/selectors/user.selectors';
import { combineLatest } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ReleaseService {

  officialVersion$ = this.store.select(getOfficialVersion);
  currentUserProgram$ = this.store.select(getCurrentUserProgram);

  private currentVersion: string;
  private currentBuild: number;

  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig,
    private releasesProvider: ReleasesProvider
  ) {

  }

  public initialize() {
    console.log(`Release service ${this.currentVersion} ${this.currentBuild}`);

    this.currentVersion = this.config.getAppVersion();
    this.currentBuild = this.config.getBuildNumber();

    return this.getReleaseObserver()
      .pipe(take(1))
      .toPromise();
  }

  public getReleaseObserver() {
    return this.officialVersion$
      .pipe(
        map((version) => version && version === this.currentVersion),
        tap((isOfficial) => !isOfficial && this.refreshReleases())
      );
  }

  public resetOfficialVersion() {
    this.store.dispatch(new SetOfficialVersion(null));
  }

  public forceRefreshReleases() {
    this.refreshReleases();
  }

  private refreshReleases() {
    this.store.select(isAuthenticated)
      .pipe(
        filter((authenticated) => authenticated === true),
        switchMap(() => combineLatest([
          this.releasesProvider.loadReleases(this.config.currentEnv(), this.currentVersion, this.currentBuild),
          this.currentUserProgram$
        ])),
        map(([releases, userProgram]) => {
          if (!releases || !(releases instanceof Array)) {
            return [];
          }

          return releases.filter((release) => (
            release && release.official &&
            release.app_version === this.currentVersion &&
            release.app_build === this.currentBuild &&
            release.program_id === userProgram.program_id
          ));
        }),
        take(1)
      )
      .subscribe((releases) => {
        if (releases.length >= 1) {
          console.log('Official release found', releases);
          this.store.dispatch(new SetOfficialVersion(this.currentVersion));
          this.store.dispatch(new SetOfficialReleaseInfo(releases[0]));
        }
      });
  }

}
