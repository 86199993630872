import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})

export class TruncatePipe implements PipeTransform {
  
  transform(value: string, limit: number): string {
    const splittedText = value.split(' ');
    if (splittedText.length <= limit) {
      return value;
    } else {
      const truncatedValue = splittedText.slice(0, limit - 1);
      
      return truncatedValue.join(' ') + '...';
    }
  }
}
