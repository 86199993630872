import {
  ChangeDetectorRef,
  Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef, OnDestroy
} from '@angular/core';

import { IonContent, NavController, NavParams } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { CheckinWizardComponent } from './components/checkin/checkin-wizard.component';
import { StressTestWizardComponent } from './components/stress-test/stress-test-wizard.component';
import { StressTestUAWizardComponent } from './components/stress-test/stress-test-ua-wizard.component';
import { StressMeterWizardComponent } from './components/stress-meter/stress-meter-wizard.component';
import { Store } from '@ngrx/store';
import { OnboardingService } from '../../services/onboarding.service';
import { NightReflectionComponent } from './components/night-reflection/night-reflection.component';
import { MorningMotivationComponent } from './components/morning-motivation/morning-motivation.component';
import { WantOMeterERNWizardComponent } from './components/stress-meter/want-o-meter-ern-wizard.component';
import { WantOMeterCTQWizardComponent } from './components/stress-meter/want-o-meter-ctq-wizard.component';
import { SessionState } from '../../store/session/session.reducers';
import * as toolsActions from '../../store/session/actions/tools.actions';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { WorryToolWizardComponent } from './components/worry-tool/worry-tool-wizard.component';
import { WizardComponent } from './components/wizard.component';
import { AfterCompleteLessonOptions } from 'src/app/store/session/actions/program.actions';

export type WizardType = 'checkin' |
  'night_reflection' |
  'morning_motivation' |
  'stresstest-ua' |
  'stresstest-ern' |
  'stressmeter' |
  'wantometer-ern' |
  'wantometer-ctq' |
  'worrytool';

export interface WizardConfig {
  title: string;
  totalSteps: number;
  initialStep: number;
}

@Component({
  selector: 'page-wizard',
  styleUrls: ['wizard.scss'],
  templateUrl: 'wizard.html'
})
export class WizardPage implements OnInit, OnDestroy {
  modal;

  @ViewChild(IonContent, { static: true }) ionContent: IonContent;
  @ViewChild('wizardContainer', {static: true, read: ViewContainerRef}) wizardContainer: ViewContainerRef;

  componentRef: ComponentRef<WizardComponent>;

  wizardTitle = '';
  stepsCount = 1;
  currentStep = 1;

  canBack = false;
  canNext = false;
  showStepsProgress = true;

  nextLabel = 'common.next';
  type: WizardType = this.navParams.get('wizardType');
  afterCompleteOptions: AfterCompleteLessonOptions = this.navParams.get('afterCompleteOptions');

  subscriptions: Subscription[] = [];

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    protected store: Store<SessionState>,
    private resolver: ComponentFactoryResolver,
    private ref: ChangeDetectorRef,
    private onboardingService: OnboardingService
  ) {
    if (this.type !== 'night_reflection' && this.type !== 'morning_motivation') {
      this.onboardingService.checkShowingOnboarding({type: this.type});
    }
  }

  ngOnInit() {
    this.loadComponent();
  }

  loadComponent() {
    const componentFactory = this.resolver.resolveComponentFactory<any>(this.getComponentType());

    this.wizardContainer.clear();
    this.componentRef = this.wizardContainer.createComponent(componentFactory);

    this.initComponent();
  }

  initComponent() {
    this.componentRef.instance.init()
      .then((config) => {
        // initial values
        this.wizardTitle = config.title;
        this.stepsCount = config.totalSteps;
        this.currentStep = config.initialStep;

        // hook events
        this.subscriptions.push(this.handleEnableResetConfig());
        this.subscriptions.push(this.handleEnableNext());
        this.subscriptions.push(this.handleDisableNext());
        this.subscriptions.push(this.handleDisableBack());
        this.subscriptions.push(this.handleStepChange());
        this.subscriptions.push(this.handleNextStep());
        this.subscriptions.push(this.handleMoveTrowStep());
        this.subscriptions.push(this.handleWizardComplete());
        this.subscriptions.push(this.handleShowStepsProgress());
      })
      .then(() => {
        this.componentRef.instance.afterInit();
      })
      .then(() => {
        this.componentRef.instance.initStep(this.currentStep);
      });
  }

  handleEnableResetConfig() {
    return this.componentRef.instance.resetConfig.subscribe(({ title, totalSteps, initialStep }: Partial<WizardConfig> = {}) => {
      if (title !== undefined) this.wizardTitle = title;
      if (totalSteps !== undefined) this.stepsCount = totalSteps;
      if (initialStep !== undefined) this.currentStep = initialStep;

      this.ref.detectChanges();
    });
  }

  handleEnableNext() {
    return this.componentRef.instance.enableNext.subscribe(() => {
      this.canNext = true;
    });
  }

  handleDisableNext() {
    return this.componentRef.instance.disableNext.subscribe(() => {
      this.canNext = false;
    });
  }

  handleDisableBack() {
    return this.componentRef.instance.disableBack.subscribe(() => {
      this.canBack = false;
    });
  }

  handleShowStepsProgress() {
    return this.componentRef.instance.showStepsProgress.subscribe((shouldShow) => {
      this.showStepsProgress = shouldShow;
    });
  }

  handleNextStep() {
    return this.componentRef.instance.nextStep.subscribe(() => {
      this.onNext();
    });
  }

  handleMoveTrowStep() {
    return this.componentRef.instance.moveTrowStep.subscribe(() => {
      this.onMoveTrow();
    });
  }

  handleStepChange() {
    return this.componentRef.instance.stepChange.subscribe((data) => {
      this.onStepChange(data);
    });
  }

  handleWizardComplete() {
    return this.componentRef.instance.wizardComplete.subscribe((data) => {
      this.onClose(false);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onNext() {
    if (this.currentStep < this.stepsCount) {
      this.componentRef.instance.onNextStep()
        .then(() => {
          this.ref.detectChanges();
        });
    }
    else {
      this.componentRef.instance.onDone(this.afterCompleteOptions);
    }
  }

  onMoveTrow() {
    if (this.currentStep + 1 < this.stepsCount) {
      this.componentRef.instance.onMoveTrowStep()
        .then(() => {
          this.ref.detectChanges();
        });
    }
    else {
      this.componentRef.instance.onDone(this.afterCompleteOptions);
    }
  }

  onBack() {
    this.componentRef.instance.onPrevStep()
      .then(() => {
        this.ref.detectChanges();
      });
  }

  onStepChange(data) {
    this.currentStep = data.step;

    this.canBack = this.currentStep <= 1
      ? false
      : true;

    this.nextLabel = this.currentStep < this.stepsCount
      ? 'common.next'
      : 'common.done';

    if (data.nextLabel) {
      this.nextLabel = data.nextLabel;
    }

    this.ionContent.scrollToTop();

    this.componentRef.instance.onStepChange(data);
  }

  onClose(closeModal = true) {
    if (this.navParams.get('wizardType') === 'stresstest-ern') {
      this.store.dispatch(new toolsActions.StopStressTest());
    }

    // the interstitial will discard this -- otherwise we'd have one going down and one up at the same time
    if (closeModal) {
      this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
    }
  }

  getComponentType() {
    switch (this.type) {
      case 'checkin':
        return CheckinWizardComponent;
      case 'night_reflection':
        return NightReflectionComponent;
      case 'morning_motivation':
        return MorningMotivationComponent;
      case 'stresstest-ua':
        return StressTestUAWizardComponent;
      case 'stresstest-ern':
        return StressTestWizardComponent;
      case 'stressmeter':
        return StressMeterWizardComponent;
      case 'wantometer-ern':
        return WantOMeterERNWizardComponent;
      case 'wantometer-ctq':
        return WantOMeterCTQWizardComponent;
      case 'worrytool':
        return WorryToolWizardComponent;

      default:
        throw new Error(`Wizard component type ${this.navParams.get('wizardType')} not implemented!`);
    }
  }

}
