import { FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ISimpleAssessmentItem, ISimpleAssessmentCheckbox } from './simple-assessment.interface';

export const NO_ITEMS_SELECTED_VALIDATION_ERROR = { noItemsSelected: true };
export const INVALID_ITEMS_SELECTED_VALIDATION_ERROR = { invalidItemsSelected: true };

const anyItemSelected = (formValue) => Object.values(formValue).map(value => Boolean(value))
  .includes(true);

export function correctSelectionValidator(models: ISimpleAssessmentItem[]): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {

    if (Boolean(models?.length)) {
      const selectedItems = Object.keys(form.value).reduce((selectedItemNameObject, key) => {
        if (form.value[key] === true) {
          selectedItemNameObject[key] = form.value[key];
        }

        return selectedItemNameObject;
      }, {});

      const correctItemsSelected: boolean =
        models.filter((model: ISimpleAssessmentItem) =>
          model.type === 'checkbox' && model.hasOwnProperty('isValid') && Object.keys(selectedItems).includes(model.name)
        ).every((item: ISimpleAssessmentCheckbox) => item.isValid);

      return correctItemsSelected ? null : INVALID_ITEMS_SELECTED_VALIDATION_ERROR;
    }

    return null;
  };
}

export function selectionValidator(models: ISimpleAssessmentItem[]): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {

    if (Boolean(models?.length)) {
      return anyItemSelected(form.value) ? null : NO_ITEMS_SELECTED_VALIDATION_ERROR;
    }

    return null;
  };
}
