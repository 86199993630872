<page-interstitial-locked
  class="ion-page"
  *ngIf="interstitialComponentType === 'locked'"
></page-interstitial-locked>

<page-interstitial-weight-capture
  class="ion-page"
  *ngIf="interstitialComponentType === 'locked.module_in_progress_missing_weight_capture'"
></page-interstitial-weight-capture>

<page-interstitial-dpp-lock
  class="ion-page"
  *ngIf="interstitialComponentType === 'locked.dpp_module_not_yet_available'"
></page-interstitial-dpp-lock>

<page-interstitial-lesson-not-supported
  class="ion-page"
  *ngIf="interstitialComponentType === 'lesson-not-supported'"
></page-interstitial-lesson-not-supported>

<page-interstitial-default
  class="ion-page"
  *ngIf="interstitialComponentType === 'default'"
></page-interstitial-default>
