import * as fromAuth from '../sensitive/reducers/auth.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface SensitiveState {
  auth: fromAuth.AuthState;
}

export const sensitiveReducers: ActionReducerMap<SensitiveState> = {
  auth: fromAuth.authReducer
};
