<cl-waves-background
  class="interstitial-waves-background interstitial-purple"
>

  <ion-content (click)="onClick()">

    <ion-header>
      <cl-header-nav-buttons
        class="page-header"
        color="blank"
        [backButton]="false">
      </cl-header-nav-buttons>
    </ion-header>

    <ion-grid class="page-wrapper">
      <div class="content">
        <ion-grid fixed>
          <ion-row class="title-holder lateral-padding ion-padding">
            <ion-col class="ion-no-padding">
              <h1>{{ 'interstitials.not_yet' | translate }}</h1>
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="ion-no-padding access-date">
              <p [innerHTML]="'interstitials.access_date' | translate: {accessDate: accessDate}"></p>
            </ion-col>
          </ion-row>

          <ion-row class="icon-holder lateral-padding ion-padding-horizontal">
            <ion-col>
              <img src="assets/imgs/time-managment.png">
            </ion-col>
          </ion-row>

          <ion-row>
            <ion-col class="ion-no-padding">
              <p>{{ 'interstitials.enjoy_time_with_our_community' | translate }}</p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <ion-row class="exit-info padding-bottom-iphone-x">
        <ion-col class="ion-text-center">
          <cl-action-button
            class="outsider"
            label="{{'interstitials.go_to_coaching' | translate}}"
            [canClick]="true"
            (click)="goToCoaching()">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </ion-grid>

  </ion-content>
</cl-waves-background>
