import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { State } from '../../store/state.reducer';
import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';

import { WizardService } from './wizard.service';
import { StressMeter } from '../../store/session/models/stress-meter.model';
import { getTriggers } from '../../store/normalized/selectors/list-items.selectors';
import * as toolsActions from '../../store/session/actions/tools.actions';
import { exerciseIdStressMeter } from '../../store/session/selectors/tools.selectors';
import { DatePipe } from '@angular/common';
import { LoggerService } from '../logger.service';
import { ListItem } from 'src/app/store/normalized/schemas/list-item.schema';
import { SyncTriggers } from 'src/app/store/session/actions/sync.actions';

const initialModel = {
  date: null,
  recorded_at: null,
  trigger_id: null,
  exercise_id: null,
  strength: 0,
  anxiety: null,
  after_feeling_strength: null,
  user_action: ''
};

@Injectable({providedIn: 'root'})
export class StressMetersService extends WizardService {

  private model: StressMeter;

  private triggers: ListItem[] = [];

  constructor(
    protected store: Store<State>,
    protected popoverCtrl: PopoverController,
    protected translate: TranslateService,
    protected logger: LoggerService
  ) {
    super(store, popoverCtrl, translate, logger);
  }

  initModel() {
    this.model = {...initialModel};

    return this.store.select(getTriggers)
      .pipe(
        distinctUntilChanged(),
        tap(triggers => {
          if(!triggers?.length) {
            this.store.dispatch(new SyncTriggers());
          }
        }),
        filter(triggers => Boolean(triggers?.length)),
        take(1),
        tap(triggers => {
          this.triggers = triggers;
        })
      )
      .toPromise();
  }

  setCravingIntensity(intensity) {
    this.model.strength = intensity / 100;
  }

  getCravingIntensity() {
    return this.model.strength * 100;
  }

  setCravingIntensityAfterFeeling(intensity) {
    this.model.after_feeling_strength = intensity / 100;
  }

  getCravingIntensityAfterFeeling() {
    return this.model.after_feeling_strength * 100;
  }

  isCravingIntensityValid() {
    return this.model.strength >= 0;
  }

  getTriggersList() {
    return this.triggers;
  }

  setTrigger(id) {
    this.model.trigger_id = id;
  }

  getTrigger() {
    return this.model.trigger_id;
  }

  setExercise(id: number) {
    this.model.exercise_id = id;
  }

  setUserAction(action) {
    this.model.user_action = action;
  }

  isTriggerValid() {
    return Number.isInteger(this.model.trigger_id);
  }

  save() {
    if (!this.model) {
      // this should never be reached since the bug was fixed...
      this.logger.info('Stress Meter model not initialized!', this.model, 'StressMetersService#save');

      return false;
    }

    const datePipe = new DatePipe('en-US');
    this.model.date = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.model.recorded_at = new Date().toISOString();

    if (!this.model.exercise_id) {
      this.store.select(exerciseIdStressMeter)
        .pipe(take(1))
        .toPromise()
        .then((exerciseId) => {
          this.model.exercise_id = exerciseId;
          this.store.dispatch(new toolsActions.SaveStressMeter(this.model));
        });
    }
    else {
      this.store.dispatch(new toolsActions.SaveStressMeter(this.model));
    }
  }
}
