import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ClarityConfig } from '../../../config/clarity.config';
import * as moment from 'moment';
import { ReleaseService } from '../../../services/release.service';
import { Subscription } from 'rxjs';
import { LiveSubscription } from '../../../store/normalized/selectors/subscription.selectors';

@Component({
  selector: 'cl-upgrade-options',
  styleUrls: ['upgrade-options.component.scss'],
  template: `
    <cl-shadow-wrapper class="free-trial-wrapper" *ngIf="fromAccountSetup">
      <ion-row class="benefits-box">
        <ion-col>
          <h4>{{'subscriptions.start_free_trial' | translate}}:</h4>
          <ul>
            <li>{{'subscriptions.access_first_X_video_modules'
              | translate: {trialModules: config.program.trialModules} }}
            </li>
            <li
              *ngIf="config.program.programCode !== 'ua'">{{'subscriptions.behavior_change_tools_including' | translate }}</li>
            <li
              *ngIf="config.program.programCode === 'ua'">{{'subscriptions.behavior_change_tools_including_ua' | translate }}</li>
            <li>{{'subscriptions.daily_mindfulness_exercises' | translate }}</li>
            <li>{{'subscriptions.guided_meditations' | translate }}</li>
          </ul>
        </ion-col>
      </ion-row>

      <ion-row class="trial-btn-box">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{'subscriptions.try_the_first_X_modules' | translate: {trialModules: config.program.trialModules} }}"
            [fullWidth]="true"
            [whiteBg]="true"
            [square]="true"
            [canClick]="true"
            (action)="onExit()">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </cl-shadow-wrapper>

    <cl-shadow-wrapper class="full-access-wrapper">
      <ion-row class="benefits-box">
        <ion-col>
          <h4>{{this.subscribeTitle | translate}}:</h4>
          <ul>
            <li>{{'subscriptions.live_video_calls_with_dr_brewer' | translate }}</li>
            <li>{{'subscriptions.all_lessons_plus_theme_weeks' | translate }}</li>
            <li *ngIf="config.program.programCode !== 'ern'">{{'subscriptions.guided_exercises_reduce_anxiety' | translate }}</li>
            <li *ngIf="config.program.programCode === 'ern'">{{'subscriptions.guided_exercises_reduce_craving' | translate }}</li>
            <li>{{'subscriptions.journal_and_checkin' | translate }}</li>
          </ul>
        </ion-col>
      </ion-row>

      <ion-row class="signup-btn-box">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{'subscriptions.purchase_a_plan' | translate}}"
            [fullWidth]="true"
            [square]="true"
            [canClick]="true"
            (action)="onSubscribe()">
          </cl-action-button>
        </ion-col>
      </ion-row>

      <ion-row class="restore-button-box" *ngIf="canRestoreSubscription">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{'subscriptions.restore_subscription' | translate}}"
            [fullWidth]="true"
            [whiteBg]="true"
            [square]="true"
            [canClick]="true"
            (action)="onRestore()">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </cl-shadow-wrapper>

    <ion-row class="license-code-link" *ngIf="canUseLicense">
      <ion-col class="ion-text-center" (click)="onLicense()">
        <a>{{'subscriptions.do_you_have_a_license' | translate}}</a>
      </ion-col>
    </ion-row>
  `
})

export class UpgradeOptionsComponent implements OnInit, OnDestroy {
  @Input() subscription: LiveSubscription;
  @Input() fromAccountSetup: boolean;
  @Input() isSharecareSso: boolean;
  @Input() isSharecareStandalone: boolean;
  @Input() isSharecareConsumer: boolean;

  @Output() exit = new EventEmitter();
  @Output() subscribe = new EventEmitter();
  @Output() restore = new EventEmitter();
  @Output() license = new EventEmitter();

  public subscribeClicked = false;

  private isOfficialRelease = false;
  private releaseSubscription: Subscription;

  constructor(
    public config: ClarityConfig,
    private releaseService: ReleaseService,
    private ref: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.releaseSubscription = this.releaseService.getReleaseObserver()
      .subscribe((isOfficialRelease) => {
        this.isOfficialRelease = isOfficialRelease;
        this.ref.detectChanges();
      });
  }

  get subscribeTitle() {
    if (!this.config.isCTQ()) {
      return 'subscriptions.get_full_access';
    }

    if (!this.config.isDevice) {
      return 'subscriptions.get_full_access_ctq_web';
    }

    return 'subscriptions.get_full_access_ctq_mobile';
  }

  get canRestoreSubscription() {
    // no restore options on web
    if (!this.config.isDevice) {
      return false;
    }

    return !this.fromAccountSetup || this.subscribeClicked;
  }

  get canUseLicense() {
    // will check if this build is official or minimum delay has passed since build (see constants)

    if (!this.isOfficialRelease && !this.config.onDev() && this.config.env.releaseId && !this.config.isWebApp) {
      const generatedAt = Number(this.config.env.releaseId);
      const now = Number(moment().format('x'));

      if (generatedAt && now < generatedAt) {
        return false;
      }
    }

    return !this.subscription || (!this.subscription.isActive && this.subscription.canLicense);
  }

  onExit() {
    this.exit.emit();
  }

  onSubscribe() {
    this.subscribeClicked = true;
    this.subscribe.emit();
    this.ref.detectChanges();
  }

  onRestore() {
    this.restore.emit();
  }

  onLicense() {
    this.license.emit();
  }

  ngOnDestroy() {
    this.releaseSubscription && this.releaseSubscription.unsubscribe();
  }
}
