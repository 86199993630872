import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IonContent, Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { StepValidationSlideComponent } from 'src/app/components/slides/step-validation-slide';
import { SessionState } from 'src/app/store/session';
import { CheckboxGroupControl, CheckboxItemControl } from 'src/app/utils/checkbox-form-control';
import * as Questions from './ern-account-setup-questions.constants';
import { ernPaths } from './ern-account-setup-paths.constants';
import * as accountActions from '../../../store/session/actions/account.actions';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { DottedStepsIndicatorStatus } from 'src/app/components/dotted-steps-indicator/dotted-steps.model';
import { ActivatedRoute } from '@angular/router';
import { AccountErnSetupPayload } from 'src/app/store/session/models/account-setup.model';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';
import { ErnContentProgram, UserProgram } from 'src/app/store/normalized/schemas/user.schema';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { unicodeDecodeB64 } from '@mindsciences/utils';

enum ErnAccountSetupSlides {
  Intro = 'intro',
  ProgramPaths = 'programPaths',
  MainGoal = 'mainGoal',
  ContentEngagement = 'contentEngagement',
  WeightTracking = 'weightTracking',
  RecommendedPrograms = 'recommendedPrograms',
}

@Component({
  selector: 'page-ern-account-setup',
  styleUrls: ['ern-account-setup.scss'],
  templateUrl: 'ern-account-setup.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErnAccountSetupPage extends StepValidationSlideComponent implements OnInit, AfterViewInit, OnDestroy {
  public ErnContentProgram = ErnContentProgram;
  private readonly destroyed$ = new Subject<void>();
  readonly TEXTAREA_CHARACTER_LIMIT = 500;
  public slides: typeof ErnAccountSetupSlides = ErnAccountSetupSlides;
  public recommendedPaths = ernPaths;

  @ViewChild('ionContent', { read: ElementRef, static: true })

  public ionContent: ElementRef<IonContent>;
  public form: FormGroup;

  public mainGoalModel: CheckboxGroupControl;
  public contentEngagementModel: CheckboxGroupControl;
  public weightTrackingModel: CheckboxGroupControl;

  public customGoal: string;

  public mainGoalOptions = Questions.mainGoal;
  public contentEngagementOptions = Questions.contentEngagement;
  public weightTrackingOptions = Questions.weightTracking;

  selectedSlideId$ = this.selectedSlideElement$.pipe(
    map(element => element ? element.querySelector('section[id]')?.id : this.slides.Intro)
  );

  isSlideScrolledToBottom$ = new BehaviorSubject(false);
  @ViewChild('scrolledToBottomObserver', { read: ElementRef, static: false })
  scrolledToBottomObserver: ElementRef<HTMLElement>;
  observer: IntersectionObserver;

  userProgramPayload: UserProgram;

  analyticsPayload: {
    selectedGoals?: string[];
    customGoal?: string;
    weightTrackRelationship?: string[];
    foodContentRelationship?: string[];

    selectedTrack?: string;
  } = {};

  dottedStepsStatus$: Observable<DottedStepsIndicatorStatus> = this.selectedSlideNumber$.pipe(
    takeUntil(this.destroyed$),
    map(selectedSlide => {
      switch (selectedSlide) {
        case 2:
          return { dots: 4, activeDot: 0 };
        case 3:
          return { dots: 4, activeDot: 1 };
        case 4:
          return { dots: 4, activeDot: 2 };
        case 5:
          return { dots: 4, activeDot: 3 };
        default:
          return null;
      }
    })
  );

  shouldShowDottedStep(selectedSlideId: ErnAccountSetupSlides) {
    return selectedSlideId !== this.slides.Intro && selectedSlideId !== this.slides.ProgramPaths;
  }

  constructor(
    public config: ClarityConfig,
    public platform: Platform,
    private store: Store<SessionState>,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {
    super();
    history.pushState(null, null, location.href);

    this.route.queryParams.subscribe(((params) => {
      try {
        this.userProgramPayload = JSON.parse(unicodeDecodeB64(params.userProgramPayload)) || null;
      } catch {
        this.userProgramPayload = null;
      }
    }));

    this.swiperInit$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.swiper.on('slideChangeTransitionStart', () => {
        (this.swiper.slides[this.swiper.activeIndex] as HTMLElement).style.zIndex = `${this.swiper.activeIndex}`;

        setTimeout(() => {
          this.observer.unobserve(this.scrolledToBottomObserver.nativeElement);
          this.ionContent.nativeElement.scrollToTop();
        }, 0);
      });

      this.swiper.on('slideChangeTransitionEnd', () => {
        setTimeout(() => {
          this.observer.observe(this.scrolledToBottomObserver.nativeElement);
        }, 0);
      });
    });

    this.selectedSlideId$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((selectedSlideId) => {
      switch (selectedSlideId) {
        case this.slides.RecommendedPrograms:
          return this.determineRecommenedPath();
        default:
          return;
      }
    });
  }

  ngOnInit(): void {
    this.form = this.createFormInputs();
  }

  ionViewWillEnter() {
    this.addScrollInputIntoViewListener();
  }

  ionViewWillLeave() {
    this.removeScrollInputIntoViewListener();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.observer = new IntersectionObserver(entries => {
      this.isSlideScrolledToBottom$.next(!entries[0].isIntersecting);
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0
    });

    setTimeout(() => this.observer.observe(this.scrolledToBottomObserver.nativeElement), 300);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public handleFoodEngamentNextSlide() {
    this.analyticsPayload.foodContentRelationship = this.getFormResult().contentEngagement;
    const formModel = this.getFormResult();

    // Check whether a 'MindfulEating' path option is selected in ContentEngagement slide
    const mindfulEatingOptionSelected = () => {
      const selection = this.contentEngagementOptions.filter((option) => option.value === formModel.contentEngagement[0])[0];

      return selection?.recommendation === ErnContentProgram.MindfulEating;
    };

    // If user selected either of the first two options in ContentEngagement slide, go straight to last slide
    // Otherwise, proceed normally to the next slide
    if (mindfulEatingOptionSelected()) {
      this.navigateToNamedSlide(this.slides.RecommendedPrograms);
    } else {
      this.tryNavigateToNext();
    }
  }

  private determineRecommenedPath() {
    let recommendedPath: ErnContentProgram;
    const formModel = this.getFormResult();

    const losingWeightGoalSelected = formModel.mainGoal.includes('losing_weight');

    const mindfulEatingAnswersSelected = () => {
      const selection =
        this.contentEngagementOptions.filter((option) => option.value === formModel.contentEngagement[0])[0] ||
        this.weightTrackingOptions.filter((option) => option.value === formModel.weightTracking[0])[0];

      return selection?.recommendation === ErnContentProgram.MindfulEating;
    };

    const weightLossAnswersSelected = () => {
      const selection = this.weightTrackingOptions.filter((option) => option.value === formModel.weightTracking[0])[0];

      return selection?.recommendation === ErnContentProgram.WeightLoss;
    };

    // Determine recommended path based on user's answers
    if (mindfulEatingAnswersSelected()) {
      recommendedPath = ErnContentProgram.MindfulEating;
    } else if (weightLossAnswersSelected()) {
      recommendedPath = ErnContentProgram.WeightLoss;
    } else if (losingWeightGoalSelected) {
      recommendedPath = ErnContentProgram.WeightLoss;
    } else {
      recommendedPath = ErnContentProgram.MindfulEating;
    }

    // Update recommendedPaths array which is used to display recommended paths so that the recommended path is the first one in the array
    this.recommendedPaths = [
      ernPaths.find(({ value }) => value === recommendedPath),
      ...ernPaths.filter(({ value }) => value !== recommendedPath)
    ];
  }

  private createFormInputs() {
    this.mainGoalModel = new CheckboxGroupControl('mainGoal', Questions.mainGoal.map(item => new CheckboxItemControl(item)));
    this.contentEngagementModel = new CheckboxGroupControl('contentEngagement', Questions.contentEngagement.map(item => new CheckboxItemControl(item)));
    this.weightTrackingModel = new CheckboxGroupControl('weightTracking', Questions.weightTracking.map(item => new CheckboxItemControl(item)));

    return new FormGroup({
      mainGoal: this.mainGoalModel.control,
      customGoal: new FormControl(''),
      contentEngagement: this.contentEngagementModel.control,
      weightTracking: this.weightTrackingModel.control
    });
  }

  private addScrollInputIntoViewListener() {
    window.addEventListener('ionKeyboardDidShow', this.scrollDownWhenKeyboardAppears.bind(this));
  }

  private removeScrollInputIntoViewListener() {
    window.removeEventListener('ionKeyboardDidShow', this.scrollDownWhenKeyboardAppears.bind(this));
  }

  private scrollDownWhenKeyboardAppears() {
    const customGoalTextAreaActive = (activeElement) =>
      activeElement?.tagName?.toLowerCase() === 'textarea' && activeElement?.parentElement?.parentElement?.classList?.contains('customGoal');

    if (!customGoalTextAreaActive(document?.activeElement)) {
      return;
    }

    if (this.config.isDevice) {
      setTimeout(() => {
        document.activeElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
      }, 100);
    } else {
      // Mobile browsers automatically attempt to scroll a focused input into view,
      // however in this onboarding we have to do it ourselves
      // because of an ionic issue: https://github.com/ionic-team/ionic-framework/issues/24647
      this.ionContent.nativeElement.scrollToBottom();
      // Scroll down just enough so the textarea is shown.
      window.scrollBy(0, 150);
    }
  };

  getFormResult() {
    return {
      mainGoal: this.mainGoalModel?.value,
      contentEngagement: this.contentEngagementModel?.value,
      weightTracking: this.weightTrackingModel?.value
    };
  }

  isStepInvalid(step: string) {
    if (!this.form || !step) return false;

    const stepControl = this.form.get(step);

    if (!stepControl) {
      console.log('warning. not found step control. step: ', step);

      return false;
    }

    if (step === this.slides.MainGoal) {
      return !Boolean(this.form.get('customGoal')?.value) && stepControl.invalid;
    } else {
      return stepControl.invalid;
    }
  }

  isStepValid() {
    return true; // true because button will be disabled in html with isStepInvalid
  }

  public optionSelected(control: FormControl, multiSelectDisabled?: boolean) {
    if (multiSelectDisabled) { control.parent.reset(); }
    control.setValue(!control.value);
  }

  public onPathSelected(contentProgramId: ErnContentProgram) {
    const accountSetupPayload: AccountErnSetupPayload = {
      userProgram: this.userProgramPayload,
      content_program: contentProgramId
    };

    this.analyticsPayload.selectedTrack = contentProgramId;

    this.store.dispatch(new accountActions.AccountSetupProcessExtended(accountSetupPayload));
    this.analyticsService.trackEvent(AnalyticsEvents.CompletedErnExtensionOnboarding, this.analyticsPayload);
  }

  public onDescriptionExtended() {
    setTimeout(() => {
      this.swiper.updateAutoHeight(0);
    }, 100);
  }

  public trackEventAndNavigateToNextSlide() {
    switch (this.getSlideName()) {
      case this.slides.MainGoal:
        const selectedGoals = {
          goals: this.mainGoalModel?.value,
          customGoal: this.form.get('customGoal')?.value
        };

        this.analyticsPayload.selectedGoals = this.mainGoalModel.value;
        this.analyticsPayload.customGoal = this.form.get('customGoal')?.value;
        break;
      case this.slides.WeightTracking:
        this.analyticsPayload.weightTrackRelationship = this.getFormResult().weightTracking;
        break;
    };

    this.tryNavigateToNext();
  }
}
