import { Injectable } from '@angular/core';

import { HttpProvider } from './http/http.provider';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { Message, MessageFeed } from '../store/normalized/schemas/my-coach.schema';

export interface ConversationsFilterParams {
  profile_id?: number;
  status?: string;
  new?: boolean;
}

@Injectable({providedIn: 'root'})
export class MyCoachProvider {

  private conversationsEndpoint = '{community}/conversations';
  private messagesEndpoint = '{community}/messages';
  private markAsReadEndpointAction = '/mark_as_read';

  constructor(
    private http: HttpProvider
  ) {
  }

  public markConversationAsRead(idConversation: string) {
    return this.http.patch(this.conversationsEndpoint + '/' + idConversation + this.markAsReadEndpointAction, {})
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public addConversation(content) {
    return this.http.post(this.messagesEndpoint, {
      content,
      new_coach_message: true
    })
      .pipe(
        map(conversation => this.mapConversation(conversation)),
        catchError(error => observableThrowError(error))
      );
  }

  public postMessage(content, conversation_id) {
    return this.http.post(this.messagesEndpoint, {
      content,
      new_coach_message: false,
      conversation_id
    });
  }

  public deleteMessage(id: string) {
    const req = `${this.messagesEndpoint}/${id}`;

    return this.http.delete(req)
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public closeConversation(id: string) {
    return this.http.patch(this.conversationsEndpoint + '/' + id, {status: 'closed'})
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public deleteConversation(id: string) {
    const req = `${this.conversationsEndpoint}/${id}`;

    return this.http.delete(req)
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public loadConversations(params, page = 1) {
    let url = `${this.conversationsEndpoint}?page=${page}`;
    const queryString = new URLSearchParams(params).toString();
    if (queryString) {
      url += ('&' + queryString);
    }

    return this.http.get(url)
      .pipe(
        catchError((error) => observableThrowError(error))
      );
  }

  public loadMessages(conversationId: string) {
    return this.http.get<MessageFeed>(`${this.conversationsEndpoint}/${conversationId}/messages`)
      .pipe(
        catchError((error) => observableThrowError(error))
      );
  }

  public loadAllMessages(conversationId: string) {
    return this.http.get<Message[]>(`${this.conversationsEndpoint}/${conversationId}/messages/all`)
      .pipe(
        catchError((error) => observableThrowError(error))
      );
  }

  public loadUnreadMessagesCount() {
    return this.http.get(`${this.messagesEndpoint}/status`)
      .pipe(
        map((payload) => payload.unread_messages_count),
        catchError((error) => observableThrowError(error))
      );
  }

  private mapConversation(msg) {
    return {
      ...msg,
      id: msg.conversation_id
    };
  }
}
