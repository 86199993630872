import { ErnContentProgram } from 'src/app/store/normalized/schemas/user.schema';

export const mainGoal = [
  {
    label: 'account_setup.ern_diagnostic_onboarding.main_goal.changing_eating_habits',
    value: 'changing_eating_habits'
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.main_goal.improving_overall_health',
    value: 'improving_overall_health'
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.main_goal.losing_weight',
    value: 'losing_weight',
    recommendation: ErnContentProgram.WeightLoss
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.main_goal.becoming_more_self_aware',
    value: 'becoming_more_self_aware'
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.main_goal.getting_healthier_for_family',
    value: 'getting_healthier_for_family'
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.main_goal.relationship_to_food',
    value: 'relationship_to_food'
  }
];

export const contentEngagement = [
  {
    label: 'account_setup.ern_diagnostic_onboarding.content_engagement.very_uncomfortable',
    value: 'very_uncomfortable',
    triggerNextSlideSkip: true,
    recommendation: ErnContentProgram.MindfulEating
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.content_engagement.uncomfortable',
    value: 'uncomfortable',
    triggerNextSlideSkip: true,
    recommendation: ErnContentProgram.MindfulEating
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.content_engagement.neutral',
    value: 'neutral',
    recommendation: null
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.content_engagement.comfortable',
    value: 'comfortable',
    recommendation: null
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.content_engagement.very_comfortable',
    value: 'very_comfortable',
    recommendation: null
  }
];

export const weightTracking = [
  {
    label: 'account_setup.ern_diagnostic_onboarding.weight_tracking.very_uncomfortable',
    value: 'very_uncomfortable',
    recommendation: ErnContentProgram.MindfulEating
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.weight_tracking.uncomfortable',
    value: 'uncomfortable',
    recommendation: ErnContentProgram.MindfulEating
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.weight_tracking.neutral',
    value: 'neutral'
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.weight_tracking.comfortable',
    value: 'comfortable',
    recommendation: ErnContentProgram.WeightLoss
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.weight_tracking.very_comfortable',
    value: 'very_comfortable',
    recommendation: ErnContentProgram.WeightLoss
  }
];
