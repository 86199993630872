import { Platform, ToastController } from '@ionic/angular';

import { Globalization } from '@ionic-native/globalization/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, withLatestFrom } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

import { ClarityConfig } from './config/clarity.config';
import { AnalyticsService } from './services/analytics/analytics.service';
import { ConnectivityService } from './services/connectivity.service';
import { LoadingService } from './services/loading.service';
import { checkBonusExerciseAlternatives } from './store/normalized/selectors/exercises.selectors';
import * as navigationActions from './store/session/actions/navigation.actions';
import * as accountActions from './store/session/actions/account.actions';
import * as authActions from './store/sensitive/actions/auth.actions';
import { isAuthenticated } from './store/sensitive/selectors/auth.selectors';
import { isUserLoaded, isUserLoading } from './store/session/selectors/account.selectors';
import { platformReady } from './config/platform-ready';
import { ConnectionHandlerService } from './providers/http/connection-handler.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { NotificationsService } from './services/notification.service';
import { LoggerService } from './services/logger.service';
import { ActionCableService } from './services/actioncable/action-cable.service';
import { OfflineQueueService } from './services/offline-queue.service';
import { CravingToolService } from './services/wizards/craving-tool.service';
import { AddData } from 'ngrx-normalizr';
import { ReleaseService } from './services/release.service';
import { BrightcoveNativePlayerLoaderService } from './services/brightcove/brightcove-native-player-loader.service';

import { Exercise, exerciseSchema } from './store/normalized/schemas/exercise.schema';
import { getCurrentUserProgram } from './store/normalized/selectors/user.selectors';
import { SyncBonusExercises } from './store/session/actions/sync.actions';
import { State } from './store/state.reducer';

import { Component } from '@angular/core';

import { fetch as fetchPolyfill } from 'whatwg-fetch';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { isFullSynced } from './store/session/selectors/sync.selectors';
import { TrackActivity } from './store/session/actions/user-activity.actions';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { TimeService } from './services/time.service';
import { ResumeService } from './services/resume.service';
import { JWPlayerService } from './services/jwplayer.service';
import { GtmService } from './services/analytics/gtm.service';
import { MyCoachService } from './services/my-coach/my-coach.service';
import { EventsService } from './services/events.service';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { DeeplinksService } from './services/deeplinks.service';
import { IRootService } from './services/files/iroot.service';
import { BrightcoveWebPlayerLoaderService } from './services/brightcove/brightcove-web-player-loader.service';
import { PrivacyScreenService } from './services/privacy-screen.service';
import { AnalyticsEvents } from './services/analytics/analytics.events';
import { GetMobileAppService } from './services/get-mobile-app.service';
import { MaintenanceService } from './services/maintenance.service';
import { isWelcomeTourViewed } from './store/persistent/flags/flags.selectors';

window.fetch = window.fetch || fetchPolyfill;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  // @ViewChild('rootNav') nav;
  rootPage: string = null;

  constructor(
    private store: Store<State>,
    private platform: Platform,
    private mobileAccessibility: MobileAccessibility,
    private translateService: TranslateService,
    public config: ClarityConfig,
    private connectivity: ConnectivityService,
    private loading: LoadingService,
    private logger: LoggerService,
    private events: EventsService,
    public analyticsService: AnalyticsService,
    private connectionHandlerService: ConnectionHandlerService,
    private screenOrientation: ScreenOrientation,
    private notificationsService: NotificationsService,
    private cableService: ActionCableService,
    private offlineQueueService: OfflineQueueService,
    private cravingToolService: CravingToolService,
    private releaseService: ReleaseService,
    // TODO: migrate https://cordova.apache.org/news/2017/11/20/migrate-from-cordova-globalization-plugin.html
    // eslint-disable-next-line import/no-deprecated
    private globalization: Globalization,
    private router: Router,
    private background: BackgroundMode,
    private timeService: TimeService,
    private resumeService: ResumeService,
    private deeplinksService: DeeplinksService,
    private jwplayerService: JWPlayerService,
    private brightcoveWebPlayerLoaderService: BrightcoveWebPlayerLoaderService,
    private brightcoveNativePlayerLoaderService: BrightcoveNativePlayerLoaderService,
    private gtmService: GtmService,
    private myCoachService: MyCoachService,
    private iRootService: IRootService,
    private privacyScreenService: PrivacyScreenService,
    private toastController: ToastController,
    private maintenanceService: MaintenanceService,
    private getMobileAppService: GetMobileAppService
  ) {
    // set default language
    this.translateService.setDefaultLang(ClarityConfig.DEFAULT_LANGUAGE);

    // settings just the default language doesn't correctly fall back - @see https://github.com/ngx-translate/core/issues/332
    this.translateService.use(ClarityConfig.DEFAULT_LANGUAGE);

    this.platform.ready()
      .then(() => this.iRootService.isRooted(this.showRootedWarning))
      .then(() => this.privacyScreenService.disable())
      .then(() => {
        // init platform ready promise - used by offline hydrator
        platformReady.resolve(this.platform);

        // TODO temp hack until we deal with index.html -> <html lang="en" class="ms-platform-x">
        this.hideWebSplashScreen();

        // hide splash screen
        if (!this.config.isDevice) {
          // setup web version
          this.hideWebSplashScreen();
        } else {
          StatusBar.hide();

          // Android is special...
          if (this.config.isAndroid) {
            setTimeout(() => {
              SplashScreen.hide();
            }, 500);
          } else {
            SplashScreen.hide();
          }

          this.mobileAccessibility.usePreferredTextZoom(false);

          // All orientations has to be enabled on all platforms in the config files
          // in order to allow the brightcove player to work properly in fullscreen.
          // The preferred orientation is set up here.
          if (this.config.isDevice && !this.platform.is('ipad')) {
            this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
          }

          // on Android we don't want any back action
          document.addEventListener('backbutton', () => {
            console.log('back button discarded');
          });

          this.platform.backButton.subscribe(() => console.log('back button discarded'));

          // platform.registerBackButtonAction(() => console.log('back button discarded'));
        }

        // initialize configuration
        // TODO: Refactor all service using initialize() to save the promise internally and check when needed
        // @see example in NotificationsService and others in initializeApp() below
        return this.config.initialize()
          .then(() => {
            if (this.config.isIos && !this.config.runningOnIonicDevApp) {
              // disable background mode -- illegal by Apple
              // somehow these don't work as expected, the second one however seem to have some effect
              this.background.disable();
              this.background.setEnabled(false);
            }
          })
          .then(() =>
            Promise.all([
              // we want errors as early as possible
              this.logger.initialize(),

              // notifications click handling requires initializing as early as possible!
              this.notificationsService.initialize(),

              // wait for state to be (re)hydrated
              this.waitForHydration()
            ])
              // extract hydation status
              .then(([log, notification, hydration]) => hydration)
              .then(hydration => {
                console.log('INITIALIZING APP', hydration);
              })
              .then(_ => this.iRootService.logRootStatus())
              // .then(this.redirectOnLoggedOut.bind(this))
              .then(_ => this.initializeApp())
              .then(_ => this.validateStateAfterHydration())
              .then(_ => this.enableServices())
              .then(_ => this.trackAllRouteChanges())
              // Components are unable to load and render when the app is offline as they are lazy loaded,
              // This can be mitigated by forcing them to preload on app init
              .then(_ => { if (this.config.isWebApp) { this.preloadChunks(); }})
              // this needs to be handled properly, no events if the user is not logged in and only after the app loads!!!
              // .then(_ => this.startLifecycleTracking())
          );
      })
      .catch(error => console.log('App initialization stopped prematurely:', error));
  }

  private showRootedWarning() {
    SplashScreen.hide();
    document.getElementById('rooted-devices').style.display = 'flex';
  }

  private waitForHydration() {
    return this.store.select('hydrated')
      .pipe(
        filter((hydrated) => hydrated === true),
        take(1) // a promise will not complete unless the observable completes
      )
      .toPromise();
  }

  private validateStateAfterHydration() {
    // check if the user is authenticated and we have a user
    return combineLatest([
      this.store.select(isAuthenticated),
      this.store.select(isUserLoaded),
      this.store.select(getCurrentUserProgram)
    ])
      .pipe(take(1))
      .toPromise()
      .then(([authenticated, userLoaded, userProgram]) => {

        // set the correct language
        let userLanguage = this.getBrowserLanguage();

        // override language if user opted for a different one
        if (userProgram && userProgram.language_code) {
          userLanguage = userProgram.language_code;
        }

        this.translateService.use(userLanguage);

        // make sure user is authenticated before dispatching any actions
        if (!authenticated) {
          return Promise.resolve(false);
        }

        // check if we need to load the user or just redirect to correct page
        if (!userLoaded) {
          this.loading.useLoadingObservable(
            this.store.select(isUserLoading),
            this.translateService.get('loading.loading_your_data')
          );

          this.store.dispatch(new accountActions.LoadUser());
        } else {
          // TODO: Remove this when everyone has upgrade to version 3.1.8+
          // here we test if bonus exercise alternative versions are setup
          // correctly. if there are any faulty bonus exercises we will remove
          // the faulty alternative_media_files from them and get the corrected
          // versions from the API
          this.store.select(checkBonusExerciseAlternatives)
            .pipe(take(1))
            .subscribe((exercises: Exercise[]) => {
              if (exercises.length > 0) {
                console.log('fixing faulty bonus exercises');
                this.store.dispatch(new AddData<Exercise>({
                  data: exercises,
                  schema: exerciseSchema
                }));

                this.store.dispatch(new SyncBonusExercises());
              }
            });

          console.log('Redirecting after login...');

          this.store.dispatch(new navigationActions.RedirectAfterLogin());
        }

        return Promise.resolve(true);
      });
  }

  private initializeApp() {
    // setup view detection
    this.attachResizeEvents();

    // fix keyboard issue
    this.attachKeyboardEvents();

    // TODO: move this inside service
    // TODO: Do we need to store this is state or just rely on events?
    // listen for network changes
    this.attachNeworkEvents();

    // setup app resume tracking
    this.attachAppResumeEvents();

    // initialize other services
    const services = [
      this.connectivity.initialize(),
      this.releaseService.initialize(),
      this.offlineQueueService.initialize(),
      this.cableService.initialize(),
      this.resumeService.initialize(),
      this.timeService.initialize(),
      this.deeplinksService.initialize(),
      this.analyticsService.initialize(),
      this.myCoachService.initialize()
    ];

    if (!this.config.isDevice) {
      services.push(this.gtmService.initialize());

      if (this.config.isBrightcoveWebEnabled()) {
        services.push(this.brightcoveWebPlayerLoaderService.initialize());
      } else {
        services.push(this.jwplayerService.initialize());
      }
    } else {
      if(this.config.isBrightcoveEnabled()) {
        services.push(this.brightcoveNativePlayerLoaderService.initialize());
      } else {
        services.push(this.jwplayerService.initialize());
      }
    }

    if (this.platform.is('mobileweb')) {
      services.push(this.getMobileAppService.initialize());
    }

    return Promise.all(services);
  }

  private attachResizeEvents() {
    window.addEventListener('resize', () => {
      this.config.resetViewHelpers();
    });
  }

  private attachKeyboardEvents() {
    const isDppOnboardingPhoneNumber = (activeElement) =>
      activeElement?.tagName?.toLowerCase() === 'input' && activeElement?.parentElement?.classList?.contains('onboarding-phone-number');

    // window is automatically resized, but for some reason the view is not scrolled
    const fixKeyboardViewOnShow = (info: KeyboardInfo) => {
      // skip textareas, they are handled separately in special cases like the community or goals in dpp onboarding
      // skip phone number field in dpp onboarding as well
      if (document.activeElement && document.activeElement['type'] !== 'textarea' && !isDppOnboardingPhoneNumber(document.activeElement)) {
        document.activeElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
      }
    };

    const fixKeyboardViewOnHide = () => {
      document.getElementsByTagName('ion-app')[0]['style']['height'] = '';
    };

    // fix scrolling issues when keyboard is displayed on mobile
    if (this.config.isDevice) {
      Keyboard.addListener('keyboardWillShow', (info) => fixKeyboardViewOnShow(info));

      // remove keyboard resize effects
      Keyboard.addListener('keyboardWillHide', () => fixKeyboardViewOnHide());
    }
  }

  private attachNeworkEvents() {
    // subscribe to network changes
    this.events.subscribe(this.config.events.connection + 'wifi-on', () => {
      console.log('WiFi detected!');
    });

    this.events.subscribe(this.config.events.unauthorized, () => {
      this.store.dispatch(new authActions.Logout());
    });
  }

  private attachAppResumeEvents() {
    this.platform.resume.pipe(
      // track app resumes when the user is logged in
      withLatestFrom(
        this.store.select(isAuthenticated),
        this.store.select(isFullSynced)
      ))
      .subscribe(async ([junk, fullSynced]) => {
        if (!fullSynced) {
          return false;
        }

        this.store.dispatch(new TrackActivity({
          kind: 'app_launch',
          activity_at: new Date(),
          name: this.config.isDevice ? 'mobile - resumed' : 'web - resumed'
        }));
      });
  }

  private enableServices() {
    // enable notifications click handling
    this.notificationsService.enableNotificationsHandling();

    // enable queue processing
    this.cravingToolService.setupCravingToolPopup();
    this.connectionHandlerService.tryQueue();

    combineLatest([
      this.store.select(isWelcomeTourViewed),
      this.store.select(isAuthenticated)
    ])
      .subscribe(
        ([welcomeTourViewed, userAuthenticated]) => {
          // maintenance message has to be shown after welcome screens & ATT modal (ios)
          // (btw these are not displayed on webapps)
          const welcomeTourCheck = this.config.isWebApp || (this.config.isDevice && welcomeTourViewed);

          if (!userAuthenticated && welcomeTourCheck) {
            this.maintenanceService.initialize();
          }
        });

    return Promise.resolve(true);
  }

  private hideWebSplashScreen() {
    // hide splash screen on first routing completed (or canceled/error)
    const splashScreenSubscription = this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe(event => {
        // only do this once when the app starts
        splashScreenSubscription.unsubscribe();

        // fade out and remove the #web-splash-screen
        const splash = document.getElementById('web-splash-screen');

        if (!splash) {
          return true;
        }

        splash.style.opacity = '0';

        setTimeout(() => {
          splash.remove();
        }, 1000);
      });
  }

  private getBrowserLanguage() {
    // fallback to default language
    let language = ClarityConfig.DEFAULT_LANGUAGE;

    try {
      // TODO: migration check that the  lang() alternative (globalization) works as expected
      // const browserLanguage = navigator.language || this.platform.lang();
      this.globalization.getPreferredLanguage()
        .then((lang) => {
          const browserLanguage: any = navigator.language || lang;

          if (browserLanguage) {
            // TODO: migrate - check split (was complaining)
            language = browserLanguage.split('-')[0];
          }

          // make sure we don't allow unsupported languages
          if (this.config.program.languages.indexOf(language) === -1) {
            language = ClarityConfig.DEFAULT_LANGUAGE;
          }
        })
        .catch(error => console.log(error));
    } catch {
      console.log('Cannot detect browser language');
    }

    return language;
  }

  private trackAllRouteChanges() {
    let previousUrl = '';

    const sanitize = (url = '') => url.replace(/^\/+/g, '').replace(/\/+$/g, '');

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(
        (from: NavigationEnd, to: NavigationEnd) => sanitize(from?.urlAfterRedirects) === sanitize(to?.urlAfterRedirects)
      )
    ).subscribe((event: NavigationEnd) => {
      const currentUrl = sanitize(event.urlAfterRedirects);

      this.analyticsService.trackEvent(AnalyticsEvents.PageView, {
        current_url: currentUrl,
        from_url: previousUrl
      });

      previousUrl = currentUrl;
    });
  }

  private preloadChunks() {
    // Toast Component is unable to load and render when the app is offline as it is lazy loaded,
    // therefore it cannot be used to display network connection errors unless its preloaded during app initialization.
    this.toastController.create({animated: false}).then(t => { t.present(); t.dismiss(); });
  }

}
