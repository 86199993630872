import { ChangeDetectorRef, Component, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { SettingsComponent } from './settings.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, Observable, Subject } from 'rxjs';
import { take , takeUntil, withLatestFrom } from 'rxjs/operators';
import { ConnectivityService } from '../../../services/connectivity.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { ClarityConfig } from '../../../config/clarity.config';
import { getProgramLanguages } from '../../../store/session/selectors/program.selectors';
import { getCurrentUserProgram } from '../../../store/normalized/selectors/user.selectors';
import { UpdateUserProgramLanguage } from '../../../store/session/actions/user-program.actions';
import { SessionState } from '../../../store/session/session.reducers';
import { isAuthenticating } from '../../../store/sensitive/selectors/auth.selectors';
import { State } from '../../../store/state.reducer';
import { hasSubtitlesEnabled, getSubtitlesLanguage } from '../../../store/persistent/media/media.selectors';
import { SetSubtitles } from '../../../store/persistent/media/media.actions';
import { scaleUpDown } from '@mindsciences/utils';
import { ErnContentProgram, UserProgram } from 'src/app/store/normalized/schemas/user.schema';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'cl-account-language',
  styleUrls: ['language.component.scss'],
  animations: [scaleUpDown],
  template: `
    <form [formGroup]="languageForm" autocomplete="off" (submit)="onSubmit()"
      [@state]="visibility" (@state.done)="animationDone($event)">

      <h3 class="top-header">{{'account_menu.language.app' | translate}}</h3>
      <ion-row>
        <cl-common-radio
          [options]="LANGUAGE_OPTIONS"
          formControlName="language_code"
          name="language_code">
        </cl-common-radio>
      </ion-row>

      <h3>{{'account_menu.language.subtitles' | translate}}</h3>
      <ion-row>
        <cl-common-radio
          [options]="SUBTITLES_OPTIONS"
          formControlName="subtitles_option"
          name="subtitles_option">
        </cl-common-radio>
      </ion-row>

      <ion-row class="action-button">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{'account_menu.language.save' | translate}}"
            [canClick]="languageForm.valid && !(authenticating$ | async)">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </form>
  `
})

export class LanguageComponent implements SettingsComponent, OnDestroy {
  @Output() endAnimation = new EventEmitter();
  visibility = 'visible';

  public authenticating$: Observable<boolean> = this.store.select(isAuthenticating);

  userProgram: UserProgram;
  languageForm: FormGroup;
  formChangeSubscription: Subscription;

  private currentSubtitleSetting: string;

  public LANGUAGE_OPTIONS = this.config.getLanguageOptions();
  public SUBTITLES_OPTIONS = [{value: 'off', label: 'common.off'}, ...this.LANGUAGE_OPTIONS];

  public readonly validations = {
    language_code: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.language_code_required'
      }
    },
    subtitles_option: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.language_code_required'
      }
    }
  };

  subtitlesSubscription: Subscription;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private sessionStore: Store<SessionState>,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private connectivity: ConnectivityService,
    private translate: TranslateService,
    private store: Store<State>,
    public alertController: AlertController,
    private config: ClarityConfig,
    private toastService: ToastService
  ) {
    this.subtitlesSubscription = this.sessionStore.select(hasSubtitlesEnabled)
      .pipe(
        withLatestFrom(this.store.select(getSubtitlesLanguage)),
        takeUntil(this.destroyed$)
      )
      .subscribe(([subtitlesEnabled, subtitlesLanguage]) => {
        this.currentSubtitleSetting = subtitlesEnabled ? subtitlesLanguage : 'off';
      });

    this.sessionStore.select(getCurrentUserProgram)
      .pipe(
        withLatestFrom(this.sessionStore.select(getProgramLanguages)),
        takeUntil(this.destroyed$)
      )
      .subscribe(([userProgram, programLanguages]) => {
        // remove any language that's not available on the backend
        this.LANGUAGE_OPTIONS = this.LANGUAGE_OPTIONS.filter((option) => programLanguages.indexOf(option.value) > -1);

        this.userProgram = userProgram;
        const formGroup = this.generateFormGroup(userProgram);

        this.languageForm = this.formBuilder.group(formGroup);
      });
  }

  generateFormGroup(userData) {
    const formGroupData = {
      language_code: {
        defaultValue: 'en'
      },
      subtitles_option: {
        defaultValue: this.currentSubtitleSetting
      }
    };

    const formGroup = {};

    Object.keys(formGroupData)
      .forEach((key) => {
        const data = formGroupData[key];
        const formValue = userData[key] || data.defaultValue;
        const formValidator = data.validators || this.validations[key].validators;
        formGroup[key] = new FormControl(formValue, formValidator);
      });

    return formGroup;
  }

  ngOnDestroy() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  async onSubmit() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    // we assign userData to toSubmit and override with the form data
    const toSubmit = {
      ...this.userProgram,
      ...this.languageForm.value
    };

    const {language_code: newLangCode} = this.languageForm.value;

    const langCodeTranslation = `auth.${newLangCode}`;

    if ([ErnContentProgram.WeightLoss, ErnContentProgram.MindfulEatingWLExtension].includes(this.userProgram?.user_program_info?.content_program)
      && this.userProgram.language_code !== 'es' && newLangCode === 'es') {

      const translations = this.translate.instant([
        'common.warning',
        'auth.weight_loss_language_warning.you_are_on_the_weight_loss_path',
        'auth.weight_loss_language_warning.we_are_sorry',
        'auth.weight_loss_language_warning.for_further_questions',
        'common.ok'
      ]);

      const alert = await this.alertController.create({
        header: translations['common.warning'],
        message: `${translations['auth.weight_loss_language_warning.you_are_on_the_weight_loss_path']}
          <br>
          <br>
          ${translations['auth.weight_loss_language_warning.we_are_sorry']}
          <br>
          <br>
          ${translations['auth.weight_loss_language_warning.for_further_questions']}`,
        buttons: [translations['common.ok']]
      });

      await alert.present();
      await alert.onDidDismiss();
    }

    if (this.userProgram.language_code !== newLangCode) {
      this.translate.get(
        ['auth.language', 'auth.are_you_sure_change_language', 'common.yes', 'common.cancel', langCodeTranslation]
      )
        .subscribe(async (translations) => {
          const alert = await this.alertController.create({
            header: translations['auth.language'],
            message: `${translations['auth.are_you_sure_change_language']}`,
            buttons: [
              {
                text: translations['common.cancel']
              },
              {
                text: translations['common.yes'],
                handler: () => {
                  this.saveSubtitlesSetting();
                  this.sessionStore.dispatch(new UpdateUserProgramLanguage(toSubmit));
                }
              }]
          });

          await alert.present();
        });
    } else {
      this.saveSubtitlesSetting();
    }
  }

  saveSubtitlesSetting() {
    const {subtitles_option: selectedSubtitlesOption} = this.languageForm.value;
    if (selectedSubtitlesOption) {
      this.sessionStore.dispatch(new SetSubtitles(selectedSubtitlesOption));
    } else {
      this.sessionStore.dispatch(new SetSubtitles(''));
    }

    this.toastService.translateConfirm('common.saved');
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }
}
