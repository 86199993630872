import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NrtProvider } from '../../../providers/nrt.provider';
import { SessionState } from '../session.reducers';
import { Store } from '@ngrx/store';
import * as nrtActions from '../actions/nrt.actions';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class NrtEffects {

   getAccess$ = createEffect(() => this.actions$.pipe(ofType(nrtActions.GET_ACCESS),
     switchMap((action: nrtActions.GetAccess) => this.nrtProvider.validateAccessRight()
       .pipe(
         map(response => {
           if (response && response.can_order) {
             return new nrtActions.GetAccessSuccess();
           }

           if (response && !response.can_order && response.last_order_at) {
             return new nrtActions.GetAccessFail({message: 'order_in_progress'});
           }

           return new nrtActions.GetAccessFail({message: 'unknown_error'});
         })
       )),
     catchError((error, caught) => {
       this.store.dispatch(new nrtActions.GetAccessFail(error));

       return caught;
     })
   ));

   constructor(
    private actions$: Actions,
    private nrtProvider: NrtProvider,
    private store: Store<SessionState>
   ) {
   }
}
