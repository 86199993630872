import {
  Component, EventEmitter, Input, Output
} from '@angular/core';

@Component({
  selector: 'cl-sso-login',
  styleUrls: ['sso-login.component.scss'],
  template: `
    <ion-button data-e2e="login-sso-button" type="button" (click)="doLogin()">
      <ion-label>{{ label | translate }}</ion-label>
    </ion-button>
  `
})
export class SSOLoginComponent {

  @Output() login = new EventEmitter();

  @Input() label = 'actions.login_with_sso';

  doLogin() {
    this.login.emit();
  }
}
