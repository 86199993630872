// persistent flags reducer

import * as onbardingActions from './onboarding.actions';

export interface OnboardingState {
  dashboard: boolean;
  lessons: boolean;
  exercises: boolean;
  community: boolean;
  journey: boolean;
  stresstest: boolean;
  checkin: boolean;
  stressmeter: boolean;
  introduceGears: boolean;
  account: boolean;
  awarenessQuiz: boolean;
  dppwlScale: boolean;
}

export const initialPersistentOnboardingState: OnboardingState = {
  dashboard: false,
  lessons: false,
  exercises: false,
  community: false,
  journey: false,
  stresstest: false,
  checkin: false,
  stressmeter: false,
  introduceGears: false,
  account: false,
  awarenessQuiz: false,
  dppwlScale: false
};

export function persistentOnboardingReducer(
  state = initialPersistentOnboardingState,
  action: onbardingActions.OnboardingActions
): OnboardingState {

  switch (action.type) {
    case onbardingActions.SET_ONBOARDING_COMPLETED: {
      const flag = action.payload;

      return {
        ...state,
        [flag]: true
      };
    }
    case onbardingActions.UNDO_ONBOARDING_COMPLETED: {
      const flag = action.payload;

      return {
        ...state,
        [flag]: false
      };
    }
  }

  return state;
}
