import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-simple-progress-bar',
  styleUrls: ['simple-progress-bar.component.scss'],
  template: `
    <p
      class="simple-progress-bar" [class.plain]="plain"
      [innerHTML]="'common.x_of_y' | translate:{current: current, total: steps}">
    </p>
  `
})
export class SimpleProgressBarComponent {
  @Input() steps = 1;
  @Input() current = 1;
  @Input() plain = false;
}
