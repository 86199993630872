import { getUserRemindersOrDefaults } from '../store/normalized/selectors/reminders.selectors';
import { Store } from '@ngrx/store';
import { getCurrentModule, LiveModule } from '../store/session/selectors/program.selectors';
import { UserReminder } from '../store/normalized/schemas/reminder.schema';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { SessionState } from '../store/session/session.reducers';
import * as moment from 'moment';
import * as navigationActions from '../store/session/actions/navigation.actions';
import * as accountActions from '../store/session/actions/account.actions';
import { State } from '../store/state.reducer';
import { take } from 'rxjs/operators';
import { ClarityConfig } from '../config/clarity.config';
import { SmokingTypeService } from './smoking-type.service';
import { TrackerService } from './tracker.service';
import { CravingToolService } from './wizards/craving-tool.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from './logger.service';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { CountByDay } from '../store/normalized/schemas/count-by-day.schema';
import {
  ActionPerformed,
  CancelOptions,
  LocalNotifications,
  LocalNotificationSchema,
  PendingLocalNotificationSchema,
  PendingResult
} from '@capacitor/local-notifications';
import { isAuthenticated } from '../store/sensitive/selectors/auth.selectors';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

export const CHECK_IN_TEXTS = [
  'how_is_your_day_going',
  'how_are_you_feeling_right_now?',
  'take_a_quick_mindfulness_exercise',
  'need_a_moment_to_ground_yourself',
  'what_is_happening_in_your_body_right_now',
  'lets_take_a_1_minute_break',
  'lost_on_autopilot',
  'stuck_in_your_head',
  'where_are_you_tense_in_your_body',
  'feeling_stuck',
  'time_to_build_your_awareness'
];

export const PAGES = {
  goal: 'app.goal',
  checkIn: 'app.check-in',
  nightReflection: 'app.night-reflection',
  morningMotivation: 'app.morning-motivation',
  morningStatsMotivator: 'app.quitting_pact',
  cravingTool: 'app.craving-tool'
};

// eslint-disable-next-line no-var
declare var cordova;

// TODO: Create separate service for each kind of notification

@Injectable({providedIn: 'root'})
export class NotificationsService {
  translations = {};
  reminders = {};

  private userReminders$: Observable<UserReminder[]> = this.sessionStore.select(getUserRemindersOrDefaults);
  private currentModule$: Observable<LiveModule> = this.store.select(getCurrentModule);
  private notifications: LocalNotificationSchema[] = [];
  private initializingComplete$ = new BehaviorSubject<boolean>(false);
  private goal = '';
  private resolveInitialized: any;
  private rejectInitialized: any;

  private initialized: Promise<any> = new Promise((resolve, reject) => {
    this.resolveInitialized = resolve;
    this.rejectInitialized = reject;
  });

  private notificationsIdSalt = 0; // will be incremented by 100 at every reset so ids don't overlap

  private readonly RESET_STALE_TIMEOUT = 30000; // 30 seconds
  private readonly RESET_RETRY_DELAY = 1000; // 1 second

  private resetTimeout: any;
  private resetStartedAt = null;

  private checkins: CountByDay[] = null;
  private hasPermissionsAtStart = false;

  constructor(
    private platform: Platform,
    private sessionStore: Store<SessionState>,
    private store: Store<State>,
    private config: ClarityConfig,
    private trackerService: TrackerService,
    private cravingToolService: CravingToolService,
    private translate: TranslateService,
    private logger: LoggerService,
    private smokingTypeService: SmokingTypeService,
    private navCtrl: NavController,
    private analyticsService: AnalyticsService
  ) {
  }

  public initialize() {
    this._initialize()
      .then((result) => {
        this.resolveInitialized(result);
      })
      .catch((error) => {
        this.rejectInitialized(error);
      });

    return this.initialized;
  }

  private setTranslations() {
    return new Promise((resolve, reject) => {
      forkJoin([
        this.translate.get('notifications'),
        this.translate.get('reminders')
      ])
        .toPromise()
        .then(([notifications, reminders]) => {
          this.translations = notifications;
          this.reminders = reminders;

          resolve(true);
        });
    });
  }

  public enableNotificationsHandling() {
    this.initializingComplete$.next(true);
  }

  public async hasPermission() {
    if (!this.platformSupported()) {
      return Promise.resolve(true);
    }

    const status = await LocalNotifications.checkPermissions();

    console.log('LocalNotifications hasPermission value:', status.display);

    return status.display === 'granted';
  }

  public isBatteryOptimizationIgnored(): Promise<boolean> {
    if (!this.platformSupported() || !this.config.isAndroid) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      cordova.plugins.DozeOptimize.IsIgnoringBatteryOptimizations(
        (responce) => {
          console.log('IsIgnoringBatteryOptimizations: ' + responce);

          if (responce === 'false') {
            resolve(false);
          } else {
            resolve(true);
          }
        },
        (error) => {
          console.error('BatteryOptimizations IsIgnoring Error' + error);
          resolve(true);
        });
    });
  }

  public openNativeAppSettings() {
    let result: Promise<{ status: boolean }>;

    if (this.config.isAndroid) {
      result = NativeSettings.openAndroid({
        option: AndroidSettings.ApplicationDetails
      });
    } else if (this.config.isIos) {
      result = NativeSettings.openIOS({
        option: IOSSettings.App
      });
    }

    return result.then((value) => value.status);
  }

  public requestNotificationsPermission() {
    if (!this.platformSupported()) {
      return Promise.resolve(false);
    }


    // on Android the request permission doesn't work, so we'll send the user to Settings and then ask for battery optimization
    if (this.config.isAndroid) {

      return new Promise(resolve =>
        LocalNotifications.checkPermissions()
          .then(async (permissionStatus) => {

            const status = {
              granted: permissionStatus.display === 'granted'
            };

            if (!status.granted) {
              status.granted = await this.openNativeAppSettings();
            }

            this.requestBatteryOptimizationIgnore()
              .finally(() => resolve(status));
          })
      );
    }

    // on ios request permissions first and then double check battery optimization (not supported anyway)
    else {

      return new Promise(resolve =>
        LocalNotifications.requestPermissions()
          .then((permissionStatus) => this.requestBatteryOptimizationIgnore()
            .finally(() => resolve({ granted: permissionStatus.display === 'granted'}))
          )
      );
    }
  }

  public requestBatteryOptimizationIgnore(): Promise<boolean> {
    if (!this.config.isAndroid) {
      return Promise.resolve(true);
    }

    return new Promise((resolve, reject) => {
      cordova.plugins.DozeOptimize.RequestOptimizations(
        (res) => {
          console.log('BatteryOptimizations Request' + res);
          resolve(res);
        },
        (error) => {
          console.error('BatteryOptimizations Request Error' + error);
          resolve(true);
        }
      );
    });
  }

  // no longer needed?!
  // setNotificationsSubscription(): void {
  //   combineLatest([this.currentModule$, this.userReminders$])
  //     .subscribe(([currentModule, userReminders]) => {
  //       console.log('Set notifications subscription fired!');
  //       if (currentModule && currentModule.record) {
  //         this.setGoal(currentModule.record.goal);
  //       }
  //
  //       if (userReminders && userReminders.length > 0) {
  //         this.checkAndSetNotifications(userReminders);
  //       }
  //     });
  // }

  private scheduleNotificationsReset() {

    if (this.resetStartedAt && this.resetStartedAt > new Date().getTime() - this.RESET_STALE_TIMEOUT) {
      console.log('Notifications reset in progress, cannot re-schedule yet!');

      return false;
    }

    this.resetStartedAt = new Date().getTime();

    this._resetNotifications()
      .then(() => {
        console.log('Notifications reset completed!');
      })
      .catch(() => {
        console.log('Notifications reset went wrong!');
      })
      // CLARITY-589 otherwise timeoutFunc will remain in an infinite loop and we don't want that
      .finally(() => this.resetStartedAt = null);

    console.log('Scheduled notifications reset!');
  }

  public resetNotifications() {

    const timeoutFunc = () => {
      console.log('Attempting notifications reset schedule...');

      // while a reset is still in progress, keep retrying
      if (this.resetStartedAt) {
        this.resetTimeout = setTimeout(timeoutFunc, this.RESET_RETRY_DELAY);

        return false;
      }

      // coast is clear, remove lock
      clearTimeout(this.resetTimeout);
      this.resetTimeout = null;

      this.scheduleNotificationsReset();
    };

    // init
    if (!this.resetTimeout) {
      timeoutFunc();
    }
  }

  private listenOnClickNotification(): void {
    if (!this.platformSupported()) {
      return;
    }

    LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction: ActionPerformed) => {
      setTimeout(() => {
        this._handleNotificationTap(notificationAction);
      }, 50);
    });
  }

  private setGoal(goal: string): void {
    this.goal = goal;
  }

  private checkAndSetNotifications(userReminders: UserReminder[]) {
    if (!this.platformSupported()) {
      return Promise.resolve();
    }

    return this.hasPermission()
      .then((hasPermission) => {
        if (hasPermission) {
          return this.setNotifications(userReminders);
        }
      });
  }

  public cancelAllNotifications() {
    LocalNotifications.cancel({notifications: this.notifications});
  }

  private setNotifications(userReminders: UserReminder[]) {
    const goalReminders = userReminders.find(reminder => reminder.tag === 'goal');
    const checkInReminders = userReminders.find(reminder => reminder.tag === 'check_in');
    const nightReflectionReminders = userReminders.find(reminder => reminder.tag === 'night_reflection');
    const morningMotivationReminders = userReminders.find(reminder => reminder.tag === 'morning_motivation');
    const morningStatsMotivatorReminders = userReminders.find(reminder => reminder.tag === 'motivation');

    this.notifications = [];

    if (goalReminders && goalReminders.enabled) {
      this.setGoalNotifications(goalReminders);
    }
    if (checkInReminders && checkInReminders.enabled) {
      const morningMotivationRemindersCount = morningMotivationReminders && morningMotivationReminders.daily_count
        ? morningMotivationReminders.daily_count
        : 0;

      this.setCheckInNotifications(checkInReminders, morningMotivationRemindersCount);
    }
    if (nightReflectionReminders && nightReflectionReminders.enabled) {
      this.setNightReflectionNotifications(nightReflectionReminders);
    }
    if (morningMotivationReminders && morningMotivationReminders.enabled) {
      this.setMorningMotivationNotifications(morningMotivationReminders);
    }

    const promises: Promise<any>[] = [Promise.resolve(true)];

    if (morningStatsMotivatorReminders && morningStatsMotivatorReminders.enabled) {
      promises.push(this.setMorningStatsMotivatorNotifications(morningStatsMotivatorReminders));
    }

    return Promise.all(promises)
      .then(() => {
        console.log('Clearing notifications...');

        return this.clearAllReminders()
          .then(() => new Promise((resolve, reject) => {
            setTimeout(() => {
              console.log('Scheduling notifications...');

              // CLARITY-223 - notifications with trigger date in the past could cause application crash
              const now = moment();
              const notifications = this.notifications.filter((notification) => now.isBefore(notification.schedule.at));

              LocalNotifications.schedule({notifications})
                .then(resolve)
                .catch(error => {
                  this.logger.error('Local notifications schedule', error);
                  reject(error);
                }); // CLARITY-589 catch that error
            }, 100);
          }));
      });
  }

  private clearAllReminders() {
    return this.clearAllNotificationsWith({reminder: true});
  }

  private setGoalNotifications(reminder: UserReminder): void {
    const firstNotification: string = reminder.first_at;
    const lastNotification: string = reminder.last_before;

    this.getReminderDates(reminder)
      .forEach(reminderDate => {
        const notificationsPerDay: number = reminder.daily_count;
        const day = new Date(reminderDate.toDate());
        const firstNotificationDate = this.getDateFromHoursMinutes(day, firstNotification);
        const lastNotificationDate = this.getDateFromHoursMinutes(day, lastNotification);
        const incrementValue = (lastNotificationDate.getTime() - firstNotificationDate.getTime()) / notificationsPerDay;
        const notificationDates = this.getRandomSpacedDates(
          firstNotificationDate,
          lastNotificationDate,
          incrementValue,
          notificationsPerDay
        );

        for (let i = 0; i < notificationsPerDay; i++) {
          const title = this.translations['remember_your_goal_today'];
          const text = this.goal || this.translations['remember_your_goal_today_default_body'];
          const date = notificationDates[i];
          const navigateTo = PAGES.goal;
          const notification = this.getNotification(title, text, date, navigateTo, true, 'goal');

          this.addNotification(notification);
        }
      });
  }

  public scheduleCravingToolNotification(options: { inMinutes: number }) {
    if (!this.platformSupported()) {
      return;
    }

    if (!options) {
      return;
    }

    const title = this.translations['craving_tool'];
    const text = this.translations['lets_continue_craving_exercise'];
    const navigateTo = PAGES.cravingTool;
    const date = moment()
      .add(options.inMinutes, 'minutes')
      .toDate();

    const notification: LocalNotificationSchema = this.getNotification(title, text, date, navigateTo, false, 'craving_tool');
    notification.id = this.getRandomId();

    return this.clearCravingToolNotifications()
      .then(() => LocalNotifications.schedule({notifications: [notification]})
        .catch(error => this.logger.error('Schedule Local Notifications after clearCravingToolNotifications', error)));
  }

  private parseData(data: any) {
    let parsedData = data;
    while (typeof parsedData === 'string') {
      try {
        parsedData = JSON.parse(parsedData);
      } catch (error) {
        return null;
      }
    }

    return parsedData;
  }

  private clearAllNotificationsWith(options: { reminder: boolean }) {
    if (!this.platformSupported()) {
      return Promise.resolve([]);
    }

    return LocalNotifications.getPending()
      .then((pendingResult: PendingResult) => {
        if (pendingResult.notifications?.length === 0) {
          return Promise.resolve([]);
        }

        return Promise.all(pendingResult.notifications.map((notification: PendingLocalNotificationSchema) => {
          if (!notification || !notification.extra) {
            return Promise.resolve();
          }

          const data = this.parseData(notification.extra);

          // if there were problems parsing the data we do nothing
          if (!data) {
            return Promise.resolve();
          }

          const cancelOptions: CancelOptions = { notifications: [{ id: notification.id }] };

          if (options.reminder) {
            if (data.reminder) {
              return LocalNotifications.cancel(cancelOptions);
            }
            // backwards compatibility for old reminders with no data.reminder setup
            if (data.reminder === undefined) {
              return LocalNotifications.cancel(cancelOptions);
            }
          }

          if (!options.reminder && data.reminder === false) {
            // here we need reminder to be false, not undefined
            // so we dont clear old reminders with no data.reminder
            return LocalNotifications.cancel(cancelOptions);
          }

          return Promise.resolve();
        }));
      })
      .catch(error => {
        this.logger.error('Error Clearing All Notifications With', error);

        return Promise.resolve();
      });
  }

  public clearCravingToolNotifications() {
    return this.clearAllNotificationsWith({reminder: false});
  }

  private setCheckInNotifications(reminder: UserReminder, morningMotivationRemindersCount = 0): void {
    const firstNotification: string = reminder.first_at;
    const lastNotification: string = reminder.last_before;

    this.getReminderDates(reminder)
      .forEach(reminderDate => {
        const notifications = this.generateCheckinNotificationsForToday(reminder, reminderDate);

        let notificationsPerDay = notifications.notifications_count;

        // adjust notificationsPerDay if morning motivation reminder is eanbled, and do not substract that from remaining checkins count
        if (morningMotivationRemindersCount) {
          notificationsPerDay = Math.min(reminder.daily_count, notificationsPerDay + morningMotivationRemindersCount);
        }

        const isToday = notifications.today;
        const day = notifications.notification_day;

        const firstNotificationDate = this.getDateFromHoursMinutes(day, firstNotification);
        const lastNotificationDate = this.getDateFromHoursMinutes(day, lastNotification);
        const incrementValue = (lastNotificationDate.getTime() - firstNotificationDate.getTime()) / notificationsPerDay;

        let notificationDates = this.getRandomSpacedDates(
          firstNotificationDate,
          lastNotificationDate,
          incrementValue,
          notificationsPerDay,
          isToday
        );

        notificationDates = notificationDates.sort((a, b) => {
          const aDate = moment(a)
            .valueOf();
          const bDate = moment(b)
            .valueOf();

          return aDate - bDate;
        });

        const checkInTexts = [...CHECK_IN_TEXTS];

        for (let i = 0; i < notificationsPerDay; i++) {
          const randomTextPosition = this.getRandom(0, checkInTexts.length - 1);
          const checkinText = checkInTexts.splice(randomTextPosition, 1);
          const title = this.reminders['check_in'];
          const text = this.translations['checkin_texts'][checkinText];
          const date = notificationDates[i];
          const navigateTo = PAGES.checkIn;
          const notification = this.getNotification(title, text, date, navigateTo, true,  'check_in');

          this.addNotification(notification);
        }
      });
  }

  private setNightReflectionNotifications(reminder: UserReminder): void {
    const notificationsPerDay: number = reminder.daily_count;
    const notificationHoursMinutes: string = reminder.first_at;

    this.getReminderDates(reminder)
      .forEach(reminderDate => {
        const day = new Date(reminderDate.toDate());
        const date = this.getDateFromHoursMinutes(day, notificationHoursMinutes);

        // TODO: Move translations to en.json
        for (let i = 0; i < notificationsPerDay; i++) {
          const title = this.translations['how_did_you_do_today'];
          const text = this.translations['lets_take_a_moment_to_reflect_together'];
          const navigateTo = PAGES.nightReflection;
          const notification = this.getNotification(title, text, date, navigateTo, true, 'night_reflexion');

          this.addNotification(notification);
        }
      });
  }

  private setMorningMotivationNotifications(reminder: UserReminder): void {
    const notificationsPerDay: number = reminder.daily_count;
    const notificationHoursMinutes: string = reminder.first_at;

    this.getReminderDates(reminder)
      .forEach(reminderDate => {
        const day = new Date(reminderDate.toDate());
        const date = this.getDateFromHoursMinutes(day, notificationHoursMinutes);

        // TODO: Move translations to en.json
        for (let i = 0; i < notificationsPerDay; i++) {
          const title = this.translations['good_morning'];
          const text = this.translations['lets_start_the_day_together'];
          const navigateTo = PAGES.morningMotivation;
          const notification = this.getNotification(title, text, date, navigateTo, true, 'morning_motivation');

          this.addNotification(notification);
        }
      });
  }

  private setMorningStatsMotivatorNotifications(reminder: UserReminder) {
    const notificationsPerDay: number = reminder.daily_count;
    const notificationHoursMinutes: string = reminder.first_at;

    let result = Promise.resolve();

    this.getReminderDates(reminder)
      .map(reminderDate => {
        const day = new Date(reminderDate.toDate());
        const date = this.getDateFromHoursMinutes(day, notificationHoursMinutes);

        return this.trackerService.calculateToDate(day)
          .then((calculations) => {
            const {savings, canSmoke} = calculations;
            // TODO: Move translations to en.json
            for (let i = 0; i < notificationsPerDay; i++) {
              const canSmokeText = canSmoke.toFixed();

              const programType = this.smokingTypeService.isCigarette() ? 'smoking' : 'vaping';
              const form = canSmoke !== 1 ? 'plural' : 'singular';
              const messageTranslatePath = `notifications.suggest_to_${programType}.${form}`;
              const suggestMessage = this.translate.instant(messageTranslatePath, {count: canSmokeText});

              const title = this.translations['good_morning'];

              const savingsText = savings.toFixed(1);

              const youHaveSavedText = this.translations['you_have_saved'];
              const soFarText = this.translations['so_far'];
              const text = savings > 0 && this.smokingTypeService.isCigarette()
                ? `${suggestMessage} ${youHaveSavedText} \$${savingsText} ${soFarText}`
                : suggestMessage;
              const navigateTo = PAGES.morningStatsMotivator;
              const notification = this.getNotification(title, text, date, navigateTo, true, 'motivation_ctq');

              this.addNotification(notification);
            }
          });
      })
      .forEach((task: any) => {
        result = result.then(task);
      });

    return result;
  }

  public cancelNotifications(): void {
    if (!this.platformSupported()) {
      return;
    }

    LocalNotifications.getPending()
      .then((pendingResult: PendingResult) => {
        if (pendingResult.notifications?.length === 0) {
          return;
        }

        const cancelOptions: CancelOptions = { notifications: pendingResult.notifications };
        LocalNotifications.cancel(cancelOptions);
      });
  }

  private getNotification(title: string, body: string, at: Date, navigateTo: string, reminder, name): LocalNotificationSchema {
    const id = this.getRandomId();
    const largeIcon = this.config.isAndroid ? 'ic_launcher_foreground' : 'icon.png';
    const smallIcon = this.config.isAndroid ? 'ic_action_name_light' : 'icon.png';
    const notification: LocalNotificationSchema = {
      id,
      title,
      body,
      largeIcon,
      smallIcon,
      sound: 'default',
      schedule: {
        at,
        allowWhileIdle: true
      },
      extra: {
        navigateTo,
        reminder,
        name
      }
    };

    return notification;
  }

  private getDateFromHoursMinutes(day: Date, hoursMinutes: string): Date {
    const hours = Number(hoursMinutes.split(':')[0]);
    const minutes = Number(hoursMinutes.split(':')[1]);
    const date: Date = new Date(day);
    date.setHours(hours);
    date.setMinutes(minutes);

    return date;
  }

  private getReminderDates(reminder: UserReminder): moment.Moment[] {
    const {day_sun, day_mon, day_tue, day_wed, day_thu, day_fri, day_sat} = reminder;
    const weekDaysRequired: boolean[] = [day_sun, day_mon, day_tue, day_wed, day_thu, day_fri, day_sat];
    const reminderDates: moment.Moment[] = [];
    const today: moment.Moment = moment();

    for (let i = 0; i < 7; i++) {
      if (weekDaysRequired[today.day()]) {
        reminderDates.push(today.clone());
      }

      today.add(1, 'days');
    }

    return reminderDates;
  }

  private getRandomId(): number {
    return this.getRandom(0, 1000) + this.notificationsIdSalt;
  }

  private getRandom(from: number, to: number): number {
    return Math.floor(Math.random() * (to - from + 1) + from);
  }

  private getRandomSpacedDates(from: Date, to: Date, increment: number, amount: number, isToday = false): Date[] {
    const fromTime: number = from.getTime();

    // console.log('randomly spacing notifications...', from, to, increment, amount, isToday);

    // if (isToday) {
    //   const now = moment(fromTime)
    //     .diff(moment(), 'minutes');
    //
    //   if (now <= 0) {
    //     fromTime = new Date()
    //       .getTime();
    //   }
    // }

    // generate randomly spaced notifications incrementally
    // -- we split the whole time spectrum in intervals and force a minimum of half interval between 2 consecutive notifications

    // step0 - get increment = max gap between 2 notifications (total time in a day interval split by number of notifications per day)
    // step1 - force first notification between start time and start time + first increment
    // step2 - calculate next max interval = start time + half increment + full increment multiplied by current notifications count
    // step3 - place next notification between previous and next interval with min gap of half increment
    // repeat steps 2 and 3

    const dates: Date[] = [new Date(this.getRandom(fromTime, fromTime + increment))];

    let date: any = null;
    let last = dates[0].getTime();
    let nextInterval: any = null;

    // console.log('calculating new random', dates, fromTime, toTime, increment);

    for (let x = 0; x < amount - 1; x++) {
      nextInterval = fromTime + increment / 2 + increment * (x + 1);

      do {
        date = new Date(this.getRandom(last, nextInterval));
        // console.log('random!', last, nextInterval, Math.abs(date.getTime() - dates[x].getTime()));
      } while (Math.abs(date.getTime() - dates[x].getTime()) < increment / 2);

      last = date.getTime();

      dates.push(date);
    }

    // console.log('spaced out!', dates);

    return dates;
  }

  private checkAuthThenNavigateTo(page: string) {
    this.store.select(isAuthenticated).pipe(take(1))
      .subscribe(userAuthenticated => {
        if (!userAuthenticated) {
          console.log('User not authenticated. Navigating from notification to login');

          this.navCtrl.navigateRoot('login', { animated: true });
        } else {
          this.navigateTo(page);
        }
      });
  }

  private navigateTo(page: string): void {
    console.log('Navigating from notification to', page);
    if (page === PAGES.goal && this.config.program.programCode === 'ctq') {
      this.store.dispatch(new navigationActions.GotoHome());
    }
    if (page === PAGES.goal && this.config.program.programCode !== 'ctq') {
      this.store.dispatch(new navigationActions.GotoDashboard());
    }
    if (page === PAGES.morningStatsMotivator) {
      this.store.dispatch(new navigationActions.RootGoTo({ route: 'tabs/account/quit' }));
      setTimeout(() => this.sessionStore.dispatch(new accountActions.SetSelectedSubMenu('goals_ctq')), 1000);
    }
    if (page === PAGES.checkIn) {
      this.store.dispatch(new navigationActions.OpenWizard({type: 'checkin', userReferrer: 'notification'}));
    }
    if (page === PAGES.nightReflection) {
      this.store.dispatch(new navigationActions.OpenWizard({type: 'night_reflection'}));
    }
    if (page === PAGES.morningMotivation) {
      this.store.dispatch(new navigationActions.OpenWizard({type: 'morning_motivation'}));
    }
    if (page === PAGES.cravingTool) {
      this.cravingToolService.openCravingTool({fromNotification: true});
    }
  }

  private _resetNotifications() {
    this.notificationsIdSalt = this.notificationsIdSalt + 100;

    return this.initialized.then(() => combineLatest([this.currentModule$, this.userReminders$])
      .pipe(take(1))
      .toPromise()
      .then(([currentModule, userReminders]) => {

        if (currentModule && currentModule.record) {
          this.setGoal(currentModule.record.goal);
        }

        if (userReminders && userReminders.length > 0) {
          return this.checkAndSetNotifications(userReminders);
        }
      }));
  }

  private _handleNotificationTap(notificationAction: ActionPerformed) {
    const isInitialized = this.initializingComplete$.getValue();

    const { notification } = notificationAction;
    console.log('Notification clicked', notification, isInitialized);

    // Sometimes the notification will come with data undecoded and the navigation will not be triggered
    if (typeof notification.extra === 'string') {
      notification.extra = this.parseData(notification.extra);
    }

    if (!notification.extra || !notification.extra.navigateTo) {
      this.logger.info('Empty notification.data found!', notification);
    }

    if(notification.extra?.name) {
      this.analyticsService.trackEvent(AnalyticsEvents.NotificationOpened,{
        name: notification.extra.name,
        title: notification.title,
        body: notification.body,
        schedule: notification.schedule.at,
        type: 'local'
      });
    }

    // make sure we navigate to the right place after everything else is initialized
    if (isInitialized && notification.extra) {
      this.checkAuthThenNavigateTo(notification.extra.navigateTo);
    } else {
      this.initializingComplete$.subscribe((initialized) => {
        if (initialized && notification.extra) {
          this.checkAuthThenNavigateTo(notification.extra.navigateTo);
        }
      });
    }
  }

  private _initialize(): Promise<any> {
    // this.setNotificationsSubscription();

    this.listenOnClickNotification();

    // TODO: Check if the notifications are also updated when this happens - i.e. program, etc. are refreshed anyway
    // make sure the notifications are updated when the language changes
    this.translate.onLangChange.subscribe(() => {
      this.setTranslations();
    });

    this.hasPermission()
      .then((hasPermissions) => this.hasPermissionsAtStart = hasPermissions);

    // schedule notifications if user was sent to Notifications settings, allowed notifications and returned
    this.platform.resume.subscribe(() => {
      this.hasPermission()
        .then((hasPermission) => {
          if (hasPermission && !this.hasPermissionsAtStart) {
            this.hasPermissionsAtStart = true;

            this.resetNotifications();

            if (this.config.isAndroid) {
              this.requestBatteryOptimizationIgnore();
            }
          }
        });
    });

    return this.setTranslations();
  }

  private generateCheckinNotificationsForToday(reminder, reminderDate) {
    // const firstNotification: string = reminder.first_at;
    // const lastNotification: string = reminder.last_before;

    const notificationsPerDay: number = reminder.daily_count;

    // let todayCheckinsCount = 0;

    // if (this.checkins) {
    //   const datePipe = new DatePipe('en-US');
    //
    //   const todaysCheckins = this.checkins.find((checkins) => {
    //     return checkins.date === datePipe.transform(new Date(), 'yyyy-MM-dd');
    //   });
    //
    //   if (todaysCheckins) {
    //     todayCheckinsCount = todaysCheckins.count;
    //   }
    // }

    const day = new Date(reminderDate.toDate());
    const isToday = moment()
      .startOf('day')
      .diff(
        moment(reminderDate)
          .startOf('day'), 'days') === 0 ? true : false;

    // substract today's checkins from total count since user has already done those
    // if (isToday) {
    //   // this is not needed since we already count checkins -- this was useful only when we were not counting them
    //   // const firstNotification2 = this.getDateFromHoursMinutes(day, firstNotification);
    //   // const lastNotification2 = this.getDateFromHoursMinutes(day, lastNotification);
    //
    //   // const hoursBetweenFirstAndLast = moment(firstNotification2)
    //   //   .diff(moment(lastNotification2), 'hours');
    //   // const todaysLeftHours = moment()
    //   //   .diff(moment(lastNotification2), 'hours');
    //
    //   // notificationsPerDay = Math.floor((todaysLeftHours * reminder.daily_count) / hoursBetweenFirstAndLast);
    //
    //   // console.log('notifications per day', notificationsPerDay);
    //   // console.log('checkins for today', todayCheckinsCount);
    //
    //   notificationsPerDay = todayCheckinsCount < notificationsPerDay ? notificationsPerDay - todayCheckinsCount : 1;
    // }

    return {today: isToday, notifications_count: notificationsPerDay, notification_day: day};
  }

  private platformSupported() {
    if (!this.platform.is('cordova') || this.config.runningOnIonicDevApp) {
      return false;
    }

    const {device_version} = this.config.env.device;

    if (this.config.isIos && device_version.startsWith('9')) {
      return false;
    }

    return true;
  }

  // CLARITY-700 Unable to make notification. Generate an error if a key is missing
  private addNotification(notification: LocalNotificationSchema) {
    if(notification.title && notification.body) {
      this.notifications.push(notification);
    } else {
      this.logger.error('Missing local notifications title or body', notification);
    }
  }
}
