import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { State } from '../../store/state.reducer';
import { LoggerService } from '../logger.service';

@Injectable({providedIn: 'root'})
export class WizardService {

  constructor(
    protected store: Store<State>,
    protected popoverCtrl: PopoverController,
    protected translate: TranslateService,
    protected logger: LoggerService
  ) {

  }
}
