<cl-waves-background footer-bg bg-position-bottom>
  <ion-content #ionContent>
    <cl-header-nav-buttons
      class="close-button"
      [backButton]="false"
      (click)="closeModal()">
    </cl-header-nav-buttons>

    <form [formGroup]="form" *ngIf="{ selectedSlide: selectedSlideNumber$ | async } as asyncs">
      <swiper
        #swiper
        [allowSlideNext]="false"
        [autoHeight]="true"
        [speed]="200"
        [resizeObserver]="true"
        effect="fade"
        [allowTouchMove]="false"
        [class.keyboard-open]="keyboardOpen | async"
      >
        <ng-template swiperSlide>
          <section>
            <div class="content"><ng-container *ngTemplateOutlet="subscription"></ng-container></div>
            <div class="footer-placeholder"></div>
          </section>
        </ng-template>
        <ng-template swiperSlide>
          <section>
            <div class="content"><ng-container *ngTemplateOutlet="terms"></ng-container></div>
              <div class="footer-placeholder"></div>
          </section>
        </ng-template>
        <ng-template swiperSlide>
          <section>
            <div class="content"><ng-container *ngTemplateOutlet="enrollment"></ng-container></div>
              <div class="footer-placeholder"></div>
          </section>
        </ng-template>
        <ng-template swiperSlide>
          <section>
            <div class="content"><ng-container *ngTemplateOutlet="password"></ng-container></div>
              <div class="footer-placeholder"></div>
          </section>
        </ng-template>
      </swiper>
    </form>
  </ion-content>
</cl-waves-background>

<!-- footer -->
<div *ngIf="{selectedSlide: selectedSlideNumber$ | async,isSlideScrolledToBottom: isSlideScrolledToBottom$ | async} as asyncs" class="controls-holder">
  <ng-container *ngIf="asyncs.selectedSlide === 0">
    <ng-container *ngTemplateOutlet="bottomButtons; context: { step: 'subscription' }"></ng-container>
  </ng-container>
  <ng-container *ngIf="asyncs.selectedSlide === 1">
    <ng-container *ngTemplateOutlet="bottomButtons; context: { step: 'terms' }"></ng-container>
  </ng-container>
  <ng-container *ngIf="asyncs.selectedSlide === 2">
    <ng-container *ngTemplateOutlet="bottomButtons; context: { step: 'enrollment' }"></ng-container>
  </ng-container>
  <ng-container *ngIf="asyncs.selectedSlide === 3">
    <ng-container *ngTemplateOutlet="bottomButtons; context: { step: 'password', label: 'common.confirm' }"></ng-container>
  </ng-container>
</div>

<!-- slide 1: Itunes / Stripe / Google subscription -->
<ng-template #subscription>
  <div class="title-holder">
    <h2>{{ 'account_deletion.subscription.title'| translate }}</h2>
  </div>

  <ng-container *ngIf="subscriptionType === 'googleplay'">
    <p class="bold">{{ 'account_deletion.subscription.google_subtitle' | translate }}</p>
    <p>{{ 'account_deletion.subscription.unable_to_do_it_behalf' | translate}}</p>
    <p class="orange-link" (click)="onViewSubscription()">{{ 'account_deletion.subscription.manage_subscription' | translate}}</p>
    <p>{{ 'account_deletion.subscription.try_again_later' | translate}}</p>
  </ng-container>
  <ng-container *ngIf="subscriptionType === 'itunes'">
    <p class="bold">{{ 'account_deletion.subscription.apple_subtitle' | translate }}</p>
    <p>{{ 'account_deletion.subscription.unable_to_do_it_behalf' | translate}}</p>
    <p class="orange-link" (click)="onViewSubscription()">{{ 'account_deletion.subscription.manage_subscription' | translate}}</p>
    <p class="already-canceled">{{ 'account_deletion.subscription.try_again_later' | translate}}</p>
  </ng-container>
  <ng-container *ngIf="subscriptionType === 'stripe'">
    <p>{{ 'account_deletion.subscription.refund_deleted_account' | translate}}</p>
    <p>
      <span>{{ 'account_deletion.subscription.request_refund' | translate}}</span>
      <span class="link" (click)="openHelpCenter()">{{ 'account_deletion.subscription.contact_support' | translate}}</span>
      <span class="already-canceled">{{ 'account_deletion.subscription.before_deleting_account' | translate}}</span>
    </p>
  </ng-container>
</ng-template>

<!-- slide 2 : terms validation -->
<ng-template #terms>
  <div class="title-holder">
    <h2>{{ 'account_deletion.confirmation.title'| translate }}</h2>
  </div>

  <h3 >{{ 'account_deletion.confirmation.deleting_irreversible' | translate}}</h3>

  <p class="tight bold small">{{'account_deletion.confirmation.no_access' | translate}}</p>
  <ul>
    <li class="bold">{{'account_deletion.confirmation.list.lessons' | translate}}</li>
    <li class="bold">{{'account_deletion.confirmation.list.community' | translate}}</li>
    <li class="bold">{{'account_deletion.confirmation.list.progress' | translate}}</li>
    <li class="bold">{{'account_deletion.confirmation.list.stats' | translate}}</li>
    <li class="bold">{{'account_deletion.confirmation.list.favorites' | translate}}</li>
    <li class="bold">{{'account_deletion.confirmation.list.more' | translate}}</li>
  </ul>

  <ion-item no-lines class="ion-no-padding">
    <ion-item lines="none" class="other-option-item ion-no-padding" >
      <ion-label>{{ 'account_deletion.confirmation.understand' | translate}}</ion-label>
      <ion-checkbox class="light" mode="android" slot="start" [formControl]="form.get('terms')"></ion-checkbox>
    </ion-item>
  </ion-item>
</ng-template>

<ng-template #enrollment>
  <div class="title-holder">
    <h2>{{ 'account_deletion.enrollment.title' | translate }}</h2>
  </div>

    <h3>{{ 'account_deletion.enrollment.multiple_programs' | translate }}</h3>

    <p class="small">{{ 'account_deletion.enrollment.multiple_programs_details' | translate}}</p>

    <div class="enrollment-checkbox-container">
      <ion-item lines="none" class="other-option-item ion-no-padding"  *ngFor="let userProgram of userPrograms">
        <ion-label>{{ userProgram.name}}</ion-label>
        <ion-checkbox class="light" mode="android" slot="start" [formControl]="form.get('enrollment').get(userProgram.code)"></ion-checkbox>
      </ion-item>
    </div>

    <p class="small">
      {{ 'account_deletion.enrollment.contact' | translate}}
      <span (click)="openHelpCenter()" class="link">{{ 'account_deletion.enrollment.customer_support' | translate}}</span>
      {{ 'account_deletion.enrollment.contact_end' | translate : {appName: config.program.name } }}
    </p>
</ng-template>

<!-- step 3 password confirmation -->
<ng-template #password>
  <div class="title-holder">
    <h2>{{ 'account_deletion.password_confirmation.title' | translate}}</h2>
  </div>

  <div class="password-holder">
    <cl-input
      class="password"
      [preventCopyPaste]="true"
      [controlForm]="form.get('password')"
      [errorMessages]="validations.password.errors"
      type="password"
      color="light"
      stacked
      (changeInput)="form.controls['password'].setValue($event.target.value)"
      label="{{'auth.password' | translate}}">
    </cl-input>

    <cl-recaptcha
      captchaElementId="checkPasswordCaptcha"
      [formControl]="form.get('captcha')">
    </cl-recaptcha>
  </div>

</ng-template>

<!-- bottom buttons -->
<ng-template #bottomButtons let-step="step" let-label="label">
  <!-- subscription step (step 1) -->
  <span *ngIf="step === 'subscription'">
     <cl-action-button
       *ngIf="subscriptionType !== 'stripe'"
       [label]="'common.close' | translate"
       (click)="closeModal()"
       [canClick]="true"
       [fullWidth]="true">
    </cl-action-button>
    <cl-action-button
      *ngIf="subscriptionType === 'stripe'"
      [label]="'common.continue' | translate"
      (click)="navigateNext()"
      [canClick]="true"
      [fullWidth]="true">
    </cl-action-button>
  </span>

  <!-- terms step (step 2) -->
  <span *ngIf="step === 'terms'">
    <ion-row>
    <ion-col size="5">
      <cl-action-button
        [label]="(displaySubscriptionStep ? 'common.back': 'common.cancel') | translate"
        (click)="displaySubscriptionStep ? navigatePrevious() : closeModal()"
        color="white"
        [canClick]="true"
        [fullWidth]="true">
      </cl-action-button>
    </ion-col>
      <ion-col size="7">
      <cl-action-button
        [label]="label || 'common.next' | translate"
        [canClick]="!(step && isStepInvalid(step))"
        (click)="!(step && isStepInvalid(step)) && navigateNext()"
        [fullWidth]="true">
      </cl-action-button>
    </ion-col>
  </ion-row>
  </span>

  <!-- enrollment step -->
  <span *ngIf="step === 'enrollment'">
    <ion-row>
    <ion-col size="5">
      <cl-action-button
        [label]="'common.back' | translate"
        (click)="navigatePrevious()"
        color="white"
        [canClick]="true"
        [fullWidth]="true">
      </cl-action-button>
    </ion-col>
    <ion-col size="7">
      <cl-action-button
        [label]="label || 'common.next' | translate"
        [canClick]="!(step && isStepInvalid(step))"
        (click)="!(step && isStepInvalid(step)) && navigateNext()"
        [fullWidth]="true">
      </cl-action-button>
    </ion-col>
  </ion-row>
  </span>

  <!-- password step -->
  <span *ngIf="step === 'password'">
    <ion-row>
    <ion-col size="5">
      <cl-action-button
        [label]="'common.back' | translate"
        (click)="navigatePrevious()"
        color="white"
        [canClick]="true"
        [fullWidth]="true">
      </cl-action-button>
    </ion-col>
    <ion-col size="7">
      <cl-action-button
        [label]="label || 'common.next' | translate"
        [canClick]="!(step && isStepInvalid(step))"
        (click)="!(step && isStepInvalid(step)) && slideFinished()"
        [fullWidth]="true">
      </cl-action-button>
    </ion-col>
  </ion-row>
  </span>
</ng-template>
