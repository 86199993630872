import { Component, OnDestroy, HostBinding } from '@angular/core';
import { NavController } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserProgramProvider } from '../../providers/user-program.provider';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { AlertsService } from '../../services/alerts.service';
import { ToastService } from '../../services/toast.service';
import { Store } from '@ngrx/store';
import * as accountActions from '../../store/session/actions/account.actions';
import { LoadingService } from '../../services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import * as subscriptionActions from '../../store/session/actions/subscription.actions';
import { ClarityConfig } from '../../config/clarity.config';
import { isAccountSetupStarted } from '../../store/session/selectors/account.selectors';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { State } from '../../store/state.reducer';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'page-license',
  styleUrls: ['license.scss'],
  templateUrl: 'license.html'
})
export class LicensePage implements OnDestroy {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  modal;
  paramsSubscription: Subscription;
  fromAccountSetup: boolean;
  fromPremiumTab: boolean;

  public validations = {
    license_code: {
      validators: Validators.compose([
        Validators.required,
        Validators.minLength(2)
      ]),
      errors: {
        required: 'errors.subscriptions.license_code_required',
        minlength: 'errors.subscriptions.license_code_min_chars'
      }
    }
  };
  public licenseForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public store: Store<State>,
    public navCtrl: NavController,
    private userProgramProvider: UserProgramProvider,
    private alerts: AlertsService,
    private toasts: ToastService,
    private loading: LoadingService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    public config: ClarityConfig
  ) {
    this.paramsSubscription = this.route.queryParams.subscribe(((params) => {
      this.fromAccountSetup = params.fromAccountSetup || null;
    }));
    this.licenseForm = this.formBuilder.group({
      license_code: new FormControl('', this.validations.license_code)
    });
  }

  goBack() {
    if (!this.fromAccountSetup) {
      this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
    } else {
      this.navCtrl.navigateBack('upgrade', {queryParams: {fromAccountSetup: this.fromAccountSetup}});
    }
  }

  onValidate() {
    this.loading.showLoadingOverlay(this.translate.get('loading.validating'));

    this.userProgramProvider.updateUserProgram(
      {
        license_code: this.licenseForm.value.license_code.toUpperCase()
      })
      .pipe(
        withLatestFrom(this.store.select(isAccountSetupStarted)),
        map(([action, accountSetupStarted]) => {
          this.loading.hideLoadingOverlay();
          this.toasts.confirm(this.translate.get('subscriptions.license_redeemed_successfully'));

          this.store.dispatch(new subscriptionActions.SubscriptionLicensed(
            this.licenseForm.value.license_code.toUpperCase()
          ));

          if (this.fromPremiumTab) {
            this.store.dispatch(new navigationActions.GotoDashboard());
          }

          if (accountSetupStarted) {
            if (!this.fromAccountSetup) {
              this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
            }

            this.store.dispatch(new accountActions.SignupComplete());
          }
          else {
            this.goBack();
          }
        }),
        catchError((error) => {
          this.loading.hideLoadingOverlay();

          if (error.status === 404) {
            this.alerts.customError(error, 'subscriptions.invalid_code', 'subscriptions.license_code_is_not_valid');

            return of(null);
          }
          else if (error.status === 409) {
            this.alerts.customError(error, 'common.error', 'subscriptions.license_already_redeemed');

            return of(null);
          }
          else {
            this.alerts.customError(error, 'errors.common.unknown_error', 'errors.common.generic_error_please_retry');

            return of(null);
          }
        })
      )
      .subscribe((result) => {
        console.log(result);
      });
  }

  ngOnDestroy() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }
}
