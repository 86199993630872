import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PlayPage } from './play';
import { SharedModule } from '../../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { ExercisePlayerComponent } from './components/exercise-player.component';
import { LessonPerformComponent } from './components/lesson-perform.component';
import { NextLessonButtonComponent } from './components/next-lesson-button.component';
import { AwarenessQuizComponent } from './components/perform/awareness-quiz/awareness-quiz.component';
import { PerformService } from './services/perform.service';
import { WeekReviewComponent } from './components/perform/week-review/week-review.component';
import { GoalEditLessonComponent } from '../lessons/components/goal-edit-lesson.component';
import { SensationCaptureLessonComponent } from '../lessons/components/sensation-capture-lesson.component';
import { MantraEditLessonComponent } from '../lessons/components/mantra-edit-lesson.component';
import { LessonCaptureComponent } from './components/lesson-capture.component';
import { AnxietyQuizComponent } from './components/perform/anxiety-quiz/anxiety-quiz.component';
import { TriggerCaptureLessonComponent } from '../lessons/components/trigger-capture-lesson.component';
import { QuittingPactLessonComponent } from '../lessons/components/quitting-pact-lesson.component';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WeightCaptureLessonComponent } from '../lessons/components/weight-capture-lesson.component';
import { SimpleAssessmentComponent } from './components/perform/simple-assessment/simple-assessment.component';

@NgModule({
  declarations: [
    PlayPage,
    ExercisePlayerComponent,
    LessonPerformComponent,
    AnxietyQuizComponent,
    NextLessonButtonComponent,
    AwarenessQuizComponent,
    WeekReviewComponent,
    LessonCaptureComponent,
    GoalEditLessonComponent,
    QuittingPactLessonComponent,
    SensationCaptureLessonComponent,
    TriggerCaptureLessonComponent,
    WeightCaptureLessonComponent,
    NextLessonButtonComponent,
    MantraEditLessonComponent,
    SimpleAssessmentComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([{ path: '', component: PlayPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ],
  providers: [
    PerformService
  ]
})
export class PlayPageModule {}
