import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cl-registration-wrapper',
  styleUrls: ['registration-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <ng-content></ng-content>
  `
})

export class RegistrationWrapperComponent {
}
