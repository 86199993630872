import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-checkin-exercise',
  styleUrls: ['checkin-exercise.component.scss'],
  template: `
    <div class="exercise-graphic tint">
      <img src="assets/imgs/checkin/{{graphic}}"/>
    </div>
    <p class="exercise-title">{{title}}</p>
    <p class="exercise-instructions" [innerHTML]="instructions"></p>
  `
})
export class CheckinExerciseComponent {

  @Input() graphic: string;
  @Input() title: string;
  @Input() instructions: string;

}
