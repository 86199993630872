
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { catchError } from 'rxjs/operators';
import { Subtitle } from '../store/normalized/schemas/mediaFile.schema';

@Injectable({providedIn: 'root'})
export class SubtitlesProvider {

  public subtitlesEndpoint = '/subtitles';

  constructor(
    public http: HttpProvider
  ) {
  }

  public loadSubtitles(): Observable<Subtitle[]> {
    return this.http.get(this.subtitlesEndpoint)
      .pipe(
        catchError((error) => {
          console.log('error retrieving subtitles', error);

          return observableThrowError(error);
        })
      );
  }
}
