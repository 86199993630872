// tabs selectors

import { createSelector } from '@ngrx/store';

import { getTabsState } from '../../../../app/store';
import * as tabsReducer from './tabs.reducer';

export const forcedTabsOpenCount = createSelector(
  getTabsState,
  tabsReducer.forcedTabsOpen
);
