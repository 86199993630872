import { Attribute, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClarityConfig } from '../../config/clarity.config';

@Component({
  selector: 'cl-input',
  styleUrls: ['input-field.component.scss'],
  template: `
    <ion-item [ngClass]="{'safari': config.runningOnSafari, 'has-icon': icon, 'has-error': controlForm.invalid && (controlForm.dirty || controlForm.touched)}"
      class="input-field ion-text-wrap" #item>

      <ion-avatar class="ion-text-start" [class]="iconClass" *ngIf="icon">
        <img src="{{icon}}">
      </ion-avatar>

      <div class="item-inner">
        <div class="input-wrapper">
          <ion-label position="{{ isStacked ? 'stacked' : 'floating' }}" [class]="color">
            {{label}}
          </ion-label>


            <ion-input
              [class]="color"
              (contextmenu)="handleCopyPasteEvents($event)"
              (copy)="handleCopyPasteEvents($event)"
              (paste)="handleCopyPasteEvents($event)"
              (change)="this.changeInput.emit($event);"
              [class.ion-text-uppercase]="uppercase"
              [formControl]="controlForm"
              (ionBlur)="handleBlurEvent()"
              type="{{type}}"
              readonly="{{readonly}}"
              placeholder="{{placeHolder}}"
              (ionFocus)="handleFocusEvent()"
              (keypress)="numberOnlyValidation($event)"
              [autocapitalize]="autocapitalize">
            </ion-input>

            <ion-button type="button" class="cl-show-password {{color}}" fill="clear" item-end (click)="togglePasswordType()" *ngIf="showPasswordEnabled">
              <ion-icon name="eye"></ion-icon>
            </ion-button>
        </div>
      </div>

    </ion-item>


    <ion-item class="input-errors" no-lines *ngIf="controlForm.invalid && (controlForm.dirty || controlForm.touched || touched)">
      <div class="input-error-message" *ngFor="let error of objectKeys(controlForm.errors)">
        {{ errorMessages[error] | translate }}
      </div>

      <ion-label> </ion-label>
    </ion-item>
  `
})

export class InputFieldComponent implements OnInit {

  @Input() preventCopyPaste = false;
  @Input() controlForm;
  @Input() icon: string = null;
  @Input() uppercase = false;
  @Input() type = 'text';
  @Input() format: string = null;
  @Input() label: string = null;
  @Input() placeHolder = '';
  @Input() autocapitalize = 'off';
  @Input() readonly = false;
  @Input() color = '';
  @Output() changeInput = new EventEmitter();
  @Output() blurInput = new EventEmitter();
  @Output() focusInput = new EventEmitter();
  // Allow the input to be disabled, and when it is make it somewhat transparent.
  // THIS IS NOT ALLOWED WITH REACTIVE FORMS!
  // @Input() disabled = false;

  @Input() errorMessages: any = null;

  @ViewChild('item', { static: true, read: ElementRef }) item: ElementRef;

  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  public isStacked = false;
  touched = false;

  showPasswordEnabled = false;
  objectKeys = Object.keys;

  constructor(
    @Attribute('stacked') isStacked: string,
    public config: ClarityConfig
  ) {
    if (isStacked === '') {
      this.isStacked = true;
    }
    // window.addEventListener('keyboardWillHide', () => {
    //   document.getElementsByClassName('scroll-content')[0].classList
    //     .remove('scrolling-padding-keyboard');
    // });
  }

  get opacity() {
    // return this.disabled ? 0.25 : 1;
    return 1;
  }

  get iconClass() {
    if (!this.icon) {
      return null;
    }

    return this.icon.replace(/^.*\/(.*)\.png|.jpg|.jpeg/g, (match, p1) => p1);
  }

  ngOnInit() {
    // enable show password icon
    if (this.type === 'password') {
      this.showPasswordEnabled = true;
    }
  }

  handleCopyPasteEvents(event) {
    if(this.preventCopyPaste){
      event.preventDefault();
    }
  }

  handleBlurEvent() {
    this.touched = true;
    this.blurInput.emit();
  }

  handleFocusEvent() {
    this.focusInput.emit();
  }

  scrollToInput(event) {
  //   document.getElementsByClassName('scroll-content')[0].classList
  //     .add('scrolling-padding-keyboard');
  //   this.item.nativeElement.scrollIntoView();
  //   console.log(this.item.nativeElement);
  }

  numberOnlyValidation(event: any) {
    if (this.type !== 'number') {
      return true;
    }

    if (this.format === 'float') {
      const pattern = /^\d+(\.\d+)?$/;
      const inputChar = String.fromCharCode(event.charCode) === ',' ? '.' : String.fromCharCode(event.charCode);
      let nextValue = this.controlForm.value ? this.controlForm.value + inputChar : inputChar;
      if (inputChar === '.') {
        nextValue = nextValue + 0;
      }
      if (!pattern.test(nextValue)) {
        event.preventDefault();
      }

    } else if (this.format === 'integer') {
      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);

      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    }
  }

  togglePasswordType() {
    this.type = this.type === 'text' ? 'password' : 'text';
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    // this.disabled = isDisabled;
  }
}
