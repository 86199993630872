import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as cigTrackerActions from '../actions/cig-tracker.actions';
import { map } from 'rxjs/operators';
import { CigCountProvider } from '../../../../app/providers/cig-count.provider';
import { AddData } from 'ngrx-normalizr';
import * as moment from 'moment';
import { CountByDay, cigarettesByDaySchema } from '../../normalized/schemas/count-by-day.schema';
import { SessionState } from '../session.reducers';

@Injectable({providedIn: 'root'})
export class CigCountEffects {

  setCigCount$ = createEffect(() => this.actions$.pipe(ofType<cigTrackerActions.SetCigCount>(cigTrackerActions.SET_CIG_COUNT),
    map((action) => {
      const count = action.payload;
      const today = moment()
        .format('YYYY-MM-DD');

      this.store.dispatch(new AddData<CountByDay>({
        data: [{
          date: today,
          count
        }],
        schema: cigarettesByDaySchema
      }));
    })
  ), {dispatch: false});

  trackCigCount$ = createEffect(() => this.actions$.pipe(ofType<cigTrackerActions.TrackCigCount>(cigTrackerActions.TRACK_CIG_COUNT),
    map((action) => this.cigCountProvider.trackCigCount(action.payload)
      .subscribe(() => console.log))
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<SessionState>,
    private cigCountProvider: CigCountProvider
  ) {}
}
