import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError, concatMap,
  defaultIfEmpty,
  delay,
  filter,
  map,
  mergeMap,
  switchMap, take,
  tap, toArray,
  withLatestFrom
} from 'rxjs/operators';
import * as integrationsActions from '@mindsciences/clarity-core/app/store/session/actions/integrations.actions';
import * as syncActions from '@mindsciences/clarity-core/app/store/session/actions/sync.actions';
import * as navigationActions from '@mindsciences/clarity-core/app/store/session/actions/navigation.actions';
import { OpenModal } from '@mindsciences/clarity-core/app/store/session/actions/navigation.actions';
import { HealthFitService } from '../../services';
import { from, Observable, of } from 'rxjs';
import { HealthFitAuthorizationDataType, HealthFitAuthorizationType, HealthFitResultType } from 'capacitor-health-fit';
import { Store } from '@ngrx/store';
import { SessionState } from '@mindsciences/clarity-core/app/store/session/session.reducers';
import { getLastSynchronizationDate } from '@mindsciences/clarity-core/app/store/session/selectors/integrations.selectors';
import { HealthFitData, HealthFitMultipleHistory } from 'capacitor-health-fit/dist/esm/definitions';
import { WeightActivitiesProvider } from '@mindsciences/clarity-core/app/providers/weight-activities.provider';
import { MinutesActivitiesProvider } from '@mindsciences/clarity-core/app/providers/minutes-activities.provider';
import { ClarityConfig } from '@mindsciences/clarity-core/app/config/clarity.config';
import {getAllowHealthFitIntegration, getCurrentUserProgram} from '@mindsciences/clarity-core/app/store/normalized/selectors/user.selectors';
import { UserProgram } from '@mindsciences/clarity-core/app/store/normalized/schemas/user.schema';
import {getLastWeightActivity, getWeightActivity} from '@mindsciences/clarity-core/app/store/normalized/selectors/weight-activity.selectors';
import { getMinutesActivity, getOfflineManualActivity } from '@mindsciences/clarity-core/app/store/normalized/selectors/minutes-activity.selectors';
import { WeightActivity, weightActivitySchema } from '@mindsciences/clarity-core/app/store/normalized/schemas/weight-activity.schema';
import { MinutesActivity, minutesActivitySchema } from '@mindsciences/clarity-core/app/store/normalized/schemas/minutes-activity.schema';
import { ToastService } from '@mindsciences/clarity-core/app/services/toast.service';
import { LoadingService } from '@mindsciences/clarity-core/app/services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { FitbitCallback, IntegrationSource } from '@mindsciences/clarity-core/app/store/session/models/integration.model';
import { ConnectedApplicationsProvider } from '@mindsciences/clarity-core/app/providers/connected-applications.provider';
import { IntegrationConnectionResult, IntegrationWeightResult } from '@mindsciences/clarity-core/app/components/integrations-status-modal.component';
import { kgToLb } from '@mindsciences/utils';
import { AddData, RemoveData } from 'ngrx-normalizr';
import { ConnectivityService } from '@mindsciences/clarity-core/app/services/connectivity.service';
import {
  AddManualMinutesActivity,
  AddManualMinutesActivityFail,
  AddManualMinutesActivitySuccess,
  AddManualWeightActivityFail,
  AddManualWeightActivitySuccess,
  RemoveManualMinutesActivity,
  RemoveManualMinutesActivityFail,
  RemoveManualMinutesActivitySuccess,
  RemoveManualWeightActivityFail,
  RemoveManualWeightActivitySuccess
} from '@mindsciences/clarity-core/app/store/session/actions/integrations.actions';

import { schema } from 'normalizr';
import { Activity } from '@mindsciences/clarity-core/app/store/normalized/schemas/activity.schema';

import { IntegrationsService } from '@mindsciences/clarity-core/app/services/integrations.service';
import { AlertsService } from '@mindsciences/clarity-core/app/services/alerts.service';
import { HealthDevicesProvider } from '@mindsciences/clarity-core/app/providers/health-devices.provider';


@Injectable()
export class IntegrationsEffects {
  openHealthApp$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.OpenHealthApp>(integrationsActions.OPEN_HEALTH_APP),
    tap(() => this.healthFitService.openHealthApp())
  ), {dispatch: false});

  connectHealthKit$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.ConnectHealthKit>(integrationsActions.CONNECT_HEALTHKIT),
    switchMap(() => this.healthFitService.isAvailable()
      .pipe(
        map(available => available ? new integrationsActions.RequestHealthFitAuthorizations() : new integrationsActions.ConnectHealthKitFail()),
        catchError(() => of(new integrationsActions.ConnectHealthKitFail()))
      ))
  ));

  connectGoogleFit$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.ConnectGoogleFit>(integrationsActions.CONNECT_GOOGLE_FIT),
    switchMap(() => this.healthFitService.isAvailable()
      .pipe(
        map(available => available ? new integrationsActions.RequestGoogleFitAuthorizations() : new integrationsActions.ConnectGoogleFitFail()),
        catchError(() => of(new integrationsActions.ConnectGoogleFitFail()))
      ))
  ));

  requestHealthFitAuthorizations$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.RequestHealthFitAuthorizations>(integrationsActions.REQUEST_HEALTH_FIT_AUTHORIZATIONS),
      switchMap(() => this.healthFitService.requestAuthorizations([HealthFitAuthorizationDataType.ACTIVITY, HealthFitAuthorizationDataType.WEIGHT])
        .pipe(
          map(() => new integrationsActions.ConnectHealthKitSuccess()),
          catchError(() => of(new integrationsActions.ConnectHealthKitFail()))
        ))
    ));

  requestGoogleFitAuthorizations$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.RequestGoogleFitAuthorizations>(integrationsActions.REQUEST_GOOGLE_FIT_AUTHORIZATIONS),
      switchMap(() => this.healthFitService.requestAuthorizations([HealthFitAuthorizationDataType.ACTIVITY, HealthFitAuthorizationDataType.WEIGHT])
        .pipe(
          map(() => new integrationsActions.CheckGoogleFitAuthorizations()),
          catchError(() => of(new integrationsActions.ConnectGoogleFitFail()))
        ))
    ));

  checkGoogleFitAuthorizations$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.CheckGoogleFitAuthorizations>(integrationsActions.CHECK_GOOGLE_FIT_AUTHORIZATIONS),
      switchMap(() =>
        this.healthFitService.isMultipleTypesAuthorized([HealthFitAuthorizationDataType.ACTIVITY, HealthFitAuthorizationDataType.WEIGHT],
          HealthFitAuthorizationType.READ)
          .pipe(
            map((accepted) => {
              if (accepted) {
                return new integrationsActions.ConnectGoogleFitSuccess();
              } else {
                const integrationResult: IntegrationConnectionResult = {status: 'rejected', type: 'connection', platform: 'android'};

                return new integrationsActions.ShowIntegrationStatusModal(integrationResult);
              }
            }),
            catchError(() => of(new integrationsActions.ConnectGoogleFitFail()))
          ))
    ));

  connectHealthKitFail$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.ConnectHealthKitFail>(integrationsActions.CONNECT_HEALTHKIT_FAIL),
    map(() => {
      const integrationResult: IntegrationConnectionResult = {status: 'fail', type: 'connection', platform: 'ios'};

      this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
    })
  ), {dispatch: false});

  connectGoogleFitFail$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.ConnectGoogleFitFail>(integrationsActions.CONNECT_GOOGLE_FIT_FAIL),
    map(() => {
      const integrationResult: IntegrationConnectionResult = {status: 'fail', type: 'connection', platform: 'android'};

      this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
    })
  ), {dispatch: false});

  connectHealthKitSuccess$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.ConnectHealthKitSuccess>(integrationsActions.CONNECT_HEALTHKIT_SUCCESS),
      map(() => {
        const integrationResult: IntegrationConnectionResult = {status: 'success', type: 'connection', platform: 'ios'};

        this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
        this.store.dispatch(new integrationsActions.GetHealthDataSinceLastSync());
      })
    ), {dispatch: false});

  connectGoogleFitSuccess$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.ConnectGoogleFitSuccess>(integrationsActions.CONNECT_GOOGLE_FIT_SUCCESS),
      tap(() => {
        const integrationResult: IntegrationConnectionResult = {status: 'success', type: 'connection', platform: 'android'};

        this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
        this.store.dispatch(new integrationsActions.GetHealthDataSinceLastSync());
      })
    ), {dispatch: false});

  connectFitBit$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.ConnectFitBit>(integrationsActions.CONNECT_FITBIT),
    tap(action => {
      const status = action.payload;
      this.store.dispatch(status === 'success' ? new integrationsActions.ConnectFitBitSuccess() : new integrationsActions.ConnectFitBitFail(action.error));
    })
  ), {dispatch: false});

  connectFitBitSuccess$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.ConnectFitBitSuccess>(integrationsActions.CONNECT_FITBIT_SUCCESS),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getWeightActivity)))
    ),
    tap(([_, weightActivity]: [integrationsActions.ConnectFitBitSuccess, WeightActivity[]]) => {
      // Weight already recorded
      if(weightActivity && weightActivity.length > 0) {
        const integrationResult: IntegrationConnectionResult = { status: 'success', type: 'connection', platform: 'fitbit' };
        this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
      } else {
        this.store.dispatch(new integrationsActions.RequestFitBitWeight());
      }
    })
  ), {dispatch: false});

  requestFitBitWeight$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.RequestFitBitWeight>(integrationsActions.REQUEST_FITBIT_WEIGHT),
    tap(action => {
      this.translate.get(['my_devices.loading_fitbit_account'])
        .toPromise()
        .then(translation => this.loadingService.showLoadingOverlay(translation['my_devices.loading_fitbit_account']));

      const timeout = setTimeout(() => {
        this.store.dispatch(new integrationsActions.RequestFitBitWeightFail());
      }, 10000);

      // dispatch & displayloader
      this.store.select(getLastWeightActivity)
        .pipe(filter(weight => weight !== undefined), take(1))
        .toPromise()
        .then(weight => {
          clearTimeout(timeout);
          this.store.dispatch(new integrationsActions.RequestFitBitWeightSuccess(weight));
        });
    })), { dispatch: false });

  requestFitBitWeightSuccess$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.RequestFitBitWeightSuccess>(integrationsActions.REQUEST_FITBIT_WEIGHT_SUCCESS),
      tap(action => {
        setTimeout(() => {
          this.loadingService.hideLoadingOverlay();
          const integrationResult: IntegrationWeightResult = { weight: action.payload, type: 'weight', status: 'success'};
          this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
        }, 500);
      })
    ), {dispatch: false});

  requestFitBitWeightFail$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.RequestFitBitWeightFail>(integrationsActions.REQUEST_FITBIT_WEIGHT_FAIL),
      tap(action => {
        setTimeout(() => {
          this.loadingService.hideLoadingOverlay();
          const integrationResult: IntegrationWeightResult = { status: 'success', type: 'weight', weight: null};
          this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
        }, 500);
      })
    ), {dispatch: false});

  connectFitBitFail$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.ConnectFitBitFail>(integrationsActions.CONNECT_FITBIT_FAIL),
    tap(action => {
      const integrationResult: IntegrationConnectionResult = { status: 'fail', type: 'connection', platform: 'fitbit', error: action.error };
      this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
    })
  ), {dispatch: false});

  disconnectFitBit$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.DisconnectFitBit>(integrationsActions.DISCONNECT_FITBIT),
      switchMap(() => this.connectedApplicationsProvider.disconnectFitBit()
        .pipe(
          map(() => new integrationsActions.DisconnectFitBitSuccess()),
          catchError(error => of(new integrationsActions.DisconnectFitBitFail(error)))
        )
      )));

  disconnectFitBitFail$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.DisconnectFitBitFail>(integrationsActions.DISCONNECT_FITBIT_FAIL),
    tap(() => {
      const integrationResult: IntegrationConnectionResult = { status: 'fail', type: 'disconnection', platform: 'fitbit' };
      this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
    })
  ), {dispatch: false});

  disconnectFitBitSuccess$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.DisconnectFitBitSuccess>(integrationsActions.DISCONNECT_FITBIT_SUCCESS),
      tap(() => {
        const integrationResult: IntegrationConnectionResult = { status: 'success', type: 'disconnection', platform: 'fitbit' };
        this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
      })
    ), {dispatch: false});

  disconnectGoogleFit$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.DisconnectGoogleFit>(integrationsActions.DISCONNECT_GOOGLE_FIT),
    tap(() => {
      const integrationResult: IntegrationConnectionResult = {status: 'success', type: 'disconnection', platform: 'android'};
      this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
    })
  ), {dispatch: false});

  disconnectHealthKit$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.DisconnectHealthKit>(integrationsActions.DISCONNECT_HEALTHKIT),
    tap(() => {
      const integrationResult: IntegrationConnectionResult = {status: 'success', type: 'disconnection', platform: 'ios'};
      this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
    })
  ), {dispatch: false});

  getFitbitConnectionStatusAndNavigate$ = createEffect(() => this.actions$.pipe(
    ofType<integrationsActions.GetFitbitConnectionStatusAndNavigate>(integrationsActions.GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE),
    switchMap(action => this.connectedApplicationsProvider.getConnectedApplications().pipe(
      map(integrations => {
        const isFitbitConnected = integrations.some(integration => integration.key === 'fitbit' && integration.connected);

        return new integrationsActions.GetFitbitConnectionStatusAndNavigateSuccess(action.returnPath, isFitbitConnected);
      }),
      catchError(_ => of(new integrationsActions.GetFitbitConnectionStatusAndNavigateFail(action.returnPath)))
    ))
  ));

  getFitbitConnectionStatusAndNavigateSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<integrationsActions.GetFitbitConnectionStatusAndNavigateSuccess>(integrationsActions.GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE_SUCCESS),
    tap(() => {
      this.store.dispatch(new syncActions.SyncWeightActivities());
    }),
    filter(action => Boolean(action.returnPath)),
    delay(2000),
    map(action => {
      const params: FitbitCallback = {
        status: 'success',
        source: IntegrationSource.fitbit,
        callback: true
      };

      return new navigationActions.RootGoTo({ route: action.returnPath, params });
    })
  ));

  getFitbitConnectionStatusAndNavigateFail$ = createEffect(() => this.actions$.pipe(
    ofType<integrationsActions.GetFitbitConnectionStatusAndNavigateFail>(integrationsActions.GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE_FAIL),
    delay(700),
    map(action => {
      const params: FitbitCallback = {
        status: 'fail',
        source: IntegrationSource.fitbit,
        callback: true
      };

      if (action.error) {
        params.error = action.error;
      }

      return new navigationActions.RootGoTo({ route: action.returnPath, params });
    })
  ));

  getHealthDataSinceLastSync$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.GetHealthDataSinceLastSync>(integrationsActions.GET_HEALTH_DATA_SINCE_LAST_SYNC),
      withLatestFrom(
        this.store.select(getLastSynchronizationDate),
        this.store.select(getCurrentUserProgram)
      ),
      switchMap((
        [action, lastSynchronizationDate, userProgram]:
          [integrationsActions.GetHealthDataSinceLastSync, string, UserProgram]) => {

        const startDate = lastSynchronizationDate ? new Date(lastSynchronizationDate) : new Date(userProgram.created_at);
        const now = new Date();
        const endDate = now;

        // To be sure we get all the data, remove 10 minute from start date & add 1 minute to end date
        // For a data to be retrieved, the date range must be wider than the data range.
        // A check with existing  values is done to avoid recording the same value several times
        startDate.setMinutes(startDate.getMinutes() - 10);
        endDate.setMinutes(endDate.getMinutes() + 1);

        return this.healthFitService.readData([HealthFitAuthorizationDataType.ACTIVITY, HealthFitAuthorizationDataType.WEIGHT],
          startDate, endDate, HealthFitResultType.ALL)
          .pipe(
            map((healthData: HealthFitMultipleHistory) => {
              this.store.dispatch(new integrationsActions.GetHealthDataSinceLastSyncSuccess());

              return new integrationsActions.PostHealthData({
                newWeights : healthData.WEIGHT as HealthFitData[],
                newMinutes : healthData.ACTIVITY as HealthFitData[],
                newSynchronizationDate : now.toISOString(),
                globalSynchronization: false
              });
            }),
            catchError((error) => {
              if(error?.code === 'AUTHORIZATION_NOT_DETERMINED' && this.config.isAndroid) {
                this.store.dispatch(new integrationsActions.GoogleFitAuthorizationsNotDetermined());
              } else {
                console.error(`Cannot GET sync data ${this.config.isAndroid ? 'googlefit' : 'healthkit'}`, error);
              }

              return of(new integrationsActions.GetHealthDataSinceLastSyncFail(error));
            })
          );
      })
    ));

  postHealthData$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.PostHealthData>(integrationsActions.POST_HEALTH_DATA),
    withLatestFrom(
      this.store.select(getWeightActivity),
      this.store.select(getMinutesActivity),
      this.store.select(getAllowHealthFitIntegration)
    ),
    switchMap(([action, existingWeights, existingMinutes, allowHealthFitIntegration]:
                 [integrationsActions.PostHealthData, WeightActivity[], MinutesActivity[], boolean]) => {
      const isDPPManualActivity = (source: string) => this.config.programDPP() && this.isManualWeightActivity(source);
      const apiCalls = [];

      const minutesActivities = action.payload.newMinutes
        .map(newMinute => ({
          minutes: newMinute.value,
          source: this.config.isAndroid ? IntegrationSource.googlefit : IntegrationSource.healthkit,
          activity_at: newMinute.start,
          comment: ''
        }))
        .filter(newMinutes => !this.existsInMinutesActivity(newMinutes, existingMinutes));

      // Weight from health-fit package is always in kg
      const weightActivities = action.payload.newWeights
        .map(newWeight => ({
          value: Number(kgToLb(newWeight.value).toFixed(1)),
          unit: 'lb',
          activity_at: newWeight.start,
          source: this.config.isAndroid ? IntegrationSource.googlefit : IntegrationSource.healthkit
        }))
        .filter(newWeight =>
          !this.existsInWeightActivity(newWeight, existingWeights) && (allowHealthFitIntegration ? true : !isDPPManualActivity(newWeight.source)));

      minutesActivities.length > 0 && apiCalls.unshift(this.minutesActivitiesProvider.createBulkMinutesActivity(minutesActivities));
      weightActivities.length > 0 && apiCalls.unshift(this.weightActivitiesProvider.createBulkWeightActivity(weightActivities));

      return from(apiCalls)
        .pipe(
          mergeMap(observable => observable, 2),
          defaultIfEmpty(null),
          toArray(),
          map(() => new integrationsActions.PostHealthDataSuccess({
            globalSynchronization: action.payload.globalSynchronization,
            newSynchronizationDate: action.payload.newSynchronizationDate})
          ),
          catchError(error => of(new integrationsActions.PostHealthDataFail({error, globalSynchronization: action.payload.globalSynchronization})))
        );
    })
  ));

  getAllHealthDataFail$ = createEffect(() => this.actions$.pipe(ofType(integrationsActions.GET_ALL_HEALTH_DATA_FAIL),
    tap((error: any) => {
      setTimeout(() => {
        this.loadingService.hideLoadingOverlay();
        const integrationResult: IntegrationConnectionResult = {status: 'fail', type: 'synchronization', platform: this.config.isAndroid ? 'android':'ios'};
        this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
      }, 500);
    })
  ), {dispatch: false});

  postHealthDataSuccess$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.PostHealthDataSuccess>(integrationsActions.POST_HEALTH_DATA_SUCCESS),
      tap(action => {
        if (action.payload.globalSynchronization) {
          setTimeout(() => {
            this.loadingService.hideLoadingOverlay();

            const integrationResult: IntegrationConnectionResult =
              {status: 'success', type: 'synchronization', platform: this.config.isAndroid ? 'android':'ios'};
            this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
          }, 500);
        }
      })
    ), {dispatch: false});

  postHealthDataFail$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.PostHealthDataFail>(integrationsActions.POST_HEALTH_DATA_FAIL),
    tap(action => {
      if (action.payload.globalSynchronization) {
        setTimeout(() => {
          this.loadingService.hideLoadingOverlay();
          const integrationResult: IntegrationConnectionResult = {status: 'fail', type: 'synchronization', platform: this.config.isAndroid ? 'android':'ios'};
          this.store.dispatch(new integrationsActions.ShowIntegrationStatusModal(integrationResult));
        }, 500);
      }
    })
  ), {dispatch: false});

  getAllHealthData$ = createEffect(() => this.actions$.pipe(ofType<integrationsActions.GetAllHealthData>(integrationsActions.GET_ALL_HEALTH_DATA),
    withLatestFrom(this.store.select(getCurrentUserProgram)),
    switchMap(([action, userProgram]: [integrationsActions.GetAllHealthData, UserProgram]) => {
      const now = new Date();
      const endDate = now;
      endDate.setMinutes(endDate.getMinutes() + 1);

      this.translate.get(['my_devices.resync_googlefit_loading', 'my_devices.resync_healthkit_loading'])
        .toPromise()
        .then(translation =>
          this.loadingService.showLoadingOverlay(
            translation[this.config.isAndroid
              ? 'my_devices.resync_googlefit_loading'
              : 'my_devices.resync_healthkit_loading']));

      return this.healthFitService.readData([HealthFitAuthorizationDataType.ACTIVITY, HealthFitAuthorizationDataType.WEIGHT],
        new Date(userProgram.created_at), endDate, HealthFitResultType.ALL)
        .pipe(
          map((healthData: HealthFitMultipleHistory) => {
            this.store.dispatch(new integrationsActions.GetAllHealthDataSuccess());

            return new integrationsActions.PostHealthData({
              newWeights : healthData.WEIGHT as HealthFitData[],
              newMinutes : healthData.ACTIVITY as HealthFitData[],
              newSynchronizationDate : now.toISOString(),
              globalSynchronization: true
            });
          }),
          catchError(error => {
            console.error(`Cannot GET sync data ${this.config.isAndroid ? 'googlefit' : 'healthkit'}`, error);

            return of(new integrationsActions.GetAllHealthDataFail(error));
          })
        );
    })
  ));

  addManualMinutesActivity$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.AddManualMinutesActivity>(integrationsActions.ADD_MANUAL_MINUTES_ACTIVITY),
      mergeMap(action => {
        const minutesActivity = this.addManualActivity(
          { minutes: Number(action.minutes),comment: '',activity_at: (action.date).toISOString()},action?.id, minutesActivitySchema);

        // Don't send to backend if user if offline
        if(this.connectivityService.isOffline()) {
          return;
        }

        return this.minutesActivitiesProvider.createMinutesActivity(minutesActivity).pipe(
          map(() => new integrationsActions.AddManualMinutesActivitySuccess(minutesActivity)),
          catchError(error => of(new integrationsActions.AddManualMinutesActivityFail({error, minutesActivity})))
        );

      })
    ));

  removeManualMinutesActivity$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.RemoveManualMinutesActivity>(integrationsActions.REMOVE_MANUAL_MINUTES_ACTIVITY),
      mergeMap(action => {
        const minutesActivity = action.payload;
        const isObservable = this.removeManualActivity(minutesActivity, minutesActivitySchema);
        if(isObservable) {
          return isObservable;
        }

        return this.minutesActivitiesProvider.deleteMinutesActivity(minutesActivity.id).pipe(
          map(() => new integrationsActions.RemoveManualMinutesActivitySuccess(minutesActivity.id)),
          catchError(error => {
            // We don't consider 404 as real error.
            // The activity may have been deleted from another device and still available on this device
            if(error.statusCode === '404') {
              return of(new integrationsActions.RemoveManualMinutesActivityFail({error, minutesActivity}));
            } else {
              new integrationsActions.RemoveManualMinutesActivitySuccess(minutesActivity.id);
            }
          })
        );
      })
    ));

  addManualMinutesActivityFail$ = createEffect(() => this.actions$.pipe(ofType(integrationsActions.ADD_MANUAL_MINUTES_ACTIVITY_FAIL),
    tap((action: AddManualMinutesActivityFail) => {
      this.addManualActivityFail(action.payload.minutesActivity, minutesActivitySchema);
      this.toastService.error(this.translate.get('errors.common.generic_error_please_retry'));
    })
  ), {dispatch: false});

  addManualMinutesActivitySuccess$ = createEffect(() => this.actions$.pipe(ofType(integrationsActions.ADD_MANUAL_MINUTES_ACTIVITY_SUCCESS),
    tap((action: AddManualMinutesActivitySuccess) =>  { this.addManualActivitySucccess(action.minutesActivity, minutesActivitySchema); })
  ), {dispatch: false});

  removeManualMinutesActivitySuccess$ = createEffect(() => this.actions$.pipe(ofType(integrationsActions.REMOVE_MANUAL_MINUTES_ACTIVITY_SUCCESS),
    tap((action: RemoveManualMinutesActivitySuccess) => { this.removeManualActivitySuccess(String(action.payload), minutesActivitySchema); })
  ), {dispatch: false});

  removeManualMinutesActivityFail$ = createEffect(() => this.actions$.pipe(ofType(integrationsActions.REMOVE_MANUAL_MINUTES_ACTIVITY_FAIL),
    tap((action: RemoveManualMinutesActivityFail) => {
      this.removeManualActivityFail(action.payload.minutesActivity, minutesActivitySchema);
      this.toastService.error(this.translate.get('errors.common.generic_error_please_retry'));
    })
  ), {dispatch: false});

  syncOfflineManualActivities$ = createEffect(() => this.actions$.pipe(
    ofType<integrationsActions.SyncOfflineManualActivities>(integrationsActions.SYNC_OFFLINE_MANUAL_ACTIVITIES),
    withLatestFrom(this.store.select(getOfflineManualActivity)),
    switchMap(([_, offlineManualActivities]) => {
      const actions = [];
      offlineManualActivities.map(minuteActivity => {
        actions.push(minuteActivity.localState.action === 'add' ?
          new AddManualMinutesActivity(minuteActivity.minutes, new Date(minuteActivity.activity_at), minuteActivity.id) :
          new RemoveManualMinutesActivity(minuteActivity));
      });

      return actions;
    })
  ));

  /***/

  addManualWeightActivity$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.AddManualWeightActivity>(integrationsActions.ADD_MANUAL_WEIGHT_ACTIVITY),
      mergeMap(action => {
        // TODO check data
        const weightActivity = this.addManualActivity(
          { value: Number(action.value),unit: action.unit,activity_at: (action.date).toISOString()},action?.id, weightActivitySchema);

        // Don't send to backend if user if offline
        if(this.connectivityService.isOffline()) {
          return;
        }

        return this.weightActivitiesProvider.createWeightActivity(weightActivity).pipe(
          map(() => new integrationsActions.AddManualWeightActivitySuccess(weightActivity)),
          catchError(error => of(new integrationsActions.AddManualWeightActivityFail({error, weightActivity})))
        );

      })
    ));

  removeManualWeightActivity$ = createEffect(() =>
    this.actions$.pipe(ofType<integrationsActions.RemoveManualWeightActivity>(integrationsActions.REMOVE_MANUAL_WEIGHT_ACTIVITY),
      mergeMap(action => {
        const weightActivity = action.payload;
        const isObservable = this.removeManualActivity(weightActivity, weightActivitySchema);
        if(isObservable) {
          return isObservable;
        }

        return this.weightActivitiesProvider.deleteWeightActivity(weightActivity.id).pipe(
          map(() => new integrationsActions.RemoveManualWeightActivitySuccess(weightActivity.id)),
          catchError(error => {
            // We don't consider 404 as real error.
            // The activity may have been deleted from another device and still available on this device
            if(error.statusCode === '404') {
              return of(new integrationsActions.RemoveManualWeightActivityFail({error, weightActivity}));
            } else {
              new integrationsActions.RemoveManualWeightActivitySuccess(weightActivity.id);
            }
          })
        );
      })
    ));

  addManualWeightActivityFail$ = createEffect(() => this.actions$.pipe(ofType(integrationsActions.ADD_MANUAL_WEIGHT_ACTIVITY_FAIL),
    tap((action: AddManualWeightActivityFail) => {
      this.addManualActivityFail(action.payload.weightActivity, weightActivitySchema);
      this.toastService.error(this.translate.get('errors.common.generic_error_please_retry'));
    })
  ), {dispatch: false});

  addManualWeightActivitySuccess$ = createEffect(() => this.actions$.pipe(ofType(integrationsActions.ADD_MANUAL_WEIGHT_ACTIVITY_SUCCESS),
    tap((action: AddManualWeightActivitySuccess) =>  { this.addManualActivitySucccess(action.weightActivity, weightActivitySchema); })
  ), {dispatch: false});

  removeManualWeightActivitySuccess$ = createEffect(() => this.actions$.pipe(ofType(integrationsActions.REMOVE_MANUAL_WEIGHT_ACTIVITY_SUCCESS),
    tap((action: RemoveManualWeightActivitySuccess) => { this.removeManualActivitySuccess(String(action.payload), weightActivitySchema); })
  ), {dispatch: false});

  removeManualWeightActivityFail$ = createEffect(() => this.actions$.pipe(ofType(integrationsActions.REMOVE_MANUAL_WEIGHT_ACTIVITY_FAIL),
    tap((action: RemoveManualWeightActivityFail) => {
      this.removeManualActivityFail(action.payload.weightActivity, weightActivitySchema);
      this.toastService.error(this.translate.get('errors.common.generic_error_please_retry'));
    })
  ), {dispatch: false});

  /***/

  openIntegrationStatusModal$ = createEffect(() => this.actions$.pipe(
    ofType<integrationsActions.ShowIntegrationStatusModal>(integrationsActions.SHOW_INTEGRATION_STATUS_MODAL),
    map(action => new OpenModal('IntegrationsStatusModalComponent',
      { integrationResult: action.integrationResult, cssClass: 'integration-status-modal-component' }))
  ));

  orderFitbitTracker$ = createEffect(() => this.actions$.pipe(
    ofType<integrationsActions.OrderFitbitTracker>(integrationsActions.ORDER_FITBIT_TRACKER),
    switchMap(_ => this.healthDevicesProvider.fetchFitBitTrackerOrderUrl().pipe(
      tap(url => this.integrationsService.goToFitBitTrackerOrderPage(url)),
      catchError(error => of(new integrationsActions.OrderFitbitTrackerFail(error)))
    ))
  ), { dispatch: false});

  orderFitbitTrackerFail$ = createEffect(() => this.actions$.pipe(ofType(integrationsActions.ORDER_FITBIT_TRACKER_FAIL),
    tap((error) => this.alertsService.genericError(error))
  ), {dispatch: false});

  private generateUniqueId(): number {
    return Number(Math.random().toString(10)
      .slice(2, 15));
  }

  private isManualWeightActivity(source: string): boolean {
    // BundleID of GoogleFit (Android) app and Health app (iOS)
    return source === 'com.google.android.apps.fitness' || source === 'com.apple.Health';
  }

  private existsInMinutesActivity = (newMinute:  MinutesActivity, existingMinutes: MinutesActivity[]) => existingMinutes?.some(existingActivity => {
    const sameDate = new Date(existingActivity.activity_at).getTime() === new Date(newMinute.activity_at).getTime();
    const sameValue = existingActivity.minutes === newMinute.minutes;

    return sameValue && sameDate;
  });

  private existsInWeightActivity = (newWeight: WeightActivity, existingWeights: WeightActivity[]) => existingWeights?.some(existingWeight => {
    const sameDate = new Date(existingWeight.activity_at).getTime() === new Date(newWeight.activity_at).getTime();
    const sameValue = existingWeight.value === newWeight.value;

    return sameValue && sameDate;
  });

  // TODO put in a service Adapter design pattern
  private removeManualActivityFail = <T extends Activity>(activity: T, schemaEntity: schema.Entity<T>) => {
    this.store.dispatch(new AddData<T>({data: [{...activity,localState: {status: 'failed', action: 'delete'}}], schema: schemaEntity}));
  };

  private removeManualActivitySuccess = <T extends Activity>(id: string, schemaEntity: schema.Entity<T>) => {
    this.store.dispatch(new RemoveData({id, schema: schemaEntity}));
  };

  private addManualActivitySucccess = <T extends Activity>(activity: T, schemaEntity: schema.Entity<T>) => {
    this.store.dispatch(new AddData<T>({data: [{...activity, localState: undefined}],schema: schemaEntity}));
  };

  private addManualActivityFail = <T extends Activity>(activity: T, schemaEntity: schema.Entity<T>) => {
    this.store.dispatch(new AddData<T>({data: [{...activity,localState: {status: 'failed', action: 'add'}}],schema: schemaEntity}));
  };

  private addManualActivity = <T extends Activity>(activityData: any, id: number = null, schemaEntity: schema.Entity<T>) => {
    let activity = {
      ...activityData,
      source: IntegrationSource.manual,
      localState: undefined,
      id: undefined
    };

    if(this.connectivityService.isOffline()) {
      activity = {
        ...activity,
        id: id || this.generateUniqueId(),
        localState: {
          status: 'offline',
          action: 'add'
        }
      };

      this.store.dispatch(new AddData<T>({data: [activity],schema: schemaEntity}));
    } else {
      activity = {
        ...activity,
        id: id || this.generateUniqueId(),
        localState: {
          status: 'pending',
          action: 'add'
        }
      };

      this.store.dispatch(new AddData<T>({data: [activity], schema: schemaEntity}));
    }

    return activity;
  };

  private removeManualActivity = <T extends Activity>(activity: T, schemaEntity: schema.Entity<T>): Observable<any> | void => {
    // The data is not sent, just removed from the store
    if(activity.localState?.status === 'failed' || (activity.localState?.status === 'offline' && activity.localState?.action === 'add')) {
      return of(new RemoveData({id: String(activity.id), schema: minutesActivitySchema }));
    }

    if(this.connectivityService.isOffline()) {
      return of(new AddData<T>({data: [{
        ...activity,
        localState : {
          action: 'delete',
          status: 'offline'
        }
      }], schema: schemaEntity}));
    } else {
      this.store.dispatch(new AddData<T>({data: [{
        ...activity,
        localState : {
          action: 'delete',
          status: 'pending'
        }
      }], schema: schemaEntity}));
    }
  };

  constructor(
    private actions$: Actions,
    private healthFitService: HealthFitService,
    private store: Store<SessionState>,
    private connectedApplicationsProvider: ConnectedApplicationsProvider,
    private weightActivitiesProvider: WeightActivitiesProvider,
    private minutesActivitiesProvider: MinutesActivitiesProvider,
    private config: ClarityConfig,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private toastService: ToastService,
    private connectivityService: ConnectivityService,
    private integrationsService: IntegrationsService,
    private alertsService: AlertsService,
    private healthDevicesProvider: HealthDevicesProvider
  ) {
  }
}
