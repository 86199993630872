import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-control-buttons',
  styleUrls: ['./control-buttons.component.scss'],
  template: `
    <ion-row class="buttons-holder">
      <ion-col size="5">
        <cl-prev-button
          data-e2e="prev-button"
          [ngClass]="{'dark-bg': darkBackground}"
          [label]="backLabel"
          [canClick]="canBack"
          (action)="onBack()">
        </cl-prev-button>
      </ion-col>
      <ion-col size="7">
        <cl-next-button
          data-e2e="next-button"
          [ngClass]="{'dark-bg': darkBackground}"
          [label]="nextLabel"
          [canClick]="canNext"
          (action)="onNext()">
        </cl-next-button>
      </ion-col>
    </ion-row>
  `
})

export class ControlButtonsComponent {

  @Input() backLabel = 'common.back';
  @Input() nextLabel = 'common.next';

  @Input() canBack = true;
  @Input() canNext = true;

  @Input() darkBackground = false;

  @Output() back = new EventEmitter();
  @Output() next = new EventEmitter();

  onBack() {
    this.back.emit();
  }

  onNext() {
    this.next.emit();
  }
}
