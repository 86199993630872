// TODO CLARITY-1184 fix this importing issue
//
// All the classes in the APP_MODULE_IMPORTS array
// have to be exported from the same path as APP_MODULE_IMPORTS
// for the AOT compilation to work.

export { APP_MODULE_IMPORTS } from './app/app.module';

// APP_MODULE_IMPORTS
export { AppComponentModule } from './app/app.component.module';
export { AppRoutingModule } from './app/app-routing.module';
export { reducer as fromStoreReducer } from './app/store/state.reducer';
export { OfflineSyncEffects } from './app/store/offline/offline.effects';
export { storeDevtoolsModule } from './environments/build-specifics/store-devtools-module';
export { translateConfig } from './app/config/translate.config';
export { AccountPageModule } from './app/pages/account/account.module';
export { AccountSetupPageModule } from './app/pages/account-setup/account-setup.module';
export { BreathExercisePageModule } from './app/pages/breathe-exercise/breathe-exercise.module';
export { PlayPageModule } from './app/pages/play/play.module';
export { CravingToolModule } from './app/pages/craving-tool/craving-tool.module';
export { CravingToolModule as CravingToolCtqModule } from './app/pages/craving-tool-ctq/craving-tool-ctq.module';
export { DebugPageModule } from './app/pages/debug/debug.module';
export { CustomPathPageModule } from './app/pages/custom-path/custom-path.module';
export { InterstitialPageModule } from './app/pages/interstitial/interstitial.module';
export { LessonsPageModule } from './app/pages/lessons/lessons.module';
export { LicensePageModule } from './app/pages/license/license.module';
export { LoginEmailPageModule } from './app/pages/login-email/login-email.module';
export { GearsReviewPageModule } from './app/pages/gears-review/gears-review.module';
export { MyCoachCtqPageModule } from './app/pages/my-coach-ctq/my-coach-ctq.module';
export { MyCoachConversationPageModule } from './app/pages/my-coach-conversation/my-coach-conversation.module';
export { MyCoachBioPageModule } from './app/pages/my-coach-bio/my-coach-bio.module';
export { MyStatsModule } from './app/pages/my-stats/my-stats.module';
export { NewCustomWeekPageModule } from './app/pages/new-custom-week/new-custom-week.module';
export { NewThemeWeekPageModule } from './app/pages/new-theme-week/new-theme-week.module';
export { NextStepsPageModule } from './app/pages/next-steps/next-steps.module';
export { NrtPageModule } from './app/pages/nrt/nrt.module';
export { OnboardingPageModule } from './app/pages/onboarding/onboarding.module';
export { PostPageModule } from './app/pages/post/post.module';
export { ProfileCompletionModule } from './app/pages/profile-completion/profile-completion.module';
export { InappMessageModule } from './app/pages/inapp-message/inapp-message.module';
export { RainExercisePageModule } from './app/pages/rain-exercise/rain-exercise.module';
export { WelcomeVideoPageModule } from './app/pages/welcome-video/welcome-video.module';
export { RestartProgramPageModule } from './app/pages/restart-program/restart-program.module';
export { SelectThemePageModule } from './app/pages/select-theme/select-theme.module';
export { SubscribePageModule } from './app/pages/subscribe/subscribe.module';
export { UpgradePageModule } from './app/pages/upgrade/upgrade.module';
export { UserProfileModule } from './app/pages/user-profile/user-profile.module';
export { WizardPageModule } from './app/pages/wizard/wizard.module';
export { AccountDeletionModule } from './app/pages/account-deletion/account-deletion.module';
export { AccountDeletionConfirmationModule } from './app/pages/account-deletion-confirmation/account-deletion-confirmation.module';
