import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppComponent } from './app.component';
import { clarityPagesTransitionAnimation } from './utils/clarity-pages-transition.animation';

declare const config: any;

@NgModule({
  imports: [
    // Ionic modules
    IonicModule.forRoot(
      config.buildPlatform === 'ios'
        ? {
          navAnimation: clarityPagesTransitionAnimation,
          backButtonText: '',
          // scroll and auto focus assist create a weird flicker on iOS
          scrollPadding: false,
          scrollAssist: false,
          inputBlurring: false,
          rippleEffect: false,
          mode: 'ios'

          // try to avoid "Loading chunk XX" errors
          // preloadModules: false
        }
        : config.buildPlatform === 'android' ? {
          navAnimation: clarityPagesTransitionAnimation,
          backButtonText: '',
          scrollPadding: true,
          scrollAssist: true,
          inputBlurring: false,
          rippleEffect: false,
          mode: 'ios',
          hardwareBackButton: false
        } : {
          // Desktop
          navAnimation: clarityPagesTransitionAnimation,
          backButtonText: '',
          scrollPadding: false,
          scrollAssist: true,
          rippleEffect: false
        }
    )
  ],
  declarations: [
    AppComponent
  ],
  exports: [
    AppComponent
  ]
})
export class AppComponentModule {
}
