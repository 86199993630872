// auth reducer

import {
  ERROR_CONNECTION_TIMEOUT,
  ERROR_REQUEST_LIMIT_EXCEEDED,
  getErrorType
} from '../../../config/error.handler';

import { HttpError } from '../../session/models/http-error.model';
import * as authActions from '../actions/auth.actions';
import { Release } from 'src/app/providers/releases.provider';

export interface AuthState {
  token: string;
  authenticated: boolean;
  authenticating: boolean;
  checkingPassword: boolean;
  deletingAccount: boolean;
  resettingPassword: boolean;
  lastPasswordReset: string;
  error: HttpError;
  iridiumToken: string;
  iridiumHost: string;
  officialVersion: string;
  officialReleaseInfo: Release;
}

export const initialAuthState: AuthState = {
  token: null,
  authenticated: false,
  authenticating: false,
  checkingPassword: false,
  deletingAccount: false,
  resettingPassword: false,
  lastPasswordReset: null,
  error: null,
  iridiumToken: null,
  iridiumHost: null,
  officialVersion: null,
  officialReleaseInfo: null
};

export function authReducer(state = initialAuthState, action: authActions.AuthActions): AuthState {
  switch (action.type) {
    case authActions.LOGIN: {
      return {
        ...state,
        authenticating: true,
        error: null
      };
    }

    case authActions.DELETE_ACCOUNT: {
      return {
        ...state,
        deletingAccount: true,
        error: null
      };
    }

    case authActions.CHECK_PASSWORD: {
      return {
        ...state,
        checkingPassword: true,
        error: null
      };
    }

    case authActions.LOGIN_WITH_AUTHORIZATION_CODE: {
      return {
        ...state,
        authenticating: true,
        error: null
      };
    }

    case authActions.LOGIN_WITH_PREAUTH_TOKEN: {
      return {
        ...state,
        authenticating: true,
        error: null
      };
    }

    case authActions.LOGIN_WITH_GOOGLE_TOKEN: {
      return {
        ...state,
        authenticating: true,
        error: null
      };
    }

    case authActions.LOGIN_COMPLETE: {
      return {
        ...state,
        authenticating: false
      };
    }

    case authActions.LOGIN_SUCCESS: {
      const token = action.payload.token;

      return {
        ...state,
        token,
        authenticating: false,
        authenticated: true
      };
    }

    case authActions.DELETE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        deletingAccount: false
      };
    }

    case authActions.DELETE_ACCOUNT_FAIL: {
      return {
        ...state,
        deletingAccount: false
      };
    }

    case authActions.CHECK_PASSWORD_FAIL: {
      // Not a http error
      if(!action.payload) {
        return {
          ...state,
          error: {
            type: null,
            httpStatus: null,
            httpError: null,
            httpMessage: null
          },
          checkingPassword: false
        };
      } else {
        const httpResponse = action.payload.error;

        return {
          ...state,
          error: {
            type: getErrorType(httpResponse.status),
            httpStatus: httpResponse.status,
            httpError: httpResponse.error,
            httpMessage: httpResponse.message
          },
          checkingPassword: false
        };
      }
    }

    case authActions.LOGIN_FAIL: {
      const httpResponse = action.payload.error;

      return {
        ...state,
        error: {
          type: getErrorType(httpResponse.status),
          httpStatus: httpResponse.status,
          httpError: httpResponse.error,
          httpMessage: httpResponse.message
        },
        authenticated: false,
        authenticating: false
      };
    }

    case authActions.CLEAR_LOGIN_STATE: {
      return {
        ...state,
        error: null
      };
    }

    case authActions.RESET_AUTHENTICATING: {
      return {
        ...state,
        authenticating: false
      };
    }

    case authActions.RESET_STATE_ON_LOGOUT: {
      return {
        ...state,
        authenticated: false
      };
    }

    case authActions.RESET_STATE_ON_ACCOUNT_DELETION: {
      return initialAuthState;
    }

    case authActions.SET_OFFICIAL_VERSION: {
      return {
        ...state,
        officialVersion: action.payload
      };
    }

    case authActions.SET_OFFICIAL_RELEASE_INFO: {
      return {
        ...state,
        officialReleaseInfo: action.payload
      };
    }

    case authActions.SET_IRIDIUM_TOKEN: {
      return {
        ...state,
        iridiumToken: action.payload
      };
    }

    case authActions.SET_IRIDIUM_HOST: {
      return {
        ...state,
        iridiumHost: action.payload
      };
    }

    case authActions.RESET_PASSWORD: {
      return {
        ...state,
        resettingPassword: true
      };
    }

    case authActions.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resettingPassword: false,
        lastPasswordReset: action.payload
      };
    }

    case authActions.RESET_PASSWORD_FAIL: {
      const httpResponse = action.payload.error;
      let type = null;

      switch (httpResponse.status) {
        case 429:
          type = ERROR_REQUEST_LIMIT_EXCEEDED;
          break;
        case 408:
          type = ERROR_CONNECTION_TIMEOUT;
          break;
      }

      return {
        ...state,
        resettingPassword: false,
        error: {
          type,
          httpStatus: httpResponse.status,
          httpError: httpResponse.error,
          httpMessage: httpResponse.message
        }
      };
    }
  }

  return state;
}

export const authenticating = (state: AuthState) => state.authenticating;
export const authenticated = (state: AuthState) => state.authenticated;
export const checkingPassword = (state: AuthState) => state.checkingPassword;
export const deletingAccount = (state: AuthState) => state.deletingAccount;
export const isResettingPassword = (state: AuthState) => state.resettingPassword;
export const lastPasswordReset = (state: AuthState) => state.lastPasswordReset;
export const authToken = (state: AuthState) => state.token;
export const authError = (state: AuthState) => state.error;
export const officialVersion = (state: AuthState) => state.officialVersion;
export const officialReleaseInfo = (state: AuthState) => state.officialReleaseInfo;
export const iridiumToken = (state: AuthState) => state.iridiumToken;
export const iridiumHost = (state: AuthState) => state.iridiumHost;
