import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { scaleUpDown, PASSWORD_VALIDATION_REGEX } from '@mindsciences/utils';

import { ClarityConfig } from '../../../config/clarity.config';
import { ConnectivityService } from '../../../services/connectivity.service';
import { getCurrentUserSSOProvider } from '../../../store/normalized/selectors/user.selectors';
import * as accountActions from '../../../store/session/actions/account.actions';
import { SessionState } from '../../../store/session/session.reducers';
import { State } from '../../../store/state.reducer';
import { SettingsComponent } from './settings.component';

import { PrivacyScreenService } from 'src/app/services/privacy-screen.service';

export type passwordType = 'text' | 'password';

@Component({
  selector: 'cl-change-password',
  styleUrls: ['change-password.component.scss'],
  animations: [scaleUpDown],
  template: `
    <div
      class="sso-alert"
      *ngIf="SSOProvider"
      [innerHtml]="'auth.update_on_provider' | translate : {provider: SSOProvider}">
    </div>

    <form [formGroup]="changePasswordForm" autocomplete="off" *ngIf="!SSOProvider" (submit)="onSubmit()"
      [@state]="visibility" (@state.done)="animationDone($event)">

      <ion-row>
        <ion-label for="currentPassword">{{ 'auth.current_password' | translate }}</ion-label>
      </ion-row>

      <ion-item lines="none">
        <ion-input
          (contextmenu)="$event.preventDefault()"
          (copy)="$event.preventDefault()"
          (paste)="$event.preventDefault()"
          [type]="currentPasswordType"
          formControlName="currentPassword"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && controls.currentPassword.errors }">
        </ion-input>
        <ion-button type="button" class="cl-show-password" fill="clear" item-end (click)="toggleCurrentPasswordType()">
          <ion-icon name="eye"></ion-icon>
        </ion-button>
      </ion-item>
      <div class="form-error">
        <ng-container *ngIf="submitted && controls.currentPassword.invalid">
          <div *ngIf="controls.currentPassword.errors?.required; else currentPasswordInvalid">{{ 'auth.current_password_required' | translate }}</div>
          <ng-template #currentPasswordInvalid>{{ 'auth.password_unsafe' | translate }}</ng-template>
        </ng-container>
      </div>

      <ion-row>
        <ion-label for="newPassword">{{ 'auth.new_password' | translate }}</ion-label>
      </ion-row>

      <ion-item lines="none">
        <ion-input
          (contextmenu)="$event.preventDefault()"
          (copy)="$event.preventDefault()"
          (paste)="$event.preventDefault()"
          [type]="newPasswordType"
          formControlName="newPassword"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && controls.newPassword.errors }">
        </ion-input>
        <ion-button type="button" class="cl-show-password" fill="clear" item-end (click)="toggleNewPasswordType()">
          <ion-icon name="eye"></ion-icon>
        </ion-button>
      </ion-item>
      <div class="form-error">
        <ng-container *ngIf="submitted && controls.newPassword.invalid">
          <div *ngIf="controls.newPassword.errors?.required; else newPasswordInvalid">{{ 'auth.new_password_required' | translate }}</div>
          <ng-template #newPasswordInvalid>{{ 'auth.password_unsafe' | translate }}</ng-template>
        </ng-container>
      </div>

      <ion-row>
        <ion-label for="newPassword">{{ 'auth.new_password_confirmation' | translate }}</ion-label>
      </ion-row>
      <ion-item lines="none">
        <ion-input
          (contextmenu)="$event.preventDefault()"
          (copy)="$event.preventDefault()"
          (paste)="$event.preventDefault()"
          [type]="confirmPasswordType"
          formControlName="newPasswordConfirmation"
          class="form-control" [ngClass]="{ 'is-invalid': submitted && controls.newPasswordConfirmation.errors }">
        </ion-input>
        <ion-button type="button" class="cl-show-password" fill="clear" item-end (click)="toggleConfirmPasswordType()">
          <ion-icon name="eye"></ion-icon>
        </ion-button>
      </ion-item>
      <div class="form-error">
        <ng-container *ngIf="submitted">
          <div *ngIf="controls.newPasswordConfirmation.errors?.required">{{ 'auth.new_password_confirmation_required' | translate }}</div>
          <div *ngIf="controls.newPasswordConfirmation.errors?.mustMatch">{{ 'auth.new_password_must_match' | translate }}</div>
        </ng-container>
      </div>

      <ion-row>
        <p class="password-rules">{{ 'auth.password_unsafe' | translate }}</p>
      </ion-row>

      <ion-row class="action-button">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            [canClick]="true"
            label="{{'auth.change_password' | translate}}">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </form>
  `
})

export class ChangePasswordComponent implements SettingsComponent, OnDestroy, OnInit {

  @Output() endAnimation = new EventEmitter<boolean>();

  visibility = 'visible';

  SSOProvider: string = null;

  changePasswordForm: FormGroup;

  submitted = false;

  currentPasswordType: passwordType = 'password';

  newPasswordType: passwordType = 'password';

  confirmPasswordType: passwordType = 'password';

  constructor(
    private sessionStore: Store<SessionState>,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private connectivity: ConnectivityService,
    private store: Store<State>,
    public config: ClarityConfig,
    private privacyScreenService: PrivacyScreenService
  ) {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', [Validators.required, Validators.minLength(8)]],
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.pattern(PASSWORD_VALIDATION_REGEX)]],
      newPasswordConfirmation: ['', [Validators.required]]
    }, { validators: this.mustMatch('newPassword', 'newPasswordConfirmation') });

    this.sessionStore.select(getCurrentUserSSOProvider)
      .pipe(take(1))
      .subscribe((ssoProvider) => {
        this.SSOProvider = ssoProvider;
      });
  }

  ngOnInit() {
    this.privacyScreenService.enable();
  }

  ngOnDestroy() {
    this.privacyScreenService.disable();
  }

  private mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  get controls() { return this.changePasswordForm.controls; }

  onSubmit() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    this.submitted = true;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.store.dispatch(new accountActions.ChangePassword({
      newPassword: this.controls.newPassword.value,
      newPasswordConfirmation: this.controls.newPasswordConfirmation.value,
      currentPassword: this.controls.currentPassword.value
    }));
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }

  toggleCurrentPasswordType(): void {
    this.currentPasswordType = this.togglePasswordType(this.currentPasswordType);
  }

  toggleNewPasswordType(): void {
    this.newPasswordType = this.togglePasswordType(this.newPasswordType);
  }

  toggleConfirmPasswordType(): void {
    this.confirmPasswordType = this.togglePasswordType(this.confirmPasswordType);
  }

  private togglePasswordType(currentType: passwordType): passwordType {
    return currentType === 'password' ? 'text' : 'password';
  }

}
