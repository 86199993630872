import { Injectable } from '@angular/core';

import { HttpProvider } from './http/http.provider';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';

@Injectable({providedIn: 'root'})
export class SocialProvider {

  private commentsEndpoint = '{community}/comments';
  private postsEndpoint = '{community}/posts';

  constructor(
    private http: HttpProvider
  ) {

  }

  public loadPosts(page, filter = null, timestamp, userId?: number) {
    const isPrivate = filter === 'private';

    let req = `${this.postsEndpoint}?page=${page}&timestamp=${timestamp}&private=${isPrivate}`;

    if (!isPrivate && filter) {
      req += `&categories=${filter}`;
    }
    if (userId) {
      req += `&profile_id=${userId}`;
    }

    return this.http.get(req)
      .pipe(
        catchError((error) => observableThrowError(error))
      );
  }

  public savePost(payload) {
    const categories = [];
    if (payload.question) categories.push('question');
    if (payload.craving) categories.push('craving');
    if (payload.faq) categories.push('faq');

    return this.http.post(this.postsEndpoint, {
      content: payload.content,
      private: payload.private,
      categories
    })
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public loadPostById(id) {
    const req = `${this.postsEndpoint}/${id}`;

    return this.http.get(req)
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public deleteComment(id) {
    const req = `${this.commentsEndpoint}/${id}`;

    return this.http.delete(req)
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public deletePost(id) {
    const req = `${this.postsEndpoint}/${id}`;

    return this.http.delete(req)
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public loadLastComments(postId) {
    const req = `${this.postsEndpoint}/${postId}/comments`;

    return this.http.get(req)
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public addLikeOnPost(postId: string) {
    return this.http.post(`${this.postsEndpoint}/${postId}/likes`, {});
  }

  public removeLikeFromPost(postId: string) {
    return this.http.delete(`${this.postsEndpoint}/${postId}/likes`);
  }

  public addLikeOnComment(commentId: string) {
    return this.http.post(`${this.commentsEndpoint}/${commentId}/likes`, {});
  }

  public removeLikeFromComment(commentId: string) {
    return this.http.delete(`${this.commentsEndpoint}/${commentId}/likes`);
  }

  public loadAllComments(postId) {
    const req = `${this.postsEndpoint}/${postId}/comments/all`;

    return this.http.get(req)
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public saveComment(content, postId) {
    return this.http.post(`${this.postsEndpoint}/${postId}/comments`, {content})
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public getCommentById(commentId) {
    return this.http.get(`${this.commentsEndpoint}/${commentId}`)
      .pipe(catchError(error => observableThrowError(error)));
  }
}
