import { Action } from '@ngrx/store';

// download actions
export const SAVE_DOWNLOADED_FILE = '[media] Save Downloaded File';
export const REMOVE_ALL_FILES = '[media] Remove All Files';
export const REMOVE_ALL_GOOGLE_FILES = '[media] Remove All Google Files';
export const REMOVE_ALL_GOOGLE_FILES_SUCCESS = '[media] Remove All Google Files Success';
export const SAVE_PLAYED_FILE = '[media] Save Played File';
export const SET_DEFAULT_MEDIAFILE = '[media] Set Default MediaFile';

// subtitles actions
export const SET_SUBTITLES = '[media] Subtitles Set';

export class SaveDownloadedFile implements Action {
  readonly type = SAVE_DOWNLOADED_FILE;

  constructor(public payload: string) {
  }
}

export class SavePlayedFile implements Action {
  readonly type = SAVE_PLAYED_FILE;

  constructor(public payload: string) {
  }
}

export class RemoveAllFiles implements Action {
  readonly type = REMOVE_ALL_FILES;
}

export class RemoveAllGoogleFiles implements Action {
  readonly type = REMOVE_ALL_GOOGLE_FILES;
}

export class RemoveAllGoogleFilesSuccess implements Action {
  readonly type = REMOVE_ALL_GOOGLE_FILES_SUCCESS;
}

export class SetDefaultMediaFileForExercise implements Action {
  readonly type = SET_DEFAULT_MEDIAFILE;

  constructor(public exerciseId: number, public mediaFileFingerprint: string) {}
}

export class SetSubtitles implements Action {
  readonly type = SET_SUBTITLES;

  constructor(public language: string) {}
}

export type MediaActions =
  | RemoveAllFiles
  | RemoveAllGoogleFiles
  | RemoveAllGoogleFilesSuccess
  | SaveDownloadedFile
  | SetDefaultMediaFileForExercise
  | SavePlayedFile
  | SetSubtitles;
