<ion-item>
  <ion-label>
    Firstname: {{ user.first_name }}
  </ion-label>
</ion-item>

<ion-item>
  <ion-label>
    Lastname: {{ user.last_name }}
  </ion-label>
</ion-item>

<ion-item>
  <ion-label>
    Username: {{ user.username }}
  </ion-label>
</ion-item>

<ion-item>
  <ion-label>
    Email: <a href="mailto:{{user.email}}">{{user.email}}</a>
  </ion-label>
</ion-item>

<ion-item>
  <ion-label class="ion-text-center">
    <a (click)="openActiveAdmin()">Active Admin</a>
  </ion-label>
</ion-item>

<ion-item>
  <ion-label class="ion-text-center">
    <a (click)="openRailsAdmin()">Rails Admin</a>
  </ion-label>
</ion-item>

<ion-item>
  <ion-label class="ion-text-center">
    <a (click)="openIridium()">Iridium</a>
  </ion-label>
</ion-item>

<ion-item>
  <ion-label class="ion-text-center">
    <a (click)="showUserPosts()">{{ 'user-profile.filter_posts' | translate }}</a>
  </ion-label>
</ion-item>
