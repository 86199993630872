<ion-content no-bounce>
    <ion-header>
      <cl-header-nav-buttons
        color="blank"
        (closeClick)="onClose()"
        [backButton]="false"
        class="page-header">
      </cl-header-nav-buttons>
    </ion-header>

    <div class="page-wrapper" *ngIf="(module$ | async)">
      <div class="title-holder padding lateral-padding">
        <cl-module-title [module]="(module$ | async)"></cl-module-title>
      </div>

      <div class="lessons-holder lateral-padding">
        <cl-lesson-card
          *ngFor="let lesson of (module$ | async).lessons"
          [lesson]="lesson"
          (loadLesson)="onPlayLesson(lesson)">
        </cl-lesson-card>
      </div>
    </div>

  <div class="waves-bg"></div>

  <div class="bottom-bg-background"></div>

</ion-content>
