<ion-row class="step lateral-padding" *ngIf="currentStep === 1">
  <ion-col class="ion-text-center">
    <h1 class="step-intro">{{'wizards.how_are_you_feeling' | translate}}</h1>
    <cl-list-single-select
      [items]="feelings"
      [selected]="feelingChoice"
      (selectItem)="onFeelingSelected($event)">
    </cl-list-single-select>
  </ion-col>
</ion-row>

<ion-row class="step lateral-padding" *ngIf="currentStep === 2">
  <ion-col class="ion-text-center">
    <h2 class="checkin-exercise-intro">{{'wizards.checkin_exercises_intro' | translate}}</h2>

    <cl-checkin-exercise
      [graphic]="checkinExercise.graphic"
      [title]="checkinExercise.title"
      [instructions]="checkinExercise.instructions">
    </cl-checkin-exercise>
  </ion-col>
</ion-row>

<ion-row class="step today-module-step lateral-padding" *ngIf="currentStep === 3">
  <ion-col class="ion-text-center" *ngIf="todayModule">
    <h1 class="step-intro">{{'wizards.todays_module_is' | translate}}
      <p class="step-intro module-name">{{todayModule}}</p></h1>
    <h1 class="step-intro ready-question" *ngIf="!todayModuleStarted">{{'wizards.ready_to_start' | translate}}</h1>
    <h1 class="step-intro ready-question" *ngIf="todayModuleStarted">{{'wizards.ready_to_continue' | translate}}</h1>

    <cl-action-button
      label="{{'wizards.lets_do_it' | translate}}"
      [square]="true"
      [fullWidth]="true"
      [canClick]="true" (action)="playModule(false)">
    </cl-action-button>

    <cl-action-button
      class="review-btn" (action)="playModule(true)"
      [whiteBg]="true"
      [square]="true"
      [fullWidth]="true"
      label="{{ 'wizards.review_previous_module' | translate }}"
      [canClick]="true">
    </cl-action-button>
  </ion-col>

  <ion-col class="ion-text-center" *ngIf="!todayModule">
    <h1 class="step-intro ready-question">{{'wizards.ready_to_start_a_new_week' | translate}}</h1>

    <cl-action-button
      label="{{'dashboard.start_new_week' | translate}}"
      [square]="true"
      [fullWidth]="true"
      [canClick]="true" (action)="startNewWeek()">
    </cl-action-button>
  </ion-col>
</ion-row>
