import { createSchemaSelectors } from 'ngrx-normalizr';
import { StripePlan, stripePlanSchema, StripeProduct } from '../schemas/stripe-plan.schema';
import { createSelector } from '@ngrx/store';
import { getGeneralPricePerWeek, SubscriptionType, WeeklyPriceRoundDirection } from 'src/app/utils/subscription-utils';
import { NON_BREAKING_SPACE } from 'src/app/utils';

const stripePlansSchemaSelectors = createSchemaSelectors<StripePlan>(stripePlanSchema);

export const getStripePlans = stripePlansSchemaSelectors.getEntities;

export const formatPrice = (curr: string, num: number, maximumFractionDigits = 2, minimumFractionDigits = 2) => {
  if (!num) {
    return null;
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: curr,
    minimumFractionDigits,
    maximumFractionDigits
  }).format(num);
};

export const getCurrencyLabel = (currency: string) => {
  if (!currency) {
    return '';
  }

  if (currency && currency.length > 1) {
    return `${currency}${NON_BREAKING_SPACE}`;
  } else {
    return `${currency}`;
  }
};

export const getPlanType = (plan: StripePlan) => {
  switch (plan.interval) {
    case 'life':
      return SubscriptionType.LIFETIME;

    case 'month':
      if (plan.interval_count === 1) {
        return SubscriptionType.MONTHLY;
      }

      if (plan.interval_count === 6) {
        return SubscriptionType.BIANNUAL;
      }

      // is this needed?
      return `${plan.interval_count}months`;

    case 'year':
      return SubscriptionType.ANNUAL;
  }
};

export const getPricePerWeek = (plan: StripePlan): { price: number; rounded?: WeeklyPriceRoundDirection } =>
  getGeneralPricePerWeek(plan.currency, getPlanType(plan) as SubscriptionType, Number(plan.discounted_price_cents));

export const findDiscountPercent = (targetPlan: StripePlan, plans: StripePlan[], lengthInMonths: number) => {
  const monthlyPlan = plans.map(plan => ({
    ...plan,
    planType: getPlanType(plan)
  })).find(plan => plan.planType === SubscriptionType.MONTHLY);

  if (!monthlyPlan) {
    return null;
  }

  return Math.floor(Math.abs(targetPlan.full_price_cents / (monthlyPlan.full_price_cents * lengthInMonths) - 1) * 100);
};

export const getSavePercent = (plan: StripePlan, plans: StripePlan[]) => {
  switch (getPlanType(plan)) {
    case SubscriptionType.LIFETIME:
      return null;

    case SubscriptionType.BIANNUAL:
      return findDiscountPercent(plan, plans, 6);

    case SubscriptionType.ANNUAL:
      return findDiscountPercent(plan, plans, 12);

    case SubscriptionType.MONTHLY:
      return null;
  }
};

export const getStripeProducts = createSelector(
  getStripePlans,
  (plans: StripePlan[]): StripeProduct[] => {
    if (!plans || !plans.length) {
      return [];
    }

    const products = plans.map((plan) => {
      const currency = plan.currency.toUpperCase();

      return {
        ...plan,
        type: 'subscription',
        currency: getCurrencyLabel(currency),
        length: `${plan.name}`,
        priceFormatted: formatPrice(currency, plan.discounted_price_cents / 100),
        fullPriceFormatted: plan.full_price_cents !== plan.discounted_price_cents
          ? formatPrice(currency, plan.full_price_cents / 100)
          : null,
        pricePerWeekFormatted: formatPrice(currency, getPricePerWeek(plan)?.price, 1, 0),
        pricePerWeekRounded: getPricePerWeek(plan)?.rounded,
        priceMicros: plan.discounted_price_cents ? plan.discounted_price_cents * 10000 : plan.full_price_cents * 10000,
        savePercent: getSavePercent(plan, plans)
      };
    });

    return products;
  }
);
