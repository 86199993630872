<ng-container *ngIf="!_entry.private">
  <div class="post-separator" *ngIf="separator === 'top'"></div>
  <div [@fadeIn]="_entry.isNew ? 'fade' : null" class="entry-container" [class.desktop]="config.usingDesktopOnWeb">
    <div class="author-container">
      <div class="author-avatar-container">
        <ion-avatar class="author-avatar">
          <img src="{{_entry.avatar}}" alt="">
        </ion-avatar>
      </div>
      <div class="author-info-container">
        <div class="inline-info" [class.pointer]="isCoach">
          <div class="name" (click)="openProfilePage()">{{_entry.username}}</div>
          <a class="filter-posts" *ngIf="userFilterEnabled" (click)="filterPosts.emit()">
            [{{ 'social.posts.filter_user_set' | translate: {username: _entry.username} }}]
          </a>
          <ion-button *ngIf="isCoach && canCopyUrl"
                      icon-only
                      class="clipboard-button"
                      (click)="copyPostUrl(_entry.id)"
                      fill="clear"
                      size="small">
            <ion-icon name="link" size="small"></ion-icon>
          </ion-button>
        </div>
        <cl-label [text]="_entry.standalone_client"></cl-label>
        <div class="days-quit">{{_entry.quit_date | daysQuitRelativeTime}}</div>
      </div>
      <div class="post-date">{{_entry.created_at | relativeTime}}</div>
    </div>
    <div class="categories-container" *ngIf="type === 'post'">
      <div class="category" *ngFor="let category of _entry.categories">{{category}}</div>
      <div class="category" *ngFor="let label of _entry.labels">{{label}}</div>
    </div>
    <p class="message copyable" *ngIf="isTruncatable">
      <cl-truncated-post [text]="_entry.content" (textClick)="onCommentClick($event)"></cl-truncated-post>
    </p>
    <p class="message copyable" [innerHTML]="_entry.content" *ngIf="!isTruncatable"></p>
    <div class="social-stats-container">
      <ion-row class="entry-actions ion-justify-content-end">
        <ion-button fill="clear" *ngIf="currentUserHasOwnerShip" (click)="onMoreClick()">
          <ion-icon slot="icon-only" name="ellipsis-horizontal" class="more"></ion-icon>
        </ion-button>
        <ion-button fill="clear" *ngIf="commentable" (click)="onCommentClick($event)">
          <ion-icon slot="start" src="assets/imgs/social/comment.svg"></ion-icon>
          {{_entry.comments_count}}
          <ng-container *ngIf="_entry.likes.total_count > 1">{{'social.posts.comments' | translate}} </ng-container>
          <ng-container *ngIf="_entry.likes.total_count < 2">{{'social.posts.comment' | translate}} </ng-container>
        </ion-button>
        <ion-button fill="clear" (click)="onLikeClick()">
          <ion-icon slot="start" src="assets/imgs/social/like.svg" [ngClass]="{'liked': _entry.current_user_liked}"></ion-icon>
          {{_entry.likes.total_count}}
          <ng-container *ngIf="_entry.likes.total_count > 1">{{'social.posts.likes' | translate}} </ng-container>
          <ng-container *ngIf="_entry.likes.total_count < 2">{{'social.posts.like' | translate}} </ng-container>
        </ion-button>
      </ion-row>
      <cl-likes-avatars [likes]="_entry.likes"></cl-likes-avatars>
    </div>
  </div>
  <div class="post-separator" *ngIf="separator === 'bottom'"></div>
</ng-container>

<ng-container *ngIf="_entry.private">
  <div class="entry-container" [class.desktop]="config.usingDesktopOnWeb">
    <div class="author-container private">
      <div class="author-info-container">
        <div class="name" (click)="openProfilePage()" [class.pointer]="isCoach">{{_entry.username}}</div>
        <div class="post-date">{{_entry.created_at | relativeTime}}</div>
      </div>
    </div>
    <p class="message copyable" *ngIf="isTruncatable">
      <cl-truncated-post [text]="_entry.content" (textClick)="onCommentClick($event)"></cl-truncated-post>
    </p>
    <p class="message copyable" [innerHTML]="_entry.content" *ngIf="!isTruncatable"></p>
    <div class="social-stats-container">
      <ion-row class="entry-actions ion-justify-content-end">
        <ion-button fill="clear" *ngIf="currentUserHasOwnerShip" (click)="onMoreClick()">
          <ion-icon slot="icon-only" name="ellipsis-horizontal" class="more"></ion-icon>
        </ion-button>
      </ion-row>
    </div>
  </div>
  <div class="post-separator" *ngIf="separator === 'bottom'"></div>
</ng-container>
