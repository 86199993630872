import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-stepped-progress-bar',
  styleUrls: ['stepped-progress-bar.component.scss'],
  template: `
    <div class="steps-wrapper" [ngClass]="stepsCountClass + ' ' + currentStepClass">
      <div class="progress"></div>
      <div class="steps-bar">
        <div class="step-point"
             *ngFor="let number of stepsNumbers"
             [ngClass]="{
      'completed-step': current > number,
      'current-step': current === number,
      'incomplete-step': current < number
    }">
          {{number}}
        </div>
      </div>
      <div class="clear-float"></div>
    </div>
  `
})
export class SteppedProgressBarComponent {
  @Input() steps = 1;
  @Input() current = 1;

  get stepsCountClass() {
    return `steps-count-${this.steps}`;
  }

  get currentStepClass() {
    return `current-step-${this.current}`;
  }

  get stepsNumbers() {
    const steps = [];

    for (let i = 1; i <= this.steps; i++) {
      steps.push(i);
    }

    return steps;
  }

}
