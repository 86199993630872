import { Component, EventEmitter, Input, Output, AfterViewInit } from '@angular/core';
import { LiveLesson } from '../../../../../store/session/selectors/program.selectors';
import * as programmActions from '../../../../../store/session/actions/program.actions';
import { Store } from '@ngrx/store';
import { State } from '../../../../../store/state.reducer';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'cl-anxiety-quiz',
  styleUrls: ['anxiety-quiz.component.scss'],
  template: `
    <div class="sub-title-holder lateral-padding">
      <h4>{{"gad7.intro_question" | translate}}</h4>
    </div>

    <div class="content-wrapper lateral-padding">
      <div *ngFor="let question of questions; let i = index" class="question-container">
        <h4 class="question">{{i + 1}}. {{("gad7.question_" + (i + 1)) | translate}}</h4>
        <cl-list-single-select
          [items]="answerVariants" class="answer" isGlobalUse="true"
          [selected]="answers['q' + (i + 1)]"
          (selectItem)="setAnswer($event, i + 1)">
        </cl-list-single-select>
      </div>
    </div>

    <ion-row class="action-buttons-holder bottom-button">
      <ion-col>
        <cl-next-lesson-button
          [lesson]="lesson"
          (next)="nextLesson()"
          [enabled]="nextActive">
        </cl-next-lesson-button>
      </ion-col>
    </ion-row>
  `
})
export class AnxietyQuizComponent implements AfterViewInit {
  @Input() lesson: LiveLesson;

  @Output() next = new EventEmitter();
  @Output() init = new EventEmitter();

  questions = new Array(7).fill(1);

  answerVariants = [
    {id: 0, name: 'gad7.answer_1'},
    {id: 1, name: 'gad7.answer_2'},
    {id: 2, name: 'gad7.answer_3'},
    {id: 3, name: 'gad7.answer_4'}
  ];
  answers = {
    q1: null,
    q2: null,
    q3: null,
    q4: null,
    q5: null,
    q6: null,
    q7: null
  };
  score = 0;
  nextActive = false;

  constructor(
    public store: Store<State>
  ) {
  }

  ngAfterViewInit() {
    this.init.emit();
  }

  nextLesson() {
    this.calculateTotalScore();
    const datePipe = new DatePipe('en-US');

    const data = {
      date: datePipe.transform(new Date(), 'yyyy-MM-dd'),
      recorded_at: new Date().toISOString(),
      answers: this.answers,
      score: this.score
    };

    this.store.dispatch(new programmActions.TrackAnxietyQuiz(data));
    this.next.emit(this.lesson);
  }

  setAnswer(answer, question) {
    this.answers['q' + question] = answer;

    this.nextActive = true;

    for (const answersKey in this.answers) {
      if (!Number.isInteger(this.answers[answersKey])) {
        this.nextActive = false;
      }
    }
  }

  calculateTotalScore() {
    this.score = 0;

    for (let i = 1; i <= 7; i++) {
      if (this.answers['q' + i] === null) {
        return false;
      }

      this.score += this.answers['q' + i];
    }
  }
}
