import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { getTriggers } from '../../../store/normalized/selectors/list-items.selectors';
import { State } from '../../../store/state.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-trigger-capture-lesson',
  styleUrls: ['trigger-capture-lesson.component.scss'],
  template: `
    <cl-pick-trigger
      [triggers]="triggers$ | async"
      (selectItem)="doAction($event)"
      [multipleSelection]='true'
      [selected]="triggerChoices">
    </cl-pick-trigger>
  `
})
export class TriggerCaptureLessonComponent implements OnInit {

  triggerChoices = [];
  @Output() enableNext = new EventEmitter();
  @Output() disableNext = new EventEmitter();
  triggers$ = this.store.select(getTriggers);

  constructor(
    private store: Store<State>
  ) {
  }

  ngOnInit() {
    this.disableNext.emit();
  }

  doAction(event) {
    this.triggerChoices = event;
    this.enableNext.emit();
  }
}
