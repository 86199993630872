<ion-content #ionContent>
  <form [formGroup]="form">
    <section>
      <div class="padding">
        <h2>{{'account_deletion.reason.title' | translate}}</h2>
        <h3>{{'account_deletion.reason.subtitle' | translate}}</h3>
      </div>

      <ion-list>
        <ion-item lines="none">
          <ion-label>{{'account_deletion.reason.list.not_used_anymore' | translate}}</ion-label>
          <ion-checkbox class="light" mode="android" slot="start" [value]="1" formControlName="reason_not_used"></ion-checkbox>
        </ion-item>

        <ion-item lines="none">
          <ion-label>{{'account_deletion.reason.list.learned_everything' | translate}}</ion-label>
          <ion-checkbox class="light" mode="android" slot="start" [value]="2" formControlName="reason_learned"></ion-checkbox>
        </ion-item>

        <ion-item lines="none">
          <ion-label>{{'account_deletion.reason.list.not_effective' | translate}}</ion-label>
          <ion-checkbox class="light" mode="android" slot="start" [value]="3" formControlName="reason_not_effective"></ion-checkbox>
        </ion-item>
        <div class="other-option-holder">
          <ion-item lines="none" class="other-option-item">
            <ion-label>{{'account_deletion.reason.list.something_else' | translate}}</ion-label>
            <ion-checkbox class="light" mode="android" slot="start" [value]="4" formControlName="reason_other"></ion-checkbox>
          </ion-item>

          <div class="textarea-holder" *ngIf="form.get('reason_other').value === true">
            <ion-textarea rows="3" [placeholder]="'account_deletion.reason.what_else' | translate" formControlName="other_reason" >
            </ion-textarea>
          </div>
        </div>
      </ion-list>
    </section>
  </form>
  <div class="scrolled-to-bottom-observer" [class.is-android]="platform.is('android')" #scrolledToBottomObserver></div>
</ion-content>

<ion-footer  [class.sticky]="isSlideScrolledToBottom$ | async" [class.desktop]="!config.isDevice">
  <ion-row class="padding">
    <ion-col size="5">
      <cl-action-button
        [label]="'common.cancel' | translate"
        (click)="closeModal()"
        color="white"
        [canClick]="true"
        [fullWidth]="true">
      </cl-action-button>
    </ion-col>
    <ion-col size="7">
      <cl-action-button
        color="danger"
        [label]="'account_deletion.delete_account' | translate"
        [canClick]="!form.invalid"
        (click)="deleteAccount()"
        [fullWidth]="true">
      </cl-action-button>
    </ion-col>
  </ion-row>
</ion-footer>
