import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CravingTool } from '../../../store/normalized/schemas/craving-tool.schema';

@Component({
  selector: 'cl-mindful-eating-exercise-craving-tool',
  styleUrls: ['../craving-tool.scss', 'mindful-eating-exercise.component.scss'],
  template: `
    <ion-content>
      <div class="first-steps lateral-padding">
        <h2 class="step-intro intro-question">{{'wizards.lets_eat_mindfully' | translate}}</h2>
        <div class="use-grid">
          <cl-inner-item
            *ngFor="let step of mindfulEatingSteps; index as stepNumber"
            [active]="mindfulEatingStep === stepNumber"
            [completed]="mindfulEatingStep > stepNumber"
            (changeItem)="completeMindfulEatingStep(stepNumber)"
            [label]="step | translate">
          </cl-inner-item>
        </div>
      </div>
    </ion-content>

    <ion-footer>
      <div class="controls-holder padding-bottom-iphone-x">
        <ion-row class="buttons-holder lateral-padding">
          <ion-col size="6" offset="3">
            <cl-next-button
              [ngClass]="{'dark-bg': darkBackground}"
              label="common.next"
              [canClick]="mindfulEatingNextEnabled"
              (action)="triggerNext()">
            </cl-next-button>
          </ion-col>
        </ion-row>
      </div>
    </ion-footer>
  `
})
export class MindfulEatingExerciseComponent implements OnInit {

  mindfulEatingSteps = [
    'craving_tool.pay_attention_to_why_you_want_to_eat',
    'craving_tool.pay_attention_to_what_you_will_eat',
    'craving_tool.pay_attention_to_each_bite',
    'craving_tool.keep_paying_attention_until_you_finish',
    'craving_tool.what_does_your_body_feel_like',
    'craving_tool.what_emotions_and_thoughts_are_you_having'
  ];

  @Input() currentCravingTool: CravingTool;
  @Input() darkBackground = false;
  @Output() next = new EventEmitter();

  mindfulEatingStep = 0;

  ngOnInit(): void {
    this.setEatMindfully();
  }

  setEatMindfully() {
    this.currentCravingTool.eat_mindfully = true;
  }

  get mindfulEatingNextEnabled() {
    return this.mindfulEatingStep >= this.mindfulEatingSteps.length;
  }

  completeMindfulEatingStep(step) {
    if (step > this.mindfulEatingStep) {
      return;
    }
    this.mindfulEatingStep = this.mindfulEatingStep > step ? step : step + 1;
  }

  nextMindfulEatingStep() {
    this.mindfulEatingStep += 1;
  }

  triggerNext() {
    this.next.emit();
  }

}
