// sync actions - to load required data from the API

import { Action } from '@ngrx/store';

// action cable
export const INIT_ACTIONCABLE = '[sync] Init Action Cable';
export const LOAD_ACTIONCABLE = '[sync] Load Action Cable';

// action cable connected
export const APPLICATION_DATA_SYNC_CHANNEL_CONNECTED = '[sync] Application Data Sync Channel Connected';

export class InitActionCable implements Action {
  readonly type = INIT_ACTIONCABLE;
}

export class LoadActionCable implements Action {
  readonly type = LOAD_ACTIONCABLE;
}

export class ApplicationDataSyncChannelConnected implements Action {
  readonly type = APPLICATION_DATA_SYNC_CHANNEL_CONNECTED;
}

// request sync actions
export const SYNC_EVERYTHING = '[sync] Everything';
export const SYNC_EVERYTHING_COMPLETE = '[sync] Everything Complete';
export const SYNC_EVERYTHING_FAIL = '[sync] Full Fail';

export const SYNC_USER = '[sync] User';
export const SYNC_SUBSCRIPTION = '[sync] Subscription';
export const SYNC_USER_PROGRAM_SESSION = '[sync] User Program Session';

// TODO: Remove Success/Fail actions and handle in the queue if possible or

// User Reminders
export const SYNC_USER_REMINDERS = '[sync] User Reminders';
export const SYNC_USER_REMINDERS_SUCCESS = '[sync] User Reminders Success';
export const SYNC_USER_REMINDERS_FAIL = '[sync] User Reminders Fail';

export const SYNC_PROGRAM = '[sync] Program';
export const SYNC_PROGRAM_SUCCESS = '[sync] Program Success';
export const SYNC_PROGRAM_FAIL = '[sync] Program Fail';

export const SYNC_USER_PROGRAM = '[sync] User Program';
export const SYNC_USER_PROGRAM_COMPLETE = '[sync] User Program Complete';

// User Progress
export const SYNC_USER_PROGRESS = '[sync] User Progress';
export const SYNC_USER_PROGRESS_SUCCESS = '[sync] User Progress Success';
export const SYNC_USER_PROGRESS_FAIL = '[sync] User Progress Fail';

// Bonus Exercises
export const SYNC_BONUS_EXERCISES = '[sync] Bonus Exercises';
export const SYNC_BONUS_EXERCISES_SUCCESS = '[sync] Bonus Exercises Success';
export const SYNC_BONUS_EXERCISES_FAIL = '[sync] Bonus Exercises Fail';

// User Bootstrap
export const SYNC_USER_BOOTSTRAP = '[sync] User Bootstrap';
export const SYNC_USER_BOOTSTRAP_SUCCESS = '[sync] User Bootstrap Success';
export const SYNC_USER_BOOTSTRAP_FAIL = '[sync] User Bootstrap Fail';

export const SYNC_USER_GOALS = '[sync] User Goals';
export const SYNC_USER_GOALS_COMPLETE = '[sync] User Goals Complete';

export const SYNC_USER_FAVS = '[sync] User Favorites';
export const SYNC_USER_FAVS_COMPLETE = '[sync] User Favorites Complete';

export const SYNC_USER_ACCOUNT = '[sync] User Account';
export const SYNC_USER_ACCOUNT_COMPLETE = '[sync] User Account Complete';

export const SYNC_IRIDIUM_TOKEN = '[sync] Iridium Token';
export const SYNC_IRIDIUM_TOKEN_COMPLETE = '[sync] Iridium Token Complete';

export const SYNC_COMMUNITY_POSTS = '[sync] Community Posts';
export const SYNC_COMMUNITY_BOOKMARKED_POSTS = '[sync] Community Bookmarked Posts';
export const SYNC_COMMUNITY_JOURNAL = '[sync] Community Journal';

export const SYNC_CHECKINS = '[sync] Checkins';
export const SYNC_STRESS_TESTS = '[sync] Stress Tests';
export const SYNC_CRAVING_METERS = '[sync] Craving Meters';
export const SYNC_DIS_O_METERS = '[sync] Dis O Meters';
export const SYNC_AWARENESS_QUIZZES = '[sync] Awareness Quizzes';
export const SYNC_ANXIETY_QUIZZES = '[sync] Anxiety Quizzes';
export const SYNC_PRUNE_MEDIA_FILES = '[sync] Prune Media Files';
export const SYNC_CIGS = '[sync] Cig count';
export const SYNC_WEIGHT_ACTIVITIES = '[sync] Weight Activities';
export const SYNC_MINUTES_ACTIVITIES = '[sync] Minutes Activities';
export const SYNC_CONNECTED_APPLICATIONS = '[sync] Connected Applications';
export const SYNC_PROGRAM_DAYS_BY_DAY = '[sync] Program Days by Day';

export const SYNC_VIDEO_CHAT_SCHEDULES = '[sync] Video Chat Schedules';

export const SYNC_THEME_WEEKS = '[sync] Theme Weeks';

export const SYNC_WORRY_TOOL = '[sync] Worry Tool';

export const SYNC_GOALS = '[sync] Goals';
export const SYNC_TRIGGERS = '[sync] Triggers';
export const SYNC_BODY_PARTS = '[sync] Body Parts';
export const SYNC_BODY_SIDES = '[sync] Body Sides';
export const SYNC_SENSATIONS = '[sync] Sensations';
export const SYNC_FEELINGS = '[sync] Feelings';


// load data actions
export const SYNC_LOAD_EVERYTHING = '[sync] Load Everything';

export const RESET_CURRENT_DAY = '[sync] Reset Current Day';

export const RESUME_APP = '[sync] Resume app';

export const SYNC_PROGRAM_DAYS = '[sync] Program Days';

export const SYNC_EXERCISES = '[sync] Exercises';

export class StreamStatus {
  streamOnly: boolean;
}

export class SyncEverything implements Action {
  readonly type = SYNC_EVERYTHING;
}

export class SyncLoadEverything implements Action {
  readonly type = SYNC_LOAD_EVERYTHING;

  constructor(public payload: any) {
  }
}

export class SyncPruneMediaFiles implements Action {
  readonly type = SYNC_PRUNE_MEDIA_FILES;
  constructor(public payload: StreamStatus = { streamOnly: false }) {
  }
}

export class SyncEverythingComplete implements Action {
  readonly type = SYNC_EVERYTHING_COMPLETE;
}

export class SyncEverythingSuccess implements Action {
  readonly type = SYNC_EVERYTHING_FAIL;

  constructor(public payload: any) {

  }
}

export class SyncUser implements Action {
  readonly type = SYNC_USER;
}

export class SyncSubscription implements Action {
  readonly type = SYNC_SUBSCRIPTION;

  constructor(public payload: string = null) {
  }
}

export class SyncUserProgramSession implements Action {
  readonly type = SYNC_USER_PROGRAM_SESSION;
}

export class SyncUserReminders implements Action {
  readonly type = SYNC_USER_REMINDERS;

  constructor(public payload: string = null) {
  }
}

export class SyncUserRemindersSuccess implements Action {
  readonly type = SYNC_USER_REMINDERS_SUCCESS;
}

export class SyncUserRemindersFail implements Action {
  readonly type = SYNC_USER_REMINDERS_FAIL;
}

export class SyncProgram implements Action {
  readonly type = SYNC_PROGRAM;

  constructor(public payload: string = null) {}
}

export class SyncProgramSuccess implements Action {
  readonly type = SYNC_PROGRAM_SUCCESS;

  constructor(public payload: string = null) {}
}

export class SyncProgramFail implements Action {
  readonly type = SYNC_PROGRAM_FAIL;

  constructor(public payload: string = null) {}
}

export class SyncUserProgram implements Action {
  readonly type = SYNC_USER_PROGRAM;

  constructor(public payload: string = null) {}
}

export class SyncUserProgramComplete implements Action {
  readonly type = SYNC_USER_PROGRAM_COMPLETE;
}

export class SyncUserProgress implements Action {
  readonly type = SYNC_USER_PROGRESS;

  constructor(public payload: string = null) {
  }
}

export class SyncUserProgressSuccess implements Action {
  readonly type = SYNC_USER_PROGRESS_SUCCESS;
}

export class SyncUserProgressFail implements Action {
  readonly type = SYNC_USER_PROGRESS_FAIL;
}

export class SyncBonusExercises implements Action {
  readonly type = SYNC_BONUS_EXERCISES;

  constructor(public payload: string = null) {
  }
}

export class SyncBonusExercisesSuccess implements Action {
  readonly type = SYNC_BONUS_EXERCISES_SUCCESS;
}

export class SyncBonusExercisesFail implements Action {
  readonly type = SYNC_BONUS_EXERCISES_FAIL;
}

export class SyncUserBootstrap implements Action {
  readonly type = SYNC_USER_BOOTSTRAP;

  constructor(public payload: string = null) {
  }
}

export class SyncUserBootstrapSuccess implements Action {
  readonly type = SYNC_USER_BOOTSTRAP_SUCCESS;
}

export class SyncUserBootstrapFail implements Action {
  readonly type = SYNC_USER_BOOTSTRAP_FAIL;
}

export class SyncUserGoals implements Action {
  readonly type = SYNC_USER_GOALS;

  constructor(public payload: string = null) {
  }
}

export class SyncUserGoalsComplete implements Action {
  readonly type = SYNC_USER_GOALS_COMPLETE;
}

export class SyncUserFavs implements Action {
  readonly type = SYNC_USER_FAVS;

  constructor(public payload: string = null) {
  }
}

export class SyncUserFavsComplete implements Action {
  readonly type = SYNC_USER_FAVS_COMPLETE;
}

export class SyncUserAccount implements Action {
  readonly type = SYNC_USER_ACCOUNT;

  constructor(public payload: string = null) {
  }
}

export class SyncUserAccountComplete implements Action {
  readonly type = SYNC_USER_ACCOUNT_COMPLETE;
}

export class SyncCommunityPosts implements Action {
  readonly type = SYNC_COMMUNITY_POSTS;

  constructor(public payload: string = null) {
  }
}

export class SyncCommunityBookmarkedPosts implements Action {
  readonly type = SYNC_COMMUNITY_BOOKMARKED_POSTS;

  constructor(public payload: string = null) {
  }
}

export class SyncCommunityJournal implements Action {
  readonly type = SYNC_COMMUNITY_JOURNAL;

  constructor(public payload: string = null) {
  }
}

export class SyncIridiumToken implements Action {
  readonly type = SYNC_IRIDIUM_TOKEN;
}

export class SyncIridiumTokenComplete implements Action {
  readonly type = SYNC_IRIDIUM_TOKEN_COMPLETE;
}

export class SyncCheckins implements Action {
  readonly type = SYNC_CHECKINS;

  constructor(public payload: string = null) {
  }
}

export class SyncCigCount implements Action {
  readonly type = SYNC_CIGS;

  constructor(public payload: string = null) {
  }
}

export class SyncStressTests implements Action {
  readonly type = SYNC_STRESS_TESTS;

  constructor(public payload: string = null) {
  }
}

export class SyncCravingMeters implements Action {
  readonly type = SYNC_CRAVING_METERS;

  constructor(public payload: string = null) {
  }
}

export class SyncDisOMeters implements Action {
  readonly type = SYNC_DIS_O_METERS;

  constructor(public payload: string = null) {
  }
}

export class SyncAwarenessQuizzes implements Action {
  readonly type = SYNC_AWARENESS_QUIZZES;

  constructor(public payload: string = null) {
  }
}

export class SyncAnxietyQuizzes implements Action {
  readonly type = SYNC_ANXIETY_QUIZZES;

  constructor(public payload: string = null) {
  }
}

export class SyncProgramDaysByDay implements Action {
  readonly type = SYNC_PROGRAM_DAYS_BY_DAY;
}

export class SyncVideoChatSchedules implements Action {
  readonly type = SYNC_VIDEO_CHAT_SCHEDULES;

  constructor(public payload: string = null) {
  }
}

export class ResetCurrentDay implements Action {
  readonly type = RESET_CURRENT_DAY;

  constructor(public payload: string) {}
}

export class ResumeApp implements Action {
  readonly type = RESUME_APP;
}

export class SyncThemeWeeks implements Action {
  readonly type = SYNC_THEME_WEEKS;

  constructor(public payload: string) {}
}

export class SyncWeightActivities implements Action {
  readonly type = SYNC_WEIGHT_ACTIVITIES;

  constructor(public payload?: string) {}
}

export class SyncMinutesActivities implements Action {
  readonly type = SYNC_MINUTES_ACTIVITIES;

  constructor(public payload: string) {}
}
export class SyncConnectedApplications implements Action {
  readonly type = SYNC_CONNECTED_APPLICATIONS;
}


export class SyncWorryTool implements Action {
  readonly type = SYNC_WORRY_TOOL;

  constructor(public payload: string) {}
}

export class SyncGoals implements Action {
  readonly type = SYNC_GOALS;
}

export class SyncTriggers implements Action {
  readonly type = SYNC_TRIGGERS;
}

export class SyncBodyParts implements Action {
  readonly type = SYNC_BODY_PARTS;
}

export class SyncBodySides implements Action {
  readonly type = SYNC_BODY_SIDES;
}

export class SyncSensations implements Action {
  readonly type = SYNC_SENSATIONS;
}

export class SyncFeelings implements Action {
  readonly type = SYNC_FEELINGS;
}

export class SyncProgramDays implements Action {
  readonly type = SYNC_PROGRAM_DAYS;

  constructor(
    public receivedTimestamp: string,
    public lastTimestamp: string
  ) {}
}

export class SyncExercises implements Action {
  readonly type = SYNC_EXERCISES;

  constructor(
    public receivedTimestamp?: string,
    public lastTimestamp?: string
  ) {}
}


export type SyncActions =
  | InitActionCable
  | LoadActionCable
  | ApplicationDataSyncChannelConnected
  | SyncEverything
  | SyncLoadEverything
  | SyncEverythingComplete
  | SyncEverythingSuccess
  | SyncUser
  | SyncUserReminders
  | SyncUserRemindersSuccess
  | SyncUserRemindersFail
  | SyncProgram
  | SyncProgramSuccess
  | SyncProgramFail
  | SyncUserProgram
  | SyncUserProgramComplete
  | SyncUserProgress
  | SyncUserProgressSuccess
  | SyncUserProgressFail
  | SyncBonusExercises
  | SyncBonusExercisesSuccess
  | SyncBonusExercisesFail
  | SyncUserBootstrap
  | SyncUserBootstrapSuccess
  | SyncUserBootstrapFail
  | SyncUserGoals
  | SyncUserGoalsComplete
  | SyncUserAccount
  | SyncUserAccountComplete
  | SyncUserFavs
  | SyncUserFavsComplete
  | SyncSubscription
  | SyncCommunityPosts
  | SyncCommunityBookmarkedPosts
  | SyncCommunityJournal
  | SyncCheckins
  | SyncCigCount
  | SyncStressTests
  | SyncCravingMeters
  | SyncDisOMeters
  | SyncAwarenessQuizzes
  | SyncAnxietyQuizzes
  | SyncProgramDaysByDay
  | SyncVideoChatSchedules
  | SyncWeightActivities
  | SyncMinutesActivities
  | ResetCurrentDay
  | ResumeApp
  | SyncThemeWeeks
  | SyncWorryTool
  | SyncGoals
  | SyncProgramDays
  | SyncExercises
  | SyncIridiumToken
  | SyncIridiumTokenComplete
  | SyncTriggers
  | SyncBodyParts
  | SyncBodySides
  | SyncSensations
  | SyncFeelings;
