import { createSelector } from '@ngrx/store';
import { getUserProfileState } from '../../../../app/store';
import * as userProfileReducer from '../reducers/user-profile.reducer';

export const getUserProfile = createSelector(
  getUserProfileState,
  userProfileReducer.getUserProfile
);

export const getProfileUpdating = createSelector(
  getUserProfileState,
  userProfileReducer.getProfileUpdating
);

export const getProfileUpdated = createSelector(
  getUserProfileState,
  userProfileReducer.getProfileUpdated
);
