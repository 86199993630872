<cl-waves-background class="interstitial-waves-background interstitial-purple">

  <ion-content (click)="onClick()">

    <ion-header>
      <cl-header-nav-buttons
        class="page-header"
        color="blank"
        [backButton]="false">
      </cl-header-nav-buttons>
    </ion-header>

    <ion-grid class="page-wrapper">
      <ion-row class="icon-holder">
        <ion-col class="ion-text-center ion-no-padding">
          <img [src]="'assets/imgs/badge/lock-nobg.png'">
        </ion-col>
      </ion-row>

      <div class="content">
        <ion-grid fixed>
          <ion-row class="title-holder lateral-padding ion-padding" *ngIf="navParams.get('title') || interstitialSettings.title"
                    [ngClass]="{'large-text': navParams.get('largeText'), 'no-vertical-padding': navParams.get('bonusExercisesLink')}">
            <ion-col class="ion-no-padding">
              <h1>{{navParams.get('title') || interstitialSettings.title | translate}}</h1>

              <h2>{{ navParams.get('subTitle') | translate }}</h2>
            </ion-col>
          </ion-row>

          <ion-row class="lock-details-holder" *ngIf="showLockDetails">
            <ion-col>
              <!-- <div *ngIf="currentModuleInProgress"> -->
              <div *ngIf="lockedSubType === 'module_in_progress'">
                <p>{{ 'interstitials.module_in_progress' | translate: { currentModule: currentModuleTitle} }}</p><br/>
                <p>{{ 'interstitials.youll_get_access_after_current_module' | translate }}</p>
              </div>

              <!-- Playing module is the next available module -->
              <!-- <div *ngIf="!currentModuleInProgress && (playModule === lastCompletedModule + 1)"> -->
              <div *ngIf="lockedSubType === 'module_will_unlock_tomorrow'">
                <p class="unlock-timing">
                  <b>{{ 'interstitials.module_will_unlock_in' | translate: { unlockModule: playModuleTitle } }}</b>
                  <br>
                  {{ 'interstitials.hours_and_minutes' | translate: { hours: hours, minutes: minutes } }}
                </p>
                <p class="learn-more-btn outline" (click)="onLearnMore($event)" >
                  {{ 'common.learn_more' | translate}}
                </p>
              </div>

              <!-- Playing module is far beyond current module, but is reachable through accelerated -->
              <!-- <div *ngIf="!currentModuleInProgress && (lastCompletedModule + 1 <= acceleratedTo)"> -->
              <div *ngIf="lockedSubType === 'accelerated_module_available'">
                <p>{{ 'interstitials.module_in_progress' | translate:  { currentModule: playModuleTitle } }}</p><br/>
                <p>{{ 'interstitials.youll_get_access_after_current_module' | translate }}</p>
              </div>

              <!-- Playing module is far beyond current module, and is also beyond allowed accelerated -->
              <!-- <div *ngIf="!currentModuleInProgress && (playModule > lastCompletedModule + 1) && (lastCompletedModule + 1 > acceleratedTo)"> -->
              <div *ngIf="lockedSubType === 'module_completely_locked'">
                <p>
                  <b>{{ 'interstitials.youll_get_access_after_current_module' | translate }}</b>
                </p>
                <br>
                <br>
                <p class="learn-more-btn outline" (click)="onLearnMore($event)" >
                  {{ 'common.learn_more' | translate}}
                </p>
              </div>
            </ion-col>
          </ion-row>

          <ion-row class="instructions-holder lateral-padding ion-padding-horizontal">
            <ion-col>
              <p *ngIf="bonusExercise && playModule">{{navParams.get('notes') | translate: {exercise: bonusExercise, module: playModule} }}</p>
              <p *ngIf="!bonusExercise || !playModule">{{navParams.get('notes') | translate}}</p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <ion-row class="exit-info padding-bottom-iphone-x">
        <ion-col class="ion-text-center">
          <p class="dismiss-btn" *ngIf="!showGoToCurrentModule">
            {{ 'common.continue' | translate }}
          </p>
          <!-- <p class="dismiss-btn" *ngIf="currentModuleInProgress || lastCompletedModule + 1 <= acceleratedTo" (click)="goToCurrentModule($event)"> -->
          <p class="dismiss-btn" *ngIf="showGoToCurrentModule" (click)="goToCurrentModule($event)">
            {{ 'interstitials.go_to_module' | translate }}
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>

  </ion-content>
</cl-waves-background>
