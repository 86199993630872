import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { State } from '../../store/state.reducer';

import { DatePipe } from '@angular/common';

import { distinctUntilChanged, filter, take, tap } from 'rxjs/operators';

import { combineLatest } from 'rxjs';
import * as toolsActions from '../../store/session/actions/tools.actions';
import { getCheckinExercises, getCheckinQuestions } from '../../store/normalized/selectors/checkin.selectors';
import { getFeelings } from '../../store/normalized/selectors/list-items.selectors';

import { Checkin } from '../../store/session/models/checkin.model';
import { WizardService } from './wizard.service';
import { LiveModule, getCurrentModule, getPreviousModule } from '../../store/session/selectors/program.selectors';
import * as programActions from '../../store/session/actions/program.actions';
import { exerciseTitleToImg } from '../../constants/checkins.constants';
import { getCigCountToday } from '../../store/session/selectors/cig-tracker.selectors';
import { LoggerService } from '../logger.service';
import { SyncFeelings } from 'src/app/store/session/actions/sync.actions';

// TODO: Questions and feel_before should not be mandatory on the backend anymore
const initialModel = {
  activity_id: 16,
  feeling_id: null,
  check_in_question_id: null,
  check_in_exercise_id: null,
  feel_before: 0,
  feel_now: null,
  anxiety: 0,
  date: null,
  recorded_at: null,
  tag: null,
  playedModule: false
};

@Injectable({
  providedIn: 'root'
})
export class CheckinsService extends WizardService {

  private model: Checkin;

  private todayModule: LiveModule;
  private feelings = [];
  private checkinExercise = null;
  private checkinQuestion = null;

  constructor(
    protected store: Store<State>,
    protected popoverCtrl: PopoverController,
    protected translate: TranslateService,
    protected logger: LoggerService
  ) {
    super(store, popoverCtrl, translate, logger);
  }

  initModel(userReferrer: Checkin['user_referrer']) {
    this.model = {...initialModel, user_referrer: userReferrer || 'application' };

    // load random checkin exercises
    return combineLatest([
      this.store.select(getFeelings),
      this.store.select(getCheckinExercises),
      this.store.select(getCurrentModule),
      this.store.select(getCheckinQuestions)
    ])
      .pipe(
        distinctUntilChanged(),
        tap(([feelings]) => {
          if(!feelings?.length) {
            this.store.dispatch(new SyncFeelings());
          }
        }),
        filter(([feelings]) => Boolean(feelings?.length)),
        take(1),
        tap(([feelings, exercises, currentModule, questions]) => {
          this.feelings = feelings;
          this.todayModule = currentModule;
          this.checkinQuestion = questions[0];
          this.checkinExercise = exercises[Math.floor(Math.random() * exercises.length)];
          this.checkinExercise.graphic = exerciseTitleToImg(this.translate.currentLang, this.checkinExercise.title);
          this.model.check_in_exercise_id = this.checkinExercise.id;
          this.model.check_in_question_id = questions[0].id;
        })
      )
      .toPromise();
  }

  saveNightReflection() {
    // TODO: Move DatePipe to UtilsService
    const datePipe = new DatePipe('en-US');
    this.model.date = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.model.recorded_at = new Date().toISOString();
    this.model.anxiety = this.model.anxiety;
    this.model.feel_before = this.model.feel_before / 100;

    this.store.dispatch(new toolsActions.SaveNightReflection(this.model));
  }

  saveCheckin() {
    // TODO: Move DatePipe to UtilsService
    const datePipe = new DatePipe('en-US');
    this.model.date = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.model.recorded_at = new Date().toISOString();

    this.store.dispatch(new toolsActions.SaveCheckin(this.model));
  }

  saveMorningMotivation() {
    // TODO: Move DatePipe to UtilsService
    const datePipe = new DatePipe('en-US');
    this.model.date = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.model.recorded_at = new Date().toISOString();
    this.model.feel_before = this.model.feel_before / 100;

    this.store.dispatch(new toolsActions.SaveMorningMotivation(this.model));
  }

  getFeelingsList() {
    return this.feelings;
  }

  getCheckinExercise() {
    return this.checkinExercise;
  }

  getCheckinQuestion() {
    return this.checkinQuestion;
  }

  isFeelingValid() {
    return Number.isInteger(this.model.feeling_id);
  }

  setFeeling(id) {
    this.model.feeling_id = id;
  }

  getFeeling() {
    return this.model.feeling_id;
  }

  getFeelNowValue() {
    return this.model.feel_now;
  }

  setFeelNowValue(value) {
    this.model.feel_now = value;
  }

  setAnxiety(anxiety) {
    this.model.anxiety = anxiety;
  }

  getAnxiety() {
    return this.model.anxiety;
  }

  setFeelBefore(feel) {
    this.model.feel_before = feel;
  }

  getTodayModule() {
    return this.todayModule && this.todayModule.type !== 'extend' && this.todayModule.record.title;
  }

  getTodayModuleStarted() {
    return this.todayModule && this.todayModule.type !== 'extend' && !!this.todayModule.lessons[0].completedAt;
  }

  setTag(tag) {
    this.model.tag = tag;
  }

  playModule(isPrevious) {
    if (isPrevious) {
      this.model.playedModule = true;
      this.store.select(getPreviousModule)
        .pipe(take(1))
        .toPromise()
        .then((module) => {
          this.store.dispatch(new programActions.PlayModule(module));
        });
    }
    else {
      this.model.playedModule = true;
      this.store.dispatch(new programActions.PlayModule(this.todayModule));
    }
  }

  getCigCount() {
    return this.store.select(getCigCountToday);
  }
}
