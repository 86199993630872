import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ClarityConfig } from '../..//config/clarity.config';
import { AlertsService } from '../../services/alerts.service';
import { first } from 'rxjs/operators';
import { OpenModal } from '../../store/session/actions/navigation.actions';
import { State } from '../../store';
import { Store } from '@ngrx/store';
import { animate, state, style, transition, trigger } from '@angular/animations';
import * as moment from 'moment';
import { isCurrentProfileCoach } from '../../store/normalized/selectors/user-profile.selectors';
import { Subscription } from 'rxjs';
import { extractPostId } from 'src/app/utils/posts';
import { OpenUserProfileDetails } from 'src/app/store/session/actions/my-coach.actions';
import { BrowserService } from '../../services/browser.service';
import { Clipboard } from '@capacitor/clipboard';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'cl-entry-card',
  styleUrls: ['./entry-card.component.scss'],
  templateUrl: './entry-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
      state('void', style({ opacity: '0' })),
      state('fade', style({ opacity: '1' })),
      transition('void <=> fade', animate('300ms ease-in'))
    ])
  ]
})

export class EntryCardComponent implements OnInit, OnDestroy {
  _entry: any;
  @Input()
  set entry(value: any) {
    this._entry = this.parseContent(value);
  }

  @Input() type = null;
  @Input() commentable = true;
  @Input() isTruncatable: boolean;
  @Input() currentProfileId;
  @Input() filter = null;
  @Input() userFilterEnabled = false;
  @Input() isUserProfileComplete: boolean;
  @Input() separator: string;
  @Input() canCopyUrl = false;

  @Output() openClick = new EventEmitter();
  @Output() like = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() filterPosts = new EventEmitter();
  @Output() openProfile = new EventEmitter();

  isCoach$ = this.store.select(isCurrentProfileCoach);
  isCoach = false;
  coachSubscription: Subscription;
  unlistenPostLinks: () => void;
  unlistenExternalLinks: () => void;

  currentUserHasOwnerShip: boolean;
  moment = moment;

  constructor(
    private actionSheetController: ActionSheetController,
    private alerts: AlertsService,
    private translate: TranslateService,
    private store: Store<State>,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private browser: BrowserService,
    public config: ClarityConfig,
    private platform: Platform,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.coachSubscription = this.isCoach$.subscribe(isCoach => {
      this.isCoach = isCoach;
      this.currentUserHasOwnerShip = this.isCoach || this._entry.profile_id === this.currentProfileId;
    });

    if (!this.isTruncatable) {
      this.unlistenPostLinks = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
        const postId = extractPostId(event);
        if (postId) {
          this.openClick.emit(postId);
        }
      });
    }
    this.unlistenExternalLinks = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      const { href } = event.target;
      // considering that all links with href attr are external
      if (href) {
        event.preventDefault();
        this.browser.goTo(href);
      }
    });
  }

  onCommentClick(event) {
    let post = this._entry;
    if (event && event.id) {
      post = {id: event.id};
    }
    this.openClick.emit(post);
  }

  openProfilePage() {
    if (!this.isCoach) {
      return;
    }
    this.store.dispatch(new OpenUserProfileDetails(this._entry.profile_id));
    this.openProfile.emit();
  }

  onLikeClick() {
    !this.isUserProfileComplete ? this.openIncompleteProfilePopup() : this.like.emit(this._entry);
  }

  openIncompleteProfilePopup() {
    this.store.dispatch(new OpenModal('ProfileCompletionPage'));
  }

  onMoreClick() {
    let customMessage = {
      header : null,
      message: null
    };
    if (this.type === 'post') {
      customMessage = {
        header : 'social.posts.delete_post',
        message : 'social.posts.delete_post_confirmation_message'
      };
    } else if (this.type === 'comment') {
      customMessage = {
        header : 'social.posts.delete_comment',
        message : 'social.posts.delete_comment_confirmation_message'
      };
    }

    this.translate.get(
      [
        customMessage.header,
        customMessage.message,
        'common.no',
        'common.yes',
        'common.cancel'
      ])
      .pipe(first())
      .subscribe(async (translations) => {
        const deleteConfirm = await this.alerts.alertController.create({
          header: translations[customMessage.header],
          message: translations[customMessage.message],
          buttons: [
            {
              text: translations['common.no'],
              role: 'cancel'
            },
            {
              text: translations['common.yes'],
              handler: () => {
                this.delete.emit(this._entry);
              }
            }
          ]
        });

        const actionSheet = await this.actionSheetController.create({
          buttons: [
            {
              text: translations[customMessage.header],
              role: 'destructive',
              icon: 'trash',
              handler: () => {
                deleteConfirm.present();
              }
            },
            {
              text: translations['common.cancel'],
              role: 'cancel',
              icon: 'close-circle'
            }
          ],
          mode: 'md'
        });

        await actionSheet.present();
      });
  }

  copyPostUrl(postId: string) {
    const host = this.config.webappHost;
    const postUrl = `${window.location.protocol}//${host}/#/tabs/posts/${postId}`;
    this.copyToClipboard(postUrl);
    this.toastService.translateConfirm('social.posts.copied_to_clipboard');
  }

  private parseContent(value) {
    let content = value.content;

    if (!content) {
      return '';
    }

    const regex = /<<POST-(\d+)([\s\w']*)>>/g;

    let match;

    // Apparently .matchAll() is not available in all browsers
    // for (const match of content.matchAll(regex)) {
    //   content = content.replace(match[0], () => {
    //     const postId = match[1];
    //     const label = match[2].trim() ? match[2].trim() : 'POST';
    //
    //     // console.log(`Replacing ${tag} with ${postId} and text ${label}`, match);
    //
    //     return `<a class="link-to-post post-${postId}">${label}</a>`;
    //   });
    // }

    // eslint-disable-next-line
    while ((match = regex.exec(content)) !== null) {
      content = content.replace(match[0], () => {
        const postId = match[1];
        const label = match[2].trim() ? match[2].trim() : 'POST';

        // console.log(`Replacing ${tag} with ${postId} and text ${label}`, match);

        return `<a class="link-to-post post-${postId}">${label}</a>`;
      });
    }

    value.content = content;

    return value;
  }

  private copyToClipboard(content: string) {
    if (this.config.isDevice) {
      Clipboard.write({
        string: content
      });
    } else {
      // a common cross-browser hack to put something in user clipboard
      // (https://stackoverflow.com/questions/49102724/angular-5-copy-to-clipboard);
      // because browser Clipboard API hasn't good support among older browsers
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = content;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }
  }

  ngOnDestroy(): void {
    this.coachSubscription && this.coachSubscription.unsubscribe();
    this.unlistenPostLinks && this.unlistenPostLinks();
    this.unlistenExternalLinks && this.unlistenExternalLinks();
  }
}
