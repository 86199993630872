import { Injectable } from '@angular/core';

import { HttpProvider } from './http/http.provider';
import { of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class NrtProvider {

  private orderEndpoint = '/nrt_orders';
  private validateAccessRightEndpoint = '/nrt_orders';

  // private validateAddressEndpoint = '/nrt/validate_address';

  constructor(
    private http: HttpProvider
  ) {
  }

  public order(order: any) {
    return this.http.post(this.orderEndpoint, order);
  }

  // public validateAddress(address: any) {
  //   return this.http.post(this.validateAddressEndpoint, address);
  // }

  public validateAccessRight() {
    return this.http.get(this.validateAccessRightEndpoint)
      .pipe(
        catchError((error) => {
          // API will return a 403 if the user is not eligible for NRT orders
          if (error.status === 403) {
            return throwError({
              success: false,
              message: 'not_allowed'
            });
          } else {
            return throwError({
              success: false,
              message: 'unknown_error'
            });
          }
        })
      );
  }

  public validateAddress(address: any) {
    // const mockInvalidResponse = {
    //   Message: 'The request is invalid.',
    //   ModelState: {
    //     ClientID: ['Invalid client ID passed.'],
    //     'Address.PostalCode': ['PostalCode must be at least 5 characters.']
    //   }
    // };

    // return throwError({status: 400, mockInvalidResponse});

    const mockValidResponse = {
      Zip4Message: 'xxx',
      Address: {
        Address1: 'xxx',
        Address2: 'xxx',
        City: 'xxx',
        StateOrProvince: 'xx',
        PostalCode: 'xxx',
        Zip4: 'xxx'
      }
    };

    return of(mockValidResponse);
  }
}
