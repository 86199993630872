import { TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpBackend, HttpClient } from '@angular/common/http';

let translateLoader: TranslateHttpLoader;

export function setTranslateLoader(handler: HttpBackend) {
  if (!translateLoader) {
    const http = new HttpClient(handler);

    translateLoader = new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }

  return translateLoader;
}

export const translateConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: (setTranslateLoader),
    deps: [HttpBackend]
  }
};
