import { ChangeDetectionStrategy, Component } from '@angular/core';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import * as integrationsActions from '../../store/session/actions/integrations.actions';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-interstitial-dpp-fitbit-tracker',
  styleUrls: ['interstitial-dpp-fitbit-tracker.scss'],
  templateUrl: 'interstitial-dpp-fitbit-tracker.html'
})
export class InterstitialDppFitbitTrackerPage {

  constructor(
    private store: Store<State>
  ) {}

  onClose() {
    this.store.dispatch(new navigationActions.CloseAllModals());
  }

  getFitbitTracker() {
    this.store.dispatch(new navigationActions.CloseAllModals());
    this.store.dispatch(new integrationsActions.OrderFitbitTracker());
  }
}
