export interface Integration {
  key: keyof typeof IntegrationSource;
  connected: boolean;
  connected_at: string;
}

export type FitbitCallbackError = 'already_connected' | 'oauth_error' | 'insufficient_scope' | 'connection_error';

export interface FitbitCallback {
  status: 'success' | 'fail';
  source: IntegrationSource; // fitbit, healthkit, etc
  weight?: string;
  callback: true; // boolean flag just fort UX safety, to ensure this URL comes from a integration callback
  error?: FitbitCallbackError;
}

export enum IntegrationSource {
  manual = 'manual',
  fitbit = 'fitbit',
  healthkit = 'healthkit',
  googlefit = 'googlefit',
  withings = 'withings'
}
