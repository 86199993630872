import { Injectable } from '@angular/core';

import mixpanel from 'mixpanel-browser';
import { Mixpanel, MixpanelPeople } from '@ionic-native/mixpanel/ngx';

import { ClarityConfig } from 'src/app/config/clarity.config';
import { LoggerService } from 'src/app/services/logger.service';
import { Observable, ReplaySubject } from 'rxjs';
import { UpdateUserDataType, UserAnalyticsInterface } from '../analytics.interface';
import { User } from 'src/app/store/normalized/schemas/user.schema';

function rangeifyAge(age: number): string {
  age = Number(age);

  if (!age || isNaN(age) || age <= 0) {
    return '00';
  }

  if (age >= 90) {
    return '90+';
  }

  const d1 = Math.floor(Number(age) / 10); // 9 to 0, 12 to 1, 29 to 2, etc...
  const d2 = d1 + 1;

  return `${d1}0-${d2}0`; // 19 to 10-20, 1 to 00-10, etc...
}

@Injectable({providedIn: 'root'})
export class MixpanelService implements UserAnalyticsInterface {

  public SERVICE_NAME = 'mixpanel';

  private identified: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public identified$: Observable<any> = this.identified.asObservable();

  // the current plugin implementation of mixpanel doesn't work on web,
  // so we need to manually include the web-sdk.
  // Since the mobile-sdk also track some mobile specific actions (app install, update, etc)
  // it's better to keep both. That's why we have mixpanelWeb and mixpanelMobile here.
  private mixpanelWeb = mixpanel;

  constructor(
    public config: ClarityConfig,
    public logger: LoggerService,
    private mixpanelMobile: Mixpanel,
    private mixpanelMobilePeople: MixpanelPeople
  ) {
  }

  public initialize(): Promise<any> {
    // clear any data just in case
    this.mixpanelStart()
      .catch((error) => this.logger.error('Cannot initialize Mixpanel', error, this.constructor.name));

    return Promise.resolve();
  }

  public async resetService(): Promise<any> {
    this.logger.debug('Mixpanel resetting user');

    return this.config.isDevice ?
      this.mixpanelMobile.reset() :
      this.mixpanelWeb.reset();
  }

  public registerUser(data: UpdateUserDataType): Promise<any> {
    return Promise.all([
      this.identifyUser(data),
      this.updateUser(data),
      this.setupMixpanelSuperProperties()
    ])
      .catch((error) => this.logger.error('Cannot register Mixpanel user', error, this.constructor.name));
  }

  public async updateUser(data: UpdateUserDataType): Promise<any> {
    // WARNING: be careful on what information is added here.
    // We're HIPAA Compliant, it means we can't send indentifiable information,
    // such as email, zip-code, name, etc. More information: https://www.hhs.gov/hipaa/for-professionals/privacy/special-topics/de-identification/index.html
    const userAttributes: any = {
      $created: data.userProgram.created_at,
      last_program: this.config.currentProgramCode,
      user_type: data.userProgram.sso_provider === 'sharecare' ? 'Sharecare' : 'Email',
      age: rangeifyAge(data.user.age),
      gender: data.user.gender,
      ...(this.config.programDPPorWL() ? {
        dpp_wl_type: data.user.dpp_wl_type
      } : {})
    };

    return this.config.isDevice ?
      this.mixpanelMobilePeople.set(userAttributes) :
      this.mixpanelWeb.people.set(userAttributes);
  }

  async trackEvent(eventName: string, data = {}) {
    this.logger.debug('Mixpanel tracking event', eventName);

    try {
      if (this.config.isDevice) {
        return await this.mixpanelMobile.track(eventName, data);
      } else {
        return this.mixpanelWeb.track(eventName, data);
      }
    } catch (error) {
      this.logger.error('Error caught tracking event to Mixpanel', error, this.constructor.name);

      return Promise.resolve(false);
    }
  }

  private async identifyUser(data: UpdateUserDataType) {
    const userId = this.userId(data.user);

    this.logger.debug('Mixpanel identifyUser', userId);

    return this.config.isDevice ?
      this.mixpanelMobile.identify(userId) :
      this.mixpanelWeb.identify(userId);
  }

  private userId(user: User) {
    return `${user.user_id}-${user.id}`;
  }

  private async mixpanelStart(): Promise<void> {
    if (this.config.isDevice) {
      await this.mixpanelMobile.init(this.config.env.mixpanel.token);
    } else {
      this.mixpanelWeb.init(this.config.env.mixpanel.token, { debug: this.config.onDev() });
    }
  }

  private async setupMixpanelSuperProperties(): Promise<void> {
    // this info will be added at every .track() call
    const trackData = {
      clarity_program: this.config.currentProgramCode,
      clarity_platform: this.config.buildPlatform,
      clarity_environment: this.config.currentEnv(),
      clarity_version: this.config.getAppVersion()
    };

    return this.config.isDevice ?
      this.mixpanelMobile.registerSuperProperties(trackData) :
      this.mixpanelWeb.register(trackData);
  }
}
