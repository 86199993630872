// program reducer

import * as userGoalsActions from '../actions/user-goals.actions';

export interface UserGoalsState {
  updating: boolean;
}

export const initialUserGoalsState: UserGoalsState = {
  updating: false
};

export function userGoalsReducer(
  state = initialUserGoalsState,
  action: userGoalsActions.UserGoalsActions
): UserGoalsState {
  switch (action.type) {
    case userGoalsActions.UPDATE_USER_GOALS: {
      return {
        ...state,
        updating: true
      };
    }
    case userGoalsActions.UPDATE_USER_GOALS_FAIL:
    case userGoalsActions.UPDATE_USER_GOALS_SUCCESS: {
      return {
        ...state,
        updating: false
      };
    }
  }

  return state;
}

export const userGoalsUpdating = (state: UserGoalsState) => state.updating;
