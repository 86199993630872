<ion-content>

  <ion-header>
    <cl-header-nav-buttons class="page-header" color="blank" [autoClose]="true" [backButton]="false"></cl-header-nav-buttons>
  </ion-header>

  <div class="page-background"></div>

  <div class="page-content lateral-padding">
    <p class="header" *ngIf="!actAsNextWeek">{{'myjourney.next_steps' | translate }}</p>
    <p class="header" *ngIf="actAsNextWeek">{{'myjourney.next_week' | translate }}</p>

    <!--<video-player *ngIf="!actAsNextWeek"></video-player>-->

    <p class="sub-header" *ngIf="!actAsNextWeek" [innerHTML]="'myjourney.next_steps_instructions' | translate"></p>
    <p class="sub-header" *ngIf="actAsNextWeek" [innerHTML]="'myjourney.next_week_instructions' | translate"></p>

    <div class="cards-wrapper" *ngIf="!actAsNextWeek" >
      <cl-arrow-card
        header="{{'myjourney.next_steps_continue_to_theme_weeks' | translate}}"
        content="{{'myjourney.next_steps_theme_weeks_details' | translate}}"
        button="{{'myjourney.go_to_theme_weeks' | translate}}"
        (action)="onGoToThemeWeeks()">
      </cl-arrow-card>

      <cl-arrow-card
        header="{{'myjourney.next_steps_revisit_core_modules' | translate}}"
        content="{{'myjourney.next_steps_revisit_details' | translate}}"
        button="{{'myjourney.go_to_core_modules' | translate}}"
        (action)="onRestartProgram()">
      </cl-arrow-card>
    </div>

    <div class="cards-wrapper" *ngIf="actAsNextWeek">
      <cl-arrow-card
        header="{{'myjourney.next_week_continue_to_theme_weeks' | translate}}"
        content="{{'myjourney.next_week_theme_weeks_details' | translate}}"
        button="{{'myjourney.go_to_theme_weeks' | translate}}"
        (action)="onGoToThemeWeeks()">
      </cl-arrow-card>

      <cl-arrow-card
        header="{{'myjourney.next_week_revisit_core_modules' | translate}}"
        content="{{'myjourney.next_week_revisit_details' | translate}}"
        button="{{'myjourney.go_to_core_modules' | translate}}"
        (action)="onRestartProgram()">
      </cl-arrow-card>

      <cl-arrow-card
        *ngIf="actAsNextWeek"
        header="{{'myjourney.next_week_create_custom_path' | translate}}"
        content="{{'myjourney.next_week_custom_path_details' | translate}}"
        button="{{'myjourney.go_to_theme_weeks' | translate}}"
        (action)="onNewCustomWeek()">
      </cl-arrow-card>
    </div>
  </div>
</ion-content>
