import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AddChildData, AddData, RemoveChildData, SetData } from 'ngrx-normalizr';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SocialProvider } from '../../../providers/social.provider';
import * as socialActions from '../actions/social.actions';
import * as userActivityActions from '../actions/user-activity.actions';

import {
  getPostById,
  getPostFeedById,
  getPostsFilter,
  getPostsFromFeed,
  getPostsPage,
  getPostsTimestamp,
  getPostsUserFilter,
  getQueuedPostsOnResume
} from '../selectors/social.selectors';
import { SessionState } from '../session.reducers';
import { LoadLastComments, LoadPost, OpenPostSuccess } from '../actions/post.actions';
import { Like, Post, PostFeed, postFeedSchema, postsSchema } from '../../normalized/schemas/social.schema';
import { postFeedId } from '../reducers/social.reducer';
import { ToastService } from '../../../services/toast.service';
import { getCurrentProfileId } from '../../normalized/selectors/user-profile.selectors';
import { Observable, of } from 'rxjs';
import { CommunityActionCableService } from '../../../services/actioncable/community-action-cable.service';
import { OpenModal } from '../actions/navigation.actions';
import { ConnectivityService } from '../../../services/connectivity.service';

const currentPostFeedId: string = postFeedId();

@Injectable({ providedIn: 'root' })
export class SocialEffects {


  initActionCable$ = createEffect(() => this.actions$.pipe(ofType(socialActions.INIT_ACTION_CABLE),
    map(() => {
      this.communityCableService.initActionCable();
    })
  ), {dispatch: false});


  savePost$ = createEffect(() => this.actions$.pipe(ofType(socialActions.SAVE_POST),
    concatMap(action => of(action)
      .pipe(withLatestFrom(
        this.store.select(getPostsFilter),
        this.store.select(getPostFeedById)
      ))
    ),
    switchMap(([action, activeFilter, postFeed]: [socialActions.SavePost, string, PostFeed]) => this.socialProvider.savePost(action.payload)
      .pipe(
        map((post) => {
          if (!postFeed) { return; }

          if (action.payload[activeFilter] || activeFilter === '') {

            this.store.dispatch(
              new SetData<PostFeed>({
                data: [{
                  ...postFeed,
                  total_count : postFeed.total_count + 1,
                  id: currentPostFeedId
                }],
                schema: postFeedSchema
              })
            );

            this.store.dispatch(
              new AddChildData<Post>({
                data: [post],
                childSchema: postsSchema,
                parentSchema: postFeedSchema,
                parentId: currentPostFeedId
              }));
          }

          return new socialActions.SavePostSuccess(post);
        })
      )),
    catchError((error, caught) => {
      this.store.dispatch(new socialActions.SavePostFail(error));

      return caught;
    })
  ));


  savePostSuccess$ = createEffect(() => this.actions$.pipe(ofType(socialActions.SAVE_POST_SUCCESS),
    tap(() => {
      this.toastService.translateConfirm('social.journal.post_sent');

      // temp - track community activity -- needed until mscommunity-core sends own tracking data to clarion-core
      this.store.dispatch(new userActivityActions.TrackActivity({kind: 'mscommunity', name: 'Post'}));
    })
  ), {dispatch: false});


  savePostFail$ = createEffect(() => this.actions$.pipe(ofType(socialActions.SAVE_POST_FAIL),
    tap(() => {
      if (this.connectivity.isOnline()) {
        this.toastService.translateError('errors.common.generic_error_please_retry');
      } else {
        this.toastService.translateError('errors.common.network_error');
      }
    })
  ), {dispatch: false});


  deletePost$ = createEffect(() => this.actions$.pipe(ofType(socialActions.DELETE_POST),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getPostFeedById)))
    ),
    switchMap(([action, postFeed]: [socialActions.DeletePost, PostFeed]) => {
      if (!postFeed) { return; }

      return this.socialProvider.deletePost(action.payload)
        .pipe(
          mergeMap(() => [
            new SetData<PostFeed>({
              data: [{
                ...postFeed,
                total_count : postFeed.total_count - 1,
                id: currentPostFeedId
              }],
              schema: postFeedSchema
            }),
            new RemoveChildData({
              id: action.payload,
              childSchema: postsSchema,
              parentSchema: postFeedSchema,
              parentId: currentPostFeedId
            }),
            new socialActions.DeletePostSuccess(action.payload)
          ])
        );
    }),
    catchError((error, caught) => {
      this.store.dispatch(new socialActions.DeletePostFail(error));

      return caught;
    })
  ));


  deletePostSuccess$ = createEffect(() => this.actions$.pipe(ofType(socialActions.DELETE_POST_SUCCESS),
    tap(() => {
      this.toastService.translateConfirm('social.journal.post_deleted');
    })
  ), {dispatch: false});


  deletePostFail$ = createEffect(() => this.actions$.pipe(ofType(socialActions.DELETE_POST_FAIL),
    tap(() => {
      if (this.connectivity.isOnline()) {
        this.toastService.translateError('errors.common.generic_error_please_retry');
      } else {
        this.toastService.translateError('errors.common.network_error');
      }
    })
  ), {dispatch: false});


  setPostsFilter$ = createEffect(() => this.actions$.pipe(
    ofType(socialActions.SET_POSTS_FILTER, socialActions.SET_POSTS_USER_FILTER),
    concatMap(action => of<socialActions.SetPostsFilter | socialActions.SetPostsUserFilter>(action)
      .pipe(withLatestFrom(
        this.store.select(getPostsPage),
        this.store.select(getPostsFilter),
        this.store.select(getPostsTimestamp),
        this.store.select(getPostsUserFilter)
      ))
    ),
    switchMap(([action, page, filter, timestamp, userFilter]) => {
      let userId: number;
      if (filter === '' && userFilter) {
        userId = userFilter.userId;
      }

      return this.socialProvider.loadPosts(page, filter, timestamp, userId)
        .pipe(
          mergeMap((postFeed) => [
            new SetData<PostFeed>({
              data: [{
                ...postFeed,
                id: currentPostFeedId
              }],
              schema: postFeedSchema
            }),
            new socialActions.LoadPostsSuccess()
          ])
        );
    }),
    catchError((error, caught) => {
      this.store.dispatch(new socialActions.LoadPostsFail(error));

      return caught;
    })
  ));


  loadPosts$ = createEffect(() => this.actions$.pipe(ofType(socialActions.LOAD_POSTS),
    concatMap(action => of(action)
      .pipe(withLatestFrom(
        this.store.select(getPostsPage),
        this.store.select(getPostsFilter),
        this.store.select(getPostsTimestamp)
      ))
    ),
    switchMap(([action, page, filter, timestamp]) => this.socialProvider.loadPosts(page, filter, timestamp)
      .pipe(
        mergeMap((postFeed) => [
          new AddChildData<Post>({
            data: postFeed.list,
            childSchema: postsSchema,
            parentSchema: postFeedSchema,
            parentId: currentPostFeedId
          }),
          new socialActions.LoadPostsSuccess()
        ])
      )),
    catchError((error, caught) => {
      this.store.dispatch(new socialActions.LoadPostsFail(error));

      return caught;
    })
  ));


  openPost$ = createEffect(() => this.actions$.pipe(ofType(socialActions.OPEN_POST),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getPostById)))
    ),
    switchMap(([action, post]: [socialActions.OpenPostById, Post]) => {
      this.store.dispatch(new OpenModal('PostPage', {
        route: `/tabs/posts/${action.payload}`,
        redirectOnClose: action.redirectOnClose
      }));

      const actions: Action[] = [new LoadLastComments(action.payload)];
      // if no post, load the post itself (may happen when opening post modal from notifications)
      if (post) {
        actions.push(new OpenPostSuccess(action.payload));
      } else {
        actions.push(new LoadPost(action.payload));
      }

      return actions;
    }
    )
  ));


  reLoadPosts$ = createEffect(() => this.actions$.pipe(ofType(socialActions.RELOAD_POSTS),
    concatMap(action => of(action)
      .pipe(withLatestFrom(
        this.store.select(getPostsPage),
        this.store.select(getPostsFilter),
        this.store.select(getPostsTimestamp)
      ))
    ),
    switchMap(([action, page, filter, timestamp]) => this.socialProvider.loadPosts(page, filter, timestamp)
      .pipe(
        mergeMap((postFeed) => [
          new SetData<PostFeed>({
            data: [{
              total_count: postFeed ? postFeed.total_count : 0,
              list: postFeed.list,
              id: currentPostFeedId
            }],
            schema: postFeedSchema
          }),
          new socialActions.ReloadPostsSuccess()
        ])
      )),
    catchError((error, caught) => {
      this.store.dispatch(new socialActions.ReloadPostsFail(error));

      return caught;
    })
  ));


  reloadOnResume$ = createEffect(() => this.actions$.pipe(ofType(socialActions.RELOAD_ON_RESUME),
    concatMap(action => of(action)
      .pipe(withLatestFrom(
        this.store.select(getPostsFilter),
        this.store.select(getPostsFromFeed)
      ))
    ),
    switchMap(([action, filter, existingPosts]) => this.socialProvider.loadPosts(1, filter, new Date().toISOString())
      .pipe(
        mergeMap((postFeed: PostFeed) => {
          if (!postFeed || !postFeed.list || !postFeed.list.length ||
              new Date(existingPosts[0].created_at) >= new Date(postFeed.list[0].created_at)) {

            return [];
          }

          return [
            new socialActions.ReloadOnResumeSuccess(postFeed)
          ];
        })
      )),
    catchError((error, caught) => {
      this.store.dispatch(new socialActions.ReloadPostsFail(error));

      return caught;
    })
  ));


  updateFeedAfterResume$ = createEffect(() => this.actions$.pipe(ofType(socialActions.UPDATE_FEED_AFTER_RESUME),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getQueuedPostsOnResume)))
    ),
    switchMap(([action, queuedPosts]: [socialActions.UpdateFeedAfterResume, PostFeed]) => {

      if (!queuedPosts) {
        return [];
      }

      return [
        new SetData<PostFeed>({
          data: [{
            total_count: queuedPosts ? queuedPosts.total_count : 0,
            list: queuedPosts.list,
            id: currentPostFeedId
          }],
          schema: postFeedSchema
        }),
        new socialActions.ReloadPostsSuccess()
      ];
    })
  ));


  toggleLikePost$ = createEffect(() => this.actions$.pipe(
    ofType<socialActions.ToggleLikePost>(socialActions.TOGGLE_LIKE_POST),
    concatMap(action => of(action)
      .pipe(withLatestFrom(
        this.store.select(getPostById),
        this.store.select(getCurrentProfileId)
      ))
    ),
    switchMap<any, Observable<Action>>(([action, post, profileId]: [socialActions.ToggleLikePost, Post, string]) => {
      const providerAction = (post.current_user_liked !== true) ?
        this.socialProvider.addLikeOnPost :
        this.socialProvider.removeLikeFromPost;

      return providerAction.call(this.socialProvider, action.payload)
        .pipe(
          mergeMap((like: Like) => {
            const likes = {
              total_count: post.likes.total_count,
              list: post.likes.list.slice()
            };

            likes.total_count = likes.total_count + (post.current_user_liked ? -1 : 1);

            if (like) {
              likes.list.push(like);

              this.store.dispatch(new userActivityActions.TrackActivity({kind: 'mscommunity', name: 'Like'}));
            } else {
              likes.list.splice(likes.list.findIndex(item => item.profile_id === profileId), 1);
            }

            return [
              new AddData<Post>({
                data: [{
                  ...post,
                  current_user_liked: !post.current_user_liked,
                  likes
                }],
                schema: postsSchema
              }),
              new socialActions.ToggleLikePostSuccess(!post.current_user_liked)
            ];
          }),
          catchError((error) => of(new socialActions.ToggleLikePostFail(post.id, error)))
        );
    })
  ));


  toggleLikePostSuccess$ = createEffect(() => this.actions$.pipe(ofType(socialActions.TOGGLE_LIKE_POST_SUCCESS),
    tap((action: socialActions.ToggleLikePostSuccess) => {
      const key = action.payload ? 'social.posts.post_like_success' : 'social.posts.post_unlike_success';
      this.toastService.translateConfirm(key);
    })
  ), {dispatch: false});


  toggleLikePostFail$ = createEffect(() => this.actions$.pipe(ofType(socialActions.TOGGLE_LIKE_POST_FAIL),
    tap((action: socialActions.ToggleLikePostFail) => {
      if (action.error.status === 404) {
        this.toastService.translateError('errors.social.post_no_longer_exists');
      } else {
        this.toastService.translateError('errors.common.generic_error_please_retry');
      }
    })
  ), {dispatch: false});


  notifyLikeOkPost$ = createEffect(() => this.actions$.pipe(ofType(socialActions.NOTIFY_POST_LIKE),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getPostById)))
    ),
    switchMap(([action, post]: [socialActions.NotifyPostLike, Post]) => {
      const params = action.payload;
      const actions: Action[] = [
        new AddData<Post>({
          data: [{
            ...post,
            likes: {
              total_count: params.likes_count,
              list: params.avatars.map(avatar => ({
                avatar,
                id: null,
                profile_id: null
              }))
            }
          }],
          schema: postsSchema
        })
      ];

      return actions;
    })
  ));


  loadOnePost$ = createEffect(() => this.actions$.pipe(ofType(socialActions.LOAD_POST_BY_ID),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getPostFeedById)))
    ),
    switchMap(([action, postFeed]: [socialActions.LoadPostById, PostFeed]) => {
      if (!postFeed) { return; }

      return this.socialProvider.loadPostById(action.payload)
        .pipe(
          mergeMap((post: Post) => [
            new SetData<PostFeed>({
              data: [{
                ...postFeed,
                total_count : postFeed.total_count ? postFeed.total_count + 1 : 0,
                id: currentPostFeedId
              }],
              schema: postFeedSchema
            }),
            new AddChildData<Post>({
              data: [post],
              childSchema: postsSchema,
              parentSchema: postFeedSchema,
              parentId: currentPostFeedId
            }),
            new socialActions.LoadPostByIdSuccess(post)
          ])
        );
    })
  ));

  constructor(
    private actions$: Actions,
    private socialProvider: SocialProvider,
    private store: Store<SessionState>,
    private toastService: ToastService,
    private communityCableService: CommunityActionCableService,
    private connectivity: ConnectivityService
  ) { }
}
