import { Component, AfterViewInit, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { DatetimeChangeEventDetail, NavController } from '@ionic/angular';

import { Observable, combineLatest, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { LoadingService } from '../../services/loading.service';

import * as accountActions from '../../store/session/actions/account.actions';
import { ClarityConfig } from '../../config/clarity.config';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { getCurrentUser, isSharecareSsoProvider, isProgramExtensionEnabled } from '../../store/normalized/selectors/user.selectors';
import { SessionState } from '../../store/session/session.reducers';
import { isUserUpdating } from '../../store/session/selectors/account.selectors';
import { BrowserService } from 'src/app/services/browser.service';
import { take } from 'rxjs/operators';
import { SmokingType } from 'src/app/store/normalized/schemas/user.schema';
import { GtmCustomEvent, GtmService } from '../../services/analytics/gtm.service';

import { PrivacyScreenService } from 'src/app/services/privacy-screen.service';
import { dateFormatter } from 'src/app/utils/date-formatter';

@Component({
  selector: 'page-account-setup',
  styleUrls: ['account-setup.scss'],
  templateUrl: 'account-setup.html'
})
export class AccountSetupPage implements OnInit, AfterViewInit, OnDestroy {
  private CIG_PACK_COST_CONTROL_NAME = 'cig_pack_cost';

  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  public updating$: Observable<boolean>;

  public validations = {
    first_name: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.first_name_required'
      }
    },
    last_name: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.last_name_required'
      }
    },
    age: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.age_required'
      }
    },
    weight: {
      validators: Validators.compose([
        Validators.required,
        Validators.min(30) // backend will reject any value less than 30 for `weight` property
      ]),
      errors: {
        required: 'errors.user.weight_required',
        min: 'errors.user.weight_minimum_amount'
      }
    },
    cigs_per_day: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.cigs_per_day_required'
      }
    },
    puffs_per_day: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.puffs_per_day_required'
      }
    },
    smoking_type: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.smoking_type_required'
      }
    },
    [this.CIG_PACK_COST_CONTROL_NAME]: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: `errors.user.${this.CIG_PACK_COST_CONTROL_NAME}_required`
      }
    },
    cig_pack_currency: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.cig_pack_currency_required'
      }
    },
    weight_unit: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.weight_unit_required'
      }
    },
    end_date: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.end_date_required'
      }
    },
    gender: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.gender_required'
      }
    },
    language_code: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.language_code_required'
      }
    }
  };

  public readonly GENDER_OPTIONS = [
    {
      label: 'auth.male',
      value: 'male'
    },
    {
      label: 'auth.female',
      value: 'female'
    },
    {
      label: 'auth.other',
      value: 'other'
    }
  ];

  public readonly SMOKING_TYPE = [
    {
      label: 'auth.smoking',
      value: 'cigarette'
    },
    {
      label: 'auth.vaping',
      value: 'vaping'
    }
  ];

  public readonly WEIGHT_UNIT_OPTIONS = [
    {
      name: 'common.lb',
      value: 'lb'
    },
    {
      name: 'common.kg',
      value: 'kg'
    }
  ];

  public readonly LANGUAGE_OPTIONS = this.config.getLanguageOptions();

  public currencyOptions = [
    'USD',
    'EUR',
    'GBP'
  ];

  minDate = new Date().toISOString();
  maxDate = new Date(new Date().getTime() + 60 * 60 * 24 * 30 * 3 * 1000).toISOString(); // max 3 months from now

  public accountSetupForm: FormGroup;

  private _smokingType: SmokingType;

  public userData: any = {
    first_name: '',
    last_name: '',
    age: null,
    gender: null
  };

  public ageOptions = [];
  private changingLanguageSubscription: Subscription;

  isSharecareSso = false;

  constructor(
    public navCtrl: NavController,
    private store: Store<SessionState>,
    private loadingService: LoadingService,
    private formBuilder: FormBuilder,
    private browser: BrowserService,
    public translate: TranslateService,
    public config: ClarityConfig,
    public gtmService: GtmService,
    private privacyScreenService: PrivacyScreenService
  ) {
    combineLatest([
      this.store.select(getCurrentUser),
      this.store.select(isSharecareSsoProvider)
    ])
      .pipe(take(1))
      .subscribe(([user, isSharecareSso]) => {
        // set language from browser
        this.isSharecareSso = isSharecareSso;

        // used for testing GTM events
        // user['tracking'] = {
        //   test1: 'a',
        //   test2: 'b'
        // };

        // if user has tracking data attached (comes from SignupInfo record), we'll push that over to GTM
        if (user['tracking']) {
          this.gtmService.triggerEvent(GtmCustomEvent.SignupTracking, user['tracking']);
        }

        if (this.accountSetupForm) {
          // this.accountSetupForm.reset();
          return;
        }

        this.userData = {
          ...user,
          language_code: this.translate.currentLang
        };

        if (this.config.isCTQ() && !this.userData.end_date) {
          this.userData.end_date = moment()
            .add(21, 'days')
            .format('YYYY-MM-DD');
        }

        if (this.config.isCTQ() && this.userData.smoking_type) {
          this._smokingType = this.userData.smoking_type;
        }

        const formGroup = this.generateFormGroup(this.config.program.programCode, this.userData);
        this.accountSetupForm = this.formBuilder.group({
          userData: new FormGroup(formGroup)
        });

        this.updatePackCostValidators();

        this.changingLanguageSubscription = this.accountSetupForm.get('userData.language_code').valueChanges
          .subscribe(languageCode => this.translate.use(languageCode));

        if (this.isSharecareSso) {
          this._disablePersonalInfoFields();
        }
      });

    this.updating$ = this.store.select(isUserUpdating);

    for (let allowedAge = this.config.program.programCode === 'ctq' ? 18 : 13; allowedAge < 100; allowedAge++) {
      this.ageOptions.push({
        value: `${allowedAge}`,
        label: `${allowedAge}`
      });
    }
  }

  generateFormGroup(program, userData) {
    const formGroupData = {
      ern: {
        language_code: {
          defaultValue: 'en'
        },
        first_name: {},
        last_name: {},
        weight: {
          defaultValue: 0
        },
        weight_unit: {
          defaultValue: 'lb'
        },
        age: {},
        gender: {}
      },
      ua: {
        language_code: {
          defaultValue: 'en'
        },
        first_name: {},
        last_name: {},
        age: {},
        gender: {}
      },
      ctq: {
        language_code: {
          defaultValue: 'en'
        },
        first_name: {},
        last_name: {},
        age: {},
        gender: {},
        cigs_per_day: {},
        // 'cig_pack_currency': {},
        end_date: {},
        [this.CIG_PACK_COST_CONTROL_NAME]: {},
        smoking_type: {}
      }
    };

    const formGroupProgram = formGroupData[program];
    const formGroup = {};

    Object.keys(formGroupProgram)
      .forEach((key) => {
        const data = formGroupProgram[key];
        const value = userData[key] || data.defaultValue;
        const formValue = value ? `${value}` : value;
        const formValidator = data.validators || this.validations[key].validators;
        formGroup[key] = new FormControl(formValue, formValidator);
      });

    return formGroup;
  }

  ngOnInit() {}

  ionViewDidEnter() {
    this.privacyScreenService.enable();
  }

  ionViewDidLeave() {
    this.privacyScreenService.disable();
  }

  ngAfterViewInit() {
    this.loadingService.useLoadingObservable(this.store.select(isUserUpdating));
  }

  ngOnDestroy(): void {
    this.changingLanguageSubscription && this.changingLanguageSubscription.unsubscribe();
  }

  onSetupAccount() {
    // we assign userData to toSubmit and override with the form data

    const weightUnit = this.config.isCTQ() ? {weight_unit: 'lb'} : {};

    const toSubmit = {
      ...this.userData,
      ...weightUnit,
      ...this.accountSetupForm.value.userData
    };

    this.store.select(isProgramExtensionEnabled).pipe(
      take(1)
    )
      .subscribe(userAllowedToSelectProgram => {
        if (this.config.isERN() && userAllowedToSelectProgram) {
          this.store.dispatch(new accountActions.AccountErnSetupStart(toSubmit));
        } else {
          this.store.dispatch(new accountActions.AccountSetupProcess(toSubmit));
        }
      });

  }

  goToLogin() {
    this.navCtrl.navigateBack('login');
  }

  openTerms() {
    this.browser.goTo(this.config.privacyTermsUrl);
  }

  selectInputOnFocus(event) {
    // clicking the label will also fire
    if (!event.target) {
      return;
    }

    if (event.target.value === '0') {
      event.target.value = '';
    }
  }

  private _disablePersonalInfoFields() {
    const userData = this.accountSetupForm.get('userData');

    userData.get('first_name')
      .disable();
    userData.get('last_name')
      .disable();
    userData.get('age')
      .disable();
    userData.get('gender')
      .disable();
  }

  onChangeSmokingType(event) {
    this._smokingType = event.detail.value;
    this.updatePackCostValidators();
    if (this.isVaping() && !this.cigPackCostControl.value) {
      this.cigPackCostControl.setValue(0);
    }
  }

  isSelectedProgramType() {
    return !!this._smokingType;
  }

  isVaping() {
    return this._smokingType === SmokingType.VAPING;
  }

  get cigPackCostControl(): FormControl {
    return this.accountSetupForm.get('userData')
      .get(this.CIG_PACK_COST_CONTROL_NAME) as FormControl;
  }

  get quittingDate() {
    return this.accountSetupForm.get('userData.end_date') as FormControl;
  }

  setQuittingDate(changeEvent: CustomEvent<DatetimeChangeEventDetail>) {
    this.quittingDate.setValue(dateFormatter(changeEvent.detail.value as string));
  }

  get smokingType(): FormControl {
    return this.accountSetupForm.get('userData.smoking_type') as FormControl;
  }

  private updatePackCostValidators() {
    if (!this.cigPackCostControl) {
      return;
    }
    this.cigPackCostControl.clearValidators();
    if (!this.isVaping()) {
      const validators = this.validations[this.CIG_PACK_COST_CONTROL_NAME]
        && this.validations[this.CIG_PACK_COST_CONTROL_NAME].validators;
      this.cigPackCostControl.setValidators(validators || []);
    }
    this.cigPackCostControl.updateValueAndValidity();
  }

}
