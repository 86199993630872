import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { State } from '../store';
import { getCurrentUser } from '../store/normalized/selectors/user.selectors';
import { ProgramType } from '../store/normalized/schemas/user.schema';
import { userDppwlTypeToEnum } from '../utils/user-dppwl-type-to-enum';

@Injectable({providedIn: 'root'})
export class ProgramTypeService {
  // by default it's ERN
  private programType: ProgramType = ProgramType.ERN;

  private resolve: any;
  private reject: any;

  public promise = new Promise((resolve, reject) => {
    this.resolve = resolve;
    this.reject = reject;
  });

  constructor(
    private store: Store<State>
  ) {
    this.store.select(getCurrentUser)
      .pipe(filter(user => !!(user && user.id)))
      .subscribe((userAccount) => this._resolve(userDppwlTypeToEnum(userAccount.dpp_wl_type)));
  }

  public restoreDefault() {
    this.programType = ProgramType.ERN;
  }

  public isDPP() {
    return this.programType === ProgramType.DPP;
  }

  public isWL() {
    return this.programType === ProgramType.WL;
  }

  private _resolve(value: ProgramType) {
    this.programType = value;

    this.resolve(value);
  }

  private _reject(error) {
    this.reject(error);
  }
}
