import { AccountEffects } from './account.effects';
import { AuthEffects } from '../../sensitive/effects/auth.effects';
import { DownloadEffects } from './download.effects';
import { NavigationEffects } from './navigation.effects';
import { ToolsEffects } from './tools.effects';
import { ProgramEffects } from './program.effects';
import { UserProgramEffects } from './user-program.effects';
import { UserProfileEffects } from './user-profile.effects';
import { SyncEffects } from './sync.effects';
import { TrackingEffects } from './tracking.effects';
import { ExercisesEffects } from './exercises.effects';
import { UserGoalsEffects } from './user-goals.effects';
import { UserProgramSessionEffects } from './user-program-session.effects';
import { UserActivityEffects } from './user-activity.effects';
import { UserRemindersEffects } from './user-reminders.effects';
import { NotificationsEffects } from './notifications.effects';
import { SubscriptionEffects } from './subscription.effects';
import { CigCountEffects } from './cig-count.effects';
import { OfflineQueueEffects } from './offline-queue.effects';
import { MediaEffects } from '../../persistent/media/media.effects';
import { SocialEffects } from './social.effects';
import { PostEffects } from './post.effects';
import { UserFavEffects } from './user-favorites.effects';
import { NrtEffects } from './nrt.effects';
import { MyCoachEffects } from './my-coach.effects';
import { IridiumEffects } from './iridium.effects';
import { WeightActivitiesEffects } from './weight-activities.effects';
import { HealthDevicesEffects } from './health-devices.effects';

export const sessionEffects: any[] = [
  AccountEffects,
  AuthEffects,
  DownloadEffects,
  NavigationEffects,
  ToolsEffects,
  ProgramEffects,
  UserGoalsEffects,
  UserFavEffects,
  UserProgramEffects,
  UserProfileEffects,
  SyncEffects,
  TrackingEffects,
  UserActivityEffects,
  UserRemindersEffects,
  ExercisesEffects,
  UserProgramSessionEffects,
  NotificationsEffects,
  SubscriptionEffects,
  CigCountEffects,
  OfflineQueueEffects,
  SocialEffects,
  PostEffects,
  NrtEffects,
  MyCoachEffects,
  IridiumEffects,
  WeightActivitiesEffects,
  HealthDevicesEffects
];

export const persistentEffects: any[] = [
  MediaEffects
];
