import { schema } from 'normalizr';

export class CoverImage {
  data_fingerprint: string;
  data_file_name: string;
  data_content_type: string;
  data_file_size: number;
  data: string;
  data_thumb: string;
}

export const coverImageSchema = new schema.Entity<CoverImage>(
  'coverImages',
  {},
  {idAttribute: 'data_fingerprint'}
);
