import { Component } from '@angular/core';

@Component({
  selector: 'cl-premium-badge',
  styleUrls: ['premium-badge.component.scss'],
  template: `
    <div class="premium-badge-container">
      <ion-icon class="premium-badge-icon" src="/assets/icon/premium.svg"></ion-icon>
      <span class="premium-badge-text">Premium</span>
    </div>
  `
})

export class PremiumBadgeComponent {

}
