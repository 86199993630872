<ion-content>

  <ion-header>
    <cl-header-nav-buttons
      color="blank"
      [autoClose]="true"
      [backButton]="!isModal"
      (back)="onBack()"
      class="page-header"
    ></cl-header-nav-buttons>
  </ion-header>

  <div class="page-background"></div>

  <div class="page-wrapper lateral-padding">
    <h2 class="title" *ngIf="config.program.programCode === 'ctq'">{{'myjourney.restart_the_program' | translate}}</h2>
    <h2 class="title" *ngIf="config.program.programCode !== 'ctq'">{{'myjourney.revisit_core_modules' | translate}}</h2>

    <cl-shadow-wrapper>
      <ion-row>
        <ion-col class="info-card ion-text-left">
          <h2 class="intro">{{'myjourney.restart_program' | translate}}</h2>
          <p [innerHTML]="'myjourney.revisit_core_modules_details' | translate"></p>
        </ion-col>
      </ion-row>
    </cl-shadow-wrapper>

    <cl-control-buttons
      [darkBackground]="true"
      nextLabel="common.restart"
      (back)="onBack()"
      (next)="onNext()">
    </cl-control-buttons>
  </div>

  <div class="waves-bg"></div>

  <div class="bottom-bg-background"></div>
</ion-content>
