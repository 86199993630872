// post actions
import { Action } from '@ngrx/store';

export const GET_ACCESS = '[nrt] Get Access';
export const GET_ACCESS_SUCCESS = '[nrt] Get Access Success';
export const GET_ACCESS_FAIL = '[nrt] Get Access Fail';

export const VALIDATE_ADDRESS = '[nrt] Validate Address';
export const VALIDATE_ADDRESS_SUCCESS = '[nrt] Validate Address Success';
export const VALIDATE_ADDRESS_FAIL = '[nrt] Validate Address Fail';

export const ORDER = '[nrt] Order';
export const ORDER_SUCCESS = '[nrt] Order Success';
export const ORDER_FAIL = '[nrt] Order Fail';

export const SURVEY_STEPS_COUNTER_INIT = '[nrt] survey steps counter init';
export const SURVEY_STEPS_COUNTER_NEXT = '[nrt] survey steps counter next';
export const SURVEY_STEPS_COUNTER_BACK = '[nrt] survey steps counter back';

export class SurveyStepsCounterInit implements Action {
  readonly type = SURVEY_STEPS_COUNTER_INIT;
  constructor(public payload: any) {}
}
export class SurveyStepsCounterNext implements Action {
  readonly type = SURVEY_STEPS_COUNTER_NEXT;
  constructor(public payload: any) {}
}
export class SurveyStepsCounterBack implements Action {
  readonly type = SURVEY_STEPS_COUNTER_BACK;
  constructor(public payload: any) {}
}

export class GetAccess implements Action {
  readonly type = GET_ACCESS;
  constructor() {}
}

export class GetAccessSuccess implements Action {
  readonly type = GET_ACCESS_SUCCESS;
  constructor() {}
}

export class GetAccessFail implements Action {
  readonly type = GET_ACCESS_FAIL;
  constructor(public payload: any) {}
}

export class ValidateAddress implements Action {
  readonly type = VALIDATE_ADDRESS;
  constructor() {}
}

export class ValidateAddressSuccess implements Action {
  readonly type = VALIDATE_ADDRESS_SUCCESS;
  constructor() {}
}

export class ValidateAddressFail implements Action {
  readonly type = VALIDATE_ADDRESS_FAIL;
  constructor() {}
}

export class Order implements Action {
  readonly type = ORDER;
  constructor() {}
}

export class OrderSuccess implements Action {
  readonly type = ORDER_SUCCESS;
  constructor() {}
}

export class OrderFail implements Action {
  readonly type = ORDER_FAIL;
  constructor() {}
}

export type NrtActions =
  | SurveyStepsCounterInit
  | SurveyStepsCounterNext
  | SurveyStepsCounterBack
  | GetAccess
  | GetAccessSuccess
  | GetAccessFail
  | ValidateAddress
  | ValidateAddressSuccess
  | ValidateAddressFail
  | Order
  | OrderSuccess
  | OrderFail;
