import { Injectable } from '@angular/core';
import { ClarityConfig } from '../../config/clarity.config';
import { LoggerService } from '../logger.service';
import { ScriptLoaderService } from '../script-loader.service';
import { first, take } from 'rxjs/operators';
import { LoadingService } from '../loading.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from '../alerts.service';

@Injectable({providedIn: 'root'})
export class BrightcoveWebPlayerLoaderService {

  private resolveLoading: any;
  private rejectLoading: any;

  private playerLoaded = false;

  private loadingPromise: Promise<any> = new Promise((resolve, reject) => {
    this.resolveLoading = resolve;
    this.rejectLoading = reject;
  });

  private brightcovePlayer: any;

  constructor(
    private config: ClarityConfig,
    private logger: LoggerService,
    private scriptLoader: ScriptLoaderService,
    private loading: LoadingService,
    private translate: TranslateService,
    private alerts: AlertsService
  ) {
  }

  get playerLoadPromise() {
    return this.loadingPromise;
  }

  public initialize(): Promise<any> {
    this.loadPlayer()
      // log the error for now, component will retry loading
      .catch((error) => console.log('BrightcoveWebPlayerLoaderService initialize failed', error));

    // avoid blocking the app if the player doesn't load for any reason
    return Promise.resolve();
  }

  public isPlayerLoaded() {
    return this.playerLoaded;
  }

  public getPlayerElement(elt: HTMLElement) {
    return this.brightcovePlayer(elt);
  }

  public forcePlayerLoad() {
    this.loading.showLoadingOverlay();

    this.loadPlayer()
      .catch(() => this.showErrorPopup());
  }

  private loadPlayer() {
    const accountId = this.config.env.brightcove.accountId;
    const playerId = this.config.env.brightcove.playerId;

    this.logger.debug(`Initializing BrightcoveWebPlayerLoaderService: accountId: ${accountId}, playerId: ${playerId}`);

    return this.scriptLoader.load({
      src: `https://players.brightcove.net/${accountId}/${playerId}_default/index.min.js`,
      async: false
    })
      .pipe(first())
      .toPromise()
      .then(() => {
        if (!window['bc']) {
          return this.rejectLoading();
        }

        this.brightcovePlayer = window['bc'];
        this.playerLoaded = true;

        this.loading.hideLoadingOverlay();

        return this.resolveLoading();
      })
      .catch((error) => {
        this.playerLoaded = false;
        throw error;
      });
  }

  private playerLoadFailed() {
    this.loading.hideLoadingOverlay();
    this.alerts.playerError();
  }

  private async showErrorPopup() {
    this.translate.get([
      'common.cancel',
      'common.retry',
      'errors.common.network_error_title',
      'errors.common.player_error'
    ])
      .pipe(take(1))
      .subscribe(async (translations) => {
        const alert = await this.alerts.alertController.create({
          header: translations['errors.common.network_error_title'],
          message: translations['errors.common.player_error'],
          buttons: [
            {
              text: translations['common.cancel'],
              role: 'cancel',
              handler: () => this.playerLoadFailed()
            },
            {
              text: translations['common.retry'],
              handler: () => this.forcePlayerLoad()
            }
          ]
        });

        this.loading.hideLoadingOverlay();

        await alert.present();
      });
  }

  /**
   *
   * @param player Brightcove Player
   *
   * Adds some extra methods to the player, like the onBeforeRequestFullscreen event
   */
  public extendBrightcoveCapabilities(player) {
    /**
     * onBeforeRequestFullscreen allow you to perform operations before the
     * native API element.requestFullscreen() gets called, by overriting the
     * original brightcove requestFullscreen() method
     *
     * @example
     * ```
     *  this.brightcovePlayer.onBeforeRequestFullscreen(() => {
     *    console.log('about to request full screen');
     * })
     * ```
     */
    const onBeforeRequestFullscreen = function(beforeFullscreenFn: Function) {
      if (typeof beforeFullscreenFn !== 'function') {
        return;
      }

      const originalRequestFullScreen: Function = player.requestFullscreen;

      player.requestFullscreen = function(...args: any[]) {
        console.log('calling ovewriten requestFullcreen method');

        beforeFullscreenFn(args);

        originalRequestFullScreen.call(player, args);
      };
    };

    const onExitFullscreen = function(exitFullscreenFn: Function) {
      if (typeof exitFullscreenFn !== 'function') {
        return;
      }

      player.on('fullscreenchange', (event) => {
        if (!player.isFullscreen()) {
          exitFullscreenFn();
        }
      });
    };

    player.onBeforeRequestFullscreen = onBeforeRequestFullscreen;
    player.onExitFullscreen = onExitFullscreen;
  }
}
