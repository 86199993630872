import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as toolsActions from '../../../store/session/actions/tools.actions';
import { getExercisesByTags } from '../../../store/normalized/selectors/exercises.selectors';
import { take } from 'rxjs/operators';
import * as exercisesActions from '../../../store/session/actions/exercises.actions';
import { getProgramModulesByProgramDayId, LiveLesson } from '../../../store/session/selectors/program.selectors';
import { State } from '../../../store/state.reducer';
import { DatePipe } from '@angular/common';
import { SimpleAssessmentModel } from '../components/perform/simple-assessment/simple-assessment.interface';
import { LoggerService } from 'src/app/services/logger.service';

@Injectable({providedIn: 'root'})
export class PerformService {

  choices = {
    q1: 0,
    q2: 0,
    q3: 0,
    q4: 0,
    q5: 0,
    q6: 0,
    q7: 0,
    q8: 0,
    q9: 0
  };

  scores = [0, 0, 0];
  modules$ = this.store.select(getProgramModulesByProgramDayId);

  constructor(
    public store: Store<State>,
    public logger: LoggerService
  ) {
  }

  part1Done() {
    for (let i = 1; i <= 9; i++) {
      const qId = 'q' + i;

      // sum up scores 123, 456 and 789
      if (i <= 3) {
        // reverse first 3 results
        this.scores[0] += 5 - this.choices[qId];
      }
      else if (i <= 6) {
        this.scores[1] += this.choices[qId];
      }
      else if (i <= 9) {
        this.scores[2] += this.choices[qId];
      }

      // reset choice!
      this.choices[qId] = 0;
    }
    const datePipe = new DatePipe('en-US');
    const awarenessQuiz = {
      date: datePipe.transform(new Date(), 'yyyy-MM-dd'),
      recorded_at: new Date().toISOString(),
      answers: this.choices,
      scores: this.scores
    };

    this.store.dispatch(new toolsActions.TrackAwarenessQuiz(awarenessQuiz));

    this.scores = [0, 0, 0];
  }

  processExerciseCaption(caption) {
    const model = {
      questions: [],
      intro: '',
      congrats: ''
    };

    const txt = document.createElement('textarea');
    txt.innerHTML = caption;
    const rows = txt.value.split(/\n/);
    let match;

    rows.forEach((row) => {
      match = row.match(/%%([^%]*)%%/i);
      if (match) {
        model.intro = match[1];
      }
      else {
        match = row.match(/__([^_]*)__\[([\w,]*)\]__/i);
        if (match) {
          const question = {
            name: match[1],
            exercises: [],
            modules: []
          };
          model.questions.push(question);

          this.parseIdsAndTags(match[2], question);
        }
        else {
          match = row.match(/##([^#]*)##/i);
          if (match) {
            model.congrats = match[1];
          }
        }
      }
    });

    return model;
  }

  parseIdsAndTags(value: string, question: any) {
    const data = value.split(/,/);

    if (data.length && parseInt(data[0], 10)) {
      this.modules$
        .pipe(
          take(1)
        )
        .toPromise()
        .then(modules => {
          for (const moduleNumber in modules) {
            if (data.indexOf(modules[moduleNumber].number.toString()) > -1) {
              question.modules.push(modules[moduleNumber]);
            }
          }
        });
    }
    else {
      this.store.dispatch(new exercisesActions.GetExercisesByTags(data));
      this.store.select(getExercisesByTags)
        .pipe(
          take(1)
        )
        .toPromise()
        .then(res => question.exercises = res);
    }

  }

  parseSimpleAssessmentModel(liveLesson: LiveLesson) {
    try {
      const model: SimpleAssessmentModel = JSON.parse(liveLesson.record.exercise.caption);

      return model;
    } catch (error) {
      this.logger.error('processExerciseCaptionPayload', `Provided JSON payload is invalid: ${error + ''}`, 'SimpleAssessmentComponent');
    }
  }

  getCurrentSimpleAssessmentStep(model: SimpleAssessmentModel, step: number) {
    return model.steps[step - 1];
  }

}
