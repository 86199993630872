<ion-content>

  <ion-header>
    <cl-header-nav-buttons
      color="blank"
      [autoClose]="true"
      [backButton]="true"
      (back)="onBack()"
      class="page-header"
    ></cl-header-nav-buttons>
  </ion-header>

  <div class="page-background"></div>

  <div class="page-content lateral-padding">
    <p class="header">{{'myjourney.select_theme' | translate }}</p>

    <div class="themes-wrapper">
      <div
        class="theme-holder"
        *ngFor="let themeWeek of (themeWeeks$ | async)">
        <cl-slide-card-component
          type="themes"
          title="{{themeWeek.title}}"
          [completed]="(completedThemeWeeks$ | async)?.indexOf(themeWeek.id) > -1"
          [started]="(startedThemeWeeks$ | async)?.indexOf(themeWeek.id) > -1"
          (clicked)="onStartWeek(themeWeek)">
        </cl-slide-card-component>
      </div>

    </div>
  </div>

  <div class="waves-bg"></div>

  <div class="bottom-bg-background"></div>

</ion-content>
