import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClarityConfig } from '../../config/clarity.config';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { State } from '../../store';
import { getCurrentSubscription } from '../../store/normalized/selectors/subscription.selectors';
import { StepValidationSlideComponent } from '../../components/slides/step-validation-slide';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IonContent, NavController, Platform } from '@ionic/angular';
import { BrowserService } from '../../services/browser.service';
import { SubscriptionsService } from '../../services/subscriptions/subscriptions.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConnectivityService } from '../../services/connectivity.service';
import { AlertsService } from '../../services/alerts.service';
import { RecaptchaComponent } from '../../components/recaptcha/recaptcha.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../services/toast.service';
import { getAuthError, isCheckingPassword } from '../../store/sensitive/selectors/auth.selectors';
import { LoadingService } from '../../services/loading.service';
import * as authActions from '../../store/sensitive/actions/auth.actions';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { getCurrentUserPrograms } from '../../store/normalized/selectors/user.selectors';
import { UserProgram } from '../../store/normalized/schemas/user.schema';
import { getProgramName, ProgramCode } from '../../utils/programs';
import { ClearLoginState } from '../../store/sensitive/actions/auth.actions';

type ProviderType = 'stripe' | 'itunes' | 'googleplay' | 'license' | null;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-account-deletion',
  styleUrls: ['account-deletion.scss'],
  templateUrl: 'account-deletion.html'
})
export class AccountDeletionPage extends StepValidationSlideComponent implements OnDestroy, OnInit {
  @ViewChild(RecaptchaComponent, { static : false }) captcha: RecaptchaComponent;
  @ViewChild('ionContent', { read: ElementRef, static: true }) ionContent: ElementRef<IonContent>;

  subscriptionType: ProviderType = null;
  displaySubscriptionStep = false;
  displayEnrollmentStep = true;
  userPrograms: Array<{code: ProgramCode; name: string}> = [];

  selectedSlideId: number = null;

  keyboardOpen = new BehaviorSubject(false);

  form: FormGroup;
  validations = {
    password: {
      validators: Validators.compose([Validators.required]), errors: {
        required: 'errors.user.password_required'
      }
    },
    terms: {
      validators: Validators.compose([Validators.requiredTrue])
    }
  };

  selectedSlideId$ = this.selectedSlideElement$.pipe(
    map(element => element?.id)
  );

  isSlideScrolledToBottom$ = new BehaviorSubject(false);
  scrolledToBottomObserver: ElementRef<HTMLElement>;
  observer: IntersectionObserver;

  private readonly destroyed$ = new Subject<void>();
  private authError$ = this.store.select(getAuthError);
  private checkingPassword$: Observable<boolean> = this.store.select(isCheckingPassword);

  constructor(
    private store: Store<State>,
    public config: ClarityConfig,
    public platform: Platform,
    private nav: NavController,
    private browser: BrowserService,
    private subscriptionsService: SubscriptionsService,
    private formBuilder: FormBuilder,
    private connectivityService: ConnectivityService,
    private alertsService: AlertsService,
    private translate: TranslateService,
    private toasts: ToastService,
    private loadingService: LoadingService
  ) {
    super();
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      terms: new FormControl(false, this.validations.terms),
      password: new FormControl('', this.validations.password),
      captcha: new FormControl()
    });

    this.swiperInit$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.swiper.on('slideChangeTransitionStart', () => {
        if (this.swiper.slides[this.swiper.activeIndex]) {
          (this.swiper.slides[this.swiper.activeIndex] as HTMLElement).style.zIndex = `${this.swiper.activeIndex}`;
        }
      });

      this.selectedSlideNumber$.pipe(takeUntil(this.destroyed$)).subscribe(slideId => this.selectedSlideId = slideId);
    });

    this.observer = new IntersectionObserver(entries => {
      this.isSlideScrolledToBottom$.next(!entries[0].isIntersecting);
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0
    });

    this.swiperInit$.subscribe(() => {
      this.checkSubscription();
      this.checkEnrollment();
    });
  }

  ionViewWillEnter() {
    this.store.dispatch(new ClearLoginState());
    this.addScrollInputIntoViewListener();
  }

  ionViewWillLeave() {
    this.removeScrollInputIntoViewListener();
  }

  ionViewDidEnter() {
    this.authError$
      .pipe(takeUntil(this.destroyed$), filter(error => error && error.httpStatus === 429))
      .subscribe(() => this.captcha.render());
  }

  closeModal() {
    this.store.dispatch(new navigationActions.CloseAllModals());
  }

  isStepValid() {
    return true; // true because button will be disabled in html with isStepInvalid
  }

  isStepInvalid(step: string) {
    if (!step) return false;

    return this.form.get(step)?.invalid;
  }

  async slideFinished() {
    // prevent offline access
    if(this.connectivityService.isOffline()) {
      return this.alertsService.offlineNotification();
    }
    const captchaToken = this.form.get('captcha').value;

    this.loadingService.useLoadingObservable(this.checkingPassword$);

    if (this.form.get('password').valid && this.form.get('captcha').valid) {
      this.store.dispatch(new authActions.CheckPassword({
        current_password: this.form.get('password').value,
        ...captchaToken && ({'g-recaptcha-response': captchaToken})
      }));
    } else {
      this.toasts.error(this.translate.get('errors.common.form_error'));
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onViewSubscription() {
    this.subscriptionsService.openAppStore();
    this.closeModal();
  }

  openHelpCenter() {
    this.browser.goTo(this.config.env.helpCenterUrl);
  }

  navigateNext() {
    if(this.displayEnrollmentStep) {
      this.tryNavigateToNext();
    }
    // If enrollment step is disabled, we skip slide 2
    if(this.selectedSlideId === 1) {
      this.swiper.allowSlideNext = true;
      this.swiper.slideTo(3);
      this.swiper.allowSlideNext = false;
    }
  }

  navigatePrevious() {
    if(this.displayEnrollmentStep) {
      this.tryNavigateToPrevious();
    }
    // If enrollment step is disabled, we skip slide 2
    if(this.selectedSlideId === 3) {
      this.swiper.allowSlidePrev = true;
      this.swiper.slideTo(1);
      this.swiper.allowSlidePrev = false;
    }

  }

  private addScrollInputIntoViewListener() {
    window.addEventListener('ionKeyboardDidShow', this.onKeyboardShow.bind(this));
    window.addEventListener('ionKeyboardDidHide', this.onKeyboardHide.bind(this));
  }

  private removeScrollInputIntoViewListener() {
    window.removeEventListener('ionKeyboardDidShow', this.onKeyboardShow.bind(this));
    window.addEventListener('ionKeyboardDidHide', this.onKeyboardHide.bind(this));
  }

  private onKeyboardShow() {
    this.keyboardOpen.next(true);
  };

  private onKeyboardHide() {
    this.keyboardOpen.next(false);
  };

  private generateEnrollmentFormGroup() {
    const formGroup = {};

    this.userPrograms.map(userProgram => {
      formGroup[userProgram.code] = new FormControl(false, Validators.requiredTrue);
    });

    this.form.addControl('enrollment', new FormGroup(formGroup));
  }

  private async checkEnrollment() {
    const userPrograms: Array<Partial<UserProgram>> = await this.store.select(getCurrentUserPrograms)
      .pipe(take(1))
      .toPromise();
    this.displayEnrollmentStep = userPrograms.length > 1;

    this.userPrograms = userPrograms
      .filter(programs => programs.program_code !== 'BBS') // old UFS name
      .map(program => ({
        code: program.program_code as ProgramCode,
        name: getProgramName(program.program_code as ProgramCode)
      }));

    this.generateEnrollmentFormGroup();
  }

  private async checkSubscription() {
    const subscription = await this.store.select(getCurrentSubscription)
      .pipe(take(1))
      .toPromise();

    this.subscriptionType = subscription.provider as ProviderType;

    // Don't display subscription step if free or license user
    this.displaySubscriptionStep =
      (['itunes', 'googleplay'].includes(this.subscriptionType) && subscription.appstore_renewal_on) || this.subscriptionType === 'stripe';

    if(!this.displaySubscriptionStep){
      setTimeout(() => this.tryNavigateToNext());
    }
  }
}
