import { Component, Input, OnInit } from '@angular/core';
import { LiveBonusExercise } from '../../store/session/selectors/program.selectors';
import { Exercise } from '../../store/normalized/schemas/exercise.schema';
import { ClarityConfig } from '../../config/clarity.config';
import { SessionState } from '../../store/session/session.reducers';
import { Store } from '@ngrx/store';
import { getLiveSubscription, LiveSubscription } from '../../store/normalized/selectors/subscription.selectors';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'exercise-item',
  styleUrls: ['exercise-item.component.scss'],
  template: `
    <button class="exercise-item-button" [class.unlocked]="!isLocked">
      <div class="locked-item-indicator" *ngIf="isLocked"></div>
      <div class="new-item-indicator" *ngIf="_exerciseModel?.isNew && !isWizard"></div>
      <cl-premium-badge *ngIf="isPremiumExercise"></cl-premium-badge>
      <div class="icon"><img [src]="imageSrc"/></div>
      <div class="divider"></div>
      <div class="content-wrapper">
        <span class="title">{{_exerciseModel.exercise.title}}</span>
        <p class="timer" *ngIf="exerciseHasTimer">
          {{_exerciseModel.exercise.length | processTime}}
        </p>
      </div>
    </button>
  `
})
export class ExerciseItemComponent implements OnInit {
  @Input() isWizard: boolean;
  @Input()
  set exerciseItem(value: LiveBonusExercise | Exercise) {
    this._exerciseModel = value;
    if (!this._exerciseModel.exercise) {
      this._exerciseModel = { exercise: this._exerciseModel as Exercise};
    }
  }

  get exerciseHasTimer(): boolean {
    const EXERCISE_KINDS_WITH_TIMER = ['audio', 'video'];

    return EXERCISE_KINDS_WITH_TIMER.includes(this._exerciseModel.exercise.kind);
  }

  get imageSrc(): string {
    const { kind } = this._exerciseModel.exercise;
    const imageSubpath = this.isLocked ? kind + '-locked' : kind;

    return `assets/imgs/${imageSubpath}.png`;
  }

  get isLocked(): boolean {
    return this._exerciseModel.isLocked && !this.isPremiumExercise;
  }

  public _exerciseModel;
  public isPremiumExercise = false;
  private subscription$: Observable<LiveSubscription> = this.store.select(getLiveSubscription);

  constructor(
    public config: ClarityConfig,
    private store: Store<SessionState>
  ) {}

  ngOnInit() {
    this.subscription$
      .pipe(take(1))
      .subscribe((liveSubscription: LiveSubscription) => {
        this.isPremiumExercise =
          this._exerciseModel.moduleNumber ? this._exerciseModel.moduleNumber > Number(this.config.program.trialModules) && !liveSubscription?.isActive : false;
      });
  }
}
