import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { LoginEmailPage } from './login-email';
import { ResetPasswordLinkComponent } from './components/reset-password-link.component';
import { SharedModule } from '../../../shared/index';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    LoginEmailPage,
    ResetPasswordLinkComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([{ path: '', component: LoginEmailPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ],
  exports: [
  ]
})

export class LoginEmailPageModule {}
