import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { ModalController, NavController } from '@ionic/angular';

import { from, of, Subscription } from 'rxjs';
import { concatMap, filter, map, tap, withLatestFrom, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClarityConfig } from '../../../../app/config/clarity.config';
import { ConnectivityService } from '../../../../app/services/connectivity.service';
import { AnalyticsService } from '../../../../app/services/analytics/analytics.service';

import * as navigationActions from '../actions/navigation.actions';
import * as accountActions from '../actions/account.actions';
import * as syncActions from '../actions/sync.actions';
import * as subscriptionActions from '../actions/subscription.actions';
import * as myCoachActions from '../actions/my-coach.actions';

import { getCurrentWeek } from '../selectors/program.selectors';
import { AccountPage } from '../../../pages/account/account';
import { CravingToolPage } from '../../../pages/craving-tool/craving-tool';
import { LessonsPage } from '../../../pages/lessons/lessons';
import { WizardPage } from '../../../pages/wizard/wizard';
import { PlayPage } from '../../../pages/play/play';
import { NextStepsPage } from '../../../pages/next-steps/next-steps';
import { UpgradePage } from '../../../pages/upgrade/upgrade';
import { BreatheExercisePage } from '../../../pages/breathe-exercise/breathe-exercise';
import { getLiveSubscription } from '../../normalized/selectors/subscription.selectors';
import { isFullSynced } from '../selectors/sync.selectors';
import { isAccountSetup } from '../selectors/account.selectors';
import { SessionState } from '../session.reducers';
import { OnboardingPage } from 'src/app/pages/onboarding/onboarding';
import { MyStatsPage } from '../../../pages/my-stats/my-stats';
import { SmokingCravingToolPage } from '../../../pages/craving-tool-ctq/craving-tool-ctq';
import { GearsReviewPage } from '../../../pages/gears-review/gears-review';
import { RainExercisePage } from '../../../pages/rain-exercise/rain-exercise';
import { NewThemeWeekPage } from '../../../pages/new-theme-week/new-theme-week';
import { UserProfilePage } from '../../../pages/user-profile/user-profile';
import { RestartProgramPage } from 'src/app/pages/restart-program/restart-program';
import { NewCustomWeekPage } from '../../../pages/new-custom-week/new-custom-week';
import { ActivatedRoute, Router } from '@angular/router';
import { isAuthenticated } from '../../sensitive/selectors/auth.selectors';
import { InterstitialPage } from '../../../pages/interstitial/interstitial';
import { AccountSetupPage } from '../../../pages/account-setup/account-setup';
import { IntegrationsStatusModalComponent } from '../../../components/integrations-status-modal.component';
import { TemporaryMalfunctionPage } from '../../../pages/subscribe/components/temporary-malfunction.component';
import { LicensePage } from '../../../pages/license/license';
import { SubscribePage } from '../../../pages/subscribe/subscribe';
import { IonicModalAnimations } from 'src/app/utils/clarity-modals-transition.animation';
import { SelectThemePage } from '../../../pages/select-theme/select-theme';
import { CustomPathPage } from '../../../pages/custom-path/custom-path';
import { PostPage } from '../../../pages/post/post';
import { ProfileCompletionPage } from '../../../pages/profile-completion/profile-completion';
import { NrtPage } from '../../../pages/nrt/nrt';
import { ModalComponent } from '../../../components/modal/modal.component';
import { MyCoachCtqPage } from '../../../pages/my-coach-ctq/my-coach-ctq';
import { MyCoachConversationPage } from '../../../pages/my-coach-conversation/my-coach-conversation';
import { MyCoachBioPage } from '../../../pages/my-coach-bio/my-coach-bio';
import { WeeklyVideoComponent } from '../../../pages/community/components/weekly-video.component';
import { InAppMessagePage } from '../../../pages/inapp-message/inapp-message';
import { WelcomeVideoPage } from '../../../pages/welcome-video/welcome-video';
import { DebugPage } from '../../../pages/debug/debug';
import { StripeFormComponent } from '../../../pages/subscribe/components/stripe-form.component';
import { EmptyModalComponent } from '../../../components/empty-modal/empty-modal.component';
import { ProgramTypeService } from '../../../services/program-type.service';
import { InterstitialDppCongratsPage } from 'src/app/pages/interstitial/interstitial-dpp-congrats';
import { AccountDeletionPage } from '../../../pages/account-deletion/account-deletion';
import { AccountDeletionConfirmationPage } from '../../../pages/account-deletion-confirmation/account-deletion-confirmation';
import { UpgradeSource } from '../actions/navigation.actions';
import { InterstitialDppFitbitTrackerPage } from 'src/app/pages/interstitial/interstitial-dpp-fitbit-tracker';
import { InterstitialErnWeightLossDiagnosticPage } from 'src/app/pages/interstitial/interstitial-ern-weight-loss-diagnostic';
import { PremiumPage } from 'src/app/pages/premium';
import { getCanExtendERNProgram } from '../../normalized/selectors/user.selectors';
import { InterstitialParamsHandlerService } from 'src/app/services/interstitial-params-handler.service';

export const PAGES = {
  EmptyModalComponent,
  ModalComponent,
  ProfileCompletionPage,
  InAppMessagePage,
  AccountPage,
  AccountSetupPage,
  BreatheExercisePage,
  NextStepsPage,
  CravingToolPage,
  CustomPathPage,
  SmokingCravingToolPage,
  GearsReviewPage,
  NewThemeWeekPage,
  NewCustomWeekPage,
  NrtPage,
  RainExercisePage,
  WelcomeVideoPage,
  InterstitialPage,
  InterstitialDppCongratsPage,
  InterstitialDppFitbitTrackerPage,
  InterstitialErnWeightLossDiagnosticPage,
  OnboardingPage,
  PlayPage,
  PostPage,
  LessonsPage,
  LicensePage,
  SelectThemePage,
  SubscribePage,
  RestartProgramPage,
  MyStatsPage,
  MyCoachCtqPage,
  MyCoachConversationPage,
  MyCoachBioPage,
  UpgradePage,
  PremiumPage,
  WizardPage,
  StripeFormComponent,
  UserProfilePage,
  DebugPage,
  IntegrationsStatusModalComponent,
  TemporaryMalfunctionPage,
  AccountDeletionPage,
  AccountDeletionConfirmationPage
};

const ANIMATIONS = {
  'IonicModalAnimations.mdEnterAnimation': IonicModalAnimations.mdEnterAnimation,
  'IonicModalAnimations.mdLeaveAnimation': IonicModalAnimations.mdLeaveAnimation,
  'IonicModalAnimations.slideEnterAnimation': IonicModalAnimations.slideEnterAnimation,
  'IonicModalAnimations.slideLeaveAnimation': IonicModalAnimations.slideLeaveAnimation,
  'IonicModalAnimations.quickLeaveAnimation': IonicModalAnimations.quickLeaveAnimation
};

@Injectable({providedIn: 'root'})
export class NavigationEffects {


  redirectAfterLogin$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.REDIRECT_AFTER_LOGIN),
    concatMap(action => of(action)
      .pipe(withLatestFrom(
        this.store.select(isAccountSetup),
        // this.store.select(getFlagsState),
        this.store.select(isFullSynced)
      ))
    ),
    map(([action, accountSetup, fullSynced]) => {
      // send user to account setup if profile is not complete
      if (!accountSetup) {
        this.analyticsService.userSignedUp();

        return new accountActions.AccountSetupStart();
      }

      // request sync or init program
      if (!fullSynced) {
        // @todo consider removing this call here, because registerUser() is being called in SyncEverything flow already
        // the only difference here is that SyncEverything takes some seconds to complete. But it won't be an issue
        // if we delay registerUser() because mixpanel can track events retroactively after identify()
        this.analyticsService.userLoggedIn();

        return new syncActions.SyncEverything();
      } else {
        if (this.config.isBrightcoveWebEnabled()) {
          console.log('Brightcove enabled & user already logged in, need to re-sync exercises!');

          this.store.dispatch(new syncActions.SyncExercises());
        }

        // good to go - init program
        return new syncActions.SyncEverythingComplete();
      }
    })
  ));

  navigateToUpgrade = createEffect(() => this.actions$.pipe(ofType<navigationActions.GotoUpgrade>(navigationActions.GOTO_UPGRADE),
    map((action) => action.payload),
    map((fromAccountSetup) => new navigationActions.RootGoTo({
      route: 'upgrade',
      params: {fromAccountSetup}
    }))
  ));

  navigateToCravingTool$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.OPEN_CRAVING_TOOL),
    tap((action: navigationActions.OpenCravingTool) => {
      this.store.dispatch(new navigationActions.OpenModal('CravingToolPage', action.payload));
    })
  ), {dispatch: false});

  openWizard$ = createEffect(() => this.actions$.pipe(ofType<navigationActions.OpenWizard>(navigationActions.OPEN_WIZARD),
    map((action) => action.payload),
    tap((options) => {
      this.store.dispatch(new navigationActions.OpenModal('WizardPage', {
        wizardType: options.type,
        afterCompleteOptions: options.afterCompleteOptions,
        userReferrer: options.userReferrer
      }));
    })
  ), {dispatch: false});

  openMyCoach$ = createEffect(() => this.actions$.pipe(ofType<navigationActions.OpenMyCoach>(navigationActions.OPEN_MY_COACH),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getLiveSubscription)))
    ),
    tap(([_, liveSubscription]) => {
      if (this.connection.preventAccessWhenOffline()) {
        return false;
      }

      this.store.dispatch(new subscriptionActions.SubscriptionRecheck());

      if (!liveSubscription || !liveSubscription.isActive) {
        return this.store.dispatch(new navigationActions.ShowUpgradePopup());
      }

      this.store.dispatch(new navigationActions.OpenModal('MyCoachCtqPage',
        {
          onDidDismiss: () => this.store.dispatch(new myCoachActions.CloseConversationModal())
        }
      ));
    })
  ), {dispatch: false});


  openMyCoachConversation$ = createEffect(() => this.actions$.pipe(
    ofType<navigationActions.OpenMyCoachConversation>(navigationActions.OPEN_MY_COACH_CONVERSATION),
    tap(({payload: conversationId, options: {showBack}}) => {
      if (this.connection.preventAccessWhenOffline()) {
        return false;
      }

      this.store.dispatch(new subscriptionActions.SubscriptionRecheck());

      if (conversationId) {
        this.store.dispatch(new myCoachActions.MarkConversationAsRead(conversationId.toString()));
      }

      let modalOptions: any = {
        showBack,
        conversationId,
        onDidDismiss: () => this.store.dispatch(new myCoachActions.CloseConversationModal())
      };

      if (showBack) {
        modalOptions = {
          ...modalOptions,
          enterAnimation: 'IonicModalAnimations.slideEnterAnimation',
          leaveAnimation: 'IonicModalAnimations.slideLeaveAnimation'
        };
      }

      this.store.dispatch(new navigationActions.OpenModal('MyCoachConversationPage', modalOptions));
    })
  ), {dispatch: false});


  openWeeklyCalls$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.OPEN_WEEKLY_CALLS),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getLiveSubscription)))
    ),
    map(([action, liveSubscription]) => {
      if (this.connection.preventAccessWhenOffline()) {
        return false;
      }

      this.store.dispatch(new subscriptionActions.SubscriptionRecheck());

      if (!liveSubscription || !liveSubscription.isActive) {
        return this.store.dispatch(new navigationActions.ShowUpgradePopup());
      }

      this.store.dispatch(new navigationActions.OpenModal('ModalComponent', {
        header: 'community.weekly_calls',
        headline: 'community.join_video_jud',
        embedComponent: WeeklyVideoComponent,
        showBackButton: false,
        icon: 'tv'
      }));
    })
  ), {dispatch: false});

  openInAppMessage$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.OPEN_INAPP_MESSAGE),
    map((action: navigationActions.OpenInAppMessage) => action.payload),
    map((message) => {
      const modalParams = {
        removeCustomCssClass: true,
        inAppMessage: message
      };

      return new navigationActions.OpenModal('InAppMessagePage', modalParams);
    })
  ));


  completeProfile$ = createEffect(() => this.actions$.pipe(
    ofType<accountActions.AccountSetupStart>(accountActions.ACCOUNT_SETUP_START),
    concatMap((_) => from(this.programTypeService.promise)),
    map(() => new navigationActions.RootGoTo({
      route: this.config.programDPPorWL()
        ? '/account-setup/dpp'
        : '/account-setup'
    })
    )
  ));

  navigationRootGoTo$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.ROOT_GOTO),
    map((action: navigationActions.RootGoTo) => action.payload),
    tap((options) => {
      this.nav.navigateRoot(options.route, {queryParams: options.params || {}});
    })
  ), {dispatch: false});

  openLessons$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.OPEN_LESSONS),
    tap(() => {
      this.store.dispatch(new navigationActions.OpenModal('LessonsPage'));
    })
  ), {dispatch: false});

  showInterstitial$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.SHOW_INTERSTITIAL),
    map((action: navigationActions.ShowInterstitial) => action.payload),
    tap((navOptions) => {
      this.store.dispatch(new navigationActions.OpenModal('InterstitialPage', navOptions.params));
    })
  ), {dispatch: false});

  showOnboarding = createEffect(() => this.actions$.pipe(ofType(navigationActions.SHOW_ONBOARDING),
    map((action: navigationActions.ShowOnboarding) => action.payload),
    tap((navOptions) => {
      const options: any = {
        ...navOptions.params,
        forceBackdrop: true // We need to force a backdrop here because sometimes we show onboarding dialog on top of another modal
      };

      this.store.dispatch(new navigationActions.OpenModal(navOptions.page, options));
    })
  ), {dispatch: false});


  showNextSteps$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.SHOW_NEXT_STEPS),
    switchMap(action => of(action)
      .pipe(
        withLatestFrom(
          this.store.select(getCurrentWeek),
          this.store.select(getCanExtendERNProgram)
        )
      )
    ),
    tap(([_, currentWeek, canExtendERNProgram]) => {
      if (this.config.isERN() && canExtendERNProgram) {
        // Display ERN 'after match' diagnostic modal if the program is not already extended
        this.store.dispatch(new navigationActions.OpenModal('InterstitialErnWeightLossDiagnosticPage', {
          backdropDismiss: false,
          forceBackdrop: false
        }));
      } else {
        // Display default NextStepsPage modal
        this.store.dispatch(new navigationActions.OpenModal('NextStepsPage', {lastWeekType: currentWeek.type}));
      }
    })
  ), {dispatch: false});


  showUpgradePopup$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.SHOW_UPGRADE_POPUP),
    tap(() => {
      this.store.dispatch(new navigationActions.OpenModal('UpgradePage'));
    })
  ), {dispatch: false});


  showPremiumPopup$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.SHOW_PREMIUM_POPUP),
    tap(() => {
      this.store.dispatch(new navigationActions.OpenModal('PremiumPage', {
        showCloseButton: true
      }));
    })
  ), {dispatch: false});


  showUpgradeSuccessPopup$ = createEffect(() => this.actions$
    .pipe(ofType<navigationActions.ShowUpgradeSuccessPopup>(navigationActions.SHOW_UPGRADE_SUCCESS_POPUP),
      tap(action => {
        this.store.dispatch(new navigationActions.CloseAllModals());
        this.store.dispatch(new navigationActions.OpenModal('UpgradePage', {
          forceShowSuccessMessage: true
        }));
        if (action.source === UpgradeSource.PREMIUM_TAB) {
          this.store.dispatch(new navigationActions.GotoDashboard());
        }
      })
    ), {dispatch: false});


  showBreatheExercise$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.SHOW_BREATHE_EXERCISE),
    tap(() => {
      this.store.dispatch(new navigationActions.OpenModal('BreatheExercisePage'));
    })
  ), {dispatch: false});

  openModal: string;


  navigateToDashboard$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.GOTO_DASHBOARD),
    map(() => new navigationActions.RootGoTo({
      route: this.config.program.programCode === 'ctq' ? '/tabs/home' : '/tabs/dashboard',
      params: this.openModal ? {modal: this.openModal} : {}
    }))
  ));

  navigateToLessons$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.GOTO_LESSONS),
    map(() => new navigationActions.RootGoTo({
      route: '/tabs/lessons',
      params: this.openModal ? {modal: this.openModal} : {}
    }))
  ));

  navigateToHome$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.GOTO_HOME),
    map(() => new navigationActions.RootGoTo({
      route: '/tabs/home',
      params: this.openModal ? {modal: this.openModal} : {}
    }))
  ));

  navigateToPremium$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.GOTO_PREMIUM),
    map(() => new navigationActions.RootGoTo({
      route: '/tabs/premium',
      params: this.openModal ? {modal: this.openModal} : {}
    }))
  ));

  navigateToDeleteAccountThankYou$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.GOTO_DELETE_ACCOUNT_THANK_YOU),
    map(() => {
      this.modalSubscription && this.modalSubscription.unsubscribe();

      return new navigationActions.RootGoTo({route: 'delete-account/thank-you'});
    })
  ));

  currentModals: HTMLIonModalElement[] = [];

  openBottomSheetModal$  = createEffect(() => this.actions$.pipe(ofType(navigationActions.OPEN_BOTTOM_SHEET_MODAL),
    tap(async (action: navigationActions.OpenModal) => {
      if (!PAGES[action.payload]) {
        return;
      }

      // TODO Need to add the same leaveAnimation when the user clicks on the cancel button
      const modal = await this.modalCtrl.create({
        component: PAGES[action.payload],
        breakpoints: [0, this.config.isDevice ? 0.95 : 0.90],
        initialBreakpoint: this.config.isDevice ? 0.95 : 0.90,
        cssClass: 'bottom-sheet-modal'
      });

      this.currentModals.push(modal);
      await modal.present();
    })
  ), {dispatch: false});

  openModal$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.OPEN_MODAL),
    tap(async (action: navigationActions.OpenModal) => {
      if (!PAGES[action.payload]) {
        return;
      }

      let cssClassName = action.payload !== 'OnboardingPage' && this.config.isWebApp ? 'web-modal' : '';

      if (this.config.isWebApp && this.config.runningOnSafari && this.config.jwplayerEnabled() && !this.config.isBrightcoveWebEnabled()) {
        // Workaround for the JWPlayer subtitles issue found in Safari - https://github.com/jwplayer/jwplayer/issues/1067
        // The problem is caused when the <video> element or its container has a "transform" applied through css
        // .web-modal class sets "transform: translate(-50%, -50%)" to the .modal-wrapper which is the video container
        // So let's just remove that web-mobal class for safari::web::jwplayer
        if (action.options && action.options.record && action.options.record.exercise.kind === 'video') {
          cssClassName = '';
        }
      }

      if (action.options.cssClass) {
        cssClassName = `${cssClassName} ${action.options.cssClass}`;
      }

      // Relying on ionic modal controller implementation (via this.modalCtrl.getTop()) causes issues as there is a delay
      // between the time the modal is closed and that function returns null as it's being deconstructed.
      // Instead we rely on our own implementation through this.currentModals array.
      const modalAlreadyOpen = Boolean(this.currentModals.length);

      if (modalAlreadyOpen && action.options.multipleModal === false) {
        return;
      }

      const showBackdrop = !modalAlreadyOpen || action.options.forceBackdrop ? true : false;
      const backdropDismiss = action.options?.backdropDismiss === undefined ? true : action.options.backdropDismiss;

      const modal = await this.modalCtrl.create({
        component: PAGES[action.payload],
        componentProps: action.options,
        enterAnimation: ANIMATIONS[action.options.enterAnimation] || IonicModalAnimations.mdEnterAnimation,
        leaveAnimation: ANIMATIONS[action.options.leaveAnimation] || IonicModalAnimations.mdLeaveAnimation,
        cssClass: cssClassName,
        showBackdrop,
        backdropDismiss
      });

      this.currentModals.push(modal);

      modal.onDidDismiss()
        .then(() => {
          const index = this.currentModals.findIndex(({id}) => id === modal.id);
          if (index >= 0) {
            this.currentModals.splice(index, 1);
          }
        });

      if (action.options.route) {
        const currentUrl: string = this.location.path();
        this.location.replaceState(action.options.route);
        modal.onDidDismiss()
          .then(() => {
            if (action.options.redirectOnClose) {
              this.nav.navigateForward(action.options.redirectOnClose);
            } else {
              this.location.replaceState(currentUrl);
            }
          });
      }

      if (action.options.onDidDismiss) {
        modal.onDidDismiss()
          .then(action.options.onDidDismiss);
      }
      if (action.options.onWillDismiss) {
        modal.onWillDismiss()
          .then(action.options.onWillDismiss);
      }
      if (action.options.onDidPresent) {
        await modal.present()
          .then(action.options.onDidPresent);
      } else {
        await modal.present();
      }

    })
  ), {dispatch: false});


  closeModal$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.CLOSE_MODAL),
    map((action: navigationActions.CloseModal) => action.payload),
    tap(async (data: any) => {
      const modalId = await data.modalId;
      let modal = await data.modal;
      const role = data.role;

      if (!modal) {
        modal = this.currentModals.find(({id}) => id === modalId);
      } else {
        console.warn('[WARNING]! action CLOSE_MODAL called with a HTML component.' +
          ' Do not dispatch actions with HTML components, as they\'re not good for serialization.' +
          ' Use `modalId` option instead of `modal`');
      }

      if (!modal) {
        console.error('[Close Modal] Modal was not found', modal, modalId);

        return;
      }

      modal.dismiss(role);
      const index = this.currentModals.findIndex(({id}) => id === modal.id);
      if (index >= 0) {
        this.currentModals.splice(index, 1);
      }
    })
  ), {dispatch: false});


  closePlayer$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.CLOSE_PLAYER),
    tap(() => {
      this.store.dispatch(new navigationActions.CloseModal({modalId: this.currentModals[this.currentModals.length - 1].id}));
    })
  ), {dispatch: false});


  closeLastModal$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.CLOSE_LAST_MODAL),
    tap(async (redirect: any) => {
      const modal = this.currentModals ? this.currentModals[this.currentModals.length - 1] : null;
      if (!modal) {
        return;
      }

      if (!modal.leaveAnimation) {
        modal.leaveAnimation = IonicModalAnimations.quickLeaveAnimation;
      }

      modal.dismiss();
    })
  ), {dispatch: false});


  closeAllModals$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.CLOSE_ALL_MODALS),
    tap(async (redirect: any) => {
      for (const index in this.currentModals) {
        const modal = this.currentModals[index];
        if (!modal) {
          console.log('[Close Modal] Modal was not found - closeAllModals$');
          continue;
        }
        let animation = IonicModalAnimations.mdLeaveAnimation;
        if (modal.leaveAnimation && parseInt(index, 10) !== (this.currentModals.length - 1)) {
          animation = IonicModalAnimations.quickLeaveAnimation;
        }
        modal.leaveAnimation = animation;
        modal.dismiss();
      }
      this.currentModals = [];

      if (redirect && redirect.payload) {
        // Redirect after closing all modals
        setTimeout(() => {
          // TimeOut here in order to wait for the transitions to be done
          this.store.dispatch(new navigationActions.RootGoTo({route: redirect.payload}));
        }, 300);
      }
    })
  ), {dispatch: false});


  closeInterstitial$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.CLOSE_INTERSTITIAL),
    tap(() => {
      try {
        this.store.dispatch(new navigationActions.CloseModal({modalId: this.currentModals[this.currentModals.length - 1].id}));
      } catch (error) {
        console.error(error);
      }
    })
  ), {dispatch: false});

  modalSubscription: Subscription;


  navigateToLogin$ = createEffect(() => this.actions$.pipe(ofType(navigationActions.GOTO_LOGIN),
    map(() => {
      this.modalSubscription && this.modalSubscription.unsubscribe();

      return new navigationActions.RootGoTo({route: '/login'});
    })
  ));

  subscriptionPurchased$ = createEffect(() => this.actions$.pipe(ofType<subscriptionActions.SubscriptionPurchased>(subscriptionActions.SUBSCRIPTION_PURCHASED),
    tap(() => {
      if (this.router.url === '/tabs/premium') {
        this.store.dispatch(new navigationActions.GotoDashboard());
      }
    })), {dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<SessionState>,
    private route: ActivatedRoute,
    private nav: NavController,
    private config: ClarityConfig,
    private location: Location,
    private modalCtrl: ModalController,
    private connection: ConnectivityService,
    private analyticsService: AnalyticsService,
    private programTypeService: ProgramTypeService,
    private router: Router
  ) {
    this.store.select(isAuthenticated)
      .pipe(
        filter((authenticated) => authenticated === true)
      )
      .subscribe(() => {
        this.modalSubscription = this.route.queryParams.subscribe(((params) => {
          this.openModal = params.modal || null;
          if (this.openModal) {
            setTimeout(() => {
              this.store.dispatch(new navigationActions.OpenModal(this.openModal));
            }, 1000);
          }
        }));
      });
  }

  getActiveNav(): any {
    return {};
    // return this.app.getActiveNavs()[0];
  }

  getAllViews() {
    return {};
    // return this.getActiveNav()
    //   .getViews();
  }

  getLastView(): any {
    return {};
    // return this.getActiveNav()
    //   .last();
  }

}
