// persistent flags reducer

import * as tabsActions from './tabs.actions';

export interface TabsState {
  forcedTabsOpen: number;
}

export const initialPersistentTabsState: TabsState = {
  forcedTabsOpen: 0
};

export function persistentTabsReducer(state = initialPersistentTabsState, action: tabsActions.TabsActions): TabsState {

  switch (action.type) {
    case tabsActions.TABS_OPENED: {
      return {
        ...state,
        forcedTabsOpen: state.forcedTabsOpen + 1
      };
    }

  }

  return state;
}

export const forcedTabsOpen = (state: TabsState) => state.forcedTabsOpen;
