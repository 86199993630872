import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';

import { LiveLesson } from '../../../store/session/selectors/program.selectors';
import { ClarityConfig } from '../../../config/clarity.config';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { userGoalsSelector, getDefaultGoalsSelection } from '../../../store/normalized/selectors/goals.selectors';
import { getRecentTriggerName } from '../../../store/normalized/selectors/recent-stress-meter.selectors';
import { isLoadingUserGoals } from '../../../store/session/selectors/user-goals.selectors';
import { State } from '../../../store/state.reducer';
import {
  getCurrentUserCigaretteSavings,
  getCurrentUserEndDate,
  getCurrentUserStartDate,
  getUserMantra
} from '../../../store/normalized/selectors/user.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-quitting-pact-lesson',
  styleUrls: ['quitting-pact-lesson.component.scss'],
  template: `
    <cl-goals-overview
      [startDate]="startDate$ | async"
      [endDate]="endDate$ | async"
      [cigaretteSavings]="cigaretteSavings$ | async"
      [goalsSyncing]="goalsSyncing$ | async"
      [customGoals]="customGoals$ | async"
      [checkedDefaultGoals]="checkedDefaultGoals$ | async"
      [mantra]="mantra | async"
      [lastTrigger]="lastTrigger$ | async"
      [enableEdit]="false">
    </cl-goals-overview>
  `
})
export class QuittingPactLessonComponent {
  @Output() action = new EventEmitter();
  @Input() lesson: LiveLesson;
  selectedDefaultGoals$ = this.store.select(getDefaultGoalsSelection);
  customGoals$ = this.store.select(userGoalsSelector);
  startDate$ = this.store.select(getCurrentUserStartDate);
  endDate$ = this.store.select(getCurrentUserEndDate);
  cigaretteSavings$ = this.store.select(getCurrentUserCigaretteSavings);
  goalsSyncing$ = this.store.select(isLoadingUserGoals);
  userMantra$ = this.store.select(getUserMantra);
  lastTrigger$ = this.store.select(getRecentTriggerName)
    .pipe(
      map((triggerName) => {
        const noTriggerText = `wizard_items.no_trigger_yet_${this.config.currentProgram()}`;

        return triggerName ? triggerName : noTriggerText;
      })
    );

  public mantra = combineLatest([this.userMantra$, this.translate.get('goals_menu.no_mantra')])
    .pipe(
      map(([userMantra, mantraPlaceholder]) => {
        if (userMantra) {
          return userMantra;
        }

        return mantraPlaceholder;
      })
    );

  public checkedDefaultGoals$ = this.selectedDefaultGoals$.pipe(
    map(goals => goals.filter(goal => goal.checked))
  );

  constructor(
    private store: Store<State>,
    public config: ClarityConfig,
    private translate: TranslateService
  ) {
  }

  doAction() {
    this.action.emit();
  }

}
