import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  HostBinding,
  OnInit
} from '@angular/core';

import { IonContent, NavController, NavParams } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {distinctUntilChanged, take} from 'rxjs/operators';

import { LoadingService } from '../../services/loading.service';

import {
  LiveBonusExercise,
  LiveLesson, getPlayLesson
} from '../../store/session/selectors/program.selectors';
import { getExerciseById, getPlayExercise } from '../../store/normalized/selectors/exercises.selectors';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { AfterCompleteLessonOptions, CompleteBonusExercise, CompleteLesson } from '../../store/session/actions/program.actions';
import { SessionState } from '../../store/session/session.reducers';
import { ClosePlayer, CloseAllModals, CloseLastModal } from '../../store/session/actions/navigation.actions';
import { ENTER_DURATION } from '../../utils/clarity-modals-transition.animation';
import { ClarityConfig } from '../../config/clarity.config';
import { adaptBonusExerciseToAnalytics, adaptLessonToAnalytics } from 'src/app/services/analytics/data-adapters';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';
import { BrightcoveNativeAudioPlayerService } from '../../services/brightcove/brightcove-native-audio-player.service';

type LessonOrExercise = LiveLesson | LiveBonusExercise;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-play',
  styleUrls: ['play.scss'],
  templateUrl: 'play.html'
})
export class PlayPage implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(IonContent, { static: true }) content: IonContent;
  @HostBinding('class.ern') isErn = this.config.program.programCode === 'ern';
  @HostBinding('class.ctq') isCtq = this.config.program.programCode === 'ctq';
  @HostBinding('class.ua') isUa = this.config.program.programCode === 'ua';

  public playExercise = false;
  public isExercise = false;
  public forceDesktop = false;

  public afterCompleteOptions: AfterCompleteLessonOptions;

  public lesson$: Observable<LiveLesson> = this.store.select(getPlayLesson);
  public exercise$: Observable<LiveBonusExercise>;

  public playItem$: Observable<LessonOrExercise>;

  transitionCompleted = false;
  modal = false;

  trackObserver: Subscription;

  constructor(
    public navCtrl: NavController,
    public loading: LoadingService,
    private navParams: NavParams,
    private store: Store<SessionState>,
    private ref: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private config: ClarityConfig,
    private brightcoveNativeAudioPlayerService: BrightcoveNativeAudioPlayerService
  ) {
    this.playExercise = this.navParams.get('exercise');
    this.isExercise = this.navParams.get('isExercise'); // @todo refactor. Looks like this isn't being used anymore

    this.afterCompleteOptions = this.navParams.get('afterCompleteOptions');

    this.exercise$ = this.isExercise
      ? this.store.select(getExerciseById)
      : this.store.select(getPlayExercise);

    this.playItem$ = this.playExercise ? this.exercise$ : this.lesson$;
  }

  async ngOnInit() {
    if (this.config.isBrightcoveEnabled()) {
      this.brightcoveNativeAudioPlayerService.closePlayerModal.pipe(take(1)).subscribe(() => {
        this.onClose();
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.transitionCompleted = true;
      try {
        this.ref.detectChanges();
      }
      catch (error) {
        console.log('Cannot trigger change detection!');
      }

    }, ENTER_DURATION);
  }

  onInitLesson() {
    this.content.scrollToTop();
  }

  onNextLesson(lesson) {
    this.playExercise
      ? this.store.dispatch(new CompleteBonusExercise(lesson, this.afterCompleteOptions))
      : this.store.dispatch(new CompleteLesson(lesson));
  }

  onBack() {
    this.store.dispatch(new ClosePlayer());
  }

  onClose() {
    if (this.afterCompleteOptions && this.afterCompleteOptions.closeModalStrategy === 'closeLastModal') {
      this.store.dispatch(new CloseLastModal());
    } else {
      this.store.dispatch(new CloseAllModals());
    }
  }

  isPerformLesson(exercise: any): boolean {
    if (!exercise) {
      return false;
    }

    return this.isExercise
      ? exercise.kind === 'perform'
      : exercise.record && exercise.record.exercise.kind === 'perform';
  }

  isCaptureLesson(exercise: any): boolean {
    if (!exercise) {
      return false;
    }

    return this.isExercise
      ? exercise && exercise.kind === 'capture'
      : exercise && exercise.record && exercise.record.exercise.kind === 'capture';
  }

  isAudioOrVideoLesson(exercise: any): boolean {
    return this.isExercise
      ? ['audio', 'video'].indexOf(exercise.kind) > -1
      : exercise && ['audio', 'video'].indexOf(exercise.record.exercise.kind) > -1;
  }

  ionViewDidEnter() {
    this.trackObserver = this.playItem$.pipe(
      distinctUntilChanged()
    ).subscribe((item: LessonOrExercise) => {
      if (this.playExercise) {
        this.analyticsService.trackEvent(AnalyticsEvents.OpenedExercise, adaptBonusExerciseToAnalytics(item as LiveBonusExercise));
      } else {
        this.analyticsService.trackEvent(AnalyticsEvents.OpenedLesson, adaptLessonToAnalytics(item as LiveLesson));
      }
    });
  }

  ionViewDidLeave() {
    this.trackObserver.unsubscribe();
  }

  ngOnDestroy() {}

}
