import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';

@Injectable({providedIn: 'root'})
export class UserProgressProvider {

  private userProgressEndpoint = '/user_progress';

  constructor(
    private http: HttpProvider
  ) {

  }

  getUserProgress() {
    return this.http.get<any>(this.userProgressEndpoint); // TODO: replace any to UserProgress
  }

}
