// import { Injectable } from '@angular/core';
// import { Cordova, IonicNativePlugin, Plugin } from '@ionic-native/core';

// @Plugin({
//   pluginName: 'md5chksum',
//   plugin: 'cordova-plugin-file-md5',
//   pluginRef: 'md5chksum',
//   platforms: ['Android', 'iOS']
// })
// @Injectable({
//   providedIn: 'root'
//  })
// export class Md5Plugin extends IonicNativePlugin {

//   @Cordova()
//   file(fileEntry, callback, error): any {
//     return;
//   }
// }

import { IonicNativePlugin, cordova } from '@ionic-native/core';
import { Injectable } from '@angular/core';

@Injectable()
export class Md5Plugin extends IonicNativePlugin {
  static plugin = 'cordova-plugin-file-md5';
  static pluginName = 'md5chksum';
  static pluginRef = 'md5chksum';
  static platforms = ['Android', 'iOS'];

  file(fileEntry, callback, error): any {
    return cordova(this, 'file', { }, [fileEntry, callback, error]);
  }
}
