import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  LiveLesson
} from '../../../../../store/session/selectors/program.selectors';
import { Store } from '@ngrx/store';
import { State } from '../../../../../store/state.reducer';
import { Observable, of, timer, merge } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { SimpleAssessmentStep, ISimpleAssessmentCheckbox, SimpleAssessmentModel } from './simple-assessment.interface';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { correctSelectionValidator, selectionValidator } from './simple-assessment.validators';
import { fadeInOut } from '@mindsciences/utils';
import { AlertsService } from 'src/app/services/alerts.service';
import { PerformService } from '../../../services/perform.service';
import { ClarityConfig } from 'src/app/config/clarity.config';

@Component({
  selector: 'cl-simple-assessment',
  styleUrls: ['simple-assessment.component.scss'],
  animations: [fadeInOut],
  template: `
    <ion-row class="lateral-padding">
      <ion-col>
        <h4 *ngIf="isAssessmentVersionSupported">{{currentStep?.ui?.heading?.text}}</h4>
        <h4 *ngIf="!isAssessmentVersionSupported">{{ 'simple_assessment.version_not_supported' | translate }}</h4>
        <ion-row *ngIf="!isAssessmentVersionSupported" class="appstore-buttons-wrapper">
          <a *ngIf="config.isIos" href="{{config.program.appStoreUrl}}" target="_blank">
            <img src="assets/imgs/stores/appstore.png">
          </a>

          <a *ngIf="config.isAndroid" href="{{config.program.googlePlayUrl}}" target="_blank">
            <img src="assets/imgs/stores/googleplay.png">
          </a>
        </ion-row>
        <div *ngIf="currentStep?.config?.showFeedbackOnSelection" class="feedback-message-box">
          <div class="chip-container">
            <ion-chip *ngIf="showFeedbackMessage$ | async" [@fadeInOut] [disabled]="true"
              [class.success]="isFeedbackMessagePositive" [class.fail]="!isFeedbackMessagePositive">
              <ion-label>{{feedbackMessage}}</ion-label>
            </ion-chip>
          </div>
        </div>
        <form *ngIf="isAssessmentVersionSupported" [formGroup]="assessmentForm">
          <ng-container *ngFor="let item of currentStep?.items">
            <ng-container [ngSwitch]="item.type">
              <cl-checkbox-list-item
                *ngSwitchCase="'checkbox'"
                [label]="item.label"
                [checked]="item.value"
                [formControlName]="item.name"
                (selected)="itemSelected($event, item)">
              </cl-checkbox-list-item>
              <div class="input-wrapper" *ngSwitchCase="'text'">
                <div class="input-row">
                  <span class="input-label">{{ item.label }}</span>
                  <input
                  [type]="item.type"
                  [formControlName]="item.name"
                  [value]="item.value"
                  [maxlength]="item.maxLength"
                  [placeholder]="item?.placeholder || (('simple_assessment.add_something_else' | translate) + '...')">
                </div>
                <div *ngIf="item.maxLength && assessmentForm.get(item.name).value.length > (item.maxLength * 0.8)" class="character-counter">
                  {{ item.maxLength - assessmentForm.get(item.name).value.length }} / {{ item.maxLength }}
                </div>
              </div>
            </ng-container>
          </ng-container>
        </form>
      </ion-col>
    </ion-row>
    <ion-row
      class="action-button-holder"
      [class.sticky]="isNextLessonEnabled"
    >
      <cl-next-lesson-button
        [lesson]="lesson"
        [enabled]="isNextLessonEnabled"
        [label]="currentStep?.ui?.actionButton?.label"
        (next)="submitForm()">
      </cl-next-lesson-button>
    </ion-row>
  `
})

export class SimpleAssessmentComponent implements OnChanges, AfterViewInit {
  private readonly CURRENT_API_VERSION = 1;
  public isAssessmentVersionSupported = true;

  @Input() lesson: LiveLesson;

  @Output() next = new EventEmitter();
  @Output() init = new EventEmitter();
  @Output() changeTitle = new EventEmitter<string>();

  private model: SimpleAssessmentModel;
  private currentStepIndex: number;
  public currentStep: SimpleAssessmentStep;
  public showFeedbackMessage$: Observable<boolean>;
  public feedbackMessage: string;
  public assessmentForm: FormGroup;

  constructor(
    public store: Store<State>,
    public config: ClarityConfig,
    private formBuilder: FormBuilder,
    private alerts: AlertsService,
    private performService: PerformService
  ) {
  }

  get isFeedbackMessagePositive() {
    return this.feedbackMessage === this.currentStep.ui.feedbackMessage.correctSelectionText;
  }

  get formValidations() {
    return this.currentStep?.validators.form;
  }

  get isNextLessonEnabled() {
    return !this.isAssessmentVersionSupported || (this.currentStep?.config.disableSubmitWhenFormInvalid ? this.assessmentForm.valid : true);
  }

  ngOnChanges() {
    this.model = this.performService.parseSimpleAssessmentModel(this.lesson);
    const parsedApiVersion = Boolean(this.model.apiVersion) ? this.model.apiVersion : 0;
    this.isAssessmentVersionSupported = parsedApiVersion <= this.CURRENT_API_VERSION;

    if (this.isAssessmentVersionSupported) {
      this.currentStepIndex = 1;
      this.renderCurrentStepFromModel();
    }
  }

  private renderCurrentStepFromModel() {
    this.currentStep = new SimpleAssessmentStep(this.performService.getCurrentSimpleAssessmentStep(this.model, this.currentStepIndex));
    this.buildFormFromPayload();
    if (this.currentStep.ui.heading.title) {
      this.changeTitle.emit(this.currentStep.ui.heading.title);
    }
  }

  ngAfterViewInit() {
    this.init.emit();
  }

  submitForm() {
    if (!this.isAssessmentVersionSupported) {
      this.next.emit(this.lesson);

      return;
    }

    if (this.shouldShowDialogOnSubmit()) {
      this.showDialogOnSubmit();

      return;
    }

    if (this.isMultiStepAssessment() && !this.isCurrentStepTheLast()) {
      this.showNextStep();

      return;
    }

    this.next.emit(this.lesson);
  }

  private isMultiStepAssessment() {
    return this.model.steps.length > 1;
  }

  private isCurrentStepTheLast() {
    return this.model.steps.length === this.currentStepIndex;
  }

  private showNextStep() {
    this.currentStepIndex++;
    this.renderCurrentStepFromModel();
  }

  private shouldShowDialogOnSubmit() {
    const numberOfItemsSelected = Object.values(this.assessmentForm.value).filter(value => Boolean(value)).length;

    return this.currentStep.config.dialogOnSubmitLimit > -1 && this.currentStep.config.dialogOnSubmitLimit >= numberOfItemsSelected;
  }

  private async showDialogOnSubmit() {
    const dialog = await this.alerts.alertController.create({
      header: this.currentStep.ui.dialogOnSubmit.message,
      buttons: [
        { text: this.currentStep.ui.dialogOnSubmit.backButtonText, role: 'cancel' },
        {
          text: this.currentStep.ui.dialogOnSubmit.buttonText,
          handler: () => this.next.emit(this.lesson)
        }
      ]
    });
    dialog.present();
  }

  public itemSelected(value: boolean, item: ISimpleAssessmentCheckbox) {
    if (!this.currentStep.config.showFeedbackOnSelection) { return; };
    if (value) {
      this.feedbackMessage = item.isValid ?
        this.currentStep.ui.feedbackMessage.correctSelectionText :
        this.currentStep.ui.feedbackMessage.incorrectSelectionText;
      this.showFeedbackMessage$ = merge(of(true), timer(2500).pipe(mapTo(false)));
    }
  }

  private buildFormFromPayload() {
    this.assessmentForm = this.formBuilder.group({});

    for (const control of this.currentStep.items) {
      this.assessmentForm.setControl(
        control.name,
        this.formBuilder.control(control.value, [])
      );
    }

    const validators: ValidatorFn[] = [
      ...(this.formValidations?.anySelected ? [selectionValidator(this.currentStep.items)] : []),
      ...(this.formValidations?.onlyCorrectSelected ? [correctSelectionValidator(this.currentStep.items)] : [])
    ];

    this.assessmentForm.addValidators(validators);
  }
}
