import { schema } from 'normalizr';

export interface WeightScaleOrder {
  id?: number;
  provider: 'fitbit';
  address1: string;
  address2: string;
  postal_code: string;
  city: string;
  state: string;
  country: string;
  scale_sku: '203BK' /* Aria air black */ | '203WT' /* Aria air write */;
  phone: string;
  first_name: string;
  last_name: string;
}

export const weightScaleOrderSchema = new schema.Entity<WeightScaleOrder>('weightScaleOrders');
