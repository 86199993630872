import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { ClarityConfig } from '../../../config/clarity.config';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/state.reducer';
import { isProgramExtensionEnabled } from 'src/app/store/normalized/selectors/user.selectors';
import { take, map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AccountSetupErnGuard implements CanActivate {

  constructor(
    private nav: NavController,
    private config: ClarityConfig,
    private store: Store<State>
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.store.select(isProgramExtensionEnabled).pipe(
      take(1),
      map(userAllowedToSelectProgram => {
        if (this.config.programERN() && userAllowedToSelectProgram) {
          return true;
        } else {
          this.nav.navigateRoot('account-setup');

          return false;
        }
      }));
  }
}
