import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { canStartThemeWeeks } from '../../store/session/selectors/program.selectors';
import { ClarityConfig } from '../../config/clarity.config';
import { State } from '../../store/state.reducer';
import * as navigationActions from '../../store/session/actions/navigation.actions';

@Component({
  selector: 'page-new-custom-week',
  styleUrls: ['new-custom-week.scss'],
  templateUrl: 'new-custom-week.html'
})
export class NewCustomWeekPage {
  modal;

  public isCoreProgramCompleted = this.store.select(canStartThemeWeeks);

  maxModules = this.config.isCTQ() ? 7 : 6;

  constructor(
    public store: Store<State>,
    public config: ClarityConfig,
    public navCtrl: NavController
  ) {
  }

  get isModal() {
    return true;
  }

  onBack() {
    if (this.isModal) {
      this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
    }
    else {
      this.navCtrl.pop();
    }
  }

  onNext() {
    this.store.dispatch(new navigationActions.OpenModal('CustomPathPage', {
      enterAnimation: 'IonicModalAnimations.slideEnterAnimation',
      leaveAnimation: 'IonicModalAnimations.slideLeaveAnimation'
    }));
  }
}
