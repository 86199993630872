import { Component, EventEmitter, HostBinding, Input, Output, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActionDialogContent, ActionDialogSettings } from './action-dialog.interface';
import { ClarityConfig } from 'src/app/config/clarity.config';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-action-dialog',
  styleUrls: ['action-dialog.component.scss'],
  template: `
    <ion-grid class="action-dialog-wrapper container" [ngClass]="dialogCssClasses">
      <ion-row *ngIf="settings.showDismissIcon">
        <img class="action-dialog-close-button" src="assets/imgs/close.png" (click)="onDismissClick()" />
      </ion-row>

      <ion-row>
        <h2 *ngIf="content.header" class="action-dialog-header">{{content.header | translate}}</h2>
      </ion-row>

      <ion-row>
        <p *ngIf="content.body" class="action-dialog-body" [innerHTML]="content.body | translate"></p>
      </ion-row>

      <ion-row class="action-dialog-media" *ngIf="content.media">
        <cl-action-dialog-media (mediaPlayed)="onMediaPlayed()" [media]="content.media"></cl-action-dialog-media>
      </ion-row>

      <ion-row class="action-dialog-actions">
        <ng-container *ngIf="content.cancelButton">
          <cl-action-button
            *ngIf="content.cancelButton.type === 'button'"
            class="action-dialog-cancel-button"
            whiteBg="true"
            label="{{content.cancelButton.label | translate}}"
            [small]="content.cancelButton.size === 'small'"
            [canClick]="!content.cancelButton.disabled"
            (action)="onDismissClick()">
          </cl-action-button>

          <p *ngIf="content.cancelButton.type === 'link'" class="link" (click)="onDismissClick()">
            {{content.cancelButton.label | translate}}
          </p>
        </ng-container>
        <ng-container *ngIf="content.actionButton">
          <cl-action-button
            *ngIf="content.actionButton.type === 'button'"
            class="action-dialog-action-button"
            label="{{content.actionButton.label | translate}}"
            [small]="content.actionButton.size === 'small'"
            [canClick]="!content.actionButton.disabled"
            (action)="onMainActionClick()">
          </cl-action-button>

          <p *ngIf="content.actionButton.type === 'link'" class="link" (click)="onMainActionClick()">
            {{content.actionButton.label | translate}}
          </p>
        </ng-container>
      </ion-row>
    </ion-grid>
  `
})
export class ActionDialogComponent implements OnInit {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  @Input() content: ActionDialogContent = {};
  @Input() settings?: ActionDialogSettings = {};

  @Output() mainAction = new EventEmitter<void | any>();
  @Output() dismiss = new EventEmitter<void>();
  @Output() mediaPlayed = new EventEmitter<void>();

  get _defaultSettings(): ActionDialogSettings {
    return {
      showDismissIcon: true,
      size: 'auto',
      textAlignment: 'left',
      hasBackgroundImage: true,
      anchor: this.config.isWebApp ? 'top' : 'bottom'
    };
  }

  get dialogCssClasses(): {[key: string]: boolean} {
    const computedTextAlignment = this.settings.size !== 'auto' ? 'center' : this.settings.textAlignment;

    const textAlignmentClassname =  `text-alignment--${computedTextAlignment}`;
    const dialogSizeClassname = `size--${this.settings.size}`;
    const hasBackgroundImageClassname = 'has-background-image';
    const hasMediaClassname = 'has-media';
    const anchorClassname = `anchored-to-${this.settings.anchor}`;

    return {
      [textAlignmentClassname]: true,
      [dialogSizeClassname]: true,
      [hasBackgroundImageClassname]: this.settings.hasBackgroundImage,
      [hasMediaClassname]: this.content.media?.source ? true : false,
      [anchorClassname]: true
    };
  }

  constructor(
    private config: ClarityConfig
  ) {}

  ngOnInit(): void {
    // merge default settings with incoming input
    this.settings = { ...this._defaultSettings, ...this.settings };
  }

  onMediaPlayed() {
    this.mediaPlayed.emit();
  }

  onDismissClick() {
    this.dismiss.emit();
  }

  onMainActionClick() {
    this.mainAction.emit();
  }
}
