<ion-header>
  <cl-header-nav-buttons
    class="page-header"
    color="blank"
    [backButton]="false"
    (closeClick)="onClose()"
    [class.desktop]="config.usingDesktopOnWeb">
  </cl-header-nav-buttons>
</ion-header>

<ion-content>
  <div class="container">
    <ion-avatar class="avatar" [ngStyle]="{'background-image': 'url('+ avatar +')'}"></ion-avatar>
    <h2>{{ name | firstName }}</h2>
    <p>{{ bio }}</p>
  </div>
</ion-content>
