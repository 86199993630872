import { createSelector } from '@ngrx/store';

import * as subscriptionReducer from '../reducers/subscription.reducer';
import { getSubscriptionState } from '../../state.reducer';

export const isLoadingStripePlans = createSelector(
  getSubscriptionState,
  subscriptionReducer.loadingStripePlans
);

export const getCouponCode = createSelector(
  getSubscriptionState,
  subscriptionReducer.couponCode
);
