import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-accordion-gears',
  styleUrls: ['accordeon-gears.component.scss'],
  template: `
    <ion-list lines="none">
      <div class="accordeon-gears-item" *ngFor="let item of items" class="button-item">
        <button class="accordeon-gears-button">
          <div class="title">
            <span class="title-text">{{item.name | translate}}</span>
          </div>
        </button>

        <div class="button-text">
          <span [innerHTML]="item.description"></span>

          <ion-row class="practice-actions" *ngIf="item.action">
            <ion-col size="6" offset="3" class="ion-text-center" auto>
              <cl-prev-button
                label="{{ item.action.name | translate }}"
                [canClick]="true"
                (action)="onAction(item.action.code)">
              </cl-prev-button>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </ion-list>
  `
})
export class AccordionGearsComponent {

  @Input() items;
  @Output() action = new EventEmitter();

  expandItem(item) {
    this.items.map((listItem) => {
      listItem.expanded = item === listItem ? !listItem.expanded : false;

      return listItem;
    });
  }

  onAction(action) {
    this.action.emit(action);
  }

}
