import { ListItem } from '../../../../store/normalized/schemas/list-item.schema';
import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'cl-pick-sensation',
  styleUrls: ['../../wizard.scss', 'pick-sensation.component.scss'],
  template: `
    <h2 class="step-intro intro-question" [class.lesson]="fromLesson">
      {{ 'wizards.what_sensation_you_feel_most' | translate }}
    </h2>
    <p class="ion-text-left">
      {{ 'wizards.pick_sensation_you_feel_most' | translate }}
    </p>
    <cl-list-single-select
      [items]="sensations"
      (selectItem)="onSelect($event)"
      [selected]="selected">
    </cl-list-single-select>
  `
})

export class PickSensationComponent {
  @Input() sensations: ListItem[];
  @Input() selected: any;
  @Input() fromLesson = false;

  @Output() selectItem = new EventEmitter();

  onSelect(event) {
    this.selectItem.emit(event);
  }
}
