import {
  Component
} from '@angular/core';
import { NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { State } from 'src/app/store';
import { CloseAllModals, CloseModal } from 'src/app/store/session/actions/navigation.actions';

@Component({
  selector: 'page-my-coach-bio',
  styleUrls: ['my-coach-bio.scss'],
  templateUrl: 'my-coach-bio.html'
})
export class MyCoachBioPage {
  private modal;

  public avatar = this.navParams.get('avatar');
  public name = this.navParams.get('name');
  public bio = this.navParams.get('bio');

  constructor(
    public config: ClarityConfig,
    private store: Store<State>,
    private navParams: NavParams
  ) {}

  onClose() {
    this.store.dispatch(new CloseModal({ modalId: this.modal.id }));
  }
}
