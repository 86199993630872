import { Action } from '@ngrx/store';
import { Conversation, ConversationFeed } from '../../normalized/schemas/my-coach.schema';

export const LOAD_ALL_MESSAGES = '[my-coach] Load All Messages';
export const LOAD_ALL_MESSAGES_SUCCESS = '[my-coach] Load All Messages Success';
export const LOAD_ALL_MESSAGES_FAIL = '[my-coach] Load All Messages Fail';

export const LOAD_MESSAGES = '[my-coach] Load Messages';
export const LOAD_MESSAGES_SILENT = '[my-coach] Load Messages Silent';
export const LOAD_MESSAGES_SUCCESS = '[my-coach] Load Messages Success';
export const LOAD_MESSAGES_FAIL = '[my-coach] Load Messages Fail';

export const LOAD_UNREAD_MESSAGES_COUNT = '[my-coach] Load Unread Messages Count';
export const LOAD_UNREAD_MESSAGES_COUNT_SUCCESS = '[my-coach] Load Unread Messages Count Success';
export const LOAD_UNREAD_MESSAGES_COUNT_FAIL = '[my-coach] Load Unread Messages Count Fail';

export const LOAD_NOTIFICATIONS = '[my-coach] Load 10 Last Notifications';
export const LOAD_NOTIFICATIONS_SUCCESS = '[my-coach] Load 10 Last Notifications Success';
export const LOAD_NOTIFICATIONS_FAILURE = '[my-coach] Load 10 Last Notifications Failure';

export const LOAD_ALL_NOTIFICATIONS = '[my-coach] Load All Notifications';
export const LOAD_ALL_NOTIFICATIONS_SUCCESS = '[my-coach] Load All Notifications Success';
export const LOAD_ALL_NOTIFICATIONS_FAILURE = '[my-coach] Load All Notifications Failure';

export const POST_MESSAGE = '[my-coach] Post Message';
export const POST_MESSAGE_SUCCESS = '[my-coach] Post Message Success';
export const POST_MESSAGE_FAIL = '[my-coach] Post Message Fail';

export const DELETE_MESSAGE = '[my-coach] Delete Message';
export const DELETE_MESSAGE_SUCCESS = '[my-coach] Delete Message Success';
export const DELETE_MESSAGE_FAIL = '[my-coach] Delete Message Fail';

export const REFRESH_CONVERSATIONS = '[my-coach] Refresh Conversations';
export const LOAD_CONVERSATIONS = '[my-coach] Load Conversations';
export const LOAD_CONVERSATIONS_SILENT = '[my-coach] Load Conversations Silent';
export const LOAD_CONVERSATIONS_SUCCESS = '[my-coach] Load Conversations Success';
export const LOAD_CONVERSATIONS_FAIL = '[my-coach] Load Conversations Fail';

export const LOAD_CONVERSATION_BY_ID = '[my-coach] Load Conversation By Id';
export const LOAD_CONVERSATION_BY_ID_SUCCESS = '[my-coach] Load Conversation By Id Success';

export const DELETE_CONVERSATION = '[my-coach] Delete Conversation By Id';
export const DELETE_CONVERSATION_FAIL = '[my-coach] Delete Conversation By Id Fail';
export const DELETE_CONVERSATION_SUCCESS = '[my-coach] Delete Conversation By Id Success';

export const DELETE_COACH_MESSAGE = '[my-coach] Delete Coach Message By Id';
export const DELETE_COACH_MESSAGE_FAIL = '[my-coach] Delete Coach Message By Id Fail';
export const DELETE_COACH_MESSAGE_SUCCESS = '[my-coach] Delete Coach Message By Id Success';

export const CLOSE_CONVERSATION = '[my-coach] Close Conversation By Id';
export const CLOSE_CONVERSATION_FAIL = '[my-coach] Close Conversation By Id Fail';
export const CLOSE_CONVERSATION_SUCCESS = '[my-coach] Close Conversation By Id Success';

export const RESET_CONVERSATION_SUPPRESSION = '[my-coach] Reset Conversation Suppression';
export const RESET_CONVERSATION_CREATION = '[my-coach] Reset Conversation Creation';
export const RESET_CONVERSATION_MARK_AS_READ = '[my-coach] Reset Mark Conversation As Read';

export const CREATE_CONVERSATION = '[my-coach] Create Conversation';
export const CREATE_CONVERSATION_SUCCESS = '[my-coach] Create Conversation Success';
export const CREATE_CONVERSATION_FAIL = '[my-coach] Create Conversation Fail';

export const MARK_CONVERSATION_AS_READ = '[my-coach] Mark Conversation As Read';
export const MARK_CONVERSATION_AS_READ_SUCCESS = '[my-coach] Mark Conversation As Read Success';
export const MARK_CONVERSATION_AS_READ_FAIL = '[my-coach] Mark Conversation As Read Fail';

export const NEW_CONVERSATION_ID = '[my-coach] Set new Conversation Id';
export const RESET_NEW_CONVERSATION_ID = '[my-coach] Reset new Conversation Id';

export const NEW_CONVERSATION = '[my-coach] New Conversation';

export const NOTIFY_NEW_MESSAGE = '[my-coach] Notify New Message';
export const CLOSE_CONVERSATION_MODAL = '[my-coach] Close Conversation Modal';

export const CHANGE_CONVERSATIONS_FILTER = '[my-coach] Change Conversations Filter';

export const OPEN_USER_PROFILE_DETAILS = '[account] Get User Profile Details';
export const OPEN_USER_PROFILE_DETAILS_FAIL = '[account] Fail Get User Profile Details';

export class OpenUserProfileDetails implements Action {
  readonly type = OPEN_USER_PROFILE_DETAILS;

  constructor(public payload: string) {}
}

export class OpenUserProfileFail implements Action {
  readonly type = OPEN_USER_PROFILE_DETAILS_FAIL;

  constructor(public payload: any) {}
}

export class CloseConversationModal implements Action {
  readonly type = CLOSE_CONVERSATION_MODAL;
}

export class NotifyNewMessage implements Action {
  readonly type = NOTIFY_NEW_MESSAGE;

  constructor(public payload: any) {}
}

export class DeleteMessage implements Action {
  readonly type = DELETE_MESSAGE;
  constructor(public payload: string) {}
}

export class DeleteMessageFail implements Action {
  readonly type = DELETE_MESSAGE_FAIL;
  constructor(public payload: any) {}
}

export class DeleteMessageSuccess implements Action {
  readonly type = DELETE_MESSAGE_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadAllMessages implements Action {
  readonly type = LOAD_ALL_MESSAGES;
  constructor(public payload: string) {}
}

export class LoadAllMessagesSuccess implements Action {
  readonly type = LOAD_ALL_MESSAGES_SUCCESS;
  constructor(public payload: string) {}
}

export class LoadAllMessagesFail implements Action {
  readonly type = LOAD_ALL_MESSAGES_FAIL;
  constructor(public payload: string) {}
}

export class LoadMessages implements Action {
  readonly type = LOAD_MESSAGES;
  constructor(public payload: string) {}
}

export class LoadMessagesSilent implements Action {
  readonly type = LOAD_MESSAGES_SILENT;
  constructor(public payload: string) {}
}

export class LoadMessagesSuccess implements Action {
  readonly type = LOAD_MESSAGES_SUCCESS;
  constructor(public payload: number) {}
}

export class LoadMessagesFail implements Action {
  readonly type = LOAD_MESSAGES_FAIL;
  constructor(public payload: string) {}
}

export class LoadUnreadMessagesCount implements Action {
  readonly type = LOAD_UNREAD_MESSAGES_COUNT;
}

export class LoadUnreadMessagesCountSuccess implements Action {
  readonly type = LOAD_UNREAD_MESSAGES_COUNT_SUCCESS;
  constructor(public payload: number) {}
}

export class LoadUnreadMessagesCountFail implements Action {
  readonly type = LOAD_UNREAD_MESSAGES_COUNT_FAIL;
  constructor(public payload: string) {}
}

export class PostMessage implements Action {
  readonly type = POST_MESSAGE;
  constructor(public payload: {content: string; conversation_id: string}) {}
}

export class PostMessageFail implements Action {
  readonly type = POST_MESSAGE_FAIL;
  constructor(public payload: string) {}
}

export class PostMessageSuccess implements Action {
  readonly type = POST_MESSAGE_SUCCESS;
}

export class NewConversation implements Action {
  readonly type = NEW_CONVERSATION;
}

export class NewConversationId implements Action {
  readonly type = NEW_CONVERSATION_ID;
  constructor(public payload: string) {}
}

export class ResetNewConversationId implements Action {
  readonly type = RESET_NEW_CONVERSATION_ID;
}

export class CreateConversation implements Action {
  readonly type = CREATE_CONVERSATION;
  constructor(public payload: string) {}
}

export class CreateConversationSuccess implements Action {
  readonly type = CREATE_CONVERSATION_SUCCESS;
  constructor(public payload: Conversation) {}
}

export class CreateConversationFail implements Action {
  readonly type = CREATE_CONVERSATION_FAIL;
  constructor(public payload: string) {}
}

export class LoadConversations implements Action {
  readonly type = LOAD_CONVERSATIONS;
}

export class LoadConversationsSilent implements Action {
  readonly type = LOAD_CONVERSATIONS_SILENT;
}

export class ResetConversationSuppression implements Action {
  readonly type = RESET_CONVERSATION_SUPPRESSION;
}

export class ResetConversationCreation implements Action {
  readonly type = RESET_CONVERSATION_CREATION;
}

export class ResetMarkConversationAsRead implements Action {
  readonly type = RESET_CONVERSATION_MARK_AS_READ;
}

export class LoadConversationsSuccess implements Action {
  readonly type = LOAD_CONVERSATIONS_SUCCESS;

  constructor(public payload: ConversationFeed) {}
}

export class MarkConversationAsRead implements Action {
  readonly type = MARK_CONVERSATION_AS_READ;
  constructor(public payload: string) {}
}

export class MarkConversationAsReadSuccess implements Action {
  readonly type = MARK_CONVERSATION_AS_READ_SUCCESS;
  constructor(public payload: string) {}
}

export class MarkConversationAsReadFail implements Action {
  readonly type = MARK_CONVERSATION_AS_READ_FAIL;
  constructor(public payload: string) {}
}

export class DeleteConversation implements Action {
  readonly type = DELETE_CONVERSATION;
  constructor(public payload: string) {}
}

export class DeleteConversationFail implements Action {
  readonly type = DELETE_CONVERSATION_FAIL;
  constructor(public payload: any) {}
}

export class DeleteConversationSuccess implements Action {
  readonly type = DELETE_CONVERSATION_SUCCESS;
  constructor(public payload: any) {}
}

export class DeleteCoachMessage implements Action {
  readonly type = DELETE_COACH_MESSAGE;
  constructor(public payload: string) {}
}

export class DeleteCoachMessageFail implements Action {
  readonly type = DELETE_COACH_MESSAGE_FAIL;
  constructor(public payload: any) {}
}

export class DeleteCoachMessageSuccess implements Action {
  readonly type = DELETE_COACH_MESSAGE_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadConversationsFail implements Action {
  readonly type = LOAD_CONVERSATIONS_FAIL;
  constructor(public payload: any) {}
}

export class LoadConversationById implements Action {
  readonly type = LOAD_CONVERSATION_BY_ID;

  constructor(public payload: number) {}
}

export class LoadConversationByIdSuccess implements Action {
  readonly type = LOAD_CONVERSATION_BY_ID_SUCCESS;
}

export class ChangeConversationsFilter implements Action {
  readonly type = CHANGE_CONVERSATIONS_FILTER;

  constructor(public payload: any) {}
}

export class CloseConversation implements Action {
  readonly type = CLOSE_CONVERSATION;
  constructor(public payload: string) {}
}

export class CloseConversationFail implements Action {
  readonly type = CLOSE_CONVERSATION_FAIL;
  constructor(public payload: any) {}
}

export class CloseConversationSuccess implements Action {
  readonly type = CLOSE_CONVERSATION_SUCCESS;
}

export class RefreshConversations implements Action {
  readonly type = REFRESH_CONVERSATIONS;
}

export class LoadMyCoachNotifications implements Action {
  readonly type = LOAD_NOTIFICATIONS;
}

export class LoadMyCoachNotificationsSuccess implements Action {
  readonly type = LOAD_NOTIFICATIONS_SUCCESS;
}

export class LoadMyCoachNotificationsFailure implements Action {
  readonly type = LOAD_NOTIFICATIONS_FAILURE;
}

export class LoadAllMyCoachNotifications implements Action {
  readonly type = LOAD_ALL_NOTIFICATIONS;
}

export class LoadAllMyCoachNotificationsSuccess implements Action {
  readonly type = LOAD_ALL_NOTIFICATIONS_SUCCESS;
}

export class LoadAllMyCoachNotificationsFailure implements Action {
  readonly type = LOAD_ALL_NOTIFICATIONS_FAILURE;
}

export type MyCoachActions =
  CloseConversationModal
  | NotifyNewMessage
  | DeleteMessage
  | DeleteMessageFail
  | DeleteMessageSuccess
  | LoadAllMessages
  | LoadAllMessagesSuccess
  | LoadAllMessagesFail
  | LoadMessages
  | LoadMessagesSilent
  | LoadMessagesSuccess
  | LoadMessagesFail
  | LoadUnreadMessagesCount
  | LoadUnreadMessagesCountSuccess
  | LoadUnreadMessagesCountFail
  | PostMessage
  | PostMessageFail
  | PostMessageSuccess
  | LoadConversations
  | LoadConversationsSilent
  | NewConversationId
  | ResetNewConversationId
  | CreateConversation
  | CreateConversationSuccess
  | CreateConversationFail
  | ResetMarkConversationAsRead
  | ResetConversationCreation
  | ResetConversationSuppression
  | MarkConversationAsRead
  | MarkConversationAsReadSuccess
  | MarkConversationAsReadFail
  | LoadConversationsSuccess
  | LoadConversationsFail
  | LoadConversationById
  | LoadConversationByIdSuccess
  | DeleteConversationSuccess
  | DeleteConversationFail
  | DeleteConversation
  | DeleteCoachMessage
  | DeleteCoachMessageFail
  | DeleteCoachMessageSuccess
  | NewConversation
  | ChangeConversationsFilter
  | CloseConversation
  | CloseConversationSuccess
  | CloseConversationFail
  | RefreshConversations
  | OpenUserProfileDetails
  | OpenUserProfileFail
  | LoadMyCoachNotifications
  | LoadMyCoachNotificationsFailure
  | LoadMyCoachNotificationsSuccess
  | LoadAllMyCoachNotifications
  | LoadAllMyCoachNotificationsSuccess
  | LoadAllMyCoachNotificationsFailure;
