import { AfterViewInit, ElementRef, ViewChild, Component, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Chart } from 'chart.js';

import Color from 'color';
import moment from 'moment';
import { Observable, combineLatest , Subscription } from 'rxjs';
import {
  getCheckinsByDay,
  getCravingToolsByDay,
  getModulesByDay,
  getStressTestByDay,
  getWantOMetersByDay,
  getWorryToolByDay
} from '../store/normalized/selectors/count-by-day.selectors';

import * as navigationActions from '../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { ClarityConfig } from '../config/clarity.config';
import { TranslateService } from '@ngx-translate/core';
import { CountByDay } from '../store/normalized/schemas/count-by-day.schema';
import { getCurrentUserStartDate } from '../store/normalized/selectors/user.selectors';
import { SessionState } from '../store/session/session.reducers';

@Component({
  selector: 'cl-activity-graph',
  styleUrls: ['activity-graph.component.scss'],
  template: `
    <div class="graph-container" [ngClass]="{'with-controls': controls}">
      <div class="help-container">
        <span tappable (click)="showHelp($event)">{{ 'activity_graph.what_is_this' | translate }}</span>
      </div>
      <div [hidden]="hasData" class="graph-overlay">
        {{ 'activity_graph.no_data' | translate }}
      </div>
      <div [hidden]="!chart" style="width:100%; height:300px;" [ngClass]="{'graph-blurred': !hasData}">
          <canvas #AG class="canvas-style">{{chart}}</canvas>
      </div>

      <div *ngIf="controls" class="controls-wrapper">
        <ion-row class="control-buttons-row">
          <ion-col class="ion-text-right">
            <ion-button size="small" expand="block" color="action"
              [disabled]="!hasPreviousWeek"
              (click)="toPreviousWeek()">
              <ion-icon name='arrow-back'></ion-icon>
              {{'activity_graph.previous' | translate | uppercase}}
            </ion-button>
          </ion-col>
          <ion-col class="ion-text-left">
            <ion-button size="small" expand="block" color="action"
              [disabled]="!hasNextWeek"
              (click)="toNextWeek()">
              {{'activity_graph.next' | translate | uppercase}}
              <ion-icon name='arrow-forward'></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </div>
  `
})

export class ActivityGraphComponent implements AfterViewInit, OnDestroy {

  @Input() weekDayFormat = 'MM/DD';
  @Input() legendPosition = 'bottom';
  @Input() legendLabelPadding = '14';

  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  get hasData() {
    return this.checkins.length > 0 ||
      this.stressTests.length > 0 ||
      this.stressMeters.length > 0 ||
      this.modules.length > 0;
  }

  get hasNextWeek() {
    const currentToday = this.today.clone();
    const realToday = moment()
      .startOf('day');
    if (currentToday.add(1, 'week') > realToday) {
      return false;
    }

    return true;
  }

  get hasPreviousWeek() {
    const currentToday = this.today.clone();
    const startDateClone = this.startDate.clone();
    if (currentToday.subtract(1, 'week') < startDateClone.subtract(1, 'week')) {
      return false;
    }

    return true;
  }
  chart: Chart = null;

  ctx: any = null;
  checkins: CountByDay[] = [];
  stressTests: CountByDay[] = [];
  stressMeters: CountByDay[] = [];
  modules: CountByDay[] = [];
  cravingTools: CountByDay[] = [];
  worryTool: CountByDay[] = [];

  startDate: moment.Moment = moment('2010-01-01')
    .startOf('day');

  today: moment.Moment = moment()
    .startOf('day');
  dataSubscription: Subscription = undefined;
  translationSubscription: Subscription = undefined;

  @Input() controls = false;
  private checkinsByDays$: Observable<CountByDay[]> = this.store.select(getCheckinsByDay);
  private stressTestsByDay$: Observable<CountByDay[]> = this.store.select(getStressTestByDay);
  private wantOMetersByDay$: Observable<CountByDay[]> = this.store.select(getWantOMetersByDay);
  private cravingToolsByDay$: Observable<CountByDay[]> = this.store.select(getCravingToolsByDay);
  private worryToolByDay$: Observable<CountByDay[]> = this.store.select(getWorryToolByDay);
  private modulesByDay$: Observable<CountByDay[]> = this.store.select(getModulesByDay);
  private initialDay$ = this.store.select(getCurrentUserStartDate);

  colorForDataSet = {
    checkins: '#fda533',
    stresstests: '#5bd3bd',
    stressmeters: '#dca4d9',
    cravingtools: '#fb6c62',
    lessons: 'rgb(146, 220, 246)',
    modules: '#3c9de0',
    worrytool: '#f44336c7'
  };

  // TODO: Translate!
  chartLabelsNoTrans = {
    modules: 'chart.modules',
    checkins: 'chart.checkins',
    stresstests: 'chart.stresstests',
    stressmeters: this.stressMeterLabel(),
    cravingtools: this.cravingToolLabel(),
    worrytool: 'chart.worrytool'
  };

  chartLabels = {};

  @ViewChild('AG', { static: true }) chartElement: ElementRef;

  showHelp($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.store.dispatch(new navigationActions.ShowOnboarding({
      page: 'OnboardingPage',
      params: {
        type: 'activityGraphWhatIsThis'
      }
    }));
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  randomValue() {
    return this.getRandomInt(7);
  }

  transparentize(color, opacity) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;

    return Color(color)
      .alpha(alpha)
      .rgb()
      .string();
  }

  toPreviousWeek() {
    this.today.subtract(1, 'week');
    this.updateData();
  }

  toNextWeek() {
    if (this.hasNextWeek) {
      this.today.add(1, 'week');
      this.updateData();
    }
  }

  cravingToolLabel() {
    if (this.config.isERN()) {
      return 'chart.craving_tools';
    }

    if (this.config.isCTQ()) {
      return 'chart.want_o_meters';
    }

    return 'none';
  }

  stressMeterLabel() {
    if (this.config.isERN()) {
      return 'chart.want_o_meters';
    }

    if (this.config.isUA()) {
      return 'chart.stressmeters';
    }

    if (this.config.isCTQ()) {
      return 'chart.want_o_meters';
    }
  }

  gradientBackgroundGenerator(color) {
    return Color(color)
      .alpha(0.6)
      .rgb()
      .string();
  }

  generateLabels() {
    const today = this.today.clone()
      .subtract(6, 'days');
    const labels = [''];
    for (let i = 0; i < 7; i++) {
      labels.push(today.format(this.weekDayFormat));
      today.add(1, 'days');
    }
    labels.push('');

    return labels;
  }

  generateLabelsColor(chart) {
    chart.legend.afterFit = () => {
      if (this.legendPosition === 'bottom' && chart.legend.lineWidths) {
        chart.legend.lineWidths = chart.legend.lineWidths.map(() => chart.width - (chart.width * .15));
      }
    };

    const data = chart.data;
    if (data.labels.length && data.datasets.length) {
      return data.datasets.map((dataset, i) => ({
        text: dataset.label,
        lineWidth: 0,
        fillStyle: dataset.pointBackgroundColor,
        datasetIndex: i
      }));
    }

    return [];
  }

  getDataSets(options = { empty: false }) {
    const { empty } = options;

    const dataSets = [
      this.getDataSet('modules', empty ? [] : this.modules, true),
      this.getDataSet('checkins', empty ? [] : this.checkins)
    ];

    if (this.config.isCTQ()) {
      dataSets.push(this.getDataSet('cravingtools', empty ? [] : this.cravingTools));
    } else {
      dataSets.push(this.getDataSet('stressmeters', empty ? [] : this.stressMeters));
    }

    if (this.config.isERN()) {
      dataSets.push(this.getDataSet('stresstests', empty ? [] : this.stressTests));
      dataSets.push(this.getDataSet('cravingtools', empty ? [] : this.cravingTools));
    }

    if (this.config.isUA()) {
      dataSets.push(this.getDataSet('stresstests', empty ? [] : this.stressTests));
      dataSets.push(this.getDataSet('worrytool', empty ? [] : this.worryTool));
    }

    return dataSets;
  }

  getDataSet(key, data, fillZero = false) {
    const color = this.colorForDataSet[key];

    const fillOption = fillZero ? { fill: 'zero' } : {};

    return {
      ...fillOption,
      data: this.getData(key, data),
      label: this.chartLabels[key],
      backgroundColor: this.gradientBackgroundGenerator(color),
      pointBackgroundColor: color,
      borderColor: this.transparentize(color, 1),
      pointRadius: 0,
      borderWidth: 0
    };
  }

  getData(key, vanillaKeyData= []) {
    const keyData = vanillaKeyData.map((dataPoint: CountByDay) => {
      const { date, count } = dataPoint;
      const response = {};

      response[date] = count;

      return response;
    });
    const dataDict = {};
    keyData.forEach(d => Object.assign(dataDict, d));
    const DATE_FORMAT = 'YYYY-MM-DD';
    const today = this.today.clone()
      .subtract(7, 'days');
    const data = [];
    for (let i = 0; i < 8; i++) {
      const date_key = today.format(DATE_FORMAT);
      let value = dataDict[date_key];
      if (!value) {
        value = 0;
      }
      data.push(+value);
      today.add(1, 'days');
    }
    // data.reverse();
    data.push(0);

    return data;
  }

  updateData(data?: CountByDay[][]) {
    if (!this.ctx || !this.chart) {
      return;
    }
    if (data) {
      const [checkins, stressTests, stressMeters, modules, cravingTools, worryTool] = data;
      this.checkins = checkins || this.checkins;
      this.stressTests = stressTests || this.stressTests;
      this.stressMeters = stressMeters || this.stressMeters;
      this.modules = modules || this.modules;
      this.cravingTools = cravingTools || this.cravingTools;
      this.worryTool = worryTool || this.worryTool;
    }
    this.chart.data.labels = this.generateLabels();
    this.chart.data.datasets = this.getDataSets();
    this.chart.update();
    this.changeDetector.detectChanges();
  }

  getFreshData() {
    this.dataSubscription = combineLatest([
      this.checkinsByDays$,
      this.stressTestsByDay$,
      this.wantOMetersByDay$,
      this.modulesByDay$,
      this.cravingToolsByDay$,
      this.worryToolByDay$
    ])
      .pipe(distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)))
      .subscribe((data) => {
        this.updateData(data);
      });
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
  }

  signToTranslations() {
    this.translationSubscription = this.translate.onLangChange
      .subscribe(() => {
        this.translateLabels()
          .then(() => this.updateData());
      });
  }

  translateLabels() {
    return Promise.all(
      Object.keys(this.chartLabelsNoTrans)
        .map(key => {
          if (!this.chartLabelsNoTrans[key]) {
            return;
          }
          this.translate.get(this.chartLabelsNoTrans[key])
            .toPromise()
            .then((translation) => {
              this.chartLabels[key] = translation;
            });
        })
    );
  }

  ngAfterViewInit() {
    const labels = this.generateLabels();
    this.initialDay$
      .pipe(
        take(1)
      )
      .subscribe((startDate) => {
        this.startDate = moment(startDate)
          .startOf('day');
      });
    this.ctx = this.chartElement.nativeElement.getContext('2d');
    const config = {
      type: 'line',
      data: {
        labels,
        datasets: this.getDataSets({ empty: true })
      },
      options: {
        elements: {
          line: {
            fill: '-1'
          }
        },
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: this.legendPosition,
          labels: {
            defaultFontFamily: 'Source Sans Pro',
            padding: parseInt(this.legendLabelPadding, 10),
            usePointStyle: true,
            generateLabels: this.generateLabelsColor
          }
        },
        responsive: true,
        title: {
          display: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              offsetGridLines: true
            },
            scaleLabel: {
              display: false
            }
          }],
          yAxes: [{
            // display: false,
            stacked: true,
            gridLines: {
              drawBorder: false
            },
            ticks: {
              beginAtZero: true,
              callback(value) { if (Number.isInteger(value)) { return value; } },
              stepSize: 1
            },
            scaleLabel: {
              display: false,
              labelString: 'Value'
            }
          }]
        }
      }
    };

    this.signToTranslations();
    this.translateLabels()
      .then(() => {
        this.chart = new Chart(this.ctx, config);
        this.getFreshData();
      });

  }
}
