import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'page-interstitial',
  templateUrl: 'interstitial.html'
})
export class InterstitialPage {
  private customInterstitialComponentTypes = new Set([
    'locked',
    'locked.module_in_progress_missing_weight_capture',
    'locked.dpp_module_not_yet_available',
    'lesson-not-supported'
  ]);

  interstitialType = this.navParams.get('type');
  interstitialComponentType = this.customInterstitialComponentTypes.has(this.interstitialType) ? this.interstitialType : 'default';

  constructor(
    public navParams: NavParams
  ) {
  }
}
