import { Action } from '@ngrx/store';
import { WeightScaleOrder } from 'src/app/providers/health-devices.provider';

export const ORDER_WEIGHT_SCALE = '[health devices] Order Weight Scale';
export const ORDER_WEIGHT_SCALE_SUCCESS = '[health devices] Order Weight Scale Success';
export const ORDER_WEIGHT_SCALE_FAIL = '[health devices] Order Weight Scale Fail';

export const RESET_FETCH_WEIGHT_SCALE_ORDERS = '[health devices] Reset Fetch Weight Scale Orders';
export const FETCH_WEIGHT_SCALE_ORDERS = '[health devices] Fetch Weight Scale Orders';
export const FETCH_WEIGHT_SCALE_ORDERS_COMPLETE = '[health devices] Fetch Weight Scale Orders Complete';

export class OrderWeightScale implements Action {
  readonly type = ORDER_WEIGHT_SCALE;

  constructor(public payload: WeightScaleOrder) {}
}

export class OrderWeightScaleSuccess implements Action {
  readonly type = ORDER_WEIGHT_SCALE_SUCCESS;

  constructor(public payload: WeightScaleOrder) {}
}

export class OrderWeightScaleFail implements Action {
  readonly type = ORDER_WEIGHT_SCALE_FAIL;

  constructor(public error: any) {}
}

export class ResetFetchWeightScaleOrders implements Action {
  readonly type = RESET_FETCH_WEIGHT_SCALE_ORDERS;

  constructor() {}
}

export class FetchWeightScaleOrders implements Action {
  readonly type = FETCH_WEIGHT_SCALE_ORDERS;

  constructor() {}
}
export class FetchWeightScaleOrdersComplete implements Action {
  readonly type = FETCH_WEIGHT_SCALE_ORDERS_COMPLETE;

  constructor(public payload: WeightScaleOrder[]) {}
}

export type HealthDevicesActions =
  | OrderWeightScale
  | OrderWeightScaleSuccess
  | OrderWeightScaleFail
  | ResetFetchWeightScaleOrders
  | FetchWeightScaleOrders
  | FetchWeightScaleOrdersComplete;
