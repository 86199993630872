import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: 'ion-textarea[clAutogrow]' // Attribute selector
})
export class AutogrowDirective implements OnInit {

  constructor(public element: ElementRef) {
  }

  @Input() growMax;

  @HostListener('input')
  onInput(): void {
    this.adjust();
  }

  ngOnInit(): void {
    this.adjust();
  }

  adjust(): void {
    const ta = this.element.nativeElement.querySelector('textarea');

    if (ta) {
      ta.style.height = 'auto';
      ta.style.height = ta.scrollHeight + 'px';

      if (this.growMax) {
        ta.style.maxHeight = this.growMax;
      }
    }
  }

}
