import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { UserProgramInfo } from '../store/normalized/schemas/user.schema';
import { Observable } from 'rxjs';
import { OfflineProvider } from './offline.provider';
import { ConnectionHandlerProvider } from './http/connection-handler.provider';

@Injectable({providedIn: 'root'})
export class UserProgramInfoProvider extends OfflineProvider {

  private userProgramEndpoint = '/user_program_info';

  constructor(
    protected connectionHandler: ConnectionHandlerProvider,
    protected http: HttpProvider
  ) {
    super(connectionHandler, http);
  }

  updateUserProgramInfo(payload: UserProgramInfo) {
    return this.http.patch(this.userProgramEndpoint, payload);
  }

  getUserProgramInfo(): Observable<UserProgramInfo> {
    return this.http.get<UserProgramInfo>(this.userProgramEndpoint);
  }

}
