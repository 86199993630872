import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { LiveWeek } from '../../store/session/selectors/program.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-journey-week-card',
  styleUrls: ['journey-week-card.component.scss'],
  template: `
    <div class="card-wrapper"
         [ngClass]="{'current': isCurrent, 'completed': isCompleted, 'locked': isLocked, 'expanded': isExpanded}"
         (click)="onClick()" tappable>
      <ion-grid>
        <ion-row>
          <ion-col class="week-name ion-text-left" size="10">{{'common.week' | translate}} {{week.number}} -
            {{getWeekName() | translate}}
          </ion-col>
          <ion-col class="week-caret ion-text-right" size="2">
            <ion-icon name="arrow-forward" *ngIf="!isExpanded && showExpand"></ion-icon>
            <ion-icon name="arrow-down" *ngIf="isExpanded && showExpand"></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="modules-holder">
            <cl-module-item
              *ngFor="let index of moduleNumbers"
              [isCustomPath]="isCustomPath"
              [module]="week.modules[index] || null"
              [selectedModules]="selectedModules"
              [expanded]="isExpanded"
              (clicked)="onModuleClicked($event)">
            </cl-module-item>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!--<div class="card-overlay" *ngIf="isLocked"></div>-->
    </div>
  `
})
export class JourneyWeekCardComponent {
  @Input() week: LiveWeek;
  @Input() expanded = null;
  @Input() isCustomPath = false;
  @Input() selectedModules = [];

  @Output() playModule = new EventEmitter();
  @Output() weekExpanded = new EventEmitter();
  @Output() weekCollpased = new EventEmitter();

  get isExpanded() {
    if (this.isCustomPath) {
      return true;
    }

    return this.expanded === null
      ? this.week.isCurrent && !this.week.isCompleted
      : this.expanded;
  }

  get showExpand() {
    return !this.isCustomPath;
  }

  get isLocked() {
    if (this.isCustomPath) {
      return false;
    }

    return !this.week.isCompleted && !this.week.isCurrent ? true : false;
  }

  get isCurrent() {
    if (this.isCustomPath) {
      return false;
    }

    return this.week.isCurrent && !this.week.isCompleted;
  }

  get isCompleted() {
    if (this.isCustomPath) {
      return true;
    }

    return this.week.isCompleted;
  }

  get moduleNumbers() {
    return this.week.modules.length > 7
      ? this.week.modules.map((module, index) => index)
      : [0, 1, 2, 3, 4, 5, 6];
  }

  constructor() {}

  getWeekName() {
    switch (this.week.type) {
      case 'program':
        return 'myjourney.core_training';

      case 'custom':
        return 'myjourney.custom_week';

      case 'theme':
        return this.week.record && this.week.record.title;
    }
  }

  onClick() {
    this.expanded = !this.expanded;

    if (this.expanded) {
      this.weekExpanded.emit(this.week.number);
    }
    else {
      this.weekCollpased.emit(this.week.number);
    }
  }

  onModuleClicked(module) {
    this.playModule.emit(module);
  }
}
