// persistent flags reducer

import * as flagActions from './flags.actions';

export type AllowedFlags =
  'welcomeTourViewed' |
  'firstAppLaunchCompleted' |
  'firstLoginCompleted' |
  'ratingRejected' |
  'ratingCompleted' |
  'writeAccessCheckCompleted' |
  'requestedPermissions' |
  'worryToolViewed';

export type FlagsState = Record<AllowedFlags, boolean>;

export const initialPersistentFlagsState: FlagsState = {
  welcomeTourViewed: null,
  firstAppLaunchCompleted: true,
  firstLoginCompleted: false,
  ratingRejected: false,
  ratingCompleted: false,
  writeAccessCheckCompleted: false,
  requestedPermissions: false,
  worryToolViewed: false
};

export function persistentFlagsReducer(state = initialPersistentFlagsState, action: flagActions.FlagActions): FlagsState {

  switch (action.type) {
    case flagActions.SET_FLAG: {
      const flag = action.payload;

      return {
        ...state,
        [flag]: true
      };
    }

    case flagActions.UNSET_FLAG: {
      const flag = action.payload;

      return {
        ...state,
        [flag]: false
      };
    }
  }

  return state;
}

// TODO: Do we need individual selectors?
export const welcomeTourViewed = (state: FlagsState) => state.welcomeTourViewed;
export const firstLoginCompleted = (state: FlagsState) => state.firstLoginCompleted;
export const ratingRejected = (state: FlagsState) => state.ratingRejected;
export const ratingCompleted = (state: FlagsState) => state.ratingCompleted;
export const writeAccessCheckCompleted = (state: FlagsState) => state.writeAccessCheckCompleted;
export const requestedPermissions = (state: FlagsState) => state.requestedPermissions;
