import { Store } from '@ngrx/store';
import {
  ConnectionHandlerSeqActionsStart,
  ConnectionHandlerNonSeqActionsStart
} from '../../store/session/actions/connection-handler.actions';
import { Injectable } from '@angular/core';
import { ConnectivityService } from '../../services/connectivity.service';
import { SessionState } from '../../store/session/session.reducers';

@Injectable({providedIn: 'root'})
export class ConnectionHandlerService {
  private interval: ReturnType<typeof setInterval>;
  private trying: boolean;
  readonly timeBetweenTries = 10 * 1000;
  constructor(
    private store: Store<SessionState>,
    private connectivityService: ConnectivityService
  ) {
    this.trying = false;
    this.tryQueueInner = this.tryQueueInner.bind(this);
    this.tryQueue = this.tryQueue.bind(this);
  }

  resetQueueTimer() {
    this.clearQueueTimer();
    this.interval = setInterval(() => {
      this.tryQueueInner();
    }, this.timeBetweenTries);
  }

  tryQueue() {
    if (this.connectivityService.isOffline()) {
      return;
    }

    this.tryQueueInner();

    // DEPRECATED - we just need to run it once to clear data in user's store in case there's still stuff in there
    // this.resetQueueTimer();
  }

  tryQueueInner() {
    if (this.trying) {
      return;
    }
    this.trying = true;
    this.store.dispatch(new ConnectionHandlerSeqActionsStart());
    this.store.dispatch(new ConnectionHandlerNonSeqActionsStart());
  }

  clearQueueTimer() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  stopTry({ retry }) {
    this.trying = false;
    if (!retry) {
      // we dont want to reschedule tries (eg: the queue was cleared)
      this.clearQueueTimer();
    }
  }
}
