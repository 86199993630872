import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'cl-badge',
  styleUrls: ['badge.component.scss'],
  template: '<ng-content></ng-content>'
})

export class BadgeComponent {
  @Input()
  color: 'warning' | 'danger';

  @HostBinding('class.warning')
  get isWarning() {
    return this.color === 'warning';
  }

  @HostBinding('class.danger')
  get isDanger() {
    return this.color === 'danger';
  }
}
