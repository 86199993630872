import * as subscriptionActions from '../actions/subscription.actions';

export interface SubscriptionState {
  loadingStripePlans: boolean;
  couponCode: string;
}

export const initialSubscriptionState: SubscriptionState = {
  loadingStripePlans: false,
  couponCode: null
};

export function subscriptionReducer(
  state = initialSubscriptionState,
  action: subscriptionActions.SubscriptionActions
): SubscriptionState {
  switch (action.type) {
    case subscriptionActions.LOAD_STRIPE_PLANS: {
      return {
        ...state,
        loadingStripePlans: true
      };
    }

    case subscriptionActions.LOAD_STRIPE_PLANS_SUCCESS: {
      return {
        ...state,
        loadingStripePlans: false
      };
    }

    case subscriptionActions.LOAD_STRIPE_PLANS_FAIL: {
      return {
        ...state,
        loadingStripePlans: false
      };
    }

    case subscriptionActions.RESET_PROGRAM_CODE: {
      return {
        ...state,
        couponCode: null
      };
    }

    case subscriptionActions.APPLY_COUPON_CODE: {
      const code = action.payload;

      return {
        ...state,
        couponCode: code
      };
    }
  }

  return state;
}

export const loadingStripePlans = (state: SubscriptionState) => state.loadingStripePlans;
export const couponCode = (state: SubscriptionState) => state.couponCode;
