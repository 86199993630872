import { IAPProduct } from '@ionic-native/in-app-purchase-2';
import { getCurrencyLabel } from '../store/normalized/selectors/stripe-plans.selectors';

export enum SubscriptionType {
  MONTHLY = 'monthly',
  BIANNUAL = '6months',
  ANNUAL = 'annual',
  LIFETIME = 'lifetime'
}

/**
 * Get a product type for itunes and play store items
 *
 * @param productId IAPProduct.id
 * @returns
 */
export function getProductType(productId: string) {
  const id = productId.toLowerCase();

  if (/monthly/.test(id) || /1month/.test(id)) {
    return SubscriptionType.MONTHLY;
  }
  else if (/6months/.test(id) || /6month/.test(id)) {
    return SubscriptionType.BIANNUAL;
  }
  else if (/annual/.test(id) || /1year/.test(id)) {
    return SubscriptionType.ANNUAL;
  }
  else if (/life/.test(id)) {
    return SubscriptionType.LIFETIME;
  }
  else {
    return null;
  }
}

export type WeeklyPriceRoundDirection = 'up' | 'down';
export interface GeneralWeeklyPrice {
  price: number;
  rounded?: WeeklyPriceRoundDirection;
};

const pricePerWeekUSDCalculator = (priceInCents: number, subscriptionType: SubscriptionType) => {
  let weeklyDollars: number;
  if (subscriptionType === SubscriptionType.BIANNUAL) {
    weeklyDollars = priceInCents / 100 * 2 / 52;
  } else if (subscriptionType === SubscriptionType.ANNUAL) {
    weeklyDollars = priceInCents / 100 / 52;
  } else if (subscriptionType === SubscriptionType.MONTHLY) {
    weeklyDollars = priceInCents / 100 * 12 / 52;
  }
  const roundedHalfDollars = Math.round(weeklyDollars * 2) / 2;

  let roundDirection: WeeklyPriceRoundDirection = 'up';
  if (roundedHalfDollars < weeklyDollars) {
    roundDirection = 'down';
  }

  return { price: roundedHalfDollars, rounded: roundDirection };
};

export function getGeneralPricePerWeek(
  currency: string, subscriptionType: SubscriptionType, priceInCents: number
): GeneralWeeklyPrice {
  if (currency.toUpperCase() === 'USD') {
    switch (subscriptionType) {
      case SubscriptionType.LIFETIME:
        return null;

      case SubscriptionType.BIANNUAL:
        return pricePerWeekUSDCalculator(priceInCents, SubscriptionType.BIANNUAL);

      case SubscriptionType.MONTHLY:
        return pricePerWeekUSDCalculator(priceInCents, SubscriptionType.MONTHLY);

      case SubscriptionType.ANNUAL:
        return pricePerWeekUSDCalculator(priceInCents, SubscriptionType.ANNUAL);
    }
  }

  switch (subscriptionType) {
    case SubscriptionType.LIFETIME:
      return null;

    case SubscriptionType.BIANNUAL:
      return { price: Math.ceil(priceInCents / 100 * 2 / 52) };

    case SubscriptionType.MONTHLY:
      return { price: Math.ceil(priceInCents / 100 * 12 / 52) };

    case SubscriptionType.ANNUAL:
      return { price: Math.ceil(priceInCents / 100 / 52) };
  }

}

export function getInAppPricePerWeek(appStoreProduct: IAPProduct) {
  const subscriptionType = getProductType(appStoreProduct.id);
  const priceInCents = Number(appStoreProduct.priceMicros) / 10000;
  const pricePerWeek = getGeneralPricePerWeek(appStoreProduct.currency, subscriptionType, priceInCents);

  if (!pricePerWeek) {
    return null;
  }

  return `${getCurrencyLabel(appStoreProduct.currency.toUpperCase())}${pricePerWeek.price}`;
}

export function getInAppPricePerWeekRounded(appStoreProduct: IAPProduct) {
  const subscriptionType = getProductType(appStoreProduct.id);
  const priceInCents = Number(appStoreProduct.priceMicros) / 10000;
  const pricePerWeek = getGeneralPricePerWeek(appStoreProduct.currency, subscriptionType, priceInCents);

  if (!pricePerWeek) {
    return null;
  }

  return pricePerWeek?.rounded;
}

export function getInAppSavePercent(appStoreProduct: IAPProduct, appStoreProducts: IAPProduct[]) {
  const lengthInMonths = appStoreProduct.billingPeriodUnit === 'Year' ? 12 : appStoreProduct.billingPeriod;
  const monthlyProduct = appStoreProducts.map(product => ({
    ...product,
    productType: getProductType(product.id)
  })).find(product => product.productType === SubscriptionType.MONTHLY);

  if (!monthlyProduct) {
    return null;
  }

  return Math.floor(Math.abs(appStoreProduct.priceMicros / (monthlyProduct.priceMicros * lengthInMonths) - 1) * 100);
}
