// download actions

import { Action } from '@ngrx/store';
import { ClarityFileError } from '../../../services/files/file.service';

export const QUEUE_MEDIA_FILES = '[download] Queue Media Files';
export const DOWNLOAD_START = '[download] Start';
export const DOWNLOAD_STOP = '[download] Stop';
export const DOWNLOAD_ENABLE = '[download] Enable';
export const DOWNLOAD_DISABLE = '[download] Disable';
export const DOWNLOAD_MEDIA_FILE = '[download] MediaFile';
export const DOWNLOAD_MEDIA_FILE_FAIL = '[download] MediaFile Fail';
export const DOWNLOAD_MEDIA_FILE_COMPLETE = '[download] MediaFile Complete';
export const DOWNLOAD_SKIP_MEDIA_FILE = '[download] MediaFile Skip';
export const DOWNLOAD_QUEUE_CLEARED = '[download] Queue Cleared';
export const DOWNLOAD_CLEAR_STATE = '[download] State Cleared';
export const REQUEST_PERMISSIONS = '[download] Request Permissions';
export const QUEUE_MODULES = '[download] Queue Modules';
export const GENERATE_QUEUE = '[download] Generate Queue';
export const SET_OFFLINE_STORAGE_STATUS = '[account] Set offline storage status';

export class QueueMediaFiles implements Action {
  readonly type = QUEUE_MEDIA_FILES;

  constructor(public payload: string[]) {
  }
}

export class DownloadStart implements Action {
  readonly type = DOWNLOAD_START;
}

export class DownloadStop implements Action {
  readonly type = DOWNLOAD_STOP;
}

export class DownloadEnable implements Action {
  readonly type = DOWNLOAD_ENABLE;
}

export class DownloadDisable implements Action {
  readonly type = DOWNLOAD_DISABLE;
}

export class DownloadMediaFile implements Action {
  readonly type = DOWNLOAD_MEDIA_FILE;
}

export class DownloadMediaFileFail implements Action {
  readonly type = DOWNLOAD_MEDIA_FILE_FAIL;

  constructor(public payload: ClarityFileError) {
  }
}

export class DownloadMediaFileComplete implements Action {
  readonly type = DOWNLOAD_MEDIA_FILE_COMPLETE;

  constructor(public payload: string) {}
}

export class DownloadSkipMediaFile implements Action {
  readonly type = DOWNLOAD_SKIP_MEDIA_FILE;
}

export class DownloadQueueCleared implements Action {
  readonly type = DOWNLOAD_QUEUE_CLEARED;
}

export class DownloadClearState implements Action {
  readonly type = DOWNLOAD_CLEAR_STATE;
}

export class RequestPermissions implements Action {
  readonly type = REQUEST_PERMISSIONS;
}

export class QueueModules implements Action {
  readonly type = QUEUE_MODULES;

  constructor(public payload: any[]) {
  }
}

export class GenerateQueue implements Action {
  readonly type = GENERATE_QUEUE;
}

export class SetOfflineStorageStatus implements Action {
  readonly type = SET_OFFLINE_STORAGE_STATUS;
  constructor(public payload: boolean) {}
}

export type DownloadActions =
  | QueueMediaFiles
  | DownloadStart
  | DownloadStop
  | DownloadEnable
  | DownloadDisable
  | DownloadMediaFile
  | DownloadMediaFileFail
  | DownloadMediaFileComplete
  | DownloadSkipMediaFile
  | DownloadQueueCleared
  | DownloadClearState
  | RequestPermissions
  | QueueModules
  | GenerateQueue
  | SetOfflineStorageStatus;
