import * as socialActions from '../actions/social.actions';
import { Post, PostFeed } from '../../normalized/schemas/social.schema';

export interface SocialState {
  postsFilter: string;
  postsUserFilter: {
    userId: number;
    username: string;
  };
  postsPage: number;
  getPostLoading: boolean;
  addPostLoading: boolean;
  addPostLoaded: boolean;
  likePostLoading: boolean;
  likePostLoaded: boolean;
  tempPost: {
    content: string;
    private: boolean;
    question: boolean;
    craving: boolean;
    faq: boolean;
  };
  timestamp: string;
  id: string;
  likePostId: string;
  likeFailPost: string;
  postSuppressionId: string;
  postCreation: Post;
  filterChanging: boolean;
  newPostAvailable: number;
  newLikeOnPost: {
    post_id: number;
    avatars: string[];
    likes_count: number;
  };
  newPost: Post;
  queuedPostsOnResume: PostFeed;
  getPostsReloaded: boolean;
}

export const initialRemindersState: SocialState = {
  postsFilter: '',
  postsUserFilter: null,
  postsPage: 1,
  timestamp: null,
  getPostLoading: false,
  addPostLoading: false,
  addPostLoaded: false,
  likePostLoading: false,
  likePostLoaded: false,
  tempPost : {
    content: '',
    private: false,
    question: false,
    craving: false,
    faq: false
  },
  id: null,
  likePostId: null,
  likeFailPost: null,
  postSuppressionId: null,
  postCreation: null,
  filterChanging: false,
  newPostAvailable: null,
  newLikeOnPost: null,
  newPost: null,
  queuedPostsOnResume: null,
  getPostsReloaded: false
};

export function socialReducer(state = initialRemindersState, action: socialActions.SocialActions): SocialState {

  switch (action.type) {

    case socialActions.INIT_ACTION_CABLE: {
      return {
        ...state,
        newLikeOnPost: null,
        newPostAvailable: null,
        newPost: null
      };
    }

    case socialActions.TOGGLE_LIKE_POST_SUCCESS: {
      return {
        ...state,
        likePostLoading: false,
        likePostLoaded: true
      };
    }

    case socialActions.RELOAD_ON_RESUME: {
      return {
        ...state,
        newPostAvailable: null,
        newPost: null,
        queuedPostsOnResume: null,
        getPostsReloaded: false
      };
    }

    case socialActions.RELOAD_POSTS: {
      return {
        ...state,
        postsPage: 1,
        getPostLoading: true,
        newPostAvailable: null,
        timestamp: new Date().toISOString(),
        newPost: null,
        getPostsReloaded: false,
        queuedPostsOnResume: null
      };
    }

    case socialActions.RELOAD_POSTS_SUCCESS: {
      return {
        ...state,
        postsPage: state.postsPage + 1,
        getPostLoading: false,
        timestamp: new Date().toISOString(),
        queuedPostsOnResume: null,
        getPostsReloaded: true
      };
    }

    case socialActions.RELOAD_POSTS_FAIL: {
      return {
        ...state,
        getPostLoading: false,
        getPostsReloaded: false
      };
    }

    case socialActions.RELOAD_ON_RESUME_SUCCESS: {
      return {
        ...state,
        queuedPostsOnResume: action.payload
      };
    }

    case socialActions.LOAD_POSTS: {
      return {
        ...state,
        getPostLoading: true,
        timestamp: new Date().toISOString(),
        newPost: null
      };
    }

    case socialActions.DELETE_POST_SUCCESS: {
      return {
        ...state,
        postSuppressionId: action.payload
      };
    }

    case socialActions.RESET_LIKE_FAIL_POST: {
      return {
        ...state,
        likeFailPost: null
      };
    }

    case socialActions.RESET_LIKE_POST: {
      return {
        ...state,
        likePostId: null
      };
    }

    case socialActions.RESET_POST_CREATION: {
      return {
        ...state,
        postCreation: null
      };
    }

    case socialActions.RESET_POST_SUPPRESSION: {
      return {
        ...state,
        postSuppressionId: null
      };
    }

    case socialActions.SAVE_TEMP_POST: {
      return {
        ...state,
        tempPost : action.payload
      };
    }

    case socialActions.SAVE_POST: {
      return {
        ...state,
        addPostLoading: true,
        addPostLoaded: false,
        newPost: null
      };
    }

    case socialActions.SAVE_POST_SUCCESS : {
      return {
        ...state,
        addPostLoading: false,
        addPostLoaded: true,
        postsPage: 1,
        postCreation: action.payload,
        tempPost : {
          content: '',
          private: false,
          question: false,
          craving: false,
          faq: false
        }
      };
    }

    case socialActions.SAVE_POST_FAIL: {
      return {
        ...state,
        addPostLoading: false
      };
    }

    case socialActions.SET_POSTS_FILTER: {
      return {
        ...state,
        postsPage: 1,
        getPostLoading: true,
        postsFilter: action.payload,
        timestamp : new Date().toISOString(),
        filterChanging: true
      };
    }

    case socialActions.SET_POSTS_USER_FILTER: {
      return {
        ...state,
        postsPage: 1,
        getPostLoading: true,
        postsUserFilter: action.payload,
        timestamp : new Date().toISOString(),
        filterChanging: true
      };
    }

    case socialActions.SET_POSTS_PAGE: {
      return {
        ...state,
        getPostLoading: true,
        postsPage: action.payload
      };
    }

    case socialActions.LOAD_POSTS_SUCCESS: {
      return {
        ...state,
        postsPage: state.postsPage + 1,
        getPostLoading: false,
        filterChanging: false
      };
    }

    case socialActions.LOAD_POSTS_FAIL: {
      return {
        ...state,
        getPostLoading: false,
        filterChanging: false
      };
    }

    case socialActions.POST_BY_ID: {
      return {
        ...state,
        id: action.payload,
        newPost: null
      };
    }

    case socialActions.TOGGLE_LIKE_POST: {
      return {
        ...state,
        id: action.payload,
        likePostLoading: true,
        likePostLoaded: false,
        likePostId: action.payload
      };
    }

    case socialActions.TOGGLE_LIKE_POST_FAIL: {
      return {
        ...state,
        likeFailPost: action.payload,
        likePostLoading: false,
        likePostLoaded: false
      };
    }

    case socialActions.OPEN_POST: {
      return {
        ...state,
        id: action.payload,
        newPost: null
      };
    }

    case socialActions.NOTIFY_NEW_POSTS: {

      const postInfo = action.payload;

      if (!postInfo || !postInfo.post_categories || (postInfo.post_categories.length === 0 && state.postsFilter.length === 0)) {
      }

      if (!postInfo || !postInfo.post_categories ||
        (state.postsFilter && !postInfo.post_categories.includes(state.postsFilter))) {

        return {...state};
      }

      return {
        ...state,
        newPostAvailable: postInfo.post_id,
        newPost: null
      };
    }

    case socialActions.NOTIFY_POST_LIKE: {
      return {
        ...state,
        newLikeOnPost: action.payload
      };
    }

    case socialActions.LOAD_POST_BY_ID: {
      return {
        ...state,
        newPost: null,
        newPostAvailable: null
      };
    }

    case socialActions.LOAD_POST_BY_ID_SUCCESS: {
      return {
        ...state,
        newPost: action.payload
      };
    }

    case socialActions.UPDATE_FEED_AFTER_RESUME: {
      return {
        ...state,
        postsPage: 1
      };
    }
  }

  return state;
}

export const postsFilter = (state: SocialState) => state.postsFilter;
export const postsUserFilter = (state: SocialState) => state.postsUserFilter;
export const postsPage = (state: SocialState) => state.postsPage;
export const postsTimestamp = (state: SocialState) => state.timestamp;
export const getPostLoading = (state: SocialState) => state.getPostLoading;
export const addPostLoading = (state: SocialState) => state.addPostLoading;
export const likePostLoading = (state: SocialState) => state.likePostLoading;
export const postId = (state: SocialState) => state.id;
export const tempPost = (state: SocialState) => state.tempPost;
export const addPostLoaded = (state: SocialState) => state.addPostLoaded;

export const likePostId = (state: SocialState) => state.likePostId;
export const likeFailPost = (state: SocialState) => state.likeFailPost;
export const postSuppressionId = (state: SocialState) => state.postSuppressionId;
export const postCreation = (state: SocialState) => state.postCreation;

export const filterChanging = (state: SocialState) => state.filterChanging;
export const newPostAvailable = (state: SocialState) => state.newPostAvailable;

export const postFeedId = () => 'current-post-feed';

export const newLikeOnPost = (state: SocialState) => state.newLikeOnPost;
export const newPost = (state: SocialState) => state.newPost;

export const queuedPostsOnResume = (state: SocialState) => state.queuedPostsOnResume;

export const getPostsReloaded = (state: SocialState) => state.getPostsReloaded;
