<cl-waves-background class="interstitial-waves-background interstitial-new-blue">
  <ion-content (click)="onClose()">
    <ion-header>
      <cl-header-nav-buttons
        class="page-header"
        color="blank"
        [backButton]="false">
      </cl-header-nav-buttons>
    </ion-header>

    <ion-grid class="page-wrapper">
      <img src="assets/imgs/fitbit-tracker-rounded.png" />

      <div class="content">
        <div class="title">{{ 'interstitials.congratulations2' | translate }}</div>
        <div class="subtitle">{{ 'interstitials.order_fitbit_tracker' | translate }}</div>
      </div>

      <ion-row class="exit-info padding-bottom-iphone-x">
        <ion-col class="ion-text-center">
          <ion-button color="action" (click)="getFitbitTracker()">
              {{ 'interstitials.get_today' | translate }}
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</cl-waves-background>
