import { schema } from 'normalizr';

export class AnxietyQuiz {
  date: string;
  score: number;
}

export const anxietyQuizSchema = new schema.Entity<AnxietyQuiz>(
  'anxietyQuizzes',
  undefined,
  { idAttribute: (anxietyQuizz: AnxietyQuiz) => anxietyQuizz.date }
);
