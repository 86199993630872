import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-hours-ago-selector',
  styleUrls: ['hours-ago-selector.component.scss'],
  template: `    
    <div class="slider-wrapper">
      <div class="slider-options">
        <span [class.selected]="sliderValue === 0">0</span>
        <span [class.selected]="sliderValue === 1">1</span>
        <span [class.selected]="sliderValue === 2">2</span>
        <span [class.selected]="sliderValue === 3">3</span>
        <span [class.selected]="sliderValue === 4">4</span>
        <span [class.selected]="sliderValue === 5">5</span>
        <span [class.selected]="sliderValue === 6">5+</span>
      </div>

      <cl-horizontal-slider
        [value]="sliderValue"
        [max]="6"
        (slide)="onSlide($event)">
      </cl-horizontal-slider>
    </div>
  `
})
export class HoursAgoSelectorComponent {

  @Input() sliderValue = null;

  @Output() eatAgo = new EventEmitter();

  onSlide(value) {
    this.sliderValue = value;
    this.eatAgo.emit(this.sliderValue / 10);
  }
}
