import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CloseAllModals } from '../../store/session/actions/navigation.actions';
import { State } from '../../store/state.reducer';
import { ClarityConfig } from '../../config/clarity.config';

@Component({
  selector: 'page-welcome-video',
  styleUrls: ['welcome-video.scss'],
  templateUrl: 'welcome-video.html'
})
export class WelcomeVideoPage {
  exercise = {
    kind: 'video',
    action: 'program',
    media_file: {
      data_file_name: 'intro-video.mp4',
      data_content_type: 'video/mp4',
      length: 128,
      data: './assets/videos/onboarding/intro-video.mp4'
    }
  };

  constructor(
    public config: ClarityConfig,
    private store: Store<State>
  ) {
  }

  done() {
    this.store.dispatch(new CloseAllModals());
  }

}
