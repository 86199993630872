import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SmokeCravingTool } from '../../../store/normalized/schemas/craving-tool.schema';
import { SmokingType } from 'src/app/store/normalized/schemas/user.schema';

@Component({
  selector: 'cl-mindful-smoking-exercise-step',
  styleUrls: ['../craving-tool-ctq.scss', 'mindful-smoking-exercise-step.component.scss'],
  template: `
    <cl-waves-background wizard-bg bg-position-bottom>
      <ion-content>
        <ion-header>
          <cl-header-nav-buttons
            class="page-header"
            color="primary"
            (closeClick)="triggerClose()"
            [backButton]="false">
          </cl-header-nav-buttons>
        </ion-header>

        <div class="first-steps lateral-padding">
          <h2 class="step-intro intro-question">{{(isVaping ? 'wizards.lets_vape_mindfully' : 'wizards.lets_smoke_mindfully') | translate}}</h2>
          <div class="use-grid">
            <cl-inner-item
              *ngFor="let step of mindfulSteps; index as stepNumber"
              [active]="mindfulStep === stepNumber"
              [completed]="mindfulStep > stepNumber"
              (changeItem)="completeMindfulStep(stepNumber)"
              [label]="step | translate">
            </cl-inner-item>
          </div>
        </div>
      </ion-content>
    </cl-waves-background>

    <div class="controls-holder padding-bottom-iphone-x">
      <ion-row class="buttons-holder lateral-padding">
        <ion-col size="6" offset="3">
          <cl-next-button
            [ngClass]="{'dark-bg': darkBackground}"
            label="common.next"
            [canClick]="mindfulNextEnabled"
            (action)="triggerNext()">
          </cl-next-button>
        </ion-col>
      </ion-row>
    </div>
  `
})
export class MindfulSmokingExerciseStepComponent implements OnInit {
  mindfulSmokingSteps = [
    'craving_tool.light_your_cigarette_and_take_a_drag',
    'craving_tool.notice_the_smoke_filling_your_mouth',
    'craving_tool.what_does_the_smoke_feel_in_your_throat',
    'craving_tool.what_does_the_smoke_feel_in_your_lungs',
    'craving_tool.breathe_the_smoke_out',
    'craving_tool.what_does_it_smell_like',
    'craving_tool.continue_this_way_until_the_end',
    'craving_tool.what_does_your_body_feel_now',
    'craving_tool.what_thoughts_and_emotions_do_you_feel'
  ];
  mindfulVapingSteps = [
    'craving_tool.take_a_puff',
    'craving_tool.notice_the_puff_filling_your_mouth',
    'craving_tool.what_does_the_puff_feel_in_your_throat',
    'craving_tool.what_does_the_puff_feel_in_your_lungs',
    'craving_tool.breathe_the_puff_out',
    'craving_tool.what_does_it_smell_like',
    'craving_tool.continue_this_way_until_the_end_vaping',
    'craving_tool.what_does_your_body_feel_now_vaping',
    'craving_tool.what_thoughts_and_emotions_do_you_feel'
  ];

  @Input() currentCravingTool: SmokeCravingTool;
  @Input() darkBackground = false;

  @Output() next = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  mindfulStep = 0;

  get mindfulNextEnabled() {
    return this.mindfulStep >= this.mindfulSteps.length;
  }

  get mindfulSteps() {
    return this.isVaping ? this.mindfulVapingSteps : this.mindfulSmokingSteps;
  }

  get isVaping() {
    return this.currentCravingTool.smokingType === SmokingType.VAPING;
  }

  ngOnInit(): void {
    this.setSmokeMindfully();
  }

  setSmokeMindfully() {
    this.currentCravingTool.smoke_exercise = true;
  }

  completeMindfulStep(step) {
    if (step > this.mindfulStep) {
      return;
    }
    this.mindfulStep = this.mindfulStep > step ? step : step + 1;
  }

  nextMindfulStep() {
    this.mindfulStep += 1;
  }

  triggerNext() {
    this.next.emit();
  }

  triggerClose() {
    this.closeClick.emit();
  }

}
