import { NrtSteps } from './nrt.service';
import { NrtStepsCounter } from '../store/session/reducers/nrt.reducer';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class NrtStepsCounterService {
  private _surveyStepsCounter: {
    [key: string]: NrtStepsCounter;
  } = {};

  surveyStepsCounter$ = new BehaviorSubject(this._surveyStepsCounter);

  init(nrtStep: NrtSteps, counter: NrtStepsCounter) {
    if (!this._surveyStepsCounter[nrtStep]) {
      counter.current = counter.current || 1;
      this._surveyStepsCounter[nrtStep] = counter;
    }
  }

  next(nrtStep: NrtSteps) {
    const counter = this._surveyStepsCounter[nrtStep];

    if (counter.current < counter.total) {
      counter.current++;
    }

    this.surveyStepsCounter$.next(this._surveyStepsCounter);
  }

  back(nrtStep: NrtSteps) {
    const counter = this._surveyStepsCounter[nrtStep];

    if (counter.current > 1) {
      counter.current--;
    }

    this.surveyStepsCounter$.next(this._surveyStepsCounter);
  }
}
