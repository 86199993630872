import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';

import { LiveLesson } from '../../../store/session/selectors/program.selectors';
import { State } from '../../../store/state.reducer';
import {
  userGoalsSelector,
  getDefaultGoalsSelection
} from '../../../store/normalized/selectors/goals.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-goals-edit-lesson',
  template: `
    <cl-goals-edit
      [initialCustomGoals]="customGoals$ | async"
      [initialDefaultGoals]="selectedDefaultGoals$ | async"
      [goalsLesson]="lesson"
      (action)="doAction()"
    ></cl-goals-edit>
  `
})
export class GoalEditLessonComponent {
  @Output() action = new EventEmitter();
  @Input() lesson: LiveLesson;
  selectedDefaultGoals$ = this.store.select(getDefaultGoalsSelection);
  customGoals$ = this.store.select(userGoalsSelector);

  constructor(
    private store: Store<State>
  ) {
  }

  doAction() {
    this.action.emit();
  }

}
