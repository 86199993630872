import { Component, Input, OnInit } from '@angular/core';
import { IntegrationSource } from '../../../store/session/models/integration.model';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import {
  getFitbitTodayManualMinutesActivitySum,
  getGooglefitTodayManualMinutesActivitySum,
  getHealthkitTodayManualMinutesActivitySum
} from '../../../store/normalized/selectors/minutes-activity.selectors';
import { of } from 'rxjs';

@Component({
  selector: 'cl-integration-activity-card',
  styleUrls: ['integration-activity-card.component.scss'],
  template: `
    <div class="integration" >
      <ion-grid>
        <ion-row>
          <ion-col class="health-logo-col ion-align-self-center">
            <div class="health-image {{integrationName}}"></div>
          </ion-col>
          <ion-col class="ion-align-self-center ion-text-left">
            <div class="title">{{ 'my_activity.' + integrationName | translate}}</div>
            <div class="subtitle" [innerHTML]="('my_activity.' + integrationName + '_subtitle') | translate"></div>
          </ion-col>
          <ion-col class="ion-align-self-center ion-text-end minutes" >
            {{getIntegrationTodaysMinutesActivitySum$ | async}}
            {{((getIntegrationTodaysMinutesActivitySum$ | async) > 1 ? 'my_activity.mins':'my_activity.min') | translate}}
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  `
})
export class IntegrationActivityCardComponent implements OnInit {
  @Input() integration: IntegrationSource = null;
  @Input() today = false;
  @Input() value = 0;

  integrationName: String = '';
  getIntegrationTodaysMinutesActivitySum$;

  private getHealthKitTodaysMinutesActivitySum$ = this.stateStore.select(getHealthkitTodayManualMinutesActivitySum);
  private getGoogleFitTodaysMinutesActivitySum$ = this.stateStore.select(getGooglefitTodayManualMinutesActivitySum);
  private getFitbitTodaysMinutesActivitySum$ = this.stateStore.select(getFitbitTodayManualMinutesActivitySum);

  constructor(private stateStore: Store<State>) {}

  ngOnInit() {

    switch(this.integration) {
      case IntegrationSource.fitbit:
        this.integrationName = 'fitbit';
        if(this.today) {
          this.getIntegrationTodaysMinutesActivitySum$ = this.getFitbitTodaysMinutesActivitySum$;
        } else {
          this.getIntegrationTodaysMinutesActivitySum$ = of(this.value);
        }
        break;

      case IntegrationSource.healthkit:
        this.integrationName = 'healthkit';
        if(this.today) {
          this.getIntegrationTodaysMinutesActivitySum$ = this.getHealthKitTodaysMinutesActivitySum$;
        } else {
          this.getIntegrationTodaysMinutesActivitySum$ = of(this.value);
        }
        break;

      case IntegrationSource.googlefit:
        this.integrationName = 'googlefit';
        if(this.today) {
          this.getIntegrationTodaysMinutesActivitySum$ = this.getGoogleFitTodaysMinutesActivitySum$;
        } else {
          this.getIntegrationTodaysMinutesActivitySum$ = of(this.value);
        }
        break;
    }
  }
}
