// helper constants
export const UTILS_CONSTANTS = {
  // fix bug caused by pinching the screen on ios (multi touch gesture)
  SWIPER_GESTURE_FIX: {
    on: {
      beforeInit() {
        const swiper = this;

        // before init is fire too early so just delay until all sync runs are done
        setTimeout(() => {
          if (swiper && swiper.zoom) {
            swiper.zoom.disable();
          }
        });
      }
    },
    zoom: {
      toggle: false
    }
  }
};

export const NON_BREAKING_SPACE = String.fromCharCode(160);
