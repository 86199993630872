<ion-row class="step lateral-padding {{'step' + currentStep}}">
  <ion-col class="ion-text-center">
    <ng-container *ngIf="currentStep === 1 then step1WorryTypeSelection"></ng-container>

    <ng-container *ngIf="isWorryFlow">
      <ng-container *ngIf="currentStep === 2 then step2Screen"></ng-container>
      <ng-container *ngIf="currentStep === 3 then step3HowHelpfulWorrying"></ng-container>
      <ng-container *ngIf="currentStep === 4 then step4WantKeepWorrying"></ng-container>
      <ng-container *ngIf="currentStep === 5 then step5Screen"></ng-container>
      <ng-container *ngIf="currentStep === 6 then checkoutWorryFlow"></ng-container>
    </ng-container>

    <ng-container *ngIf="isAnxietyFlow">
      <ng-container *ngIf="currentStep === 2 then toolsToStepOutOfLoop"></ng-container>
      <ng-container *ngIf="currentStep === 3 then checkoutAnxietyFlow"></ng-container>
    </ng-container>
  </ion-col>
</ion-row>

<ng-template #step1WorryTypeSelection>
  <h2 class="intro-question">{{'wizards.worry_tool_which_is_stronger' | translate}}</h2>

  <p class="ion-text-left">
    <strong><u>{{'wizard_items.Anxiety' | translate}}</u>:</strong>&nbsp;{{'wizards.worry_tool_anxiety_explanation' | translate}}
  </p>
  <p class="ion-text-left">
    <strong><u>{{'wizard_items.Worry' | translate}}</u>:</strong>&nbsp;{{'wizards.worry_tool_worry_explanation' | translate}}
  </p>

  <cl-list-single-select
    [items]="worryTypes"
    (selectItem)="onWorryTypeSelected($event)"
    [selected]="selectedWorryType && selectedWorryType.id">
  </cl-list-single-select>
</ng-template>

<ng-template #step2Screen>
  <h2 class="intro-question">{{'wizards.worry_tool_help_recalibrate_your_brain' | translate}}</h2>

  <div
    class="worry-mindfulness"
    *ngFor="let step of worryMindfulnessSteps1; index as stepNumber"
    [class.active]="worryMindfulnessStep1 === stepNumber"
  >
    <button (click)="completeWorryMindfulnessStep1(stepNumber, $event)">
      <div class="check-item" [class.checked]="worryMindfulnessStep1 > stepNumber">
        <i></i>
      </div>

      <span>{{step | translate}}</span>
    </button>
  </div>
</ng-template>

<ng-template #step3HowHelpfulWorrying>
  <h2 class="intro-question">{{'wizards.worry_tool_how_helpful' | translate}}</h2>

  <cl-intensity-selector
    [sliderValue]="this.model.strength * 100"
    highestIntensityLabel="wizards.very_helpful"
    mediumIntensityLabel=""
    lowestIntensityLabel="wizards.very_unhelpful"
    (intensity)="onSelectWorryingStrength($event)"
    lowestIntensityScale="-10"
    lowIntensityScale="-5"
    mediumIntensityScale="0"
    highIntensityScale="+5"
    highestIntensityScale="+10"
  >
  </cl-intensity-selector>
</ng-template>

<ng-template #step4WantKeepWorrying>
  <h2 class="intro-question">{{'wizards.worry_tool_want_keep_worrying' | translate}}</h2>

  <cl-list-single-select
    [items]="keepWorryingOptions"
    (selectItem)="onKeepWorryingOptionSelected($event)"
    [selected]="selectedKeepWorryingOption && selectedKeepWorryingOption.id">
  </cl-list-single-select>
</ng-template>

<ng-template #step5Screen>
  <ng-container *ngIf="selectedKeepWorryingOption.id === 2 then doNotKeepWorrying"></ng-container>
  <ng-container *ngIf="selectedKeepWorryingOption.id !== 2 then doKeepWorrying"></ng-container>

  <ng-template #doNotKeepWorrying>
    <ng-container *ngTemplateOutlet="toolsToStepOutOfLoop"></ng-container>
  </ng-template>

  <ng-template #doKeepWorrying>
    <h2 class="intro-question">{{'wizards.worry_tool_lets_do_exercises' | translate}}</h2>

    <p class="ion-text-left">
      {{'wizards.worry_tool_take_a_moment' | translate}}
    </p>

    <div
      class="worry-mindfulness"
      *ngFor="let step of worryMindfulnessSteps2; index as stepNumber"
      [class.active]="worryMindfulnessStep2 === stepNumber"
    >
      <button (click)="completeWorryMindfulnessStep2(stepNumber, $event)">
        <div class="check-item" [class.checked]="worryMindfulnessStep2 > stepNumber">
          <i></i>
        </div>

        <span>{{step | translate}}</span>
      </button>
    </div>
  </ng-template>
</ng-template>

<ng-template #toolsToStepOutOfLoop>
  <h2 class="intro-question">{{'wizards.worry_tool_tools_to_step_out_loop' | translate}}</h2>

  <ng-container *ngTemplateOutlet="extraExercises"></ng-container>
</ng-template>

<ng-template #checkoutWorryFlow>
  <ng-container *ngIf="selectedKeepWorryingOption.id === 2">
    <h2 class="intro-question">{{'wizards.worry_tool_checkout_not_worry' | translate}}</h2>
  </ng-container>

  <ng-container *ngIf="selectedKeepWorryingOption.id !== 2">
    <h2 class="intro-question">{{'wizards.worry_tool_checkout_worry' | translate}}</h2>
  </ng-container>

  <cl-intensity-selector
    [sliderValue]="this.model.after_exercise_strength * 100"
    highestIntensityLabel="wizards.very_helpful"
    mediumIntensityLabel=""
    lowestIntensityLabel="wizards.very_unhelpful"
    (intensity)="onSelectWorryingStrengthAfterExercise($event)"
    lowestIntensityScale="-10"
    lowIntensityScale="-5"
    mediumIntensityScale="0"
    highIntensityScale="+5"
    highestIntensityScale="+10"
  >
  </cl-intensity-selector>
</ng-template>

<ng-template #checkoutAnxietyFlow>
  <h2 class="intro-question">{{'wizards.worry_tool_checkout_anxiety_flow' | translate}}</h2>

  <cl-intensity-selector
    [sliderValue]="this.model.after_exercise_strength * 100"
    highestIntensityLabel="wizards.very_helpful"
    mediumIntensityLabel=""
    lowestIntensityLabel="wizards.very_unhelpful"
    (intensity)="onSelectWorryingStrengthAfterExercise($event)"
    lowestIntensityScale="-10"
    lowIntensityScale="-5"
    mediumIntensityScale="0"
    highIntensityScale="+5"
    highestIntensityScale="+10"
  >
  </cl-intensity-selector>
</ng-template>

<ng-template #extraExercises>
  <div class="exercises-list ion-text-left">
    <exercises-group
      [exercises]="(exercises$ | async)"
      [noLateralPadding]="true"
      [title]=""
      [isWizard]="true"
      (playExercise)="playExercise($event)">
    </exercises-group>

    <cl-stress-test-button (click)="startStressTest()"></cl-stress-test-button>
  </div>
</ng-template>
