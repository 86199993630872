import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-list-item',
  styleUrls: ['list-item-component.scss'],
  template: `
    <button>
      <ion-icon name="help-circle-outline"></ion-icon>
      <span>{{label}}</span>
    </button>
  `
})

export class ListItemComponent {

  @Input() label: string;

}
