import { createSelector } from '@ngrx/store';

import * as interstitialsReducer from './interstitials.reducer';
import { getInterstitialsState } from '../../../../app/store';

export const isFirstBonusExerciseCompleted = createSelector(
  getInterstitialsState,
  interstitialsReducer.firstBonusExerciseCompleted
);

export const isFirstCheckInCompleted = createSelector(
  getInterstitialsState,
  interstitialsReducer.firstCheckInCompleted
);

export const isFirstStressTestCompleted = createSelector(
  getInterstitialsState,
  interstitialsReducer.firstStressTestComplited
);

export const isFirstStressMeterCompleted = createSelector(
  getInterstitialsState,
  interstitialsReducer.firstStressMeterComplited
);
