import {
  Component, EventEmitter, Input, Output
} from '@angular/core';

  @Component({
    selector: 'cl-google-login',
    styleUrls: ['google-login.component.scss'],
    template: `
      <ion-button data-e2e="login-google-button" type="button" (click)="doLogin()">
        <ion-img src="assets/imgs/login/google-logo.png" slot="start"></ion-img>
        <ion-label>{{ label | translate }}</ion-label>
      </ion-button>
    `
  })
export class GoogleLoginComponent {

    @Output() login = new EventEmitter();

    @Input() label = 'actions.login_via_google';

    doLogin() {
      this.login.emit();
    }
}
