import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-accordion',
  styleUrls: ['accordion.component.scss'],
  template: `
    <ion-list>
      <ion-item detail-none (click)="expandItem(item)" *ngFor="let item of items" class="item">
        <div class="item-wrapper">

          <ion-label>
            <div class="title">
              <div class="title-text">
                {{item.name}}
              </div>
              <div class="arrow">
                <ion-icon name="ellipsis-horizontal" *ngIf="!item.expanded"></ion-icon>
                <ion-icon name="remove" *ngIf="item.expanded"></ion-icon>
              </div>
            </div>
          </ion-label>
          <div class='expand-wrapper' [class.collapsed]="!item.expanded">
            {{item.description}}
          </div>
        </div>
      </ion-item>
    </ion-list>
  `
})
export class AccordionComponent {

  @Input() items;

  expandItem(item) {
    this.items.map((listItem) => {
      listItem.expanded = item === listItem ? !listItem.expanded : false;

      return listItem;
    });
  }

}
