<ion-content class="ion-padding-top">
  <ion-header>
    <cl-header-nav-buttons
      [closeButton]="true"
      [backButton]="false"
      title="{{'gears_review.gears_review' | translate}}"
      color="primary"
      (closeClick)="onClose()"
      class="page-header"
    >
    </cl-header-nav-buttons>
  </ion-header>

  <div class="page-wrapper">
    <div class="task-text lateral-padding">
      <div class="title-wrapper">
        <div class="gears-header">
          <p class="gears-type" *ngIf="gear !== 'O'">{{gearText | translate}}</p>
          <p class="gears-name">{{gearName | translate}}</p>
        </div>
      </div>
    </div>

    <div class="contents-wrapper lateral-padding">
      <cl-accordion-gears [items]="accordeonItems" (action)="onAction($event)"></cl-accordion-gears>
    </div>
  </div>
</ion-content>

<ion-footer>
  <div class="gear-change-container lateral-padding">
    <ion-row>
      <ion-col class="gear-col" *ngFor="let gearInfo of gears">
        <div class="round-icon"
             [class.selected]="gear === gearInfo.name"
             (click)="showGear(gearInfo)">
          <span>{{gearInfo.name}}</span>
        </div>
      </ion-col>
    </ion-row>
  </div>
</ion-footer>

<div class="waves-bg"></div>
