import { GetResult, Preferences } from '@capacitor/preferences';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { platformReady } from '../../config/platform-ready';
import { FullState } from '../state.reducer';
import { Action } from '@ngrx/store';
import { SessionState } from '../session';
import { SensitiveState } from '../sensitive';

export const OFFLINE_STORE_PREFIX = '[CLOFFLINE]';
export const NORMALIZED_KEY_PREFIX = '[NORMLZD]';
export const SESSION_STORE = 'session';
export const SENSITIVE_STORE = 'sensitive';

export interface OfflineSyncAction extends Action {
  payload: FullState;
};

export const OFFLINE_SYNC_ACTION = '[OFFLINE] SYNC';

export class OfflineStore {
  private readyPromise = platformReady.promise;
  private capConfigurePromise: Promise<void>;
  private isIos = false;
  private isDevice = false;

  // data in these stores should be encrypted
  // remember to implement a proper migration when adding new fields here
  private sensitiveData = ['sensitive', NORMALIZED_KEY_PREFIX + 'userPrograms'];

  private LEGACY_KEY_PREFIX = '_cloffline/_ionickv/'; // if device, this key gets emptied

  private _storageAvailable = true;

  public get storageAvailable() {
    return this._storageAvailable;
  }

  get KEY_NAMESPACE() {
    return this.LEGACY_KEY_PREFIX + OFFLINE_STORE_PREFIX;
  }

  namespacedKey = (key = '') => this.KEY_NAMESPACE + key;

  constructor() {
    this.capConfigurePromise = Preferences.configure({
      group: 'NativeStorage'
    });

    this.readyPromise.then((platform) => {
      // on ipads, starting 14.6 platforms() is no longer returning ios for some reason, will ignore until capacitor upgrade
      this.isIos = platform.is('ios') || (platform.is('cordova') && platform.is('tablet') && !platform.is('android'));
      this.isDevice = platform.is('cordova');

      if (!this.checkCookiesStatus() || this.localStorageNotAvailable()) {
        this._storageAvailable = false;

        return;
      }

      if (this.isDevice) {
        this.LEGACY_KEY_PREFIX = '';
      }
    });
  }

  storeNormalizedData(key: string, value: any) {
    return this.storeData(NORMALIZED_KEY_PREFIX + key, value);
  }

  async storeData(key: string, value: any) {
    await this.readyPromise;

    if (!this.storageAvailable) {
      return this.noStoragePromise('storeData');
    }

    return this._setWithNamespace(key, value);
  }

  async fetchStore(): Promise<FullState> {
    await this.readyPromise;

    if (!this.storageAvailable) {
      return this.noStoragePromise('fetchStore');
    }

    const keys = await this._getNamespacedKeysFromStorage();
    const keysAdded = await this.verifyKeysToMigrateAndExecuteMigration(keys);
    const sanitizedKeys = await this._sanitizedKeysFromStorage([...keys, ...keysAdded]);

    // fetch all stored keys
    const data = await Promise.all(sanitizedKeys.map((key) => this._getWithNamespace(key)));

    const initialStore: FullState = {
      normalized: {
        result: [],
        entities: {}
      },

      persistent: undefined,
      sensitive: undefined,
      session: undefined,

      hydrated: undefined
    };

    // reconstruct store
    return sanitizedKeys.reduce((store, key, index) => {
      let currentValue = {};

      try {
        currentValue = JSON.parse(data[index].value);
      } catch (error) {
        console.error(`fetchStore(): error parsing store key ${key}`);
      }

      // if normalizd, store it inside the store.normalized.entities[key]
      if (key.indexOf(NORMALIZED_KEY_PREFIX) === 0) {
        return {
          ...store,
          normalized: {
            ...store.normalized,
            entities: {
              ...store.normalized.entities,
              [key.replace(NORMALIZED_KEY_PREFIX, '')]: currentValue
            }
          }
        };
      } else if (currentValue === null) {
        // ngrx can't receive `null` for top-level keys, otherwise the reducers will break
        currentValue = undefined;
      }

      return {
        ...store,
        [key]: currentValue
      };
    }, initialStore);
  }

  async verifyKeysToMigrateAndExecuteMigration(keys: string[]): Promise<string[]> {
    await this.verifyKeysToFullyMigrateAndExecute(keys);

    const keysAdded = await this.verifyKeysToPartiallyMigrateAndExecute(keys);

    return keysAdded ?? [];
  }

  private async verifyKeysToFullyMigrateAndExecute(keys: string[]) {
    // these keys shouldn't exist, and should be migrated to secure storage (with the cap_sec_ at the front)
    const SHOULD_MIGRATE = [
      this.namespacedKey(NORMALIZED_KEY_PREFIX + 'userPrograms')
    ];

    const keysToMigrate = keys.filter(key => SHOULD_MIGRATE.includes(key));

    if (!keysToMigrate?.length) {
      return;
    }

    // iterate over all keys and call migrate for each of them
    await Promise.all(keysToMigrate.map(async key => {
      try {
        return this.fullyMigrateKeyToSecure(key);
      } catch(error) {
        console.error(error);
      }
    }));
  }

  // 1. get value from unsecure storage
  // 2. set this value at the secure storage
  // 3. remove key from unsecure storage
  private async fullyMigrateKeyToSecure(key: string) {
    const [unsecureData, secureData] = await Promise.all([
      this._storageGet(key),
      this._secureStorageGet(key)
    ]);

    if (secureData?.value) {
      console.error(
        `[fullyMigrateKeyToSecure] found Secure Key "${key}" already set. Old value: ${secureData?.value}, will replace with value: ${unsecureData?.value}`
      );
    }

    console.warn(`[fullyMigrateKeyToSecure] migrating ${key}`);

    await SecureStoragePlugin.set({
      key,
      value: unsecureData.value
    });

    await Preferences.remove({ key });
  }

  private async verifyKeysToPartiallyMigrateAndExecute(keys: string[]): Promise<string[]> {
    // these keys should be checked to perform a custom migration

    const sessionKey = this.namespacedKey('session');
    const KEYS_TO_MIGRATE = [sessionKey];

    if (KEYS_TO_MIGRATE.every(key => !keys.includes(key))) {
      // if there's no key to be migrated inside our keys, we do nothing
      return [];
    }

    const keysAdded = [];

    // ---- start session migration
    //  1. get session.auth, and, if it's there:
    //  2. extend the secured sensitive key with the previous session.auth
    //  3. set again session key but without the auth key

    const sessionState: SessionState = JSON.parse((await this._storageGet(sessionKey)).value);
    const unsecureAuth = (sessionState as any)?.auth;

    if (!unsecureAuth) {
      return []; // we're safe, there's no session.auth property saved in unsecure store
    }

    const sensitiveKey = this.namespacedKey(SENSITIVE_STORE);
    const sensitiveState: SensitiveState = JSON.parse((await this._secureStorageGet(sensitiveKey)).value);
    if (sensitiveState?.auth) {
      console.error(
        `[verifyKeysToPartiallyMigrateAndExecute] session migration.
        Found sensitive.auth already set while migrating it from session.auth.
        Old value: ${JSON.stringify(sensitiveState.auth)}, will replace with value: ${JSON.stringify(unsecureAuth)}`
      );
    }

    console.warn(`[verifyKeysToPartiallyMigrateAndExecute] migrating ${sessionKey}`);

    await SecureStoragePlugin.set({
      key: sensitiveKey,
      value: JSON.stringify({
        ...sensitiveState,
        auth: unsecureAuth
      })
    });

    await Preferences.set({
      key: sessionKey,
      value: JSON.stringify({
        ...sessionState,
        auth: undefined
      })
    });

    keysAdded.push(sensitiveKey);

    return keysAdded;
  }

  clearStore() {
    if (!this.storageAvailable) {
      return this.noStoragePromise('clearStore');
    }

    return Promise.all([
      Preferences.clear(),
      SecureStoragePlugin.clear()
    ]);
  }

  private async _setWithNamespace(key, value) {
    const namespacedKey = this.namespacedKey(key);

    if (this.sensitiveData.includes(key)) {
      return SecureStoragePlugin.set({
        key: namespacedKey,
        value: JSON.stringify(value)
      });
    }

    return Preferences.set({
      key: namespacedKey,
      value: JSON.stringify(value)
    });
  }


  private _getWithNamespace(key: string) {
    const namespacedKey = this.namespacedKey(key);

    if (this.sensitiveData.includes(key)) {
      return this._secureStorageGet(namespacedKey);
    }

    return this._storageGet(namespacedKey);
  }

  private _storageGet(key: string): Promise<GetResult> {
    // prevent throw if key is not found
    return Preferences.get({ key }).catch(() => ({ value: null }));
  }

  private _secureStorageGet(key: string): Promise<GetResult> {
    // prevent throw if key is not found
    return SecureStoragePlugin.get({ key }).catch(() => ({ value: null }));
  }

  private async _getNamespacedKeysFromStorage() {
    await this.readyPromise;
    await this.capConfigurePromise;

    const keysResult = await Promise.all([
      Preferences.keys(),             // on web, this returns all keys, included the secured ones, but with the cap_sec_ at the front
      SecureStoragePlugin.keys()  // on web, this returns all secured keys, but without the cap_sec at the front
    ]);
    const keys: string[] = [
      ...keysResult[0].keys,
      ...keysResult[1].value.map(key => 'cap_sec_' + key)
    ]
      .filter(key => key.includes(this.KEY_NAMESPACE));  // remove all keys not from our namespace

    return [...new Set([...keys])]; // make sure keys are unique
  }

  private async _sanitizedKeysFromStorage(keys: string[]) {
    const sanitizedKeys = keys
      .map(key => key.replace(this.KEY_NAMESPACE, '')) // remove our namespace
      .map(key => key.replace(/cap_sec_/i, ''));       // remove one additional namesapce, from secure storage

    return [...new Set([...sanitizedKeys])]; // make sure keys are unique
  }

  private localStorageNotAvailable(): boolean {
    if (this.isDevice && this.isIos) {
      return false;
    }

    return !window.localStorage || window.localStorage === null;
  }

  private checkCookiesStatus(): boolean {
    if (this.isDevice && this.isIos) {
      return true;
    }

    try {
      document.cookie = 'cookietest=1';
      const cookiesEnabled = document.cookie.indexOf('cookietest=') !== -1;
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';

      return cookiesEnabled;
    } catch (error) {
      return false;
    }
  }

  private noStoragePromise(key: string) {
    console.log(`No storage available - could not perform offline store ${key}`);

    return Promise.reject(null);
  }
}

export const offlineStore = new OfflineStore();
