import { Injectable, OnDestroy } from '@angular/core';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';
import { PluginListenerHandle } from '@capacitor/core';
import { AudioNotificationOptions, AudioPlayerState, BrightcovePlayer } from 'capacitor-brightcove-player';
import { ClarityConfig } from '../../config/clarity.config';
import { LoggerService } from '../logger.service';
import { ToastService } from '../toast.service';
import { BrightcoveAudioPlayerService } from './brightcove-audio-player-service.interface';
import { AudioCue, MediaFile } from '../../store/normalized/schemas/mediaFile.schema';
import { File } from '@ionic-native/file/ngx';
import { AudioSubtitlesService } from 'src/app/services/brightcove/audio-subtitles.service';

export enum AudioPlayerStatus {
  ERROR,
  NONE,
  MEDIA_LOADING,
  MEDIA_LOADED,
  POSITION,
  STARTING,
  RUNNING,
  PAUSED,
  STOPPED,
  ENDED
}

@Injectable({providedIn: 'root'})
export class BrightcoveNativeAudioPlayerService implements OnDestroy, BrightcoveAudioPlayerService {

  private playing = false;
  private isAndroid;
  private readonly status$: BehaviorSubject<AudioPlayerStatus> = new BehaviorSubject<AudioPlayerStatus>(AudioPlayerStatus.NONE);
  private readonly positionSubject: Subject<number> = new Subject<number>();

  private _currentPosition = 0;
  private _duration = 0;
  private remainingLoopTime = 0;
  private lastLoopTime: number | null = null;

  private playerListeners: PluginListenerHandle[] = [];
  readonly looping$: Subject<boolean> = new Subject();
  position$ = this.positionSubject.asObservable();
  readonly closePlayerModal$: Subject<boolean> = new Subject<boolean>();
  closePlayerModal = this.closePlayerModal$.asObservable();

  get currentPosition() {
    return this._currentPosition;
  }

  set currentPosition(position) {
    this._currentPosition = position;
  }

  constructor(
    private readonly platform: Platform,
    private readonly insomnia: Insomnia,
    private readonly toastService: ToastService,
    private readonly config: ClarityConfig,
    private loggerService: LoggerService,
    private audioSubtitleService: AudioSubtitlesService
  ) {
    this.isAndroid = this.platform.is('android');
  }

  ngOnDestroy() {
    this.unload();
    this.playerListeners.forEach(listener => {
      listener.remove();
    });
  }

  getCurrentTime() {
    return this._currentPosition;
  }

  load(config: { brightcove_key: string; notificationOptions: AudioNotificationOptions }): Promise<void> {
    if (!this.config.isDevice) {
      return;
    }

    if (!this.playerListeners.length) {
      this.initPlayerListeners();
    }

    this.status$.next(AudioPlayerStatus.MEDIA_LOADING);

    // set logo of the app as the poster for iOS
    const file =  new File();
    const defaultPosterUrl = this.config.isIos ?
      file.applicationDirectory + `public/assets/icons/${this.config.currentProgram()}/brightcove-audio-lock-screen.png` : null;

    return BrightcovePlayer.destroyAudioPlayer()
      .then(() => {
        if (config.notificationOptions) {
          BrightcovePlayer.setAudioNotificationOptions(config.notificationOptions).catch(error => {
            this.loggerService.error('BrightcoveNativeAudioPlayerService', error, 'Error setting audio notification options');
            throw error;
          });
        }

        return BrightcovePlayer.loadAudio({fileId: config.brightcove_key, local: true, defaultPosterUrl})
          .then(() => Promise.resolve())
          .catch(error => {
            this.loggerService.error('BrightcoveNativeAudioPlayerService', error, 'Error loading audio');
            throw error;
          });
      })
      .catch(error => {
        this.loggerService.error('BrightcoveNativeAudioPlayerService', error, 'Error destroying audio');
        throw error;
      });
  }

  unload(forceDestroyAudioPlayer: boolean = true): Promise<void> {
    if (!this.config.isDevice) {
      return Promise.resolve();
    }

    return this.disableInsomnia()
      .then(() => this.removePlayerListeners())
      .then(() => {
        forceDestroyAudioPlayer && BrightcovePlayer.destroyAudioPlayer()
          .catch(error => {
            this.loggerService.error('BrightcoveNativeAudioPlayerService', error, 'Error destroying audio');
            throw error;
          });

        this.playing = false;
      });
  }

  play(): Promise<void> {
    if (!this.config.isDevice) {
      return Promise.resolve();
    }

    return BrightcovePlayer.playAudio().catch(error => {
      this.loggerService.error('BrightcoveNativeAudioPlayerService', error, 'Error play audio');
      throw error;
    });
  }

  pause(): Promise<void> {
    if (!this.config.isDevice) {
      return Promise.resolve();
    }

    return BrightcovePlayer.pauseAudio().catch(error => {
      this.loggerService.error('BrightcoveNativeAudioPlayerService', error, 'Error pause audio');
      throw error;
    });
  }

  togglePlayPause() {
    if (this.playing) {
      return this.pause();
    }

    return this.play();

  }

  stop(): Promise<void> {
    if (!this.config.isDevice) {
      Promise.resolve();
    }

    return BrightcovePlayer.getAudioPlayerState()
      .then((playerState: AudioPlayerState) => this.positionSubject.next(playerState.currentMillis))
      .then(() => BrightcovePlayer.stopAudio())
      .catch(error => {
        this.loggerService.error('BrightcoveNativeAudioPlayerService', error, 'Error stop audio');
        throw error;
      });
  }

  seekTo(seconds: number) {
    if (!this.config.isDevice || isNaN(seconds)) {
      return Promise.resolve();
    }
    let newPositionSeconds = seconds;
    if (newPositionSeconds < 0) {
      newPositionSeconds = 0;
    } else if (this.getDuration() && newPositionSeconds > this.getDuration()) {
      newPositionSeconds = this.getDuration();
    }
    this.currentPosition = newPositionSeconds;

    return BrightcovePlayer.seekToAudio({position: newPositionSeconds * 1000})
      .catch(reason => {
        this.loggerService.info('BrightcoveNativeAudioPlayerService', reason, 'Error seeking audio');
      });
  }

  async jumpBy(seconds: number) {
    if (!this.config.isDevice) {
      return;
    }

    // Current position is in milliseconds
    this.currentPosition += seconds * 1000;


    return BrightcovePlayer[seconds > 0 ? 'forwardAudio' : 'backwardAudio']({amount: Math.abs(seconds * 1000)});
  }

  getDuration() {
    return this._duration;
  }

  get playerStatus() {
    return this.status$;
  }

  getRemainingLoopTime() {
    if (!this.remainingLoopTime) {
      return null;
    }

    return Math.round(this.remainingLoopTime / 1000);
  }

  isLooping(): Promise<boolean> {
    if (!this.config.isDevice) {
      return Promise.resolve(false);
    }

    return BrightcovePlayer.isAudioLooping()
      .then(result => result && result.value)
      .catch(error => {
        this.loggerService.error('BrightcoveNativeAudioPlayerService', error, 'Error is audio looping');
        throw error;
      });
  }

  setLooping(loopingEnabled: boolean, duration?: number) {
    if (!this.config.isDevice) {
      return;
    }

    this.lastLoopTime = null;

    BrightcovePlayer[loopingEnabled ? 'enableAudioLooping' : 'disableAudioLooping']({time: duration})
      .then(() => {
        this.looping$.next(loopingEnabled);
      });
  }

  private async initPlayerListeners() {
    this.playerListeners = [
      await BrightcovePlayer.addListener('audioNotificationClose', () => {
        // In this case, the audio player is destroyed by Android when the user click on the notification close button
        this.unload(false)
          .then(() => {
            this.closePlayerModal$.next(true);
          })
          .catch(reason => {
            this.loggerService.error('BrightcoveNativeAudioPlayerService', reason, 'Error closing audio player');
          });
      }),
      await BrightcovePlayer.addListener('audioStateChange', (playerState: AudioPlayerState) => {
        this.handleStatusUpdate(playerState);
      }),
      await BrightcovePlayer.addListener('audioPositionChange', (position: AudioPlayerState) => {
        let currentPosition = position.currentMillis;
        if (position.timestamp && currentPosition > 0) {
          currentPosition += new Date().getTime() - position.timestamp;
        }
        this.currentPosition = currentPosition;
        this.positionSubject.next(currentPosition);
        this._duration = position.totalMillis;
        this.remainingLoopTime = position.remainingTime;
      }),
      await BrightcovePlayer.addListener('audioError', () => {
        this.toastService.error('Error while playing audio');
        this.loggerService.error('BrightcoveNativeAudioPlayerService', 'Error while playing audio');
      })
    ];
  }

  private async removePlayerListeners() {
    await Promise.all(this.playerListeners.map(listener => listener.remove()));
    this.playerListeners = [];
  }

  private async enableInsomnia() {
    if (!this.isAndroid) {
      return;
    }

    return this.insomnia.keepAwake();
  }

  private async disableInsomnia() {
    if (!this.isAndroid) {
      return;
    }

    return this.insomnia.allowSleepAgain();
  }

  // eslint-disable-next-line complexity
  private handleStatusUpdate(playerState: AudioPlayerState) {
    const status = playerState.state;
    switch (status) {
      case 'NONE':
        this.status$.next(AudioPlayerStatus.NONE);
        break;
      case 'RUNNING':
        this.enableInsomnia();
        this.status$.next(AudioPlayerStatus.RUNNING);
        break;
      case 'READY_TO_PLAY':
        const currentStatus = this.status$.getValue();
        // TODO we should improve this logic & avoid using 2 differents enums for AudioPlayerState
        // See https://jira.mindsciences.net/browse/CLARITY-1273
        if (currentStatus !== AudioPlayerStatus.RUNNING) {
          this.status$.next(AudioPlayerStatus.MEDIA_LOADED);
        } else {
          this.status$.next(AudioPlayerStatus.PAUSED);
          this.isLooping()
            .then(looping => {
              this.looping$.next(looping);
            });
          this.disableInsomnia();
        }
        break;
      case 'LOADING':
        this.status$.next(AudioPlayerStatus.MEDIA_LOADING);
        break;
      case 'ENDED':
        this.status$.next(AudioPlayerStatus.ENDED);
        break;
      case 'STOPPED':
        this.isLooping()
          .then(looping => {
            this.looping$.next(looping);
            if (!looping) {
              this.disableInsomnia();
            }
            this.status$.next(AudioPlayerStatus.STOPPED);
          });

        break;
    }
  }

  async getLanguages(brightcove_key: string): Promise<string[]> {
    if(!brightcove_key) {
      return Promise.reject();
    }

    let metadata;

    try {
      metadata = await BrightcovePlayer.getMetadata({fileId: brightcove_key});
    } catch(error) {
      this.loggerService.error('BrightcoveNativeAudioPlayerService', error, 'Error getting audio metadata');
      throw error;
    }

    const languages = metadata?.metadata?.subtitles.map(sub => sub.language);

    return Promise.resolve(languages);
  }

  async getTextTracks(lang: string, brightcove_key: string): Promise<AudioCue[]> {
    if (!lang || !brightcove_key) {
      return Promise.reject();
    }

    const vttFile = await this.audioSubtitleService.getSubtitlesFromBrightcove(lang, brightcove_key);
    const subtitles = this.audioSubtitleService.parseWebVttFile(vttFile);

    return Promise.resolve(subtitles.cues);
  }
}
