import { OnInit, Component, OnDestroy } from '@angular/core';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { SettingsComponent } from './settings.component';
import { getOnboardingState, State } from '../../../store/state.reducer';
import { ActivatedRoute, Router } from '@angular/router';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { BrowserService } from 'src/app/services/browser.service';
import { ConnectivityService } from 'src/app/services/connectivity.service';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { IntegrationsService } from 'src/app/services/integrations.service';
import * as navigationActions from 'src/app/store/session/actions/navigation.actions';
import * as integrationsActions from 'src/app/store/session/actions/integrations.actions';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { isFitbitConnected as isFitbitConnectedSelector } from 'src/app/store/session/selectors/integrations.selectors';
import { FitbitCallback } from 'src/app/store/session/models/integration.model';
import { getNotManualWeightActivitiesByDatesDescending } from 'src/app/store/normalized/selectors/weight-activity.selectors';
import { getCurrentUser, getCurrentUserProgram, getIsScaleRedemptionEnabled } from 'src/app/store/normalized/selectors/user.selectors';
import { isAfter, isSameDay, parseJSON } from 'date-fns';
import { SetOnboardingCompleted, UndoOnboardingCompleted } from 'src/app/store/persistent/onboarding/onboarding.actions';
import { WeightTrackingInfo } from './weight-track-block.component';
import { convertWeight } from '@mindsciences/utils';
import { getWeightScaleOrders } from 'src/app/store/session/selectors/health-devices.selectors';
import { FetchWeightScaleOrders, ResetFetchWeightScaleOrders } from 'src/app/store/session/actions/health-devices.actions';
import { LoadingService } from 'src/app/services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { NavController } from '@ionic/angular';

enum WeightTrackState {
  SHOULD_ORDER_SCALE = 0,
  WAITING_SCALE_RECEIVAL = 1,
  RECEIVED_SCALE = 2,
  CONNECTED_FITBIT = 3,
  USED_SCALE = 4
}

@Component({
  selector: 'cl-account-weight-track-dpp',
  styleUrls: ['weight-track-dpp.component.scss'],
  template: `
    <div *ngIf="(weightTrackState$ | async) === WeightTrackState.SHOULD_ORDER_SCALE" class="onboarding-incomplete">
      <div class="order-scale-container">
        <div class="order-scale-img">
          <img src="assets/imgs/fitbit-scale-black-tilted.png">
        </div>
        <div class="order-scale-body">
          <p class="title">
            {{ 'weight_tracking.lets_order_your_scale' | translate }}
          </p>

          <cl-action-button
            (action)="goToOrderMyScale()"
            [canClick]="true"
            label="{{ 'common.next' | translate }}">
          </cl-action-button>
        </div>
      </div>

      <div>
        <p class="sub-title bold">{{ 'weight_tracking.to_continue_you_need_the_scale' | translate }}</p>
      </div>
    </div>
    <div *ngIf="(weightTrackState$ | async) === WeightTrackState.WAITING_SCALE_RECEIVAL" class="onboarding-incomplete">
      <h2 class="title">{{ 'weight_tracking.havent_received_your_scale' | translate }}</h2>
      <h3 class="sub-title">{{ 'weight_tracking.check_your_email_to_redeem' | translate }}</h3>
      <p class="sub-title">{{ 'weight_tracking.once_you_received_your_scale' | translate }}</p>

      <h3 class="sub-title bold">{{ 'weight_tracking.in_the_meantime_lets_get_going' | translate }}</h3>
      <div class="button-container">
        <cl-action-button
          color="white"
          (action)="goToTodaysLesson()"
          [canClick]="true"
          label="{{ 'weight_tracking.todays_lesson' | translate }}">
        </cl-action-button>
      </div>
    </div>
    <div *ngIf="(weightTrackState$ | async) === WeightTrackState.RECEIVED_SCALE" class="onboarding-complete">
      <h2 class="title">{{ 'weight_tracking.awesome_you_have_your_scale' | translate }}</h2>
      <h3 class="sub-title">{{ 'weight_tracking.connect_your_fitbit_account_now' | translate }}</h3>
      <div class="button-container">
        <cl-action-button
          (action)="connectFitbit()"
          [canClick]="true"
          label="{{ 'weight_tracking.connect_to_fitbit' | translate }}">
        </cl-action-button>
      </div>
      <ng-container *ngTemplateOutlet="help"></ng-container>
    </div>
    <div *ngIf="(weightTrackState$ | async) === WeightTrackState.CONNECTED_FITBIT">
      <h3 class="sub-title"><img src="assets/imgs/danger.png" /> {{ 'weight_tracking.you_have_no_recordings_yet' | translate }}</h3>
      <h2 class="title">{{ 'weight_tracking.step_on_your_scale' | translate }}</h2>
      <ng-container *ngTemplateOutlet="help"></ng-container>
    </div>
    <div *ngIf="(weightTrackState$ | async) === WeightTrackState.USED_SCALE" class="account-weight-container">
      <ion-row class="weight-stats">
        <ion-col class="weight-tracker big">
          <cl-weight-track-block
            [weightTrackingInfo]="startWeight$ | async"
            [weightTitle]="'weight_tracking.start' | translate">
          </cl-weight-track-block>
        </ion-col>

        <ion-col class="weight-tracker big">
          <cl-weight-track-block
            [weightTrackingInfo]="weightTarget$ | async"
            [weightTitle]="'weight_tracking.goal' | translate"
            [showInfoIcon]="true">
          </cl-weight-track-block>
        </ion-col>
      </ion-row>

      <div class="weight-track-list">
        <ion-row *ngFor="let weightActivity of (validWeightActivities$ | async) | slice:0:shownWeightActivities" class="weight-point-container">
          <ion-col class="track-col ion-text-left">
            {{weightActivity.value | number:'1.1-1' }} {{ weightActivity | weightUnit }},
            {{ weightActivity.activity_at | weightInfoDate : 'mediumDate' : 'UTC' | titlecase }}
          </ion-col>
        </ion-row>
      </div>

      <ion-row *ngIf="(validWeightActivities$ | async)?.length > shownWeightActivities" class="action-button">
        <ion-col class="ion-text-center" auto>
          <div class="see-more">
            {{'weight_tracking.see_more' | translate}}
          </div>
        </ion-col>
      </ion-row>

      <div *ngIf="isProgrammDPP" [innerHTML]="'weight_tracking.manual_entries_informations' | translate" class="manual-entries-informations"></div>
    </div>

    <ng-template #help>
      <p class="small-print">{{ 'weight_tracking.trouble_setting_up_your_fitbit' | translate }}</p>
      <div class="button-container">
        <cl-action-button
          color="white"
          (action)="openHelpCenter()"
          [canClick]="true"
          label="{{ 'weight_tracking.contact_us' | translate }}">
        </cl-action-button>
      </div>
    </ng-template>`
})
export class WeightTrackDPPComponent implements SettingsComponent, OnInit, OnDestroy {
  isProgrammDPP = this.config.programDPP();

  queryParamsSubscription: Subscription;
  weightTrackStateActionSubscription: Subscription;
  private currentUser$ = this.store.select(getCurrentUser);
  private userProgramCreatedAt$ = this.store.select(getCurrentUserProgram)
    .pipe(
      map(userProgram => userProgram.created_at)
    );
  public validWeightActivities$ = this.store.select(getNotManualWeightActivitiesByDatesDescending)
    .pipe(
      withLatestFrom(this.userProgramCreatedAt$, this.currentUser$),
      map(([weightActivities, createdAt, user]) =>
        weightActivities.map(weightActivity => ({
          ...weightActivity,
          value: convertWeight(weightActivity.value, weightActivity.unit, user.weight_unit),
          unit: user.weight_unit
        }))
          .filter(weightActivity => {
            const activityDate = parseJSON(weightActivity.activity_at);
            const programDate = parseJSON(createdAt);

            return isAfter(activityDate, programDate) || isSameDay(activityDate, programDate);
          }))
    );

  private didReceiveScale$ = this.store.select(getOnboardingState).pipe(
    map(onboardingState => onboardingState.dppwlScale)
  );
  private isFitbitConnected$ = this.store.select(isFitbitConnectedSelector);
  private didUseScale$ = this.validWeightActivities$.pipe(
    map(validWeightActivities => Boolean(validWeightActivities?.length))
  );
  private didOrderedScale$ = this.store.select(getWeightScaleOrders).pipe(
    map(weightScaleOrders => Boolean(weightScaleOrders?.length))
  );
  private isScaleRedemptionEnabled$ = this.store.select(getIsScaleRedemptionEnabled);

  private controlProperties$ = combineLatest([
    this.didReceiveScale$,
    this.isFitbitConnected$,
    this.didUseScale$,
    this.didOrderedScale$,
    this.isScaleRedemptionEnabled$
  ]);

  public WeightTrackState = WeightTrackState;
  public weightTrackState$: Observable<WeightTrackState> = this.controlProperties$
    .pipe(map(([didReceiveScale, isFitbitConnected, didUseScale, didOrderedScale, isScaleRedemptionEnabled]) => {
      if (didUseScale) {
        return WeightTrackState.USED_SCALE;
      }
      else if (isFitbitConnected) {
        return WeightTrackState.CONNECTED_FITBIT;
      }
      else if (didOrderedScale && !didReceiveScale) {
        // since `didReceiveScale` it's a persistent state, it can be [falsely] true after creating a new account
        // so `didOrderedScale` takes precedence here, because it's an API information and more reliable
        // we clean the persistent state on performActionsBasedOnTrackState
        return WeightTrackState.WAITING_SCALE_RECEIVAL;
      }
      else if (didReceiveScale) {
        return WeightTrackState.RECEIVED_SCALE;
      }
      else if (didOrderedScale) {
        return WeightTrackState.WAITING_SCALE_RECEIVAL;
      }

      if (!isScaleRedemptionEnabled) {
        return WeightTrackState.WAITING_SCALE_RECEIVAL;
      }

      return WeightTrackState.SHOULD_ORDER_SCALE;
    }), distinctUntilChanged());

  public shownWeightActivities = 10;
  public startWeight$: Observable<WeightTrackingInfo> = this.currentUser$
    .pipe(map(user => ({
      value: user.weight,
      unit: user.weight_unit
    })));
  public weightTarget$: Observable<WeightTrackingInfo> = this.startWeight$
    .pipe(
      map(weightTrackingInfo => ({
        ...weightTrackingInfo,
        value: weightTrackingInfo.value * 0.95
      }))
    );

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<State>,
    private browser: BrowserService,
    private connectivity: ConnectivityService,
    private config: ClarityConfig,
    private integrationsService: IntegrationsService,
    private onboardingService: OnboardingService,
    private loading: LoadingService,
    private translate: TranslateService,
    private nav: NavController
  ) {
  }

  ngOnInit() {
    this.queryParamsSubscription = this.handleIntegrationCallbackParams();

    this.store.dispatch(new ResetFetchWeightScaleOrders());

    this.weightTrackStateActionSubscription = this.performActionsBasedOnTrackState();
  }

  performActionsBasedOnTrackState() {
    let waitingScaleOnboardingShown = false;

    return this.weightTrackState$.subscribe(weightTrackState => {
      if (weightTrackState >= WeightTrackState.CONNECTED_FITBIT) {
        this.store.dispatch(new SetOnboardingCompleted('dppwlScale'));
      } else if (weightTrackState === WeightTrackState.WAITING_SCALE_RECEIVAL && !waitingScaleOnboardingShown) {
        waitingScaleOnboardingShown = true;
        this.onboardingService.checkShowingOnboarding({type: 'dppwlScale'});
      } else if (weightTrackState === WeightTrackState.SHOULD_ORDER_SCALE) {
        // confirm we're actually at this stage
        this.fetchWeightScaleOrders();
        this.store.dispatch(new UndoOnboardingCompleted('dppwlScale'));
      }
    });
  }

  openHelpCenter() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    this.browser.goTo(this.config.env.helpCenterUrl);
  }

  connectFitbit() {
    this.integrationsService.goToFitbitConnectionPage({
      returnPath: this.config.env.fitbitCallbackWeightPath.replace('/', '')
    });
  }

  goToTodaysLesson() {
    this.store.dispatch(new navigationActions.GotoLessons());
  }

  showMoreWeights() {
    this.shownWeightActivities += 10;
  }

  async fetchWeightScaleOrders() {
    const translation = await this.translate.get('loading.loading').toPromise();
    await this.loading.showLoadingOverlay(translation);

    this.store.dispatch(new FetchWeightScaleOrders());
  }

  goToOrderMyScale() {
    this.nav.navigateForward('/order-weight-scale');
    this.removeSubscriptions(); // ngOnDestroy is not called in this case
  }

  ngOnDestroy() {
    this.removeSubscriptions();
  }

  removeSubscriptions() {
    this.queryParamsSubscription.unsubscribe();
    this.weightTrackStateActionSubscription.unsubscribe();
  }

  handleIntegrationCallbackParams() {
    return this.activatedRoute.queryParams.subscribe((queryParams: FitbitCallback) => {
      console.log('[WeightTrackDPPComponent] queryParams', queryParams);
      const { callback, status, error } = queryParams;
      if (!callback) {
        return;
      }

      this.store.dispatch(new integrationsActions.ConnectFitBit(status, error));

      this.removeCurrentQueryParams();
    });
  }

  private removeCurrentQueryParams() {
    if (this.config.isWebApp) {
      history.pushState(null, '', window.location.pathname);
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {}
      }
    );
  }
}
