import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'cl-checkbox-list-item',
  styleUrls: ['checkbox-list-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxListItemComponent)
    }
  ],
  template: `
    <button (blur)="onTouch()" (click)="onSelected()" [class.white]="style === 'white'">
      <div class="check-item" [class.checked]="checked">
        <i></i>
        <input hidden type="checkbox" ngDefaultControl [ngModel]="checked" />
      </div>
      <span>{{label}}</span>
    </button>
  `
})

export class CheckboxListItemComponent implements ControlValueAccessor {

  @Input() label: string;
  @Input() checked = false;
  @Input() style: string;

  @Output() selected = new EventEmitter();

  onChange: any = (value: boolean) => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(checked: boolean) {
    this.checked = checked;
  }

  onSelected() {
    this.checked = !this.checked;
    this.onChange(this.checked);
    this.selected.emit(this.checked);
  }
}
