import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NrtQuestion } from 'src/app/services/nrt.service';

@Component({
  selector: 'cl-nrt-assertions',
  styleUrls: [
    'nrt-assertions.component.scss',
    'nrt.component.scss'
  ],
  template: `
    <ion-grid class="component-content-grid">
      <ion-row>
        <ion-col>
          <ion-row class="subtitle-holder lateral-padding">
            <ion-col>
              <h4>{{'nrt.assertions.subtitle' | translate}}</h4>
            </ion-col>
          </ion-row>
          <ion-row class="lateral-padding assertions-content">
            <ion-col class="content-wrapper">
              <div *ngFor="let question of questions">
                <div class="assertion-response" [innerHTML]="question.assertion"></div>
              </div>
            </ion-col>
          </ion-row> 
        </ion-col>
      </ion-row>
    </ion-grid>
  `
})

export class NrtAssertionsComponent {

  @Input() questions: NrtQuestion[];
  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();

  constructor() {}

}
