import { Injectable } from '@angular/core';

import { ToastController } from '@ionic/angular';

import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class ToastService {

  constructor(
    private toastController: ToastController,
    private translate: TranslateService
  ) {
  }

  translateConfirm(message, params = {}) {
    return this.confirm(this.translate.get(message, params));
  }

  translateError(message) {
    return this.error(this.translate.get(message));
  }

  async confirm(message: string | Observable<any>) {
    // wait for observables if message is one
    if (message instanceof Observable) {
      message.subscribe(async (msg) => {
        const toast = await this.initConfirmToast(msg);

        return toast.present();
      });
    }
    else {
      const toast = await this.initConfirmToast(message);

      return toast.present();
    }
  }

  async error(message: string | Observable<any>) {
    // wait for observables if message is one
    if (message instanceof Observable) {
      message.subscribe(async (msg) => {
        const toast = await this.initErrorToast(msg);
        await toast.present();
      });
    }
    else {
      const toast = await this.initErrorToast(message);
      await toast.present();
    }
  }

  private async initConfirmToast(message) {
    return this.toastController.create({
      message,
      duration: 2000,
      position: 'bottom',
      mode: 'md'
    });
  }

  private async initErrorToast(message) {
    return this.toastController.create({
      message,
      duration: 3000,
      position: 'bottom',
      mode: 'md',
      cssClass: 'error'
    });
  }

}
