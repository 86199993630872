import { createSchemaSelectors } from 'ngrx-normalizr';

import { checkinAnxietyByDaySchema, CheckinAnxietyByDay } from '../schemas/checkin-anxiety-by-day.schema';
import { createSelector } from '@ngrx/store';

const checkinAnxietyByDaySchemaSelectors = createSchemaSelectors<CheckinAnxietyByDay>(checkinAnxietyByDaySchema);

export const getCheckinAnxietyByDays = checkinAnxietyByDaySchemaSelectors.getEntities;

export const getNormalizedCheckinsByDay = createSelector(
  checkinAnxietyByDaySchemaSelectors.getNormalizedEntities,
  (entities) => entities.checkInsByDay
);
