import { createSchemaSelectors } from 'ngrx-normalizr';
import { createSelector } from '@ngrx/store';
import {
  User,
  UserProgram,
  UserProgramSession,
  userProgramSchema,
  userProgramSessionSchema,
  userSchema,
  ProgramType,
  ErnContentProgram
} from '../schemas/user.schema';

import { getSessionState } from '../../state.reducer';
import equal from 'fast-deep-equal';

const userProgramSchemaSelector = createSchemaSelectors<UserProgram>(userProgramSchema);

const userProgramSessionSchemaSelector = createSchemaSelectors<UserProgramSession>(userProgramSessionSchema);

const userSchemaSelector = createSchemaSelectors<User>(userSchema);

let usersCopy: User[] = [];

export const getUsers = createSelector(
  userSchemaSelector.getEntities,
  (users) => {
    if (!equal(users, usersCopy)) {
      usersCopy = users;
    }

    return usersCopy;
  }
);

let userProgramsCopy: UserProgram[] = [];

export const getUserPrograms = createSelector(
  userProgramSchemaSelector.getEntities,
  (userPrograms) => {
    if (!equal(userPrograms, userProgramsCopy)) {
      userProgramsCopy = userPrograms;
    }

    return userProgramsCopy;
  }
);

export const getCurrentDppWlUserProgram = createSelector(
  getUserPrograms,
  (userPrograms) => userPrograms?.find(program => program.dpp_wl_type === ProgramType.DPP || program.dpp_wl_type === ProgramType.WL)
);

let userProgramSessionsCopy: UserProgramSession[] = [];

export const getUserProgramSessions = createSelector(
  userProgramSessionSchemaSelector.getEntities,
  (userProgramSessions) => {
    if (!equal(userProgramSessions, userProgramsCopy)) {
      userProgramSessionsCopy = userProgramSessions;
    }

    return userProgramSessionsCopy;
  }
);

export const getCurrentUser = createSelector(
  getUsers,
  (users) => {
    if (!users || users.length === 0) {
      return ({} as User);
    }

    return users[0];
  }
);

export const getCurrentUserStartDate = createSelector(
  getCurrentUser,
  (user) => user.start_date
);

export const getCurrentUserEndDate = createSelector(
  getCurrentUser,
  (user) => user.end_date
);

export const getCurrentUserNRTAvailable = createSelector(
  getCurrentUser,
  (user) => user.nrt_available
);

export const getCurrentUserQuitDate = createSelector(
  getCurrentUser,
  (user) => user.quit_date
);

export const getCurrentUserQuitAndEndDate = createSelector(
  getCurrentUserEndDate,
  getCurrentUserQuitDate, (end, quit) => ({end_date: end, quit_date: quit})
);

export const getCurrentUserCigaretteSavings = createSelector(
  getCurrentUser,
  (user) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { cig_pack_cost, cigs_per_day } = user;
    if (!(cig_pack_cost && cigs_per_day)) {
      return 0;
    }
    const cigaretteCost = cig_pack_cost / 20;

    const savingsPerYear = cigs_per_day * cigaretteCost * 365;

    return savingsPerYear;
  }
);

export const getCurrentUserPrograms = createSelector(
  getCurrentUser,
  (user) => user.user_programs
);

export const getCurrentUserProgram = createSelector(
  getUserPrograms,
  (programs) => {

    if (!programs || programs.length === 0) {
      return ({} as UserProgram);
    }

    return programs[0];
  }
);

export const getCurrentUserProgramInfo = createSelector(
  getCurrentUserProgram,
  (userProgram) => userProgram?.user_program_info
);

export const getAllowHealthFitIntegration = createSelector(
  getCurrentUserProgramInfo,
  (userProgramInfo) => userProgramInfo?.healthfit_integration_enabled
);

export const getCurrentUserSSOProvider = createSelector(
  getCurrentUser,
  (user) => user.sso_provider
);

export const isProgramExtensionEnabled = createSelector(
  getCurrentUserProgramInfo,
  (userProgramInfo) => userProgramInfo?.program_extension_enabled
);

export const isERNProgramExtended = createSelector(
  getCurrentUserProgramInfo,
  (userProgramInfo) => userProgramInfo?.content_program === ErnContentProgram.MindfulEatingWLExtension
);

export const isERNWeightLoss = createSelector(
  getCurrentUserProgramInfo,
  (userProgramInfo) => userProgramInfo?.content_program === ErnContentProgram.WeightLoss
);

export const getCanExtendERNProgram = createSelector(
  isProgramExtensionEnabled,
  isERNProgramExtended,
  isERNWeightLoss,
  (allowedProgramExtension, programExtended, programWl) => allowedProgramExtension && programWl === false && programExtended === false
);

export const doesERNProgramContainWeightLoss = createSelector(
  getCurrentUserProgramInfo,
  (userProgramInfo) => userProgramInfo?.content_program === ErnContentProgram.MindfulEatingWLExtension ||
    userProgramInfo?.content_program === ErnContentProgram.WeightLoss
);

export const getIsScaleRedemptionEnabled = createSelector(
  getCurrentUserProgramInfo,
  (userProgramInfo) => Boolean(userProgramInfo?.custom_attributes?.isScaleRedemptionEnabled)
);

export const getIsTrackerRedemptionEnabled = createSelector(
  getCurrentUserProgramInfo,
  (userProgramInfo) => Boolean(userProgramInfo?.custom_attributes?.isTrackerRedemptionEnabled)
);

export const getIsCoachingTabEnabled = createSelector(
  getCurrentUserProgramInfo,
  (userProgramInfo) => Boolean(userProgramInfo?.custom_attributes?.isCoachingTabEnabled)
);

export const isSharecareSsoProvider = createSelector(
  getCurrentUserSSOProvider,
  (ssoProvider) => ssoProvider === 'sharecare'
);

export const isCurrentUserSharecareStandalone = createSelector(
  getCurrentUser,
  (user) => user.provider_type === 'sc_standalone'
);

export const isCurrentUserSharecareDigital = createSelector(
  getCurrentUser,
  (user) => user.provider_type === 'sc_digital'
);

export const isCurrentUserShacareConsumer = createSelector(
  getCurrentUser,
  (user) => user.sc_consumer
);

export const getCurrentUserProgramSession = createSelector(
  getUserProgramSessions,
  (userProgramSessions) => {

    if (!userProgramSessions || userProgramSessions.length === 0) {
      return ({} as UserProgramSession);
    }

    return userProgramSessions[0];
  }
);

export const getUserMantra = createSelector(
  getCurrentUserProgramSession,
  (userProgramSession) => userProgramSession.mantra
);

export const getUserGoalIds = createSelector(
  getCurrentUserProgram,
  (userProgram) => userProgram.goal_ids ? userProgram.goal_ids : []
);

export const getUserDebuggingData = createSelector(
  getCurrentUserProgram,
  getSessionState,
  (userProgram, session) => ({
    userProgram,
    session
  })
);

export const getUserProgramLanguage = createSelector(
  getCurrentUserProgram,
  (userProgram) => userProgram?.language_code
);
