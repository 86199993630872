import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpProvider } from '../providers/http/http.provider';
import { Integration } from '../store/session/models/integration.model';

@Injectable({providedIn: 'root'})
export class ConnectedApplicationsProvider {

  private connectedApplicationsEndpoint = '/connected_applications';

  constructor(
    private http: HttpProvider
  ) { }

  getConnectedApplications(): Observable<Integration[]> {
    return this.http.get<Integration[]>(this.connectedApplicationsEndpoint);
  }

  disconnectFitBit(): Observable<void> {
    return this.http.delete(`${this.connectedApplicationsEndpoint}/fitbit`);
  }
}
