import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { BrowserService } from '../services/browser.service';
import * as navigationActions from '../store/session/actions/navigation.actions';
import * as integrationsActions from '../store/session/actions/integrations.actions';
import { NavController } from '@ionic/angular';
import { FitbitCallbackError } from 'src/app/store/session/models/integration.model';
import { WeightActivity } from '../store/normalized/schemas/weight-activity.schema';

export type StatusType = 'success' | 'fail' | 'rejected';
type Platform = 'ios' | 'android' | 'fitbit';

export interface IntegrationResultBase {
  type: 'connection' | 'disconnection' | 'synchronization' | 'weight' | 'information';
  status: StatusType;
  error?: FitbitCallbackError;
}

export interface IntegrationConnectionResult extends IntegrationResultBase {
  type: 'connection' | 'disconnection' | 'synchronization';
  platform?: Platform;
}

export interface IntegrationInformation extends IntegrationResultBase {
  type: 'information';
  platform?: Platform;
}

export interface IntegrationWeightResult extends IntegrationResultBase {
  type: 'weight';
  weight: WeightActivity;
}

export type IntegrationResult = IntegrationConnectionResult | IntegrationWeightResult | IntegrationInformation;

@Component({
  selector: 'cl-integrations-status-modal',
  styleUrls: ['integrations-status-modal.component.scss'],
  template: `
    <cl-bottom-sheet (closeClick)="onClose()">
      <div class="background-waves">
        <ng-container *ngIf="integrationResult.type === 'connection' then fromConnectionSection"></ng-container>
        <ng-container *ngIf="integrationResult.type === 'disconnection' then fromDisconnectionSection"></ng-container>
        <ng-container *ngIf="integrationResult.type === 'weight' then fromWeightSection"></ng-container>
        <ng-container *ngIf="integrationResult.type === 'synchronization' then fromSynchronizationSection"></ng-container>
        <ng-container *ngIf="integrationResult.type === 'information' then fromInformationSection"></ng-container>
      </div>
    </cl-bottom-sheet>

    <ng-template #fromInformationSection>
      <ng-container *ngIf="platform === 'android'">
        <h2>{{'integrations_status.connected_googlefit' | translate}}</h2>
        <p>
          {{ 'integrations_status.track_googlefit' | translate }}
          <span  (click)="onViewDevice()" class="my-devices">{{ 'integrations_status.my_devices' | translate }}</span>
        </p>
      </ng-container>
      <ng-container *ngIf="platform === 'ios'">
        <h2>{{'integrations_status.connected_healtkit' | translate}}</h2>
        <p>
          {{ 'integrations_status.track_healthkit' | translate }}
          <span (click)="onViewDevice()" class="my-devices">{{ 'integrations_status.my_devices' | translate }}</span>
        </p>
      </ng-container>
      <ng-container *ngIf="platform === 'fitbit'">
        <h2>{{'integrations_status.connected_fitbit' | translate}}</h2>
        <p>
          {{ 'integrations_status.track_fitbit' | translate}}
          <span (click)="onViewDevice()" class="my-devices">{{ 'integrations_status.my_devices' | translate }}</span>
        </p>
      </ng-container>

    </ng-template>

    <ng-template #fromSynchronizationSection>
      <ng-container *ngIf="status === 'success' && platform === 'android'">
        <h2>{{ 'integrations_status.resync_successful' | translate }}</h2>
        <p>{{ 'integrations_status.resync_googlefit_successful' | translate }}</p>

        <div class="action-button">
          <cl-action-button
            label="{{'integrations_status.view_activity' | translate}}"
            color="white"
            [canClick]="true"
            (click)="onViewActivity()">
          </cl-action-button>
        </div>
      </ng-container>

      <ng-container *ngIf="status === 'success' && platform === 'ios'">
        <h2>{{ 'integrations_status.resync_successful' | translate }}</h2>
        <p>{{ 'integrations_status.resync_healthkit_successful' | translate }}</p>

        <div class="action-button">
          <cl-action-button
            label="{{'integrations_status.view_activity' | translate}}"
            color="white"
            [canClick]="true"
            (click)="onViewActivity()">
          </cl-action-button>
        </div>
      </ng-container>

      <ng-container *ngIf="status === 'fail' && platform === 'android'">
        <h2>{{ 'integrations_status.resync_unsuccessful' | translate }}</h2>
        <p>{{ 'integrations_status.resync_googlefit_unsuccessful' | translate }}</p>
        <div class="action-button">
          <cl-action-button
            label="{{'integrations_status.resync_again' | translate}}"
            color="white"
            [canClick]="true"
            (click)="onRetrySync()">
          </cl-action-button>
        </div>

        <div class="troubleshoot">
          <a (click)="openGoogleFitHelp()">{{'integrations_status.troubleshoot_synchronization' | translate}}</a>
        </div>

      </ng-container>

      <ng-container *ngIf="status === 'fail' && platform === 'ios'">
        <h2>{{ 'integrations_status.resync_unsuccessful' | translate }}</h2>
        <p>{{ 'integrations_status.resync_healthkit_unsuccessful' | translate }}</p>

        <div class="action-button">
          <cl-action-button
            label="{{'integrations_status.resync_again' | translate}}"
            color="white"
            [canClick]="true"
            (click)="onRetrySync()">
          </cl-action-button>
        </div>

        <div class="troubleshoot">
          <a (click)="openHealthKitHelp()">{{'integrations_status.troubleshoot_synchronization' | translate}}</a>
        </div>

      </ng-container>
    </ng-template>

    <ng-template #fromConnectionSection>
      <ng-container *ngIf="status === 'success' && !platform">
        <h2>{{ 'integrations_status.connection_successful' | translate }}</h2>
      </ng-container>

      <ng-container *ngIf="status === 'success' && platform === 'fitbit' ">
        <h2>{{ 'integrations_status.connect_fitbit' | translate}}</h2>
        <p>{{ 'integrations_status.connection_fitbit_successful' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="status === 'fail' && platform === 'fitbit' ">
        <h2>{{ 'integrations_status.connection_unsuccessful' | translate}}</h2>
        <p *ngIf="error === 'already_connected'">{{ 'integrations_status.fitbit_already_connected' | translate }}</p>
        <p *ngIf="error === 'oauth_error'">{{ 'integrations_status.fitbit_oauth_error' | translate }}</p>
        <p *ngIf="error === 'insufficient_scope'">{{ 'integrations_status.fitbit_insufficient_scope' | translate }}</p>
        <p *ngIf="error === 'connection_error'">{{ 'integrations_status.connection_fitbit_unsuccessful' | translate }}</p>
        <div class="troubleshoot">
          <a (click)="openFitbitHelp()">{{'integrations_status.troubleshoot_fitbit_connection' | translate}}</a>
        </div>
      </ng-container>

      <ng-container *ngIf="status === 'success' && platform === 'ios' ">
        <h2>{{ 'integrations_status.connect_healthkit' | translate}}</h2>
        <p>{{ 'integrations_status.connection_healthkit_successful' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="status === 'success' && platform === 'android' ">
        <h2>{{ 'integrations_status.connect_googlefit' | translate}}</h2>
        <p>{{ 'integrations_status.connection_googlefit_successful' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="status === 'fail' && !platform">
        <h2>{{ 'integrations_status.connection_unsuccessful' | translate }}</h2>
      </ng-container>

      <ng-container *ngIf="status === 'fail' && platform === 'ios'">
        <h2>{{ 'integrations_status.connection_unsuccessful' | translate}}</h2>
        <p>{{ 'integrations_status.connection_healthkit_unsuccessful' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="status === 'fail' && platform === 'android'">
        <h2>{{ 'integrations_status.connection_unsuccessful' | translate}}</h2>
        <h2>{{ 'integrations_status.connection_googlefit_unsuccessful' | translate }}</h2>
      </ng-container>

      <ng-container *ngIf="status === 'rejected' && platform === 'android'">
        <h2>{{ 'integrations_status.connect_googlefit' | translate}}</h2>
        <p>{{ 'integrations_status.your_googlefit_data_will_not_be_synced_until' | translate }}</p>

        <div class="action-button">
          <cl-action-button
            label="{{ 'common.retry' | translate }}"
            color="white"
            [canClick]="true"
            (click)="retryAndroidConnection()">
          </cl-action-button>
        </div>
      </ng-container>

      <ng-container *ngIf="status === 'something-wrong'">
        <h2>{{ 'integrations_status.something_wrong' | translate }}</h2>
      </ng-container>
    </ng-template>

    <ng-template #fromDisconnectionSection>
      <ng-container *ngIf="status === 'success' && !platform">
        <h2>{{ 'integrations_status.your_account_was_disconnected' | translate }}</h2>
      </ng-container>

      <ng-container *ngIf="status === 'success' && platform === 'fitbit'">
        <h2>{{ 'integrations_status.disconnect_fitbit' | translate}}</h2>
        <p>{{ 'integrations_status.your_account_was_disconnected_fitbit' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="status === 'fail' && platform === 'fitbit'">
        <h2>{{ 'integrations_status.disconnect_healthkit' | translate}}</h2>
        <p>{{ 'integrations_status.your_account_was_disconnected_healthkit' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="status === 'success' && platform === 'ios'">
        <h2>{{ 'integrations_status.disconnect_healthkit' | translate}}</h2>
        <p>{{ 'integrations_status.your_account_was_disconnected_healthkit' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="status === 'success' && platform === 'android'">
        <h2>{{ 'integrations_status.disconnect_googlefit' | translate}}</h2>
        <p>{{ 'integrations_status.your_account_was_disconnected_googlefit' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="status === 'something-wrong'">
        <h2>{{ 'integrations_status.something_wrong' | translate }}</h2>
      </ng-container>
    </ng-template>

    <ng-template #fromWeightSection>
      <ng-container *ngIf="status === 'success'">
        <ng-container *ngIf="integrationWeightResult.weight then weightFound else noWeightFound"></ng-container>

        <ng-template #weightFound>
          <h2>{{ 'integrations_status.weight_tracked' | translate }}</h2>
          <p [innerHTML]="'integrations_status.your_weight_is' |
            translate: { yourWeight: integrationWeightResult.weight.value, unit: integrationWeightResult.weight.unit == 'lb' ? 'lbs':'kg' }"></p>
        </ng-template>

        <ng-template #noWeightFound>
          <h2>{{ 'integrations_status.weight_capture_unsuccessfull' | translate }}</h2>
          <p>{{ 'integrations_status.no_weight_was_found_use_scale' | translate }} </p>

          <div class="troubleshoot">
            <a href="https://www.fitbit.com/scale/setup/start" >{{'integrations_status.troubleshoot_fitbit_weight_error' | translate}}</a>
          </div>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="status === 'fail'">
        <h2>{{ 'integrations_status.connection_unsuccessful' | translate }}</h2>
      </ng-container>

      <ng-container *ngIf="status === 'something-wrong'">
        <h2>{{ 'integrations_status.something_wrong' | translate }}</h2>
      </ng-container>
    </ng-template>
  `
})
export class IntegrationsStatusModalComponent {
  modal;

  @Input() integrationResult: IntegrationResult;

  // helper getter just for better type-linting
  get integrationWeightResult(): IntegrationWeightResult {
    return this.integrationResult as IntegrationWeightResult;
  }

  get platform(): Platform | null {
    return (this.integrationResult as IntegrationConnectionResult)?.platform || null;
  }

  get status(): StatusType | 'something-wrong' {
    const status = this.integrationResult.status;
    if (
      status !== 'success' &&
      status !== 'fail' &&
      status !== 'rejected' &&
      status !== 'disconnected'
    ) {
      return 'something-wrong';
    }

    return status;
  }

  get error() {
    return this.integrationResult?.error;
  }

  constructor(
    private store: Store<State>,
    private nav: NavController,
    private browser: BrowserService
  ) {}

  onClose() {
    this.store.dispatch(new navigationActions.CloseModal({ modalId: this.modal.id }));
  }

  retryAndroidConnection() {
    this.store.dispatch(new integrationsActions.ConnectGoogleFit());
    this.onClose();
  }

  onRetrySync() {
    this.store.dispatch(new integrationsActions.GetAllHealthData());
    this.onClose();
  }

  onViewDevice() {
    this.nav.navigateRoot('tabs/account/devices');
    this.onClose();
  }

  onViewActivity() {
    this.nav.navigateRoot('tabs/account/activity');
    this.onClose();
  }

  openFitbitHelp() {
    this.browser.goTo('https://claritasmindsciences.zendesk.com/hc/en-us/articles/4413291974675');
  }

  openGoogleFitHelp() {
    this.browser.goTo('https://claritasmindsciences.zendesk.com/hc/en-us/articles/4413292000275');
  }

  openHealthKitHelp() {
    this.browser.goTo('https://claritasmindsciences.zendesk.com/knowledge/articles/4413288557971/en-us?brand_id=353088');
  }
}
