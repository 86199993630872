import { schema } from 'normalizr';

export class Subscription {
  id: number;
  provider: string;
  created_at: string;
  status: string;
  active_until: string;
  cancel_requested: string;
  appstore_renewal_on: boolean;
}

export const subscriptionSchema = new schema.Entity<Subscription>(
  'subscriptions',
  {},
  {idAttribute: () => 'current'}
);
