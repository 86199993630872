<ion-content no-bounce>
  <ion-header>
    <cl-header-nav-buttons
      color="blank"
      [backButton]="!isExercise && !playExercise"
      (back)="onBack()"
      (closeClick)="onClose()"
      class="page-header"
    >
    </cl-header-nav-buttons>
  </ion-header>

  <div class="page-wrapper">
    <cl-exercise-player
      [lesson]="playItem$ | async"
      [playExercise]="playExercise"
      [isExercise]="isExercise"
      [canLoad]="transitionCompleted"
      (init)="onInitLesson()"
      (next)="onNextLesson($event)"
      (closeInput)="onClose()"
      *ngIf="isAudioOrVideoLesson(playItem$ | async)">
    </cl-exercise-player>

    <cl-lesson-capture
      [lesson]="lesson$ | async"
      [playExercise]="playExercise"
      (init)="onInitLesson()"
      (next)="onNextLesson($event)"
      *ngIf="isCaptureLesson(playItem$ | async)">
    </cl-lesson-capture>

    <cl-lesson-perform
      [lesson]="lesson$ | async"
      (init)="onInitLesson()"
      (next)="onNextLesson($event)"
      *ngIf="isPerformLesson(playItem$ | async)">
    </cl-lesson-perform>
  </div>

  <div class="waves-bg"></div>

  <div class="bottom-bg-background"></div>

</ion-content>
