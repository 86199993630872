// interface generated with
// `npx -p typescript tsc local-plugins/KochavaTrackerCordova/www/KochavaTracker.js --declaration --allowJs --emitDeclarationOnly`
// and then modified a little bit to improve some "any" types

export interface IKochavaTrackerSdk {
  executeAdvancedInstruction(name: any, value: any): void;
  setLogLevel(logLevel: any): void;
  setSleep(sleep: any): void;
  setAppLimitAdTracking(appLimitAdTracking: any): void;
  registerCustomDeviceIdentifier(name: any, value: any): void;
  registerIdentityLink(name: any, value: any): void;
  enableAndroidInstantApps(instantAppGuid: any): void;
  enableIosAppClips(identifier: any): void;
  enableIosAtt(): void;
  setIosAttAuthorizationWaitTime(waitTime: number): void;
  setIosAttAuthorizationAutoRequest(autoRequest: boolean): void;
  registerPrivacyProfile(name: any, keys: any): void;
  setPrivacyProfileEnabled(name: any, enabled: any): void;
  getStarted(): Promise<any>;
  registerAndroidAppGuid(androidAppGuid: string): void;
  registerIosAppGuid(iosAppGuid: string): void;
  registerPartnerName(partnerName: any): void;
  start(): void;
  shutdown(deleteData: any): void;
  getDeviceId(): Promise<any>;
  getInstallAttribution(): Promise<any>;
  retrieveInstallAttribution(): Promise<any>;
  processDeeplink(path: any): Promise<any>;
  processDeeplinkWithOverrideTimeout(path: any, timeout: any): Promise<any>;
  registerPushToken(token: any): void;
  setPushEnabled(enabled: any): void;
  sendEvent(name: any): void;
  sendEventWithString(name: any, data: any): void;
  sendEventWithDictionary(name: any, data: any): void;
  buildEventWithEventType(eventType: KochavaTrackerEventType): {
      send: () => void;
      setCustomStringValue: (key: string, value: string) => void;
      setCustomBoolValue: (key: string, value: boolean) => void;
      setCustomNumberValue: (key: string, value: number) => void;
      setAndroidGooglePlayReceipt: (data: any, signature: any) => void;
      setIosAppStoreReceipt: (base64String: any) => void;
      setAction: (value: any) => void;
      setBackground: (value: any) => void;
      setCheckoutAsGuest: (value: any) => void;
      setCompleted: (value: any) => void;
      setContentId: (value: any) => void;
      setContentType: (value: any) => void;
      setCurrency: (value: any) => void;
      setDate: (value: any) => void;
      setDescription: (value: any) => void;
      setDestination: (value: any) => void;
      setDuration: (value: any) => void;
      setEndDate: (value: any) => void;
      setItemAddedFrom: (value: any) => void;
      setLevel: (value: any) => void;
      setMaxRatingValue: (value: any) => void;
      setName: (value: any) => void;
      setOrderId: (value: any) => void;
      setOrigin: (value: any) => void;
      setPayload: (value: any) => void;
      setPrice: (value: any) => void;
      setQuantity: (value: any) => void;
      setRatingValue: (value: any) => void;
      setReceiptId: (value: any) => void;
      setReferralFrom: (value: any) => void;
      setRegistrationMethod: (value: any) => void;
      setResults: (value: any) => void;
      setScore: (value: any) => void;
      setSearchTerm: (value: any) => void;
      setSource: (value: any) => void;
      setSpatialX: (value: any) => void;
      setSpatialY: (value: any) => void;
      setSpatialZ: (value: any) => void;
      setStartDate: (value: any) => void;
      setSuccess: (value: any) => void;
      setUri: (value: any) => void;
      setUserId: (value: any) => void;
      setUserName: (value: any) => void;
      setValidated: (value: any) => void;
      setAdCampaignId: (value: any) => void;
      setAdCampaignName: (value: any) => void;
      setAdDeviceType: (value: any) => void;
      setAdGroupId: (value: any) => void;
      setAdGroupName: (value: any) => void;
      setAdMediationName: (value: any) => void;
      setAdNetworkName: (value: any) => void;
      setAdPlacement: (value: any) => void;
      setAdSize: (value: any) => void;
      setAdType: (value: any) => void;
  };
  buildEventWithEventName(eventName: string): {
      send: () => void;
      setCustomStringValue: (key: string, value: string) => void;
      setCustomBoolValue: (key: string, value: boolean) => void;
      setCustomNumberValue: (key: string, value: number) => void;
      setAndroidGooglePlayReceipt: (data: any, signature: any) => void;
      setIosAppStoreReceipt: (base64String: any) => void;
      setAction: (value: any) => void;
      setBackground: (value: any) => void;
      setCheckoutAsGuest: (value: any) => void;
      setCompleted: (value: any) => void;
      setContentId: (value: any) => void;
      setContentType: (value: any) => void;
      setCurrency: (value: any) => void;
      setDate: (value: any) => void;
      setDescription: (value: any) => void;
      setDestination: (value: any) => void;
      setDuration: (value: any) => void;
      setEndDate: (value: any) => void;
      setItemAddedFrom: (value: any) => void;
      setLevel: (value: any) => void;
      setMaxRatingValue: (value: any) => void;
      setName: (value: any) => void;
      setOrderId: (value: any) => void;
      setOrigin: (value: any) => void;
      setPayload: (value: any) => void;
      setPrice: (value: any) => void;
      setQuantity: (value: any) => void;
      setRatingValue: (value: any) => void;
      setReceiptId: (value: any) => void;
      setReferralFrom: (value: any) => void;
      setRegistrationMethod: (value: any) => void;
      setResults: (value: any) => void;
      setScore: (value: any) => void;
      setSearchTerm: (value: any) => void;
      setSource: (value: any) => void;
      setSpatialX: (value: any) => void;
      setSpatialY: (value: any) => void;
      setSpatialZ: (value: any) => void;
      setStartDate: (value: any) => void;
      setSuccess: (value: any) => void;
      setUri: (value: any) => void;
      setUserId: (value: any) => void;
      setUserName: (value: any) => void;
      setValidated: (value: any) => void;
      setAdCampaignId: (value: any) => void;
      setAdCampaignName: (value: any) => void;
      setAdDeviceType: (value: any) => void;
      setAdGroupId: (value: any) => void;
      setAdGroupName: (value: any) => void;
      setAdMediationName: (value: any) => void;
      setAdNetworkName: (value: any) => void;
      setAdPlacement: (value: any) => void;
      setAdSize: (value: any) => void;
      setAdType: (value: any) => void;
  };
}

// manually extracted from local-plugins/KochavaTrackerCordova/www/KochavaTrackerEventType.js
export enum KochavaTrackerEventType {
  Achievement =         'Achievement',
  AddToCart =           'Add to Cart',
  AddToWishList =       'Add to Wish List',
  CheckoutStart =       'Checkout Start',
  LevelComplete =       'Level Complete',
  Purchase =            'Purchase',
  Rating =              'Rating',
  RegistrationComplete ='Registration Complete',
  Search =              'Search',
  TutorialComplete =    'Tutorial Complete',
  View =                'View',
  AdView =              'Ad View',
  PushReceived =        'Push Received',
  PushOpened =          'Push Opened',
  ConsentGranted =      'Consent Granted',
  Deeplink =            '_Deeplink',
  AdClick =             'Ad Click',
  StartTrial =          'Start Trial',
  Subscribe =           'Subscribe'
};
