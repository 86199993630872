import { Action } from '@ngrx/store';
import { AllowedFlags } from './flags.reducer';

// flags actions

export const SET_FLAG = '[flags] Set';
export const UNSET_FLAG = '[flags] Unset';

export class SetFlag implements Action {
  readonly type = SET_FLAG;

  constructor(public payload: AllowedFlags) {

  }
}

export class UnsetFlag implements Action {
  readonly type = UNSET_FLAG;

  constructor(public payload: AllowedFlags) {

  }
}

export type FlagActions =
  | SetFlag
  | UnsetFlag;
