import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/state.reducer';
import { InAppMessage } from '../../store/normalized/schemas/my-coach.schema';
import { Subscription } from 'rxjs';
import { ClarityConfig } from '../../config/clarity.config';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { MarkInAppMessageAsRead } from '../../store/session/actions/iridium.actions';

@Component({
  selector: 'app-inapp-message',
  templateUrl: './inapp-message.html',
  styleUrls: ['./inapp-message.scss']
})
export class InAppMessagePage {
  @Input() inAppMessage: InAppMessage = null;

  public acceptedHtmlTags = ['div', 'p', 'span', 'b', 'i', 'a', 'img', 'video', 'br', 'strong',
    'li', 'ol', 'ul', 'u', 'blockquote', 'em', 'strong', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

  subscription: Subscription;
  modal;

  constructor(
    private store: Store<State>,
    public config: ClarityConfig
  ) {
  }

  ionViewWillLeave() {
    this.store.dispatch(new MarkInAppMessageAsRead(this.inAppMessage.id));
  }

  closeModal() {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }

}
