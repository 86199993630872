import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { getIridiumToken } from '../../../store/sensitive/selectors/auth.selectors';
import * as syncActions from '../../../store/session/actions/sync.actions';
import { IridiumProvider } from 'src/app/services/analytics/iridium/iridium.provider';
import { isJWTTokenValid } from 'src/app/utils/is-jwt-token-valid';
import { SessionState } from 'src/app/store/session';
import { SyncState } from 'src/app/store/session/reducers/sync.reducer';
import { getIridiumTokenSyncing } from 'src/app/store/session/selectors/sync.selectors';

@Injectable({providedIn: 'root'})
export class IridiumAuthorizationInterceptor implements HttpInterceptor {
  private iridiumTokenSyncing$ = this.store.select(getIridiumTokenSyncing);
  private iridiumTokenSyncingLocalState$ = new BehaviorSubject<boolean>(false);
  private iridiumToken$ = this.store.select(getIridiumToken);
  private iridiumToken: string = null;

  constructor(
    private store: Store<SessionState | SyncState>,
    private iridiumProvider: IridiumProvider
  ) {
    this.iridiumToken$
      .subscribe((token) => {
        this.iridiumToken = token;
      });

    this.iridiumTokenSyncing$.subscribe(this.iridiumTokenSyncingLocalState$);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('{iridium}')) {
      return this.handleIridiumRequest(request, next);
    }

    // handled by clarion-authorization interceptor
    return next.handle(request);
  }

  handleIridiumRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isTokenRequest(request)) {
      return this.handleTokenRequest(request, next);
    } else if (this.iridiumTokenSyncingLocalState$.value) {
      return this.retryRequestWithNewToken(request, next);
    } else if (this.isTokenInvalidOrMissing()) {
      return this.getNewTokenThenRetryRequest(request, next);
    }

    return this.handleNormalRequest(request, next);
  }

  isTokenRequest(request: HttpRequest<any>) {
    return request.url === this.iridiumProvider.tokenEndpoint;
  }

  handleTokenRequest(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(catchError(_ => throwError('Iridium token request failed.')));
  }

  /**
   * Wait until the syncing is complete and use the new token in the request
   */
  retryRequestWithNewToken(request: HttpRequest<any>, next: HttpHandler) {
    return combineLatest([this.iridiumToken$, this.iridiumTokenSyncingLocalState$]).pipe(
      filter(([_, isSyncing]) => !isSyncing),
      take(1),
      switchMap(([freshToken, _]) => this.handleNormalRequest(request, next, freshToken))
    );
  }

  handleNormalRequest(request: HttpRequest<any>, next: HttpHandler, iridiumToken = this.iridiumToken) {
    return next.handle(this.addIridiumAuthorizationHeader(request, iridiumToken))
      .pipe(
        switchMap(event => this.handleSilentError(event, request, next)),
        catchError(error => this.handleError(error, request, next))
      );
  }

  handleSilentError(event: HttpEvent<any>, request: HttpRequest<any>, next: HttpHandler) {
    // custom iridium silent errors
    if (event instanceof HttpResponse && event?.body?.error === true && event?.body?.statusCode === 401) {
      return this.getNewTokenThenRetryRequest(request, next);
    }

    return of(event);
  }

  handleError(error: any, request: HttpRequest<any>, next: HttpHandler) {
    if (error instanceof HttpErrorResponse && error.status === 401) {
      return this.getNewTokenThenRetryRequest(request, next);
    }

    return throwError(error);
  }

  isTokenInvalidOrMissing() {
    return !this.iridiumToken || !isJWTTokenValid(this.iridiumToken);
  }

  getNewTokenThenRetryRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // set isSyncing locally too because with multiple requests the reducer might not happen fast enough
    this.iridiumTokenSyncingLocalState$.next(true);
    this.store.dispatch(new syncActions.SyncIridiumToken());

    return this.retryRequestWithNewToken(request, next);
  }

  addIridiumAuthorizationHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
