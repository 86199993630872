import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { State } from '../../store/state.reducer';

import { WizardService } from './wizard.service';
import { take, tap } from 'rxjs/operators';
import { StressTest } from '../../store/session/models/stress-test.model';
import * as toolsActions from '../../store/session/actions/tools.actions';
import { stressMeterActive } from '../../store/session/selectors/tools.selectors';
import { getSymptoms } from '../../store/normalized/selectors/symptoms.selectors';
import { DatePipe } from '@angular/common';
import { LoggerService } from '../logger.service';
import { AfterCompleteLessonOptions } from 'src/app/store/session/actions/program.actions';
import { CloseLastModal } from 'src/app/store/session/actions/navigation.actions';

const initialModel = {
  exercise_id: 0,
  date: null,
  recorded_at: null,
  feel_now: null,
  body_part_id: null,
  body_side_id: null,
  sensation_id: null,
  sensation_level: null,
  since_eat: 0,
  symptom_ids: []
};

@Injectable({providedIn: 'root'})
export class StressTestService extends WizardService {

  protected model: StressTest;

  private symptomsChoice = [];
  private symptoms;

  constructor(
    protected store: Store<State>,
    protected popoverCtrl: PopoverController,
    protected translate: TranslateService,
    protected logger: LoggerService
  ) {
    super(store, popoverCtrl, translate, logger);
  }

  initModel() {
    this.model = {...initialModel};

    return this.store.select(getSymptoms)
      .pipe(
        take(1),
        tap(symptoms => {
          this.symptoms = symptoms;
        })
      )
      .toPromise();
  }

  getFeelingsList() {
    return this.symptoms;
  }

  setExercise(id: number) {
    this.model.exercise_id = id;
  }

  setSinceEat(hours: number) {
    this.model.since_eat = hours;
  }

  setFeelNow(feelNow: number) {
    this.model.feel_now = feelNow / 100;
  }

  getFeelNow() {
    return this.model.feel_now * 100;
  }

  setFeelingChoice(symptoms) {
    this.symptomsChoice = symptoms;
    this.model.symptom_ids = symptoms.map(symptom => symptom.id);
  }

  getFeelingChoice() {
    return this.symptomsChoice;
  }

  getSymptoms() {
    return this.symptoms;
  }

  calculateTotals(): any {
    const total = {
      stress: 0, hunger: 0, habit: 0
    };
    const symptoms = this.getSymptoms();
    symptoms.forEach((symptom) => {
      this.symptomsChoice.forEach(feeling => {
        if (feeling.title === symptom.name) {
          total.stress += symptom.stress ? 1 : 0;
          total.hunger += symptom.hunger ? 1 : 0;
          total.habit += symptom.habit ? 1 : 0;
        }
      });
    });

    return total;
  }

  getDiagnose() {
    const total = this.calculateTotals();
    let diagnose;
    if (total.stress === total.hunger) {
      diagnose = {
        text: 'wizards.diagnoses.stressed_bored_hungry',
        tags: ['stress']
      };
    } else {
      if (total.hunger > total.stress && total.hunger > total.habit) {
        diagnose = {
          text: 'wizards.diagnoses.hungry', tags: ['mindful']
        };
      } else {
        diagnose = {
          text: 'wizards.diagnoses.stressed', tags: ['rain']
        };
      }
    }

    return diagnose;
  }

  save(afterCompleteOptions: AfterCompleteLessonOptions) {
    const datePipe = new DatePipe('en-US');
    this.model.date = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.model.recorded_at = new Date().toISOString();

    this.store.select(stressMeterActive)
      .pipe(take(1))
      .subscribe((isStressMeterActive) => {
        // @todo isStressMeterActive and use afterCompleteOptions, or refactor both to use a more general approach
        // as something like `SessionState.toolsNavigation: [{ tool: 'stress-test', afterCompleteStrategy: 'closeLastModal' }]`
        if (isStressMeterActive) {
          this.store.dispatch(new toolsActions.ReturnStressMeter(this.model.exercise_id));
        } else if (afterCompleteOptions && afterCompleteOptions.skipInterstitial) {
          this.store.dispatch(new toolsActions.SaveStressTest(this.model)); // saves the test but doesnt perform any navigation
          this.store.dispatch(new CloseLastModal());
        } else {
          this.store.dispatch(new toolsActions.CompleteStressTest(this.model)); // saves the test AND perform navigation
        }
      });
  }

}
