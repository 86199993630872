import { Component, Output, EventEmitter, OnInit } from '@angular/core';

import { SettingsComponent } from './settings.component';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { slideInOut } from '@mindsciences/utils';
import { Store } from '@ngrx/store';
import { SessionState } from 'src/app/store/session';
import { getAllowHealthFitIntegration } from 'src/app/store/normalized/selectors/user.selectors';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cl-account-weight',
  styleUrls: ['weight-track.component.scss'],
  animations: [slideInOut],
  template: `
    <div class="account-weight-container" [@state]="visibility" (@state.done)="animationDone($event)">
        <cl-account-weight-track-ern *ngIf="weightComponentType === 'ern'"></cl-account-weight-track-ern>
        <cl-account-weight-track-dpp *ngIf="weightComponentType === 'dpp'"></cl-account-weight-track-dpp>
        <cl-account-weight-track-healthfit *ngIf="weightComponentType === 'ern_healthfit'"></cl-account-weight-track-healthfit>
    </div>`
})

export class WeightTrackComponent implements SettingsComponent, OnInit {
  @Output() endAnimation = new EventEmitter();
  visibility = 'visible';
  weightComponentType: 'ern' | 'dpp' | 'ern_healthfit' = null;

  private allowHealthFitIntegration$ = this.store.select(getAllowHealthFitIntegration);

  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig
  ) { }

  ngOnInit() {
    this.allowHealthFitIntegration$.pipe(take(1)).subscribe(allowHealthFitIntegration => {
      if(this.config.programDPPorWL()) {
        this.weightComponentType = 'dpp';
      } else if(allowHealthFitIntegration) {
        this.weightComponentType = 'ern_healthfit';
      } else {
        this.weightComponentType = 'ern';
      }
    });
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }
}
