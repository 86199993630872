import { Injectable } from '@angular/core';

import { HttpProvider } from './http/http.provider';
import { HttpHeaders } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class ListItemsProvider {

  private listItemsEndpoint = '/list_items?type=';

  // TODO Temporary fix related to CLARITY-699
  // This fix is set up to check if the origin of the issue is the caching of these requests.
  // Related slack thread : https://mindsciencesinc.slack.com/archives/GCLLCKT18/p1644488155290989
  private noCacheHeader = {
    headers: new HttpHeaders({
      'Cache-Control': 'no-cache'
    })
  };


  constructor(
    private http: HttpProvider
  ) {

  }

  public loadFeelings() {
    return this.http.get(this.listItemsEndpoint + 'feeling', this.noCacheHeader);
  }

  public loadTriggers() {
    return this.http.get(this.listItemsEndpoint + 'trigger', this.noCacheHeader);
  }

  public loadGoals() {
    return this.http.get(this.listItemsEndpoint + 'goal', this.noCacheHeader);
  }

  public loadSensations() {
    return this.http.get(this.listItemsEndpoint + 'sensation', this.noCacheHeader);
  }

  public loadBodyParts() {
    return this.http.get(this.listItemsEndpoint + 'body_part', this.noCacheHeader);
  }

  public loadBodySides() {
    return this.http.get(this.listItemsEndpoint + 'body_side', this.noCacheHeader);
  }

}
