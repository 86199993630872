import * as iridiumActions from '../actions/iridium.actions';
import { InAppMessage } from '../../normalized/schemas/my-coach.schema';

export interface IridiumState {
  activated: boolean;
  inAppMessages: InAppMessage[];
}

export const initialIridiumState: IridiumState = {
  activated: false,
  inAppMessages: []
};

export function iridiumReducer(state = initialIridiumState, action: iridiumActions.IridiumActions): IridiumState {
  switch (action.type) {
    case iridiumActions.ACTIVATE:
      return {
        ...state,
        activated: true
      };

    case iridiumActions.DEACTIVATE:
      return {
        ...state,
        activated: false
      };

    case iridiumActions.MARK_INAPP_MESSAGE_AS_READ_SUCCESS:
      const readMessageId = action.payload;

      return {
        ...state,
        inAppMessages: state.inAppMessages.filter((message) => message.id !== readMessageId)
      };

    case iridiumActions.LOAD_INAPP_MESSAGES:
      return {
        ...state,
        inAppMessages: []
      };

    case iridiumActions.LOAD_INAPP_MESSAGES_SUCCESS:
      const messages = action.payload;

      return {
        ...state,
        inAppMessages: state.inAppMessages.concat(messages)
      };
  }

  return state;
}

export const inAppMessages = (state: IridiumState) => state.inAppMessages;
export const isActivated = (state: IridiumState) => state.activated;
