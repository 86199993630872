import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { addMinutes, isSameDay, parseJSON, subDays } from 'date-fns';

@Pipe({
  name: 'weightInfoDate'
})
export class WeightInfoDatePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private translate: TranslateService
  ) {}

  transform(value: string, ...args: any[]): string {
    const now = new Date();
    const date = parseJSONToUTC(value, now);

    if (isSameDay(date, now)) {
      return this.translate.instant('dates.today');
    } else if (isSameDay(date, subDays(now, 1))) {
      return this.translate.instant('dates.yesterday');
    } else {
      return this.datePipe.transform(value, ...args);
    }
  }
}

/**
 * The date-fns `parseJSON` parses a string to a date in UTC time, and the resulting Date object uses the browser's timezone by default.
 * This can cause unwanted problems when other functions of the date-fns library are used.
 *
 * For instance `parseJSON('2020-12-31 23:59:59')` returns
 *  - '01/01/2021, 00:59:59' in Europe/Budapest
 *  - '12/31/2020, 6:59:59 PM' in America/New_York.
 *
 * This is theoretically correct, but results in `isSameDay(new Date(), parseJSON('2020-12-31 23:59:59'))`
 * returning `false` on 31st December, 2021 in a browser located in Budapest, which is sometimes not what we want.
 *
 * This can occur when data is imported from the Fitbit API, where a value added with a given timestamp will not be converted to UTC ever.
 *
 * This function corrects this by moving the parsed timestamp to the browser's timezone.
 * For example `parseJSONToUTC('2020-12-31 23:59:59').toLocaleString()` will return
 *  - '12/31/2021, 23:59:59' in Europe/Budapest
 *  - '12/31/2021, 23:59:59' in America/New_York,
 *
 * and `isSameDay(new Date(), parseJSONToUTC('2020-12-31 23:59:59'))` will always return `true` on 31st December, 2021 in any timezone.
 *
 * @param dateString
 * @returns
 */
export function parseJSONToUTC(dateString: string, now: Date) {
  return addMinutes(parseJSON(dateString), now.getTimezoneOffset());
};
