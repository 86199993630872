import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BonusExercise, Exercise } from '../store/normalized/schemas/exercise.schema';

import { HttpProvider } from './http/http.provider';
import { OfflineHttpProvider } from './http/offline-http.provider';

@Injectable({providedIn: 'root'})
export class ExercisesProvider {

  private readonly exercisesEndpoint = '/exercises';
  private readonly exercisesBonusEndpoint = '/bonus_exercises';
  private readonly breathsEndpoint = '/breaths';

  constructor(
    protected http: HttpProvider,
    private offlineHttp: OfflineHttpProvider
  ) {
  }

  public loadExercises(): Observable<Exercise[]> {
    const endpoint = `${this.exercisesEndpoint}`;

    return this.http.get(endpoint);
  }

  public loadExercisesUpdatedAfter(timestamp: string): Observable<Exercise[]> {
    if (!timestamp) {
      console.warn('WARNING! Calling loadExercisesUpdatedAfter without timestamp');
    }

    const endpoint = `${this.exercisesEndpoint}?updated_after=${timestamp}`;

    return this.http.get(endpoint);
  }

  public loadExercisesByTag(tag: string): Observable<Exercise[]> {
    const endpoint = `${this.exercisesEndpoint}/by_tag?tag=${tag}`;

    return this.http.get(endpoint);
  }

  public loadBonusExercises(): Observable<BonusExercise[]> {
    const endpoint = this.exercisesBonusEndpoint;

    return this.http.get(endpoint);
  }

  public trackBreath(data) {
    return this.offlineHttp.post(this.breathsEndpoint, data);
  }
}
