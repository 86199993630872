// subscriptions actions

import { Action } from '@ngrx/store';
import { LiveSubscription } from '../../normalized/selectors/subscription.selectors';

// restore/purchase actions
export const SUBSCRIPTION_PURCHASED = '[subscription] Purchased';
export const SUBSCRIPTION_RESTORED = '[subscription] Restored';
export const SUBSCRIPTION_LICENSED = '[subscription] Licensed';

// validated
export const SUBSCRIPTION_ACTIVATED = '[subscription] Activated';

// recheck - periodically
export const SUBSCRIPTION_RECHECK = '[subscription] Recheck';

// cancel
export const CANCEL_SUBSCRIPTION = '[subscription] Cancel';

// stripe plans
export const LOAD_STRIPE_PLANS = '[subscription] Load Stripe Plans';
export const LOAD_STRIPE_PLANS_SUCCESS = '[subscription] Load Stripe Plans Success';
export const LOAD_STRIPE_PLANS_FAIL = '[subscription] Load Stripe Plans Fail';

export const CHECK_PROGRAM_CODE = '[subscription] Check Program Code';
export const RESET_PROGRAM_CODE = '[subscription] Reset Program Code';

export const APPLY_COUPON_CODE = '[subscription] Apply Coupon Code';
export const REDEEM_LICENSE_CODE = '[subscription] Redeem License Code';

export class SubscriptionPurchased implements Action {
  readonly type = SUBSCRIPTION_PURCHASED;

  constructor(public payload: any) {
  }
}

export class SubscriptionRestored implements Action {
  readonly type = SUBSCRIPTION_RESTORED;

  constructor(public payload: LiveSubscription) {
  }
}

export class SubscriptionLicensed implements Action {
  readonly type = SUBSCRIPTION_LICENSED;

  constructor(public payload: any) {}
}

export class SubscriptionActivated implements Action {
  readonly type = SUBSCRIPTION_ACTIVATED;

  constructor(public payload: LiveSubscription = null) {
  }
}

export class SubscriptionRecheck implements Action {
  readonly type = SUBSCRIPTION_RECHECK;
}

export class CancelSubscription implements Action {
  readonly type = CANCEL_SUBSCRIPTION;
}

export class LoadStripePlans implements Action {
  readonly type = LOAD_STRIPE_PLANS;
}

export class LoadStripePlansSuccess implements Action {
  readonly type = LOAD_STRIPE_PLANS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadStripePlanFail implements Action {
  readonly type = LOAD_STRIPE_PLANS_FAIL;

  constructor(public payload: any) {
  }
}

export class CheckProgramCode implements Action {
  readonly type = CHECK_PROGRAM_CODE;

  constructor(public payload: string) {
  }
}

export class ResetProgramCode implements Action {
  readonly type = RESET_PROGRAM_CODE;
}

export class ApplyCouponCode implements Action {
  readonly type = APPLY_COUPON_CODE;

  constructor(public payload: string) {
  }
}

export class RedeemLicenseCode implements Action {
  readonly type = REDEEM_LICENSE_CODE;

  constructor(public payload: string, public closeModals = false) {}
}

export type SubscriptionActions =
  | SubscriptionPurchased
  | SubscriptionRestored
  | SubscriptionLicensed
  | SubscriptionActivated
  | SubscriptionRecheck
  | CancelSubscription
  | LoadStripePlans
  | LoadStripePlansSuccess
  | LoadStripePlanFail
  | CheckProgramCode
  | ResetProgramCode
  | ApplyCouponCode
  | RedeemLicenseCode;
