import { createSchemaSelectors } from 'ngrx-normalizr';

import {
  CravingTool,
  cravingToolSchema
} from '../schemas/craving-tool.schema';
import { createSelector } from '@ngrx/store';

const cravingToolSchemaSelectors = createSchemaSelectors<CravingTool>(cravingToolSchema);

const getCravingTools = cravingToolSchemaSelectors.getEntities;

export const getCurrentCravingTool = createSelector(
  getCravingTools,
  (cravingTools): CravingTool => {
    if (!cravingTools || cravingTools.length === 0) {
      return null;
    }

    return cravingTools[0];
  }
);
