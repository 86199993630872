import { ChangeDetectorRef, Component, AfterViewInit, HostBinding, Output, EventEmitter } from '@angular/core';

import { SettingsComponent } from './settings.component';
import { ClarityConfig } from '../../../config/clarity.config';
import { SmokingTypeService } from 'src/app/services/smoking-type.service';
import { slideInOut } from '@mindsciences/utils';

const graphTypes = {
  ACTIVITY_GRAPH: 'activity-graph',
  DAILY_GRAPH: 'daily-graph',
  AWARENESS_GRAPH: 'awareness-graph',
  WEEKLY_GRAPH: 'weekly-graph',
  CIGARETTES_GRAPH: 'cigarettes-graph'
};

@Component({
  selector: 'cl-my-stats',
  styleUrls: ['my-stats.component.scss'],
  animations: [slideInOut],
  template: `
    <div class="my-stats-container" [@state]="visibility"
    (@state.done)="animationDone($event)" [@.disabled]="!config.isWebApp">
      <ion-row *ngIf="config.program.programCode === 'ern'" class="switch-buttons-row">
        <ion-col class="ion-text-center">
          <ion-button expand="block" size="small"
                  [color]="!activitySelected ? 'white' : 'action'"
                  [ngClass]="{ 'button-active' : activitySelected, 'button-inactive': !activitySelected }"
                  (click)="showActivity()">
            <b> {{ 'my-stats.activity' | translate }}</b>
          </ion-button>
        </ion-col>

        <ion-col size="1"></ion-col>

        <ion-col class="ion-text-center">
          <ion-button expand="block" size="small"
                  [color]="!awarenessSelected ? 'white' : 'action'"
                  [ngClass]="{ 'button-active' : awarenessSelected, 'button-inactive': !awarenessSelected }"
                  (click)="showAwareness()">
            <b> {{ 'my-stats.awareness' | translate }}</b>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="config.program.programCode === 'ua'" class="switch-buttons-row">
        <ion-col class="ion-text-center">
            <ion-button expand="block" size="small"
                  [color]="!activitySelected ? 'white' : 'action'"
                  [ngClass]="{ 'button-active' : activitySelected, 'button-inactive': !activitySelected }"
                  (click)="showActivity()">
            <b> {{ 'my-stats.activity' | translate }}</b>
          </ion-button>
        </ion-col>


        <ion-col class="ion-text-center">
          <ion-button expand="block" size="small"
                  [color]="!weeklySelected ? 'white' : 'action'"
                  [ngClass]="{ 'button-active' : weeklySelected, 'button-inactive': !weeklySelected  }"
                  (click)="showWeekly()">
            <b> {{ 'my-stats.anxiety' | translate }}</b>
          </ion-button>
        </ion-col>


        <ion-col class="ion-text-center">
          <ion-button expand="block" size="small"
                  [color]="!awarenessSelected ? 'white' : 'action'"
                  [ngClass]="{ 'button-active' : awarenessSelected, 'button-inactive': !awarenessSelected }"
                  (click)="showAwareness()">
            <b> {{ 'my-stats.awareness' | translate }}</b>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="config.program.programCode === 'ctq'" class="switch-buttons-row">
        <ion-col class="ion-text-center">
          <ion-button expand="block" size="small"
                  [color]="!cigarettesSelected ? 'white' : 'action'"
                  [ngClass]="{ 'button-active' : cigarettesSelected, 'button-inactive': !cigarettesSelected }"
                  (click)="showCigarettesGraph()">
            <b> {{ getButtonLabel() | translate }}</b>
          </ion-button>
        </ion-col>


        <ion-col class="ion-text-center">
          <ion-button expand="block" size="small"
                  [color]="!activitySelected ? 'white' : 'action'"
                  [ngClass]="{ 'button-active' : activitySelected, 'button-inactive': !activitySelected }"
                  (click)="showActivity()">
            <b> {{ 'my-stats.activity' | translate }}</b>
          </ion-button>
        </ion-col>
      </ion-row>

      <cl-activity-graph *ngIf="activitySelected" [controls]="true"></cl-activity-graph>

      <cl-daily-graph *ngIf="dailySelected" [controls]="true"></cl-daily-graph>

      <cl-weekly-graph *ngIf="weeklySelected" [controls]="true"></cl-weekly-graph>

      <cl-awareness-graph *ngIf="awarenessSelected" [controls]="true"></cl-awareness-graph>

      <cl-cigarettes-graph *ngIf="cigarettesSelected" [controls]="true"></cl-cigarettes-graph>

      <div class="cigarettes-stats-container" *ngIf="cigarettesSelected">
        <cl-cigarette-stats></cl-cigarette-stats>
      </div>
    </div>
  `
})
export class MyStatsComponent implements SettingsComponent, AfterViewInit {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  @Output() endAnimation = new EventEmitter();
  visibility = 'visible';

  public view = graphTypes.ACTIVITY_GRAPH;

  constructor(
    private cdr: ChangeDetectorRef,
    public config: ClarityConfig,
    private smokingTypeService: SmokingTypeService
  ) {
    if (config.isERN() || config.isUA()) {
      this.view = graphTypes.ACTIVITY_GRAPH;
    }

    if (config.isCTQ()) {
      this.view = graphTypes.CIGARETTES_GRAPH;
    }
  }

  get activitySelected() {
    return this.view === graphTypes.ACTIVITY_GRAPH;
  }

  get awarenessSelected() {
    return this.view === graphTypes.AWARENESS_GRAPH;
  }

  get dailySelected() {
    return this.view === graphTypes.DAILY_GRAPH;
  }

  get weeklySelected() {
    return this.view === graphTypes.WEEKLY_GRAPH;
  }

  get cigarettesSelected() {
    return this.view === graphTypes.CIGARETTES_GRAPH;
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }

  ngAfterViewInit() {
    this.refresh();
  }

  showActivity() {
    this.view = graphTypes.ACTIVITY_GRAPH;

    try {
      this.cdr.detectChanges();
    } catch (error) {
      // console.log('cannot detect changes')
    }
  }

  showAwareness() {
    this.view = graphTypes.AWARENESS_GRAPH;

    try {
      this.cdr.detectChanges();
    } catch (error) {
      // console.log('cannot detect changes')
    }
  }

  showDaily() {
    this.view = graphTypes.DAILY_GRAPH;

    try {
      this.cdr.detectChanges();
    } catch (error) {
      // console.log('cannot detect changes')
    }
  }

  showWeekly() {
    this.view = graphTypes.WEEKLY_GRAPH;

    try {
      this.cdr.detectChanges();
    } catch (error) {
      // console.log('cannot detect changes')
    }
  }

  getButtonLabel() {
    return this.smokingTypeService.isVaping() ? 'my-stats.vaping' : 'my-stats.cigarettes';
  }

  showCigarettesGraph() {
    this.view = graphTypes.CIGARETTES_GRAPH;

    try {
      this.cdr.detectChanges();
    } catch (error) {
      // console.log('cannot detect changes')
    }
  }

  refresh() {
    try {
      this.cdr.detectChanges();
    } catch (error) {
      // console.log('cannot detect changes')
    }
  }
}
