import { OnboardingCustomSettings } from '../store/session/actions/navigation.actions';

// WARNING: the order in here is linked to the Welcome Screens section in Account > Help
export const onboardingTypes: { [key: string]: Partial<OnboardingCustomSettings> & { program?: string; type?: string } } = {
  locked_reason: {
    size: 'full',
    header: '',
    body: 'interstitials.module_locked_reason',
    button: 'onboarding.ok',
    program: ''
  },
  ctq_home: {
    size: 'half',
    header: 'onboarding.welcome_to_dashboard_ctq',
    body: 'onboarding.ctq_home_intro',
    button: 'onboarding.lets_get_started',
    program: 'ctq'
  },
  ern_dashboard: {
    size: 'half',
    header: 'onboarding.welcome_to_dashboard',
    body: 'onboarding.ern_dashboard_intro',
    button: 'onboarding.lets_get_started',
    program: 'ern',
    dppwlProgram: false
  },
  dppwl_dashboard: {
    size: 'half',
    header: 'onboarding.welcome_to_dashboard',
    body: 'onboarding.dppwl_dashboard_intro',
    button: 'onboarding.lets_get_started',
    program: 'ern',
    dppwlProgram: true
  },
  ua_dashboard: {
    size: 'half',
    header: 'onboarding.welcome_to_dashboard',
    body: 'onboarding.ua_dashboard_intro',
    button: 'onboarding.lets_get_started',
    program: 'ua'
  },
  ern_home: {
    size: 'half',
    header: 'onboarding.welcome_to_dashboard',
    body: 'onboarding.ern_dashboard_intro',
    button: 'onboarding.lets_get_started',
    program: 'ern',
    dppwlProgram: false
  },
  program_lessons: {
    size: 'half',
    header: 'onboarding.welcome_to_lessons',
    body: 'onboarding.lessons_intro',
    button: 'onboarding.lets_get_started'
  },
  program_extended: {
    size: 'half',
    header: 'myjourney.program_extended_successfully',
    body: 'onboarding.program_extended_intro',
    button: 'onboarding.got_it'
  },
  ctq_lessons: {
    size: 'half',
    header: 'onboarding.ctq_welcome_lessons',
    body: 'onboarding.ctq_lessons_intro',
    button: 'onboarding.got_it',
    program: 'ctq'
  },
  ern_lessons: {
    size: 'half',
    header: 'onboarding.ctq_welcome_lessons',
    body: 'onboarding.ctq_lessons_intro',
    link: 'onboarding.got_it',
    program: 'ern'
  },
  ua_lessons: {
    size: 'half',
    header: 'onboarding.ctq_welcome_lessons',
    body: 'onboarding.ctq_lessons_intro',
    link: 'onboarding.got_it',
    program: 'ua'
  },
  ctq_tools_cig: {
    size: 'half',
    header: 'onboarding.ctq_welcome_tools',
    body: 'onboarding.ctq_tools_intro_cig',
    button: 'onboarding.got_it',
    program: 'ctq'
  },
  ctq_tools_puff: {
    size: 'half',
    header: 'onboarding.ctq_welcome_tools',
    body: 'onboarding.ctq_tools_intro_puff',
    button: 'onboarding.got_it',
    program: 'ctq'
  },
  ern_tools: {
    size: 'half',
    header: 'onboarding.welcome_to_tools',
    body: 'onboarding.exercises_intro',
    link: 'onboarding.all_set',
    program: 'ern'
  },
  ua_tools: {
    size: 'half',
    header: 'onboarding.welcome_to_tools',
    body: 'onboarding.exercises_intro',
    link: 'onboarding.all_set',
    program: 'ua'
  },
  ctq_inbox: {
    size: 'third',
    header: 'onboarding.ctq_welcome_inbox',
    body: 'onboarding.ctq_inbox_intro',
    button: 'onboarding.got_it',
    program: 'ctq'
  },
  ern_community: {
    size: 'half',
    header: 'onboarding.welcome_to_community',
    body: 'onboarding.community_intro',
    button: 'onboarding.lets_get_started',
    program: 'ern'
  },
  ua_community: {
    size: 'half',
    header: 'onboarding.welcome_to_community',
    body: 'onboarding.community_intro',
    button: 'onboarding.lets_get_started',
    program: 'ua'
  },
  ctq_account: {
    size: 'third',
    header: 'onboarding.ctq_welcome_account',
    body: 'onboarding.ctq_account_intro',
    button: 'onboarding.got_it',
    program: 'ctq'
  },
  ern_account: {
    size: 'third',
    header: 'onboarding.account',
    body: 'onboarding.account_text',
    link: 'onboarding.all_set',
    program: 'ern'
  },
  ua_account: {
    size: 'third',
    header: 'onboarding.account',
    body: 'onboarding.account_text',
    link: 'onboarding.all_set',
    program: 'ua'
  },
  // ctq_avatar: {
  //   size: 'full',
  //   header: 'onboarding.ctq_welcome_community_profile',
  //   body: 'onboarding.ctq_community_profile_intro',
  //   button: 'onboarding.i_agree'
  // },
  // this doesn't exist in CTQ
  // awarenessQuizCTQ: {
  //   size: 'half',
  //   header: 'onboarding.self_assessment',
  //   body: 'onboarding.self_assessment_text_ctq',
  //   button: 'onboarding.lets_get_started',
  //   program: 'ctq'
  // },
  checkin: {
    size: 'full',
    header: 'onboarding.welcome_to_check-in',
    body: 'onboarding.check-in_intro',
    button: 'onboarding.lets_get_started'
  },
  'stresstest-ua': {
    size: 'half',
    header: 'onboarding.welcome_to_stress-test',
    body: 'onboarding.stress-test_intro',
    button: 'onboarding.lets_get_started',
    program: 'ua'
  },
  'stresstest-ern': {
    size: 'half',
    header: 'onboarding.welcome_to_stress-test',
    body: 'onboarding.stress-test_intro',
    button: 'onboarding.lets_get_started',
    program: 'ern'
  },
  stressmeter: {
    size: 'half',
    header: 'onboarding.welcome_to_stress-meter',
    body: 'onboarding.stress-meter_intro',
    button: 'onboarding.lets_get_started',
    program: 'ua'
  },
  'wantometer-ern': {
    size: 'half',
    header: 'onboarding.welcome_to_wantometer',
    body: 'onboarding.wantometer_intro',
    button: 'onboarding.lets_get_started',
    program: 'ern'
  },
  // 'wantometer-ctq': {
  //   size: 'half',
  //   header: 'onboarding.welcome_to_wantometer',
  //   body: 'onboarding.wantometer_intro_ctq',
  //   button: 'onboarding.lets_get_started',
  //   link: 'onboarding.ready',
  //   program: 'ctq'
  // },
  cravingTool: {
    size: 'half',
    header: 'onboarding.welcome_to_craving_tool',
    body: 'onboarding.craving_tool_intro_ern',
    button: 'onboarding.lets_get_started',
    program: 'ern'
  },
  smokeCigCravingTool: {
    size: 'half',
    header: 'onboarding.welcome_to_wantometer',
    body: 'onboarding.craving_tool_intro_ctq_cig',
    button: 'onboarding.lets_get_started',
    program: 'ctq'
  },
  smokePuffCravingTool: {
    size: 'half',
    header: 'onboarding.welcome_to_wantometer',
    body: 'onboarding.craving_tool_intro_ctq_puff',
    button: 'onboarding.lets_get_started',
    program: 'ctq'
  },
  gears_review: {
    size: 'half',
    header: 'onboarding.welcome_gears_review',
    body: 'onboarding.gears_review_intro',
    button: 'onboarding.got_it'
  },
  introduceGears: {
    size: 'full',
    header: 'onboarding.gears-animation',
    body: 'onboarding.gears-animation_text',
    button: 'onboarding.skip'
  },
  awarenessQuizERN: {
    size: 'half',
    header: 'onboarding.self_assessment',
    body: 'onboarding.self_assessment_text_ern',
    button: 'onboarding.lets_get_started',
    program: 'ern'
  },
  awarenessQuizUA: {
    size: 'half',
    header: 'onboarding.self_assessment',
    body: 'onboarding.self_assessment_text_ua',
    button: 'onboarding.lets_get_started',
    program: 'ua'
  },
  cigarettesGraphWhatIsThis: {
    size: 'full',
    header: 'cigarettes_graph.help_title',
    body: 'cigarettes_graph.help_text',
    button: 'onboarding.ok',
    program: 'ctq'
  },
  vapingGraphWhatIsThis: {
    size: 'full',
    header: 'vaping_graph.help_title',
    body: 'vaping_graph.help_text',
    button: 'onboarding.ok',
    program: 'ctq'
  },
  activityGraphWhatIsThis: {
    size: 'full',
    header: 'activity_graph.help_title',
    body: 'activity_graph.help_text',
    button: 'onboarding.ok'
  },
  dailyGraphWhatIsThis: {
    size: 'full',
    header: 'daily_graph.help_title',
    body: 'daily_graph.help_text',
    button: 'onboarding.ok',
    program: 'ua'
  },
  weeklyGraphWhatIsThis: {
    size: 'full',
    header: 'weekly_graph.help_title',
    body: 'weekly_graph.help_text',
    button: 'onboarding.ok',
    program: 'ua'
  },
  awarenessGraphWhatIsThisERN: {
    size: 'full',
    header: 'awareness_graph.help_title',
    body: 'awareness_graph.help_text_ern',
    button: 'onboarding.ok',
    program: 'ern'
  },
  // awarenessGraphWhatIsThisCTQ: {
  //   size: 'full',
  //   header: 'awareness_graph.help_title',
  //   body: 'awareness_graph.help_text_ctq',
  //   button: 'onboarding.ok',
  //   program: 'ctq'
  // },
  awarenessGraphWhatIsThisUA: {
    size: 'full',
    header: 'awareness_graph.help_title',
    body: 'awareness_graph.help_text_ua',
    button: 'onboarding.ok',
    program: 'ua'
  },
  weeklyVideoCalls: {
    size: 'full',
    header: 'onboarding.weekly_video_calls',
    body: 'onboarding.weekly_video_calls_text',
    button: 'onboarding.ok'
  },
  program_restarted: {
    size: 'half',
    header: 'onboarding.welcome_back_to_core_modules',
    body: 'onboarding.program_restarted_intro',
    button: 'onboarding.lets_get_started'
  },
  worrytool: {
    size: 'half',
    header: 'onboarding.welcome_to_worry_tool',
    body: 'onboarding.worry_tool_intro',
    button: 'onboarding.lets_get_started',
    program: 'ua'
  },
  dppwlScale: {
    size: 'half',
    header: 'account_menu.weight.title',
    body: 'onboarding.dpp_my_weight_intro',
    button: 'shared.yes',
    cancelButton: 'shared.no',
    dontCompleteOnCancelClick: true,
    textAlignment: 'center',
    program: 'ern',
    hideInHelpScreen: true
  },
  dppwlWeightGoal: {
    size: 'half',
    header: 'weight_tracking.healthy_goal',
    body: 'weight_tracking.research_shows',
    button: 'weight_tracking.thats_great',
    dontCompleteOnButtonClick: true,
    program: 'ern'
  },
  custom: {}
};

export interface OnboardingCloseEvent {
  isComplete: boolean;
}
