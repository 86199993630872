import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import * as socialActions from '../../store/session/actions/social.actions';
import { BrowserService } from '../../services/browser.service';

@Component({
  selector: 'page-user-profile',
  styleUrls: ['user-profile.scss'],
  templateUrl: 'user-profile.html'
})
export class UserProfilePage implements OnInit, OnDestroy {
  user: any;

  constructor(
    private navParams: NavParams,
    private config: ClarityConfig,
    private store: Store<State>,
    private browser: BrowserService
  ) {
    const componentProps = this.navParams.get('componentProps');
    this.user = componentProps ? componentProps.user : null;
  }

  ngOnInit() {}

  get activeAdminLink() {
    return `${this.config.adminUrl}/activeadmin/users/${this.user.user_id}`;
  }

  get railsAdminLink() {
    return `${this.config.adminUrl}/admin/user/${this.user.user_id}`;
  }

  get iridiumLink() {
    const { iridiumApiUseSsl, iridiumApiHost, iridiumApiEndpointPrefix } = this.config.env;
    const protocol = iridiumApiUseSsl ? 'https://' : 'http://';

    return `${protocol}${iridiumApiHost}/users/external/${this.user.user_id}`;
  }

  ngOnDestroy() {}

  showUserPosts() {
    const modal = this.navParams.get('modal');
    this.store.dispatch(new navigationActions.CloseModal({ modalId: modal.id }));
    this.store.dispatch(new socialActions.SetPostsFilter(''));
    this.store.dispatch(new socialActions.SetPostsUserFilter({
      userId: this.user.id,
      username: this.user.username
    }));
  }

  openActiveAdmin() {
    this.browser.goTo(this.activeAdminLink);
  }

  openRailsAdmin() {
    this.browser.goTo(this.railsAdminLink);
  }

  openIridium() {
    this.browser.goTo(this.iridiumLink);
  }

}
