export enum AnalyticsEvents {
  AppLaunch = 'app_launch',
  PageView = 'pageview',
  SharedApp = 'shared-app',

  ClikedFreeTrial = 'clicked-free-trial',

  UserSignup = 'user-signup',
  UserLogin = 'user-login',
  UserLogout = 'user-logout',
  UserDeleteAccount = 'user-delete-account',

  NotificationOpened = 'notification-opened',

  SkippedIntroVideos = 'skipped-intro-videos',

  OpenedExercise = 'opened-exercise',
  CompletedExercise = 'completed-exercise',
  OpenedLesson = 'opened-lesson',
  CompletedLesson = 'completed-lesson',
  CompletedModule = 'completed-module',

  CustomWeekStarted = 'custom-week-started',
  CustomWeekReplaced = 'custom-week-replaced',

  CommunityClickedWeeklyCalls = 'community-clicked-weekly-calls',
  CommunityOpenedPost = 'community-opened-post',
  CommunityStartedJournal = 'community-started-journal',

  CompletedCheckin = 'completed-checkin',
  CompletedMorningMotivation = 'completed-morning-motivation',
  CompletedNightReflection = 'completed-night-reflection',

  CompletedStressTest = 'completed-stress-test',
  CompletedStressMeter = 'completed-stress-meter',
  CompletedWantOMeter = 'completed-want-o-meter',
  CompletedCravingTool = 'completed-craving-tool',
  CompletedAwarenessQuiz = 'completed-awareness-quiz',
  CompletedAnxietyQuiz = 'completed-anxiety-quiz',
  CompletedBreatheExercise = 'completed-breathe-exercise',

  UpdatedReminder = 'updated-reminder',
  SetupReminder = 'setup-reminder',

  UserActivity = 'user-activity',
  UserTriggeredSync = 'user-triggered-sync',

  AcceptedGetMobileAppPopup = 'accepted-get-mobile-app-popup',
  DismissedGetMobileAppPopup = 'dismissed-get-mobile-app-popup',

  CompletedErnExtensionOnboarding = 'completed-ern-extension-onboarding',
  CompletedErnExtensionAfterMatch = 'completed-ern-extension-after-match',

  CompletedDppOnboarding = 'completed-dpp-onboarding'
};

export enum SubscriptionEvents {
  Purchased = 'subscription-purchased',
  Restored = 'subscription-restored',
  Licensed = 'subscription-licensed',
  Canceled = 'subscription-canceled',
};
