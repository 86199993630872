import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/state.reducer';
import { ClarityConfig } from '../../config/clarity.config';
import { Observable } from 'rxjs';
import {
  allConversationsLoaded,
  getConversationCreation,
  getConversationLoaded,
  getConversationReaded,
  getConversationsFilter,
  getConversationsFromFeed,
  getConversationsLoading,
  getConversationSuppressionId,
  getUnreadCoachMessagesCount
} from '../../store/session/selectors/my-coach.selectors';
import {
  getCurrentUserProfile,
  isCurrentProfileCoach,
  isUserProfileComplete
} from '../../store/normalized/selectors/user-profile.selectors';
import { Conversation } from '../../store/normalized/schemas/my-coach.schema';
import {
  ChangeConversationsFilter,
  CloseConversation,
  DeleteConversation,
  LoadConversations,
  NewConversation,
  RefreshConversations,
  ResetConversationCreation,
  ResetConversationSuppression,
  ResetMarkConversationAsRead
} from '../../store/session/actions/my-coach.actions';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { animate, style, transition, trigger } from '@angular/animations';
import { UserProfile } from '../../store/normalized/schemas/user.schema';
import { getResumedAt } from '../../store/session/selectors/sync.selectors';
import { getLiveSubscription } from 'src/app/store/normalized/selectors/subscription.selectors';
import { first } from 'rxjs/operators';
import { MyCoachService } from '../../services/my-coach/my-coach.service';
import { EventsService } from 'src/app/services/events.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-my-coach-ctq',
  styleUrls: ['my-coach-ctq.scss'],
  templateUrl: 'my-coach-ctq.html',
  animations: [
    trigger('createDelete', [
      transition(':enter', [style({opacity: 0}), animate('0.3s', style({opacity: 1}))]),
      transition(':leave', [style({opacity: 1}), animate('0.3s', style({opacity: 0}))])
    ])
  ]
})
export class MyCoachCtqPage implements OnDestroy, OnInit {
  isUserProfileComplete$: Observable<boolean> = this.store.select(isUserProfileComplete);
  localConversations: Conversation[] = [];

  public isCoach = false;
  public currentUserProfile: UserProfile;
  public paginating = false;

  public currentUserProfile$ = this.store.select(getCurrentUserProfile);
  public isCoach$ = this.store.select(isCurrentProfileCoach);
  public conversations$: Observable<Conversation[]> = this.store.select(getConversationsFromFeed);
  public conversationsLoading$: Observable<boolean> = this.store.select(getConversationsLoading);
  public conversationsLoaded$: Observable<boolean> = this.store.select(getConversationLoaded);
  public isFullListLoaded$: Observable<boolean> = this.store.select(allConversationsLoaded);
  public conversationsFilter$: Observable<Conversation[]> = this.store.select(getConversationsFilter);

  private conversationSuppression$: Observable<string> = this.store.select(getConversationSuppressionId);
  private conversationCreation$: Observable<Conversation> = this.store.select(getConversationCreation);
  private conversationReaded$: Observable<string> = this.store.select(getConversationReaded);
  private unreadCoachMessagesCount$: Observable<number> = this.store.select(getUnreadCoachMessagesCount);

  private modal;
  private subscriptions = [];
  private hasActiveSubscription: boolean;

  coachOnline = null;

  constructor(
    private store: Store<State>,
    public config: ClarityConfig,
    private changeDetector: ChangeDetectorRef,
    private events: EventsService,
    private myCoachService: MyCoachService
  ) {
    this.events.subscribe(this.config.events.logout, () => {
      this.onClose();
    });
  }

  onClose(): void {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }

  openIncompleteProfilePopup(): void {
    this.store.dispatch(new navigationActions.OpenModal('ProfileCompletionPage'));
  }

  ngOnInit(): void {
    // this.subscriptions.push(this.conversationsLoaded$.subscribe(
    //   loaded => {
    //     if (loaded) {
    //       this.subscriptions.push(
    //
    //     }
    //   }));

    this.subscriptions.push(
      // this is already fired above, we can skip the first fire
      this.unreadCoachMessagesCount$
        .subscribe(() => {
          this.paginating = false;
          this.store.dispatch(new RefreshConversations());
        })
    );

    this.subscriptions.push(
      this.conversationSuppression$.subscribe(idConversation => {
        if (idConversation) {
          this.localConversations.splice(this.localConversations.findIndex(c => c.id === idConversation), 1);
          this.store.dispatch(new ResetConversationSuppression());
          this.changeDetector.detectChanges();
        }
      }));

    this.subscriptions.push(
      this.conversationCreation$.subscribe((conversation: Conversation) => {
        if (conversation) {
          this.localConversations.unshift(conversation);
          this.store.dispatch(new ResetConversationCreation());
          this.changeDetector.detectChanges();
        }
      }));

    this.subscriptions.push(
      this.conversationReaded$.subscribe(idConversation => {
        if (idConversation) {
          this.store.dispatch(new ResetMarkConversationAsRead());
        }
      }));

    this.subscriptions.push(this.store.select(getResumedAt)
      .subscribe(resumedAt => {
        const currentTimestamp = new Date().getTime();

        if (resumedAt && (currentTimestamp - resumedAt) <= 500) {
          this.store.dispatch(new LoadConversations());
        }
      })
    );

    this.subscriptions.push(this.store.select(getLiveSubscription)
      .subscribe((liveSubscription) => {
        this.hasActiveSubscription = liveSubscription && liveSubscription.isActive;
      }
      ));

    this.subscriptions.push(this.myCoachService.coachOnline$
      .subscribe((status) => {
        this.coachOnline = status;
        this.changeDetector.detectChanges();
      })
    );
  }

  onFilterChange(event) {
    this.store.dispatch(new ChangeConversationsFilter(event.detail.value));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onNewConversationClick(): void {
    if (this.hasActiveSubscription) {
      this.store.dispatch(new NewConversation());
      this.store.dispatch(new navigationActions.OpenMyCoachConversation(null));
    } else {
      this.store.dispatch(new navigationActions.ShowUpgradePopup());
    }
  }

  onOpenClick(conversation: Conversation): void {
    if (this.hasActiveSubscription) {
      this.store.dispatch(new navigationActions.OpenMyCoachConversation(conversation.id));
    } else {
      this.store.dispatch(new navigationActions.ShowUpgradePopup());
    }
  }

  onDeleteClick(event): void {
    this.store.dispatch(new DeleteConversation(event.id));
  }

  onCloseConversationClick(event): void {
    this.store.dispatch(new CloseConversation(event.id));
  }

  loadConversations(event = null) {
    this.paginating = true;
    this.store.dispatch(new LoadConversations());

    if (!event) {
      return;
    }

    this.completeEventOnStopLoading(event);
  }

  private completeEventOnStopLoading(event) {
    this.conversationsLoaded$
      .pipe(first(done => done))
      .subscribe(() => {

        if (event) {
          event.target.complete();
        }
      });
  }
}
