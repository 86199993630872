import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as iridiumActions from '../actions/iridium.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InAppMessage } from '../../normalized/schemas/my-coach.schema';
import { Store } from '@ngrx/store';
import { SessionState } from '../session.reducers';
import { IridiumProvider } from '../../../services/analytics/iridium/iridium.provider';
import * as navigationActions from '../actions/navigation.actions';

@Injectable({providedIn: 'root'})
export class IridiumEffects {


  markInAppMessageAsRead$ = createEffect(() => this.actions$.pipe(ofType<iridiumActions.MarkInAppMessageAsRead>(iridiumActions.MARK_INAPP_MESSAGE_AS_READ),
    switchMap((action: iridiumActions.MarkInAppMessageAsRead) => {
      const messageId = action.payload;

      return this.iridiumProvider.markInAppMessageAsRead(messageId)
        .pipe(
          map(() => new iridiumActions.MarkInAppMessageAsReadSuccess(messageId))
        );
    }),
    catchError((error, caught) => {
      this.store.dispatch(new iridiumActions.MarkInAppMessageAsReadFail(error));

      return caught;
    })));


  loadInAppMessages$ = createEffect(() => this.actions$.pipe(ofType<iridiumActions.LoadInAppMessages>(iridiumActions.LOAD_INAPP_MESSAGES),
    switchMap(() => this.iridiumProvider.getInAppMessages()
      .pipe(
        map((inAppMessages: InAppMessage[]) => {
          if (!inAppMessages || Object.keys(inAppMessages).length === 0) {
            return;
          }

          this.store.dispatch(new iridiumActions.LoadInAppMessagesSuccess(inAppMessages));

        })
      )),
    catchError((error, caught) => {
      this.store.dispatch(new iridiumActions.LoadInAppMessagesFail(error));

      return caught;
    })), {dispatch: false});


  loadInAppMessageSuccess$ = createEffect(() => this.actions$.pipe(ofType<iridiumActions.LoadInAppMessagesSuccess>(iridiumActions.LOAD_INAPP_MESSAGES_SUCCESS),
    map((action: iridiumActions.LoadInAppMessagesSuccess) => action.payload),
    map((inAppMessages) => {
      inAppMessages.map(message => {
        this.store.dispatch(new navigationActions.OpenInAppMessage(message));
      });
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<SessionState>,
    private iridiumProvider: IridiumProvider
  ) {

  }

}
