<ion-content [fullscreen]="layoutType === 'modal'">
  <!-- header displayed if page is opened in a popup -->
  <ion-header *ngIf="showCloseButton">
    <cl-header-nav-buttons
      class="page-header"
      [backButton]="false"
      (closeClick)="dismissPremiumPage()"
      color="primary">
    </cl-header-nav-buttons>
  </ion-header>

  <div [ngClass]="{ 'ion-padding-top':showCloseButton, main: true}">

    <ion-row class="header" [class.ua]="config.isUA()">
      <h1 #headerObserverElement>{{ 'subscriptions.premium_tab.' + config.currentProgramCode + '.title' | translate }}</h1>
    </ion-row>

    <ion-row class="sticky-boxes">
      <div class="cap" [class.visible]="!(isHeaderVisible$ | async)"></div>
      <ion-col class="loading" *ngIf="!(generalProducts$ | async)?.length">
        <ion-spinner color="secondary"></ion-spinner>
        <span>{{ 'loading.loading' | translate }}</span>
      </ion-col>
      <ion-col *ngFor="let product of generalProducts$ | async">
        <input class="pricing-box-input" type="radio" id="price-{{ product.id }}-{{ layoutType }}" name="pricing-{{ layoutType }}"
          [checked]="getDefaultGeneralProductId() === product.id">
        <label for="price-{{ product.id }}-{{ layoutType }}" class="pricing-box-label" (click)="onGeneralProductSelected(product.id)">
          <div class="save-percent" *ngIf="product.savePercent">{{ 'subscriptions.premium_tab.common.save' | translate }} {{ product.savePercent }}%</div>
          <span class="product-name" *ngIf="config.isDevice">{{ product.name | translate }}</span>
          <span class="product-name" *ngIf="!config.isDevice">{{ product.name }}</span>
          <span class="product-price">{{ product.wholePrice }}<span *ngIf="product.fractionPrice" class="product-fraction-price">.{{ product.fractionPrice }}</span></span>
          <div class="product-price-per-week" [class.invisible]="!(isHeaderVisible$ | async)">
            <span *ngIf="product.pricePerWeekFormatted">
              <span *ngIf="product.pricePerWeekRounded">{{ (product.pricePerWeekRounded === 'up' ? 'subscriptions.premium_tab.common.less_than' : 'subscriptions.premium_tab.common.just') | translate }} </span>
              {{ product.pricePerWeekFormatted }}/{{ 'subscriptions.premium_tab.common.week' | translate }}
            </span>
            <span *ngIf="!product.pricePerWeekFormatted">{{ 'subscriptions.premium_tab.common.one_time_payment' | translate }}</span>
          </div>
          <div class="selected-tick"><img src="assets/imgs/tick.svg"></div>
        </label>
      </ion-col>
      <div class="skirt" [class.visible]="!(isHeaderVisible$ | async)"></div>
    </ion-row>

    <ng-container *ngIf="canUseLicense">
      <ion-col class="license-row" (click)="onLicenseCode()">
        <a class="license-link">{{ 'subscriptions.premium_tab.common.have_a_license_code' | translate }}</a>
      </ion-col>
    </ng-container>

    <div class="main-content">
      <div class="selling-points">
        <ion-row>
          <ion-icon class="icon complete-program" size="large" color="white" src="assets/imgs/icon-head.svg"></ion-icon>
          <div class="text" [innerHTML]="'subscriptions.premium_tab.' + config.currentProgramCode + '.access_to_the_complete_program' | translate :{ lessonCount: lessonCount$ | async }"></div>
        </ion-row>
        <ion-row>
          <ion-icon class="icon" size="large" color="white" src="assets/imgs/icon-calls.svg"></ion-icon>
          <div class="text" [innerHTML]="'subscriptions.premium_tab.common.weekly_calls' | translate"></div>
        </ion-row>
        <ion-row>
          <ion-icon class="icon" size="large" color="white" src="assets/imgs/icon-exercise.svg"></ion-icon>
          <div class="text" [innerHTML]="'subscriptions.premium_tab.' + config.currentProgramCode + '.exercises' | translate :{ exerciseCount: exerciseCount$ | async }"></div>
        </ion-row>
        <ion-row *ngIf="config.currentProgramCode === 'ua'">
          <ion-icon class="icon" size="large" color="white" src="assets/imgs/icon-theme-week.svg"></ion-icon>
          <div class="text" [innerHTML]="'subscriptions.premium_tab.' + config.currentProgramCode + '.theme_weeks' | translate :{ themeWeekCount: themeWeekCount$ | async }"></div>
        </ion-row>
        <ion-row *ngIf="config.currentProgramCode !== 'ua'">
          <ion-icon class="icon" size="large" color="white" src="assets/imgs/icon-theme-week.svg"></ion-icon>
          <div class="text" [innerHTML]="'subscriptions.premium_tab.' + config.currentProgramCode + '.audio_modules' | translate :{ audioModulesCount: audioExerciseCount$ | async }"></div>
        </ion-row>
        <ion-row>
          <ion-icon class="icon" size="large" color="white" src="assets/imgs/icon-community.svg"></ion-icon>
          <div class="text" [innerHTML]="'subscriptions.premium_tab.common.community' | translate"></div>
        </ion-row>
        <ion-row>
          <ion-icon class="icon" size="large" color="white" src="assets/imgs/icon-messages.svg"></ion-icon>
          <div class="text" [innerHTML]="'subscriptions.premium_tab.common.community_discussion' | translate"></div>
        </ion-row>
        <ion-row>
          <ion-icon class="icon" size="large" color="white" src="assets/imgs/icon-tools.svg"></ion-icon>
          <div class="text" [innerHTML]="'subscriptions.premium_tab.' + config.currentProgramCode + '.tools' | translate"></div>
        </ion-row>
        <ion-row>
          <ion-icon class="icon" size="large" color="white" src="assets/imgs/icon-meditate.svg"></ion-icon>
          <div class="text" [innerHTML]="'subscriptions.premium_tab.common.ask_the_experts' | translate"></div>
        </ion-row>
        <ion-row>
          <ion-icon class="icon" size="large" color="white" src="assets/imgs/icon-journal.svg"></ion-icon>
          <div class="text" [innerHTML]="'subscriptions.premium_tab.common.journal' | translate"></div>
        </ion-row>
      </div>

      <div class="sticky-button" *ngIf="selectedGeneralProduct">
        <div class="bottom-gradient" [class.is-device]="config.isIos || config.isAndroid" [class.is-scrolled-to-bottom]="isScrolledToBottom$ | async"></div>
        <cl-action-button
          *ngIf="config.isDevice"
          [label]="'subscriptions.premium_tab.common.get_subscription' | translate :{ name: selectedGeneralProduct.name | translate, price: selectedGeneralProduct.priceFormatted }"
          [canClick]="true"
          (action)="buyProduct()">
        </cl-action-button>
        <cl-action-button
          *ngIf="!config.isDevice"
          [label]="'subscriptions.premium_tab.common.get_subscription' | translate :{ name:selectedGeneralProduct.name, price: selectedGeneralProduct.priceFormatted }"
          [canClick]="true"
          (action)="buyProduct()">
        </cl-action-button>
      </div>
      <div #scrolledToBottomObserverElement class="bottom-observer"></div>
    </div>
  </div>
</ion-content>

<div class="tabs-placeholder" *ngIf="(config.isIos || config.isAndroid) && layoutType !== 'modal'"></div>
