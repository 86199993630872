import * as offlineQueueActions from '../actions/offline-queue.actions';
import { offlineQueueSettings } from '../../../../app/config/offline-queue.const';

export interface OfflineRequest {
  id: string;
  request: offlineQueueActions.Queueable;
  retries: number;
  queuedAt: number;
  lastRetriedAt: number;
  lastError: string;
}

export interface OfflineQueueState {
  queue: OfflineRequest[];
  deadQueue: OfflineRequest[];
}

export const initialOfflineQueueState: OfflineQueueState = {
  queue: [],
  deadQueue: []
};

const generateUniqId = () => (
  Date.now()
    .toString(36) +
    Math.random()
      .toString(36)
      .substr(2, 5)
)
  .toUpperCase();

export function offlineQueueReducer(state = initialOfflineQueueState, action: offlineQueueActions.OfflineQueueActions): OfflineQueueState {
  switch (action.type) {
    case offlineQueueActions.QUEUE_REQUEST: {
      const request = action.payload;

      const newQueue = [...state.queue];

      newQueue.push({
        id: generateUniqId(),
        request,
        retries: 0,
        queuedAt: new Date().getTime(),
        lastRetriedAt: new Date().getTime(),
        lastError: null
      });

      return {
        ...state,
        queue: newQueue
      };
    }

    case offlineQueueActions.SEND_REQUESTS: {
      const queueIds = action.payload;

      const newQueue = [...state.queue];

      newQueue.forEach((request) => {
        if (queueIds.indexOf(request.id) > -1) {
          request.lastRetriedAt = new Date().getTime();
        }
      });

      return {
        ...state,
        queue: newQueue
      };
    }

    case offlineQueueActions.REQUEST_FAILED: {
      const result = action.payload;

      const newQueue = [...state.queue];
      const deadQueue = [...state.deadQueue];

      newQueue.forEach((request, idx) => {
        if (request.id === result.requestId) {
          // move dead requests to dead queue and never retry them
          request.retries += 1;
          request.lastError = `${result.status} - ${result.error}`;

          if (request.retries >= offlineQueueSettings.MAX_RETIRES) {
            deadQueue.push(request);
            newQueue.splice(idx, 1);
          }
        }
      });

      return {
        ...state,
        queue: newQueue,
        deadQueue
      };
    }

    case offlineQueueActions.REQUEST_FULFILLED: {
      const result = action.payload;

      const newQueue = [...state.queue].filter((request) => request.id !== result.requestId);

      return {
        ...state,
        queue: newQueue
      };
    }
  }

  return state;
}

export const offlineQueue = (state: OfflineQueueState) => state.queue;
