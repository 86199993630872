<ng-container *ngIf="{
    loading: messageLoading$ | async,
    messages: messages$ | async,
    lastCoachMessage: lastCoachMessage$ | async,
    isCoach: isCoach$ | async,
    conversation: currentConversation$ | async
  } as data">

  <ion-header>
    <cl-header-nav-buttons
      class="page-header"
      color="blank"
      [backButton]="showBack"
      (back)="onBack()"
      (closeClick)="onClose()"
      [title]="data.isCoach && !data.loading ? getMessageOwner(data.messages) : 'my-coach.coach_conversation' | translate"
      [adminUrl]="data.isCoach && getAdminUrl(data.messages)"
      [standaloneClient]="data.isCoach && getStandaloneClient(data.messages)"
      [class.desktop]="config.usingDesktopOnWeb">
    </cl-header-nav-buttons>
    <div class="coach-name-section" *ngIf="config.programDPPorWL() && data.lastCoachMessage?.username">
      <span>{{'common.with' | translate}}</span>
      <span class="coach-name" (click)="showCoachingBio(data.conversation, data.lastCoachMessage)">{{ data.lastCoachMessage.username | firstName }}</span>
      <ion-icon name="person-circle-outline" (click)="showCoachingBio(data.conversation, data.lastCoachMessage)"></ion-icon>
    </div>
    <div class="coach-online" *ngIf="!!(myCoachService.coachOnline$ | async)">{{'my-coach.coach_online' | translate}}</div>
  </ion-header>

  <ion-content #content>

    <div *ngIf="(data.loading && !silentLoading); then initialLoading else messages"></div>

    <ng-template #initialLoading>
      <div class="loading-wrapper">
        <ion-spinner></ion-spinner>
      </div>
    </ng-template>

    <ng-template #messages>
      <div class="conversation-container ion-padding">
        <div class="conversation-first-message"
             *ngIf="data.messages.length === 0">{{'my-coach.conversation_first_message' | translate}}</div>

        <ion-button expand="block" fill="clear" *ngIf="canLoadAllMessages" (click)="loadAllMessages()" color="action">
          {{'my-coach.conversation.load_all_messages' | translate:{count: remainingMessages} }}
        </ion-button>

        <div class="conversation-messages" *ngIf="data.messages.length > 0">

          <cl-message-card
            *ngFor="let message of data.messages; let i = index; let last = last"
            [last]="last"
            [index]="i"
            [message]="message"
            [previousMessage]="i > 0 ? data.messages[i - 1] : null"
            [nextMessage]="data.messages[i + 1] ? data.messages[i + 1] : null"
            (delete)="deleteMessage($event.id)">
          </cl-message-card>
        </div>
        <div class="conversation-typing" *ngIf="typingMessageCoachName">
          {{'my-coach.conversation.typing_message' | translate:{name: typingMessageCoachName} }}
        </div>
      </div>
    </ng-template>

  </ion-content>

  <ion-footer>
    <ion-toolbar>
      <div #messageFormWrapper>
        <form [formGroup]="messageForm" (ngSubmit)="addMessage()">
          <ion-textarea
            (ionFocus)="onMessageFocus()"
            (click)="onMessageClick()"
            rows="1"
            autocapitalize="on"
            auto-grow="false"
            (ionInput)="onMessageInput()"
            placeholder="{{'my-coach.conversation.enter_a_message' | translate}}"
            formControlName="content"
            [class.large]="textAreaHasFocus"
            [attr.disabled]="posting"
            clAutogrow
            growMax="50vh">
          </ion-textarea>

          <div *ngIf="textAreaHasFocus">
            <ion-button expand="block" type="submit" [disabled]="posting || !messageForm.valid">
              {{('my-coach.conversation.post' | translate | uppercase)}}
            </ion-button>
          </div>
        </form>
      </div>
    </ion-toolbar>
  </ion-footer>

</ng-container>
