import { Injectable } from '@angular/core';
import { AudioSubtitlesProvider } from 'src/app/providers/audio-subtitles.provider';
import { WebVTTParser } from 'webvtt-parser';
import { AudioCue, AudioSubtitle } from 'src/app/store/normalized/schemas/mediaFile.schema';
import { BrightcovePlayer, Subtitle } from 'capacitor-brightcove-player';
import { LoggerService } from 'src/app/services/logger.service';


@Injectable({providedIn: 'root'})
export class AudioSubtitlesService {

  constructor(
    private audioSubtitlesProvider: AudioSubtitlesProvider,
    private loggerService: LoggerService
  ) {}

  async getSubtitlesFromBrightcove(lang: string, brightcove_key: string): Promise<any> {
    if (!lang || !brightcove_key) {
      return;
    }

    let metadata;

    try {
      metadata = await BrightcovePlayer.getMetadata({fileId: brightcove_key});
    } catch(error) {
      this.loggerService.error('Error getting audio metadata', error);
      throw error;
    }

    const subtitle: Subtitle = metadata?.metadata?.subtitles.filter(sub => sub.language === lang)[0];

    // Brightcove plugin is returning http link
    // Force https to ensure that we don't have any usesCleartextTraffic issues
    const subtitleSrc = subtitle.src.replace('http://','https://');

    return this.audioSubtitlesProvider.loadSubtitle(subtitleSrc).toPromise();
  }

  parseWebVttFile(vtt: string): AudioSubtitle {
    const parser = new WebVTTParser();
    try {
      return parser.parse(vtt, 'metadata');
    }  catch (error) {
      this.loggerService.error('Cannot parse vtt file', error);
    }
  }

  getText(cues: AudioCue[], positionInSecond: number) {
    if(!cues || !positionInSecond) {
      return;
    }

    const found =  cues.filter(cue => positionInSecond > cue.startTime && positionInSecond < cue.endTime);

    return found && found.length === 1 ? found[0].text : '';
  }
}
