import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-slide-card-component',
  styleUrls: ['slide-card.component.scss'],
  template: `
    <button class="card-background" [ngClass]="type" (click)="onClicked()" [class.completed]="completed">
      <ion-icon
        *ngIf="!completed && !started"
        name="add-circle">
      </ion-icon>
      <ion-icon
        *ngIf="!completed && started"
        class="in-progress"
        clTooltip
        [tooltipText]="'tooltips.program_week.in_progress' | translate"
        src="/assets/icon/partially_completed_orange.svg">
      </ion-icon>
      <ion-icon
        *ngIf="completed"
        name="checkmark-circle"
        clTooltip
        [preventPropagation]="false"
        [tooltipText]="'tooltips.program_week.completed_can_start' | translate">
      </ion-icon>
      <p>{{title}}</p>
    </button>
  `
})
export class SlideCardComponent {
  @Input() title: string;
  @Input() type: string;
  @Input() completed: boolean;
  @Input() started: boolean;

  @Output() clicked = new EventEmitter();

  onClicked() {
    this.clicked.emit();
  }

}
