import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit, ViewChild
} from '@angular/core';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { LoadingService } from '../../services/loading.service';
import { getAuthError, isAuthenticating } from '../../store/sensitive/selectors/auth.selectors';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ClarityConfig } from '../../config/clarity.config';
import { ClearLoginState, Login } from '../../store/sensitive/actions/auth.actions';
import { SessionState } from '../../store/session/session.reducers';
import { ActivatedRoute } from '@angular/router';
import { RecaptchaComponent } from '../../components/recaptcha/recaptcha.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../services/toast.service';

import { PrivacyScreenService } from 'src/app/services/privacy-screen.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['login-email.scss'],
  selector: 'cl-login-email-page',
  templateUrl: 'login-email.html'
})
export class LoginEmailPage implements OnInit {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;
  @ViewChild(RecaptchaComponent, { static : true }) captcha: RecaptchaComponent;

  public authenticating$: Observable<boolean> = this.store.select(isAuthenticating);
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  private authError$ = this.store.select(getAuthError);

  public loginForm: FormGroup;

  public validations = {
    email: {
      validators: Validators.compose([Validators.required, Validators.email]), errors: {
        required: 'errors.user.email_required', email: 'errors.user.email_invalid'
      }
    },
    password: {
      validators: Validators.compose([Validators.required]), errors: {
        required: 'errors.user.password_required'
      }
    }
  };

  constructor(
    private store: Store<SessionState>,
    private loadingService: LoadingService,
    public navCtrl: NavController,
    public config: ClarityConfig,
    private route: ActivatedRoute,
    private privacyScreenService: PrivacyScreenService,
    private translate: TranslateService,
    private toasts: ToastService
  ) {}

  ngOnInit() {
    this.initLoginForm();
  }

  ionViewWillEnter() {
    this.store.dispatch(new ClearLoginState());
  }

  ionViewDidEnter() {
    this.privacyScreenService.enable();

    this.authError$
      .pipe(takeUntil(this.unsubscribe$), filter(error => error && error.httpStatus === 429))
      .subscribe(() => this.captcha.render());

    this.route.data
      .pipe(take(1))
      .subscribe((data) => {
        if (!data) {
          return false;
        }

        if (Boolean(data.forceDpp)) {
          this.config.enforceDppProgram(true);
        }

        if (Boolean(data.forceWl)) {
          this.config.enforceWlProgram(true);
        }
      });
  }

  ionViewWillLeave() {
    this.privacyScreenService.disable();
  }

  ionViewDidLeave() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  onLogin() {
    // In case login-email button is accessible when form is invalid (only on web)
    // we mark the fields as touched when login-email is pressed to display errors
    for (const control in this.loginForm.controls) {
      this.loginForm.controls[control].markAsTouched();
    }

    const captchaToken = this.loginForm.get('captcha').value;

    if (this.loginForm.valid) {
      this.loadingService.useLoadingObservable(this.authenticating$);
      this.store.dispatch(new Login({
        email: this.loginForm.get('email').value,
        password: this.loginForm.get('password').value,
        ...captchaToken && ({'g-recaptcha-response': captchaToken})
      }));
    } else {
      this.toasts.error(this.translate.get('errors.common.form_error'));
    }
  }

  goBack() {
    this.navCtrl.navigateBack('login');
  }

  goToResetPassword() {
    this.navCtrl.navigateForward('reset-password');
  }

  goToSignup() {
    this.navCtrl.navigateForward('signup');
  }

  private initLoginForm(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', this.validations.email),
      password: new FormControl('', this.validations.password),
      captcha: new FormControl()
    });
  }
}
