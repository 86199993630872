import { schema } from 'normalizr';

export class ThemeWeek {
  id: number;
  title: string;
  description: string;
  tag: string;
  position?: number;
}

export const themeWeekSchema = new schema.Entity<ThemeWeek>('themeWeeks');
