import { createSelector } from '@ngrx/store';

import * as userGoalsReducer from '../reducers/user-goals.reducer';
import { getUserGoalsState } from '../../../../app/store';
import { isUserGoalsSyncing, isUserProgramSyncing } from './sync.selectors';

export const isUpdatingUserGoals = createSelector(
  getUserGoalsState,
  userGoalsReducer.userGoalsUpdating
);

export const isLoadingUserGoals = createSelector(
  isUserProgramSyncing,
  isUserGoalsSyncing,
  (syncingUserProgram, syncingGoals) => syncingUserProgram || syncingGoals ? true : false
);
