import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-anxious-feeling',
  styleUrls: ['anxious-feeling.component.scss'],
  template: `
    <div class="slider-wrapper">
      <div class="slider-options">
        <span *ngFor="let item of items; let i = index" [class.selected]="sliderValue === i">{{i}}</span>
      </div>

      <cl-horizontal-slider
        [value]="sliderValue"
        [max]="10"
        (slide)="onSlide($event)">
      </cl-horizontal-slider>

      <div class="bottom-text-container">
        <div>{{'wizards.not_at_all' | translate}}</div>
        <div>{{'wizards.most_ever' | translate}}</div>
      </div>
    </div>
  `
})
export class AnxiousFeelingComponent {

  @Input() sliderValue = null;

  @Output() valueChanged = new EventEmitter();

  items = new Array(11).fill(1);

  onSlide(value) {
    this.sliderValue = value;
    this.valueChanged.emit(this.sliderValue / 10);
  }
}
