import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({providedIn: 'root'})
export class NotificationsProvider {
  private notificationEndpoint = '{community}/notifications';
  private allNotificationEndpoint = '{community}/notifications/all';
  private markAsReadNotificationEndpoint = '{community}/notifications/mark_as_read';

  constructor(private http: HttpProvider) {}

  public loadNotifications() {
    return this.http.get(this.notificationEndpoint)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  public loadAllNotifications() {
    return this.http.get(this.allNotificationEndpoint)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  public markCommunityNotificationsAsRead(lastId) {
    return this.http.patch(this.markAsReadNotificationEndpoint, {last_id: lastId})
      .pipe(
        catchError(error => throwError(error))
      );
  }

}
