import { ActionReducerMap } from '@ngrx/store';
import { FlagsState, persistentFlagsReducer } from './flags/flags.reducer';
import { InterstitialsState, persistentInterstitialReducer } from './interstitials/interstitials.reducer';
import { OnboardingState, persistentOnboardingReducer } from './onboarding/onboarding.reducer';
import { persistentMediaReducer, MediaState } from './media/media.reducer';
import { persistentTabsReducer, TabsState } from './tabs/tabs.reducer';
import { persistentTimestampsReducer, TimestampsState } from './timestamps/timestamps.reducer';

export interface PersistentState {
  flags: FlagsState;
  tabs: TabsState;
  media: MediaState;
  interstitials: InterstitialsState;
  onboarding: OnboardingState;
  timestamps: TimestampsState;
}

export const persistentReducers: ActionReducerMap<PersistentState> = {
  flags: persistentFlagsReducer,
  tabs: persistentTabsReducer,
  media: persistentMediaReducer,
  interstitials: persistentInterstitialReducer,
  onboarding: persistentOnboardingReducer,
  timestamps: persistentTimestampsReducer
};
