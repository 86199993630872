<ion-content #ionContent>
  <form [formGroup]="form" *ngIf="{ selectedSlide: selectedSlideNumber$ | async } as asyncs">
    <swiper
      #swiper
      [allowSlideNext]="false"
      [autoHeight]="true"
      [speed]="200"
      [resizeObserver]="true"
      effect="fade"
      [allowTouchMove]="false">

      <ng-template swiperSlide>
        <section>
          <ng-container *ngIf="asyncs.selectedSlide === 0">
            <ng-container *ngTemplateOutlet="background"></ng-container>
          </ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="intro"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngIf="asyncs.selectedSlide === 1">
            <ng-container *ngTemplateOutlet="background"></ng-container>
          </ng-container>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'goals' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="goalIntro1"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngIf="asyncs.selectedSlide === 2">
            <ng-container *ngTemplateOutlet="background"></ng-container>
          </ng-container>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'goals' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="goalIntro2"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'goals' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="goalSelection"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngIf="asyncs.selectedSlide === 4">
            <ng-container *ngTemplateOutlet="background"></ng-container>
          </ng-container>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'motivation' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="motivationIntro1"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngIf="asyncs.selectedSlide === 5">
            <ng-container *ngTemplateOutlet="background"></ng-container>
          </ng-container>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'motivation' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="motivationIntro2"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'motivation' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="motivationSelection"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'motivation' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="motivationDoctorSelection"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'about_you' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="aboutNickname"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'about_you' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="education"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'about_you' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="aboutYou"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide *ngIf="!isScaleRedemptionEnabled">
        <section>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'about_you' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="aboutDppPhoneNumber"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section [id]="SCALE_ORDER_PRE_START">
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'scale' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="scalePreStart"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section [id]="SCALE_ORDER_INITIAL_STEP">
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'scale' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="scaleIntro"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'scale' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="shippingAddress"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section>
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'scale' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="shippingAddressConfirm"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section [id]="SCALE_ORDER_PROGRESS">
          <div class="content loading-scale-content">
            <ng-container *ngTemplateOutlet="loadingScaleOrder"></ng-container>
          </div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section [id]="SCALE_ORDER_RESULT">
          <ng-container *ngTemplateOutlet="headerSection; context: { selected: 'scale' }"></ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="scaleOrderResult"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section [id]="VIDEO_ELEMENT_ID">
          <ng-container *ngIf="asyncs.selectedSlideId === VIDEO_ELEMENT_ID">
            <ng-container *ngTemplateOutlet="background"></ng-container>
          </ng-container>
          <div class="content">
            <ng-container *ngTemplateOutlet="slideFinal"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>
    </swiper>
  </form>
  <div class="scrolled-to-bottom-observer" [class.is-android]="platform.is('android')" #scrolledToBottomObserver></div>
</ion-content>
<ion-footer
  *ngIf="{
    selectedSlide: selectedSlideNumber$ | async,
    selectedSlideId: selectedSlideId$ | async,
    isSlideScrolledToBottom: isSlideScrolledToBottom$ | async
  } as asyncs"
  [class.single-element]="!shouldShowDottedStep(asyncs.selectedSlide, asyncs.selectedSlideId)"
  [class.sticky]="asyncs.isSlideScrolledToBottom"
>
  <div class="footer">
    <cl-dotted-steps-indicator
      *ngIf="shouldShowDottedStep(asyncs.selectedSlide, asyncs.selectedSlideId)"
      [status$]="dottedStepsStatus$"
    ></cl-dotted-steps-indicator>
    <ng-container *ngIf="asyncs.selectedSlide === 0">
      <ng-container *ngTemplateOutlet="buttonNext; context: { label: 'account_setup.continue' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="[1, 2, 4, 5].includes(asyncs.selectedSlide)">
      <ng-container *ngTemplateOutlet="buttonNext"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 3">
      <ng-container *ngTemplateOutlet="buttonNextWithSkip; context: { step: 'goals' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 6">
      <ng-container *ngTemplateOutlet="buttonNext; context: { step: 'motivation' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 7">
      <ng-container *ngTemplateOutlet="buttonNext; context: { step: 'doctorMotivation' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 8">
      <ng-container *ngTemplateOutlet="buttonNext; context: { step: 'nickname' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 9">
      <ng-container *ngTemplateOutlet="buttonNext; context: { step: 'education' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 10">
      <ng-container *ngTemplateOutlet="buttonNextWithSkip; context: { step: 'aboutYou' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlideId === SCALE_ORDER_PRE_START">
      <ng-container *ngTemplateOutlet="buttonNextWeightPreStart"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 11 && !isScaleRedemptionEnabled">
      <ng-container *ngTemplateOutlet="buttonNext; context: { step: 'shippingAddress.phoneNumber' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 12">
      <ng-container *ngTemplateOutlet="buttonNextWithFitbitSkip;"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 13">
      <ng-container *ngTemplateOutlet="buttonNextWithFitbitSkip; context: { step: 'shippingAddress' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 14">
      <ng-container *ngTemplateOutlet="orderScaleFooter"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 15">
      <!-- empty. It's a fullscreen loading -->
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlide === 16">
      <ng-container *ngTemplateOutlet="scaleOrderResultFooter"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlideId === VIDEO_ELEMENT_ID">
      <ng-container
        *ngTemplateOutlet="buttonNext; context: { label: 'account_setup.lets_begin', hideChevron: true }">
      </ng-container>
    </ng-container>
  </div>
</ion-footer>

<ng-template #background>
  <cl-circles-background></cl-circles-background>
</ng-template>

<ng-template #intro>
  <h2 [innerHTML]="'account_setup.joining_this_program_took' | translate"></h2>
  <div class="img-container">
    <img class="background-img" [class.bg-1-anim-after]="imageDidAnimate[0]" src="assets/imgs/onboarding/step1-bg.svg" alt="">
    <img class="foreground-img" src="assets/imgs/onboarding/step1-no-bg.svg" alt="">
  </div>
  <p [innerHTML]="'account_setup.were_so_happy_youre_here' | translate"></p>
</ng-template>

<ng-template #goalIntro1>
  <h2 [innerHTML]="'account_setup.lets_begin_with_some_goals' | translate"></h2>
  <div class="img-container">
    <img class="background-img" [class.bg-2-anim-before]="!imageDidAnimate[0]" [class.bg-2-anim-after]="imageDidAnimate[1]" src="assets/imgs/onboarding/step2-1-bg.svg" alt="">
    <img class="foreground-img" [class.bg-2-anim-after]="imageDidAnimate[1]" src="assets/imgs/onboarding/step2-1-no-bg.svg" alt="">
  </div>
  <p [innerHTML]="'account_setup.setting_goals_helps_create' | translate"></p>
</ng-template>

<ng-template #goalIntro2>
  <h2 [innerHTML]="'account_setup.youve_got_this' | translate"></h2>
  <div class="img-container">
    <img class="background-img" [class.bg-3-anim-before]="!imageDidAnimate[1]" src="assets/imgs/onboarding/step2-2-bg.svg" alt="">
    <img class="foreground-img" [class.fg-3-anim-before]="!imageDidAnimate[1]" src="assets/imgs/onboarding/step2-2-no-bg.svg" alt="">
  </div>
  <p [innerHTML]="'account_setup.you_may_already_have_a_goal' | translate"></p>
</ng-template>

<ng-template #goalSelection>
  <h2 [innerHTML]="'account_setup.select_all_of_the_goals' | translate"></h2>
  <p [innerHTML]="'account_setup.i_want_to' | translate"></p>

  <div *ngIf="goalsModel" class="flex-container">
    <div class="custom-checkbox-container" *ngFor="let goal of goalsModel.items">
      <input class="custom-checkbox" type="checkbox" [id]="'goals-' + goal.value" [formControl]="goal.control">
      <label class="custom-checkbox-label" (click)="goal.control.setValue(!goal.control.value)">{{ goal.label }}</label>
    </div>
  </div>

  <p [innerHTML]="'account_setup.anything_else' | translate"></p>

  <ion-textarea
    [rows]="6"
    [maxlength]="TEXTAREA_CHARACTER_LIMIT"
    name="goalsElse"
    class="goals-else onboarding-goals"
    [formControl]="form.get('goalsElse')"
  ></ion-textarea>
  <div class="character-counter">{{ TEXTAREA_CHARACTER_LIMIT - form.get('goalsElse').value.length }} / {{TEXTAREA_CHARACTER_LIMIT}}</div>
</ng-template>

<ng-template #motivationIntro1>
  <h2 [innerHTML]="'account_setup.feel_inspired_and_motivated' | translate"></h2>
  <div class="img-container">
    <img [class.bg-4-1-anim-after]="imageDidAnimate[2]" class="background-img" src="assets/imgs/onboarding/step3-1-1-bg.svg" alt="">
    <img [class.bg-4-2-anim-after]="imageDidAnimate[2]" class="background-img" src="assets/imgs/onboarding/step3-1-2-bg.svg" alt="">
    <img class="background-img" src="assets/imgs/onboarding/step3-1-3-bg.svg" alt="">
    <img class="foreground-img" src="assets/imgs/onboarding/step3-1-no-bg.svg" alt="">
  </div>
  <p [innerHTML]="'account_setup.and_feeling_nervous' | translate"></p>
</ng-template>

<ng-template #motivationIntro2>
  <h2 [innerHTML]="'account_setup.support_every_step_of_the_way' | translate"></h2>
  <div class="img-container">
    <img [class.bg-5-1-anim-before]="!imageDidAnimate[2]" class="background-img" src="assets/imgs/onboarding/step3-2-1-bg.svg" alt="">
    <img [class.bg-5-2-anim-before]="!imageDidAnimate[2]" class="background-img" src="assets/imgs/onboarding/step3-2-2-bg.svg" alt="">
    <img class="foreground-img" src="assets/imgs/onboarding/step3-2-no-bg.svg" alt="">
  </div>
  <p [innerHTML]="'account_setup.you_will_have_a_coach' | translate"></p>
</ng-template>

<ng-template #motivationSelection>
  <h2 [innerHTML]="'account_setup.just_a_few_more_questions' | translate"></h2>
  <p [innerHTML]="'account_setup.tell_us_what_has_motivated' | translate"></p>

  <div class="flex-container">
    <div class="custom-checkbox-container" *ngFor="let motivation of motivationModel.items">
      <input class="custom-checkbox" type="checkbox" [id]="'motivation-' + motivation.value" [formControl]="motivation.control">
      <label class="custom-checkbox-label" (click)="motivation.control.setValue(!motivation.control.value)">{{ motivation.label | translate }}</label>
    </div>
  </div>
</ng-template>

<ng-template #motivationDoctorSelection>
  <h2 [innerHTML]="'account_setup.did_a_healthcare_professional' | translate"></h2>

  <div class="flex-container">
    <div class="custom-checkbox-container" *ngFor="let motivation of doctorMotivationOptions">
      <input class="custom-checkbox" type="radio" [value]="motivation.value" [formControl]="form.get('doctorMotivation')">
      <label class="custom-checkbox-label" (click)="form.get('doctorMotivation').setValue(motivation.value)">{{ motivation.label | translate }}</label>
    </div>
  </div>
</ng-template>

<ng-template #aboutYou>
  <p [innerHTML]="'account_setup.please_share_anything_else' | translate"></p>

  <ion-textarea
    [rows]="6"
    [maxlength]="TEXTAREA_CHARACTER_LIMIT"
    name="aboutYou"
    class="about-you"
    [formControl]="form.get('aboutYou')"
  ></ion-textarea>
  <div class="character-counter">{{ TEXTAREA_CHARACTER_LIMIT - form.get('aboutYou').value.length }} / {{TEXTAREA_CHARACTER_LIMIT}}</div>
</ng-template>

<ng-template #aboutDppPhoneNumber>
  <h2 [innerHTML]="'account_setup.one_last_question_we_promise' | translate"></h2>

  <p [innerHTML]="'account_setup.please_provide_your_mobile' | translate"></p>

  <ion-input
    class="onboarding-phone-number"
    name="phoneNumber"
    type="tel"
    [formControl]="form.get('shippingAddress').get('phoneNumber')"
    placeholder="(999) 999-9999">
  </ion-input>

  <div class="terms">
    {{ 'account_setup.terms_of_use_and_privacy_policy' | translate }} <span (click)="openTerms()" class="terms-link" [innerHTML]="'account_setup.terms_of_use_and_privacy_policy2' | translate"></span>
  </div>
</ng-template>

<ng-template #aboutNickname>
  <h2 [innerHTML]="'account_setup.what_name_would_you_like' | translate"></h2>

  <ion-input
    name="nickname"
    maxlength="25"
    [formControl]="form.get('nickname')"
  ></ion-input>
</ng-template>

<ng-template #education>
  <h2 [innerHTML]="'account_setup.what_is_your_highest_level_of_education' | translate"></h2>

  <p [innerHTML]="'account_setup.select_one_option' | translate"></p>

  <div class="flex-container">
    <div class="custom-checkbox-container" *ngFor="let education of educationOptions">
      <input class="custom-checkbox" type="radio" [value]="education.value" [formControl]="form.get('education')">
      <label class="custom-checkbox-label" (click)="form.get('education').setValue(education.value)" [innerHTML]="education.label | translate"></label>
    </div>
  </div>
</ng-template>

<ng-template #scalePreStart>
  <h2 [innerHTML]="'account_setup.you_already_have_scale' | translate"></h2>

  <p>
    {{ 'account_setup.do_you_have_scale_question' | translate }}
  </p>

  <div class="flex-container">
    <div class="custom-checkbox-container" *ngFor="let receivedScaleOption of receivedScaleOptions">
      <input class="custom-checkbox" type="radio" [value]="receivedScaleOption.value" [formControl]="form.get('receivedScaleOption')">
      <label class="custom-checkbox-label" (click)="form.get('receivedScaleOption').setValue(receivedScaleOption.value)" [innerHTML]="receivedScaleOption.label | translate"></label>
    </div>
  </div>
</ng-template>

<ng-template #scaleIntro>
  <h2 [innerHTML]="'account_setup.lets_get_your_fitbit' | translate"></h2>

  <img class="img-order-scale" src="assets/imgs/fitbit-scale-black.png">

  <p>
    {{ 'account_setup.you_will_need_to_complete_program' | translate }}
  </p>
</ng-template>

<ng-template #shippingAddress>
  <h2 [innerHTML]="'account_setup.enter_your_shipping_address' | translate"></h2>

  <p class="label">{{ 'account_setup.first_name' | translate }}</p>
  <ion-input
    name="firstName"
    type="text"
    [formControl]="form.get('shippingAddress').get('firstName')">
  </ion-input>

  <p class="label">{{ 'account_setup.last_name' | translate }}</p>
  <ion-input
    name="lastName"
    type="text"
    [formControl]="form.get('shippingAddress').get('lastName')">
  </ion-input>

  <p class="label">{{ 'account_setup.street_name_and_number' | translate }}</p>
  <ion-input
    name="streetAddress"
    type="text"
    [formControl]="form.get('shippingAddress').get('streetAddress')">
  </ion-input>

  <p class="label">{{ 'account_setup.street_address2_optional' | translate }}</p>
  <ion-input
    name="streetAddress2"
    type="text"
    [formControl]="form.get('shippingAddress').get('streetAddress2')">
  </ion-input>

  <p class="label">{{ 'account_setup.city' | translate }}</p>
  <ion-input
    name="city"
    type="text"
    [formControl]="form.get('shippingAddress').get('city')">
  </ion-input>

  <p class="label">{{ 'account_setup.zip' | translate }}</p>
  <ion-input
    name="zip"
    type="text"
    [formControl]="form.get('shippingAddress').get('zip')">
  </ion-input>

  <p class="label">{{ 'account_setup.country' | translate }}</p>

  <ion-select
    mode="ios"
    [placeholder]="'account_setup.select_country' | translate "
    [formControl]="form.get('shippingAddress').get('country')"
    [value]="form.get('shippingAddress').get('country').value"
  >
    <ion-select-option *ngFor="let country of countries" [value]="country.code">
      {{ country.name }}
    </ion-select-option>
  </ion-select>

  <p class="label">{{ 'account_setup.state' | translate }}</p>

  <ion-select
    mode="ios"
    [placeholder]="'account_setup.select_state' | translate "
    [formControl]="form.get('shippingAddress').get('state')"
    [value]="form.get('shippingAddress').get('state').value"
  >
    <ion-select-option *ngFor="let state of states" [value]="state.abbreviation">
      {{ state.name }}
    </ion-select-option>
  </ion-select>

  <p class="label">{{ 'account_setup.mobile_phone' | translate }}</p>
  <ion-input
    name="phoneNumber"
    type="tel"
    [formControl]="form.get('shippingAddress').get('phoneNumber')"
    placeholder="(999) 999-9999">
  </ion-input>
</ng-template>

<ng-template #shippingAddressConfirm>
  <h2 [innerHTML]="'account_setup.verify_your_information' | translate"></h2>

  <div class="edit-address">
    <p>
      <strong>{{ 'account_setup.contact_information' | translate }}</strong>
    </p>

    <ion-button fill="outline" color="light" (click)="tryNavigateToPrevious()">
      <ion-icon name="pencil"></ion-icon>
      {{ 'account_setup.edit' | translate }}
    </ion-button>
  </div>

  <div class="info-container">
    <p>
      {{ form.get('shippingAddress')?.get('firstName')?.value + ' ' + form.get('shippingAddress')?.get('lastName')?.value }}
    </p>
    <p>
      {{ form.get('shippingAddress')?.get('streetAddress')?.value }}
    </p>
    <p *ngIf="form.get('shippingAddress')?.get('streetAddress2')?.value">
      {{ form.get('shippingAddress')?.get('streetAddress2')?.value }}
    </p>
    <p>
      {{ form.get('shippingAddress')?.get('city')?.value + ', ' + form.get('shippingAddress')?.get('state')?.value + ' ' + form.get('shippingAddress')?.get('zip')?.value }}
    </p>
    <p class="info-phone-number">
      <ion-icon name="call-outline"></ion-icon>
      {{ form.get('shippingAddress')?.get('phoneNumber')?.value }}
    </p>
  </div>

  <div class="terms">
    {{ 'account_setup.terms_of_use_and_privacy_policy' | translate }} <span (click)="openTerms()" class="terms-link" [innerHTML]="'account_setup.terms_of_use_and_privacy_policy2' | translate"></span>
  </div>
</ng-template>

<ng-template #loadingScaleOrder>
  <h2 [innerHTML]="'account_setup.processing_your_order' | translate"></h2>

  <p [innerHTML]="'account_setup.scale_delivered_5_7_business_day' | translate"></p>

  <img class="img-loading-scale" src="assets/imgs/logo_ern.png">
</ng-template>

<ng-template #scaleOrderResult>
  <div *ngIf="weightScaleOrderStatus === 'succeed'">
    <h2 [innerHTML]="'account_setup.order_complete' | translate"></h2>

    <img src="assets/imgs/onboarding/order-scale-succeed.svg" alt="">

    <p>
      {{ 'account_setup.receive_order_tracking_number' | translate }}
    </p>
  </div>

  <div *ngIf="weightScaleOrderStatus === 'failed'">
    <h2 [innerHTML]="'account_setup.cannot_complete_order' | translate"></h2>

    <img src="assets/imgs/onboarding/order-scale-failed.svg" alt="">

    <p>
      {{ 'account_setup.please_try_again_order' | translate }}
    </p>
  </div>
</ng-template>

<ng-template #slideFinal>
  <h2 [innerHTML]="'account_setup.thank_you' | translate"></h2>

  <p [innerHTML]="'account_setup.we_got_all_the_information' | translate"></p>
  <p [innerHTML]="'account_setup.watch_this_brief_video' | translate"></p>

  <div class="intro-video" *ngIf="exercise">
    <cl-play-exercise
      [showTitle]="false"
      [exercise]="exercise"
      [autoplay]="false"
      [playerController]="playerController$"
      [isInlineOnIos]="true"
      [isDescriptionVisible]="false">
    </cl-play-exercise>
  </div>
</ng-template>

<ng-template #headerSection let-selected="selected">
  <header>
    <div [class.active]="selected === 'goals'">
      {{ 'account_setup.header.goals' | translate }}
    </div>
    <div [class.active]="selected === 'motivation'">
      {{ 'account_setup.header.motivation' | translate }}
    </div>
    <div [class.active]="selected === 'about_you'">
      {{ 'account_setup.header.about_you' | translate }}
    </div>
    <div *ngIf="isScaleRedemptionEnabled" [class.active]="selected === 'scale'">
      {{ 'account_setup.header.scale' | translate }}
    </div>
  </header>
</ng-template>

<ng-template #orderScaleFooter>
  <ion-button
    class="dark-blue-theme light-button"
    (click)="postOrderScale()"
  >
    {{ 'account_setup.order_scale' | translate }}
    <ion-icon name="chevron-forward-outline"></ion-icon>
    <ion-ripple-effect></ion-ripple-effect>
  </ion-button>
</ng-template>

<ng-template #scaleOrderResultFooter>
  <ng-container *ngIf="weightScaleOrderStatus === 'succeed'">
    <ng-container *ngTemplateOutlet="buttonNext; context: { label: 'account_setup.next' }"></ng-container>
  </ng-container>

  <ng-container *ngIf="weightScaleOrderStatus === 'failed'">
    <p class="skip" (click)="confirmWantsToSkip()">{{'account_setup.later' | translate}}</p>

    <ion-button
      class="dark-blue-theme light-button"
      (click)="tryAgainOrderScale()"
    >
      {{ 'account_setup.try_again' | translate }}
      <ion-ripple-effect></ion-ripple-effect>
    </ion-button>
  </ng-container>
</ng-template>


<ng-template #buttonNextWithFitbitSkip let-step="step" let-label="label">
  <p class="skip" (click)="confirmWantsToSkip()">{{'account_setup.later' | translate}}</p>

  <ng-container *ngTemplateOutlet="buttonNext; context: { step: step, label: label }"></ng-container>
</ng-template>

<ng-template #buttonNextWithSkip let-step="step" let-label="label">
  <p class="skip" (click)="tryNavigateToNext(true)">{{'account_setup.skip' | translate}}</p>

  <ng-container *ngTemplateOutlet="buttonNext; context: { step: step, label: label }"></ng-container>
</ng-template>

<ng-template #buttonNextWeightPreStart>
  <ion-button
    class="dark-blue-theme light-button"
    (click)="nextWeightPreStart()"
    [disabled]="isStepInvalid('receivedScaleOption')"
  >
    {{ 'account_setup.next' | translate }}
    <ion-icon name="chevron-forward-outline"></ion-icon>
    <ion-ripple-effect></ion-ripple-effect>
  </ion-button>
</ng-template>

<ng-template #buttonNext let-step="step" let-label="label" let-hideChevron="hideChevron">
  <ion-button
    class="dark-blue-theme light-button"
    (click)="tryNavigateToNext()"
    [disabled]="step && isStepInvalid(step)"
  >
    {{ label || 'account_setup.next' | translate }}
    <ion-icon *ngIf="!hideChevron" name="chevron-forward-outline"></ion-icon>
    <ion-ripple-effect></ion-ripple-effect>
  </ion-button>
</ng-template>
