<cl-waves-background wizard-bg bg-position-bottom>
  <ion-header>
    <cl-header-nav-buttons
      color="primary"
      (closeClick)="onClose()"
      [backButton]="false"
      class="page-header"
    >
    </cl-header-nav-buttons>
  </ion-header>

  <cl-simulation-step
    *ngIf="simulation"
    [(currentCravingTool)]="currentCravingTool"
    (next)="toAfterSimulation()">
  </cl-simulation-step>

  <cl-after-simulation-step
    *ngIf="afterSimulation"
    [(currentCravingTool)]="currentCravingTool"
    (next)="toMindfulEatingExercise()"
    (done)="done()">
  </cl-after-simulation-step>

  <cl-mindful-eating-exercise-craving-tool
    *ngIf="mindfulEating"
    [(currentCravingTool)]="currentCravingTool"
    (next)="toAfterEatingSatisfactionStep()">
  </cl-mindful-eating-exercise-craving-tool>

  <cl-after-eating-satisfaction-step
    *ngIf="afterEatingSatisfaction"
    [(currentCravingTool)]="currentCravingTool"
    (next)="toAfterEatingQuantityStep()">
  </cl-after-eating-satisfaction-step>

  <cl-after-eating-exercise-quantity-step
    *ngIf="afterEatingQuantity"
    [(currentCravingTool)]="currentCravingTool"
    [inMinutes]="firstStepMinutes"
    (next)="schedule5MinuteReminder()"
    (done)="done()">
  </cl-after-eating-exercise-quantity-step>

  <cl-after-minutes-checkin-step
    *ngIf="afterMinutesCheckin"
    [(currentCravingTool)]="currentCravingTool"
    [minutes]="minutesElapsed"
    [hide15Min]="hide15Min"
    [inMinutes]="minutesElapsed > secondStepMinutes ? null : secondStepMinutes"
    (next)="schedule15MinuteReminder()"
    (done)="done()">
  </cl-after-minutes-checkin-step>
</cl-waves-background>
