import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { isPlatform } from '@ionic/angular';

import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { isAuthenticating } from '@mindsciences/clarity-core/app/store/sensitive/selectors/auth.selectors';
import { SessionState } from '@mindsciences/clarity-core/app/store/session/session.reducers';
import { LoadingService } from '@mindsciences/clarity-core/app/services/loading.service';

import { LoggerService } from '@mindsciences/clarity-core/app/services/logger.service';
import { GoogleAuth, User as GoogleAuthUser } from '@codetrix-studio/capacitor-google-auth';
import { LoginWithGoogleToken } from '@mindsciences/clarity-core/app/store/sensitive/actions/auth.actions';

@Injectable({providedIn: 'root'})
export class GoogleAuthService {

  public authenticating$: Observable<boolean> = this.store.select(isAuthenticating);
  private googleAuthData: GoogleAuthUser['authentication'];

  constructor(
    private store: Store<SessionState>,
    private logger: LoggerService,
    private loadingService: LoadingService
  ) {
    if (!isPlatform('capacitor')) {
      GoogleAuth.initialize();
    }
  }

  public init() {
    from(this.triggerGoogleAuthPlugin())
      .pipe(
        map((data: GoogleAuthUser) => this.googleAuthData = data?.authentication)
      )
      .subscribe((googleAuthData: GoogleAuthUser['authentication']) => {
        if (!googleAuthData) { return; }
        this.loadingService.useLoadingObservable(this.authenticating$);
        this.store.dispatch(new LoginWithGoogleToken(this.googleAuthData));
      });
  }

  public async signOut() {
    await GoogleAuth.signOut();
  }

  private async triggerGoogleAuthPlugin(): Promise<GoogleAuthUser> {
    try {
      const data: GoogleAuthUser = await GoogleAuth.signIn();

      return data;
    } catch (error) {
      this.handlePluginError(error);
    }
  }

  private handlePluginError(error) {
    switch (error.code) {
      // Sign in cancelled by the user
      case '-5':
        console.log(error.message);
        break;
      default:
        this.logger.error('GoogleAuthPlugin', error.message, 'google.auth-service -> triggerGoogleAuthPlugin()');
    }

  }
}
