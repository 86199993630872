// user actions

import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { AccountDppSetupPayload, AccountErnSetupPayload } from '../models/account-setup.model';
import { SignupData } from '../models/signup-data.model';
import { MenuItem } from '../../../pages/account/account-menu';
import { ErnContentProgram, UserProgram } from 'src/app/store/normalized/schemas/user.schema';

// signup
export const SIGNUP = '[account] Sign Up';
export const SIGNUP_SUCCESS = '[account] Sign Up Success';
export const SIGNUP_FAIL = '[account] Sign Up Fail';
export const SIGNUP_COMPLETE = '[account] Sign Up Complete';


export const CLEAR_SIGNUP_STATE = '[account] Clear Signup State';

// complete profile
export const ACCOUNT_SETUP_START = '[account] Account Setup Start';
export const ACCOUNT_SETUP_COMPLETE = '[account] Account Setup Complete';

// select program and license
export const SET_USER_PROGRAM = '[account] Set User Program';
export const SET_USER_PROGRAM_FAIL = '[account] Set User Program Fail';
export const SET_USER_PROGRAM_SUCCESS = '[account] Set User Program Success';

// load user
export const LOAD_USER = '[account] Load User';
export const LOAD_USER_FAIL = '[account] Load User Fail';
export const LOAD_USER_SUCCESS = '[account] Load User Success';
export const LOAD_USER_REFRESH = '[account] Load User Refresh';

// load user
export const UPDATE_USER_ACCOUNT = '[account] Update User Account';
export const UPDATE_USER_ACCOUNT_FAIL = '[account] Update User Account Fail';
export const UPDATE_USER_ACCOUNT_SUCCESS = '[account] Update User Account Success';

// account setup step
export const ACCOUNT_SETUP_PROCESS = '[account] Account Setup Process';
export const ACCOUNT_DPP_SETUP_PROCESS = '[account] Account DPP Setup Process';

// onboarding
export const ONBOARDING_START = '[account] Onboarding Start';
export const ONBOARDING_COMPLETED = '[account] Onboarding Completed';

// change password
export const CHANGE_PASSWORD = '[account] Change Password';
export const CHANGE_PASSWORD_SUCCESS = '[account] Change Password Success';
export const CHANGE_PASSWORD_FAIL = '[account] Change Password Fail';

// account menu managment
export const SET_SELECTED_SUB_MENU = '[account] SET SELECTED SUB MENU';
export const SET_SELECTED_MENU_TAB = '[account] SET SELECTED MENU TAB';
export const SET_ROOT_ACCOUNT_MENU = '[account] SET ROOT ACOUNT MENU';
export const SET_IS_EDITING_SETTINGS_GOALS = '[account] SET IS EDITING SETTINGS GOALS';

// ERN DIAGNOSTIC ONBOARDING/ACCOUNT SETUP
export const ACCOUNT_ERN_SETUP_START = '[account] Account ERN Setup Start';
export const ACCOUNT_SETUP_PROCESS_EXTENDED = '[account] Account Setup Process Extended';

export const DEFINE_CONTENT_PROGRAM = '[account] Define Content Program';
export const DEFINE_CONTENT_PROGRAM_SUCCESS = '[account] Define Content Program Success';
export const DEFINE_CONTENT_PROGRAM_FAIL = '[account] Define Content Program Fail';

export const DEFINE_AFTER_MATCH_CONTENT_PROGRAM = '[account] Defind After Match Content Program';
export const DEFINE_AFTER_MATCH_CONTENT_PROGRAM_SUCCESS = '[account] Defind After Match Content Program Success';
export const DEFINE_AFTER_MATCH_CONTENT_PROGRAM_FAIL = '[account] Defind After Match Content Program Fail';

export const CHECK_ERN_PATH = '[account] CHECK ERN PATH';

export class Signup implements Action {
  readonly type = SIGNUP;

  constructor(public payload: SignupData) {
  }
}

export class SignupSuccess implements Action {
  readonly type = SIGNUP_SUCCESS;

  constructor(public payload: SignupData) {

  }
}

export class SignupFail implements Action {
  readonly type = SIGNUP_FAIL;

  constructor(public payload: any) {

  }
}

export class ClearSignupState implements Action {
  readonly type = CLEAR_SIGNUP_STATE;
}

export class SignupComplete implements Action {
  readonly type = SIGNUP_COMPLETE;
}

export class AccountSetupStart implements Action {
  readonly type = ACCOUNT_SETUP_START;
}

export class AccountErnSetupStart implements Action {
  readonly type = ACCOUNT_ERN_SETUP_START;

  constructor(public payload: any) {}
}

export class AccountSetupComplete implements Action {
  readonly type = ACCOUNT_SETUP_COMPLETE;
}

export class AccountSetupProcess implements Action {
  readonly type = ACCOUNT_SETUP_PROCESS;

  constructor(public payload: UserProgram) {}
}

export class AccountDppSetupProcess implements Action {
  readonly type = ACCOUNT_DPP_SETUP_PROCESS;

  constructor(public payload: AccountDppSetupPayload) {}
}

export class AccountSetupProcessExtended implements Action {
  readonly type = ACCOUNT_SETUP_PROCESS_EXTENDED;

  constructor(public payload: AccountErnSetupPayload) {
  }
}

export class SetUserProgram implements Action {
  readonly type = SET_USER_PROGRAM;

  constructor(public payload: any) {
  }
}

export class SetUserProgramFail implements Action {
  readonly type = SET_USER_PROGRAM_FAIL;

  constructor(public payload: any) {
  }
}

export class SetUserProgramSuccess implements Action {
  readonly type = SET_USER_PROGRAM_SUCCESS;

  constructor(public payload: SignupData) {

  }
}

export class LoadUser implements Action {
  readonly type = LOAD_USER;
}

export class LoadUserFail implements Action {
  readonly type = LOAD_USER_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadUserSuccess implements Action {
  readonly type = LOAD_USER_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadUserRefresh implements Action {
  readonly type = LOAD_USER_REFRESH;

  constructor(public payload: any) {
  }
}

export class UpdateUserAccount implements Action {
  readonly type = UPDATE_USER_ACCOUNT;

  constructor(public payload: any, public canDefer = true) {

  }
}

export class UpdateUserAccountFail implements Action {
  readonly type = UPDATE_USER_ACCOUNT_FAIL;

  constructor(public payload: any) {
  }
}

export class UpdateUserAccountSuccess implements Action {
  readonly type = UPDATE_USER_ACCOUNT_SUCCESS;

  constructor(public payload: any, public canDefer = true) {
  }
}

export class OnboardingStart implements Action {
  readonly type = ONBOARDING_START;
}

export class ChangePassword implements Action {
  readonly type = CHANGE_PASSWORD;

  constructor(public payload: any) {}
}

export class ChangePasswordSuccess implements Action {
  readonly type = CHANGE_PASSWORD_SUCCESS;

  constructor(public payload: any) {}
}
export class ChangePasswordFail implements Action {
  readonly type = CHANGE_PASSWORD_FAIL;

  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class SetSelectedSubMenu implements Action {
  readonly type = SET_SELECTED_SUB_MENU;
  constructor(public payload: string) {}
}

export class SetRootAccountMenu implements Action {
  readonly type = SET_ROOT_ACCOUNT_MENU;
  constructor(public payload: MenuItem) {}
}

export class SetSelectedMenuTab implements Action {
  readonly type = SET_SELECTED_MENU_TAB;
  constructor(public payload: string) {}
}

export class SetIsEdditingSettingsGoals implements Action {
  readonly type = SET_IS_EDITING_SETTINGS_GOALS;
  constructor(public payload: boolean) {}
}

export class DefineContentProgram implements Action {
  readonly type = DEFINE_CONTENT_PROGRAM;
  constructor(public payload: ErnContentProgram) {}
}

export class DefineContentProgramSuccess implements Action {
  readonly type = DEFINE_CONTENT_PROGRAM_SUCCESS;
  constructor(public payload: ErnContentProgram) {}
}

export class DefineContentProgramFail implements Action {
  readonly type = DEFINE_CONTENT_PROGRAM_FAIL;
  constructor(public payload: Error) {}
}

export class DefineAfterMatchContentProgram implements Action {
  readonly type = DEFINE_AFTER_MATCH_CONTENT_PROGRAM;
  constructor(public payload: ErnContentProgram) {}
}

export class DefineAfterMatchContentProgramSuccess implements Action {
  readonly type = DEFINE_AFTER_MATCH_CONTENT_PROGRAM_SUCCESS;
}

export class DefineAfterMatchContentProgramFail implements Action {
  readonly type = DEFINE_AFTER_MATCH_CONTENT_PROGRAM_FAIL;
  constructor(public payload: Error) {}
}

export class CheckErnPath implements Action {
  readonly type = CHECK_ERN_PATH;
}

export type AccountActions =
  | Signup
  | SignupFail
  | SignupSuccess
  | ClearSignupState
  | AccountSetupStart
  | AccountSetupComplete
  | AccountSetupProcess
  | AccountDppSetupProcess
  | SetUserProgram
  | SetUserProgramFail
  | SetUserProgramSuccess
  | LoadUser
  | LoadUserFail
  | LoadUserSuccess
  | LoadUserRefresh
  | UpdateUserAccount
  | UpdateUserAccountFail
  | UpdateUserAccountSuccess
  | OnboardingStart
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordFail
  | SetSelectedSubMenu
  | SetRootAccountMenu
  | SetSelectedMenuTab
  | SetIsEdditingSettingsGoals
  | AccountSetupProcessExtended
  | AccountErnSetupStart
  | DefineContentProgram
  | DefineContentProgramSuccess
  | DefineContentProgramFail
  | DefineAfterMatchContentProgram
  | DefineAfterMatchContentProgramSuccess
  | DefineAfterMatchContentProgramFail
  | CheckErnPath;
