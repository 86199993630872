import { ListItem } from '../../../../store/normalized/schemas/list-item.schema';
import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import equal from 'fast-deep-equal';

@Component({
  selector: 'cl-pick-trigger',
  styleUrls: ['../../wizard.scss', 'pick-trigger.components.scss'],
  template: `
  <ion-col class="ion-text-center" [class.lesson]="multipleSelection">
    <h2 class="step-intro intro-question">
      {{'wizards.what_triggered_these_feelings' | translate}}
    </h2>
    <ng-template [ngIf]='multipleSelection'>
      <cl-list-multiple-select
        [items]="_triggers"
        (selectItem)="onSelect($event)"
        [selected]="selected">
      </cl-list-multiple-select>
     </ng-template>

     <ng-template [ngIf]='!multipleSelection'>
       <cl-list-single-select
         [items]="_triggers"
         (selectItem)="onSelect($event)"
         [selected]="selected">
       </cl-list-single-select>
     </ng-template>
  </ion-col>`
})

export class PickTriggerComponent {
  _triggers: ListItem[];
  @Input()
  set triggers(triggers: ListItem[]) {
    if (this._triggers && this._triggers.length && equal(this._triggers, triggers)) {
      return;
    }
    this._triggers = triggers;
  }

  @Input() selected: any;
  @Input() multipleSelection = false;

  @Output() selectItem = new EventEmitter();

  onSelect(event$) {
    this.selectItem.emit(event$);
  }
}
