import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-options-title',
  styleUrls: ['options-title.component.scss'],
  template: `
    <ion-title *ngIf="title">
      {{title | translate}}
    </ion-title>
  `
})
export class OptionsTitleComponent {
  @Input() title;
  @Input() isMenu;
}
