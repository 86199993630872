<cl-waves-background wizard-bg bg-position-bottom>
  <ion-content>
    <ion-header>
      <cl-header-nav-buttons
        color="primary"
        (closeClick)="onClose()"
        [backButton]="false"
        [title]="wizardTitle | translate"
        class="page-header"
      >
      </cl-header-nav-buttons>
    </ion-header>

    <ion-row class="steps-holder lateral-padding" *ngIf="showStepsProgress">
      <ion-col class="ion-padding-horizontal">
        <cl-stepped-progress-bar [steps]="stepsCount" [current]="currentStep"></cl-stepped-progress-bar>
      </ion-col>
    </ion-row>

    <div class="wizard-component" [ngClass]="{ 'padding-top': !showStepsProgress }" #wizardContainer></div>
  </ion-content>
</cl-waves-background>

<div class="controls-holder padding-bottom-iphone-x">
  <div class="controls-wrapper">
    <cl-control-buttons
      [nextLabel]="nextLabel"
      [canBack]="canBack"
      (back)="onBack()"
      [canNext]="canNext"
      (next)="onNext()">
    </cl-control-buttons>
  </div>
</div>
