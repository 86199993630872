import { Injectable } from '@angular/core';

import { HttpProvider } from '../http/http.provider';
import { OfflineHttpProvider } from '../http/offline-http.provider';
import { CravingTool, SmokeCravingTool } from '../../store/normalized/schemas/craving-tool.schema';
import { CountByDay } from 'src/app/store/normalized/schemas/count-by-day.schema';

@Injectable({providedIn: 'root'})
export class CravingToolProvider {

  private cravingToolEndpoint = '/dis_o_meters';

  constructor(
    protected http: HttpProvider,
    private offlineHttp: OfflineHttpProvider
  ) {

  }

  public trackCravingTool(cravingTool: CravingTool, { done = false }) {
    if (cravingTool.eat_amount != null) {
      cravingTool.eat_amount = Math.round(cravingTool.eat_amount);
    }
    if (done) {
      if (cravingTool.id) {
        return this.offlineHttp.patch(`${this.cravingToolEndpoint}/${cravingTool.id}`, cravingTool);
      } else {
        return this.offlineHttp.post(this.cravingToolEndpoint, cravingTool);
      }
    } else {
      if (cravingTool.id) {
        return this.http.patch(`${this.cravingToolEndpoint}/${cravingTool.id}`, cravingTool);
      } else {
        return this.http.post(this.cravingToolEndpoint, cravingTool);
      }
    }
  }

  public trackSmokingCravingTool(cravingTool: SmokeCravingTool) {
    return this.offlineHttp.post(this.cravingToolEndpoint, cravingTool);
  }

  public getCountByDay() {
    return this.http.get<CountByDay>(this.cravingToolEndpoint + '/count_by_day' + '?tz=' + this.getTZ());
  }

  getTZ() {
    let offset = new Date().getTimezoneOffset() / 60;
    let ret = 'GMT';
    if (offset > 0) {
      ret += '-' + offset;
    } else if (offset < 0) {
      offset = -offset;
      ret += '%2B' + offset;
    }

    return ret;
  }
}
