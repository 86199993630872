import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { Observable, Subscription, Subject } from 'rxjs';
import { ClarityConfig } from '../../config/clarity.config';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { takeUntil } from 'rxjs/operators';
import { getSubtitlesLanguage } from '../../store/persistent/media/media.selectors';
import { LoggerService } from '../../services/logger.service';
import { BrightcoveWebPlayerLoaderService } from 'src/app/services/brightcove/brightcove-web-player-loader.service';
import { AlertsService } from '../../services/alerts.service';
import { SetSubtitles } from 'src/app/store/persistent/media/media.actions';
import { PLAYER_ERROR_CODES } from './player-errors.enum';

@Component({
  selector: 'cl-video-player-brightcove-web',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div #videoWrapper></div>
  `
})
export class VideoPlayerBrightcoveWebComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  readonly MINIMUM_PLAYED_SECS = 1;
  @Input() brightcove_key: string;

  @Input() controls: Observable<string>;
  @Input() autoplay: boolean;
  @Input() autoSkip: boolean;
  @Input() autoPauseAfter: number;

  @Output() canPlay = new EventEmitter();
  @Output() canPlayThrough = new EventEmitter();
  @Output() playedMinimum = new EventEmitter();
  @Output() completed = new EventEmitter();
  @Output() autoSkipped = new EventEmitter();
  @Output() autoPaused = new EventEmitter();

  @ViewChild('videoWrapper', { static: true }) videoWrapper: ElementRef;

  player: any;

  @ViewChild('track', { static: true }) trackElement: ElementRef;

  private playerIsPaused = true;

  accountId = this.config.env.brightcove.accountId;
  playerId = this.config.env.brightcove.playerId;

  showTrackElement = false;

  playingStarted = false;
  controlsSubscription: Subscription;

  autoPauseAfterTimeout: any;

  subtitleLanguageSubscription: Subscription;
  selectedSubtitlesOption: string;

  private destroyed$: Subject<void> = new Subject();

  constructor(
    public config: ClarityConfig,
    protected changeDetector: ChangeDetectorRef,
    private store: Store<State>,
    private brightcoveWebPlayerLoaderService: BrightcoveWebPlayerLoaderService,
    private loggerService: LoggerService,
    private alertsService: AlertsService
  ) {
    this.subtitleLanguageSubscription = this.store.select(getSubtitlesLanguage)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((subtitlesLanguage) => {
        this.selectedSubtitlesOption = subtitlesLanguage;
      });

    if (!this.brightcoveWebPlayerLoaderService.isPlayerLoaded()) {
      this.brightcoveWebPlayerLoaderService.forcePlayerLoad();
    }
  }

  get playIcon() {
    return this.stopped ? 'play' : 'pause';
  }

  get stopped() {
    return this.playerIsPaused;
  }

  ngOnInit() {
    this.brightcoveWebPlayerLoaderService.playerLoadPromise
      .then(() => this.load());
  }

  load() {
    if (this.player) {
      // we can get into this situation when
      // switching alternatives files on an exercise
      this.player.dispose();
    }

    const cssClass = this.config.runningOnSafari ? 'vjs-16-9 safari' : 'vjs-16-9';

    // https://docs.videojs.com/tutorial-layout.html
    const playerHTML = `
      <video-js
        id="brightcove-player"
        data-video-id="${this.brightcove_key}"
        data-account="${this.accountId}"
        data-player="${this.playerId}"
        data-embed="default"
        class="${cssClass}"
        controls>
      </video-js>`;

    this.videoWrapper.nativeElement.innerHTML = playerHTML;

    this.player = window['bc']('brightcove-player');

    this.player.on('loadedmetadata', () => {
      if (this.autoplay) {
        // For some reasons, autoplay is failing on web for some users
        // We should investigate more deeply this issue & avoid async process between user interaction & autoplay
        // See CLARITY-1085
        try {
          this.player.play();
        } catch(error) {
          this.loggerService.error('BrightcoveWebVideoPlayerError', error, 'Brightcove web video autoplay failed');
        }
      }
    });

    this.listenToPlayerEvents();

    if (this.controls) {
      this.controlsSubscription = this.controls.pipe(
        takeUntil(this.destroyed$)
      ).subscribe((action) => this.parentPlayControl(action));
    }
  }

  setPlayerSubtitles() {
    // from https://github.com/BrightcoveLearning/18750-automatically-set-caption-language/blob/master/index.html
    const tracks = this.player.textTracks();

    for (let i = 0; i < (tracks.length); i++) {
      const track_language = tracks[i].language.substr(0, 2);

      if (track_language) {
        if (track_language === this.selectedSubtitlesOption) {
          tracks[i].mode = 'showing';
        } else {
          tracks[i].mode = 'disabled';
        }
      }
    }
  }

  ngAfterViewInit() {
    if (!this.brightcove_key) {
      this.loggerService.error('BrightcoveWebVideoPlayerError', 'Video player requested, but no brightcove_key provided');
      this.alertsService.playerError('technical', PLAYER_ERROR_CODES.MISSING_POLICYKEY);
    }
  }

  ngOnChanges(changes) {
    if (changes.brightcove_key) {
      const {brightcove_key} = changes;
      const hasBrightcoveKeyChanges = brightcove_key && brightcove_key.currentValue !== brightcove_key.previousValue && !brightcove_key.firstChange;

      if (!hasBrightcoveKeyChanges) {
        return;
      }

      if (brightcove_key && brightcove_key.currentValue) {
        this.setSrc();
      }
    }
  }

  ngOnDestroy() {
    if(this.player) {
      this.player.dispose();
    }

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  parentPlayControl(action) {
    switch (action) {
      case 'play':
        return this.resumePlay();

      case 'pause':
        return this.pausePlay();

      default:
        return false;
    }
  }

  pausePlay() {
    this.player?.pause();
  }

  resumePlay() {
    setTimeout(() => {
      const ret = this.player.play();

      // on some older Android devices, this will not return a promise
      if (ret && ret.catch) {
        ret.catch((error) => {
          // sometimes the error will not be available so this will throw an exception
          // console.log('Player error occurred', error, this.player.error.code ? this.player.error.code : 'Unknown error code');
          this.loggerService.error('BrightcoveWebVideoPlayerError', error, 'Resume Play Video player failed');
          this.alertsService.playerError('technical', PLAYER_ERROR_CODES.TECHNICAL_ERROR);
        });
      }
    });
  }

  getCurrentTime() {
    return this.player.getPosition();
  }

  setCurrentTime(newTime) {
    this.player.seek(newTime);
  }

  forwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(this.getCurrentTime() + 15);
    this.resumePlay();
  }

  backwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(Math.max(this.getCurrentTime() - 15, 0));
    this.resumePlay();
  }

  handlePlaying() {
    console.log('video - playing');

    this.playerIsPaused = false;

    this.changeDetector.detectChanges();

    this.cancelAutoPauseTimeout();

    if (!this.playingStarted) {
      if (this.playedMinimum.observers.length > 0) {
        setTimeout(() => {
          this.playedMinimum.emit(this.MINIMUM_PLAYED_SECS);
        }, this.MINIMUM_PLAYED_SECS * 1000);
      }

      if (this.autoPauseAfter && this.autoPaused.observers.length > 0) {
        this.autoPauseAfterTimeout = setTimeout(() => {
          this.pausePlay();
          this.exitFullscreen();

          this.autoPaused.emit(this.autoPauseAfter);
        }, this.autoPauseAfter * 1000);
      }
    }

    this.playingStarted = true;

    const textTracks = this.player.textTracks();
    textTracks.on('change', () => {
      console.log('player - subtitle track changed');
      const activeTextTrack: any = Array.from(textTracks).filter((item: any) => item?.mode === 'showing')[0];
      this.selectedSubtitlesOption = activeTextTrack?.language || '';
      this.store.dispatch(new SetSubtitles(this.selectedSubtitlesOption));
    });
  }

  handlePause() {
    console.log('video - pause');

    this.playerIsPaused = true;

    // cancel any autopause if the user pauses manually
    if (this.autoPauseAfterTimeout) {
      this.cancelAutoPauseTimeout();
    }

    this.changeDetector.detectChanges();
  }

  handleEnded() {
    console.log('video - ended');
    this.changeDetector.detectChanges();
    this.completed.emit();
  }

  handleCanPlayThrough(buffer) {
    if (this.autoplay && buffer.bufferPercent === 100) {
      console.log('video - canplaythrough');
      this.canPlayThrough.emit(true);
    }

    this.setPlayerSubtitles();
  }

  handleCanPlay() {
    console.log('video - canplay', this.autoplay, this.autoSkip);

    if (this.autoplay && !this.autoSkip) {
      this.canPlay.emit(true);
      this.resumePlay();
    }

    if (this.autoSkip) {
      this.autoSkipped.emit();
      this.playedMinimum.emit(0);
    }
  }

  enterFullscreen() {
    this.player.setFullscreen(true);
  }

  setSrc() {
    this.playingStarted = false;

    this.load();
  }

  exitFullscreen() {
    if(this.player.isFullscreen()) {
      this.player.exitFullscreen();
    }
  }

  private cancelAutoPauseTimeout() {
    clearTimeout(this.autoPauseAfterTimeout);
    this.autoPauseAfterTimeout = null;
  }

  private handlePlayerError(error) {
    const errorCode = error.target.player.error_.code ? error.target.player.error_.code : PLAYER_ERROR_CODES.TECHNICAL_ERROR;
    const errorInfo = error.target.player.errors.getAll()[errorCode];
    this.loggerService.error('BrightcoveWebVideoPlayerError', errorCode, errorInfo?.headline, {brightcoveKey: this.brightcove_key});
    this.alertsService.playerError('technical', errorCode);
  }

  private listenToPlayerEvents() {
    this.player.on('play', this.handlePlaying.bind(this));
    this.player.on('pause', this.handlePause.bind(this));
    this.player.on('ended', this.handleEnded.bind(this));
    this.player.on('canplaythrough', this.handleCanPlayThrough.bind(this));
    this.player.on('error', this.handlePlayerError.bind(this));

    if(this.config.runningOnSafari) {
      this.brightcoveWebPlayerLoaderService.extendBrightcoveCapabilities(this.player);
      this.replacePlayerElementPositionOnFullscreen();
    };
  }

  // Safari has some bug of playing fullscreen videos depending on which
  // HTML stack this video element is inside. This dirty fix works by
  // placing the element out of this stack right before seting the fullscreen,
  // and returning to normal stack after exit fullscren.
  private replacePlayerElementPositionOnFullscreen() {
    console.log('about to override request fullscreen behavior');

    let playerOriginalParent: HTMLElement;

    // extended by us
    this.player.onBeforeRequestFullscreen(() => {
      const playerElement = this.player.el();

      playerOriginalParent = playerElement.parentElement;

      document.body.appendChild(playerElement); // appending element to the body avoid issues
    });

    // extended by us
    this.player.onExitFullscreen(() => {
      playerOriginalParent.appendChild(this.player.el()); // after close fullscreen, return to the original place
    });
  }

}
