import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment'; // TODO: migrate - check if it still works
import { ClarityConfig } from '../../../config/clarity.config';
import { LiveSubscription } from '../../../store/normalized/selectors/subscription.selectors';

@Component({
  selector: 'cl-subscription-details',
  styleUrls: ['subscription-details.component.scss'],
  template: `
    <p *ngIf="subscription.canBuy" class="no-subscription-info">
      {{'subscriptions.you_have_no_valid_subscription' | translate: {
      trialModules: 'common.' + config.program.trialModules | translate,
      programName: config.program.name
    } }}
    </p>

    <div *ngIf="!subscription.canBuy" class="subscription-status">
      <cl-data-section
        class="subscription-detail"
        title="{{'subscriptions.active' | translate}}"
        [content]="isSubscriptionActive | translate">
      </cl-data-section>

      <cl-data-section
        class="subscription-detail"
        title="{{'subscriptions.status' | translate}}"
        [content]="subscriptionStatus | translate">
      </cl-data-section>

      <cl-data-section
        class="subscription-detail"
        title="{{'subscriptions.billing' | translate}}"
        [content]="subscriptionBilling | translate">
      </cl-data-section>

      <cl-data-section
        class="subscription-detail"
        *ngIf="subscription.isTrialing && !subscription.isCanceled"
        title="{{'subscriptions.starts_on' | translate}}"
        [content]="subscriptionActiveUntil | translate">
      </cl-data-section>

      <cl-data-section
        class="subscription-detail"
        *ngIf="subscription.willRenew && subscription.provider !== 'itunes'"
        title="{{'subscriptions.renews_on' | translate}}"
        [content]="subscriptionActiveUntil  | translate">
      </cl-data-section>

      <cl-data-section
        class="subscription-detail"
        *ngIf="subscription.isCanceled"
        title="{{'subscriptions.expires_on' | translate}}"
        [content]="subscriptionActiveUntilWithTime  | translate">
      </cl-data-section>

      <div *ngIf="subscription.provider === 'itunes'">
        <p class="app-store-notification">
          {{'subscriptions.subscriptions_managed_through_app_store_click_button' | translate}}
        </p>

        <ion-row class="appstore-button">
          <ion-col class="ion-text-center" auto>
            <cl-action-button
              label="{{'subscriptions.itunes_app_store' | translate}}"
              [canClick]="true"
              (click)="onOpenAppStore()">
            </cl-action-button>
          </ion-col>
        </ion-row>
      </div>
    </div>
  `
})

export class SubscriptionDetailsComponent {

  @Input() subscription: LiveSubscription;

  @Output() openAppStore = new EventEmitter();

  constructor(
    public config: ClarityConfig
  ) {

  }

  get isSubscriptionActive() {
    return this.subscription.isActive ? 'common.yes' : 'common.no';
  }

  get subscriptionStatus() {
    if (!this.subscription.isActive) {
      return 'subscriptions.status_expired';
    }

    return `subscriptions.status_${this.subscription.status}`;
  }

  get subscriptionBilling() {
    return `subscriptions.${this.subscription.provider}`;
  }

  get subscriptionActiveUntilWithTime() {
    // for licensed subscriptions, only show the date when it actually expires
    const expiresOnFormat = this.subscription.isLicensed ? 'Do MMMM YYYY' : 'Do MMMM YYYY [at] HH:mm a';

    return this.subscription.active_until
      ? moment(this.subscription.active_until)
        .format(expiresOnFormat)
      : 'subscriptions.unlimited';
  }

  get subscriptionActiveUntil() {
    return this.subscription.active_until
      ? moment(this.subscription.active_until)
        .format('Do MMMM YYYY')
      : 'subscriptions.unlimited';
  }

  onOpenAppStore() {
    this.openAppStore.emit();
  }
}
