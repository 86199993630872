import { Injectable } from '@angular/core';
import { ClarityConfig } from '../config/clarity.config';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';

@Injectable({providedIn: 'root'})
export class PrivacyScreenService {
  constructor(
    public config: ClarityConfig
  ) {
  }

  enable() {
    if (!this.config.isDevice) {
      return;
    }

    return PrivacyScreen.enable();
  }

  disable() {
    if (!this.config.isDevice) {
      return;
    }

    return PrivacyScreen.disable();
  }

}
