import { Action } from '@ngrx/store';

export const RESET_NOTIFICATIONS = '[notifications] Reset';
export const ASK_PERMISSION = '[notifications] Ask Permission';
export const NOTIFICATIONS_NOT_ALLOWED = '[notifications] Not Allowed';
export const NOTIFICATIONS_ALLOWED = '[notifications] Allowed';

// ---------- Inbox notifications -------------
export const LOAD_NOTIFICATIONS = '[notifications] Load 10 Last Notifications';
export const LOAD_NOTIFICATIONS_SUCCESS = '[notifications] Load 10 Last Notifications Success';
export const LOAD_NOTIFICATIONS_FAILURE = '[notifications] Load 10 Last Notifications Failure';

export const LOAD_ALL_NOTIFICATIONS = '[notifications] Load All Notifications';
export const LOAD_ALL_NOTIFICATIONS_SUCCESS = '[notifications] Load All Notifications Success';
export const LOAD_ALL_NOTIFICATIONS_FAILURE = '[notifications] Load All Notifications Failure';

export const COUNT_NOTIFICATIONS = '[notifications] Get Notifications counter';
export const COUNT_NOTIFICATIONS_SUCCESS = '[notifications] Get Notifications counter success';
export const COUNT_NOTIFICATIONS_FAIL = '[notifications] Get Notifications counter fail';

export const MARK_COACH_NOTIFICATIONS_AS_READ = '[notifications] Mark coach notifications as read';
export const MARK_COACH_NOTIFICATIONS_AS_READ_SUCCESS = '[notifications] Mark coach notification as read success';
export const MARK_COACH_NOTIFICATIONS_AS_READ_FAIL = '[notifications] Mark coach notification as read fail';

export const MARK_COMMUNITY_NOTIFICATIONS_AS_READ = '[notifications] Mark Community Notifications As Read';
export const MARK_COMMUNITY_NOTIFICATIONS_AS_READ_SUCCESS = '[notifications] Mark Community Notifications As read Success';
export const MARK_COMMUNITY_NOTIFICATIONS_AS_READ_FAIL = '[notifications] Mark Community Notifications As Read Fail';

// ---------------------------------------------

export class CountNotifications implements Action {
  readonly type = COUNT_NOTIFICATIONS;
}

export class CountNotificationsSuccess implements Action {
  readonly type = COUNT_NOTIFICATIONS_SUCCESS;
  constructor(public payload: number) {}
}

export class CountNotificationsFail implements Action {
  readonly type = COUNT_NOTIFICATIONS_FAIL;
}

export class ResetNotifications implements Action {
  readonly type = RESET_NOTIFICATIONS;
}

export class AskForNotificationPermission implements Action {
  readonly type = ASK_PERMISSION;
}

export class NotificationsNotAllowed implements Action {
  readonly type = NOTIFICATIONS_NOT_ALLOWED;
}

export class NotificationsAllowed implements Action {
  readonly type = NOTIFICATIONS_ALLOWED;
}

export class LoadNotifications implements Action {
  readonly type = LOAD_NOTIFICATIONS;
}

export class LoadNotificationsSuccess implements Action {
  readonly type = LOAD_NOTIFICATIONS_SUCCESS;
}

export class LoadNotificationsFailure implements Action {
  readonly type = LOAD_NOTIFICATIONS_FAILURE;
}

export class LoadAllNotifications implements Action {
  readonly type = LOAD_ALL_NOTIFICATIONS;
}

export class LoadAllNotificationsSuccess implements Action {
  readonly type = LOAD_ALL_NOTIFICATIONS_SUCCESS;
}

export class LoadAllNotificationsFailure implements Action {
  readonly type = LOAD_ALL_NOTIFICATIONS_FAILURE;
}

export class MarkCommunityNotificationsAsRead implements Action {
  readonly type = MARK_COMMUNITY_NOTIFICATIONS_AS_READ;
}
export class MarkCoachNotificationsAsRead implements Action {
  readonly type = MARK_COACH_NOTIFICATIONS_AS_READ;
}

export class MarkCoachNotificationsAsReadSuccess implements Action {
  readonly type = MARK_COACH_NOTIFICATIONS_AS_READ_SUCCESS;
}

export class MarkCoachNotificationsAsReadFail implements Action {
  readonly type = MARK_COACH_NOTIFICATIONS_AS_READ_FAIL;
}

export class MarkCommunityNotificationsAsReadSuccess implements Action {
  readonly type = MARK_COMMUNITY_NOTIFICATIONS_AS_READ_SUCCESS;
}

export class MarkCommunityNotificationsAsReadFail implements Action {
  readonly type = MARK_COMMUNITY_NOTIFICATIONS_AS_READ_FAIL;
}

export type NotificationsActions =
  MarkCoachNotificationsAsReadSuccess
  | MarkCoachNotificationsAsReadFail
  | MarkCommunityNotificationsAsRead
  | MarkCoachNotificationsAsRead
  | CountNotificationsFail
  | CountNotifications
  | CountNotificationsSuccess
  | AskForNotificationPermission
  | NotificationsNotAllowed
  | NotificationsAllowed
  | ResetNotifications
  | LoadNotifications
  | LoadNotificationsSuccess
  | LoadNotificationsFailure
  | LoadAllNotifications
  | LoadAllNotificationsSuccess
  | LoadAllNotificationsFailure
  | MarkCommunityNotificationsAsReadSuccess
  | MarkCommunityNotificationsAsReadFail;
