// persistent flags reducer

import * as interstitialsActions from './interstitials.actions';

export interface InterstitialsState {
  firstBonusExerciseCompleted: boolean;
  firstCheckInCompleted: boolean;
  firstStressTestCompleted: boolean;
  firstStressMeterCompleted: boolean;
}

export const initialPersistentInterstitialState: InterstitialsState = {
  firstBonusExerciseCompleted: false,
  firstCheckInCompleted: false,
  firstStressTestCompleted: false,
  firstStressMeterCompleted: false
};

export function persistentInterstitialReducer(state = initialPersistentInterstitialState,
  action: interstitialsActions.InterstitialsActions): InterstitialsState {

  switch (action.type) {
    case interstitialsActions.SET_FIRST_ACTION_COMPLETED: {
      const actiontype = action.payload;

      return {
        ...state,
        [actiontype]: true
      };
    }
  }

  return state;
}

export const firstBonusExerciseCompleted = (state: InterstitialsState) => state.firstBonusExerciseCompleted;
export const firstCheckInCompleted = (state: InterstitialsState) => state.firstCheckInCompleted;
export const firstStressTestComplited = (state: InterstitialsState) => state.firstStressTestCompleted;
export const firstStressMeterComplited = (state: InterstitialsState) => state.firstStressMeterCompleted;
