import { Component } from '@angular/core';

@Component({
  selector: 'cl-stress-test-button',
  styleUrls: ['stress-test-button.component.scss'],
  template: `
    <button class="stress-test">
      <div class="icon">
        <img [src]="'assets/imgs/arrow.png'"/>
      </div>
      <span>{{'wizards.stress_test' | translate}}</span>
    </button>
  `
})
export class StressTestButtonComponent {
}
