import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { State } from '../../store/state.reducer';
import { Store } from '@ngrx/store';
import * as navigationActions from '../../store/session/actions/navigation.actions';

@Component({
  selector: 'page-next-steps',
  styleUrls: ['next-steps.scss'],
  templateUrl: 'next-steps.html'
})
export class NextStepsPage {

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public store: Store<State>
  ) {
  }

  get actAsNextWeek() {
    return this.navParams.get('lastWeekType') !== 'program';
  }

  onGoToThemeWeeks() {
    this.store.dispatch(new navigationActions.OpenModal('SelectThemePage', {
      enterAnimation: 'IonicModalAnimations.slideEnterAnimation',
      leaveAnimation: 'IonicModalAnimations.slideLeaveAnimation'
    }));
  }

  onRestartProgram() {
    this.store.dispatch(new navigationActions.OpenModal('RestartProgramPage', {
      enterAnimation: 'IonicModalAnimations.slideEnterAnimation',
      leaveAnimation: 'IonicModalAnimations.slideLeaveAnimation'
    }));
  }

  onNewCustomWeek() {
    this.store.dispatch(new navigationActions.OpenModal('NewCustomWeekPage', {
      enterAnimation: 'IonicModalAnimations.slideEnterAnimation',
      leaveAnimation: 'IonicModalAnimations.slideLeaveAnimation'
    }));
  }
}
