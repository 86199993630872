import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { AccountSetupPage } from './account-setup';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared/index';
import { translateConfig } from '../../config/translate.config';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DppAccountSetupPage } from './dpp/dpp-account-setup';
import { ErnAccountSetupPage } from './ern/ern-account-setup';
import { AccountSetupGuard } from './guards/account-setup.guard';
import { AccountSetupDppGuard } from './guards/account-setup-dpp.guard';
import { AccountSetupErnGuard } from './guards/account-setup-ern.guard';
import { SwiperModule } from 'swiper/angular';
import { DppSetupConfirmExitModalComponent } from './dpp/confirm-exit-modal';

const routes: Routes = [
  {
    path: '',
    component: AccountSetupPage,
    canActivate: [AccountSetupGuard]
  },
  {
    path: 'dpp',
    component: DppAccountSetupPage,
    canActivate: [AccountSetupDppGuard]
  },
  {
    path: 'ern',
    component: ErnAccountSetupPage,
    canActivate: [AccountSetupErnGuard]
  }
];

@NgModule({
  declarations: [
    AccountSetupPage,
    DppAccountSetupPage,
    ErnAccountSetupPage,
    DppSetupConfirmExitModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(translateConfig),
    SharedModule,
    SwiperModule
  ]
})
export class AccountSetupPageModule {}
