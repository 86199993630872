<ion-content>
  <div class="flex-wrapper">
    <ion-header>
      <cl-header-nav-buttons
        color="blank"
        title="{{ header | translate }}"
        (closeClick)="onClose()"
        class="page-header"
        [backButton]="showBackButton">
      </cl-header-nav-buttons>
    </ion-header>

    <div class="page-wrapper">
      <div class="section-holder padding lateral-padding">
        <h3 *ngIf="headline">{{ headline | translate }}</h3>

        <ion-row class="icon-holder" *ngIf="icon">
          <ion-col>
            <ion-icon name="{{ icon }}"></ion-icon>
          </ion-col>
        </ion-row>

        <ion-row class="section-explainer ion-text-center">
          <ion-col>
            <p *ngIf="content" [innerHTML]="content | translate"></p>
            <div #componentContainer></div>
          </ion-col>
        </ion-row>

        <ion-row class="button-holder ion-text-center" *ngIf="actionText && actionCb">
          <ion-col>
            <cl-action-button
              [label]="actionText | translate"
              [canClick]="true"
              [square]="true"
              (click)="actionCb()">
            </cl-action-button>
          </ion-col>
        </ion-row>
      </div>
    </div>

    <div class="waves-bg"></div>
  </div>
</ion-content>
