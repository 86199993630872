import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstName'
})

export class FirstNamePipe implements PipeTransform {
  transform(username: string): string {
    if (!username) {
      return '';
    }

    return username.split(' ')[0];
  }
}
