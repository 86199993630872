<cl-waves-background intro-bg [ngClass]="{'desktop signup': isDesktop}">

  <ion-header>
    <cl-header-nav-buttons
      class="page-header"
      [closeButton]="false"
      (back)="goBack()"
      color="{{ !isDesktop ? 'primary' : 'white' }}">
    </cl-header-nav-buttons>
  </ion-header>

  <ion-content class="ion-padding-top">
    <cl-registration-wrapper
      *ngIf="hasAppStoreProducts || (!config.isDevice && !(isLoadingStripeProducts$ | async)); else loadingTemplate"
      class="lateral-padding">

      <ng-container *ngIf="config.isDevice">
        <ion-row class="logo">
          <ion-col class="padding-top-iphone-x">
            <cl-icon-title-container
              header="{{ 'subscriptions.subscription' | translate }}"
              [showCompany]="false"
              intro="{{ 'subscriptions.use_one_of_the_options_below_to_subscribe' | translate }}"
              subIntro="{{ 'subscriptions.in_case_you_purchased_click_restore' | translate }}"
              subHeader="{{ 'subscriptions.subscription' | translate }}">
            </cl-icon-title-container>
          </ion-col>
        </ion-row>

        <cl-price-card
          *ngFor="let product of getAppStoreProducts()"
          [length]="getProductLengthTranslateKey(product.id) | translate"
          [price]="product.price"
          [pricePerWeek]="getProductPricePerWeekTranslateKey(product.id) | translate : getProductPricePerWeekParams(product)"
          [specialDiscount]="getProductDiscountSpecial(product.id)"
          [selected]="product.state === 'initiated' || product.state === 'requested'"
          (purchase)="onOrder(product.id);">
        </cl-price-card>
      </ng-container>

      <ng-container *ngIf="!config.isDevice">
        <ion-row class="logo">
          <ion-col class="padding-top-iphone-x">
            <cl-icon-title-container
              header="{{ 'subscriptions.subscription' | translate }}"
              [showCompany]="false"
              intro="{{ 'subscriptions.use_one_of_the_options_below_to_subscribe' | translate }}"
              subHeader="{{ 'subscriptions.subscription' | translate }}">
            </cl-icon-title-container>
          </ion-col>
        </ion-row>

        <div class="stripe-products">
          <cl-price-card
            *ngFor="let product of (stripeProducts$ | async)"
            [length]="product.length"
            [price]="product.priceFormatted"
            [fullPrice]="product.fullPriceFormatted"
            [pricePerWeek]="getProductPricePerWeekTranslateKey(product) | translate : getProductPricePerWeekParams(product)"
            [specialDiscount]="getProductDiscountSpecial(product)"
            [selected]="false"
            (purchase)="openStripeFormModal(product);">
          </cl-price-card>
        </div>

        <ion-row class="coupon-code-button">
          <ion-col class="ion-text-center">
            <cl-action-button
              [label]="'subscriptions.do_you_have_a_coupon_code' | translate"
              [whiteBg]="true"
              [noPadding]="true"
              [canClick]="true"
              (action)="showCouponPromptForm()">
            </cl-action-button>
          </ion-col>
        </ion-row>
      </ng-container>

      <p *ngIf="config.isDevice && config.isIos"
         class="subscription-terms">
        <span [innerHTML]="'subscriptions.upgrade_details_itunes' | translate"></span>
        <span><a (click)="openPrivacyTerms()">{{ 'subscriptions.privacy_policy_terms_use' | translate }}</a>.</span>
      </p>

      <p *ngIf="config.isDevice && config.isAndroid"
         class="subscription-terms">
        <span [innerHTML]="'subscriptions.upgrade_details_googleplay' | translate"></span>
        <span><a (click)="openPrivacyTerms()">{{ 'subscriptions.privacy_policy_terms_use' | translate }}</a>.</span>
      </p>

      <p *ngIf="!config.isDevice" class="subscription-terms">
        <span [innerHTML]="'subscriptions.upgrade_details_stripe' | translate"></span>
        <span><a (click)="openPrivacyTerms()">{{ 'subscriptions.privacy_policy_terms_use' | translate }}</a>.</span>
      </p>
    </cl-registration-wrapper>
  </ion-content>
</cl-waves-background>

<ng-template #loadingTemplate>
  <cl-registration-wrapper class="lateral-padding">
    <ion-row class="logo">
      <ion-col class="padding-top-iphone-x">
        <cl-icon-title-container
          header="{{ 'subscriptions.subscription' | translate }}"
          [showCompany]="false">
        </cl-icon-title-container>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-text-center">
        <h3 class="loading-screen">
          <ion-spinner></ion-spinner>
          {{ 'subscriptions.loading_products' | translate }}
        </h3>
        <p *ngIf="config.isDevice && config.isAndroid" class="app-store-login-notification">
          {{ 'subscriptions.check_playstore_account_settings_to_continue' | translate }}
        </p>
        <p *ngIf="config.isDevice && config.isIos" class="app-store-login-notification">
          {{ 'subscriptions.check_itunes_account_settings_to_continue' | translate }}
        </p>
      </ion-col>
    </ion-row>
  </cl-registration-wrapper>
</ng-template>
