import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import {
  ERROR_CONNECTION_TIMEOUT,
  ERROR_INVALID_CREDENTIALS,
  ERROR_INVALID_CREDENTIALS_TOKEN,
  ERROR_INVALID_INPUT,
  ERROR_REQUEST_LIMIT_EXCEEDED } from '../../../config/error.handler';
import { ClarityConfig } from '../../../config/clarity.config';
import { AuthProvider } from '../../../providers/auth.provider';
import { ToastService } from '../../../services/toast.service';
import { LoadingService } from '../../../services/loading.service';
import { LoggerService } from '../../../services/logger.service';

import * as authActions from '../actions/auth.actions';
import * as accountActions from '../../session/actions/account.actions';
import * as flagActions from '../../persistent/flags/flags.actions';
import * as navigationActions from '../../session/actions/navigation.actions';
import { SessionState } from '../../session/session.reducers';
import { getAuthError, getAuthToken, isAuthenticating } from '../selectors/auth.selectors';
import {AlertsService} from '../../../services/alerts.service';
import { EventsService } from 'src/app/services/events.service';
import { ProgramTypeService } from '../../../services/program-type.service';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CredentialsCheckPassword, CredentialsReset } from '../../session/models/credentials.model';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';
import { HttpErrorResponse } from '@angular/common/http';
import { OpenBottomSheetModal } from '../../session/actions/navigation.actions';
import { UserProvider } from '../../../providers/user.provider';
import { GoogleAuthService, SharecareSSOAuthError } from '@mindsciences/login-providers';
import { NotificationsService } from 'src/app/services/notification.service';

@Injectable({providedIn: 'root'})
export class AuthEffects {


  login$ = createEffect(() => this.actions$.pipe(ofType(authActions.LOGIN),
    map((action: authActions.Login) => action.payload),
    switchMap((credentials) => this.authProvider.doLogin(credentials)
      .pipe(
        map(token => new authActions.LoginSuccess(token)),
        // some login errors (i.e. 422) will trigger a signup and the credentials are needed
        catchError((error) => {
          if (error.status === 409) {
            this.loading.hideLoadingOverlay();
            this.alerts.managedBySharecare();

            return of(new authActions.ResetAuthenticating());
          } else {
            return of(
              new authActions.LoginFail({
                error,
                credentials
              }
              ));
          }
        })
      ))
  ));


  loginWithAuthorizationCode$ = createEffect(() => this.actions$.pipe(ofType(authActions.LOGIN_WITH_AUTHORIZATION_CODE),
    map((action: authActions.LoginWithAuthorizationCode) => action.payload),
    switchMap((params) => this.authProvider.doLoginWithAuthorizationCode(params)
      .pipe(
        map(token => new authActions.LoginSuccess(token)),
        // some login errors (i.e. 422) will trigger a signup and the credentials are needed
        catchError(error => {
          if (error.status === 403 || error.status === 422) {
            // it's not clear if we still need this, the detailed popup below should cover all cases
            // // 403 User is not eligible
            // if (error.status === 403) {
            //   this.loadingService.hideLoadingOverlay();
            //   this.toasts.error(this.translate.get('errors.auth.not_eligible_contact_provider'));
            //
            //   return of({type: 'noop'});
            // }

            this.loading.hideLoadingOverlay();

            switch (error.message) {
              case 'user_already_has_a_licence_subscription':
                this.alerts.sharecareError(SharecareSSOAuthError.user_already_has_a_licence_subscription);
                break;
              case 'user_already_has_a_itunes_subscription':
                this.alerts.sharecareError(SharecareSSOAuthError.user_already_has_a_itunes_subscription);
                break;
              case 'user_already_has_a_googleplay_subscription':
                this.alerts.sharecareError(SharecareSSOAuthError.user_already_has_a_googleplay_subscription);
                break;
              case 'user_already_has_a_stripe_subscription':
                this.alerts.sharecareError(SharecareSSOAuthError.user_already_has_a_stripe_subscription);
                break;
              default:
                this.alerts.sharecareError(SharecareSSOAuthError.not_qualified_error);
            }

            return of({type: 'noop'});
          }

          return of(new authActions.LoginFail({error}));
        })
      ))
  ));


  loginWithPreauthToken$ = createEffect(() => this.actions$.pipe(ofType(authActions.LOGIN_WITH_PREAUTH_TOKEN),
    map((action: authActions.LoginWithPreauthToken) => action.payload),
    switchMap((params) => this.authProvider.doLowingWithPreauthToken(params)
      .pipe(
        map(token => new authActions.LoginSuccess(token)),
        // some login errors (i.e. 422) will trigger a signup and the credentials are needed
        catchError(error => {
          // 403 User is not eligible
          if (error.status === 403) {
            this.loadingService.hideLoadingOverlay();
            this.toasts.error(this.translate.get('errors.auth.not_eligible_contact_provider'));

            return of({type: 'noop'});
          }

          return of(new authActions.LoginFail({error}));
        })
      ))
  ));

  loginWithGoogleToken$ = createEffect(() => this.actions$.pipe(ofType(authActions.LOGIN_WITH_GOOGLE_TOKEN),
    map((action: authActions.LoginWithGoogleToken) => action.payload),
    switchMap((params) => this.authProvider.doLoginWithGoogle(params)
      .pipe(
        map(token => new authActions.LoginSuccess(token)),
        catchError(error => of(new authActions.LoginFail({error})))
      ))
  ));


  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.RESET_PASSWORD),
      map((action: authActions.ResetPassword) => action.payload),
      switchMap((credentialsReset: CredentialsReset) => this.authProvider.resetPassword(credentialsReset)
        .pipe(
          tap(() => {
            this.translate
              .get(['auth.reset_password', 'auth.password_reset_success', 'common.ok'])
              .subscribe(async (translations) => {
                const alert = await this.alerts.alertController.create({
                  header: translations['auth.reset_password'],
                  message: `${translations['auth.password_reset_success']}`,
                  buttons: [
                    {
                      text: translations['common.ok']
                    }]
                });

                await alert.present();
              });
          }),
          map(() => new authActions.ResetPasswordSuccess(new Date().toISOString())),
          catchError((error) => of(new authActions.ResetPasswordFail(error)))
        )
      )
    )
  );

  resetPasswordFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.RESET_PASSWORD_FAIL),
      withLatestFrom(this.store.select(getAuthError)),
      tap(([_, resetPasswordError]) => {
        this.loading.hideLoadingOverlay();

        switch (resetPasswordError.type) {
          case ERROR_CONNECTION_TIMEOUT:
            return this.toasts.error(this.translate.get('errors.common.network_error'));
          case ERROR_REQUEST_LIMIT_EXCEEDED:
            return this.toasts.error(this.translate.get('errors.common.account_locked'));
          default:
            this.logger.error('apiError', resetPasswordError.httpError, 'resetPassword$');

            return this.toasts.error(this.translate.get('errors.common.generic_error_please_retry'));
        }
      })
    ),
  { dispatch: false }
  );

  loginCompleted$ = createEffect(() => this.actions$.pipe(ofType<authActions.LoginComplete>(authActions.LOGIN_COMPLETE),
    map(() => {
      const hash = window.location.hash;
      if (this.config.program.programCode === 'ctq') {
        if (hash.includes('/tabs/posts')) {
          return { type: 'noop' };
        }

        return new navigationActions.GotoHome();
      } if (this.config.programDPPorWL &&
        (hash.includes(this.config.env.fitbitCallbackWeightPath) || hash.includes(this.config.env.fitbitCallbackDevicesPath))) {
        return { type: 'noop' };
      } else {
        return new navigationActions.GotoDashboard();
      }
    })
  ));


  loginSuccess$ = createEffect(() => this.actions$.pipe(ofType(authActions.LOGIN_SUCCESS),
    tap(() => this.store.dispatch(new flagActions.SetFlag('firstLoginCompleted'))),
    map(() => new accountActions.LoadUser())
  ));


  loginFailed$ = createEffect(() => this.actions$.pipe(ofType<authActions.LoginFail>(authActions.LOGIN_FAIL),
    map((action) => action.payload),
    concatMap(loginFailError => of(loginFailError)
      .pipe(withLatestFrom(this.store.select(getAuthError)))
    ),
    tap(([loginFailError, authError]) => {
      this.loadingService.hideLoadingOverlay();

      // handle errors
      switch (authError.type) {
        case ERROR_INVALID_CREDENTIALS:
          return this.toasts.error(this.translate.get('errors.auth.invalid_credentials'));
        case ERROR_INVALID_CREDENTIALS_TOKEN:
          return this.toasts.error(this.translate.get('errors.auth.invalid_token'));
        case ERROR_CONNECTION_TIMEOUT:
          return this.toasts.error(this.translate.get('errors.common.network_error'));
        case ERROR_REQUEST_LIMIT_EXCEEDED:
          return;
        case ERROR_INVALID_INPUT: {
          // user exists, but is registered for a different program, we need to sign them up for this one also
          const userProgram = Object.assign(
            {},
            loginFailError.credentials ? loginFailError.credentials : {},
            {
              account_attributes: {
                program_code: this.config.currentProgramCode
                  .toUpperCase(),
                agree_to_tos: true,
                license_code: null
              }
            }
          );

          this.loadingService.useLoadingObservable(this.store.select(isAuthenticating));

          return this.store.dispatch(new accountActions.SetUserProgram(userProgram));
        }
        default:
          return this.toasts.error(this.translate.get('errors.common.generic_error_please_retry'));
      }

    })
  ), {dispatch: false});

  logoutAlert$ = createEffect(() => this.actions$.pipe(
    ofType<authActions.LogoutAlert>(authActions.LOGOUT_ALERT),
    tap(() => {
      this.translate.get([
        'auth.logout_confirmation.alert_header',
        'auth.logout_confirmation.alert_message',
        'auth.logout_confirmation.confirm_action',
        'auth.logout_confirmation.cancel_action'
      ])
        .toPromise()
        .then(async (translations) => {
          const alertPopup = await this.alerts.alertController.create({
            header: translations['auth.logout_confirmation.alert_header'],
            message: translations['auth.logout_confirmation.alert_message'],
            buttons: [{
              text: translations['auth.logout_confirmation.cancel_action'],
              handler: () => this.store.dispatch(new authActions.LogoutAlertCanceled())
            },
            {
              text: translations['auth.logout_confirmation.confirm_action'],
              handler: () => this.store.dispatch(new authActions.Logout())
            }]
          });
          await alertPopup.present();
        });
    })
  ), {dispatch: false});

  logout$ = createEffect(() => this.actions$.pipe(
    ofType<authActions.Logout | authActions.LogoutFromError>(authActions.LOGOUT, authActions.LOGOUT_FROM_ERROR),
    withLatestFrom(this.store.select(getAuthToken)),
    tap(([action, token]) => {
      if (token) {
        this.authProvider.doLogout().subscribe();
      }

      if (this.config.isGoogleAuthEnabled()) {
        this.googleAuthService.signOut();
      }

      if (action.type === authActions.LOGOUT) {
        this.analyticsService.trackEvent(AnalyticsEvents.UserLogout);
        this.store.dispatch(new navigationActions.GotoLogin());
        this.programTypeService.restoreDefault();
      }

      this.events.publish(this.config.events.logout);
    }),
    delay(100), // wait for the published logout event to propagate
    map(() => new authActions.ResetStateOnLogout())
  ));

  resetStateOnLogout$ = createEffect(() => this.actions$.pipe(ofType(authActions.RESET_STATE_ON_LOGOUT),
    map(() => new authActions.LoggedOut())
  ));

  setIridiumHost$ = createEffect(() => this.actions$.pipe(ofType(authActions.SET_IRIDIUM_HOST),
    // we want to redispatch the "SetIridiumHost" with the default one from config if none was provided by Clarion
    filter((action: authActions.SetIridiumHost) => !action.payload),
    map(() => {
      const iridiumHost = `${(this.config.env.iridiumApiUseSsl ? 'https://' : 'http://') + this.config.env.iridiumApiHost}`;

      return new authActions.SetIridiumHost(iridiumHost);
    })
  ));

  checkPassword$ = createEffect(() => this.actions$.pipe(ofType(authActions.CHECK_PASSWORD),
    map((action: authActions.CheckPassword) => action.payload),
    switchMap((credentials: CredentialsCheckPassword) => this.authProvider.checkPassword(credentials)
      .pipe(
        map(response => response.valid),
        map((valid) => valid ? new authActions.CheckPasswordSuccess() : new authActions.CheckPasswordFail()),
        catchError((error: HttpErrorResponse) => of(new authActions.CheckPasswordFail({ error })))
      )
    )
  ));

  deleteAccount$ = createEffect(() => this.actions$.pipe(ofType(authActions.DELETE_ACCOUNT),
    map((action: authActions.DeleteAccount) => action.payload),
    switchMap(reasons => this.userProvider.deleteAccount(reasons)
      .pipe(
        map(() => {
          this.analyticsService.trackEvent(AnalyticsEvents.UserDeleteAccount, { reasons });

          return new authActions.DeleteAccountSuccess();
        }),
        catchError(() => of(new authActions.DeleteAccountFailed()))
      ))
  ));

  deleteAccountFailed$ = createEffect(() => this.actions$.pipe(ofType(authActions.DELETE_ACCOUNT_FAIL),
    tap(() => {
      this.loadingService.hideLoadingOverlay();
      this.alerts.genericError();
    })
  ), { dispatch: false});

  deleteAccountSuccess$ = createEffect(() => this.actions$.pipe(ofType(authActions.DELETE_ACCOUNT_SUCCESS),
    withLatestFrom(this.store.select(getAuthToken)),
    tap(([_, token]) => {
      if (token) {
        this.authProvider.doLogout().subscribe();
      }

      this.store.dispatch(new navigationActions.CloseAllModals());
      this.store.dispatch(new navigationActions.GotoDeleteAccountThankYou());
      this.notificationsService.cancelAllNotifications();
      this.programTypeService.restoreDefault();
      this.events.publish(this.config.events.logout);
    }),
    delay(100), // wait for the published logout event to propagate
    map(() => new authActions.ResetStateOnAccountDeletion())
  ));

  checkPasswordFailed$ = createEffect(() => this.actions$.pipe(ofType<authActions.CheckPasswordFail>(authActions.CHECK_PASSWORD_FAIL),
    map((action) => action.payload),
    concatMap(checkPasswordFailedError => of(checkPasswordFailedError)
      .pipe(withLatestFrom(this.store.select(getAuthError)))
    ),
    tap(([_, authError]) => {
      this.loadingService.hideLoadingOverlay();

      // handle errors
      switch (authError?.type) {
        case ERROR_INVALID_CREDENTIALS:
        case null:
          return this.toasts.error(this.translate.get('errors.auth.invalid_password'));
        case ERROR_CONNECTION_TIMEOUT:
          return this.toasts.error(this.translate.get('errors.common.network_error'));
        case ERROR_REQUEST_LIMIT_EXCEEDED:
          return;
        default:
          return this.toasts.error(this.translate.get('errors.common.generic_error_please_retry'));
      }
    })
  ), {dispatch: false});

  checkPasswordSuccess$ = createEffect(() => this.actions$.pipe(ofType(authActions.CHECK_PASSWORD_SUCCESS),
    tap(() => {
      this.loadingService.hideLoadingOverlay();
      this.store.dispatch(new OpenBottomSheetModal('AccountDeletionConfirmationPage'));
    })
  ), { dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<SessionState>,
    public config: ClarityConfig,
    private authProvider: AuthProvider,
    private userProvider: UserProvider,
    private translate: TranslateService,
    private toasts: ToastService,
    private alerts: AlertsService,
    private loading: LoadingService,
    private logger: LoggerService,
    private events: EventsService,
    private loadingService: LoadingService,
    private programTypeService: ProgramTypeService,
    private analyticsService: AnalyticsService,
    private googleAuthService: GoogleAuthService,
    private notificationsService: NotificationsService
  ) {
  }
}
