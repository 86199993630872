import { createSelector } from '@ngrx/store';
import { createSchemaSelectors } from 'ngrx-normalizr';
import { ListItem, listItemSchema } from '../schemas/list-item.schema';

const listItemsSchemaSelector = createSchemaSelectors<ListItem>(listItemSchema);

export const getNormalizedListItems = createSelector(
  listItemsSchemaSelector.getNormalizedEntities,
  (entities) => entities.listItems
);

export const getFeelings = createSelector(
  listItemsSchemaSelector.getEntities,
  (items) => {
    if (! items) {
      return [];
    }

    return items.filter((item) => item.type === 'Feeling');
  }
);

export const getTriggers = createSelector(
  listItemsSchemaSelector.getEntities,
  (items) => {
    if (! items) {
      return [];
    }

    return items.filter((item) => item.type === 'Trigger');
  }
);

export const getGoals = createSelector(
  listItemsSchemaSelector.getEntities,
  (items) => {
    if (! items) {
      return [];
    }

    return items.filter((item) => item.type === 'Goal');
  }
);

export const getBodyParts = createSelector(
  listItemsSchemaSelector.getEntities,
  (items) => {
    if (! items) {
      return [];
    }

    return items.filter((item) => item.type === 'BodyPart');
  }
);
export const getBodySides = createSelector(
  listItemsSchemaSelector.getEntities,
  (items) => {
    if (! items) {
      return [];
    }

    return items.filter((item) => item.type === 'BodySide');
  }
);
export const getSensations = createSelector(
  listItemsSchemaSelector.getEntities,
  (items) => {
    if (! items) {
      return [];
    }

    return items.filter((item) => item.type === 'Sensation');
  }
);
