import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'cl-likes-avatars',
  templateUrl: './likes-avatars.component.html',
  styleUrls: ['likes-avatars.component.scss']
})
export class LikesAvatarsComponent implements OnChanges {
  @Input() likes = null;
  @Input() max = 9;

  avatars = [];

  ngOnChanges(changes) {    
    this.updateAvatars(changes.likes.currentValue.list);
  }

  private updateAvatars(avatars: any[]) {
    this.avatars = avatars.slice()
      .sort((avatarA, avatarB) => {
        if (!avatarA.created_at && !avatarB.created_at) {
          return 0;
        }

        if (!avatarA.created_at) {
          return -1;
        }

        if (!avatarB.created_at) {
          return 1;
        }

        return new Date(avatarB.created_at).getTime() - new Date(avatarA.created_at).getTime();
      })
      .slice(0, this.max);
  }
}
