import { ProgramType } from '../store/normalized/schemas/user.schema';

export function userDppwlTypeToEnum(dppWlType: 'dpp' | 'wl') {
  switch (dppWlType?.toLocaleLowerCase()) {
    case ProgramType.DPP:
      return ProgramType.DPP;

    case ProgramType.WL:
      return ProgramType.WL;

    case ProgramType.ERN:
      return ProgramType.ERN;

    // by default this will not be used
    default:
      return null;
  }
}
