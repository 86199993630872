import { Component, Input, ComponentRef, ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { State } from '../../store/state.reducer';

@Component({
  selector: 'cl-empty-modal',
  styleUrls: ['empty-modal.component.scss'],
  templateUrl: 'empty-modal.component.html'
})
export class EmptyModalComponent {

  modal;
  componentRef: ComponentRef<any>;

  @ViewChild('componentContainer', {static: true, read: ViewContainerRef}) componentContainer: ViewContainerRef;

  @Input() content: string;

  @Input()
  set embedComponent(value: any) {
    this.componentRef = this.componentContainer.createComponent(this.resolver.resolveComponentFactory<any>(value));
    this._setComponentProperties();
  }

  _componentProps;
  @Input()
  set componentProps(value: any) {
    this._componentProps = value;
    this._setComponentProperties();
  }

  constructor(
    private resolver: ComponentFactoryResolver,
    public navCtrl: NavController,
    public store: Store<State>
  ) { }

  onClose() {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id, role: 'close-btn'}));
  }

  private _setComponentProperties() {
    if (this._componentProps && this.componentRef) {
      Object.keys(this._componentProps)
        .forEach((key) => {
          this.componentRef.instance[key] = this._componentProps[key];
        });
    }
  }
}
