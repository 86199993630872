import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ClarityConfig } from '../../../config/clarity.config';
import { SubscriptionsService } from '../../../services/subscriptions/subscriptions.service';
import { Store } from '@ngrx/store';
import { getCurrentUserProgram } from '../../../store/normalized/selectors/user.selectors';
import { CloseLastModal, ShowUpgradeSuccessPopup, UpgradeSource } from '../../../store/session/actions/navigation.actions';
import { SignupComplete } from '../../../store/session/actions/account.actions';
import { ToastService } from '../../../services/toast.service';
import { SessionState } from 'src/app/store/session';
import { BrowserService } from '../../../services/browser.service';

@Component({
  selector: 'cl-stripe-form',
  styleUrls: ['stripe-form.component.scss'],
  template: `
    <cl-close-button (closeClick)="onClose()"></cl-close-button>

    <div class="row-center">
      <h2 *ngIf="headline">{{ headline | translate }}</h2>
    </div>

    <cl-price-card
      [length]="product.length"
      [price]="product.priceFormatted"
      [fullPrice]="product.fullPriceFormatted"
      [selected]="false"
      [disabled]="true"
      [renewalNotice]="!!product.pricePerWeekFormatted"
      [variant]="'big'">
    </cl-price-card>

    <form id="subscription-form" (submit)="onPurchase($event)" #subscriptionForm>
      <fieldset>
        <div class="form-row">
          <input id="holdername-element"
                 placeholder="{{ 'stripe.card_holder_placeholder' | translate }}"
                 type="text"
                 required="true"
                 autocomplete="name"/>
        </div>
        <div id="holdername-errors" class="errors"></div>
        <div id="lastname-errors" class="errors"></div>
      </fieldset>

      <fieldset>
        <div class="form-row">
          <div id="card-element"></div>
        </div>
        <div id="card-errors" class="card-errors"></div>
      </fieldset>

      <p class="subscription-terms">
        <span [innerHTML]="'subscriptions.upgrade_details_stripe' | translate"></span>
        <span><a (click)="openPrivacyTerms()">{{ 'subscriptions.privacy_policy_terms_use' | translate }}</a></span>
      </p>

      <div class="row-center action-button-holder">
        <cl-action-button
          [label]="'stripe.pay_x' | translate : {price: product.priceFormatted}"
          [canClick]="canSubmitForm">
        </cl-action-button>
      </div>
    </form>
  `
})
export class StripeFormComponent implements OnInit {

  @ViewChild('subscriptionForm', {static: true}) subscriptionForm: ElementRef;

  @Input() headline: string;
  @Input() source: UpgradeSource = UpgradeSource.SETTINGS;

  @Output() purchase = new EventEmitter();

  public product;
  public modal;
  public canSubmitForm = false;
  public loading = false;
  private userLanguage = ClarityConfig.DEFAULT_LANGUAGE;
  private cardElement;
  private holderNameElement;

  constructor(
    private toastService: ToastService,
    private store: Store<SessionState>,
    private subscriptionsService: SubscriptionsService,
    private config: ClarityConfig,
    private browser: BrowserService
  ) {
    this.product = this.subscriptionsService.getSelectredStripePlan();

    this.store.select(getCurrentUserProgram)
      .subscribe((userProgram) => {
        if (userProgram && userProgram.language_code) {
          this.userLanguage = userProgram.language_code;
        }
      });
  }

  ngOnInit() {
    this.subscriptionForm = this.subscriptionForm ? this.subscriptionForm.nativeElement : null;

    if (!this.config.stripeWebEnabled()) {
      return false;
    }

    this.initCardForm();
  }

  onPurchase(event) {
    event.preventDefault();

    const additionalData = {
      name: this.holderNameElement.value
    };

    this.subscriptionsService.purchaseStripeSubscription(this.cardElement, additionalData)
      .then(() => {
        this.toastService.translateConfirm('subscriptions.subscription_purchased_successfully');

        this.onClose();

        if (this.source === UpgradeSource.ACCOUNT_SETUP) {
          this.store.dispatch(new SignupComplete());
        } else {
          this.store.dispatch(new ShowUpgradeSuccessPopup(this.source)); // close all modals and show success popup
        }
      })
      .catch((error) => {
        console.log('Stripe subscription purchase failed', error);
      });
  }

  onClose() {
    this.store.dispatch(new CloseLastModal());
  }

  private initCardForm() {
    const elements = this.subscriptionsService.stripe.elements({
      fonts: [{
        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto'
      }],
      locale: this.userLanguage
    });

    const styles = {
      base: {
        iconColor: '#c4f0ff',
        color: '#fff',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontWeight: 500,
        ':-webkit-autofill': {
          color: '#c4f0ff'
        },
        '::placeholder': {
          color: '#c4f0ff'
        }
      },
      invalid: {
        iconColor: '#f00',
        color: '#f00'
      }
    };

    const elementClasses = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid'
    };

    this.cardElement = elements.create('card', {
      style: styles,
      classes: elementClasses
    });

    this.cardElement.mount('#card-element');
    this.cardElement.on('change', (event) => this.onFormChange(event));

    this.holderNameElement = document.getElementById('holdername-element');
    this.holderNameElement.addEventListener('input', () => this.onNameChange());
  }

  private onFormChange(event) {
    this.validateForm();

    const displayError = document.getElementById('card-errors');
    displayError.textContent = event.error ? event.error.message : '';
    displayError.setAttribute('class', event.error ? 'card-errors show' : 'card-errors');
  }

  private onNameChange() {
    if (!this.holderNameElement) {
      this.holderNameElement = document.getElementById('holdername-element');
    }

    this.validateForm();

    const displayError = document.getElementById('holdername-errors');
    displayError.setAttribute('class', !this.holderNameElement.value ? 'card-errors show' : 'card-errors');
    displayError.textContent = !this.holderNameElement.value ? 'Cardholder\'s name is missing' : '';

    return !this.holderNameElement.value ? true : false;
  }

  private validateForm() {
    setTimeout(() => {
      this.canSubmitForm = this.holderNameElement.value && this.cardElement._complete && !this.cardElement._invalid;
    });
  }

  public openPrivacyTerms() {
    this.browser.goTo(this.config.privacyTermsUrl);
  }
}
