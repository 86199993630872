import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';

@Injectable({providedIn: 'root'})
export class ProgramDaysProvider {

  private programDaysEndpoint = '/program_days';

  constructor(
    private http: HttpProvider
  ) {

  }

  getProgramDays() {
    return this.http.get<any>(this.programDaysEndpoint);
  }

}
