import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

import { UserReminder } from '../../../store/normalized/schemas/reminder.schema';

export type RangeValue = number | {lower: number; upper: number};

enum CONTROL_QUANTITY {
  SINGLE = 1,
  BOTH = 2
}

@Component({
  selector: 'cl-reminder-timing-edit',
  styleUrls: ['reminders-timing-edit.component.scss'],
  template: `
    <ng-container *ngIf="reminder">
      <!-- 1 Max daily count START -->
      <div *ngIf="reminder.max_daily_count === CONTROL_QUANTITY.SINGLE; else startEnd">
        <div class="reminders-ion-item">
          <ion-label
            class="reminders-controls-item reminders-label">
            {{ 'reminders.notification_at' | translate }}
          </ion-label>
          <ion-range [value]="reminder.first_at"
                     class="reminders-controls-item reminders-range"
                     min="0"
                     max="24"
                     step="1"
                     [snaps]="true"
                     [ticks]="true"
                     (ionChange)="onSingleTimerChange($event)"></ion-range>
          <ion-label text-center
                     class="reminders-controls-item reminders-label">
            <span class="display-value">{{ formattedFirstAt }}</span>
          </ion-label>
        </div>
      </div><!-- 1 Max daily count END -->

      <!-- StartEnd Template START -->
      <ng-template #startEnd>
        <!-- ion-range START -->
        <div class="reminders-ion-item dual">
          <ion-label
            class="reminders-controls-item reminders-label">
            {{ 'common.from' | translate }}
            <span class="display-value">{{ formattedFirstAt }}</span>
          </ion-label>

          <ion-range
            [value]="rangeValue"
            class="reminders-controls-item reminders-range"
            min="0"
            max="24"
            step="1"
            [snaps]="true"
            [ticks]="true"
            dual-knobs
            (ionChange)="onDualTimerChange($event)"></ion-range>

          <ion-label text-center class="reminders-controls-item reminders-label">
            {{ 'common.to' | translate }}
            <span class="display-value">{{ formattedLastAt }}</span>
          </ion-label>
        </div><!-- ion-range END -->

        <ion-item no-lines>
          <ion-label>
            {{ 'reminders.number_of_notifications_per_day' | translate }}
          </ion-label>
          <ion-select [(ngModel)]="reminder.daily_count"
                      [value]="reminder.daily_count">
            <ion-select-option
              *ngFor="let option of options"
              [value]="option">
              {{option}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ng-template><!-- StartEnd Template END -->
    </ng-container>
  `
})
export class RemindersTimingEditComponent {
  @Input() public reminder: UserReminder;
  public readonly CONTROL_QUANTITY = CONTROL_QUANTITY;

  public get options(): number[] {
    return new Array(this.reminder.max_daily_count)
      .fill(true)
      .map((value: boolean, index: number): number => index + 1);
  }

  public get rangeValue(): RangeValue {
    switch (this.reminder.max_daily_count) {
      case CONTROL_QUANTITY.SINGLE:
        return this.getRoundedValue(this.reminder.first_at);
      case CONTROL_QUANTITY.BOTH:
      default:
        return {
          lower: this.getRoundedValue(this.reminder.first_at),
          upper: this.getRoundedValue(this.reminder.last_before)
        };
    }
  }

  public get formattedFirstAt() {
    return this.convertTime24to12(this.getRoundedValue(this.reminder.first_at));
  }

  public get formattedLastAt() {
    return this.convertTime24to12(this.getRoundedValue(this.reminder.last_before));
  }

  public convertTime24to12(hours: number): string {
    return moment()
      .set('hour', hours)
      .format('ha')
      .toString();
  }

  public formatTime(timeValue: number): string {
    return timeValue === 24
      ? '23:59' // 24:00 doesn't work consistently
      : `${timeValue}:00`;
  }

  public onSingleTimerChange(event: CustomEvent): void {
    const {value} = event.detail;
    this.reminder.first_at = this.formatTime(value);
    this.reminder.last_before = this.reminder.first_at;
  }

  public onDualTimerChange(event: CustomEvent): void {
    const {lower: firstAt, upper: lastBefore} = event.detail.value;
    this.reminder.first_at = this.formatTime(firstAt);
    this.reminder.last_before = this.formatTime(lastBefore);
  }

  private getRoundedValue(checkinValue: string): number {
    const [hours, minutes] = checkinValue
      .split(':')
      .map(Number);

    // rounding hours up if necessary
    return minutes > 30 ? hours + 1 : hours;
  }
}
