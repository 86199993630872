import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LessonsPage } from './lessons';
import { SharedModule } from '../../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { LessonCardComponent } from './components/lesson-card.component';
import { ModuleTitleComponent } from './components/module-title.component';
import { translateConfig } from '../../config/translate.config';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    LessonsPage,
    ModuleTitleComponent,
    LessonCardComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule.forChild([{ path: '', component: LessonsPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ]
})
export class LessonsPageModule {}
