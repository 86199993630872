import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { State } from '../../store/state.reducer';

import { distinctUntilChanged, filter, mapTo, take, tap } from 'rxjs/operators';
import { StressTestService } from './stress-test.service';
import { ListItem } from '../../store/normalized/schemas/list-item.schema';
import { combineLatest } from 'rxjs';
import { getBodyParts, getBodySides, getSensations } from '../../store/normalized/selectors/list-items.selectors';
import { LoggerService } from '../logger.service';
import { SyncBodyParts, SyncBodySides, SyncSensations } from 'src/app/store/session/actions/sync.actions';

const initialModel = {
  date: null,
  recorded_at: null,
  exercise_id: 0,
  feel_now: null,
  body_part_id: null,
  body_side_id: null,
  sensation_id: null,
  sensation_level: 0.5,
  since_eat: 0,
  symptom_ids: []
};

@Injectable({providedIn: 'root'})
export class StressTestUAService extends StressTestService {

  public bodyParts$ = this.store.select(getBodyParts);
  public bodySides$ = this.store.select(getBodySides);
  public sensations$ = this.store.select(getSensations);

  private bodyParts: ListItem[];
  private bodySides: ListItem[];
  private sensations: ListItem[];

  constructor(
    protected store: Store<State>,
    protected popoverCtrl: PopoverController,
    protected translate: TranslateService,
    protected logger: LoggerService
  ) {
    super(store, popoverCtrl, translate, logger);
  }

  initModel() {
    this.model = {...initialModel};

    return combineLatest([
      this.bodyParts$,
      this.bodySides$,
      this.sensations$
    ])
      .pipe(
        distinctUntilChanged(),
        tap(([bodyParts, bodySides, sensations]) => {
          if(!bodyParts?.length) {
            this.store.dispatch(new SyncBodyParts());
          }
          if(!bodySides?.length) {
            this.store.dispatch(new SyncBodySides());
          }
          if(!sensations?.length) {
            this.store.dispatch(new SyncSensations());
          }
        }),
        filter(([bodyParts, bodySides, sensations]) => Boolean(bodyParts?.length) && Boolean(bodySides?.length) && Boolean(sensations?.length)),
        take(1),
        tap(([bodyParts, bodySides, sensations]) => {
          this.bodyParts = bodyParts;
          this.bodySides = bodySides;
          this.sensations = sensations;
        }),
        mapTo(null)
      )
      .toPromise();
  }

  getBodyParts() {
    return this.bodyParts;
  }

  getBodySides() {
    return this.bodySides;
  }

  getSensations() {
    return this.sensations;
  }

  setBodyPart(bodyPart: ListItem) {
    this.model.body_part_id = bodyPart.id;
  }

  setBodySide(bodySide: ListItem) {
    this.model.body_side_id = bodySide.id;
  }

  setSensation(sensation: ListItem) {
    this.model.sensation_id = sensation.id;
  }

  setSensationIntensity(intensity: number) {
    this.model.sensation_level = intensity / 100;
  }

  getSensationIntensity() {
    return this.model.sensation_level * 100;
  }

  setExercise(id: number) {
    this.model.exercise_id = id;
  }

  setFeelNow(feelNow: number) {
    this.model.feel_now = feelNow / 100;
  }

  getFeelNow() {
    return this.model.feel_now * 100;
  }
}
