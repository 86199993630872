<ion-content #ionContent>
  <form [formGroup]="form" *ngIf="{ selectedSlide: selectedSlideNumber$ | async } as asyncs">
    <swiper
      #swiper
      [allowSlideNext]="false"
      [autoHeight]="true"
      [speed]="200"
      [resizeObserver]="true"
      effect="fade"
      [allowTouchMove]="false">
      <ng-template swiperSlide>
        <section [id]="ernWeightLossInterstitialSlides.Introduction">
          <ng-container *ngTemplateOutlet="introduction"></ng-container>
        </section>
      </ng-template>
      <ng-template swiperSlide>
        <section [id]="ernWeightLossInterstitialSlides.QuestionWLContent">
          <ng-container *ngTemplateOutlet="questionWLContent"></ng-container>
        </section>
      </ng-template>
      <ng-template swiperSlide>
        <section [id]="ernWeightLossInterstitialSlides.QuestionTrackWeight">
          <ng-container *ngTemplateOutlet="questionTrackWeight"></ng-container>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section [id]="ernWeightLossInterstitialSlides.recommendMindfulEating">
          <ng-container *ngTemplateOutlet="recommendMindfulEating"></ng-container>
        </section>
      </ng-template>
      <ng-template swiperSlide>
        <section [id]="ernWeightLossInterstitialSlides.RecommendWeightLoss">
          <ng-container *ngTemplateOutlet="RecommendWeightLoss"></ng-container>
        </section>
      </ng-template>
    </swiper>
  </form>
</ion-content>

<ng-template #introduction>
  <h1>{{ 'interstitials.ern_diagnostic.title' | translate }}</h1>
  <p>
    {{ 'interstitials.ern_diagnostic.description1' | translate }}
    <span class="bold">{{ 'interstitials.ern_diagnostic.description2' | translate }}</span>
    {{ 'interstitials.ern_diagnostic.description3' | translate }}
    <span class="bold">{{ 'interstitials.ern_diagnostic.description4' | translate }}</span>
    {{ 'interstitials.ern_diagnostic.description5' | translate }}
  </p>

  <cl-program-path
    [data]="{
    icon: 'theme-week-icon',
    label: 'interstitials.ern_diagnostic.theme_weeks.title' | translate,
    description: 'interstitials.ern_diagnostic.theme_weeks.description' | translate
    }"
    [showRecommendedBadge]="false"
    (pathSelected)="goToThemeWeeks()">
  </cl-program-path>

  <cl-program-path
    [data]="{
    icon: 'weight-loss-icon',
    label: 'interstitials.ern_diagnostic.weight_loss.title' | translate,
    description: 'interstitials.ern_diagnostic.weight_loss.intro_description' | translate
    }"
    [showRecommendedBadge]="false"
    [showLanguageWarning]="(userProgramLanguage$ | async) === 'es'"
    (pathSelected)="tryNavigateToNext()">
  </cl-program-path>
</ng-template>

<ng-template #questionWLContent>
  <h1>{{'interstitials.ern_diagnostic.comfortable_seeing_content' | translate}}</h1>
  <div class="flex-container">
    <div class="custom-checkbox-container" *ngFor="let formValue of formValues">
      <input class="custom-checkbox" type="radio" [value]="formValue.value" [formControl]="form.get('content')">
      <label class="custom-checkbox-label" (click)="form.get('content').setValue(formValue.value)">{{ formValue.label | translate }}</label>
    </div>
  </div>
</ng-template>

<ng-template #questionTrackWeight>
  <h1>{{'interstitials.ern_diagnostic.comfortable_tracking_weight' | translate}}</h1>
  <p>
    {{'interstitials.ern_diagnostic.comfortable_tracking_weight_description' | translate}}
    <span class="bold">{{ 'interstitials.ern_diagnostic.comfortable_tracking_weight_description2' | translate}}</span>
  </p>

  <div class="flex-container">
    <div class="custom-checkbox-container" *ngFor="let formValue of formValues">
      <input class="custom-checkbox" type="radio" [value]="formValue.value" [formControl]="form.get('weight')">
      <label class="custom-checkbox-label" (click)="form.get('weight').setValue(formValue.value)">{{ formValue.label | translate }}</label>
    </div>
  </div>
</ng-template>

<ng-template #recommendMindfulEating>
  <div class="slider-container-center">
    <h1>{{ 'interstitials.ern_diagnostic.thank_you' | translate }}</h1>
    <p>
      {{ 'interstitials.ern_diagnostic.recommend1' | translate }}
      <span class="bold">{{ 'interstitials.ern_diagnostic.recommend2' | translate }}</span>
    </p>
    <img src="assets/imgs/interstitial-theme-weeks.svg" alt="" />
    <div class="bold">{{ 'interstitials.ern_diagnostic.theme_weeks.title' | translate }}</div>
    <div class="path-description">{{ 'interstitials.ern_diagnostic.theme_weeks.warning' | translate }}</div>

    <div class="button-container">
      <cl-action-button
        [fullWidth]="true"
        [canClick]="true"
        label="{{'interstitials.ern_diagnostic.lets_go_theme_week' | translate}}"
        (click)="goToThemeWeeks()">
      </cl-action-button>
    </div>
    <cl-action-button
      [fullWidth]="true"
      [canClick]="true"
      color="white"
      label="{{'interstitials.ern_diagnostic.take_me_wl' | translate}}"
      (click)="onPathSelected(ernContentProgram.MindfulEatingWLExtension)">
    </cl-action-button>
  </div>

</ng-template>

<ng-template #RecommendWeightLoss>
  <div class="slider-container-center">
    <h1>{{ 'interstitials.ern_diagnostic.thank_you' | translate }}</h1>
    <p>
      {{ 'interstitials.ern_diagnostic.recommend1' | translate }}
      <span class="bold">{{ 'interstitials.ern_diagnostic.recommend2' | translate }}</span>
    </p>
    <img src="assets/imgs/interstitial-weight-loss.svg" alt="" />
    <div class="bold">{{ 'interstitials.ern_diagnostic.weight_loss.path' | translate }}</div>
    <div class="path-description">{{ 'interstitials.ern_diagnostic.weight_loss.outro_description' | translate }}</div>

    <div class="button-container">
      <cl-action-button
        [canClick]="true"
        label="{{'interstitials.ern_diagnostic.lets_go_wl' | translate}}"
        (click)="onPathSelected(ernContentProgram.MindfulEatingWLExtension)">
      </cl-action-button>
    </div>

    <cl-action-button
      [canClick]="true"
      color="white"
      label="{{'interstitials.ern_diagnostic.take_me_theme_week' | translate}}"
      (click)="goToThemeWeeks()">
    </cl-action-button>

  </div>
</ng-template>

<!-- footer -->
<ion-footer *ngIf="{selectedSlide: selectedSlideNumber$ | async} as asyncs">
  <div class="footer" *ngIf="showFooter">
    <cl-dotted-steps-indicator [status$]="dottedStepsStatus$"></cl-dotted-steps-indicator>
    <ion-button
      color="action"
      (click)="conditionalNavigateNext()"
      [disabled]="isStepInvalid(asyncs.selectedSlide)">
      {{ 'common.next' | translate }}
      <ion-icon name="chevron-forward-outline"></ion-icon>
      <ion-ripple-effect></ion-ripple-effect>
    </ion-button>
  </div>
</ion-footer>
