// download effects

import { Injectable } from '@angular/core';

import { catchError, concatMap, debounceTime, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as downloadActions from '../actions/download.actions';
import * as programActions from '../actions/program.actions';
import * as syncActions from '../actions/sync.actions';
import * as mediaActions from '../../persistent/media/media.actions';
import {
  getCurrentDownloadMediaFile,
  getQueuedMediaFiles,
  getDownloadQueue
} from '../selectors/download.selectors';
import { MediaFile } from '../../normalized/schemas/mediaFile.schema';
import { DownloadService } from '../../../services/files/download.service';
import { ClarityFileError } from '../../../services/files/file.service';
import { getMediaFilesByBonusExercise } from '../../../../app/store/normalized/selectors/exercises.selectors';

import { getMediaFilesByModule } from '../../normalized/selectors/mediafiles.selectors';
import { SessionState } from '../session.reducers';

import { ClarityConfig } from 'src/app/config/clarity.config';

const GENERATE_QUEUE_DELAY_MS = 5 * 1000;
const DOWNLOAD_QUEUE_MODULES_DELAY_MS = 10 * 1000;

@Injectable({providedIn: 'root'})
export class DownloadEffects {


  loadLiveProgram$ = createEffect(() => this.actions$.pipe(ofType(programActions.LOAD_LIVE_PROGRAM),
    switchMap(() => of([])
      .pipe(
        map(() => {
          if (this.config.isDevice) {
            // init download service
            this.downloads.initialize();

            setTimeout(() => {
              this.store.dispatch(new downloadActions.GenerateQueue());
            }, GENERATE_QUEUE_DELAY_MS);
          }
        })
      ))
  ), {dispatch: false});

  generateQueue$ = createEffect(() => this.actions$.pipe(ofType(downloadActions.GENERATE_QUEUE),
    debounceTime(1000),
    tap(() => {
      if (!this.config.isDevice) {
        return;
      }

      this.downloads.generateQueue()
        .then((modulesToQueue) => {
          if (modulesToQueue.length > 0) {
            // delay generating the queue so we don't lock the interface after login
            setTimeout(() => {
              this.store.dispatch(new downloadActions.QueueModules(modulesToQueue));
            }, DOWNLOAD_QUEUE_MODULES_DELAY_MS);
          }
        });
    })
  ), {dispatch: false});


  queueModules$ = createEffect(() => this.actions$.pipe(ofType<downloadActions.QueueModules>(downloadActions.QUEUE_MODULES),
    map((action) => action.payload),
    switchMap((modulesToQueue) => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getMediaFilesByModule),
          this.store.select(getMediaFilesByBonusExercise),
          this.store.select(getDownloadQueue),
          (blank, mediaFilesByModule, mediaFilesByBonusExercise, downloadQueue) => {
            const toQueue = [];

            modulesToQueue.forEach((identifier) => {
              // exercises must be handled differently (format is 'e<exerciseId> -> e12')
              if (isNaN(identifier)) {
                const mediaFiles = mediaFilesByBonusExercise[identifier.replace('e', '')];

                if (mediaFiles) {
                  for (const mediaFile of mediaFiles) {
                    if (mediaFile && downloadQueue.indexOf(mediaFile) === -1) {
                      toQueue.push(mediaFile);
                    }
                  }
                }
              }
              else if (mediaFilesByModule && mediaFilesByModule[identifier]) {
                mediaFilesByModule[identifier].forEach((mediaFile) => {
                  if (downloadQueue.indexOf(mediaFile) === -1) {
                    toQueue.push(mediaFile);
                  }
                });
              }
            });


            if (toQueue.length > 0) {
              // TODO: Re-enable for production
              this.store.dispatch(new downloadActions.QueueMediaFiles(toQueue));
            }
          })
      )
    )
  ), {dispatch: false});


  downloadQueueCleared$ = createEffect(() => this.actions$.pipe(ofType(downloadActions.DOWNLOAD_QUEUE_CLEARED),
    map(() => new downloadActions.GenerateQueue())
  ));


  syncBonusExercises$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_BONUS_EXERCISES_SUCCESS),
    map(() => new downloadActions.GenerateQueue())
  ));

  syncExercises$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_EXERCISES),
    map(() => new downloadActions.GenerateQueue())
  ));

  moduleCompleted$ = createEffect(() => this.actions$.pipe(ofType(programActions.COMPLETE_MODULE_AND_NAVIGATE),
    map(() => new downloadActions.GenerateQueue())
  ));


  downloadStart$ = createEffect(() => this.actions$.pipe(ofType(downloadActions.DOWNLOAD_START),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getDownloadQueue)))
    ),
    map(([action, downloadQueue]) => {
      if (!downloadQueue || downloadQueue.length === 0) {
        return new downloadActions.DownloadQueueCleared();
      }

      return new downloadActions.DownloadMediaFile();
    })
  ));


  downloadEnable$ = createEffect(() => this.actions$.pipe(ofType(downloadActions.DOWNLOAD_ENABLE),
    map(() => new downloadActions.GenerateQueue())
  ));


  downloadDisable$ = createEffect(() => this.actions$.pipe(ofType(downloadActions.DOWNLOAD_DISABLE),
    map(() => new mediaActions.RemoveAllFiles())
  ));

  downloadMediaFile$ = createEffect(() => this.actions$.pipe(ofType(downloadActions.DOWNLOAD_MEDIA_FILE),
    switchMap(() => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getQueuedMediaFiles),
          this.store.select(getCurrentDownloadMediaFile),
          (blank, queuedMediaFiles, currentMediaFile) => {
            const mediaFile: MediaFile = queuedMediaFiles.find((mf) => mf.data_fingerprint === currentMediaFile);

            console.log(`downloading ${mediaFile.data_file_name}`);

            this.downloads.downloadMediaFile(mediaFile)
              .then((mediaFileId = null) => {
                // If brightcove & if the media is not downloaded yet
                // We do nothing here, because we can't get downloaded state of a brightcove media direclty here.
                // This action is called directly from the download.service with the downloadStateChange event listener from the plugin
                if(mediaFileId) {
                  this.store.dispatch(new downloadActions.DownloadMediaFileComplete(mediaFileId));
                }
              })
              .catch((error) => {
                this.store.dispatch(new downloadActions.DownloadMediaFileFail(error));
              });
          }),
        catchError((error) => {
          this.store.dispatch(new downloadActions.DownloadMediaFileFail(error));

          return of({type: 'noop'});
        })
      )
    )
  ), {dispatch: false});


  downloadMediaFileComplete$ = createEffect(() => this.actions$.pipe(
    ofType<downloadActions.DownloadMediaFileComplete>(downloadActions.DOWNLOAD_MEDIA_FILE_COMPLETE),
    map((action) => action.payload),
    map((mediaFileId) => new mediaActions.SaveDownloadedFile(mediaFileId))
  ));


  downloadMediaFileError = createEffect(() => this.actions$.pipe(ofType(downloadActions.DOWNLOAD_MEDIA_FILE_FAIL),
    map((action: downloadActions.DownloadMediaFileFail) => {
      const error = action.payload;

      console.log('Got media file download error', error);

      switch (error.errorType) {
        case ClarityFileError.errorTypes.FILE_TRANSFER_ERROR:
        case ClarityFileError.errorTypes.BRIGHTCOVE_DOWNLOAD_ERROR:
        case ClarityFileError.errorTypes.MD5_MISMATCH:
          return new downloadActions.DownloadSkipMediaFile();

        case ClarityFileError.errorTypes.MD5_ERROR:
        case ClarityFileError.errorTypes.FILE_ERROR:
        case ClarityFileError.errorTypes.NOT_ENOUGH_SPACE:
        case ClarityFileError.errorTypes.NO_WIFI:
        case ClarityFileError.errorTypes.UNKNOWN_ERROR:
        default:
          return new downloadActions.DownloadStop();

      }
    })
  ));

  setOfflineStorageStatus$ = createEffect(() => this.actions$.pipe(
    ofType<downloadActions.SetOfflineStorageStatus>(downloadActions.SET_OFFLINE_STORAGE_STATUS),
    tap(action => {
      this.store.dispatch(!action.payload ? new downloadActions.DownloadDisable() : new downloadActions.DownloadEnable());
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<SessionState>,
    private downloads: DownloadService,
    private config: ClarityConfig
  ) {
  }
}
