import { Action } from '@ngrx/store';

export const SET_ONBOARDING_COMPLETED = '[onboarding] Set Onboarding Completed';
export const UNDO_ONBOARDING_COMPLETED = '[onboarding] Undo Onboarding Completed';

export class SetOnboardingCompleted implements Action {
  readonly type = SET_ONBOARDING_COMPLETED;

  constructor(public payload: string) {
  }
}

// @todo this is a ugly fix for a bad use of onboarding. consider removing it
export class UndoOnboardingCompleted implements Action {
  readonly type = UNDO_ONBOARDING_COMPLETED;

  constructor(public payload: string) {
  }
}

export type OnboardingActions =
  | SetOnboardingCompleted
  | UndoOnboardingCompleted;
