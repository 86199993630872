import { Action, ActionReducer } from '@ngrx/store';
import { FullState } from '..';

// see examples on main.ts on how to use this action to help in development

export function developMetaReducer(nextReducer: ActionReducer<FullState, Action>): ActionReducer<FullState, Action> {
  return function newReducer(state, action: Action) {
    if (action.type === '[dev] set whole state' && action['payload']) {
      console.warn('calling %s with payload', action.type, action['payload']);

      state = action['payload'];

      // by not calling the nextReducer(state) we ensure the action.payload is set without transformation
      // return state;
    }

    return nextReducer(state, action);
  };
}
