import { Component, EventEmitter, Input, Output } from '@angular/core';

// TODO: migrate ion-range[value] was ngModel, check if still works
@Component({
  selector: 'cl-horizontal-slider',
  styleUrls: ['horizontal-slider.component.scss'],
  template: `
    <ion-range
      [min]="min"
      [max]="max"
      step="1"
      ticks="false"
      snaps="true"
      [value]="value"
      mode="ios"
      (ionChange)="onValueChanged($event.detail)">
    </ion-range>
  `
})
export class HorizontalSliderComponent {

  @Input() value = null;
  @Input() max = 100;
  @Input() min = 0;

  @Output() slide = new EventEmitter();

  onValueChanged(event) {
    this.value = event.value;
    this.slide.emit(this.value);
  }

}
