import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SmokeCravingTool } from '../../../store/normalized/schemas/craving-tool.schema';
import { map } from 'rxjs/operators';
import { getRainExercise } from '../../../store/normalized/selectors/exercises.selectors';
import { Store } from '@ngrx/store';
import { State } from '../../../store/state.reducer';

@Component({
  selector: 'cl-rain-exercise-step',
  styleUrls: ['../craving-tool-ctq.scss', 'rain-exercise-step.component.scss'],
  template: `
    <cl-waves-background wizard-bg bg-position-bottom>
      <ion-content>
        <ion-header>
          <cl-header-nav-buttons
            class="page-header"
            color="primary"
            (closeClick)="triggerClose()"
            [backButton]="false">
          </cl-header-nav-buttons>
        </ion-header>

        <div class="exercise-wrapper lateral-padding">
          <div class="play-wrapper">
            <cl-play-exercise
              [exercise]="rainExercise$ | async"
              [isWizard]="true"
              [autoplay]="false">
            </cl-play-exercise>
          </div>
        </div>
      </ion-content>
    </cl-waves-background>

    <div class="controls-holder padding-bottom-iphone-x">
      <ion-row class="buttons-holder lateral-padding">
        <ion-col size="6" offset="3">
          <cl-next-button
            [ngClass]="{'dark-bg': darkBackground}"
            label="common.next"
            [canClick]="true"
            (action)="triggerNext()">
          </cl-next-button>
        </ion-col>
      </ion-row>
    </div>
  `
})
export class RainExerciseStepComponent implements OnInit {

  @Input() currentCravingTool: SmokeCravingTool;
  @Input() darkBackground = false;

  @Output() next = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  rainExercise$ = this.store.select(getRainExercise)
    .pipe(
      map(exercises => exercises[0])
    );

  constructor(
    public store: Store<State>
  ) {
  }

  triggerNext() {
    this.next.emit();
  }

  ngOnInit(): void {
    this.setRainExercise();
  }

  setRainExercise() {
    this.currentCravingTool.rain_exercise = true;
  }

  triggerClose() {
    this.closeClick.emit();
  }
}
