import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { MinutesActivity } from '../../../store/normalized/schemas/minutes-activity.schema';
import { AlertController } from '@ionic/angular';
import { format } from 'date-fns';
import { IntegrationSource } from '../../../store/session/models/integration.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-daily-minutes-activity-card',
  styleUrls: ['daily-minutes-activity-card.scss'],
  template: `
    <div class="container">
      <ion-grid>
        <ion-row class="row" (click)="toggleActivityDetails()">
          <ion-col class="ion-text-left activity-date">{{date}}</ion-col>
          <ion-col class="ion-text-right minutes-col">
            {{filteredSumActivity() + ' ' + ((filteredSumActivity() > 1 ? 'my_activity.mins':'my_activity.min') | translate)}}
          </ion-col>
          <ion-col class="ion-text-right toggle-col">
            <div
              [class.active]="isMenuExpanded"
              class="toggle"
            ></div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <div *ngIf="isMenuExpanded" class="activities">

        <cl-integration-activity-card
          *ngIf="filteredSumActivity(integrationSource.fitbit) > 0"
          [integration]="integrationSource.fitbit"
          [value]="filteredSumActivity(integrationSource.fitbit)">
        </cl-integration-activity-card>
        <cl-integration-activity-card
          *ngIf="filteredSumActivity(integrationSource.googlefit) > 0"
          [integration]="integrationSource.googlefit"
          [value]="filteredSumActivity(integrationSource.googlefit)">
        </cl-integration-activity-card>
        <cl-integration-activity-card
          *ngIf="filteredSumActivity(integrationSource.healthkit) > 0"
          [integration]="integrationSource.healthkit"
          [value]="filteredSumActivity(integrationSource.healthkit)">
        </cl-integration-activity-card>

        <cl-minutes-activity-card
        *ngFor="let activity of filteredActivity(integrationSource.manual)"
        [activity]="activity">
        </cl-minutes-activity-card>
      </div>
    </div>
  `
})
export class DailyMinutesActivityCardComponent {
  @Input() activities: MinutesActivity[];
  isMenuExpanded = false;
  integrationSource = IntegrationSource;

  get date() {
    return format(new Date(this.activities[0].activity_at),'PP');
  }

  filteredActivity(filter: String): MinutesActivity[] {
    return this.activities
      .filter(activity => activity.source === filter);
  }

  filteredSumActivity(filter: String = null): number {
    let activity = this.activities;
    filter != null && (activity = this.filteredActivity(filter));

    return activity.reduce((accumulator, currentValue) => accumulator + currentValue?.minutes, 0);
  }

  toggleActivityDetails(): void {
    this.isMenuExpanded = !this.isMenuExpanded;
  }

  constructor(
    public alertController: AlertController
  ) {}

}
