import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NrtDosageService } from '../../../services/nrt-dosage.service';

@Component({
  selector: 'cl-nrt-product-selection',
  styleUrls: [
    'nrt-product-selection.component.scss',
    'nrt.component.scss'
  ],
  template: `
    <ion-grid class="component-content-grid">
      <ion-row>
        <ion-col>
          <ion-row class="subtitle-holder lateral-padding">
            <ion-col>
              <h4>{{'nrt.product_selection.subtitle' | translate}}</h4>
            </ion-col>
          </ion-row>

          <h3>{{'nrt.product_selection.' + product | translate}}</h3>
          <ion-row class="lateral-padding products-holder">
            <ion-col class="content-wrapper">
              <ul>
                <li *ngFor="let prescription of prescriptions">
                  {{prescription}}
                </li>
              </ul>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>

  `
})
export class NrtProductSelectionComponent {

  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();

  product: string;
  prescriptions: string[];

  constructor(
    private nrtDosageService: NrtDosageService
  ) {}

  private _data;

  get data() {
    return this._data;
  }

  @Input() set data(data) {
    this._data = data;
    this.product = data.type.result.toLowerCase();

    this.updatePrescription();
  }

  private updatePrescription() {
    this.nrtDosageService
      .getPrescription(this.product, this._data)
      .subscribe(prescription => this.prescriptions = prescription);
  }
}
