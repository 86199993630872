// program reducer

import * as userActivitiesActions from '../actions/user-activity.actions';

export interface UserActivitiesState {
  updatingQuitDate: boolean;
}

export const initialUserActivitiesState: UserActivitiesState = {
  updatingQuitDate: false
};

export function userActivitiesReducer(
  state = initialUserActivitiesState,
  action: userActivitiesActions.UserActivityActions
): UserActivitiesState {
  switch (action.type) {
    case userActivitiesActions.SET_QUIT_DATE: {
      return {
        ...state,
        updatingQuitDate: true
      };
    }
    case userActivitiesActions.SET_QUIT_DATE_SUCCESS:
    case userActivitiesActions.SET_QUIT_DATE_FAIL: {
      return {
        ...state,
        updatingQuitDate: false
      };
    }
  }

  return state;
}

export const userQuitDateUpdating = (state: UserActivitiesState) => state.updatingQuitDate;
