import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { SessionState } from '../../store/session/session.reducers';

@Component({
  selector: 'page-interstitial-weight-capture',
  styleUrls: ['interstitial-weight-capture.scss'],
  templateUrl: 'interstitial-weight-capture.html'
})
export class InterstitialWeightCapturePage {
  constructor(
    private store: Store<SessionState>,
    private nav: NavController
  ) {
  }

  goToMyWeight($event) {
    $event.stopPropagation();

    this.store.dispatch(new navigationActions.CloseAllModals());
    this.nav.navigateRoot('tabs/account/weight');
  }

  onClick() {
    this.store.dispatch(new navigationActions.CloseAllModals());
  }

}
