import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'cl-nrt-message',
  styleUrls: [
    'nrt-message.component.scss',
    'nrt.component.scss'
  ],
  template: `
    <ion-grid class="component-content-grid">
      <ion-row>
        <ion-col>
          <ion-row class="description-holder lateral-padding ion-padding">
            <ion-col [innerHTML]="'nrt.' + messageType + '.description' | translate" *ngIf="messageType"></ion-col>
            <ion-col *ngIf="errorMessage">
              {{errorMessage | translate}}
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  `
})
export class NrtMessageComponent implements OnInit {

  // <cl-nrt-navigation
  //   [hidePrevious]="hidePreviousButton"
  //   nextText="{{'nrt.' + messageType + '.button' | translate}}"
  //   (next)="next.emit()"
  //   (previous)="previous.emit()"
  //     ></cl-nrt-navigation>

  hidePreviousButton = true;

  @Input() messageType: string;
  @Input() errorMessage: string;

  @Output() next = new EventEmitter();
  @Output() previous = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.hidePreviousButton = this.previous.observers.length < 1;
  }
}
