import { Action } from '@ngrx/store';

export const QUEUE_REQUEST = '[offlineQueue] Queue Request';
export const SEND_REQUESTS = '[offline Queue] Send Requests';

export const REQUEST_FULFILLED = '[offlineQueue] Request Fulfilled';
export const REQUEST_FAILED = '[offlineQueue] Request Failed';

export interface Queueable {
  type: string;
  endpoint: string;
  payload: any;
  options: any;
  lastError: any;
  ignoreHttpResponseCode?: Array<number>;
}

export interface OfflineRequestResult {
  requestId: string;
  status: number;
  error?: string;
}

export class QueueRequest implements Action {
  readonly type = QUEUE_REQUEST;

  constructor(public payload: Queueable) {

  }
}

export class SendRequests implements Action {
  readonly type = SEND_REQUESTS;

  constructor(public payload: string[]) {

  }
}

export class RequestFulfilled implements Action {
  readonly type = REQUEST_FULFILLED;

  constructor(public payload: OfflineRequestResult) {

  }
}

export class RequestFailed implements Action {
  readonly type = REQUEST_FAILED;

  constructor(public payload: OfflineRequestResult) {

  }
}

export type OfflineQueueActions =
  | QueueRequest
  | SendRequests
  | RequestFulfilled
  | RequestFailed;
