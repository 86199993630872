import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class SyncResolutionService {
  constructor() {}

  /*
  Resolve conflict between data from localStorage & data from server.
  Always keep the backend data first.
  The keys variable corresponds to the comparison keys.
   */
  resolveConflict<T>(backendDatas: Array<T>, storageDatas: Array<T>, keys: Array<string>): Array<T> {
    return this.mergeEntitiesByKeys(backendDatas, storageDatas, keys);
  }

  private mergeEntitiesByKeys<T>(source: Array<T>, destination: Array<T>, keys: Array<string>): Array<T> {
    // for each destination entry, check and exclude the source if it is already present in the destination
    destination.map(storageData => {
      if(!source.find(syncedData => this.isTheSame(syncedData, storageData, keys))) {
        source.unshift(storageData);
      }
    });

    // for each destination entry, exclude if it's already in source
    const difference = destination.filter(dItem => !source.find(sItem => this.isTheSame(sItem, dItem, keys)));

    return [...source, ...difference];
  }

  private isTheSame<T>(itemA: T, itemB: T, keys: Array<string>): boolean {
    return keys.every(key => itemA[key] === itemB[key]);
  }
}
