import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-action-button',
  styleUrls: ['action-button.component.scss'],
  template: `
    <ion-button
      color="{{_color}}"
      class="action-btn"
      type="{{type}}"
      [class.square]="square"
      [class.green-tracker]="greenTracker"
      [class.full-width]="fullWidth"
      [class.small]="small"
      [class.no-padding]="noPadding"
      [disabled]="!canClick"
      (click)="onClick($event)">
      <ion-icon *ngIf="icon" name="{{icon}}" slot="start"></ion-icon>
      <ion-label [innerHTML]="label"></ion-label>
    </ion-button>
  `
})

export class ActionButtonComponent {

  _color = 'action';
  @Input()
  set color(value: string) {
    this._color = value;
  }

  @Input()
  set whiteBg(value: boolean) {
    this._color = value ? 'white' : 'action';
  }

  @Input() greenTracker: boolean;
  @Input() canClick: boolean;
  @Input() icon: string;
  @Input() label: string;
  @Input() square: boolean;
  @Input() type = 'submit';

  @Input() fullWidth: boolean;
  @Input() noPadding = false;

  @Input() small: boolean;

  @Output() action = new EventEmitter();

  onClick($event) {
    this.action.emit($event);
  }

}
