/** strip non-digits */
export function onlyDigits(value: string) {
  return String(value).replace(/\D/g, '');
}

/**
 * Given a string of digits, return it in the US format
 *
 * Examples:
 * 12 return (12
 * 1234 returns (123) 4
 * 1234567 returns (123) 456-7
 * 1234567890123 returns (123) 456-7890
 */
export function usPhoneMask(inputValue: string) {
  const value = onlyDigits(inputValue);
  if (isNaN(Number(value))) {
    return value;
  }

  switch (value.length) {
    case 0:
      return '';
    case 1:
    case 2:
    case 3:
      return `(${value}`;
    case 4:
    case 5:
    case 6:
      return value.replace(/(\d{3})(\d{1,3})/, '($1) $2');
    default:
      return value.substring(0, 10).replace(/(\d{3})(\d{3})(\d{1,4})/, '($1) $2-$3');
  }
}

export function usZipMask(inputValue: string) {
  const value = onlyDigits(inputValue);
  if (isNaN(Number(value))) {
    return value;
  }

  switch (value.length) {
    case 0:
      return '';
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return value;
    default:
      return value.substring(0, 9).replace(/(\d{5})(\d{1,4})/, '$1-$2');
  }
}
