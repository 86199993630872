import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { SessionState } from '../store/session';
import { ResumeApp } from '../store/session/actions/sync.actions';
import { TimeService } from './time.service';

@Injectable()
export class ResumeService {

  constructor(
    private platform: Platform,
    private store: Store<SessionState>,
    private time: TimeService
  ) {
  }

  public initialize() {
    this.platform.resume.subscribe(async () => {
      this.store.dispatch(new ResumeApp());

      this.time.checkCurrentDay();

      // this will cause route handlers to be executed multiple times!
      // if (this.platform.is('ios')) {
      //   this.deeplinksProvider.initialize();
      // }
    });

    return Promise.resolve(true);
  }
}
