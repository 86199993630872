import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { WelcomeVideoPage } from './welcome-video';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    WelcomeVideoPage
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: WelcomeVideoPage }]),
    TranslateModule.forChild(),
    SharedModule
  ],
  exports: [
    WelcomeVideoPage
  ]
})
export class WelcomeVideoPageModule {}
