<ion-content>
  <cl-action-dialog
    [content]="onboardingDialogPayload.content"
    [settings]="onboardingDialogPayload.settings"
    (mainAction)="onDialogMainAction()"
    (dismiss)="onDialogDismiss()"
    (mediaPlayed)="onMediaPlayed()">
  >
  </cl-action-dialog>
</ion-content>
