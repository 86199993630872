import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CravingTool } from '../../../store/normalized/schemas/craving-tool.schema';
import { SECOND_CHECKIN } from '../../../constants/craving-tool.constants';
import { ClarityConfig } from '../../../config/clarity.config';

@Component({
  selector: 'cl-after-minutes-checkin-step',
  styleUrls: ['../craving-tool.scss', 'after-minutes-checkin-step.component.scss'],
  template: `
  <ion-content>
    <div class="simulation lateral-padding">
      <h2 class="step-intro intro-question">{{'wizards.X_minutes_checkin_title' | translate:{minutes: minutes} }}</h2>
      <cl-intensity-selector
        [sliderValue]="sliderValue"
        highestIntensityLabel="wizards.very_satisfied"
        mediumIntensityLabel=""
        lowestIntensityLabel="wizards.very_dissatisfied"
        (intensity)="onAfterSatisfactionChange($event)"
        lowestIntensityScale="-10"
        lowIntensityScale="-5"
        mediumIntensityScale="0"
        highIntensityScale="+5"
        highestIntensityScale="+10"
      >
      </cl-intensity-selector>
    </div>
  </ion-content>

  <ion-footer>
    <div class="controls-holder padding-bottom-iphone-x">
      <ng-container *ngIf="!config.isWebApp; else webActionButtonsTemplate">
        <h2 class="button-header lateral-padding" *ngIf="showNextAndDone">
          {{'wizards.craving_tool_check_in_X_minutes' | translate:{minutes: inMinutes} }}
        </h2>
        <ion-row class="buttons-holder lateral-padding" *ngIf="showNextAndDone">
          <ion-col size="6">
            <cl-prev-button
              [ngClass]="{'dark-bg': darkBackground}"
              label="common.done"
              [canClick]="true"
              (action)="triggerDone()">
            </cl-prev-button>
          </ion-col>
          <ion-col size="6">
            <cl-next-button
              [ngClass]="{'dark-bg': darkBackground}"
              label="common.yes"
              [canClick]="true"
              (action)="triggerNext()">
            </cl-next-button>
          </ion-col>
        </ion-row>
        <ion-row class="buttons-holder" *ngIf="!showNextAndDone">
          <ion-col size="6" offset="3">
            <cl-next-button
              [ngClass]="{'dark-bg': darkBackground}"
              label="common.done"
              [canClick]="true"
              (action)="triggerDone()">
            </cl-next-button>
          </ion-col>
        </ion-row>
      </ng-container>

      <ng-template #webActionButtonsTemplate>
        <ion-row class="buttons-holder lateral-padding">
          <ion-col class="center" size="6">
            <cl-next-button
              [ngClass]="{'dark-bg': darkBackground}"
              label="common.done"
              [canClick]="true"
              (action)="triggerDone()">
            </cl-next-button>
          </ion-col>
        </ion-row>
      </ng-template>
    </div>
  </ion-footer>
  `
})
export class AfterMinutesCheckinStepComponent implements OnInit {
  @Input() currentCravingTool: CravingTool;
  @Input() minutes: number;
  @Input() inMinutes: number = undefined;
  @Input() hide15Min = true;
  @Input() darkBackground = false;
  @Output() next = new EventEmitter();
  @Output() done = new EventEmitter();

  constructor(
    private ref: ChangeDetectorRef,
    public config: ClarityConfig
  ) {

  }

  ngOnInit(): void {
    this.setupDefaultValues();
    this.ref.detectChanges();
  }

  onAfterSatisfactionChange($event) {
    if (this.inMinutes === SECOND_CHECKIN) {
      this.currentCravingTool.satisfaction_after_5mins = (($event - 50) * 2) / 100;
      this.currentCravingTool.recording_5mins_at = new Date().toISOString();
    }

    if (!this.inMinutes) {
      this.currentCravingTool.satisfaction_after_20mins = (($event - 50) * 2) / 100;
      this.currentCravingTool.recording_20mins_at = new Date().toISOString();
    }

    this.ref.detectChanges();
  }

  get showNextAndDone() {
    return this.inMinutes && !this.hide15Min;
  }

  get sliderValue() {
    // in minutes refers to the time until the next step
    let value = 0;
    if (this.inMinutes === SECOND_CHECKIN) {
      value = this.currentCravingTool.satisfaction_after_5mins;
    }
    if (this.inMinutes === null) {
      value = this.currentCravingTool.satisfaction_after_20mins;
    }

    value = value || 0;

    return (value / 2 + 0.5) * 100;
  }

  triggerNext() {
    this.next.emit();
  }

  triggerDone() {
    this.done.emit();
  }

  private setupDefaultValues() {
    if (this.inMinutes === 15 && this.currentCravingTool.satisfaction_after_5mins == null) {
      this.currentCravingTool.satisfaction_after_5mins = 0;
      this.currentCravingTool.recording_5mins_at = new Date().toISOString();
    }
    if (this.inMinutes === null && this.currentCravingTool.satisfaction_after_20mins == null) {
      this.currentCravingTool.satisfaction_after_20mins = 0;
      this.currentCravingTool.recording_20mins_at = new Date().toISOString();
    }
  }
}
