/* eslint-disable no-unused-expressions, @typescript-eslint/no-unused-expressions, no-shadow, @typescript-eslint/no-shadow */

import * as downloadActions from '../actions/download.actions';
import { ClarityFileError } from '../../../services/files/file.service';

class DownloadError {
  mediaFileId: string;
  // TODO: We cannot store class instances in state!
  error: ClarityFileError;
}

export interface DownloadState {
  queue: string[];
  downloading: boolean;
  currentMediaFile: string;
  lastDownloadAt: any;
  lastError: DownloadError;
  offlineStorage: boolean;
}

export const initialDownloadState: DownloadState = {
  queue: [],
  downloading: false,
  currentMediaFile: null,
  lastDownloadAt: null,
  lastError: null,
  offlineStorage: true
};

function removeCurrentFileFromQueue(state: DownloadState, skip = false) {
  const {queue, currentMediaFile, lastDownloadAt} = state;

  const indexToRemove = queue.findIndex(
    (fileHash) => fileHash === currentMediaFile
  );

  if (indexToRemove < 0) {
    return state;
  }

  const newQueue = [...queue];
  newQueue.splice(indexToRemove, 1);

  const newLastDownloadAt = skip === true ? lastDownloadAt : new Date();

  return {
    ...state,
    queue: newQueue,
    currentMediaFile: null,
    lastDownloadAt: newLastDownloadAt,
    downloading: false
  };
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function downloadReducer(state = initialDownloadState, action: downloadActions.DownloadActions): DownloadState {
  switch (action.type) {
    case downloadActions.GENERATE_QUEUE: {
      return {
        ...state,
        downloading: false
      };
    }

    case downloadActions.QUEUE_MEDIA_FILES: {
      const currentQueue = state.queue;
      const queue = currentQueue.concat(action.payload)
        .filter(onlyUnique);

      return {
        ...state,
        queue
      };
    }

    case downloadActions.DOWNLOAD_START: {
      return {
        ...state,
        downloading: true
      };
    }

    case downloadActions.DOWNLOAD_STOP: {
      return {
        ...state,
        downloading: false
      };
    }

    case downloadActions.DOWNLOAD_MEDIA_FILE: {
      const {queue} = state;
      const currentMediaFile = [...queue].shift();

      if (!currentMediaFile) {
        return state;
      }

      return {
        ...state,
        currentMediaFile,
        downloading: true
      };
    }

    case downloadActions.DOWNLOAD_MEDIA_FILE_COMPLETE: {
      const newState = removeCurrentFileFromQueue(state);

      return newState;
    }

    case downloadActions.DOWNLOAD_SKIP_MEDIA_FILE: {
      const newState = removeCurrentFileFromQueue(state, true);

      return newState;
    }

    case downloadActions.DOWNLOAD_MEDIA_FILE_FAIL: {
      const error = action.payload;
      const {currentMediaFile} = state;

      return {
        ...state,
        lastError: {
          mediaFileId: currentMediaFile,
          error
        },
        downloading: false
      };
    }

    case downloadActions.DOWNLOAD_QUEUE_CLEARED: {
      return {
        ...state,
        downloading: false
      };
    }
    case downloadActions.DOWNLOAD_DISABLE: {
      return {
        ...initialDownloadState,
        offlineStorage: state.offlineStorage
      };
    }
    case downloadActions.DOWNLOAD_ENABLE: {
      return {
        ...initialDownloadState,
        offlineStorage: state.offlineStorage
      };
    }

    case downloadActions.SET_OFFLINE_STORAGE_STATUS: {
      return {
        ...initialDownloadState,
        offlineStorage: action.payload

      };
    }
  }

  return state;
}

export const currentMediaFile = (state: DownloadState) => state.currentMediaFile;
export const downloading = (state: DownloadState) => state.downloading;
export const downloadQueue = (state: DownloadState) => state.queue;
export const offlineStorageStatus = (state: DownloadState) => state.offlineStorage;
