import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnDestroy, OnInit,
  Output
} from '@angular/core';
import { LiveModule, LiveWeek } from '../../store/session/selectors/program.selectors';
import { UTILS_CONSTANTS } from '../../utils';
import { Subject, Subscription } from 'rxjs';
import { ClarityConfig } from '../../config/clarity.config';

import SwiperCore, { Virtual, SwiperOptions, Swiper } from 'swiper';
SwiperCore.use([Virtual]);

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-todays-lesson-slides',
  styleUrls: ['./todays-lesson-slides.component.scss'],
  template: `
    <div class="slides-off-wrapper" *ngIf="!isProgramReady">
      <swiper
        class="swiper"
        (swiper)="onSwiper($event)"
        [config]="swiperConfig"
      >
        <ng-template swiperSlide>
          <div class="today-lesson loading-lessons ion-padding">
            <cl-todays-lesson-card></cl-todays-lesson-card>
          </div>
        </ng-template>
      </swiper>
    </div>

    <div class="slides-on-wrapper" *ngIf="isProgramReady">
      <swiper
        class="swiper"
        (swiper)="onSwiper($event)"
        [config]="swiperConfig"
      >
        <ng-container *ngFor="let week of weeks">
          <ng-template swiperSlide *ngFor="let module of week.modules">
            <div class="today-lesson ion-padding">
              <cl-todays-lesson-card
                [class.desktop]="config.usingDesktopOnWeb"
                [week]="week"
                [module]="module"
                (begin)="onBegin($event)"
                (prev)="onSlidePrev()"
                (next)="onSlideNext()"
                (newWeek)="onNewWeek()">
              </cl-todays-lesson-card>
            </div>
          </ng-template>
        </ng-container>
      </swiper>
    </div>
  `
})

export class TodaysLessonSlidesComponent implements OnInit, OnDestroy {
  @Input() sliderReset: Subject<any>;

  @Output() begin = new EventEmitter<LiveModule>();
  @Output() newWeek = new EventEmitter<void>();

  swiper: Swiper;
  swiperConfig: SwiperOptions = {
    ...UTILS_CONSTANTS.SWIPER_GESTURE_FIX,
    shortSwipes: !this.config.isWebApp,
    touchRatio: this.config.isWebApp ? 2 : 1,
    slidesPerView: 1,
    spaceBetween: 0,
    virtual: {
      addSlidesAfter: 1,
      addSlidesBefore: 1
    }
  };

  private _ready: boolean;
  private sliderResetsubscription: Subscription;

  constructor(
    public config: ClarityConfig,
    private cdr: ChangeDetectorRef
  ) {}

  get isProgramReady() {
    return this._ready;
  }

  @Input()
  set isProgramReady(ready: boolean) {
    this._ready = ready;

    this.cdr.detectChanges();
  }

  private _weeks: LiveWeek[] = [];

  get weeks() {
    return this._weeks;
  }

  @Input()
  set weeks(weeks: LiveWeek[]) {
    this._weeks = weeks;

    this.cdr.detectChanges();
  }

  private _sliderIndex: number;

  @Input()
  set sliderIndex(index: number) {
    if (Number.isInteger(index)) {
      this._sliderIndex = index;
      this.slideIntoPosition();
    }
  }

  ngOnInit(): void {
    this.sliderResetsubscription = this.sliderReset
      .subscribe(() => {
        this.slideIntoPosition();
      });
  }

  ngOnDestroy(): void {
    this.sliderResetsubscription && this.sliderResetsubscription.unsubscribe();
  }

  onSwiper(swiper) {
    this.swiper = swiper;
  }

  onSlidePrev() {
    this.swiper.slidePrev();
  }

  onSlideNext() {
    this.swiper.slideNext();
  }

  onBegin(event: LiveModule) {
    this.begin.emit(event);
  }

  onNewWeek() {
    this.newWeek.emit();
  }

  private slideIntoPosition() {
    // console.log('sliding into position', this._sliderIndex);

    if (!this.swiper || !Number.isInteger(this._sliderIndex) || this._sliderIndex < 0) {
      return false;
    }

    // for some reason, when this is triggered from the cable sync, the closure is messed up - setTimeout fixes it
    setTimeout(() => {
      const activeIndex = this.swiper.activeIndex;
      // avoid re-sliding if correct slide is already active
      if (activeIndex === this._sliderIndex) {
        return;
      }

      this.swiper.update();

      // Sometimes when the update is triggered from a WS message, this fails with Cannot read property 'slidesPerGroup' of undefined
      // Seems the scope is mseed up somehow...still with the setTimeout :(
      try {
        this.swiper.slideTo(this._sliderIndex, 0);
      } catch (error) {
        console.log('Error sliding into position!');
      }

      this.cdr.detectChanges();
    });

  }
}
