import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { MyCoachCtqPage } from './my-coach-ctq';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    MyCoachCtqPage
  ],
  imports: [
    IonicModule,
    RouterModule.forChild([{path: '', component: MyCoachCtqPage}]),
    TranslateModule.forChild(translateConfig),
    SharedModule,
    CommonModule
  ]
})
export class MyCoachCtqPageModule {}
