import { createSchemaSelectors } from 'ngrx-normalizr';
import { createSelector } from '@ngrx/store';
import {
  ProgramBootstrap,
  ProgramDay,
  programBootstrapSchema,
  programDayExerciseSchema,
  programDaySchema, ProgramDayExercise
} from '../schemas/program-bootstrap.schema';

import { User } from '../schemas/user.schema';
import { getCurrentUser } from './user.selectors';
import equal from 'fast-deep-equal';

const programsBootstrapSchemaSelector = createSchemaSelectors<ProgramBootstrap>(programBootstrapSchema);
const programDaysSchemaSelector = createSchemaSelectors<ProgramDay>(programDaySchema);
const programDayExercisesSchemaSelector = createSchemaSelectors<ProgramDayExercise>(programDayExerciseSchema);

let programModulesCopy: ProgramDay[] = [];

export const getProgramModules = createSelector(
  programDaysSchemaSelector.getEntities,
  (programModules) => {
    if (!equal(programModules, programModulesCopy)) {
      programModulesCopy = programModules;
    }

    return programModulesCopy;
  }
);

let programLessonsCopy: ProgramDayExercise[] = [];

export const getProgramLessons = createSelector(
  programDayExercisesSchemaSelector.getEntities,
  (programLessons) => {
    if (!equal(programLessons, programLessonsCopy)) {
      programLessonsCopy = programLessons;
    }

    return programLessonsCopy;
  }
);

export const getProgramBootstrap = createSelector(
  programsBootstrapSchemaSelector.getEntities,
  (programs: ProgramBootstrap[]) => programs && programs.length > 0 ? programs[0] : {}
);

let normalizedModulesCopy: Record<string, ProgramDay> = {};

export const getNormalizedModules = createSelector(
  programDaysSchemaSelector.getEntities,
  (modules) => {
    const normalizedModules = normalizeArray(modules);

    if (!equal(normalizedModules, normalizedModulesCopy)) {
      normalizedModulesCopy = normalizedModules;
    }

    return normalizedModulesCopy;
  }
);

export const getModulesNormalizedByNumber = createSelector(
  getProgramModules,
  (modules) => {
    const normalizedModules = {};

    if (!modules || !modules.length) {
      return normalizedModules;
    }

    for (let i = 0; i < modules.length; i++) {
      normalizedModules[modules[i].number] = modules[i];
    }

    console.log('$$$ SELECTOR getModulesNormalizedByNumber');

    return normalizedModules;
  }
);

let normalizedLessonsCopy: Record<string, ProgramDayExercise> = {};

export const getNormalizedLessons = createSelector(
  getProgramLessons,
  (lessons) => {
    const normalizedLessons = normalizeArray(lessons);

    if (!equal(normalizedLessons, normalizedLessonsCopy)) {
      normalizedLessonsCopy = normalizedLessons;
    }

    return normalizedLessonsCopy;
  }
);

export const getModulesNumbers = createSelector(
  getProgramModules,
  (modules) => {
    if (!modules) {
      return [];
    }

    return modules
      .map((module) => module.number)
      .sort((a, b) => a - b);
  }
);

export const getUserWeightInfo = createSelector(
  getCurrentUser,
  (user: User) => {
    const { weight, weight_unit } = user;

    return { value: weight, unit: weight_unit };
  }
);

function normalizeArray<T extends { id: any }>(array: T[]): Record<any, T> {
  const object = {};

  if (!array || array.length === 0) {
    return object;
  }

  for (let i = 0; i < array.length; i++) {
    object[array[i].id] = array[i];
  }

  return object;
}
