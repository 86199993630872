import { Component } from '@angular/core';

@Component({
  selector: 'cl-community-button',
  styleUrls: ['community-button.component.scss'],
  template: `
    <ion-button class="icon-button go-to-community" color="action">
      {{'community.go_to_community_page' | translate}}
    </ion-button>
  `
})

export class CommunityButtonComponent {

}
