import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as programActions from '../../../store/session/actions/program.actions';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { getVideoChats } from '../../../store/normalized/selectors/community.selectors';
import { SessionState } from '../../../store/session/session.reducers';
import { VideoChat } from 'src/app/store/normalized/schemas/community.schema';
import { Observable } from 'rxjs';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';

@Component({
  selector: 'cl-weekly-video',
  styleUrls: ['weekly-video.component.scss'],
  template: `
    <div class="live-block" *ngFor="let video of (videoChats$ | async)" (click)="openVideoChat(video)">
      <img src="assets/imgs/bg/card-bottom-community.png" />
      <span>{{'community.join_us_live' | translate}}</span>
      <p>{{video.starts_at_with_timezone | processDateInLocal : false : video.timezone : true}}</p>
      <p>{{video.meeting_notes}}</p>
    </div>
  `
})

export class WeeklyVideoComponent {

  videoChats$: Observable<VideoChat[]> = this.store.select(getVideoChats);

  constructor(
    public store: Store<SessionState>,
    private analyticsService: AnalyticsService
  ) {
  }

  openVideoChat(call: VideoChat) {
    this.analyticsService.trackEvent(AnalyticsEvents.CommunityClickedWeeklyCalls, { call });

    this.store.dispatch(new programActions.OpenWeeklyCalls());
  }
}
