import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SocialProvider } from '../../../providers/social.provider';
import { Action, Store } from '@ngrx/store';
import { SessionState } from '../session.reducers';
import * as postActions from '../actions/post.actions';
import { AddChildData, AddData, RemoveChildData, RemoveData, SetData } from 'ngrx-normalizr';
import {
  Comment,
  CommentFeed,
  commentFeedSchema,
  commentSchema,
  Like,
  Post,
  postsSchema
} from '../../normalized/schemas/social.schema';
import { commentFeedId } from '../reducers/post.reducer';
import { getCommentById, getCommentFeedById, getOpenPostId } from '../selectors/post.selectors';
import { ToastService } from '../../../services/toast.service';
import { getCurrentProfileId } from '../../normalized/selectors/user-profile.selectors';
import { Observable, of } from 'rxjs';
import * as userActivityActions from '../actions/user-activity.actions';

const currentCommentFeedId: string = commentFeedId();

@Injectable({ providedIn: 'root' })
export class PostEffects {


  loadPost$ = createEffect(() => this.actions$.pipe(ofType(postActions.LOAD_POST),
    switchMap((action: postActions.LoadPost) => this.socialProvider.loadPostById(action.payload)
      .pipe(
        mergeMap(post => [
          new AddData<Post>({
            data: [post],
            schema: postsSchema
          }),
          new postActions.LoadPostSuccess(action.payload)
        ])
      )),
    catchError((error, caught) => {
      if (error.status === 404) {
        this.toastService.translateError('inbox.notifications.errors.post_not_exist');

      } else if (error.status === 408) {
        this.toastService.translateError('errors.common.network_error');

      } else {
        this.toastService.translateError('errors.common.unknown_error');
      }

      this.store.dispatch(new postActions.LoadPostFail(error));

      return caught;
    })
  ));


  reloadPost$ = createEffect(() => this.actions$.pipe(ofType(postActions.RELOAD_POST),
    switchMap((action: postActions.ReloadPost) => [
      new postActions.LoadPost(action.payload),
      new postActions.LoadLastComments(action.payload)
    ])
  ));


  loadLastComments$ = createEffect(() => this.actions$.pipe(ofType(postActions.LOAD_LAST_COMMENTS),
    switchMap((action: postActions.LoadLastComments) => this.socialProvider.loadLastComments(action.payload)
      .pipe(
        mergeMap((commentFeed) => [
          new SetData<CommentFeed>({
            data: [{
              ...commentFeed,
              id: currentCommentFeedId
            }],
            schema: commentFeedSchema
          }),
          new postActions.LoadLastCommentsSuccess()
        ])
      )),
    catchError((error, caught) => {
      this.store.dispatch(new postActions.LoadLastCommentsFail(error));

      return caught;
    })
  ));


  loadAllComments$ = createEffect(() => this.actions$.pipe(ofType(postActions.LOAD_ALL_COMMENTS),
    switchMap((action: postActions.LoadAllComments) => this.socialProvider.loadAllComments(action.payload)
      .pipe(
        mergeMap((comments: Comment[]) => [
          new SetData<CommentFeed>({
            data: [{
              total_count: comments.length,
              list: comments,
              id: currentCommentFeedId
            }],
            schema: commentFeedSchema
          }),
          new postActions.LoadAllCommentsSuccess()
        ])
      )),
    catchError((error, caught) => {
      this.store.dispatch(new postActions.LoadAllCommentsFail(error));

      return caught;
    })
  ));


  deleteComment$ = createEffect(() => this.actions$.pipe(ofType(postActions.DELETE_COMMENT),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getCommentFeedById)))
    ),
    switchMap(([action, commentFeed]: [postActions.DeleteComment, CommentFeed]) => {
      if (!commentFeed) { return; }
      const commentId = action.payload.commentId;

      return this.socialProvider.deleteComment(commentId)
        .pipe(
          mergeMap(() => [
            new SetData<CommentFeed>({
              data: [{
                ...commentFeed,
                total_count: commentFeed.total_count ? commentFeed.total_count - 1 : 0,
                id: currentCommentFeedId
              }],
              schema: commentFeedSchema
            }),
            new RemoveChildData({
              id: action.payload.commentId,
              childSchema: commentSchema,
              parentSchema: commentFeedSchema,
              parentId: currentCommentFeedId
            }),
            new postActions.DeleteCommentSuccess({
              deletedCommentId: commentId,
              commentUpdate: {
                postId: action.payload.postId,
                totalCount: commentFeed.total_count ? commentFeed.total_count - 1 : 0
              }
            })
          ])
        );
    }),
    catchError((error, caught) => {
      this.store.dispatch(new postActions.DeleteCommentFail(error));

      return caught;
    })
  ));


  deleteCommentSuccess$ = createEffect(() => this.actions$.pipe(ofType(postActions.DELETE_COMMENT_SUCCESS),
    tap(() => this.toastService.translateConfirm('social.journal.comment_deleted'))
  ), {dispatch: false});


  deleteCommentFail$ = createEffect(() => this.actions$.pipe(ofType(postActions.DELETE_COMMENT_FAIL),
    tap(() => this.toastService.translateError('errors.common.generic_error_please_retry'))
  ), {dispatch: false});


  saveComment$ = createEffect(() => this.actions$.pipe(ofType(postActions.SAVE_COMMENT),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getCommentFeedById)))
    ),
    switchMap(([action, commentFeed]: [postActions.SaveComment, CommentFeed]) => {
      if (!commentFeed) throw { status: 404 }; // eslint-disable-line no-throw-literal

      return this.socialProvider.saveComment(action.payload.content, action.payload.postId)
        .pipe(
          mergeMap((comment) => [

            new AddData<CommentFeed>({
              data: [{
                ...commentFeed,
                total_count: commentFeed.total_count ? commentFeed.total_count + 1 : 0
              }],
              schema: commentFeedSchema
            }),
            new AddChildData<Comment>({
              data: [comment],
              childSchema: commentSchema,
              parentSchema: commentFeedSchema,
              parentId: currentCommentFeedId
            }),
            new postActions.SaveCommentSuccess({
              postId: action.payload.postId,
              totalCount: typeof commentFeed.total_count === 'number' ? commentFeed.total_count + 1 : 0
            })
          ])
        );
    }),
    catchError((error, caught) => {
      this.store.dispatch(new postActions.SaveCommentFail(error));

      return caught;
    })
  ));


  saveCommentSuccess$ = createEffect(() => this.actions$.pipe(ofType(postActions.SAVE_COMMENT_SUCCESS),
    tap(() => {
      this.store.dispatch(new userActivityActions.TrackActivity({kind: 'mscommunity', name: 'Comment'}));
      this.toastService.translateConfirm('social.posts.comment_sent');
    })
  ), {dispatch: false});


  saveCommentFail$ = createEffect(() => this.actions$.pipe(ofType(postActions.SAVE_COMMENT_FAIL),
    tap((action: postActions.SaveCommentFail) => {
      if (action.payload.status === 404) {
        this.toastService.translateError('errors.social.post_no_longer_exists');
      } else {
        this.toastService.translateError('errors.common.generic_error_please_retry');
      }
    })
  ), {dispatch: false});


  cleanCommentFeed$ = createEffect(() => this.actions$.pipe(ofType(postActions.CLEAN_COMMENT_FEED),
    map(() => new RemoveData({
      id: currentCommentFeedId,
      schema: commentFeedSchema
    }))
  ));


  toggleLikeComment$ = createEffect(() => this.actions$.pipe(ofType(postActions.TOGGLE_LIKE_COMMENT),
    concatMap(action => of(action)
      .pipe(withLatestFrom(
        this.store.select(getCommentById),
        this.store.select(getCurrentProfileId)
      ))
    ),
    switchMap<any, Observable<Action>>(([action, comment, profileId]: [postActions.ToggleLikeComment, Comment, string]) => {
      const providerAction = (comment.current_user_liked !== true) ?
        this.socialProvider.addLikeOnComment :
        this.socialProvider.removeLikeFromComment;

      return providerAction.bind(this.socialProvider)(action.payload)
        .pipe(
          mergeMap((like: Like) => {
            if (!comment) { return; }

            const likes = {
              total_count: comment.likes ? comment.likes.total_count : 0,
              list: comment.likes.list.slice()
            };

            likes.total_count = likes.total_count + (comment.current_user_liked ? -1 : 1);

            if (like) {
              likes.list.push(like);

              this.store.dispatch(new userActivityActions.TrackActivity({kind: 'mscommunity', name: 'Like'}));
            } else {
              likes.list.splice(likes.list.findIndex(item => item.profile_id === profileId), 1);
            }

            return [
              new AddData<Comment>({
                data: [{
                  ...comment,
                  likes,
                  current_user_liked: !comment.current_user_liked
                }],
                schema: commentSchema
              }),
              new postActions.ToggleLikeCommentSuccess(!comment.current_user_liked)
            ];
          }),
          catchError((error) => of(new postActions.ToggleLikeCommentFail(comment.id, error)))
        );
    })
  ));


  toggleLikeCommentSuccess$ = createEffect(() => this.actions$.pipe(ofType(postActions.TOGGLE_LIKE_COMMENT_SUCCESS),
    tap((action: postActions.ToggleLikeCommentSuccess) => {
      const key = action.payload ? 'social.posts.comment_like_success' : 'social.posts.comment_unlike_success';
      this.toastService.translateConfirm(key);
    })
  ), {dispatch: false});


  toggleLikeCommentFail$ = createEffect(() => this.actions$.pipe(ofType(postActions.TOGGLE_LIKE_COMMENT_FAIL),
    tap((action: postActions.ToggleLikeCommentFail) => {
      if (action.error.status === 404) {
        this.toastService.translateError('errors.social.comment_no_longer_exists');
      } else {
        this.toastService.translateError('errors.common.generic_error_please_retry');
      }
    })
  ), {dispatch: false});


  notifyLikeOnComment$ = createEffect(() => this.actions$.pipe(ofType(postActions.NOTIFY_COMMENT_LIKE),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getCommentById)))
    ),
    switchMap(([action, comment]: [postActions.NotifyCommentLike, Comment]) => {
      const params = action.payload;
      const actions: Action[] = [
        new AddData<Comment>({
          data: [{
            ...comment,
            likes: {
              total_count: params.likes_count,
              list: params.avatars.map(avatar => ({
                avatar,
                id: null,
                profile_id: null
              }))
            }
          }],
          schema: commentSchema
        })
      ];

      return actions;
    })
  ));


  notifyNewComment$ = createEffect(() => this.actions$.pipe(ofType(postActions.NOTIFY_NEW_COMMENT),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getOpenPostId)))
    ),
    switchMap(([action, id]: [postActions.NotifyNewComment, any]) => {

      if (!id || action.payload.post_id !== id) {
        return [];
      }

      return [
        new postActions.LoadComment(action.payload.comment_id)
      ];
    })
  ));


  loadComment$ = createEffect(() => this.actions$.pipe(ofType(postActions.LOAD_COMMENT),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getCommentFeedById)))
    ),
    switchMap(([action, commentFeed]: [postActions.LoadComment, CommentFeed]) => {
      if (!commentFeed) return;

      return this.socialProvider.getCommentById(action.payload)
        .pipe(
          mergeMap((comment) => [
            new AddData<CommentFeed>({
              data: [{
                ...commentFeed,
                total_count: commentFeed.total_count ? commentFeed.total_count + 1 : 0
              }],
              schema: commentFeedSchema
            }),
            new AddChildData<Comment>({
              data: [comment],
              childSchema: commentSchema,
              parentSchema: commentFeedSchema,
              parentId: currentCommentFeedId
            }),
            new postActions.LoadCommentSuccess(comment)
          ])
        );
    })
  ));


  loadCommentSuccess$ = createEffect(() => this.actions$.pipe(ofType(postActions.LOAD_COMMENT_SUCCESS),
    tap((action: postActions.LoadCommentSuccess) =>
      this.toastService.translateConfirm('social.posts.user_commented_post', { user: action.payload.username })
    )
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private socialProvider: SocialProvider,
    private store: Store<SessionState>,
    private toastService: ToastService
  ) { }
}
