import * as userFavsActions from '../actions/user-favorites.actions';

export interface UserFavoritesState {
  updating: boolean;
}

export const initialUserFavsState: UserFavoritesState = {
  updating: false
};

export function userFavoritesReducer(
  state = initialUserFavsState,
  action: userFavsActions.UserFavsActions
): UserFavoritesState {
  switch (action.type) { 
    case userFavsActions.DELETE_USER_FAVORITE:
    case userFavsActions.CREATE_USER_FAVORITE: {
      return {
        ...state,
        updating: true
      };
    }
    case userFavsActions.DELETE_USER_FAVORITE_FAIL:
    case userFavsActions.DELETE_USER_FAVORITE_SUCCESS:
    case userFavsActions.CREATE_USER_FAVORITE_FAIL:
    case userFavsActions.CREATE_USER_FAVORITE_SUCCESS: {
      return {
        ...state,
        updating: false
      };
    }
  }

  return state;
}

export const userFavsUpdating = (state: UserFavoritesState) => state.updating;
