import { Component, EventEmitter, Output, Injector } from '@angular/core';
import { WizardComponent } from '../wizard.component';
import { Observable , combineLatest , Subject } from 'rxjs';
import { Exercise } from '../../../../store/normalized/schemas/exercise.schema';
import * as navigationActions from '../../../../store/session/actions/navigation.actions';
import * as toolsActions from '../../../../store/session/actions/tools.actions';
import { StressMetersService } from '../../../../services/wizards/stress-meters.service';
import {
  getExerciseById, getRainExercise
} from '../../../../store/normalized/selectors/exercises.selectors';
import * as exercisesActions from '../../../../store/session/actions/exercises.actions';
import {
  getCurrentModule
} from '../../../../store/session/selectors/program.selectors';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { getCheckinExercises } from '../../../../store/normalized/selectors/checkin.selectors';
import { exerciseTitleToImg } from '../../../../constants/checkins.constants';
import { getAcceleratedTo } from '../../../../store/normalized/selectors/user-bootstrap.selectors';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../../store/session/session.reducers';
import { BrowserService } from '../../../../services/browser.service';
import { ClarityConfig } from '../../../../config/clarity.config';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cl-stress-meter-wizard',
  styleUrls: ['../../wizard.scss', 'stress-meter-wizard.component.scss'],
  templateUrl: 'stress-meter-wizard.component.html'
})
export class StressMeterWizardComponent extends WizardComponent {

  constructor(
    protected store: Store<SessionState>,
    protected browser: BrowserService,
    protected injector: Injector,
    public config: ClarityConfig,
    protected analyticsService: AnalyticsService,
    protected translate: TranslateService
  ) {
    super(store, browser, injector, config, analyticsService, translate);
  }

  public anxiousFeeling = 0;
  public feelingNow = 50;
  public triggers = [];
  public triggerChoice = null;
  public exerciseToPlay = null;
  public isStressExercise = false;
  public rainExercise$ = this.store.select(getRainExercise);
  public breatheExercise$ = this.store.select(getExerciseById);
  public otherExercises$: Observable<any>;
  public header: string;
  public checkinExercise = false;

  public showExerciseStep = false;

  public module2Completed = false;
  public module12Completed = false;
  public autoPlay = true;
  public playerController = new Subject<string>();

  public service: StressMetersService;

  @Output() nextStep = new EventEmitter();

  protected wizardConfig = {
    title: 'wizards.stress_meter',
    totalSteps: 3,
    initialStep: 1
  };

  protected _init() {
    this.service = this.injector.get<StressMetersService>(StressMetersService);
    this.store.dispatch(new toolsActions.StartStressMeter());

    this.store.select(getCurrentModule)
      .pipe(withLatestFrom(this.store.select(getAcceleratedTo)))
      .pipe(take(1))
      .subscribe(([currentModule, acceleratedTo]) => {
        if ((currentModule.number === 2 && currentModule.isCompleted)
          || currentModule.number > 2 || acceleratedTo >= 2) {
          this.module2Completed = true;
        }

        if ((currentModule.number === 12 && currentModule.isCompleted)
          || currentModule.number > 12 || acceleratedTo >= 12) {
          this.module12Completed = true;
        }
      });

    return this.service.initModel()
      .then(() => {
        this.triggers = this.service.getTriggersList();
      });
  }

  initStep1() {
    this.anxiousFeeling = this.service.getCravingIntensity() / 10;
    this.onEnableNext();
  }

  initStep2() {
    this.loadTriggerChoice();
  }

  initStep3() {
    this.onDisableNext();
    this.showExerciseStep = false;
    this.checkinExercise = false;
  }

  isStepValid2() {
    return this.service.isTriggerValid();
  }

  setAnxiousFeelingChoice(value) {
    this.service.setCravingIntensity(value * 100);
  }

  onIntensityAfterFeelingChange(value) {
    this.service.setCravingIntensityAfterFeeling(value);
  }

  onTriggerSelected(trigger) {
    const triggerItem = this.triggers.find((item) => item.id === trigger);
    this.getRecommendedExercises(triggerItem.exercise_id);

    this.service.setTrigger(trigger);
    this.loadTriggerChoice();
  }

  private loadTriggerChoice() {
    this.exerciseToPlay = null;
    this.triggerChoice = this.service.getTrigger();

    if (this.triggerChoice) {
      this.onEnableNext();
    }
    else {
      this.onDisableNext();
    }
  }

  onDone() {
    this.service.save();
    this.playerController.next('pause');
    this.wizardComplete.emit();
  }

  getRecommendedExercises(id) {
    this.store.dispatch(new exercisesActions.GetBonusExerciseById(id));
    this.store.select(getExerciseById);
    this.otherExercises$ = combineLatest([this.breatheExercise$])
      .pipe(
        map((input) => input)
      );
  }

  playExercise(exercise: Exercise) {
    this.exerciseToPlay = exercise;
    this.autoPlay = true;

    this.service.setExercise(exercise.id);
    this.service.setUserAction('exercise');

    if (exercise.tags.find((tag) => tag.name === 'breathe_into_anxiety')) {
      this.service.setUserAction('breathe_exercise');

      this.store.dispatch(new navigationActions.ShowBreatheExercise());

      this.onEnableNext();

      return;
    }

    if (exercise.tags.find((tag) => tag.name.startsWith('rain'))) {
      this.autoPlay = false;
    }

    this.showExerciseStep = true;
    this.onEnableNext();
  }

  imFeelingLucky() {
    this.service.setUserAction('checkin_exercise');

    this.store.select(getCheckinExercises)
      .pipe(take(1))
      .subscribe((checkinExercises) => {
        this.exerciseToPlay = checkinExercises[Math.floor(Math.random() * checkinExercises.length)];
        this.checkinExercise = true;
        this.exerciseToPlay.graphic = exerciseTitleToImg(this.translate.currentLang, this.exerciseToPlay.title);
        this.showExerciseStep = true;
        this.onEnableNext();
      });
  }

  startStressTest() {
    this.service.setUserAction('stress_test');
    this.store.dispatch(new navigationActions.OpenWizard({type: 'stresstest-ua'}));

    this.onEnableNext();
  }

}
