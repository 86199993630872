import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as authActions from '../../sensitive/actions/auth.actions';
import * as notificationsActions from './../actions/notifications.actions';
import * as syncActions from './../actions/sync.actions';
import * as programActions from './../actions/program.actions';
import * as cigTrackerActions from './../actions/cig-tracker.actions';
import * as flagActions from '../../persistent/flags/flags.actions';

import { map, switchMap, mergeMap, catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { NotificationsService } from '../../../../app/services/notification.service';
import { from as fromPromise, of } from 'rxjs';
import { SetData } from 'ngrx-normalizr';
import { NotificationFeed, notificationFeedSchema } from '../../normalized/schemas/inbox.schema';
import { NotificationsProvider } from '../../../providers/notifications.provider';
import { notificationFeedId, NotificationState } from '../reducers/notifications.reducer';
import { getCurrentNotificationFeed, getLastUnreadNotificiationId } from '../selectors/notifications.selectors';
import { Store } from '@ngrx/store';

@Injectable({providedIn: 'root'})
export class NotificationsEffects {


  clearNotifications$ = createEffect(() => this.actions$.pipe(ofType(authActions.LOGOUT, authActions.LOGOUT_FROM_ERROR),
    switchMap(_ => of(this.notificationsService.cancelNotifications()))
  ), {dispatch: false});


  resetNotifications$ = createEffect(() => this.actions$.pipe(ofType(notificationsActions.RESET_NOTIFICATIONS),
    switchMap(_ => of(this.notificationsService.resetNotifications()))
  ), {dispatch: false});


  initLiveProgram$ = createEffect(() => this.actions$.pipe(ofType(programActions.INIT_LIVE_PROGRAM),
    switchMap(_ => of(this.notificationsService.resetNotifications()))
  ), {dispatch: false});


  syncUserProgress$ = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_USER_PROGRESS_SUCCESS),
    switchMap(_ => of(this.notificationsService.resetNotifications()))
  ), {dispatch: false});


  syncUserReminders = createEffect(() => this.actions$.pipe(ofType(syncActions.SYNC_USER_REMINDERS_SUCCESS),
    switchMap(_ => of(this.notificationsService.resetNotifications()))
  ), {dispatch: false});


  askForNotificationPermission$ = createEffect(() => this.actions$.pipe(ofType(notificationsActions.ASK_PERMISSION),
    switchMap(() => fromPromise(this.notificationsService.requestNotificationsPermission())
      .pipe(
        map((notifications: any) => {
          if (notifications !== undefined) {
            // Make sure the promise was resolved with granted true/false!
            // otherwise we will want to ask again for permissions at next app start
            this.store.dispatch(new flagActions.SetFlag('requestedPermissions'));
          }

          return notifications?.granted ? new notificationsActions.NotificationsAllowed() : new notificationsActions.NotificationsNotAllowed();
        })
      )
    )
  ));

  cigsSmoked$ = createEffect(() => this.actions$.pipe(ofType(cigTrackerActions.TRACK_CIG_COUNT),
    switchMap(_ => of(this.notificationsService.resetNotifications()))
  ), {dispatch: false});


  loadAllNotifications$ = createEffect(() => this.actions$.pipe(ofType(notificationsActions.LOAD_ALL_NOTIFICATIONS),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getCurrentNotificationFeed)))
    ),
    switchMap(([action, notificationFeed]) => this.notificationsProvider.loadAllNotifications()
      .pipe(
        mergeMap(notifications => [
          new SetData<NotificationFeed>({
            data: [{
              ...notificationFeed,
              list: notifications,
              id: notificationFeedId()
            }],
            schema: notificationFeedSchema
          }),
          new notificationsActions.LoadAllNotificationsSuccess()
        ])
      )),
    catchError((error, caught) => {
      this.store.dispatch(new notificationsActions.LoadAllNotificationsFailure());

      return caught;
    })
  ));


  loadNotifications$ = createEffect(() => this.actions$.pipe(ofType(notificationsActions.LOAD_NOTIFICATIONS),
    switchMap(() => this.notificationsProvider.loadNotifications()
      .pipe(
        mergeMap(notificationFeed => [
          new SetData<NotificationFeed>({
            data: [{
              ...notificationFeed,
              id: notificationFeedId()
            }],
            schema: notificationFeedSchema
          }),
          new notificationsActions.LoadNotificationsSuccess()
        ])
      )),
    catchError((error, caught) => {
      this.store.dispatch(new notificationsActions.LoadNotificationsFailure());

      return caught;
    })
  ));


  markCommunityNotificationsAsRead$ = createEffect(() => this.actions$.pipe(ofType(notificationsActions.MARK_COMMUNITY_NOTIFICATIONS_AS_READ),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getLastUnreadNotificiationId)))
    ),
    switchMap(([action, lastUnreadNotifId]: [notificationsActions.MarkCoachNotificationsAsRead, number]) =>
      this.notificationsProvider.markCommunityNotificationsAsRead(lastUnreadNotifId)
        .pipe(
          map(() => new notificationsActions.MarkCommunityNotificationsAsReadSuccess())
        )),
    catchError((error, caught) => {
      this.store.dispatch(new notificationsActions.MarkCommunityNotificationsAsReadFail());

      return caught;
    })
  ));


  markCommunityNotificationsAsReadSuccess$ = createEffect(() => this.actions$.pipe(ofType(notificationsActions.MARK_COMMUNITY_NOTIFICATIONS_AS_READ_SUCCESS),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getCurrentNotificationFeed)))
    ),
    map(([action, notificationFeed]: [notificationsActions.MarkCoachNotificationsAsReadSuccess, NotificationFeed]) => new SetData<NotificationFeed>(
      {
        data: [{
          ...notificationFeed,
          total_unread_count: 0,
          id: notificationFeedId()
        }],
        schema: notificationFeedSchema
      }
    ))
  ));

  constructor(
    private actions$: Actions,
    private notificationsService: NotificationsService,
    private notificationsProvider: NotificationsProvider,
    private store: Store<NotificationState>
  ) {
  }
}
