import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmokingType } from 'src/app/store/normalized/schemas/user.schema';

@Component({
  selector: 'cl-smoking-simulation-step',
  styleUrls: [
    '../craving-tool-ctq.scss',
    'smoking-simulation-step.component.scss'
  ],
  template: `
    <cl-waves-background wizard-bg bg-position-bottom>
      <ion-content>
        <ion-header>
          <cl-header-nav-buttons
            class="page-header"
            color="primary"
            (closeClick)="triggerClose()"
            [backButton]="false"
          >
          </cl-header-nav-buttons>
        </ion-header>

        <div class="first-steps lateral-padding">
          <div class="use-grid step-intro">
            <cl-inner-item
              *ngFor="let step of simulationSteps; index as stepNumber"
              [active]="simulationStep === stepNumber"
              [completed]="simulationStep > stepNumber"
              (changeItem)="completeSimulationStep(stepNumber)"
              [label]="step | translate"
            >
            </cl-inner-item>
          </div>
        </div>
      </ion-content>
    </cl-waves-background>

    <div class="controls-holder padding-bottom-iphone-x">
      <ion-row class="buttons-holder lateral-padding">
        <ion-col size="6">
          <cl-prev-button
            [ngClass]="{ 'dark-bg': darkBackground }"
            label="common.skip"
            [canClick]="true"
            (action)="triggerSkip()"
          >
          </cl-prev-button>
        </ion-col>

        <ion-col size="6">
          <cl-next-button
            [ngClass]="{ 'dark-bg': darkBackground }"
            label="common.next"
            [canClick]="simulationNextEnabled"
            (action)="triggerNext()"
          >
          </cl-next-button>
        </ion-col>
      </ion-row>
    </div>
  `
})
export class SmokingSimulationStepComponent {
  @Input() smokingType: SmokingType;
  @Input() darkBackground = false;

  @Output() next = new EventEmitter();
  @Output() skip = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  simulationStepsSmoking = [
    'craving_tool.bring_to_mind_the_last_time_you_smoked',
    'craving_tool.what_did_the_smoke_taste_like',
    'craving_tool.what_did_it_feel_going_into_your_throat',
    'craving_tool.what_did_it_feel_going_into_your_lungs',
    'craving_tool.what_did_it_smell_like',
    'craving_tool.what_did_your_body_feel_like_after_you_smoked',
    'craving_tool.what_thoughts_and_emotions_did_you_have_after_smoking'
  ];

  simulationStepsVaping = [
    'craving_tool.bring_to_mind_the_last_time_you_vaped',
    'craving_tool.what_did_the_vapor_taste_like',
    'craving_tool.what_did_it_feel_going_into_your_throat',
    'craving_tool.what_did_it_feel_going_into_your_lungs',
    'craving_tool.what_did_it_smell_like',
    'craving_tool.what_did_your_body_feel_when_you_were_vaping',
    'craving_tool.what_did_your_body_feel_after_you_vaped'
  ];

  simulationStep = 0;

  get simulationSteps() {
    return this.smokingType === SmokingType.CIGARETTE ? this.simulationStepsSmoking : this.simulationStepsVaping;
  }

  get simulationNextEnabled() {
    return this.simulationStep >= this.simulationSteps.length;
  }

  completeSimulationStep(step) {
    if (step > this.simulationStep) {
      return;
    }
    this.simulationStep = this.simulationStep > step ? step : step + 1;
  }

  triggerNext() {
    this.next.emit();
  }

  triggerSkip() {
    this.skip.emit();
  }

  triggerClose() {
    this.closeClick.emit();
  }
}
