export enum PLAYER_ERROR_CODES {
    NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
    MISSING_POLICYKEY = 'MISSING_POLICYKEY',
    MISSING_ACCOUNTID = 'MISSING_ACCOUNTID',
    MISSING_FILEID = 'MISSING_FILEID',
    MISSING_SOURCE_URL = 'MISSING_SOURCE_URL',
    REQUIRES_IOS12_OR_HIGHER = 'REQUIRES_IOS12_OR_HIGHER',
    NO_INTERNET_CONNECTION = 'NO_INTERNET_CONNECTION',
    FILE_NOT_EXIST_AND_NO_INTERNET = 'FILE_NOT_EXIST_AND_NO_INTERNET',
    FILE_NOT_EXIST = 'FILE_NOT_EXIST',
    MISSING_FILE_PARAMETER = 'MISSING_FILE_PARAMETER',
    VIDEO_CANT_BE_DOWNLOADED = 'VIDEO_CANT_BE_DOWNLOADED',
    DOWNLOAD_STATUS_NOT_DETERMINED = 'DOWNLOAD_STATUS_NOT_DETERMINED',
    DOWNLOADED_FILE_NOT_FOUND = 'DOWNLOADED_FILE_NOT_FOUND',
    TECHNICAL_ERROR = 'TECHNICAL_ERROR',
}
