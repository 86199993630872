import { Component, ViewChild, OnInit, HostBinding } from '@angular/core';

import { IonContent, ModalController } from '@ionic/angular';
import { ClarityConfig } from '../../config/clarity.config';
import { TranslateService } from '@ngx-translate/core';
import { OnboardingService } from '../../services/onboarding.service';
import { Store } from '@ngrx/store';
import { take, withLatestFrom } from 'rxjs/operators';
import { getIntroduceGearsVideo } from '../../store/normalized/selectors/exercises.selectors';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { SessionState } from '../../store/session/session.reducers';
import { getCurrentUserProgram } from '../../store/normalized/selectors/user.selectors';
import { combineLatest } from 'rxjs';
import { SmokingType } from '../../store/normalized/schemas/user.schema';
import { TrackActivity } from '../../store/session/actions/user-activity.actions';

const ACTIONS = {
  'gears_review.Ogear_practice': {
    name: 'common.watch_now',
    code: 'introducingGears'
  }
};

@Component({
  selector: 'page-gears-review',
  styleUrls: ['gears-review.scss'],
  templateUrl: 'gears-review.html'
})
export class GearsReviewPage implements OnInit {
  modal;

  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  @ViewChild(IonContent, { static: true }) content: IonContent;

  readonly gears = [
    {name: 'O'},
    {name: 'R'},
    {name: '1'},
    {name: '2'},
    {name: '3'}
  ];

  gear = 'O';

  accordeonItems: {expanded: boolean; name: string; description: string; action?: { name: string; code: string } }[] = [];

  private _smokingType: SmokingType;

  constructor(
    public config: ClarityConfig,
    public modalController: ModalController,
    public translation: TranslateService,
    public store: Store<SessionState>,
    public onboardingService: OnboardingService
  ) {
  }

  ngOnInit() {
    this.reviewPracticeItems();
  }

  ionViewDidEnter() {
    this.onboardingService.checkShowingOnboarding({type: 'gears_review'});
    this.store.dispatch(new TrackActivity({
      activity_at: new Date(),
      kind: 'page_view',
      name: 'gears'
    }));
  }

  get gearText() {
    return `gears_review.${this.gear}gear`;
  }

  get gearName() {
    return `gears_review.${this.gear}gear_title`;
  }

  get gearReview() {
    return `gears_review.${this.gear}gear_review`;
  }

  get gearReviewProgram() {
    const program = this.config.currentProgram();

    let reviewType = `${this.gear}gear_review_${program}`;

    if ((this.gear === 'R' || this.gear === '1' || this.gear === '2') && program === 'ctq') {
      // "[R,1,2]gear_review_ctq" contain translations which includes terms as 'cigarettes' or 'puffs'
      reviewType = this._smokingType === SmokingType.CIGARETTE ? `${reviewType}_cig` : `${reviewType}_puff`;
    }

    return `gears_review.${reviewType}`;
  }

  get gearPractice() {
    return `gears_review.${this.gear}gear_practice`;
  }

  get gearPracticeProgram() {
    const program = this.config.currentProgram();

    let reviewType = `${this.gear}gear_practice_${program}`;

    if (this.gear === '2' && program === 'ctq') {
      // "2gear_practice_ctq" has translations which includes terms as 'cigarettes' or 'puffs'
      reviewType = this._smokingType === SmokingType.CIGARETTE ? `${reviewType}_cig` : `${reviewType}_puff`;
    }

    return `gears_review.${reviewType}`;
  }

  reviewPracticeItems() {
    this.store.select(getCurrentUserProgram)
      .pipe(take(1))
      .toPromise()
      .then((userProgram) => {
        this._smokingType = userProgram.smoking_type;

        this.translation.get([
          this.gearReview,
          this.gearReviewProgram,
          this.gearPractice,
          this.gearPracticeProgram
        ])
          .subscribe((translations) => {
            const practice = translations[this.gearPractice];
            const practiceProgram = translations[this.gearPracticeProgram];
            const review = translations[this.gearReview];
            const reviewProgram = translations[this.gearReviewProgram];

            this.accordeonItems = [
              {
                name: 'gears_review.practice',
                expanded: true,
                description: [practice, practiceProgram].join('\n'),
                action: this.getAction(this.gearPractice)
              },
              {
                name: 'gears_review.review',
                expanded: false,
                description: [review, reviewProgram].join('\n'),
                action: this.getAction(this.gearReview)
              }
            ];
          });
      });
  }

  getAction(name) {
    return ACTIONS[name];
  }

  showGear(gearInfo) {
    this.gear = gearInfo.name;
    this.reviewPracticeItems();
    this.content.scrollToTop();
  }

  onAction(action) {
    if (action === ACTIONS['gears_review.Ogear_practice'].code) {
      this.store.select(getIntroduceGearsVideo)
        .pipe(
          take(1)
        )
        .subscribe((introduceGearsVideo) => {
          const params = {
            type: 'introduceGears',
            video: introduceGearsVideo,
            forceOnboarding: true
          };
          this.onboardingService.checkShowingOnboarding(params);
        });
    }
  }

  onClose() {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }
}
