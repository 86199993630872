<cl-waves-background intro-bg [ngClass]="{'desktop signup': isDesktop, 'from-account-setup': fromAccountSetup}">

  <ion-header *ngIf="!fromAccountSetup">
    <cl-header-nav-buttons
      class="page-header"
      [backButton]="false"
      (closeClick)="dismissUpgradePage()"
      color="{{ !isDesktop ? 'primary' : 'white' }}">
    </cl-header-nav-buttons>
  </ion-header>

  <ion-content class="ion-padding-top">
    <cl-registration-wrapper class="lateral-padding" [class.container]="isDesktop">

      <ion-row class="logo">
        <ion-col *ngIf="!(liveSubscription$ | async)" class="padding-top-iphone-x">
          <cl-icon-title-container
            *ngIf="fromAccountSetup"
            [icon]="config.logo"
            header="{{config.program.name}}&reg;"
            intro="{{ 'subscriptions.try_first_X_modules_free' | translate: {modulesCount: 'common.' + config.program.trialModules | translate} }}"
            [showCompany]="true">
          </cl-icon-title-container>

          <cl-icon-title-container
            *ngIf="!fromAccountSetup"
            [icon]="config.logo"
            header="{{config.program.name}}&reg;"
            intro="{{ 'subscriptions.we_hope_you_enjoy_your_time_with_x' | translate: {programName: config.program.name} }}"
            [showCompany]="true">
          </cl-icon-title-container>
        </ion-col>

        <ion-col *ngIf="liveSubscription$ | async">
          <cl-icon-title-container
            [icon]="config.logo"
            header="{{config.program.name}}&reg;"
            [showCompany]="true">
          </cl-icon-title-container>
        </ion-col>
      </ion-row>

      <cl-active-subscription
        *ngIf="shouldShowSuccessMessage"
        [isSharecareSso]="isSharecareSso"
        [isSharecareStandalone]="isSharecareStandalone"
        [subscription]="liveSubscription$ | async"
        (continue)="onTrial()">
      </cl-active-subscription>

      <cl-upgrade-options
        *ngIf="!shouldShowSuccessMessage"
        [subscription]="liveSubscription$ | async"
        [fromAccountSetup]="fromAccountSetup"
        [isSharecareSso]="isSharecareSso"
        [isSharecareStandalone]="isSharecareStandalone"
        (exit)="onTrial()"
        (subscribe)="onSubscribe()"
        (restore)="onRestoreSubscription()"
        (license)="onLicense()">
      </cl-upgrade-options>

    </cl-registration-wrapper>
  </ion-content>

</cl-waves-background>
