import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'cl-self-assessment-slider',
  styleUrls: ['self-assessment-slider.component.scss'],
  template: `    
    <div class="slider-wrapper">
      <h4>{{assessment | translate}}</h4>

      <div class="slider-options legend">
        <span>{{'common.never' | translate}}</span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span>{{'common.always' | translate}}</span>
      </div>
      
      <div class="slider-options">
        <span [class.selected]="sliderValue === 0">0</span>
        <span [class.selected]="sliderValue === 1">1</span>
        <span [class.selected]="sliderValue === 2">2</span>
        <span [class.selected]="sliderValue === 3">3</span>
        <span [class.selected]="sliderValue === 4">4</span>
        <span [class.selected]="sliderValue === 5">5</span>
      </div>

      <cl-horizontal-slider
        [value]="sliderValue"
        [min]="0"
        [max]="5"
        (slide)="onSlide($event)">
      </cl-horizontal-slider>
      
      <div class="clear"></div>
    </div>
  `
})
export class AwarenessQuizSliderComponent implements OnChanges{

  @Input() sliderValue = 0;
  @Input() assessment: string;

  @Output() setValue = new EventEmitter();

  onSlide(value) {
    this.setValue.emit(value);
  }

  ngOnChanges(changes) {
    if (changes.sliderValue) {
      this.sliderValue = changes.sliderValue.currentValue;
    }
  }
}
