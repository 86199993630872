// sync selectors

import { createSelector } from '@ngrx/store';

import * as syncReducer from '../reducers/sync.reducer';
import { getSyncState } from '../../../../app/store';

export const isFullSynced = createSelector(
  getSyncState,
  syncReducer.fullSynced
);

export const isSyncing = createSelector(
  getSyncState,
  syncReducer.syncing
);

export const isProgramSyncing = createSelector(
  getSyncState,
  syncReducer.programSyncing
);

export const isUserProgramSyncing = createSelector(
  getSyncState,
  syncReducer.userProgramSyncing
);

export const isUserGoalsSyncing = createSelector(
  getSyncState,
  syncReducer.userGoalsSyncing
);

export const isUserFavsSyncing = createSelector(
  getSyncState,
  syncReducer.userFavsSyncing
);

export const getCurrentDay = createSelector(
  getSyncState,
  syncReducer.currentDay
);

export const getResumedAt = createSelector(
  getSyncState,
  syncReducer.resumedAt
);

export const getLastSyncEverythingAt = createSelector(
  getSyncState,
  syncReducer.lastSyncEverythingAt
);

export const getLastSyncExercises = createSelector(
  getSyncState,
  syncReducer.lastSyncExercises
);

export const getIridiumTokenSyncing = createSelector(
  getSyncState,
  syncReducer.iridiumTokenSyncing
);
