import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { take , map } from 'rxjs/operators';
// import { Alert } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { differenceInMinutes, differenceInSeconds } from 'date-fns';
import {
  FIRST_CHECKIN,
  FIRST_CHECKIN_EXTENDED_THRESHOLD,
  FIRST_CHECKIN_NORMAL_THRESHOLD,
  SECOND_CHECKIN,
  SECOND_CHECKIN_EXTENDED_THRESHOLD,
  SECOND_CHECKIN_NORMAL_THRESHOLD
} from '../../constants/craving-tool.constants';
import { AlertController } from '@ionic/angular';
import { CravingTool } from '../../store/normalized/schemas/craving-tool.schema';
import { getCurrentCravingTool } from '../../store/normalized/selectors/craving-tool.selectors';
import { ClearCravingToolNotifications, SaveCravingTool } from '../../store/session/actions/tools.actions';
import { SessionState } from '../../store/session/session.reducers';
import { OpenModal } from '../../store/session/actions/navigation.actions';

export class CravingToolStatus {
  beforeFirstCheckinThreshold?: boolean;
  beforeSecondCheckinThreshold?: boolean;
  checkin5min?: boolean;
  hide15Min?: boolean;
  timeHasExpired?: boolean;
  checkin15min?: boolean;
  completed5min?: boolean;
  currentCravingTool: CravingTool;
}

@Injectable({
  providedIn: 'root'
})
export class CravingToolService {

  activePopup: any; // TODO: type Alert;
  activeTimer;

  constructor(
    private store: Store<SessionState>,
    private translate: TranslateService,
    private alertCtrl: AlertController
  ) {
  }

  closeActivePopup() {
    if (this.activePopup) {
      this.activePopup.dismiss();
      this.activePopup = undefined;
    }
  }

  getCravingTool() {
    return this.store.select(getCurrentCravingTool)
      .pipe(
        take(1)
      );
  }

  scheduleCravingToolPopup(options: { inMinutes: number }) {
    this.clearOldTimeouts();
    this.activeTimer = setTimeout(() => this.doYouWantToContinueThisExercisePopup(), options.inMinutes * 60 * 1000);
  }

  clearOldTimeouts() {
    if (this.activeTimer) {
      clearTimeout(this.activeTimer);
      this.activeTimer = undefined;
    }
    this.store.dispatch(new ClearCravingToolNotifications());
  }

  setupCravingToolPopup() {
    this.clearOldTimeouts();

    this.checkCravingToolState()
      .subscribe((currentState) => {
        const MINUTES = 60;

        if (!currentState.currentCravingTool) {
          return;
        }

        if (currentState.timeHasExpired) {
          this.savePastCravingTool();

          return;
        }
        const {completed5min} = currentState;
        const completedTimeout = Math.abs(differenceInSeconds(
          new Date(),
          new Date(currentState.currentCravingTool.recording_initial_at)
        ));

        if (currentState.beforeFirstCheckinThreshold) {
          const timeTillPopup = Math.max(0, (FIRST_CHECKIN * MINUTES) - completedTimeout);
          this.activeTimer = setTimeout(() => this.doYouWantToContinueThisExercisePopup(), timeTillPopup * 1000);

          return;
        }
        if (currentState.checkin5min) {
          this.activeTimer = setTimeout(() => this.doYouWantToContinueThisExercisePopup(), 1000);

          return;
        }
        if (completed5min) {
          const timeTillPopup = Math.max(0, ((FIRST_CHECKIN + SECOND_CHECKIN) * MINUTES) - completedTimeout);
          this.activeTimer = setTimeout(() => this.doYouWantToContinueThisExercisePopup(), timeTillPopup);

          return;
        }
      });
  }

  beforeThresholdPopup() {
    this.closeActivePopup();
    this.translate.get([
      'wizards.craving_tool',
      'wizards.you_already_started_one',
      'common.wait',
      'common.start_over'
    ])
      .subscribe(async (translations) => {
        this.activePopup = await this.alertCtrl.create({
          header: translations['wizards.craving_tool'],
          subHeader: translations['wizards.you_already_started_one'],
          buttons: [
            {
              text: translations['common.start_over'],
              handler: () => {
                this.savePastCravingTool();
                this.openCravingToolWithOptions({currentCravingTool: undefined});

                return false;
              }
            },
            {
              text: translations['common.wait'],
              role: 'cancel'
            }
          ]
        });
        await this.activePopup.present();
      });
    // you already started a craving-tool and we will notify you in X minutes.
    // do you want to start over?
    // restart / no
  }

  timeHasExpiredPopup() {
    this.closeActivePopup();
    this.translate.get([
      'wizards.craving_tool',
      'wizards.time_expired_craving_tool',
      'common.ok'
    ])
      .subscribe(async (translations) => {
        this.activePopup = await this.alertCtrl.create({
          header: translations['wizards.craving_tool'],
          subHeader: translations['wizards.time_expired_craving_tool'],
          buttons: [{
            text: translations['common.ok'],
            handler: () => {
              this.savePastCravingTool();
              this.openCravingToolWithOptions({currentCravingTool: undefined});

              return false;
            }
          }]
        });
        await this.activePopup.present();
      });
  }

  youAlreadyHaveOneCompleteOrRestartPopup(currentState) {
    this.closeActivePopup();
    this.translate.get([
      'wizards.craving_tool',
      'common.complete',
      'common.new',
      'wizards.you_already_started_a_craving_tool'
    ])
      .subscribe(async (translations) => {
        this.activePopup = await this.alertCtrl.create({
          header: translations['wizards.craving_tool'],
          subHeader: translations['wizards.you_already_started_a_craving_tool'],
          buttons: [
            {
              text: translations['common.new'],
              handler: () => {
                this.savePastCravingTool();
                this.openCravingToolWithOptions({currentCravingTool: undefined});

                return false;
              }
            },
            {
              text: translations['common.complete'],
              handler: () => {
                this.openCravingToolWithOptions(currentState);

                return false;
              }
            }
          ]
        });
        await this.activePopup.present();
      });
  }

  doYouWantToContinueThisExercisePopup() {
    this.closeActivePopup();
    this.translate.get([
      'wizards.craving_tool',
      'common.yes',
      'common.no',
      'wizards.do_you_want_to_complete_craving_tool'
    ])
      .subscribe(async (translations) => {
        this.activePopup = await this.alertCtrl.create({
          header: translations['wizards.craving_tool'],
          subHeader: translations['wizards.do_you_want_to_complete_craving_tool'],
          buttons: [
            {
              text: translations['common.no'],
              handler: () => {
                // TODO discard craving-tool
                this.savePastCravingTool();
                this.closeActivePopup();

                return false;
              }
            },
            {
              text: translations['common.yes'],
              handler: () => {
                this.openCravingTool({});

                return false;
              }
            }
          ]
        });
        await this.activePopup.present();
      });
    // opens exercise or finishes
  }

  openCravingToolWithOptions(options: CravingToolStatus) {
    this.closeActivePopup();
    this.clearOldTimeouts();
    this.store.dispatch(new OpenModal('CravingToolPage', {options}));
  }

  checkCravingToolState() {
    return this.getCravingTool()
      .pipe(
        map((currentCravingTool: CravingTool): CravingToolStatus => {
          if (!currentCravingTool) {
            return {currentCravingTool: undefined};
          }
          const minutesSince = Math.abs(differenceInMinutes(
            new Date(currentCravingTool.recording_initial_at),
            new Date()
          ));
          const completed5min = currentCravingTool.satisfaction_after_5mins != null;

          if (!completed5min) {
            if (minutesSince < FIRST_CHECKIN) {
              return {beforeFirstCheckinThreshold: true, currentCravingTool};
            }
            if (minutesSince < FIRST_CHECKIN_NORMAL_THRESHOLD) {
              return {checkin5min: true, hide15Min: false, currentCravingTool};
            }
            if (minutesSince >= FIRST_CHECKIN_NORMAL_THRESHOLD &&
              minutesSince < FIRST_CHECKIN_EXTENDED_THRESHOLD) {
              return {checkin5min: true, hide15Min: true, currentCravingTool};
            }
            if (minutesSince >= FIRST_CHECKIN_EXTENDED_THRESHOLD) {
              return {timeHasExpired: true, currentCravingTool};
            }
          }

          if (completed5min) {
            const minutesSince5min = Math.abs(differenceInMinutes(
              new Date(currentCravingTool.recording_5mins_at),
              new Date()
            ));
            if (minutesSince5min < SECOND_CHECKIN_NORMAL_THRESHOLD) {
              return {completed5min, beforeSecondCheckinThreshold: true, currentCravingTool};
            }
            if (minutesSince5min >= SECOND_CHECKIN_NORMAL_THRESHOLD &&
              minutesSince5min < SECOND_CHECKIN_EXTENDED_THRESHOLD) {
              return {completed5min, checkin15min: true, currentCravingTool};
            }
            if (minutesSince5min >= SECOND_CHECKIN_EXTENDED_THRESHOLD) {
              return {completed5min, timeHasExpired: true, currentCravingTool};
            }
          }
        })
      );
  }

  openCravingTool({fromNowTools = false, fromNotification = false}) {
    this.closeActivePopup();
    this.checkCravingToolState()
      .subscribe((currentState) => {
        const fromPopup = !(fromNowTools || fromNotification);

        if (!currentState.currentCravingTool) {
          // its a new craving-tool session
          return this.openCravingToolWithOptions(currentState);
        }

        if (currentState.beforeFirstCheckinThreshold ||
          currentState.beforeSecondCheckinThreshold) {
          return this.beforeThresholdPopup();
        }

        if (currentState.timeHasExpired) {
          if (fromNowTools) {
            this.savePastCravingTool();

            return this.openCravingToolWithOptions({currentCravingTool: undefined});
          }
          if (fromNotification || fromPopup) {
            return this.timeHasExpiredPopup();
          }
        }

        if (currentState && fromNowTools) {
          return this.youAlreadyHaveOneCompleteOrRestartPopup(currentState);
        }

        this.openCravingToolWithOptions(currentState);
      });
  }

  savePastCravingTool() {
    this.checkCravingToolState()
      .subscribe((currentState) => {
        if (!currentState.currentCravingTool) {
          return;
        }

        this.store.dispatch(new SaveCravingTool(currentState.currentCravingTool, {silent: true}));
      });
  }
}
