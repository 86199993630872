import { createSchemaSelectors } from 'ngrx-normalizr';
import { createSelector } from '@ngrx/store';
import { getSelectedListItems, ListItem } from '../schemas/list-item.schema';
import { UserGoal, userGoalsSchema } from '../schemas/user.schema';
import { getGoals } from './list-items.selectors';
import { getUserGoalIds } from './user.selectors';

export class SelectedListItem extends ListItem {
  checked: boolean;
}

const userGoalsSchemaSelector = createSchemaSelectors<UserGoal>(userGoalsSchema);

const userGoalsBaseSelector = userGoalsSchemaSelector.getEntities;

export const userGoalsSelector = createSelector(
  userGoalsBaseSelector,
  (userGoals) => {
    if (!userGoals) {
      return [];
    }

    return userGoals;
  }
);

export const getDefaultGoalsSelection = createSelector(
  getGoals,
  getUserGoalIds,
  (
    defaultGoals,
    goalIds
  ) => getSelectedListItems(defaultGoals, goalIds)
);
