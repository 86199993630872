import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CountByDay } from '../store/normalized/schemas/count-by-day.schema';
import { differenceInDays, parseISO } from 'date-fns';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { getCurrentUser } from '../store/normalized/selectors/user.selectors';
import { getCigarettesByDay } from '../store/normalized/selectors/count-by-day.selectors';
import { SessionState } from '../store/session/session.reducers';

@Injectable({providedIn: 'root'})
export class TrackerService {

  constructor(
    private store: Store<SessionState>
  ) {
  }

  public async calculateToDate(date) {
    const canSmoke = await this.calculateCanSmokeToDate(date);
    const savings = await this.calculateSmokingSavingsToDate(date);

    return {
      canSmoke,
      savings
    };
  }

  public async calculateCanSmokeToDate(date = new Date()) {
    const user = await this.store.select(getCurrentUser)
      .pipe(take(1))
      .toPromise();

    const endDate = typeof user.end_date === 'string' ? parseISO(user.end_date) : user.end_date;
    const startDate = typeof user.start_date === 'string' ? parseISO(user.start_date) : user.start_date;

    const daysToQuit = differenceInDays(endDate, startDate) + 1;
    const daysSoFar = differenceInDays(date, startDate);

    const countAllowed = Math.ceil(user.cigs_per_day - user.cigs_per_day / daysToQuit * daysSoFar);

    return Math.min(user.cigs_per_day, Math.max(0, countAllowed));
  }

  public async calculateSmokingSavingsToDate(date) {
    const user = await this.store.select(getCurrentUser)
      .pipe(take(1))
      .toPromise();

    const cigsPerDay = user.cigs_per_day;
    const savingsPerCig = user.cig_pack_cost / 20;
    const currentDate = moment(date)
      .clone();
    const startDate = moment(user.start_date)
      .clone();

    return this.store.select(getCigarettesByDay)
      .pipe(take(1))
      .toPromise()
      .then((savedCigarettes) => {
        const totalSavings = this.savedCigarettesToDate(
          startDate,
          currentDate,
          cigsPerDay,
          savedCigarettes
        ) * savingsPerCig;

        return totalSavings;
      });
  }

  private savedCigarettesToDate(
    startDate: moment.Moment,
    currentDate: moment.Moment,
    cigsByDay,
    cigarettesByDay: CountByDay[] = []
  ): number {
    const cigarettesByDayMap = {};
    cigarettesByDay.forEach((cigByDay) => {
      cigarettesByDayMap[cigByDay.date] = cigByDay.count;
    });

    let cigaretteSavings = 0;

    while (startDate < currentDate) {
      const startDay = startDate.format('YYYY-MM-DD');
      const smokedCigs = cigarettesByDayMap[startDay] || 0;
      cigaretteSavings += Math.max(0, (cigsByDay - smokedCigs));
      startDate.add(1, 'day');
    }

    return cigaretteSavings;
  }

}
