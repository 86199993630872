import { User, UserProgram } from 'src/app/store/normalized/schemas/user.schema';

export interface UpdateUserDataType {
  user: User;
  userProgram: UserProgram;
};

interface BaseAnalyticsInterface {
  // called when app starts
  initialize(): Promise<void>;

  // called when user logs-out
  resetService(): Promise<void>;

  registerUser(data: UpdateUserDataType): Promise<any>;

  // currently only UserAnalytics needs to implement `updateUser` method
  // updateUser(data: UpdateUserDataType): any;

  SERVICE_NAME?: string;
}

export interface MarketingAnalyticsInterface extends BaseAnalyticsInterface {
  trackSubscription(eventType: string, subscription: any, product: any): any;
}

export interface UserAnalyticsInterface extends BaseAnalyticsInterface {
  trackEvent(event: string, data: any): any;

  updateUser(data: UpdateUserDataType): any;
}

