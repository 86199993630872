// authentication selectors
import { createSelector } from '@ngrx/store';

import * as toolsReducer from '../reducers/tools.reducer';
import { getNowState } from '../..';

export const stressMeterActive = createSelector(
  getNowState,
  toolsReducer.isStressMeterActive
);

export const exerciseIdStressMeter = createSelector(
  getNowState,
  toolsReducer.exerciseId
);
