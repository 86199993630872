import { Component, HostBinding } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { onboardingTypes } from '../../constants/onboarding-types.const';
import { SavePlayedFile } from '../../store/persistent/media/media.actions';
import { MediaFile } from '../../store/normalized/schemas/mediaFile.schema';
import { SetOnboardingCompleted } from '../../store/persistent/onboarding/onboarding.actions';
import { State } from '../../store/state.reducer';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { ClarityConfig } from '../../config/clarity.config';
import { ActionDialogContent, ActionDialogSettings, ActionDialogButtonConfig } from 'src/app/components/action-dialog/action-dialog.interface';

@Component({
  selector: 'page-onboarding',
  styleUrls: ['onboarding.scss'],
  templateUrl: 'onboarding.html'
})
export class OnboardingPage {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  modal;
  onboardingSettings;
  video: MediaFile = this.params.get('video');

  public onboardingDialogPayload: {content: ActionDialogContent; settings: ActionDialogSettings};

  constructor(
    private store: Store<State>,
    private params: NavParams,
    private config: ClarityConfig
  ) {
    if (this.params.get('type') === 'custom') {
      this.onboardingSettings = this.params.get('onboardingSettings');
    } else {
      this.onboardingSettings = onboardingTypes[this.params.get('type')];
    }

    if (!this.onboardingSettings) {
      throw new Error(`[ERROR] OnboardingPage: couldn\'t find onboardingSettings for NavParams.type = ${this.params.get('type')}. `
        + 'Please implement it in onboarding-types constant file');
    }

    this._mapOnboardingDataToDialogPayload();
  }

  private _mapOnboardingDataToDialogPayload() {
    const { header, body, link, button, size, cancelButton: cancelButtonOption, textAlignment } = this.onboardingSettings;

    let actionButton: ActionDialogButtonConfig;
    let cancelButton: ActionDialogButtonConfig;

    if (button && link) {
      // legacy flow:
      // for some reason some onboarding messages has both "button" and "link" defined.
      // for those cases we set the "link" as the cancelButton with type "link"
      actionButton = {
        type: 'button',
        label: button,
        size: 'default'
      };
      cancelButton = {
        type: 'link',
        label: link,
        size: 'default'
      };
    } else {
      actionButton = {
        type: button ? 'button' : 'link',
        label: link || button,
        size: 'default'
      };

      if (cancelButtonOption) {
        // new flow
        // some onboarding messages can have two buttons (instead of 1 button and 1 link)
        cancelButton = {
          type: 'button',
          label: cancelButtonOption,
          size: 'default'
        };
      }
    }
    const mappedPayload: {content: ActionDialogContent; settings: ActionDialogSettings} = {
      content: {
        header,
        body,
        actionButton,
        cancelButton,
        ...(this.video ? {media: {type: 'video', source: this.video }} : {})
      },
      settings: {
        size: this.isDesktop && size !== 'full' ? 'auto' : size,
        textAlignment: textAlignment ?? (this.isDesktop ? 'left' : 'center')
      }
    };

    this.onboardingDialogPayload = mappedPayload;
  }

  onMediaPlayed() {
    this.store.dispatch(new SavePlayedFile(this.video.data_fingerprint));
  }

  onDialogMainAction() {
    if (!this.onboardingSettings.dontCompleteOnButtonClick) {
      this.completeOnboarding();
    }

    if(this.onboardingSettings?.actionOnButton) {
      this.store.dispatch(this.onboardingSettings.actionOnButton);
    }

    this.closeModal();
  }

  onDialogDismiss() {
    if (!this.onboardingSettings.dontCompleteOnCancelClick) {
      this.completeOnboarding();
    }

    if(this.onboardingSettings?.actionOnLink) {
      this.store.dispatch(this.onboardingSettings.actionOnLink);
    }

    this.closeModal();
  }

  private completeOnboarding() {
    this.store.dispatch(new SetOnboardingCompleted(this.params.get('type')));
  }

  private closeModal() {
    if(this.onboardingSettings?.callbackOnButton) {
      this.onboardingSettings.callbackOnButton();
    }

    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }

}
