import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Store } from '@ngrx/store';

import * as navigationActions from '../../store/session/actions/navigation.actions';
import { NavController } from '@ionic/angular';
import { State } from '../../store/state.reducer';

@Component({
  selector: 'cl-header-nav-buttons',
  styleUrls: ['header-nav-buttons.component.scss'],
  template: `
    <ion-toolbar class="header-nav-buttons">
      <cl-back-button color="{{color}}" (back)="onBack()" *ngIf="backButton"></cl-back-button>

      <ion-title *ngIf="title" [innerHTML]="title">
        <cl-label [text]="standaloneClient"></cl-label>

        <span *ngIf="adminUrl" class="admin-url">
          (<a target="_msadmin" href="{{adminUrl}}">{{'common.view' | translate}}</a>)
        </span>
      </ion-title>

      <cl-close-button color="{{color}}" (closeClick)="onClose()" icon="{{closeIcon}}" *ngIf="closeButton"></cl-close-button>
    </ion-toolbar>
  `
})

export class HeaderNavButtonsComponent {
  @Input() autoClose = false;
  @Input() backButton = true;
  @Input() closeButton = true;
  @Input() color: string;
  @Input() title: string;
  @Input() adminUrl: string;
  @Input() standaloneClient: string;
  @Input() closeIcon = 'close';

  @Output() back = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  constructor(
    private store: Store<State>,
    private navCtrl: NavController
  ) {
  }

  onClose() {
    if (this.autoClose) {
      this.store.dispatch(new navigationActions.CloseAllModals());
    }
    else {
      this.closeClick.emit();
    }
  }

  onBack() {
    this.back.emit();
  }

  goBack() {
    this.navCtrl.pop();
  }
}
