import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, AfterViewInit } from '@angular/core';
import { LiveLesson } from '../../../store/session/selectors/program.selectors';
import { PerformService } from '../services/perform.service';

@Component({
  selector: 'cl-lesson-perform',
  styleUrls: ['../play.scss', 'lesson-perform.component.scss'],
  template: `
    <ion-grid>
      <ion-row class="title-holder large-title-holder">
        <ion-col>
          <h1>{{wizardTitle | translate}}</h1>
        </ion-col>
      </ion-row>

      <ion-row class="steps-holder" player-holder>
        <ion-col class="ion-padding-horizontal padding-top">
          <cl-simple-progress-bar
            *ngIf="isAwarenessQuiz()"
            [plain]="true"
            [steps]="stepsCount"
            [current]="currentStep">
          </cl-simple-progress-bar>

          <cl-awareness-quiz
            *ngIf="isAwarenessQuiz()"
            [lesson]="lesson"
            [step]="currentStep"
            (init)="onInit()">
          </cl-awareness-quiz>

          <cl-week-review
            *ngIf="isWeekReview()"
            class="lesson"
            [lesson]="lesson"
            [step]="currentStep"
            (init)="onInit()">
          </cl-week-review>

          <cl-anxiety-quiz
            *ngIf="isAnxietyQuiz()"
            [lesson]="lesson"
            (next)="onNextLesson()"
            (init)="onInit()">
          </cl-anxiety-quiz>

          <cl-simple-assessment
            *ngIf="isSimpleAssessment()"
            class="lesson"
            [lesson]="lesson"
            (next)="onNextLesson()"
            (init)="onInit()"
            (changeTitle)="onChangeTitle($event)">
          </cl-simple-assessment>
        </ion-col>
      </ion-row>

      <ion-row class="action-buttons-holder bottom-button" *ngIf="!isAnxietyQuiz() && !isSimpleAssessment()">
        <ion-col>
          <cl-next-lesson-button
            *ngIf="currentStep === stepsCount"
            [lesson]="lesson"
            (next)="onNextLesson()"
            [enabled]="true">
          </cl-next-lesson-button>

          <ion-button
            *ngIf="currentStep !== stepsCount"
            color="action"
            class="next-step-btn"
            type="submit"
            [disabled]="false"
            (click)="nextStep()">
            {{ "common.next" | translate  }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  `
})
export class LessonPerformComponent implements OnChanges, AfterViewInit {
  @Input() lesson: LiveLesson;

  @Output() next = new EventEmitter();
  @Output() init = new EventEmitter();

  wizardTitle = '';
  stepsCount = 1;

  /**
   * Indexed from 1.
   */
  currentStep = 1;

  constructor(
    private performService: PerformService,
    private ref: ChangeDetectorRef
  ) {

  }

  ngAfterViewInit() {
    this.init.emit();
  }

  onInit() {
    this.init.emit();
  }

  ngOnChanges() {
    this.wizardTitle = this.lesson && this.lesson.record && this.lesson.record.exercise.title;

    if (this.isAwarenessQuiz()) {
      this.stepsCount = 9;
    }
    else if (this.isWeekReview()) {
      this.stepsCount = 2;
    }

    this.currentStep = 1;
    this.ref.detectChanges();
  }

  onNextLesson() {
    if (this.isAwarenessQuiz()) {
      this.performService.part1Done();
    }

    this.next.emit(this.lesson);
  }

  onChangeTitle(title: string) {
    this.wizardTitle = title;
  }

  nextStep() {
    if (this.currentStep < this.stepsCount) {
      this.currentStep++;
    }

    this.ref.detectChanges();
  }

  get isLastStep() {
    return this.currentStep === this.stepsCount;
  }

  isAwarenessQuiz() {
    return this.lesson && this.lesson.record.exercise.action === 'awareness_quiz';
  }

  isAnxietyQuiz() {
    return this.lesson && this.lesson.record.exercise.action === 'anxiety_quiz';
  }

  isWeekReview() {
    return this.lesson && this.lesson.record.exercise.action === 'week_review';
  }

  isSimpleAssessment() {
    return this.lesson && this.lesson.record.exercise.action === 'simple_assessment';
  }

}
