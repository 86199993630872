import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import {
  getTodayWeightActivity,
  getIsWeightActivityAddedToday,
  getIsWeightActivityAddedYesterday
} from 'src/app/store/normalized/selectors/weight-activity.selectors';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { format, subDays } from 'date-fns';
import { IntegrationSource } from 'src/app/store/session/models/integration.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-add-manual-weight',
  styleUrls: ['add-manual-weight.component.scss'],
  template: `<div class="manual" (click)="addWeightMannualy()">
    <div>
      <ion-icon name="add"></ion-icon>
    </div>
    <div class="title">
      {{ 'weight_tracking.add_manual' | translate}}
    </div>
  </div>`
})
export class AddManualWeightComponent {
  @Input() weightUnit: 'kg' | 'lb';
  @Output() addManualWeight = new EventEmitter<{ value: number; weightUnit: string; date: Date }>();

  constructor(
    private store: Store<State>,
    private translate: TranslateService,
    private alertController: AlertController) { }

  /**
   * By default, we allow the user to add today's & yesterday's weight
   * If today is already added, we don't allow new manual entry
   * If yesterday is added, we just allow today's weight
   */
  async addWeightMannualy() {
    const [yesterdayWeightActivityAdded, todayWeightActivityAdded, todayWeight] = await combineLatest([
      this.store.select(getIsWeightActivityAddedYesterday),
      this.store.select(getIsWeightActivityAddedToday),
      this.store.select(getTodayWeightActivity)
    ])
      .pipe(take(1))
      .toPromise();

    if(todayWeightActivityAdded) {
      if(todayWeight.source === 'manual') {
        return this.showUpdateWeightAlert();
      } else {
        // Weight from fitbit / healthkit / google fit can't be deleted or updated from the app
        return this.showCantUpdateWeightAlert(todayWeight.source);
      }
    }

    const minDate = format(yesterdayWeightActivityAdded ? new Date() : subDays(new Date(), 1),'yyyy-MM-dd');

    this.translate.get(
      ['weight_tracking.add_daily_weight', 'weight_tracking.weight_in' ,'common.submit', 'common.cancel', 'common.kg','common.lb']
    )
      .toPromise()
      .then(async translations => {
        const alert = await this.alertController.create({
          header: translations['weight_tracking.add_daily_weight'],
          inputs: [
            {
              name: 'value',
              type: 'number',
              placeholder: `${translations['weight_tracking.weight_in']} ${translations[this.weightUnit === 'lb' ? 'common.lb' : 'common.kg']}`
            },
            {
              name: 'date',
              type: 'date',
              min: minDate,
              max: format(new Date(), 'yyyy-MM-dd'),
              value: format(new Date(), 'yyyy-MM-dd')
            }],
          buttons: [
            {
              text: translations['common.cancel']
            },
            {
              text: translations['common.submit'],
              handler: data => {
                data.value && this.addManualWeight.emit({value: Number(data.value), weightUnit: this.weightUnit, date: new Date(data.date)});
              }
            }]
        });

        await alert.present();
      });
  }

  private showCantUpdateWeightAlert(source: IntegrationSource) {
    this.translate.get(
      ['common.ok','weight_tracking.entry_limitation_title', 'weight_tracking.entry_limitation_message','weight_tracking.added_by.' + source]
    )
      .toPromise()
      .then(async translations => {
        const alert = await this.alertController.create({
          header: translations['weight_tracking.entry_limitation_title'],
          message: translations['weight_tracking.entry_limitation_message'] + translations['weight_tracking.added_by.' + source],
          buttons: [
            {
              text: translations['common.ok']
            }]
        });

        await alert.present();
      });
  }

  private showUpdateWeightAlert() {
    this.translate.get(
      ['weight_tracking.update_weight_title', 'weight_tracking.update_weight_message', 'common.ok']
    )
      .toPromise()
      .then(async translations => {
        const alert = await this.alertController.create({
          header: translations['weight_tracking.update_weight_title'],
          message: translations['weight_tracking.update_weight_message'],
          buttons: [
            {
              text: translations['common.ok']
            }]
        });

        await alert.present();
      });
  }
}
