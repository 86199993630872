import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { TrackerService } from '../services/tracker.service';
import { getCurrentUserCigaretteSavings } from '../store/normalized/selectors/user.selectors';
import { SessionState } from '../store/session/session.reducers';
import { ClarityConfig } from '../config/clarity.config';
import { SmokingTypeService } from '../services/smoking-type.service';

@Component({
  selector: 'cl-cigarette-stats',
  styleUrls: ['cigarette-stats.component.scss'],
  // needed to apply styling to translated text -- CSS must also be atteched using Selector instead of host:
  encapsulation: ViewEncapsulation.None,
  template: `
  <div class="cig-stats-wrapper">
    <div class="stat-text"
         [innerHTML]="canSmoke | i18nPlural: cigsLeftMapping | translate :{canSmoke: canSmoke | number:'1.0'} | safeHtml">
    </div>

    <h5 class="stat-text padded-both-ways"
        *ngIf="smokingTypeService.isCigarette()"
        [innerHTML]="'cigarettes_graph.saving'
          | translate :{savedAlready: savings
            | number:'1.2', savingYear: cigaretteSavings$
            | async
            | number:'1.2'}
          | safeHtml">

    </h5>
  </div>
  `
})

export class CigaretteStatsComponent implements AfterViewInit {

  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig,
    private trackerService: TrackerService,
    public smokingTypeService: SmokingTypeService
  ) {}

  cigsLeftMapping: any = {};

  cigaretteSavings$ = this.store.select(getCurrentUserCigaretteSavings);

  public canSmoke: number;
  public savings: number;

  ngAfterViewInit() {
    const prefix = this.smokingTypeService.isVaping() ? 'vaping' : 'cigarettes';
    this.cigsLeftMapping = {
      '=0': `${prefix}_graph.suggest_to_smoke_only.none`,
      '=1': `${prefix}_graph.suggest_to_smoke_only.singular`,
      other: `${prefix}_graph.suggest_to_smoke_only.plural`
    };

    this.trackerService.calculateToDate(new Date())
      .then((resultsForToday) => {
        this.canSmoke = resultsForToday.canSmoke;
        this.savings = resultsForToday.savings;
      });
  }
}
