import { Component, EventEmitter, Input, OnChanges, AfterViewInit, Output } from '@angular/core';
import { LiveLesson } from '../../../../../store/session/selectors/program.selectors';
import { PerformService } from '../../../services/perform.service';
import { OnboardingService } from '../../../../../services/onboarding.service';
import { ClarityConfig } from '../../../../../config/clarity.config';

@Component({
  selector: 'cl-awareness-quiz',
  styleUrls: ['awareness-quiz.component.scss'],
  template: `
    <ion-row class="step lateral-padding">
      <ion-col>
        <h4>{{"perform.part_1_question" | translate}}</h4>
        <cl-self-assessment-slider
          [assessment]="'perform.part_1_step_title_' + step"
          [sliderValue]="sliderValue"
          (setValue)="setValue($event)">
        </cl-self-assessment-slider>
      </ion-col>
    </ion-row>
  `
})
export class AwarenessQuizComponent implements OnChanges, AfterViewInit {
  @Input() lesson: LiveLesson;
  @Input() step: number;

  @Output() init = new EventEmitter();

  sliderValue: number;

  constructor(
    private config: ClarityConfig,
    private onboardingService: OnboardingService,
    private performService: PerformService
  ) {
    if (this.config.isERN()) {
      this.onboardingService.checkShowingOnboarding({type: 'awarenessQuizERN'});
    }
    else if (this.config.isUA()) {
      this.onboardingService.checkShowingOnboarding({type: 'awarenessQuizUA'});
    }
    else if (this.config.isCTQ()) {
      this.onboardingService.checkShowingOnboarding({type: 'awarenessQuizCTQ'});
    }
  }

  setValue(value) {
    this.performService.choices['q' + this.step] = value;
    this.sliderValue = value;
  }

  ngAfterViewInit() {
    this.init.emit();
  }

  ngOnChanges() {
    this.sliderValue = 0;
  }

}
