import { Component, Input, Optional } from '@angular/core';

import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'cl-common-radio',
  styleUrls: ['common-radio.component.scss'],
  template: `
    <div [(ngModel)]="value" ngDefaultControl>
<ion-radio-group [value]="value" (ionChange)="writeValue($event.detail.value)">
      <ion-row *ngFor="let option of options">
        <ion-col>
          <ion-item no-lines>
            <ion-label>{{option.label | translate}}</ion-label>
            <ion-radio
              slot="start"
              [value]="option.value">
            </ion-radio>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-radio-group>
</div>
  `
})

export class CommonRadioComponent implements ControlValueAccessor {

  @Input() options: [any] = null;
  // Allow the input to be disabled, and when it is make it somewhat transparent.
  @Input() disabled = false;
  private model: any;

  constructor(@Optional() public ngControl: NgControl) {
    if (ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  // Allows Angular to update the model

  get value(): any {
    return this.model;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.model) {
      this.model = v;
      this.onChange(v);
    }
  }

  // Update the model and changes needed for the view here.
  writeValue(value: string): void {
    if (value !== this.model) {
      this.model = value;
      this.onChange(value);
    }
  }

  // Function to call when the rating changes.
  onChange = (input: any) => undefined;

  // Function to call when the input is touched.
  onTouched = () => undefined;

  // Allows Angular to register a function to call when the model changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: (input: any) => void): void {
    this.onChange = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
