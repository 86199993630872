import { createSelector } from '@ngrx/store';
import { getPostState } from '../../state.reducer';
import * as postReducer from '../reducers/post.reducer';
import { createSchemaSelectors } from 'ngrx-normalizr';
import { Comment, CommentFeed, commentFeedSchema, commentSchema } from '../../normalized/schemas/social.schema';
import { getResumedAt } from './sync.selectors';

const commentsSchemaSelector = createSchemaSelectors<Comment>(commentSchema),
  commentFeedSchemaSelector = createSchemaSelectors<CommentFeed>(commentFeedSchema);

export const getCommentFeedId = createSelector(
  getPostState,
  postReducer.commentFeedId
);

export const getCommentFeeds = commentFeedSchemaSelector.getEntities;

export const getCommentFeedById = createSelector(
  commentFeedSchemaSelector.getNormalizedEntities,
  getCommentFeedId,
  commentFeedSchemaSelector.entityProjector
);

export const getCommentsTotalCountFromFeed = createSelector(
  getCommentFeedById,
  (commentFeed: CommentFeed) => commentFeed ? commentFeed.total_count : null
);

export const getCommentsFromFeed = createSelector(
  getCommentFeedById,
  (commentFeed: CommentFeed) => {
    if (!commentFeed || !commentFeed.list) {
      return [];
    }

    return commentFeed.list.sort((a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
  }
);

export const getCurrentCommentFeed = createSelector(
  getCommentFeeds,
  (commentFeeds) => {

    if (!commentFeeds || commentFeeds.length === 0) {
      return ({} as CommentFeed);
    }

    return commentFeeds[0];
  }
);

export const getAllCommentsLoaded = createSelector(
  postReducer.allCommentsAreLoaded,
  getCurrentCommentFeed,
  (commentsLoaded, commentFeed): any => {
    if (!commentFeed || !commentFeed.list) return false;

    return !!commentsLoaded || commentFeed.total_count <= commentFeed.list.length;
  }
);

export const getPostLoading = createSelector(
  getPostState,
  postReducer.postIsLoading,
  getResumedAt,
  (state, loading, resumedAt) => {
    const currentTimestamp = new Date().getTime();
    if (resumedAt && (currentTimestamp - resumedAt) <= 500) {
      return false;
    }

    return loading;
  }
);

export const getCommentsLoading = createSelector(
  getPostState,
  postReducer.commentsAreLoading,
  getResumedAt,
  (state, loading, resumedAt) => {
    const currentTimestamp = new Date().getTime();
    if (resumedAt && (currentTimestamp - resumedAt) <= 500) {
      return false;
    }

    return loading;
  }
);

export const getCommentsLoaded = createSelector(
  getPostState,
  postReducer.commentsAreLoaded
);

export const getAllCommentsLoading = createSelector(
  getPostState,
  postReducer.allCommentsAreLoading
);

export const getCommentSending = createSelector(
  getPostState,
  postReducer.commentIsSending
);

export const getCommentSent = createSelector(
  getPostState,
  postReducer.commentIsSent
);

export const getCommentId = createSelector(
  getPostState,
  postReducer.commentId
);

export const getCommentById = createSelector(
  commentsSchemaSelector.getNormalizedEntities,
  getCommentId,
  commentsSchemaSelector.entityProjector
);

export const getCommentUpdate = createSelector(
  getPostState,
  postReducer.commentUpdate
);

export const getLikeCommentLoading = createSelector(
  getPostState,
  postReducer.likeCommentLoading
);

export const deletedCommentId = createSelector(
  getPostState,
  postReducer.deletedCommentId
);

export const failToggleLikeComment = createSelector(
  getPostState,
  postReducer.toggleLikeCommentFail
);

export const getOpenPostId = createSelector(
  getPostState,
  postReducer.openPostId
);

export const getNewLikeOnComment = createSelector(
  getPostState,
  postReducer.newLikeOnComment
);

export const getNewComment = createSelector(
  getPostState,
  postReducer.newComment
);
