import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';

import { CravingTool } from '../../store/normalized/schemas/craving-tool.schema';
import { OnboardingService } from '../../services/onboarding.service';
import { CravingToolStatus } from '../../services/wizards/craving-tool.service';
import { SaveCravingTool, ScheduleCravingTool } from '../../store/session/actions/tools.actions';
import { SessionState } from '../../store/session/session.reducers';
import * as navigationActions from '../../store/session/actions/navigation.actions';

@Component({
  selector: 'page-craving-tool',
  styleUrls: ['craving-tool.scss'],
  templateUrl: 'craving-tool.html'
})
export class CravingToolPage implements OnInit {
  modal;

  constructor(
    private store: Store<SessionState>,
    private onboardingService: OnboardingService,
    public navParams: NavParams,
    private ref: ChangeDetectorRef
  ) {}

  get initialCravingTool(): CravingTool {
    const datePipe = new DatePipe('en-US');

    return {
      date: datePipe.transform(new Date(), 'yyyy-MM-dd'),
      craving_type: 'type',
      satisfaction_before_eating: 0,
      satisfaction_after_eating: 0,
      eat_amount: null,
      recording_initial_at: new Date().toISOString(),
      completed: false,
      eat_mindfully: false
    };
  }

  currentCravingTool: CravingTool = { ...this.initialCravingTool };

  firstStepMinutes = 5;
  secondStepMinutes = 15;
  // simulation step
  simulation = true;
  afterSimulation = false;
  mindfulEating = false;
  afterEatingSatisfaction = false;
  afterEatingQuantity = false;
  afterMinutesCheckin = false;
  hide15Min = true;
  minutesElapsed: number;

  ngOnInit(): void {
    this.ref.detectChanges();
    this.onboardingService.checkShowingOnboarding({type: 'cravingTool'});
  }

  resetCravingTool() {
    this.simulation = true;
    this.afterSimulation = false;
    this.mindfulEating = false;
    this.afterEatingSatisfaction = false;
    this.afterEatingQuantity = false;
    this.afterMinutesCheckin = false;
    this.hide15Min = true;
  }

  onClose() {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }

  ionViewDidEnter() {
    const cravingToolStatus: CravingToolStatus = this.navParams.get('options') || { currentCravingTool: null };
    this.currentCravingTool = { ...this.initialCravingTool };

    const {
      currentCravingTool,
      checkin5min,
      checkin15min,
      hide15Min
    } = cravingToolStatus;

    if (!currentCravingTool) {
      return;
    }

    this.resetCravingTool();

    const secondStepStart = this.firstStepMinutes + this.secondStepMinutes;

    if (checkin5min) {
      this.toAfterMinutesStep(this.firstStepMinutes);
      this.currentCravingTool = {...currentCravingTool};
      this.hide15Min = hide15Min;

      return;
    }

    if (checkin15min) {
      this.toAfterMinutesStep(secondStepStart);
      this.currentCravingTool = {...currentCravingTool};
    }

    return;
  }

  toAfterSimulation() {
    this.simulation = false;
    this.afterSimulation = true;
  }

  // after simulation step

  toMindfulEatingExercise() {
    this.mindfulEating = true;
    this.afterSimulation = false;
  }

  // mindfulEating Exercise step

  toAfterEatingSatisfactionStep() {
    this.mindfulEating = false;
    this.afterEatingSatisfaction = true;
  }

  toAfterEatingQuantityStep() {
    this.afterEatingSatisfaction = false;
    this.afterEatingQuantity = true;
  }

  toAfterMinutesStep(minutes: number) {
    this.simulation = false;
    this.afterSimulation = false;
    this.mindfulEating = false;
    this.afterEatingSatisfaction = false;
    this.afterEatingQuantity = false;
    this.afterMinutesCheckin = true;
    this.minutesElapsed = minutes;
  }

  done() {
    this.store.dispatch(new SaveCravingTool(this.currentCravingTool, { silent: false }));
    this.ref.detectChanges();
  }

  savePastCravingTool(cravingTool) {
    this.store.dispatch(new SaveCravingTool(cravingTool, {
      silent: true
    }));
  }

  schedule5MinuteReminder() {
    this.store.dispatch(new ScheduleCravingTool(this.currentCravingTool, {
      inMinutes: this.firstStepMinutes
    }));
  }

  schedule15MinuteReminder() {
    this.store.dispatch(new ScheduleCravingTool(this.currentCravingTool, {
      inMinutes: this.secondStepMinutes
    }));
  }

}
