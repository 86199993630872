import {
  Component, EventEmitter, Input, Output
} from '@angular/core';

@Component({
  selector: 'cl-sharecare-login',
  styleUrls: ['sharecare-login.component.scss'],
  template: `
    <ion-button data-e2e="login-sharecare-button" type="button" (click)="doLogin()">
      <ion-img src="assets/imgs/sharecare-icon.png" slot="start"></ion-img>
      <ion-label>{{ label | translate }}</ion-label>
    </ion-button>
  `
})
export class SharecareLoginComponent {

  @Output() login = new EventEmitter();

  @Input() label = 'actions.login_via_sharecare';

  doLogin() {
    this.login.emit();
  }
}
