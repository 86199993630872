<cl-waves-background
  class="interstitial-waves-background interstitial-new-blue"
>

  <ion-content (click)="onClick()">
    <ion-header>
      <cl-header-nav-buttons
        class="page-header"
        color="blank"
        [backButton]="false">
      </cl-header-nav-buttons>
    </ion-header>

    <ion-grid class="page-wrapper">
      <div class="content">
        <ion-grid fixed>
          <ion-row class="title-holder lateral-padding ion-padding">
            <ion-col class="ion-no-padding">
              <h1 [innerHTML]="navParams.data.title | translate"></h1>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="navParams.data.subtitle" class="details-holder lateral-padding ion-padding-horizontal">
            <ion-col class="subtitle-holder">
              <h2 [innerHTML]="navParams.data.subtitle | translate"></h2>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="navParams.data.notes" class="details-holder lateral-padding ion-padding-horizontal">
            <ion-col class="notes-holder">
              <h3 [innerHTML]="navParams.data.notes | translate :{ startDate: notesDate }"></h3>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="navParams.data.subnotes" class="details-holder lateral-padding ion-padding-horizontal">
            <ion-col class="subnotes-holder">
              <p [innerHTML]="navParams.data.subnotes | translate"></p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <ion-row class="icon-row">
        <ion-col class="icon-holder ion-text-center">
          <img [src]="'assets/imgs/' + navParams.data.badge">
        </ion-col>
      </ion-row>

      <ion-row class="exit-info padding-bottom-iphone-x">
        <ion-col class="ion-text-center">
          <ion-button
            class="dark-blue-theme dark-button"
            (click)="buttonAction($event)"
          >
            {{ navParams.data.button | translate }}
            <ion-icon name="chevron-forward-outline"></ion-icon>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

  </ion-content>
</cl-waves-background>
