// persistent download reducer

import * as mediaActions from './media.actions';

export interface MediaState {
  downloaded: string[];
  played: string[];
  defaultMediaFileForExercise: {[key: number]: string };
  subtitlesLanguage: string;
  googleStorageDeleted: boolean;
}

export const initialPersistentMediaState: MediaState = {
  downloaded: [],
  played: [],
  defaultMediaFileForExercise: {},
  subtitlesLanguage: '',
  googleStorageDeleted: false
};

export function persistentMediaReducer(state = initialPersistentMediaState, action: mediaActions.MediaActions): MediaState {
  switch (action.type) {
    case mediaActions.SAVE_DOWNLOADED_FILE: {
      const mediaFileId = action.payload;

      if (state.downloaded.indexOf(mediaFileId) > -1) {
        return state;
      }

      const newDownloaded = [...state.downloaded, mediaFileId];

      return {
        ...state,
        downloaded: newDownloaded
      };
    }
    case mediaActions.REMOVE_ALL_FILES: {
      return {
        ...initialPersistentMediaState,
        googleStorageDeleted: state.googleStorageDeleted
      };
    }

    case mediaActions.REMOVE_ALL_GOOGLE_FILES_SUCCESS: {
      return {
        ...initialPersistentMediaState,
        googleStorageDeleted: true
      };
    }

    case mediaActions.SAVE_PLAYED_FILE: {
      const mediaFileId = action.payload;
      // eslint-disable-next-line no-shadow, @typescript-eslint/no-shadow
      const { played = [] } = state;
      if (played.indexOf(mediaFileId) > -1) {
        return state;
      }

      const newPlayed = [...played, mediaFileId];

      return {
        ...state,
        played: newPlayed
      };
    }

    case mediaActions.SET_DEFAULT_MEDIAFILE: {
      const { defaultMediaFileForExercise = {}} = state;
      const { exerciseId, mediaFileFingerprint } = action;

      return {
        ...state,
        defaultMediaFileForExercise: {
          ...defaultMediaFileForExercise,
          [exerciseId]: mediaFileFingerprint
        }
      };
    }

    case mediaActions.SET_SUBTITLES: {
      return {
        ...state,
        subtitlesLanguage: action.language
      };
    }

    default:
      return state;
  }
}

export const downloaded = (state: MediaState) => state.downloaded;
export const played = (state: MediaState) => state.played;
export const defaultMediaForExercises = (state: MediaState) => state.defaultMediaFileForExercise;
export const subtitlesLanguage = (state: MediaState) => state.subtitlesLanguage;
export const googleStorageDeleted = (state: MediaState) => state.googleStorageDeleted;
