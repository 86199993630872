// reminders reducer

import * as userRemindersActions from '../actions/user-reminders.actions';

export interface UserRemindersState {
  updating: boolean;
}

export const initialUserRemindersState: UserRemindersState = {
  updating: false
};

export function userRemindersReducer(
  state = initialUserRemindersState,
  action: userRemindersActions.UserRemindersActions
): UserRemindersState {
  switch (action.type) {
    case userRemindersActions.CREATE_USER_REMINDER:
    case userRemindersActions.UPDATE_USER_REMINDER: {
      return {
        ...state,
        updating: true
      };
    }
    default:
      return {
        ...state,
        updating: false
      };
  }
}

export const userRemindersUpdating = (state: UserRemindersState) => state.updating;
