import { ChangeDetectorRef, Component } from '@angular/core';
import { SettingsComponent } from './settings.component';
import { ClarityConfig } from '../../../config/clarity.config';
import { BrowserService } from '../../../services/browser.service';
import { ConnectivityService } from '../../../services/connectivity.service';

@Component({
  selector: 'cl-account-help',
  styleUrls: ['help.component.scss'],
  template: `
    <cl-header-nav-buttons
      *ngIf="isOnboardingOpen"
      class="page-header"
      color="primary"
      [closeButton]="false"
      [title]="'account_menu.help.welcome_screen' | translate"
      (back)="closeOnboarding()"
      [backButton]="true">
    </cl-header-nav-buttons>

    <h2 *ngIf="!isOnboardingOpen">{{ 'account_menu.help.title' | translate }}</h2>

    <cl-help-center *ngIf="!isOnboardingOpen">
      <div>
        <ion-row class="action-button help-center-btn">
          <ion-col class="ion-text-center" auto>
            <cl-action-button
              (action)="openHelpCenter()"
              class="open_help"
              [label]="'account_menu.help.open_help_center' | translate"
              [canClick]="true">
            </cl-action-button>
          </ion-col>
        </ion-row>

        <ion-row class="action-button onboarding-btn" *ngIf="!isOnboardingOpen">
          <ion-col class="ion-text-center" auto>
            <cl-action-button
              (action)="openOnboarding()"
              [label]="'account_menu.help.open_onboarding_pages' | translate"
              [whiteBg]="true"
              class="open_onboarding"
              [canClick]="true">
            </cl-action-button>
          </ion-col>
        </ion-row>

        <!--<ion-row class="action-button">-->
        <!--<ion-col class="ion-text-center" auto>-->
        <!--<button (click)="sendDebugReport()" class="button">-->
        <!--{{"account_menu.help.send_debug_report" | translate}}-->
        <!--</button>-->
        <!--</ion-col>-->
        <!--</ion-row>-->
      </div>
    </cl-help-center>

    <cl-onboardings-list *ngIf="isOnboardingOpen"></cl-onboardings-list>
  `
})

export class HelpComponent implements SettingsComponent {
  isOnboardingOpen = false;

  constructor(
    public config: ClarityConfig,
    private cdr: ChangeDetectorRef,
    private browser: BrowserService,
    private connectivity: ConnectivityService
  ) {
  }

  openOnboarding() {
    this.isOnboardingOpen = true;

    this.cdr.detectChanges();
  }

  closeOnboarding() {
    this.isOnboardingOpen = false;
    this.cdr.detectChanges();
  }

  openHelpCenter() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    this.browser.goTo(this.config.env.helpCenterUrl);
  }

  // sendDebugReport() {
  //   this.translate.get('account_menu.help')
  //     .pipe(take(1))
  //     .subscribe((vacabulary: any) => {
  //       const alert = this.alertCtrl.create({
  //         title: vacabulary.reset_password,
  //         message: vacabulary.please_use_this_feature,
  //         buttons: [{
  //           text: vacabulary.cancel,
  //           role: 'cancel'
  //         }, {
  //           text: vacabulary.send,
  //           handler: data => {
  // this.loadingService.showLoadingOverlay();
  // this.buildDebugReport();
  //           }
  //         }]
  //       });
  //       alert.present();
  //     });
  // }

  // buildDebugReport() {
  //   this.loadingService.hideLoadingOverlay();
  //   const data = {
  //     config: JSON.stringify(this.config.env),
  //     debugging: JSON.stringify({}),
  //     local_storage: JSON.stringify(this.secureStorageService.getAll()),
  //   };
  //
  //   Promise.all(
  //     this.fileService.getAllFilesList()
  //   )
  //   .then((result) => {
  //     data['db_storage'] = JSON.stringify(result[0]);
  //   });
  // }

}
