<cl-waves-background
  class="interstitial-waves-background interstitial-purple"
>

  <ion-content (click)="onClick()">

    <ion-header>
      <cl-header-nav-buttons
        class="page-header"
        color="blank"
        [backButton]="false">
      </cl-header-nav-buttons>
    </ion-header>

    <ion-grid class="page-wrapper">
      <div class="content">
        <ion-grid fixed>
          <ion-row class="title-holder lateral-padding ion-padding">
            <ion-col class="ion-no-padding">
              <h1>{{ 'interstitials.we_didnt_get_your_weight' | translate }}</h1>
            </ion-col>
          </ion-row>

          <ion-row class="icon-holder lateral-padding ion-padding-horizontal">
            <ion-col>
              <img src="assets/imgs/fitbit-scale-missing-measure.png">
            </ion-col>
          </ion-row>

          <ion-row class="details-holder lateral-padding ion-padding-horizontal">
            <ion-col>
              <p [innerHTML]="'interstitials.to_unlock_you_need_to_weight' | translate"></p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <ion-row class="exit-info padding-bottom-iphone-x">
        <ion-col class="ion-text-center">
          <ion-button class="dismiss-btn" (click)="goToMyWeight($event)" color="action">
            {{ 'interstitials.check_my_weight' | translate }}
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

  </ion-content>
</cl-waves-background>
