import { Component } from '@angular/core';
import { NavParams } from '@ionic/angular';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { SessionState } from '../../store/session/session.reducers';
import { interstitialTypes } from './interstitial-types.const';
import { ClarityConfig } from '../../config/clarity.config';

@Component({
  selector: 'page-interstitial-lesson-not-supported',
  styleUrls: ['interstitial-lesson-not-supported.scss'],
  templateUrl: 'interstitial-lesson-not-supported.html'
})
export class InterstitialLessonNotSupportedPage {
    public interstitialSettings = interstitialTypes.lessonNotSupported;

    constructor(
        private store: Store<SessionState>,
        public navParams: NavParams,
        public config: ClarityConfig
    ) {
    }

    onCloseModalClick() {
      this.store.dispatch(new navigationActions.CloseInterstitial());
    }
}
