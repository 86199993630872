import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';

// import * as fromNormalizedCore from '../../store';

import { map, mergeMap, toArray, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { v1 as uuidv1 } from 'uuid';
import { SessionState } from '../../store/session/session.reducers';

// import * as schemas from '../../store/normalized/schemas';
import * as connectionHandlerActions from '../../store/session/actions/connection-handler.actions';
import { HttpProvider } from './http.provider';

export interface HttpRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export type MethodSignature = (endpoint: string, params: object, options?: HttpRequestOptions) => Observable<object>;

export type AfterMethodSignature = (res: any, handlers: RequestHandlers) => any[];

export interface PostponedChange {
  method: string;
  afterMethod: string;
  endpoint: string;
  params?: object;
  requestHandlers: RequestHandlers;
  options?: HttpRequestOptions;
  uuid?: string;
  tries?: number;
  addedAt?: number;
  sequential?: boolean;
}

export interface RequestHandlers {
  endpoint: string;
  id: number | string;
  schema?: string;
}

@Injectable({providedIn: 'root'})
export class ConnectionHandlerProvider {

  private api = '';

  readonly methodMap = {
    post: this.innerPost.bind(this),
    patch: this.innerPatch.bind(this),
    put: this.innerPut.bind(this),
    delete: this.innerDelete.bind(this)
  };

  readonly afterMethodMap = {
    afterPost: this.afterPost.bind(this),
    afterPatch: this.afterPatch.bind(this),
    afterPut: this.afterPut.bind(this),
    afterDelete: this.afterDelete.bind(this)
  };

  // Extending the HttpClient through the Angular DI.
  public constructor(
    public http: HttpProvider,
    // private normalizedStore: Store<fromNormalizedCore.State>,
    private store: Store<SessionState>
  ) {
  }

  // GET request
  public get<T>(endPoint: string, options?: HttpRequestOptions): Observable<T> {
    return this.http.get<T>(this.api + endPoint, options);
  }

  private stashChanges(action: PostponedChange) {
    const actionWithId = {
      ...action,
      uuid: uuidv1(),
      tries: 1, // we will imidiatelly try this one out
      addedAt: new Date().getTime()
    };
    this.store.dispatch(new connectionHandlerActions.QueueAction(actionWithId));
  }

  /**
   * POST request
   */
  public post(endpoint: string, params: object, requestHandlers: RequestHandlers, sequential = false, options?: HttpRequestOptions) {
    this.stashChanges({
      method: 'post',
      afterMethod: 'afterPost',
      endpoint,
      params,
      options,
      requestHandlers,
      sequential
    });
  }

  private innerPost(endpoint: string, params: object, options?: HttpRequestOptions) {
    return this.http.post(this.api + endpoint, params, options);
  }

  private innerDelete(endpoint: string, params: object, options?: HttpRequestOptions) {
    return this.http.delete(this.api + endpoint, options);
  }

  private innerPut(endpoint: string, params: object, options?: HttpRequestOptions) {
    return this.http.put(this.api + endpoint, params, options);
  }

  private innerPatch(endpoint: string, params: object, options?: HttpRequestOptions) {
    return this.http.patch(this.api + endpoint, params, options);
  }

  /**
   * PATCH request
   */
  public patch(endpoint: string, params: object, requestHandlers: RequestHandlers, sequential = false, options?: HttpRequestOptions) {
    this.stashChanges({
      method: 'patch',
      afterMethod: 'afterPatch',
      endpoint,
      params,
      options,
      requestHandlers,
      sequential
    });
  }

  /**
   * PUT request
   */
  public put(endpoint: string, params: object, requestHandlers: RequestHandlers, sequential = false, options?: HttpRequestOptions) {
    this.stashChanges({
      method: 'put',
      afterMethod: 'afterPut',
      endpoint,
      params,
      options,
      requestHandlers,
      sequential
    });
  }

  /**
   * DELETE request
   */
  public delete(endpoint: string, requestHandlers: RequestHandlers, sequential = false, options?: HttpRequestOptions) {
    this.stashChanges({
      method: 'delete',
      afterMethod: 'afterDelete',
      endpoint,
      options,
      requestHandlers,
      sequential
    });
  }

  /**
   * Post Update method
   */

  // after post we need to remove the old placeholder object
  // and create the new one
  afterPost(res: any, action: PostponedChange, translator: any) {
    // const { requestHandlers } = action;
    // if (requestHandlers.schema) {
    //   const schema = schemas[requestHandlers.schema];

    //   this.normalizedStore.dispatch(new RemoveData({id: `${requestHandlers.id}`, schema }));
    //   this.normalizedStore.dispatch(new AddData({data: [res], schema }));
    // }

    return [
      of(res)
    ];
  }

  // after the rest of the actions we dont need to do anything as the action was already
  // carried out with the dummy response.
  afterPut(res, action: PostponedChange, translator: any) {
    return [
      of(true)
    ];
  }

  afterPatch(res, action: PostponedChange, translator: any) {
    // patch doesn't return anything so we turn back to the patch we send
    // if there is a need to translate the Id, we do it, otherwise we return
    // the id that goes on the patch.
    // if (action.requestHandlers.schema) {
    // const id = this.getRealId(action.requestHandlers, translator);
    // const data = id ? { ...action.params, id } : action.params;
    // const schema = schemas[action.requestHandlers.schema];
    // this.normalizedStore.dispatch(new AddData({ data: [data], schema }));
    // }

    return [
      of(true)
    ];
  }

  afterDelete(res, action: PostponedChange, translator: any) {
    return [
      of(true)
    ];
  }

  private getRealId(
    requestHandler: RequestHandlers,
    idTranslator: Map<string, Map<number | string, number | string>>
  ) {
    let maybeId: string | number;
    const endpointIdMap = idTranslator[requestHandler.endpoint];
    if (endpointIdMap) {
      maybeId = endpointIdMap[requestHandler.id];
    }

    return maybeId || requestHandler.id;
  }

  private getEndpoint(requestHandler: RequestHandlers, idTranslator: Map<string, Map<number | string, number | string>>) {
    const id = this.getRealId(requestHandler, idTranslator);
    const idString = (typeof id === 'string') ? `/${id}` : (id > 0 ? `/${id}` : '');

    return `${requestHandler.endpoint}${idString}`;
  }

  public applyAction(action: PostponedChange, idTranslator: Map<string, Map<number | string, number | string>>): Observable<any> {
    return this.methodMap[action.method](
      this.api + this.getEndpoint(action.requestHandlers, idTranslator),
      action.params,
      action.options
    )
      .pipe(
        mergeMap(
          res => this.afterMethodMap[action.afterMethod](res, action, idTranslator)
        )
      );
  }

  public applyNonSequentialActions(actions: PostponedChange[]) {
    const idTranslator = new Map<string, Map<number | string, number | string>>();

    return from(actions)
      .pipe(
        // eslint-disable-next-line import/no-deprecated
        mergeMap(
          (action: any) => this.applyAction(action, idTranslator)
            .pipe(
              map(() => ({
                action,
                failed: false
              })),
              catchError(() => of({
                action,
                failed: true
              }))
            ),
          undefined,
          3 // number of requests in parallel
        ),
        toArray()
      );
  }

  transformRequest(change: PostponedChange, oldId: number, newId: number) {
    return;
  }

}
