import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CravingTool } from '../../../store/normalized/schemas/craving-tool.schema';

@Component({
  selector: 'cl-after-simulation-step',
  styleUrls: ['../craving-tool.scss', 'after-simulation.component.scss'],
  template: `
    <ion-content>
      <div class="simulation lateral-padding">
        <h2 class="step-intro intro-question">{{'wizards.how_satisfied_content_do_you_feel' | translate}}</h2>
        <cl-intensity-selector
          [sliderValue]="sliderValue"
          highestIntensityLabel="wizards.a_lot_stronger"
          mediumIntensityLabel="wizards.same_as_before"
          lowestIntensityLabel="wizards.a_lot_weaker"
          (intensity)="onAfterSimulationIntensityChange($event)"
          lowestIntensityScale="-10"
          lowIntensityScale="-5"
          mediumIntensityScale="0"
          highIntensityScale="+5"
          highestIntensityScale="+10"
        >
        </cl-intensity-selector>
      </div>
    </ion-content>

    <ion-footer>
      <div class="controls-holder padding-bottom-iphone-x">
        <p class="button-header lateral-padding">
          {{'wizards.do_you_want_to_eat_now' | translate}}
        </p>

        <ion-row class="buttons-holder lateral-padding">
          <ion-col size="6">
            <cl-next-button
              label="common.no"
              [canClick]="true"
              (action)="triggerDone()">
            </cl-next-button>
          </ion-col>
          <ion-col size="6">
            <cl-next-button
              label="common.yes"
              [canClick]="true"
              (action)="triggerNext()">
            </cl-next-button>
          </ion-col>
        </ion-row>
      </div>
    </ion-footer>
  `
})
export class AfterSimulationStepComponent {
  @Input() currentCravingTool: CravingTool;
  @Output() done = new EventEmitter();
  @Output() next = new EventEmitter();

  onAfterSimulationIntensityChange($event) {
    this.currentCravingTool.satisfaction_before_eating = (($event - 50) * 2) / 100;
  }

  get sliderValue() {
    const value = this.currentCravingTool.satisfaction_before_eating || 0;

    return (value / 2 + 0.5) * 100;
  }

  triggerNext() {
    this.next.emit();
  }

  triggerDone() {
    this.done.emit();
  }
}
