import { createSelector } from '@ngrx/store';

import { createSchemaSelectors } from 'ngrx-normalizr';
import { Completed, Progress, UserBootstrap, completedSchema, progressSchema,
  userBootstrapSchema, NormalizedProgress, Lesson, lessonSchema } from '../schemas/user-bootstrap.schema';

import equal from 'fast-deep-equal';

const userBootstrapSchemaSelector = createSchemaSelectors<UserBootstrap>(userBootstrapSchema);
const progressSchemaSelector = createSchemaSelectors<Progress>(progressSchema);
export const completedSchemaSelector = createSchemaSelectors<Completed>(completedSchema);

let userBootstrapCopy: any = {};

export const getUserBootstrap = createSelector(
  userBootstrapSchemaSelector.getEntities,
  (bootstraps: UserBootstrap[]) => {
    const userBootstrap = bootstraps && bootstraps.length > 0 ? bootstraps[0] : {};

    if (!equal(userBootstrap, userBootstrapCopy)) {
      userBootstrapCopy = userBootstrap;
    }

    return userBootstrapCopy;
  }
);

// deprecated -- this should no longer be used - replaced with getRealNormalizedProgress below
// export const getNormalizedProgress = createSelector(
//   progressSchemaSelector.getNormalizedEntities,
//   (entities) => entities.completed
// );

let realNormalizedProgressCopy: NormalizedProgress = {};

// The progress must be normalized from the Progress schema, not completed!!!
// It's not safe to use the completed schema directly since that can contain orphaned data
export const getRealNormalizedProgress = createSelector(
  progressSchemaSelector.getEntities,
  (realProgress) => {
    const progress: NormalizedProgress = {};

    if (!realProgress) {
      return progress;
    }

    realProgress.forEach((val) => {
      if (val.completed && val.completed.length > 0) {
        val.completed.forEach((record) => {
          if (record && record.id) {
            progress[record.id] = record;
          }
        });
      }
    });

    if (! equal(progress, realNormalizedProgressCopy)) {
      realNormalizedProgressCopy = progress;

      console.log('Updating internal progress copy...');
    }

    return realNormalizedProgressCopy;
  }
);

let progressCopy: Progress[] = [];

export const getProgress = createSelector(
  progressSchemaSelector.getEntities,
  (progress) => {
    if (!equal(progress, progressCopy)) {
      progressCopy = progress;
    }

    return progressCopy;
  }
);

export const getCompletedModules = createSelector(
  getProgress,
  (progress) => {
    if (!progress) {
      return [];
    }

    const modulesProgress = progress.filter((section) => section.id === 'modules');

    if (!modulesProgress || !modulesProgress[0]) {
      return [];
    }

    console.log('$$$ SELECTOR getCompletedModules');

    return modulesProgress[0].completed.filter((completed) => completed && (/^\d*-\d*$/).test(completed.id))
      .map((completed) => parseInt(completed.id.replace(/^\d*-/, ''), 10));
  }
);

export const getAcceleratedTo = createSelector(
  getUserBootstrap,
  (userBootstrap: UserBootstrap) => {
    if (!userBootstrap || !userBootstrap.acceleratedTo) {
      return 0;
    }

    console.log('$$$ SELECTOR getAcceleratedTo');

    return userBootstrap.acceleratedTo;
  }
);

export const getLessonsCount = createSelector(
  createSchemaSelectors<Lesson>(lessonSchema).getEntities,
  (lessons) => lessons.length
);
