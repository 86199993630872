// account selectors

import { createSelector } from '@ngrx/store';

import * as accountReducer from '../reducers/account.reducer';
import { getAccountState } from '../../../../app/store';

export const isUserLoading = createSelector(
  getAccountState,
  accountReducer.loading
);

export const isUserLoaded = createSelector(
  getAccountState,
  accountReducer.loaded
);

export const isUserRegistering = createSelector(
  getAccountState,
  accountReducer.registering
);

export const isUserUpdating = createSelector(
  getAccountState,
  accountReducer.updating
);

export const getUpdateError = createSelector(
  getAccountState,
  accountReducer.updateError
);

export const getSignupError = createSelector(
  getAccountState,
  accountReducer.signupError
);

export const isAccountSetupStarted = createSelector(
  getAccountState,
  accountReducer.setupStarted
);

export const isAccountSetup = createSelector(
  getAccountState,
  accountReducer.setupComplete
);

export const getAccountSelectedSubMenu = createSelector(
  getAccountState,
  accountReducer.selectedSubMenu
);

export const getRootAccountMenu = createSelector(
  getAccountState,
  accountReducer.rootAccountMenu
);

export const getSelectedMenuTab = createSelector(
  getAccountState,
  accountReducer.selectedMenuTab
);

export const getMenuObjectBasedOnSelectedTab = createSelector(
  getAccountState,
  accountReducer.menuObjectBasedOnSelectedTab
);

export const getIsEdditingSettingsGoals = createSelector(
  getAccountState,
  accountReducer.isEdditingSettingsGoals
);
