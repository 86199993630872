import { Component, EventEmitter, Output } from '@angular/core';
import { Flavors, NrtService } from '../../../services/nrt.service';

@Component({
  selector: 'cl-nrt-gum-flavor',
  styleUrls: [
    'nrt-product-selection.component.scss',
    'nrt.component.scss'
  ],
  template: `
    <ion-grid class="component-content-grid">
      <ion-row>
        <ion-col>
          <ion-row class="question-holder lateral-padding">
            <ion-col>
              <p>{{'nrt.flavor_selection.subtitle' | translate}}</p>
            </ion-col>
          </ion-row>
          <ion-row class="lateral-padding">
            <ion-col>
              <form>
                <ion-radio-group>
                  <div class="select-button-item">
                    <cl-radio-list-item
                      [checked]="nrtService.gumFlavor === FlavorEnum.CINNAMON"
                      [label]="'nrt.flavor_selection.cinnamon' | translate"
                      (click)="onSelected(FlavorEnum.CINNAMON)">
                    </cl-radio-list-item>
                    <cl-radio-list-item
                      [checked]="nrtService.gumFlavor === FlavorEnum.FRUIT"
                      [label]="'nrt.flavor_selection.fruit' | translate"
                      (click)="onSelected(FlavorEnum.FRUIT)">
                    </cl-radio-list-item>
                    <cl-radio-list-item
                      [checked]="nrtService.gumFlavor === FlavorEnum.MINT"
                      [label]="'nrt.flavor_selection.spearmint' | translate"
                      (click)="onSelected(FlavorEnum.MINT)">
                    </cl-radio-list-item>
                    <cl-radio-list-item
                      [checked]="nrtService.gumFlavor === FlavorEnum.ICE"
                      [label]="'nrt.flavor_selection.ice' | translate"
                      (click)="onSelected(FlavorEnum.ICE)">
                    </cl-radio-list-item>
                  </div>
                </ion-radio-group>
              </form>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>

  `
})
export class NrtGumFlavorComponent {

  @Output() selected = new EventEmitter();

  public FlavorEnum = Flavors;

  constructor(
    public nrtService: NrtService
  ) {
  }

  onSelected(flavor: Flavors) {
    this.nrtService.gumFlavor = flavor;
    this.selected.emit();
  }
}
