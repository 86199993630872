import { createSelector } from '@ngrx/store';
import { getNrtState } from '../../state.reducer';
import * as nrtReducer from '../reducers/nrt.reducer';

export const getAccessLoading = createSelector(
  getNrtState,
  nrtReducer.accessLoading
);

const getAccessGranted = createSelector(
  getNrtState,
  nrtReducer.accessGranted
);

const getUngrantedMessage = createSelector(
  getNrtState,
  nrtReducer.ungrantedMessage
);

export const getAccess = createSelector(
  getAccessGranted,
  getUngrantedMessage,
  (success, message) => ({success, message})
);
