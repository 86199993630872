import { Injectable } from '@angular/core';
import { FileEntry } from '@ionic-native/file';
import { Md5Plugin } from './md5.plugin';

@Injectable({providedIn: 'root'})
export class Md5Service {

  constructor(public md5: Md5Plugin) {
  }

  sum(fileEntry: FileEntry) {
    return new Promise((resolve, reject) => {
      this.md5.file(
        fileEntry,
        (checksum) => resolve(checksum),
        (error) => reject(error)
      );
    });
  }

  // SLICE_SIZE = 1024;
  // constructor(public reader: File) {}

  // nextSlice(sliceNumber, blob) {
  //   const { size } = blob;
  //   const start = this.SLICE_SIZE * sliceNumber;
  //   if (start > size) {
  //     return;
  //   }
  //   const expectedEnd = ((this.SLICE_SIZE * (sliceNumber + 1)) - 1);
  //   const end = expectedEnd > size ? size : expectedEnd;

  //   return blob.slice(start, end);
  // }

  // sum(entry: FileEntry) {
  //   debugger;
  //   const fileName = path.basename(entry.toURL());
  //   const filePath = path.dirname(entry.toURL());
  //   debugger;
  //   return this.reader.readAsArrayBuffer(filePath, fileName)
  //     .then((result) => {
  //       debugger;
  //       const hasher = new Md5();
  //       hasher.appendByteArray(new Uint8Array(result));

  //       const resultSum = hasher.end();
  //       console.log(fileName, resultSum);
  //       debugger;
  //       return resultSum;
  //     }).catch(err => {
  //       debugger;
  //       console.log(err);
  //     });
  // }
}
