import { Injectable, NgZone } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AlertsService } from './alerts.service';
import { ClarityConfig } from '../config/clarity.config';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Store } from '@ngrx/store';
import { State } from '../store/state.reducer';
import { CravingToolService } from './wizards/craving-tool.service';
import * as navigationActions from '../store/session/actions/navigation.actions';
import * as programActions from '../store/session/actions/program.actions';
import { buildAuthorizationUrl } from '@mindsciences/authorization-util';
import { SharecareSSOAuthError } from '@mindsciences/login-providers';

// Documentation: https://mindsciences.atlassian.net/wiki/spaces/KB/pages/351109610/Deeplinks

@Injectable()
export class DeeplinksService {

  private initialized = false;

  constructor(
    private alerts: AlertsService,
    private config: ClarityConfig,
    private nav: NavController,
    private zone: NgZone,
    private store: Store<State>,
    private cravingToolService: CravingToolService
  ) {
  }

  public initialize() {
    if (!this.config.isDevice || this.config.runningOnIonicDevApp) {
      console.log('Not running on device, cannot initialize Deeplinks...');

      return Promise.resolve(true);
    }

    if (this.initialized) {
      console.log('Deeplinks already initialized!');

      return Promise.resolve(false);
    }

    console.log('Registering Deeplinks...');

    App.addListener('appUrlOpen', (data: any) => {
      /* eslint-disable-next-line complexity */
      this.zone.run(() => {
        let path;

        console.log('Parsing deeplink URL...', data);

        if (data.url.indexOf('http') === 0) {
          const url = new URL(data.url);
          // e.g https://staging-webapp.unwindinganxiety.com/preauth?code=xxx
          path = `${url.pathname}${url.search}`.substring(1);
        }
        else {
          // e.g. unwindinganxiety://preauth?code=xxx
          path = data.url.split('://')
            .pop()
            // there's no point in keep anything after the # since we cannot interpret that
            .split('#')
            .shift();
        }

        if (!path) {
          return console.error('Invalid deeplink URL passed', data);
        }

        const route = path.indexOf('?') !== -1 ? path.slice(0, path.indexOf('?')) : path;
        const params = data.url.indexOf('?') !== -1 ? path.slice(path.indexOf('?'), path.length) : '';
        const urlParams = new URLSearchParams(params);

        console.log('Deeplink matched!', data, route);

        this.handleRouteUrlParams({route, urlParams});
      });

    });

    return Promise.resolve(true);
  }

  /**
   * Open specific tools with links/deeplinks.
   */
  public handleRouteUrlParams({route, urlParams}): void {

    switch (route) {
      // TODO: implement this on the backend
      // something went wrong during the SSO process
      case 'sso_error':
        this._ssoError(urlParams);
        break;

      // TODO: Update backend to return a parsable error instead and add a special route for that
      // if the user is not qualified on SC end yet, we'll get an empty code from the backend
      case 'preauth':
        this._preAuth(urlParams);
        break;

      case 'init_sharecare':
        this._initSharecare(urlParams);
        break;

      case 'authorize':
        this._authorize(urlParams);
        break;

      case 'craving_tool':
        this._openCravingTool();
        break;

      case 'play_module':
        this._playModule(urlParams);
        break;

      case 'play_bonus_exercise':
        this._playBonusExercise(urlParams);
        break;

      case 'open_conversation':
        this._openConversation(urlParams);
        break;

      case 'checkin':
      case 'stresstest-ern':
      case 'stresstest-ua':
      case 'stressmeter':
      case 'wantometer-ern':
      case 'wantometer-ctq':
      case 'worrytool':
        this._openWizard(route);
        break;

      case 'fitbit_callback_weight':
      case 'fitbit_callback_devices':
        this._initFitbit(route, urlParams);
        break;

      default:
        console.log('Deeplink: No route found', route, JSON.stringify(urlParams));
    }
  }

  private async _openCravingTool() {
    this.cravingToolService.openCravingTool({fromNotification: false});
  }

  private async _playModule(urlParams) {
    const moduleNumber = Number(urlParams.get('id')) || -1;

    this.store.dispatch(new programActions.OpenModuleById(moduleNumber));
  }

  private async _playBonusExercise(urlParams) {
    const id = Number(urlParams.get('id')) || -1;

    this.store.dispatch(new programActions.OpenBonusExerciseById(id));
  }

  private async _openConversation(urlParams) {
    const id = urlParams.get('id') || '';

    this.store.dispatch(new navigationActions.OpenMyCoachConversation(id));
  }

  private async _openWizard(urlParam) {
    this.store.dispatch(new navigationActions.OpenWizard({type: urlParam}));
  }

  private async _ssoError(urlParams) {
    console.log('Deeplink: SSO error returned - showAlert(sharecareError)');

    if (this.config.isDevice && this.config.isIos) {
      Browser.close();
    }

    const code = urlParams.get('code');

    switch (code) {
      case 422:
      default:
        // this SC account is not eligible yet, send user to Achieve section
        return this.alerts.sharecareError(SharecareSSOAuthError.not_qualified_error);
    }
  }

  private _preAuth(urlParams) {
    console.log('Deeplink: Preauth token passed - navigateRoot(pretauth/:preauth_token)');

    // close the browser
    if (this.config.isDevice && this.config.isIos) {
      Browser.close();
    }

    const code = urlParams.get('code');
    // make sure we have a code, otherwise this could be a non-qualified SC account
    if (!code) {
      console.error('Invalid preauth url, no code found!', urlParams);
      this.alerts.sharecareError(SharecareSSOAuthError.not_qualified_error);

      return false;
    }

    this.zone.run(() => this.nav.navigateRoot(`preauth/${code}/${this.makeRand()}`));
  }

  private _authorize(params) {
    console.log('Deeplink: Authorized code passed - navigateRoot(code/:code)', params);

    // close the browser
    if (this.config.isDevice && this.config.isIos) {
      Browser.close();
    }

    this.zone.run(() => {
      const code = params.get('code');
      const offeringCode = params.get('offeringCode');
      const sponsor = params.get('sponsor');

      const authorizationUrl = buildAuthorizationUrl({ code, offeringCode, sponsor });

      return this.nav.navigateRoot(authorizationUrl);
    });
  }

  private _initSharecare(params) {
    console.log('Deeplink: Init Sharecare link detected - navigateRoot(provider/:provider)', params);

    this.zone.run(() => {
      if (params.get('offeringCode') && params.get('sponsor')) {
        return this.nav.navigateRoot(`provider_params/sharecare/${params.get('offeringCode')}/${params.get('sponsor')}/${this.makeRand()}`);
      }

      return this.nav.navigateRoot('provider/sharecare');
    });
  }

  private _initFitbit(route: string, params: URLSearchParams) {
    console.log('Deeplink: Init Fitbit link detected: ', route, params);

    // close the browser
    if (this.config.isDevice && this.config.isIos) {
      Browser.close();
    }

    this.zone.run(() => this.nav.navigateRoot(`/${route}${
      Boolean(params.toString())
        ? '?' + params.toString()
        : ''}`));
  }

  private makeRand() {
    return Math.floor((Math.random() * 9999) + 1);
  }
}
