import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LiveSubscription } from '../../../store/normalized/selectors/subscription.selectors';
import { ClarityConfig } from '../../../config/clarity.config';

@Component({
  selector: 'cl-active-subscription',
  styleUrls: ['active-subscription.component.scss'],
  template: `
    <cl-shadow-wrapper *ngIf="!isSharecareSso && !isSharecareStandalone">
      <ion-row class="benefits-box">
        <ion-col class="ion-text-center">
          <h4>{{'subscriptions.subscription_is_active' | translate}}</h4>

          <p>{{'subscriptions.manage_your_subscription_in_account' | translate}}</p>
        </ion-col>
      </ion-row>

      <cl-download-links *ngIf="this.config.isWebApp"></cl-download-links>

      <ion-row class="trial-btn-box">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{'common.continue' | translate}}"
            [canClick]="true"
            (action)="onContinue()">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </cl-shadow-wrapper>

    <cl-shadow-wrapper *ngIf="isSharecareStandalone">
      <ion-row class="benefits-box">
        <ion-col class="ion-text-center">
          <h4>{{'subscriptions.account_is_active' | translate}}</h4>

          <cl-download-links *ngIf="this.config.isWebApp"></cl-download-links>
        </ion-col>
      </ion-row>

      <ion-row class="trial-btn-box">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{'common.continue' | translate}}"
            [canClick]="true"
            (action)="onContinue()">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </cl-shadow-wrapper>

    <cl-shadow-wrapper *ngIf="isSharecareSso">
      <ion-row class="benefits-box sharecare-intro" *ngIf="isSharecareSso">
        <ion-col class="ion-text-center">
          <h4>{{'subscriptions.sharecare_sub_title_1' | translate : {program: this.config.program.name} }}</h4>

          <cl-download-links *ngIf="this.config.isWebApp" [sharecareDownloadReminder]="true"></cl-download-links>
        </ion-col>
      </ion-row>

      <ion-row class="trial-btn-box">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{'common.continue' | translate}}"
            [canClick]="true"
            (action)="onContinue()">
          </cl-action-button>
        </ion-col>
      </ion-row>

    </cl-shadow-wrapper>
  `
})

export class ActiveSubscriptionComponent {

  @Input() subscription: LiveSubscription;
  @Input() isSharecareSso: boolean;
  @Input() isSharecareStandalone: boolean;
  @Input() showFreeAccessNote: boolean;

  @Output() continue = new EventEmitter();

  constructor(
    public config: ClarityConfig
  ) {

  }

  onContinue() {
    this.continue.emit();
  }
}
