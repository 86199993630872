// program reducer

import * as programActions from '../actions/program.actions';

export interface PlayState {
  week: number;
  module: number;
  lesson: number;
  exercise: number;
}

export interface ProgramState {
  initialized: boolean;
  introModule: boolean;

  play: PlayState;

  lastCompletedLesson: number;
  lessonCompleting: boolean;

  lastCompletedModule: number;
  moduleCompleting: boolean;

  activeUserWeekNumber: number;
}

export const initialProgramState: ProgramState = {
  initialized: false,
  introModule: false,

  play: {
    week: null,
    module: null,
    lesson: null,
    exercise: null
  },

  lastCompletedLesson: null,
  lastCompletedModule: null,

  lessonCompleting: false,
  moduleCompleting: false,

  activeUserWeekNumber: null
};

export function programReducer(state = initialProgramState, action: programActions.ProgramActions): ProgramState {
  switch (action.type) {
    case programActions.LOAD_LIVE_PROGRAM: {
      const liveProgram = action.payload;

      return {
        ...state,
        introModule: liveProgram && liveProgram.weeks[0] && liveProgram.weeks[0].modules[0].number === 0 ? true : false
      };
    }

    case programActions.PLAY_LESSON: {
      const weekNumber = action.payload.weekNumber;
      const moduleNumber = action.payload.moduleNumber;
      const lessonPosition = action.payload.position;

      return {
        ...state,

        play: {
          week: weekNumber,
          module: moduleNumber,
          lesson: lessonPosition,
          exercise: null
        }
      };
    }

    case programActions.PLAY_MODULE: {
      const module = action.payload;

      return {
        ...state,

        play: {
          week: module.weekNumber,
          module: module.number,
          lesson: null,
          exercise: null
        }
      };
    }

    case programActions.PLAY_BONUS_EXERCISE:
    case programActions.PLAY_EXERCISE: {
      const exerciseId = action.payload ? action.payload.id : null;

      return {
        ...state,

        play: {
          week: null,
          module: null,
          lesson: null,
          exercise: exerciseId
        }
      };
    }

    case programActions.SET_ACTIVE_USER_WEEK_NUMBER: {
      return {
        ...state,
        activeUserWeekNumber: action.payload
      };
    }

    // case programActions.COMPLETE_MODULE: {
    //   console.log('programAction - COMPLETE_MODULE');
    //
    //   return {
    //     ...state
    //   };
    // }
    //
    // case programActions.COMPLETE_LESSON: {
    //   console.log('programAction - COMPLETE_LESSON');
    //
    //   return {
    //     ...state,
    //   };
    // }
  }

  return state;
}

export const lessonCompleting = (state: ProgramState) => state.lessonCompleting;
export const lastCompletedLesson = (state: ProgramState) => state.lastCompletedLesson;
export const moduleCompleting = (state: ProgramState) => state.moduleCompleting;
export const lastCompletedModule = (state: ProgramState) => state.lastCompletedModule;
export const play = (state: ProgramState) => state.play;
export const introModule = (state: ProgramState) => state.introModule;
export const activeUserWeekNumber = (state: ProgramState) => state.activeUserWeekNumber;
