// program actions

import { Action } from '@ngrx/store';
import { LiveBonusExercise, LiveLesson, LiveModule, LiveProgram } from '../selectors/program.selectors';
import { Exercise } from '../../normalized/schemas/exercise.schema';
import { ProgramBootstrap } from '../../normalized/schemas/program-bootstrap.schema';
import { ThemeWeek } from '../../normalized/schemas/theme-week.schema';

// bootstrap
export const LOAD_PROGRAM_BOOTSTRAP = '[program] Load Program Bootstrap';
export const LOAD_PROGRAM_BOOTSTRAP_SUCCESS = '[program] Load Program Bootstrap Success';
export const LOAD_PROGRAM_BOOTSTRAP_FAIL = '[program] Load Program Bootstrap Fail';

export class LoadProgramBootstrap implements Action {
  readonly type = LOAD_PROGRAM_BOOTSTRAP;
}

export class LoadProgramBootstrapSuccess implements Action {
  readonly type = LOAD_PROGRAM_BOOTSTRAP_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadProgramBootstrapFail implements Action {
  readonly type = LOAD_PROGRAM_BOOTSTRAP_FAIL;

  constructor(public payload: any) {
  }
}

// init bootstrap
export const INIT_PROGRAM_BOOTSTRAP = '[program] Init Program Bootstrap';
export const INIT_PROGRAM_BOOTSTRAP_SUCCESS = '[program] Init Program Bootstrap Success';

export class InitProgramBootstrap implements Action {
  readonly type = INIT_PROGRAM_BOOTSTRAP;

  constructor(public payload: ProgramBootstrap) { }
}

export class InitProgramBootstrapSuccess implements Action {
  readonly type = INIT_PROGRAM_BOOTSTRAP_SUCCESS;
}

export const INIT_LIVE_PROGRAM = '[program] Init Live Program';
export const LOAD_LIVE_PROGRAM = '[program] Load Live Program';

export class InitLiveProgram implements Action {
  readonly type = INIT_LIVE_PROGRAM;
}

export class LoadLiveProgram implements Action {
  readonly type = LOAD_LIVE_PROGRAM;

  constructor(public payload: LiveProgram) { }
}

// update progress
export const UPDATE_PLAY_STATE = '[program] Update Play State';
export const SET_ACTIVE_USER_WEEK_NUMBER = '[program] Set Active User Week Number';

export class SetActiveUserWeekNumber implements Action {
  readonly type = SET_ACTIVE_USER_WEEK_NUMBER;

  constructor(public payload: number) { }
}

// modules
export const PLAY_MODULE = '[program] Play Module';
export const OPEN_MODULE_BY_ID = '[program] Open Module';
export const PLAY_CURRENT_MODULE = '[program] Play Current Module';
export const PLAY_NEXT_MODULE = '[program] Play Next Module';
export const COMPLETE_MODULE_AND_NAVIGATE = '[program] Complete Module And Navigate'; // call COMPLETE_MODULE_STATE then perform navigation
export const COMPLETE_MODULE_STATE = '[program] Complete Module State';
export const TRACK_COMPLETE_MODULE = '[program] Track Complete Module';
export const SHOW_COMPLETION_INTERSTITIAL = '[program] Show Completion Interstitial';

export class PlayModule implements Action {
  readonly type = PLAY_MODULE;

  constructor(public payload: LiveModule) { }
}

export class OpenModuleById implements Action {
  readonly type = OPEN_MODULE_BY_ID;

  constructor(public payload: number) { }
}
export class PlayCurrentModule implements Action {
  readonly type = PLAY_CURRENT_MODULE;
}

export class PlayNextModule implements Action {
  readonly type = PLAY_NEXT_MODULE;
}

export class CompleteModuleAndNavigate implements Action {
  readonly type = COMPLETE_MODULE_AND_NAVIGATE;

  constructor(public payload: LiveModule, public skipInterstitial: boolean = false) { }
}
export class CompleteModuleState implements Action {
  readonly type = COMPLETE_MODULE_STATE;

  constructor(public payload: LiveModule) { }
}

export class TrackCompleteModule implements Action {
  readonly type = TRACK_COMPLETE_MODULE;

  constructor(public payload: LiveModule) { }
}

export class ShowCompletionInterstitial implements Action {
  readonly type = SHOW_COMPLETION_INTERSTITIAL;
}

// lessons
export const PLAY_LESSON = '[program] Play Lesson';
export const TRY_TO_PLAY_LESSON = '[program] Try To Play Lesson';
export const COMPLETE_LESSON = '[program] Complete Lesson';
export const COMPLETE_BONUS_EXERCISE = '[program] Complete Bonus Exercise';
export const TRACK_COMPLETE_LESSON = '[program] Track Complete Lesson';
export const PLAY_EXERCISE = '[program] Play Exercise';
export const TRACK_BONUS_EXERCISE = '[program] Track Bonus Exercise';
export const PLAY_BONUS_EXERCISE = '[program] Play Bonus Exercise';
export const OPEN_BONUS_EXERCISE_BY_ID = '[program] Open Bonus Exercise By ID';
export const PLAY_BREATHE_EXERCISE = '[program] Play Breathe Exercise';
export const RESUME_LESSON = '[program] Resume Lesson';
export const ENABLE_FAST_ONBOARDING = '[program] Enable Fast Onboarding';
export const SKIP_LESSON = '[program] Skip Lesson';
export const TRACK_ANXIETY_QUIZ = '[program] Track Anxiety Quiz';
export const HANDLE_UNSUPPORTED_LESSON_TYPE = '[program] Handle Unsupported Lesson Type';

export interface AfterCompleteLessonOptions {
  skipInterstitial: boolean;
  closeModalStrategy: 'closeAllModals' | 'closeLastModal';
}

export class PlayLesson implements Action {
  readonly type = PLAY_LESSON;

  constructor(public payload: LiveLesson) { }
}

export class TryToPlayLesson implements Action {
  readonly type = TRY_TO_PLAY_LESSON;

  constructor(public payload: LiveLesson) { }
}

export class PlayExercise implements Action {
  readonly type = PLAY_EXERCISE;

  constructor(public payload: Exercise) { }
}

export class PlayBonusExercise implements Action {
  readonly type = PLAY_BONUS_EXERCISE;

  constructor(
    public payload: LiveBonusExercise,
    public options: AfterCompleteLessonOptions = {
      skipInterstitial: false,
      closeModalStrategy: 'closeAllModals'
    }
  ) { }
}

export class OpenBonusExerciseById implements Action {
  readonly type = OPEN_BONUS_EXERCISE_BY_ID;

  constructor(public payload: number) { }
}

export class PlayBreatheExercise implements Action {
  readonly type = PLAY_BREATHE_EXERCISE;

  constructor(public payload?: LiveBonusExercise) { }
}

export class CompleteLesson implements Action {
  readonly type = COMPLETE_LESSON;

  constructor(
    public payload: LiveLesson,
    public options: { skipNavigationEffects: boolean } = {
      skipNavigationEffects: false // if true, will only change the state, and not trigger any subsequent navigation effect
    }
  ) { }
}

export class TrackCompleteLesson implements Action {
  readonly type = TRACK_COMPLETE_LESSON;

  constructor(public payload: LiveLesson) { }
}

export class CompleteBonusExercise implements Action {
  readonly type = COMPLETE_BONUS_EXERCISE;

  constructor(
    public payload: LiveBonusExercise,
    public options: AfterCompleteLessonOptions = {
      skipInterstitial: false,
      closeModalStrategy: 'closeAllModals'
    }
  ) { }
}

export class TrackBonusExercise implements Action {
  readonly type = TRACK_BONUS_EXERCISE;

  constructor(public payload: LiveBonusExercise) { }
}

export class ResumeLesson implements Action {
  readonly type = RESUME_LESSON;

  constructor(public payload?: {lesson?: LiveLesson; position?: number}) { }
}

export class EnableFastOnboarding implements Action {
  readonly type = ENABLE_FAST_ONBOARDING;

  constructor(public payload: LiveLesson) { }
}

export class SkipLesson implements Action {
  readonly type = SKIP_LESSON;

  constructor(public payload: LiveLesson) { }
}

export class TrackAnxietyQuiz implements Action {
  readonly type = TRACK_ANXIETY_QUIZ;

  constructor(public payload: any) {
  }
}

export class HandleUnsupportedLessonType implements Action {
  readonly type = HANDLE_UNSUPPORTED_LESSON_TYPE;

  constructor(public payload: LiveLesson) { }
}

// user weeks
export const START_THEME_WEEK = '[program] Start Theme Week';
export const REPLACE_THEME_WEEK = '[program] Replace Theme Week';
export const RESTART_PROGRAM = '[program] Restart Program';

export class StartThemeWeek implements Action {
  readonly type = START_THEME_WEEK;

  constructor(public payload: ThemeWeek) { }
}

export class ReplaceThemeWeek implements Action {
  readonly type = REPLACE_THEME_WEEK;

  constructor(public payload: ThemeWeek) { }
}

export class RestartProgram implements Action {
  readonly type = RESTART_PROGRAM;
}

// custom weeks
export const START_CUSTOM_WEEK = '[program] Start Custom Week';
export const REPLACE_CUSTOM_WEEK = '[program] Replace Custom Week';


export interface CustomWeek {
  program_days: number[];
}
export class StartCustomWeek implements Action {
  readonly type = START_CUSTOM_WEEK;

  constructor(public payload: CustomWeek) { }
}

export class ReplaceCustomWeek implements Action {
  readonly type = REPLACE_CUSTOM_WEEK;

  constructor(public payload: CustomWeek) { }
}

// community
export const OPEN_COMMUNITY = '[program] Open Community';
export const OPEN_COMMUNITY_JOURNAL = '[program] Open Community Journal';

export const OPEN_WEEKLY_CALLS = '[program] Open Weekly Calls';

export class OpenCommunity implements Action {
  readonly type = OPEN_COMMUNITY;

  constructor(public payload: string) {}
}

export class OpenCommunityJournal implements Action {
  readonly type = OPEN_COMMUNITY_JOURNAL;
}

export class OpenWeeklyCalls implements Action {
  readonly type = OPEN_WEEKLY_CALLS;
}

export type ProgramActions =
  | LoadProgramBootstrap
  | LoadProgramBootstrapSuccess
  | LoadProgramBootstrapFail
  | InitProgramBootstrap
  | InitProgramBootstrapSuccess
  | InitLiveProgram
  | LoadLiveProgram
  | SetActiveUserWeekNumber
  | PlayModule
  | CompleteModuleAndNavigate
  | CompleteModuleState
  | TrackCompleteModule
  | TrackAnxietyQuiz
  | PlayLesson
  | CompleteLesson
  | CompleteBonusExercise
  | ShowCompletionInterstitial
  | TrackCompleteLesson
  | PlayExercise
  | PlayBonusExercise
  | PlayBreatheExercise
  | TrackBonusExercise
  | ResumeLesson
  | EnableFastOnboarding
  | SkipLesson
  | StartThemeWeek
  | ReplaceThemeWeek
  | RestartProgram
  | StartCustomWeek
  | ReplaceCustomWeek
  | OpenCommunity
  | OpenCommunityJournal
  | OpenWeeklyCalls;
