// auth reducer

import { ERROR_INVALID_CREDENTIALS } from '../../../../app/config/error.handler';

import { HttpError } from '../models/http-error.model';
import * as exercisesActions from '../actions/exercises.actions';
import { BonusExercise } from '../../../../app/store/normalized/schemas/exercise.schema';

export interface ExercisesState {
  exercises: BonusExercise[];
  error: HttpError;
  id: number;
  tags: string[];
}

export const initialExercisesState: ExercisesState = {
  exercises: [],
  error: null,
  id: null,
  tags: null
};

export function exercisesReducer(state = initialExercisesState, action: exercisesActions.ExercisesActions): ExercisesState {
  switch (action.type) {
    case exercisesActions.EXERCISES: {
      return {
        ...state,
        error: null
      };
    }

    case exercisesActions.EXERCISE_BY_ID: {
      return {
        ...state,
        id: action.payload
      };
    }

    case exercisesActions.EXERCISES_BY_TAGS: {
      return {
        ...state,
        tags: action.payload
      };
    }

    case exercisesActions.EXERCISES_SUCCESS: {
      return {
        ...state,
        exercises: action.payload
      };
    }

    case exercisesActions.EXERCISES_FAIL: {
      const httpResponse = action.payload;
      let type = null;

      switch (httpResponse.status) {
        case 401:
          type = ERROR_INVALID_CREDENTIALS;
      }

      return {
        ...state,
        error: {
          type,
          httpStatus: httpResponse.status,
          httpError: httpResponse.error,
          httpMessage: httpResponse.message
        }
      };
    }
  }

  return state;
}

export const exercises = (state: ExercisesState) => state.exercises;
export const exercisesError = (state: ExercisesState) => state.error;
export const exerciseId = (state: ExercisesState) => state.id;
export const exerciseTags = (state: ExercisesState) => state.tags;
