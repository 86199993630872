import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../../../shared';
import { translateConfig } from '../../config/translate.config';
import { AccountPage } from './account';
import { AboutWebComponent } from './components/about-web.component';
import { AboutComponent } from './components/about.component';
import { ChangePasswordComponent } from './components/change-password.component';
import { CommonRadioComponent } from './components/common-radio.component';
import { DataSectionComponent } from './components/data-section.component';
import { GoalSettingsComponent } from './components/goal-settings.component';
import { HelpCenterComponent } from './components/help-center.component';
import { HelpWebComponent } from './components/help-web.component';
import { HelpComponent } from './components/help.component';
import { IntegrationsStatusModalComponent } from '../../components/integrations-status-modal.component';
import { IntegrationsComponent } from './components/integrations.component';
import { LanguageComponent } from './components/language.component';
import { OnboardingsListComponent } from './components/onboardings-list.component';
import { OnboardingsWebListComponent } from './components/onboardings-web-list.component';
import { OptionCardWebComponent } from './components/option-card-web.component';
import { OptionsTitleComponent } from './components/options-title.component';
import { ProfileWebComponent } from './components/profile-web.component';
import { QuittingPlanSettingsComponent } from './components/quitting-plan-settings/quitting-plan-settings.component';
import { RemindersEditComponent } from './components/reminders-edit.component';
import { RemindersTimingEditComponent } from './components/reminders-timing-edit.component';
import { RemindersComponent } from './components/reminders.component';
import { SettingsWebComponent } from './components/settings-web.component';
import { SubscriptionDetailsComponent } from './components/subscription-details.component';
import { SubscriptionSettingsComponent } from './components/subscription-settings.component';
import { WeightTrackBlockComponent } from './components/weight-track-block.component';
import { WeightTrackDPPComponent } from './components/weight-track-dpp.component';
import { WeightTrackERNComponent } from './components/weight-track-ern.component';
import { WeightTrackComponent } from './components/weight-track.component';
import { SettingsHostDirective } from './directives/settings-host.directive';
import { ActivityComponent } from './components/activity.component';
import { MinutesActivityCardComponent } from './components/minutes-activity-card.component';
import { IntegrationActivityCardComponent } from './components/integration-activity-card.component';
import { DailyMinutesActivityCardComponent } from './components/daily-minutes-activity-card.component';
import { DownloadComponent } from './components/download.component';
import { AddManualWeightComponent } from 'src/app/pages/account/components/add-manual-weight.component';
import { WeightActivityCardComponent } from 'src/app/pages/account/components/weight-activity-card.component';
import { WeightTrackHealthfitComponent } from 'src/app/pages/account/components/weight-track-healthfit.component';

@NgModule({
  declarations: [
    AccountPage,
    SettingsHostDirective,
    ProfileWebComponent,
    ChangePasswordComponent,
    LanguageComponent,
    RemindersComponent,
    ActivityComponent,
    IntegrationsComponent,
    IntegrationsStatusModalComponent,
    RemindersEditComponent,
    RemindersTimingEditComponent,
    GoalSettingsComponent,
    WeightTrackComponent,
    WeightTrackERNComponent,
    WeightTrackDPPComponent,
    WeightTrackHealthfitComponent,
    WeightTrackBlockComponent,
    AddManualWeightComponent,
    OptionCardWebComponent,
    OptionsTitleComponent,
    SubscriptionSettingsComponent,
    HelpComponent,
    HelpWebComponent,
    HelpCenterComponent,
    SettingsWebComponent,
    OnboardingsListComponent,
    OnboardingsWebListComponent,
    AboutComponent,
    AboutWebComponent,
    DataSectionComponent,
    SubscriptionDetailsComponent,
    CommonRadioComponent,
    QuittingPlanSettingsComponent,
    MinutesActivityCardComponent,
    WeightActivityCardComponent,
    DailyMinutesActivityCardComponent,
    IntegrationActivityCardComponent,
    DownloadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild([{ path: '', component: AccountPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ],
  providers: [
    DatePipe
  ]
})
export class AccountPageModule { }
