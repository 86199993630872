import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { LiveModule } from '../store/session/selectors/program.selectors';
import { OpenModal, ShowInterstitial } from '../store/session/actions/navigation.actions';
import { ClarityConfig } from '../config/clarity.config';
import { getCanExtendERNProgram } from '../store/normalized/selectors/user.selectors';
import { Observable } from 'rxjs';
import { SessionState } from '../store/session';
import { map, take } from 'rxjs/operators';

export enum AvailableInterstitialTags {
  INTERSTITIAL_TRACK_WEIGHT = 'interstitial-track-weight',
  INTERSTITIAL_EXTEND_ERN = 'interstitial-extend-ern'
}

const interstitialTagMap: Record<AvailableInterstitialTags, Action> = {
  'interstitial-track-weight': new ShowInterstitial({
    page: 'InterstitialPage',
    params: {
      type: 'congratulations',
      color: 'navy',
      badge: 'cup-nobg',
      title: 'interstitials.great_job',
      subTitle: 'interstitials.you_completed_weeks_module',
      notes: 'interstitials.weight_every_week',
      buttonText: 'common.skip'
    }
  }),
  'interstitial-extend-ern': new OpenModal('InterstitialErnWeightLossDiagnosticPage', {
    backdropDismiss: false,
    forceBackdrop: false
  })
};

function moduleHasTag(module: LiveModule, tag: AvailableInterstitialTags) {
  return module.record?.tags?.find((t) => t.name === tag);
}

// @todo maybe join this service with the lock-interstitial.service

/**
 * The ShowCompletionInterstitial effect at program.effects grew too complex.
 * So in order to simplify it, this service intends to extract some of the logic out of it.
 */
@Injectable({providedIn: 'root'})
export class InterstitialParamsHandlerService {
  constructor(
    public config: ClarityConfig,
    private store: Store<SessionState>
  ) {}

  getParamsForTag(tag: AvailableInterstitialTags) {
    return interstitialTagMap[tag];
  }

  createParamsByProgramDayTag(module: LiveModule): Action {
    const availableTags = Object.keys(interstitialTagMap);

    const interstitialTag = module.record?.tags?.find((tag) => availableTags.includes(tag?.name));

    return interstitialTag ? interstitialTagMap[interstitialTag.name] : undefined;
  }

  shouldShowExtendERNInterstitial(module: LiveModule): Observable<boolean> {
    return this.store.select(getCanExtendERNProgram).pipe(
      take(1),
      map((canExtendERNProgram) => Boolean(
        this.config.isERN() && canExtendERNProgram
        && (module.isLast || moduleHasTag(module, AvailableInterstitialTags.INTERSTITIAL_EXTEND_ERN))
      ))
    );
  }
}
