import { Injectable } from '@angular/core';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddData } from 'ngrx-normalizr';

import * as userRemindersActions from '../actions/user-reminders.actions';
import * as fromNormalizedCore from '../../../../app/store';
import { ToastService } from '../../../../app/services/toast.service';
import { LoadingService } from '../../../../app/services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { UserRemindersProvider } from '../../../../app/providers/user-reminders.provider';
import { isUpdatingUserReminders } from '../selectors/user-reminders.selectors';
import * as notificationsActions from '../actions/notifications.actions';
import { AnalyticsService } from '../../../../app/services/analytics/analytics.service';
import { UserReminder, userReminderSchema } from '../../normalized/schemas/reminder.schema';
import { SessionState } from '../session.reducers';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';

@Injectable({providedIn: 'root'})
export class UserRemindersEffects {


  updateUserReminders$ = createEffect(() => this.actions$.pipe(ofType(userRemindersActions.UPDATE_USER_REMINDER),
    tap(() => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUpdatingUserReminders),
        this.translate.get('reminders.saving_reminders')
      );
    }),
    map((action: userRemindersActions.UpdateUserReminder) => action.payload),
    switchMap(reminder => this.remindersProvider.updateUserReminder(reminder)
      .pipe(
        map(() => {
          this.normalizedStore.dispatch(new AddData<UserReminder>({
            data: [reminder],
            schema: userReminderSchema
          }));

          this.analyticsService.trackEvent(AnalyticsEvents.UpdatedReminder, { reminder });

          return new userRemindersActions.UpdateUserReminderSuccess();
        }),
        catchError(() => of(new userRemindersActions.UpdateUserReminderFail()))
      ))
  ));


  updateUserReminderSuccess$ = createEffect(() => this.actions$.pipe(ofType(userRemindersActions.UPDATE_USER_REMINDER_SUCCESS),
    tap(() => {
      this.toastService.confirm(this.translate.get('reminders.update_success'));
      this.store.dispatch(new notificationsActions.ResetNotifications());
    })
  ), { dispatch: false });


  updateUserReminderFail$ = createEffect(() => this.actions$.pipe(ofType(userRemindersActions.UPDATE_USER_REMINDER_FAIL),
    tap(() => {
      this.toastService.error(this.translate.get('errors.common.network_error'));
    })
  ), { dispatch: false });


  createUserReminders$ = createEffect(() => this.actions$.pipe(ofType(userRemindersActions.CREATE_USER_REMINDER),
    tap(() => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUpdatingUserReminders),
        this.translate.get('reminders.saving_reminders')
      );
    }),
    map((action: userRemindersActions.UpdateUserReminder) => action.payload),
    switchMap(reminder => this.remindersProvider.createUserReminder(reminder)
      .pipe(
        map((remind: UserReminder) => {
          this.normalizedStore.dispatch(new AddData<UserReminder>({
            data: [remind],
            schema: userReminderSchema
          }));

          this.analyticsService.trackEvent(AnalyticsEvents.SetupReminder, { remind });

          return new userRemindersActions.CreateUserReminderSuccess();
        }),
        catchError(() => of(new userRemindersActions.CreateUserReminderFail()))
      ))
  ));


  createUserReminderSuccess$ = createEffect(() => this.actions$.pipe(ofType(userRemindersActions.CREATE_USER_REMINDER_SUCCESS),
    tap(() => {
      this.store.dispatch(new notificationsActions.ResetNotifications());
    })
  ), {dispatch: false});


  createUserReminderFail$ = createEffect(() => this.actions$.pipe(ofType(userRemindersActions.CREATE_USER_REMINDER_FAIL),
    tap(() => {
      this.toastService.error(this.translate.get('errors.common.network_error'));
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private remindersProvider: UserRemindersProvider,
    private normalizedStore: Store<fromNormalizedCore.State>,
    private loadingService: LoadingService,
    private store: Store<SessionState>,
    private toastService: ToastService,
    private translate: TranslateService,
    private analyticsService: AnalyticsService
  ) {
  }
}
