import {
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as userGoalsActions from '../../../store/session/actions/user-goals.actions';
import { SelectedListItem } from '../../../store/normalized/selectors/goals.selectors';
import { LiveLesson } from '../../../store/session/selectors/program.selectors';
import { ConnectivityService } from '../../../services/connectivity.service';
import * as deepEqual from 'fast-deep-equal'; // TODO: migrate - check if it works
import { UserGoal } from '../../../store/normalized/schemas/user.schema';
import { State } from '../../../store/state.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-goals-edit',
  styleUrls: ['goal-edit.component.scss'],
  template: `
    <div class="goal-edit-wrapper">
      <ion-item *ngFor="let defaultGoal of selectedDefaultGoals" no-lines>
        <ion-checkbox mode="ios" class="goal-checkbox" color="checkbox"
                      [checked]="defaultGoal.checked"
                      (click)="updateSelection(defaultGoal.id)">
        </ion-checkbox>
        <ion-label class="goal-name">{{defaultGoal.name}}</ion-label>
      </ion-item>

      <ion-item *ngFor="let customGoal of customGoals; index as customIdx" no-lines class="custom-goal-container"
                mode="ios">
        <ion-input
          [value]="customGoal.name"
          class="custom-goal-disabled"
          [readonly]="customGoal.id"
          mode="ios"
          type="text"
          placeholder="{{'goals_menu.type_your_goal' | translate}}"
          (input)="updateCustomGoal($event, customIdx)">
        </ion-input>
        <ion-label></ion-label>
        <ion-button (click)="deleteCustomGoal(customIdx)" type="button" fill="clear" item-end>
          <ion-icon
            class="custom-goal-remove"
            name="remove-circle">
          </ion-icon>
        </ion-button>
      </ion-item>

      <div class="add-custom-container" (click)="addCustom()">
        <ion-icon class="add-custom" name="add-circle"></ion-icon>
        <span class="add-custom-label">
           {{'goals_menu.add_custom' | translate}}
         </span>
      </div>

      <ion-row class="action-button">
        <ion-col class="ion-text-center" auto>
          <cl-action-button (action)="doSubmit()"
                            [canClick]="canSubmit"
                            label="{{buttonLabelText | translate}}">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </div>`
})
export class GoalEditComponent implements OnInit, OnChanges {
  @Input() initialCustomGoals: UserGoal[];
  @Input() initialDefaultGoals: SelectedListItem[];
  @Input() goalsLesson: LiveLesson;
  @Output() action = new EventEmitter();

  private initialSelection: SelectedListItem[];

  public customGoals: UserGoal[];
  public selectedDefaultGoals: SelectedListItem[];

  constructor(
    private store: Store<State>,
    private connectivityService: ConnectivityService
  ) {
  }

  get currentDefaultsSet() {
    return new Set(
      this.selectedDefaultGoals
        .filter(dg => dg.checked)
        .map(dg => dg.id)
    );
  }

  get canSubmit() {
    if (this.goalsLesson) {
      return true;
    }

    return this.dataChanged;
  }

  doSubmit() {
    if (!this.dataChanged) {
      this.action.emit();

      return;
    }

    if (this.connectivityService.preventAccessWhenOffline()) {
      return;
    }

    this.updateGoals();
  }

  get dataChanged() {
    return this.defaultsChanged || this.customsChanged;
  }

  get buttonLabelText() {
    if (!this.goalsLesson) {
      return 'goals_menu.set_goals';
    }
    if (this.dataChanged) {
      return 'goals_menu.set_my_goals_and_next_lesson';
    }

    return 'goals_menu.next_lesson';
  }

  get initialCustomsSet() {
    return new Set(this.initialCustomGoals.map(cg => cg.name));
  }

  get currentCustomsSet() {
    return new Set(this.nonEmptyCurrentCustomGoals.map(cg => cg.name));
  }

  get defaultsChanged() {
    const newDefaultsSet = this.currentDefaultsSet;
    const sameSize = this.initialSelection.length === newDefaultsSet.size;
    if (!sameSize) {
      return true;
    }
    const sameElements = this.initialSelection
      .every(selectedDefault => newDefaultsSet.has(selectedDefault.id));
    if (!sameElements) {
      return true;
    }

    return false;
  }

  get customsChanged() {
    const newCustomsSet = this.initialCustomsSet;
    const currentGoalsSet = this.currentCustomsSet;
    const sameSize = currentGoalsSet.size === newCustomsSet.size;
    if (!sameSize) {
      return true;
    }
    const sameElements = this.nonEmptyCurrentCustomGoals
      .every(initialCustomGoal => newCustomsSet.has(initialCustomGoal.name));
    if (!sameElements) {
      return true;
    }

    return false;
  }

  get nonEmptyCurrentCustomGoals() {
    return this.customGoals.filter(goal => goal.name.trim().length > 0);
  }

  updateCustomGoal(event, customGoalIdx) {
    const {value} = event.target;
    this.customGoals[customGoalIdx].name = value;
  }

  addCustom() {
    this.customGoals.push({id: undefined, name: ''});
  }

  deleteCustomGoal(customGoalIdx) {
    this.customGoals.splice(customGoalIdx, 1);
  }

  updateCustomGoals() {
    const currentSet = this.currentCustomsSet;
    const initialSet = this.initialCustomsSet;
    const toRemove = this.initialCustomGoals.filter(cc => !currentSet.has(cc.name));
    const toAdd = this.customGoals.filter(
      icg => !initialSet.has(icg.name)
    );
    const uniqueMap = {};
    toAdd.forEach(customGoal => {
      // skip empty goals
      if (!customGoal.name || !customGoal.name.trim()) {
        return false;
      }

      const goal = uniqueMap[customGoal.name];

      if (goal) {
        if (customGoal.id) {
          uniqueMap[customGoal.name] = customGoal;
        }

        return;
      }
      uniqueMap[customGoal.name] = customGoal;
    });

    const add = Object.keys(uniqueMap)
      .map(key => uniqueMap[key]);

    const update = {
      add,
      remove: toRemove
    };

    return update;
  }

  updateSelection(id) {
    this.selectedDefaultGoals = this.selectedDefaultGoals.map(goal => {
      if (goal.id !== id) {
        return goal;
      }

      return {
        ...goal,
        checked: !goal.checked
      };
    });
  }

  ngOnInit() {
    this.customGoals = this.initialCustomGoals.map(cg => ({
      id: cg.id,
      name: cg.name
    }));
    this.selectedDefaultGoals = this.initialDefaultGoals.map(defaultGoal => Object.assign({}, defaultGoal));
    this.initialSelection = this.selectedDefaultGoals.filter(goal => goal.checked);
  }

  hasChanges(change: SimpleChange) {
    if (!change) {
      return false;
    }

    const { previousValue, currentValue } = change;

    const response = !deepEqual(previousValue, currentValue);

    return response;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hasChanges(changes['initialCustomGoals'])) {
      this.customGoals = this.initialCustomGoals.map(cg => ({
        id: cg.id,
        name: cg.name
      }));
    }

    if (this.hasChanges(changes['initialDefaultGoals'])) {
      this.selectedDefaultGoals = this.initialDefaultGoals.map(defaultGoal => Object.assign({}, defaultGoal));
      this.initialSelection = this.selectedDefaultGoals.filter(goal => goal.checked);
    }
  }

  updateDefaults() {
    const currSelection = this.selectedDefaultGoals
      .filter(goal => goal.checked)
      .map(goal => goal.id);

    return currSelection;
  }

  updateGoals() {
    let add = [], remove = [];
    let defaults;

    if (this.customsChanged) {
      ({add = [], remove = []} = this.updateCustomGoals());
    }

    if (this.defaultsChanged) {
      const newDefaults = this.updateDefaults();
      defaults = newDefaults;
    }

    const patch = {
      add,
      remove,
      defaults,
      lesson: this.goalsLesson
    };

    this.store.dispatch(new userGoalsActions.UpdateUserGoals(patch));

    this.action.emit();
  }

}
