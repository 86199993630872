import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { AwarenessQuiz } from '../store/normalized/schemas/awareness-quiz.schema';
import { OfflineHttpProvider } from './http/offline-http.provider';

@Injectable({providedIn: 'root'})
export class AwarenessQuizzesProvider {

  private awarenessQuizzesEndpoint = '/awareness_quizzes';

  constructor(
    private offlineHttp: OfflineHttpProvider,
    protected http: HttpProvider
  ) {

  }

  loadAwarenessQuizzes() {
    return this.http.get<AwarenessQuiz[]>(this.awarenessQuizzesEndpoint);
  }

  public trackAwarenessQuiz(awarenessQuiz) {
    return this.offlineHttp.patch(this.awarenessQuizzesEndpoint + '/' + awarenessQuiz.date, awarenessQuiz);
  }
}
