import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-radio-list-item',
  styleUrls: ['radio-list-item.component.scss'],
  template: `
    <ion-button [class.checked]="checked">
      <div class="check-item" [class.checked]="checked">
        <i></i>
      </div>
      <span>{{label}}</span>
    </ion-button>
  `
})

export class RadioListItemComponent {

  @Input() label: string;
  @Input() checked = false;

}
