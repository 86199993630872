import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-dpp-setup-confirm-exit-modal',
  styleUrls: ['confirm-exit-modal.scss'],
  template: `
    <div class="content">
      <p [innerHTML]="'account_setup.confirm_want_skip_step' | translate"></p>

      <div class="action">
        <ion-button class="dark-blue-theme light-button" (click)="onCancel()">
          {{ 'common.no' | translate }}
        </ion-button>
        <ion-button class="dark-blue-theme light-button" (click)="onConfirm()">
          {{ 'common.yes' | translate }}
        </ion-button>
      </div>
    </div>
  `
})

export class DppSetupConfirmExitModalComponent {
  modal: HTMLIonModalElement;

  onCancel() {
    this.modal.dismiss('cancel');
  }

  onConfirm() {
    this.modal.dismiss('confirm');
  }
}
