import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeInOut = trigger(
  'fadeInOut', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('200ms ease-in', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('200ms ease-out', style({ opacity: 0 }))
    ])
  ]
);

export const scaleUpDown = trigger('state', [
  state('void, hidden', style({ transform: 'scale(0)' })),
  state('visible', style({ transform: 'scale(1)' })),
  transition('* => visible', animate('150ms cubic-bezier(0.0, 0.0, 0.2, 1)')),
  transition('* => hidden', animate('75ms cubic-bezier(0.4, 0.0, 1, 1)'))
]);

export const slideInOut =  trigger('state', [
  state('void', style({transform: 'translateX(-100%)'})),
  state('visible', style({transform: 'translateX(0)'})),
  state('hidden', style({transform: 'translateX(100%)'})),
  transition('* => visible', animate('150ms cubic-bezier(0.0, 0.0, 0.2, 1)')),
  transition('* => hidden', animate('75ms cubic-bezier(0.4, 0.0, 1, 1)'))
]);
