function safelyDecodeURI(str: string) {
	try {
		return decodeURIComponent(str);
	} catch(error) {
		return str;
	}
}

export function fullyDecodeURL(url: string) {
	let decoded = url;
	let previous = decoded;

	do {
		previous = decoded;
		decoded = safelyDecodeURI(previous);
	} while(previous !== decoded);

	return decoded;
}

export function safelyEncodeURL(url: string) {
  if (!url) {
    return url;
  }

	const decodedUrl = fullyDecodeURL(url);

	return encodeURIComponent(decodedUrl);
}

export function buildAuthorizationUrl({ code, offeringCode, sponsor }: { code: string, offeringCode: string, sponsor: string }) {
  const sanitizedCode = sanitizeAuthorizeToken(code);

  if (!sanitizedCode) {
    console.error('buildAuthorizationUrl error', 'empty sanitizedCode', sanitizedCode);
  }

  const sanitizedOfferingCode = offeringCode ? sanitizeOfferingCode(offeringCode) : false;
  const sanitizedSponsor = sponsor ? safelyEncodeURL(sponsor) : false;

  if (sanitizedOfferingCode && sanitizedSponsor) {
    return `authorize_params/${sanitizedCode}/${sanitizedOfferingCode}/${sanitizedSponsor}`;
  } else {
    return `authorize/${sanitizedCode}`;
  }
}

export const sanitizeAuthorizeToken = (token: string) => {
  const match = token.match(/^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/);

  if (!match) {
    return false;
  }

  return match[0];
};

export const sanitizePreauthToken = (token: string) => {
  const match = token.match(/^[A-Za-z0-9-]*$/);

  if (!match) {
    return false;
  }

  return match[0];
};

export const sanitizeOfferingCode = (offeringCode: string) => {
  // accepted chars letters, _ and -
  const match = offeringCode.match(/^[a-zA-Z_-]*$/);

  if (!match) {
    return false;
  }

  return match[0];
};
