import { Action, MetaReducer } from '@ngrx/store';

import { logoutMetaReducer } from './meta-reducers/logout.meta-reducer';
import { offlineReducerFactory } from './meta-reducers/offline.meta-reducer';
import { NgZone } from '@angular/core';
import { FullState } from './state.reducer';
import { developMetaReducer } from './meta-reducers/develop.meta-reducer';

declare const config: any; // clarity.constants.js
const isProd = config.environment.environment === 'prod';

// export const metaReducers: MetaReducer<any, any>[] = [logoutMetaReducer, offlineMetaReducer];

export function getMetaReducers(zone: NgZone): MetaReducer<FullState, Action>[] {
  return [
    ...(!isProd ? [developMetaReducer] : []),
    logoutMetaReducer,
    offlineReducerFactory(zone)
  ];
}
