import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NewThemeWeekPage } from './new-theme-week';
import { SharedModule } from '../../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    NewThemeWeekPage
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: NewThemeWeekPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ]
})
export class NewThemeWeekPageModule {}
