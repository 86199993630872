import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * This service substitutes the old ionic Events API
 * It's used mainly to control signout & clear observables, and network
 * !! Avoid using this service. !! Prefer managing state with redux. We'll likely remove this service in a near future
 */
@Injectable({providedIn: 'root'})
export class EventsService {

  private channels = new Map<string, Subject<any>>();

  constructor() { }

  private getChannel(name) {
    let channel = this.channels.get(name);

    if (!channel) {
      channel = new Subject();
      this.channels.set(name, channel);
    }

    return channel;
  }

  publish(event: string, details?: any) {
    const channel = this.getChannel(event);

    channel.next(details);
  }

  subscribe(event: string, callback: (details: any) => any) {
    const channel = this.getChannel(event);

    channel.subscribe((details) => callback(details));
  }
}
