import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import { CravingTool } from '../../../store/normalized/schemas/craving-tool.schema';
import { ClarityConfig } from '../../../config/clarity.config';

@Component({
  selector: 'cl-after-eating-exercise-quantity-step',
  styleUrls: ['../craving-tool.scss', 'after-eating-exercise-quantity.component.scss'],
  template: `
    <ion-content>
      <div class="simulation lateral-padding">
        <h2 class="step-intro intro-question">
          {{'wizards.how_much_did_you_eat' | translate}}
        </h2>
        <cl-intensity-selector
          [sliderValue]="eatAmountQuantity"
          lowestIntensityLabel='wizards.none'
          lowIntensityLabel='wizards.too_little'
          mediumIntensityLabel='wizards.right_amount'
          highIntensityLabel='wizards.too_much'
          highestIntensityLabel='wizards.way_too_much'
          [showNumbers]="false"
          (intensity)="onAfterQuantityChange($event)">
        </cl-intensity-selector>
      </div>
    </ion-content>

    <ion-footer>
      <div class="controls-holder padding-bottom-iphone-x">

        <ng-container *ngIf="!config.isWebApp; else webActionButtonsTemplate">
          <h2 class="button-header lateral-padding">
            {{ 'wizards.craving_tool_check_in_X_minutes' | translate:{'minutes': inMinutes} }}
          </h2>
          <ion-row class="buttons-holder lateral-padding">
            <ion-col size="6">
              <cl-prev-button
                [ngClass]="{'dark-bg': darkBackground}"
                label="common.no"
                [canClick]="true"
                (action)="triggerDone()">
              </cl-prev-button>
            </ion-col>
            <ion-col size="6">
              <cl-next-button
                [ngClass]="{'dark-bg': darkBackground}"
                label="common.yes"
                [canClick]="true"
                (action)="triggerNext()">
              </cl-next-button>
            </ion-col>
          </ion-row>
        </ng-container>

        <ng-template #webActionButtonsTemplate>
          <ion-row class="buttons-holder lateral-padding">
            <ion-col class="center" size="6">
              <cl-next-button
                [ngClass]="{'dark-bg': darkBackground}"
                label="common.done"
                [canClick]="true"
                (action)="triggerDone()">
              </cl-next-button>
            </ion-col>
          </ion-row>
        </ng-template>
      </div>
    </ion-footer>
  `
})
export class AfterEatingExerciseQuantityComponent implements OnChanges {
  @Input() currentCravingTool: CravingTool;
  @Input() inMinutes: number;
  @Input() darkBackground = false;
  @Output() next = new EventEmitter();
  @Output() done = new EventEmitter();

  constructor(public config: ClarityConfig) { }

  get eatAmountQuantity() {
    return this.currentCravingTool.eat_amount;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentCravingTool'] && !changes['currentCravingTool'].previousValue) {
      this.currentCravingTool.eat_amount = 3;
    }
  }

  onAfterQuantityChange($event) {
    this.currentCravingTool.eat_amount = $event;
  }

  triggerNext() {
    this.next.emit();
  }

  triggerDone() {
    this.done.emit();
  }
}
