<ion-row class="step step1 lateral-padding" *ngIf="currentStep === 1">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{'wizards.how_anxious_are_you_feeling' | translate}}</h2>

    <!--<h4 class="step-instructions">{{'wizards.please_adjust_slider' | translate}}</h4>-->

    <cl-anxious-feeling
      (valueChanged)="setAnxiousFeelingChoice($event)"
      [sliderValue]="anxiousFeeling">
    </cl-anxious-feeling>
  </ion-col>
</ion-row>

<ion-row class="step step2 lateral-padding" *ngIf="currentStep === 2">
  <cl-pick-trigger
    [triggers]="triggers"
    [selected]="triggerChoice"
    (selectItem)="onTriggerSelected($event)">
  </cl-pick-trigger>
</ion-row>

<ion-row class="step step3" *ngIf="currentStep === 3">
  <ion-col class="ion-text-center" *ngIf="!showExerciseStep">

    <div class="rain-exercise lateral-padding ion-text-left" *ngIf="module12Completed">
      <h2 class="step-intro intro-question">{{'wizards.ready_for_third_gear' | translate}}</h2>

      <exercises-group
        [exercises]="rainExercise$ | async"
        (playExercise)="playExercise($event)"
        [isWizard]="true">
      </exercises-group>
    </div>

    <div class="exercises-list lateral-padding ion-text-left">
      <h2 class="step-intro intro-question" *ngIf="module12Completed">{{'wizards.feeling_nervous' | translate}}</h2>
      <h2 class="step-intro intro-question" *ngIf="!module12Completed">{{'wizards.youll_learn_on_module_12' | translate}}</h2>

      <div class="exercises-container">
        <exercises-group
          [exercises]="otherExercises$ | async"
          [noLateralPadding]="true"
          [isWizard]="true"
          (playExercise)="playExercise($event)">
        </exercises-group>

        <cl-stress-test-button (click)="startStressTest()"></cl-stress-test-button>

        <cl-im-feeling-lucky-button (click)="imFeelingLucky()"></cl-im-feeling-lucky-button>
      </div>
    </div>
  </ion-col>

  <ion-col class="lateral-padding ion-text-center" *ngIf="showExerciseStep">
    <cl-play-exercise
      *ngIf="!checkinExercise"
      [exercise]="exerciseToPlay"
      [isWizard]="true"
      [autoplay]="autoPlay"
      [playerController]="playerController">
    </cl-play-exercise>

    <h2 class="step-intro intro-question" *ngIf="checkinExercise">
      {{  'wizards.lets_do_a_mindfulness_exercise' | translate }}
    </h2>

    <cl-checkin-exercise
      *ngIf="checkinExercise"
      [graphic]="exerciseToPlay.graphic"
      [title]="exerciseToPlay.title"
      [instructions]="exerciseToPlay.instructions">
    </cl-checkin-exercise>
  </ion-col>
</ion-row>
