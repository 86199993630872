// favorites actions

import { Action } from '@ngrx/store';
import { UserFavoriteCreate, UserFavorite } from '../../normalized/schemas/user.schema';

export const CREATE_USER_FAVORITE = '[user favs] Create User Favorite';
export const CREATE_USER_FAVORITE_SUCCESS = '[user favs] Create User Favorite Success';
export const CREATE_USER_FAVORITE_FAIL = '[user favs] Create Favorite Fail';

export const DELETE_USER_FAVORITE = '[user favs] Delete User Favorite';
export const DELETE_USER_FAVORITE_SUCCESS = '[user favs] Delete User Favorite Success';
export const DELETE_USER_FAVORITE_FAIL = '[user favs] Create Favs Fail';

export const LOAD_USER_FAVORITES = '[user favs] Load User Favorites';
export const LOAD_USER_FAVORITES_SUCCESS = '[user favs] Load User Favorites Success';
export const LOAD_USER_FAVORITES_FAIL = '[user favs] Load User Favorites Fail';

export class CreateUserFav implements Action {
  readonly type = CREATE_USER_FAVORITE;

  constructor(public payload: UserFavoriteCreate) {}
}

export class CreateUserFavSuccess implements Action {
  readonly type = CREATE_USER_FAVORITE_SUCCESS;

  constructor(public payload: UserFavorite) {}
}

export class CreateUserFavFail implements Action {
  readonly type = CREATE_USER_FAVORITE_FAIL;

  constructor(public payload: any) {}
}

export class LoadUserFavs implements Action {
  readonly type = LOAD_USER_FAVORITES;
  constructor() {}
}

export class LoadUserFavsSuccess implements Action {
  readonly type = LOAD_USER_FAVORITES_SUCCESS;
}

export class LoadUserFavsFail implements Action {
  readonly type = LOAD_USER_FAVORITES_FAIL;

  constructor(public payload: any) {}
}

export class DeleteUserFavs implements Action {
  readonly type = DELETE_USER_FAVORITE;

  constructor(public payload: number) {}
}

export class DeleteUserFavsSuccess implements Action {
  readonly type = DELETE_USER_FAVORITE_SUCCESS;

  constructor(public payload: number) {}
}

export class DeleteUserFavsFail implements Action {
  readonly type = DELETE_USER_FAVORITE_FAIL;

  constructor(public payload: any) {}
}

export type UserFavsActions =
  | CreateUserFav
  | CreateUserFavSuccess
  | CreateUserFavFail
  | LoadUserFavs
  | LoadUserFavsSuccess
  | LoadUserFavsFail
  | DeleteUserFavs
  | DeleteUserFavsSuccess
  | DeleteUserFavsFail;
