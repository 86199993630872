// persistent timestamps reducer

import * as timestampsActions from './timestamps.actions';

export type AllowedTimestamps =
  'getMobileAppPopupLastSeenAt';

export type TimestampsState = Record<AllowedTimestamps, Date>;

export const initialPersistentTimestampsState: TimestampsState = {
  getMobileAppPopupLastSeenAt: null
};

export function persistentTimestampsReducer(state = initialPersistentTimestampsState, action: timestampsActions.TimestampsActions): TimestampsState {

  switch (action.type) {
    case timestampsActions.SET_TIMESTAMP: {
      const name = action.name;
      const date = action.date;

      return {
        ...state,
        [name]: date
      };
    }

    case timestampsActions.UNSET_TIMESTAMP: {
      const name = action.name;

      return {
        ...state,
        [name]: null
      };
    }
  }

  return state;
}

export const getMobileAppPopupLastSeenAt = (state: TimestampsState) => state.getMobileAppPopupLastSeenAt;
