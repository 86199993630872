import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../store/state.reducer';
import { filter, map, switchMap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { isAuthenticated } from '../store/sensitive/selectors/auth.selectors';
import { isAccountSetup } from '../store/session/selectors/account.selectors';
import { ClarityConfig } from '../config/clarity.config';

@Injectable({providedIn: 'root'})
export class AccountCompletedGuard implements CanActivate {

  constructor(
    private nav: NavController,
    private store: Store<State>,
    private config: ClarityConfig
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('||||| AccountCompletedGuard');

    // hydration is important! not just for obvious reasons, but also to avoid showing the welcome screen once in a while ;)
    return this.store.select('hydrated')
      .pipe(
        filter((hydrated) => hydrated === true),
        switchMap(() => combineLatest([
          this.store.select(isAuthenticated),
          this.store.select(isAccountSetup)
        ])
          .pipe(map(([isLoggedIn, isAccountSetupComplete]) => {
            // Allow action if user is not logged in
            let response = true;

            if(isLoggedIn && !isAccountSetupComplete) {
              response = false;
              this.nav.navigateRoot(this.config.programDPPorWL() ? 'account-setup/dpp' : 'account-setup');
            }

            return response;
          })))
      );
  }
}
