import { Component, EventEmitter, Output, Injector } from '@angular/core';
import { WizardComponent } from '../wizard.component';
import { Observable , Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { take, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as navigationActions from '../../../../store/session/actions/navigation.actions';
import * as toolsActions from '../../../../store/session/actions/tools.actions';
import { StressMetersService } from '../../../../services/wizards/stress-meters.service';
import {
  getExerciseById, getExercisesByExerciseTag
} from '../../../../store/normalized/selectors/exercises.selectors';
import * as exercisesActions from '../../../../store/session/actions/exercises.actions';
import {
  getCurrentModule
} from '../../../../store/session/selectors/program.selectors';
import { Exercise } from '../../../../store/normalized/schemas/exercise.schema';
import { getAcceleratedTo } from '../../../../store/normalized/selectors/user-bootstrap.selectors';
import { SessionState } from '../../../../store/session/session.reducers';
import { BrowserService } from '../../../../services/browser.service';
import { ClarityConfig } from '../../../../config/clarity.config';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';

const tag2title = {
  mindful: 'wizards.mindful_header_ern',
  stress: 'wizards.stress_header_ern',
  rain: 'wizards.rain_header_ern'
};

@Component({
  selector: 'cl-want-o-meter-ern-wizard',
  styleUrls: ['../../wizard.scss', 'want-o-meter-ern-wizard.component.scss'],
  templateUrl: 'want-o-meter-ern-wizard.component.html'
})
export class WantOMeterERNWizardComponent extends WizardComponent {

  public cravingIntensity = 50;
  public feelingNow = 50;
  public triggers = [];
  public triggerChoice = null;
  public exerciseToPlay = null;
  public isStressExercise = false;
  public otherExercises$: Observable<Exercise[]>;
  public recommendedExercise$ = this.store.select(getExerciseById);
  public header: string;
  public autoPlay = true;
  public playerController = new Subject<string>();

  @Output() nextStep = new EventEmitter();

  protected wizardConfig = {
    title: 'wizards.want_o_meter',
    totalSteps: 4,
    initialStep: 1
  };

  constructor(
    protected store: Store<SessionState>,
    protected browser: BrowserService,
    protected actions$: Actions,
    protected injector: Injector,
    public config: ClarityConfig,
    protected analyticsService: AnalyticsService,
    protected translate: TranslateService
  ) {
    super(store, browser, injector, config, analyticsService, translate);
  }

  initStep1() {
    this.cravingIntensity = this.service.getCravingIntensity();
    this.onEnableNext();
  }

  isStepValid1() {
    return this.service.isCravingIntensityValid();
  }

  initStep2() {
    this.loadTriggerChoice();
  }

  initStep3() {
    this.onDisableNext();
  }

  initStep4() {
    this.onEnableNext();
  }

  isStepValid2() {
    return this.service.isTriggerValid();
  }

  onIntensityChange(value) {
    this.service.setCravingIntensity(value);
  }

  onIntensityAfterFeelingChange(value) {
    this.service.setCravingIntensityAfterFeeling(value);
  }

  onTriggerSelected(trigger) {
    const triggerItem = this.triggers.find((item) => item.id === trigger);
    this.getRecommendedExercises(triggerItem.exercise_id);

    this.recommendedExercise$
      .pipe(take(1))
      .subscribe((exercise) => {
        if (exercise && exercise.tags.length) {
          exercise.tags[0].name === 'stress' ? this.isStressExercise = true : this.isStressExercise = false;
          this.header = tag2title[exercise.tags[0].name];
        }
      });

    this.service.setTrigger(trigger);
    this.loadTriggerChoice();
  }

  onDone() {
    this.service.save();
    this.playerController.next('pause');
    this.wizardComplete.emit();
  }

  getRecommendedExercises(id) {
    this.store.dispatch(new exercisesActions.GetBonusExerciseById(id));
    this.store.select(getExerciseById);
    this.otherExercises$ = this.store.select(getExercisesByExerciseTag);
  }

  playExercise(exercise: Exercise) {
    this.autoPlay = true;

    if (exercise.tags.find((tag) => tag.name === 'rain')) {
      this.store.select(getCurrentModule)
        .pipe(withLatestFrom(this.store.select(getAcceleratedTo)))
        .pipe(take(1))
        .subscribe(([currentModule, acceleratedTo]) => {
          if (currentModule.number >= 6 || acceleratedTo >= 6) {
            this.exerciseToPlay = exercise;
            this.service.setExercise(exercise.id);

            this.autoPlay = false;
            this.nextStep.emit();
          }
          else {
            this.store.dispatch(
              new navigationActions.ShowInterstitial({
                page: 'InterstitialPage',
                params: {
                  type: 'locked',
                  title: 'interstitials.rain_locked_title',
                  notes: 'interstitials.rain_locked_module_6_ern'
                }
              })
            );
          }
        });
    }
    else {
      this.exerciseToPlay = exercise;
      this.service.setExercise(exercise.id);
      this.nextStep.emit();
    }
  }

  startStressTest() {
    this._handleWizardOpen();

    this.store.dispatch(new navigationActions.OpenWizard({type: 'stresstest-ern'}));

    setTimeout(() => {
      this.exerciseToPlay = null;
      this.nextStep.emit();
    }, 200);
  }

  protected _init() {
    this.service = this.injector.get<StressMetersService>(StressMetersService);
    this.store.dispatch(new toolsActions.StartStressMeter());

    return this.service.initModel()
      .then(() => {
        this.triggers = this.service.getTriggersList();
      });
  }

  private _handleWizardOpen() {
    // @todo move this out of here and use a better and more abstract approach of tools navigation
    this.actions$.pipe(ofType<navigationActions.OpenWizard>(navigationActions.OPEN_WIZARD),
      take(1))
      .subscribe(() => this._handleStopStressTest());
  }

  private _handleStopStressTest() {
    this.actions$.pipe(ofType<toolsActions.StopStressTest>(toolsActions.STOP_STRESS_TEST),
      take(1))
      .subscribe(() => this.onDone());
  }

  private loadTriggerChoice() {
    this.exerciseToPlay = null;
    this.triggerChoice = this.service.getTrigger();

    if (this.triggerChoice) {
      this.onEnableNext();
    }
    else {
      this.onDisableNext();
    }
  }

}
