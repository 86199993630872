import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { SettingsComponent } from './settings.component';
import { UserReminder } from '../../../store/normalized/schemas/reminder.schema';
import { ClarityConfig } from '../../../config/clarity.config';
import { ConnectivityService } from '../../../services/connectivity.service';
import { SessionState } from '../../../store/session';
import * as userReminderActions from '../../../store/session/actions/user-reminders.actions';
import { scaleUpDown } from '@mindsciences/utils';

@Component({
  selector: 'cl-edit-reminder',
  styleUrls: ['reminders-edit.component.scss'],
  animations: [scaleUpDown],
  template: `
    <div [@state]="visibility" (@state.done)="animationDone($event)">
      <div class="reminder-title" *ngIf="!isDesktop">
        {{ reminderName | translate }}
      </div>

      <p class="reminder-instructions">
        {{getInstructionsTranslationKey(reminderName) | translate : {programName: config.program.name} }}
      </p>

      <div class="reminder-section-name">
        {{ 'reminders.notification_settings' | translate }}
      </div>

      <ion-item no-lines>
        <ion-label>
          {{ 'reminders.notification_on' | translate }}
        </ion-label>
        <ion-toggle color="toggle" mode="ios" [(ngModel)]="reminder.enabled" (ionChange)="onReminderToggled()"></ion-toggle>
      </ion-item>

      <div class="reminder-section-name" *ngIf="reminder.enabled">
        {{ 'reminders.notification_settings' | translate }}
      </div>

      <div *ngIf="reminder.enabled">
        <cl-reminder-timing-edit [reminder]="reminder"></cl-reminder-timing-edit>
      </div>

      <div class="reminder-section-name" *ngIf="reminder.enabled">
        {{ 'reminders.notification_days' | translate }}
      </div>

      <ion-item no-lines *ngIf="reminder.enabled">
        <ion-label>
          {{ 'reminders.sunday' | translate }}
        </ion-label>
        <ion-toggle color="toggle" mode="ios" [(ngModel)]="reminder.day_sun"></ion-toggle>
      </ion-item>

      <ion-item no-lines *ngIf="reminder.enabled">
        <ion-label>
          {{ 'reminders.monday' | translate }}
        </ion-label>
        <ion-toggle color="toggle" mode="ios" [(ngModel)]="reminder.day_mon"></ion-toggle>
      </ion-item>

      <ion-item no-lines *ngIf="reminder.enabled">
        <ion-label>
          {{ 'reminders.tuesday' | translate }}
        </ion-label>
        <ion-toggle color="toggle" mode="ios" [(ngModel)]="reminder.day_tue"></ion-toggle>
      </ion-item>

      <ion-item no-lines *ngIf="reminder.enabled">
        <ion-label>
          {{ 'reminders.wednesday' | translate }}
        </ion-label>
        <ion-toggle color="toggle" mode="ios" [(ngModel)]="reminder.day_wed"></ion-toggle>
      </ion-item>

      <ion-item no-lines *ngIf="reminder.enabled">
        <ion-label>
          {{ 'reminders.thursday' | translate }}
        </ion-label>
        <ion-toggle color="toggle" mode="ios" [(ngModel)]="reminder.day_thu"></ion-toggle>
      </ion-item>

      <ion-item no-lines *ngIf="reminder.enabled">
        <ion-label>
          {{ 'reminders.friday' | translate }}
        </ion-label>
        <ion-toggle color="toggle" mode="ios" [(ngModel)]="reminder.day_fri"></ion-toggle>
      </ion-item>

      <ion-item no-lines *ngIf="reminder.enabled">
        <ion-label>
          {{ 'reminders.saturday' | translate }}
        </ion-label>
        <ion-toggle color="toggle" mode="ios" [(ngModel)]="reminder.day_sat"></ion-toggle>
      </ion-item>

      <ion-row class="action-button">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            (action)="updateReminder()"
            [canClick]="hasChanges"
            label="{{'reminders.update_reminder' | translate}}">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </div>
  `
})
export class RemindersEditComponent implements SettingsComponent, OnInit {
  @HostBinding('class.desktop') get desktop() {
    return this.isDesktop;
  }

  @Input() isDesktop: boolean;
  @Input() reminder: UserReminder;
  @Input() reminderName: string;
  @Input() showName = true;
  @Output() unsavedReminder = new EventEmitter();
  @Output() reminderUpdated = new EventEmitter();

  @Output() endAnimation = new EventEmitter();
  visibility = 'visible';

  initialReminderSettings: UserReminder;

  constructor(
    private sessionStore: Store<SessionState>,
    public config: ClarityConfig,
    private connectivityService: ConnectivityService,
    private changeDetector: ChangeDetectorRef
  ) {
  }

  get hasChanges(): boolean {
    const hasChanges: boolean = Object.keys(this.initialReminderSettings)
      .map((key: string): boolean => this.initialReminderSettings[key] !== this.reminder[key])
      .some((el: boolean): boolean => el);

    this.unsavedReminder.emit(hasChanges);

    return hasChanges;
  }

  ngOnInit() {
    this.initialReminderSettings = {...this.reminder};
  }

  updateReminder() {
    this.unsavedReminder.emit(false);

    if (this.reminder.id) {
      this.initialReminderSettings = {...this.reminder};
      this.sessionStore.dispatch(new userReminderActions.UpdateUserReminder(this.reminder));
    } else {
      if (this.connectivityService.preventAccessWhenOffline()) {
        return;
      }

      this.initialReminderSettings = {...this.reminder};
      this.sessionStore.dispatch(new userReminderActions.CreateUserReminder(this.reminder));
    }

    this.reminderUpdated.emit(true);
  }

  getInstructionsTranslationKey(reminderName) {
    return `${reminderName}_instructions`;
  }

  onReminderToggled() {
    this.changeDetector.detectChanges();
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }
}
