import { Component, Injector } from '@angular/core';
import { WizardComponent } from '../wizard.component';
import { getExercisesNightReflection } from '../../../../store/normalized/selectors/exercises.selectors';
import { CheckinsService } from '../../../../services/wizards/checkins.service';
import * as programActions from '../../../../store/session/actions/program.actions';
import * as navigationActions from '../../../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../../store/session/session.reducers';
import { BrowserService } from '../../../../services/browser.service';
import { ClarityConfig } from '../../../../config/clarity.config';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { LiveBonusExercise } from 'src/app/store/session/selectors/program.selectors';
import { getCurrentUserProgram } from '../../../../store/normalized/selectors/user.selectors';

@Component({
  selector: 'cl-night-reflection',
  styleUrls: ['../../wizard.scss', 'night-reflection.component.scss'],
  templateUrl: 'night-reflection.component.html'
})
export class NightReflectionComponent extends WizardComponent {

  userProgram$ = this.store.select(getCurrentUserProgram);
  exercises$ = this.store.select(getExercisesNightReflection);
  public exerciseToPlay = null;

  public feelings = [];
  public feelingChoice = null;

  public smokingType;

  anxiousFeeling = 0;
  feelBefore = 50;

  cigCount = 0;
  cigCountSubscription;

  protected wizardConfig = {
    title: 'wizards.night_reflection',
    totalSteps: 3,
    initialStep: 1
  };

  constructor(
    protected store: Store<SessionState>,
    protected browser: BrowserService,
    protected injector: Injector,
    public config: ClarityConfig,
    protected analyticsService: AnalyticsService,
    protected translate: TranslateService
  ) {
    super(store, browser, injector, config, analyticsService, translate);

    this.userProgram$.subscribe((userProgram) => {
      this.smokingType = userProgram.smoking_type;
    });
  }

  initStep1() {
    if (this.config.isUA()) {
      this.onEnableNext();
      this.loadFeelingChoice();
    }
    else if (this.config.isERN() || this.config.isCTQ())  {
      this.onEnableNext();

    }
  }

  initStep2() {
    if (this.config.isUA()) {
      this.anxiousFeeling = this.service.getAnxiety() * 10;
      this.onEnableNext();
    }
    else if (this.config.isERN()) {
      this.onDisableNext();
    }
    else if (this.config.isCTQ()) {
      this.onEnableNext();
    }
  }

  initStep3() {
    if (this.config.isCTQ()) {
      this.onDisableNext();
      this.store.select(getExercisesNightReflection)
        .pipe(
          take(1)
        )
        .subscribe((exercises) => {
          const allLocked = exercises.every(exercise => exercise.isLocked);
          if (allLocked) {
            this.onEnableNext();
          }
        });
    }
  }

  onDone() {
    if (this.config.isUA()) {
      this.service.saveNightReflection();
    }
    else if (this.config.isERN()) {
      this.store.dispatch(new navigationActions.CloseAllModals());
    }
    else if (this.config.isCTQ()) {
      this.cigCountSubscription.unsubscribe();
      this.store.dispatch(new navigationActions.CloseAllModals());
    }

    this.wizardComplete.emit();
  }

  playExercise(bonusExercise: LiveBonusExercise) {
    if (bonusExercise.isLocked) {
      return this.store.dispatch(new navigationActions.ShowInterstitial({
        page: 'InterstitialPage',
        params: {
          type: 'locked',
          notes: 'interstitials.you_need_to_finish_module',
          playModule: bonusExercise.moduleNumber,
          bonusExercise: bonusExercise.exercise.title
        }
      }));
    } else {
      this.onEnableNext();
      this.exerciseToPlay = bonusExercise.exercise;
      this.nextStep.emit();
    }
  }

  openJournal() {
    this.store.dispatch(new programActions.OpenCommunityJournal());
  }

  onFeelingSelected(feeling) {
    this.service.setFeeling(feeling);
    this.loadFeelingChoice();
  }

  private loadFeelingChoice() {
    this.feelingChoice = this.service.getFeeling();

    if (this.feelingChoice) {
      this.onEnableNext();
    }
    else {
      this.onDisableNext();
    }
  }

  setAnxiousFeelingChoice(anxiety: number) {
    this.service.setAnxiety(anxiety);
  }

  setFeelBefore(value: number) {
    this.service.setFeelBefore(value);
    this.feelBefore = value;
  }

  protected _init() {
    if (this.config.isUA()) {
      this.service = this.injector.get<CheckinsService>(CheckinsService);

      return this.service.initModel()
        .then(() => {
          this.feelings = this.service.getFeelingsList();
          this.service.setTag('night_reflection');
        });
    }

    if (this.config.isCTQ()) {
      this.service = this.injector.get<CheckinsService>(CheckinsService);

      this.cigCountSubscription = this.service.getCigCount()
        .subscribe(cigCount => this.cigCount = cigCount);

      this.store.select(getExercisesNightReflection)
        .pipe(
          take(1)
        )
        .subscribe((exercises) => {
          const allLocked = exercises.every(exercise => exercise.isLocked);
          this.wizardConfig.totalSteps = allLocked ? 3 : 4;
        });
    }

    return Promise.resolve();
  }
}
