import { Component, EventEmitter, Output, Injector, OnDestroy } from '@angular/core';
import { WizardComponent } from '../wizard.component';
import { Observable , Subscription } from 'rxjs';
import * as navigationActions from '../../../../store/session/actions/navigation.actions';
import * as toolsActions from '../../../../store/session/actions/tools.actions';
import { StressMetersService } from '../../../../services/wizards/stress-meters.service';
import {
  getExerciseById, getMindfulExercise
} from '../../../../store/normalized/selectors/exercises.selectors';
import * as exercisesActions from '../../../../store/session/actions/exercises.actions';
import {
  getCurrentModule
} from '../../../../store/session/selectors/program.selectors';
import { take, withLatestFrom } from 'rxjs/operators';
import { getCigCountToday } from '../../../../store/session/selectors/cig-tracker.selectors';
import { TrackerService } from '../../../../services/tracker.service';
import { Exercise } from '../../../../store/normalized/schemas/exercise.schema';
import { getAcceleratedTo } from '../../../../store/normalized/selectors/user-bootstrap.selectors';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../../store/session/session.reducers';
import { BrowserService } from '../../../../services/browser.service';
import { ClarityConfig } from '../../../../config/clarity.config';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cl-want-o-meter-ctq-wizard',
  styleUrls: ['../../wizard.scss', 'want-o-meter-ctq-wizard.component.scss'],
  templateUrl: 'want-o-meter-ctq-wizard.component.html'
})
export class WantOMeterCTQWizardComponent extends WizardComponent implements OnDestroy {

  public cravingIntensity = 50;
  public feelingNow = 50;
  public triggers = [];
  public triggerChoice = null;
  public exerciseToPlay = null;
  public isStressExercise = false;
  public otherExercises$: Observable<Exercise[]>;
  public recommendedExercise$ = this.store.select(getExerciseById);
  public header: string;
  public cigarettesLeftParam = {cigarettesLeft: 2};
  public autoPlay = false;

  @Output() nextStep = new EventEmitter();
  protected wizardConfig = {
    title: 'wizards.want_o_meter',
    totalSteps: 4,
    initialStep: 1
  };
  private trackerService: TrackerService = null;
  private trackerSubscription: Subscription;

  constructor(
    protected store: Store<SessionState>,
    protected browser: BrowserService,
    protected injector: Injector,
    public config: ClarityConfig,
    protected analyticsService: AnalyticsService,
    protected translate: TranslateService
  ) {
    super(store, browser, injector, config, analyticsService, translate);
  }

  initStep1() {
    this.cravingIntensity = this.service.getCravingIntensity();
    this.onEnableNext();
  }

  isStepValid1() {
    return this.service.isCravingIntensityValid();
  }

  initStep2() {
    this.loadTriggerChoice();
  }

  initStep3() {
    this.onDisableNext();
  }

  initStep4() {
    this.onEnableNext();
  }

  isStepValid2() {
    return this.service.isTriggerValid();
  }

  onIntensityChange(value) {
    this.service.setCravingIntensity(value);
  }

  onIntensityAfterFeelingChange(value) {
    this.service.setCravingIntensityAfterFeeling(value);
  }

  onTriggerSelected(trigger) {
    const triggerItem = this.triggers.find((item) => item.id === trigger);
    this.getRecommendedExercises(triggerItem.exercise_id);

    this.service.setTrigger(trigger);
    this.loadTriggerChoice();
  }

  onDone() {
    this.service.save();
    this.trackerSubscription && this.trackerSubscription.unsubscribe();
    this.wizardComplete.emit();
  }

  getRecommendedExercises(id) {
    this.store.dispatch(new exercisesActions.GetBonusExerciseById(id));
    this.store.select(getExerciseById);
    this.otherExercises$ = this.store.select(getMindfulExercise);
  }

  playExercise(exercise: Exercise) {
    this.autoPlay = true;
    if (exercise.tags.find((tag) => tag.name.startsWith('rain'))) {
      this.store.select(getCurrentModule)
        .pipe(withLatestFrom(this.store.select(getAcceleratedTo)))
        .pipe(take(1))
        .subscribe(([currentModule, acceleratedTo]) => {
          if (currentModule.number >= 4 || acceleratedTo >= 4) {
            this.exerciseToPlay = exercise;
            this.service.setExercise(exercise.id);
            this.nextStep.emit();
            this.autoPlay = false;
          }
          else {
            this.store.dispatch(
              new navigationActions.ShowInterstitial({
                page: 'InterstitialPage',
                params: {
                  type: 'locked',
                  title: 'interstitials.rain_locked_title',
                  notes: 'interstitials.rain_locked_module_4_ctq'
                }
              })
            );
          }
        });
    }
    else {
      this.exerciseToPlay = exercise;
      this.service.setExercise(exercise.id);
      this.nextStep.emit();
    }
  }

  startStressTest() {
    this.store.dispatch(new navigationActions.OpenWizard({type: 'stresstest-ern'}));

    setTimeout(() => {
      this.nextStep.emit();
    }, 200);
  }

  ngOnDestroy() {
    this.trackerSubscription && this.trackerSubscription.unsubscribe();
  }

  protected _init() {
    this.service = this.injector.get<StressMetersService>(StressMetersService);
    this.store.dispatch(new toolsActions.StartStressMeter());

    this.trackerService = this.injector.get<TrackerService>(TrackerService);

    this.trackerSubscription = this.store.select(getCigCountToday)
      .subscribe((smokedToday) => {
        this.trackerService.calculateCanSmokeToDate()
          .then((canSmoke) => {
            this.cigarettesLeftParam.cigarettesLeft = Math.max(0, canSmoke - smokedToday);
          });
      });

    return this.service.initModel()
      .then(() => {
        this.triggers = this.service.getTriggersList();
      });
  }

  private loadTriggerChoice() {
    this.exerciseToPlay = null;
    this.triggerChoice = this.service.getTrigger();

    if (this.triggerChoice) {
      this.onEnableNext();
    }
    else {
      this.onDisableNext();
    }
  }

}
