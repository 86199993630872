import { createSchemaSelectors } from 'ngrx-normalizr';
import { Reminder, UserReminder, reminderSchema, userReminderSchema } from '../schemas/reminder.schema';
import { createSelector } from '@ngrx/store';

const reminderSchemaSelectors = createSchemaSelectors<Reminder>(reminderSchema);
const userReminderSchemaSelectors = createSchemaSelectors<UserReminder>(userReminderSchema);

// export const getReminders = createSelector(
//   reminderSchemaSelectors.getNormalizedEntities,
//   reminderSchemaSelectors.entitiesProjector
// );

export const getReminders = reminderSchemaSelectors.getEntities;

export const getUserReminders = createSelector(
  userReminderSchemaSelectors.getNormalizedEntities,
  userReminderSchemaSelectors.entitiesProjector
);

function reminderToUserReminder(reminder: Reminder): UserReminder {
  const userReminder = {
    ...reminder,
    reminder_id: reminder.id,
    id: undefined
  };

  return userReminder;
}

function sanitizeUserReminder(reminder: UserReminder): UserReminder {
  if (reminder.daily_count === 0) {
    reminder.daily_count = 1;
  }

  return reminder;
}

export const getUserRemindersOrDefaults = createSelector(
  getReminders,
  getUserReminders,
  (reminders, userReminders = []): UserReminder[] => {
    if (!userReminders || !reminders) {
      return ([] as UserReminder[]);
    }

    const userRemindersByReminderId = {};
    userReminders.forEach((userReminder) => {
      userRemindersByReminderId[userReminder.reminder_id] = userReminder;
    });

    return reminders.map((reminder) => {
      if (userRemindersByReminderId[reminder.id]) {

        return {
          ...reminderToUserReminder(reminder),
          ...userRemindersByReminderId[reminder.id]
        };
      }

      return reminderToUserReminder(reminder);
    })
      .map(sanitizeUserReminder);
  }
);

// const getReminderById = createSelector(
//   reminderSchemaSelectors.getNormalizedEntities,
//   getSelectedId
// );
