<cl-smoking-simulation-step
  *ngIf="simulation"
  [smokingType]="currentCravingTool.smokingType"
  (closeClick)="onClose()"
  (next)="toAfterSimulation(false)"
  (skip)="toAfterSimulation(true)">
</cl-smoking-simulation-step>

<cl-smoke-or-ride-capture-step
  *ngIf="afterSimulation"
  [(currentCravingTool)]="currentCravingTool"
  [skipped]="skippedSimulation"
  (closeClick)="onClose()"
  (smoke)="toMindfulSmokingExercise()"
  (ride)="toRainExercise()">
</cl-smoke-or-ride-capture-step>

<cl-mindful-smoking-exercise-step
  *ngIf="mindfulSmoking"
  [(currentCravingTool)]="currentCravingTool"
  (closeClick)="onClose()"
  (next)="toAfterSmokingSatisfactionStep()">
</cl-mindful-smoking-exercise-step>

<cl-rain-exercise-step
  *ngIf="rainExercise"
  [(currentCravingTool)]="currentCravingTool"
  (closeClick)="onClose()"
  (next)="toAfterSmokingSatisfactionStep()">
</cl-rain-exercise-step>

<cl-after-smoking-satisfaction-step
  *ngIf="afterSmokingSatisfaction"
  [(currentCravingTool)]="currentCravingTool"
  (closeClick)="onClose()"
  (done)="done()">
</cl-after-smoking-satisfaction-step>
