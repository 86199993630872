import { Animation, createAnimation, NavOptions } from '@ionic/core';

const runAnimation = (_, opts) => {
  const DEFAULT_OUT_DURATION = 180;
  const DEFAULT_IN_DURATION = 240;

  const rootTransition = createAnimation();

  const enteringEl = opts.enteringEl;
  const leavingEl = opts.leavingEl;

  const ionPageElement = getIonPageElement(enteringEl);

  rootTransition
    .addElement(ionPageElement)
    .beforeRemoveClass('ion-page-invisible')
    .beforeRemoveClass('dimmed');

  const forwardDirection = (opts.direction === 'forward');

  // Animate entering element
  if (forwardDirection) {
    // entering element going forward -- comes in from right to left
    rootTransition
      .duration(opts.duration || DEFAULT_IN_DURATION)
      .easing('cubic-bezier(0.420, 0.000, 0.580, 1.000)')
      .fromTo('transform', 'translateX(100%)', 'translateX(0%)')
      .afterClearStyles(['transform']);
    // .fromTo('opacity', 1, 1, true);
  } else {
    // entering element going backward - comes in from left to right
    rootTransition
    // reverse duration so the page slides back in faster
      .duration(opts.duration || DEFAULT_OUT_DURATION)
      .easing('cubic-bezier(0.420, 0.000, 0.580, 1.000)')
      .fromTo('transform', 'translateX(-50%)', 'translateX(0%)')
      .afterClearStyles(['transform']);
  }

  // const enterToolbarElement = ionPageElement.querySelector('ion-toolbar');
  //
  // // If toolbar exists, animate
  // if (enterToolbarElement) {
  //   const enteringToolBar = new AnimationC();
  //   enteringToolBar.addElement(enterToolbarElement);
  //   rootTransition.add(enteringToolBar);
  // }

  // Animate leaving element
  if (leavingEl) {
    const leavingPage = createAnimation();

    if (forwardDirection) {
      // leaving element going forward -- moves to the left of the screen
      leavingPage
        .duration(opts.duration || DEFAULT_OUT_DURATION)
        .beforeAddClass('dimmed')
        .addElement(getIonPageElement(leavingEl))
        .fromTo('transform', 'translateX(0%)', 'translateX(-40%)')
        .afterClearStyles(['transform']);
    }
    else {
      // leaving element going backward -- moves to the right of the screen and fades out
      leavingPage
      // reverse duration so the pages fades out slower
        .duration(opts.duration || DEFAULT_IN_DURATION)
        .addElement(getIonPageElement(leavingEl))
        .fromTo('transform', 'translateX(0%)', 'translateX(100%)')
        .afterClearStyles(['transform']);
    }

    rootTransition.addAnimation(leavingPage);
  }

  return rootTransition;
};

// uncomment to use sandbox in browser
// window['anime'] = runAnimation;

export function clarityPagesTransitionAnimation(_: HTMLElement, opts: TransitionOptions): Animation {
  // uncomment to use sandbox in browser
  // return runAnimation(_, opts);
  return runAnimation(_, opts);
}

export interface TransitionOptions extends NavOptions {
  enteringEl: HTMLElement;
  baseEl: any;
  leavingEl: HTMLElement | undefined;
  progressCallback?: ((ani: Animation | undefined) => void);
  window: Window;
}

function getIonPageElement(element: HTMLElement) {
  if (element.classList.contains('ion-page')) {
    return element;
  }

  const ionPage = element.querySelector(':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs');

  if (ionPage) {
    return ionPage;
  }

  return element;
}
