import { createSelector } from '@ngrx/store';
import { createSchemaSelectors } from 'ngrx-normalizr';
import { getHealthDevicesState } from '../..';
import { WeightScaleOrder, weightScaleOrderSchema } from '../../normalized/schemas/health-devices.schema';
import { HealthDevicesState } from '../reducers/health-devices.reducer';

const weightScaleOrderSchemaSelector = createSchemaSelectors<WeightScaleOrder>(weightScaleOrderSchema);

export const getWeightScaleOrders = createSelector(
  weightScaleOrderSchemaSelector.getEntities,
  (orders) => orders ?? []
);

export const getWeightScaleOrderStatus = createSelector(
  getHealthDevicesState,
  (state: HealthDevicesState) => state.weightScale?.orderingStatus
);
