// goals actions

import { Action } from '@ngrx/store';
import { LiveLesson } from '../selectors/program.selectors';
import { UserGoal } from '../../normalized/schemas/user.schema';

export const UPDATE_USER_GOALS = '[user goals] Update User Goals';
export const UPDATE_USER_GOALS_SUCCESS = '[user goals] Update User Goals Success';
export const UPDATE_USER_GOALS_FAIL = '[user goals] Update Goals Fail';

export const LOAD_USER_GOALS = '[user goals] Load User Goals';
export const LOAD_USER_GOALS_SUCCESS = '[user goals] Load User Goals Success';
export const LOAD_USER_GOALS_FAIL = '[user goals] Load User Goals Fail';

export class UserGoalUpdate {
  add: UserGoal[];
  remove: UserGoal[];
  defaults: number[];
  lesson?: LiveLesson;
  hideLoader?: boolean;
}

export class UpdateUserGoals implements Action {
  readonly type = UPDATE_USER_GOALS;

  constructor(public payload: UserGoalUpdate) {

  }
}

export class UpdateUserGoalsSuccess implements Action {
  readonly type = UPDATE_USER_GOALS_SUCCESS;

  constructor(public payload: LiveLesson) {

  }
}

export class UpdateUserGoalsFail implements Action {
  readonly type = UPDATE_USER_GOALS_FAIL;

  constructor(public payload: LiveLesson) {
  }
}

export class LoadUserGoals implements Action {
  readonly type = LOAD_USER_GOALS;
  constructor(public payload: any) {
  }
}

export class LoadUserGoalsSuccess implements Action {
  readonly type = LOAD_USER_GOALS_SUCCESS;
}

export class LoadUserGoalsFail implements Action {
  readonly type = LOAD_USER_GOALS_FAIL;

  constructor(public payload: any) {
  }
}

export type UserGoalsActions =
  | UpdateUserGoals
  | UpdateUserGoalsSuccess
  | UpdateUserGoalsFail
  | LoadUserGoals
  | LoadUserGoalsSuccess
  | LoadUserGoalsFail;
