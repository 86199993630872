import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { SessionState } from '../../store/session/session.reducers';

@Component({
  selector: 'page-my-stats',
  styleUrls: ['my-stats.scss'],
  templateUrl: 'my-stats.html'
})
export class MyStatsPage {

  showBackButton = true;
  modal;

  constructor(
    private store: Store<SessionState>,
    public navCtrl: NavController,
    public navParams: NavParams
  ) {
    this.showBackButton = this.navParams.get('backButton') !== undefined ? this.navParams.get('backButton') : true;
  }

  closeButtonAction() {
    this.store.dispatch(new navigationActions.CloseAllModals());
  }

  backButtonAction() {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }
}
