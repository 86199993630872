import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { LiveModule } from '../../store/session/selectors/program.selectors';

@Component({
  selector: 'cl-module-item',
  styleUrls: ['module-item.component.scss'],
  template: `
    <ion-button
      class="module-item-button-wrapper"
      mode="ios"
      (click)="onClick($event)"
      [disabled]="!expanded"
      [ngClass]="{'completed': isCompleted, 'current': isCurrent, 'missing': isMissing, 'expanded': expanded}">
      <div class="inner-button-container">
        <div class="inner-button-icon-container">
          <div class="round-icon" *ngIf="!isCustomPath">
            <span *ngIf="module && module.type === 'program'">{{module.number}}</span>
            <span *ngIf="module && module.type === 'custom'">{{module.positionInWeek}}</span>
            <span *ngIf="module && module.type === 'theme'">{{module.positionInWeek}}</span>

            <ion-icon class="checkmark-icon" name="checkmark-circle"></ion-icon>
          </div>

          <div class="round-icon" [class.white-icon]="!isSelected" [class.orange-icon]="isSelected"
               *ngIf="isCustomPath">
            <span *ngIf="module && module.type === 'program'">{{module.number}}</span>
            <span *ngIf="module && module.type === 'custom'">{{module.positionInWeek}}</span>
            <span *ngIf="module && module.type === 'theme'">{{module.positionInWeek}}</span>
          </div>
        </div>
        <div class="inner-button-title-container">
          <p [ngClass]="{'missing': isMissing}" *ngIf="module && module.type !== 'extend'">
            {{module.record.title}}
          </p>
        </div>
      </div>
    </ion-button>
  `
})
export class ModuleItemComponent implements OnChanges {
  @Input() module: LiveModule;
  @Input() expanded = false;
  @Input() isCustomPath = false;
  @Input() selectedModules = [];

  @Output() clicked = new EventEmitter();

  isSelected = false;

  get isMissing() {
    return this.module && this.module.type !== 'extend' ? false : true;
  }

  get isCurrent() {
    if (this.isCustomPath) {
      return false;
    }

    return this.module && this.module.isCurrent ? true : false;
  }

  get isCompleted() {
    if (this.isCustomPath) {
      return false;
    }

    return this.module && this.module.isCompleted ? true : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedModules']) {
      this.isSelected = !!changes['selectedModules']
        .currentValue.find((selectedModule) => this.module && selectedModule.number === this.module.number);
    }
  }

  onClick($event) {
    if (!this.expanded) {
      return false;
    }

    // do not bubble when element is expanded and play only when expanded
    $event.stopPropagation();
    this.clicked.emit(this.module);
  }
}
