import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { Observable, throwError, throwError as observableThrowError, of } from 'rxjs';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { OfflineHttpProvider } from 'src/app/providers/http/offline-http.provider';
import { HttpParams } from '@angular/common/http';
import { Conversation, ConversationFeed, InAppMessage, Message, MessageFeed } from 'src/app/store/normalized/schemas/my-coach.schema';
import { HttpProvider } from 'src/app/providers/http/http.provider';
import { Notification, NotificationFeed } from 'src/app/store/normalized/schemas/inbox.schema';

interface UserEnvironment {
  osType: string;
  appVersion: string;
  osVersion: string;
}

interface TokenResponse {
  token: string;
};

@Injectable({providedIn: 'root'})
export class IridiumProvider {

  private userEnvironment: UserEnvironment;

  readonly eventsEndpoint = '{iridium}/events';
  readonly usersEndpoint = '{iridium}/users';

  readonly conversationsEndpoint = '{iridium}/conversations';
  readonly markConversationAsReadEndpoint = '{iridium}/conversations/:id/mark-read';
  readonly countUnreadMessagesEndpoint = '{iridium}/messages/count-unread';
  readonly messagesEndpoint = '{iridium}/messages';
  readonly getCustomAttributeValuesEndpoint = '{iridium}/customAttributeValues/:key';

  readonly markInAppMessageAsReadEndpoint = '{iridium}/outbound/:id/mark-read';
  readonly unreadInAppMessagesEndpoint = '{iridium}/outbound/unread';

  readonly notificationsEndpoint = '{iridium}/notifications';
  readonly allNotificationsEndpoint = '{iridium}/notifications/all';

  readonly tokenEndpoint = '{iridium}/auth/login';

  constructor(
    private http: HttpProvider,
    private config: ClarityConfig,
    private offlineHttp: OfflineHttpProvider
  ) {

  }

  public getInAppMessages(): Observable<InAppMessage[]> {
    return this.http.get<InAppMessage[]>(this.unreadInAppMessagesEndpoint)
      .pipe(
        catchError((error) => observableThrowError(error))
      );
  }

  public markInAppMessageAsRead(messageId): Observable<void> {
    return this.http.put(this.markInAppMessageAsReadEndpoint.replace(':id', messageId), {})
      .pipe(
        catchError((error) => observableThrowError(error))
      );
  }

  public logEvent(name, data: {} = {}): Observable<void> {
    return this.offlineHttp.post<void>(this.eventsEndpoint, {
      name,
      programCode: this.config.currentProgramCode,
      data,
      userEnvironment: this.getUserEnvironment()
    })
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public updateUser(user, externalId): Observable<any> {
    return this.offlineHttp.patch(`${this.usersEndpoint}/${externalId}`, {...user})
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  private getUserEnvironment(): UserEnvironment {
    if (! this.userEnvironment) {
      this.userEnvironment = {
        osType: this.config.buildPlatform,
        appVersion: this.config.getAppVersion(),
        osVersion: this.config.getOsVersion()
      };
    }

    return this.userEnvironment;
  }

  public markConversationAsRead(idConversation: string): Observable<void> {
    return this.http.put(this.markConversationAsReadEndpoint.replace(':id', idConversation), {})
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public addConversation(message): Observable<Conversation> {
    return this.http.post(this.conversationsEndpoint, {
      message,
      program: this.config.currentProgramCode
    })
      .pipe(
        map(conversation => this.mapConversation(conversation)),
        catchError(error => observableThrowError(error))
      );
  }

  public postMessage(content, conversation_id): Observable<Message> {
    return this.http.post(this.messagesEndpoint, {
      content,
      channel: 'coaching',
      conversationId: conversation_id
    })
      .pipe(
        map(message => this.mapMessage(message))
      );
  }

  public loadConversations(params, page = 1): Observable<ConversationFeed> {
    return this.http.get(this.conversationsEndpoint, {
      params: new HttpParams({
        fromObject: {
          ...params,
          program: this.config.currentProgramCode,
          page,
          limit: 20
        }
      })
    })
      .pipe(
        map((conversations: any) => ({
          id: null,
          list: conversations.data.map(conversation => this.mapConversation(conversation)),
          total_count: conversations.total
        })),
        catchError((error) => observableThrowError(error))
      );
  }

  public deleteConversation(id: string): Observable<void> {
    const req = `${this.conversationsEndpoint}/${id}`;

    return this.http.delete(req)
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public deleteMessage(id: string): Observable<void> {
    const req = `${this.messagesEndpoint}/${id}`;

    return this.http.delete(req)
      .pipe(
        catchError(error => observableThrowError(error))
      );
  }

  public loadMessages(conversationId: string): Observable<MessageFeed> {
    const builder = RequestQueryBuilder.create()
      .setFilter({field: 'conversationId', operator: CondOperator.EQUALS, value: conversationId})
      .setPage(1);

    return this.http.get<MessageFeed>(this.messagesEndpoint, {params: new HttpParams({fromObject: builder.queryObject})})
      .pipe(
        map((messages: any) => ({
          id: null,
          conversation_id: Number(conversationId),
          total_count: messages.total,
          list: messages.data
            .map(message => this.mapMessage(message))
            .reverse()
        })),
        catchError((error) => observableThrowError(error))
      );
  }

  public loadAllMessages(conversationId: string): Observable<Message[]> {
    const builder = RequestQueryBuilder.create()
      .setFilter({field: 'conversationId', operator: CondOperator.EQUALS, value: conversationId})
      .setLimit(9999);

    return this.http.get<Message[]>(this.messagesEndpoint, {params: new HttpParams({fromObject: builder.queryObject})})
      .pipe(
        map((messages: any) => messages.data
          .map(message => this.mapMessage(message))
          .reverse()
        ),
        catchError((error) => observableThrowError(error))
      );
  }

  public loadUnreadMessagesCount(): Observable<number> {
    return this.http.get(this.countUnreadMessagesEndpoint, {
      params: new HttpParams().set('program', this.config.currentProgramCode)
    })
      .pipe(
        catchError((error) => observableThrowError(error))
      );
  }

  public loadNotifications(): Observable<NotificationFeed> {
    return this.http.get(this.notificationsEndpoint, {
      params: new HttpParams().set('program', this.config.currentProgramCode)
    })
      .pipe(
        catchError(error => throwError(error))
      );
  }

  public loadAllNotifications(): Observable<Notification[]>  {
    return this.http.get(this.allNotificationsEndpoint, {
      params: new HttpParams().set('program', this.config.currentProgramCode)
    })
      .pipe(
        catchError(error => throwError(error))
      );
  }

  public getTokenWithTemporaryToken(temporaryToken: string): Observable<TokenResponse> {
    return this.http.post(this.tokenEndpoint, { temporaryToken })
      .pipe(catchError(error => throwError(error)));
  }

  private mapConversation(conversation): Conversation {
    return {
      id: conversation.id,
      owner_id: conversation.userId,
      messages_count: conversation.messages.length,
      unread_messages_count: conversation.messages.filter(message => !message.read).length,
      avatar: conversation.lastMessageAvatar,
      content: conversation.lastMessageContent,
      created_at: conversation.createdAt,
      updated_at: conversation.updatedAt,
      last_message_author: conversation.lastMessageAuthor,
      last_message_at: conversation.lastMessageDate,
      agents: conversation.agents,
      profiles: conversation.agents ? conversation.agents.concat([conversation.user]) : [conversation.user]
    };
  }

  private mapMessage(message): Message {
    return {
      id: message.id,
      profile_id: message.agentId || message.userId,
      type: message.userId ? 'user' : 'coach',
      content: message.content,
      username: message.authorName,
      avatar: message.authorAvatar,
      created_at: message.createdAt,
      updated_at: message.updatedAt,
      read: message.read,
      conversation_id: message.conversationId
    };
  }

}
