import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SettingsComponent } from './settings.component';
import { onboardingTypes } from '../../../constants/onboarding-types.const';
import * as navigationActions from '../../../store/session/actions/navigation.actions';
import { TranslateService } from '@ngx-translate/core';
import { ClarityConfig } from '../../../config/clarity.config';
import { SessionState } from '../../../store/session/session.reducers';
import { getIntroduceGearsVideo } from 'src/app/store/normalized/selectors/exercises.selectors';
import { getCurrentUserProgram } from 'src/app/store/normalized/selectors/user.selectors';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SmokingType } from 'src/app/store/normalized/schemas/user.schema';

enum smokingTypeKeys {
  ctq_tools_puff = 1,
  ctq_tools_cig,
  smokePuffCravingTool,
  smokeCigCravingTool
}

const ONBOARDING_LIST_NAME_MAP = {
  'onboarding.welcome_to_dashboard': 'tabs.home',
  'onboarding.welcome_to_exercises': 'tabs.exercises',
  'onboarding.welcome_to_journey': 'tabs.my_journey',
  'onboarding.welcome_to_community': 'tabs.community',
  'onboarding.welcome_to_lessons': 'dashboard.todays_lesson',
  'onboarding.account': 'sections.account',
  'onboarding.welcome_to_check-in': 'wizards.checkin',
  'onboarding.welcome_to_stress-test': 'wizards.stress_test',
  'onboarding.welcome_to_stress-meter': 'wizards.stress_meter',
  'onboarding.welcome_to_wantometer': 'wizards.want_o_meter',
  'onboarding.welcome_to_craving_tool': 'wizards.craving_tool',
  'activity_graph.help_title': 'my-stats.activity_chart',
  'cigarettes_graph.help_title': 'my-stats.cigarettes_chart',
  'vaping_graph.help_title': 'my-stats.vaping_chart',
  'daily_graph.help_title': 'my-stats.daily_chart',
  'weekly_graph.help_title': 'my-stats.weekly_chart',
  'awareness_graph.help_title': 'my-stats.awareness_chart',
  'onboarding.gears-animation': 'onboarding.shifting_gears_animation',
  'onboarding.welcome_back_to_core_modules': 'myjourney.revisit_core_modules'
};

@Component({
  selector: 'cl-onboardings-list',
  styleUrls: ['onboardings-list.component.scss'],
  template: `
    <div>
      <div class="title">
        {{'account_menu.help.choose_onboarding_to_review' | translate}}
      </div>

      <div>
        <ion-searchbar
          mode="ios"
          [placeholder]="'onboarding.type_to_search' | translate"
          [(ngModel)]="searchValue"
          (ionInput)="search()">
        </ion-searchbar>

        <ion-list>
          <div *ngFor="let onboarding of onboardingsList">
            <cl-list-item
              [label]="onboardingName(onboarding.header) | translate"
              (click)="openOnboarding(onboarding)">
            </cl-list-item>
          </div>
        </ion-list>
      </div>
    </div>`
})

export class OnboardingsListComponent implements SettingsComponent, OnInit, OnDestroy {

  onboardingTypes = [];
  searchedOnboardings = [];
  searchValue: string;
  smokingType: SmokingType;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig,
    private translate: TranslateService
  ) { }


  ngOnInit(): void {
    this.store.select(getCurrentUserProgram)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((userProgram) => {
        this.smokingType = userProgram?.smoking_type;
        this.processOnboardingTypes();
      });
  }

  processOnboardingTypes() {
    this.onboardingTypes = Object.keys(onboardingTypes)
      .map(key => {
        const onboarding = onboardingTypes[key];
        onboarding.type = key;
        if (onboarding.program && this.config.program.programCode !== onboarding.program) {
          return null;
        }

        if (smokingTypeKeys[key]) {
          return this.handleSmokingType(key);
        }

        return onboarding;
      });
    this.searchedOnboardings = this.onboardingTypes;
  }

  private handleSmokingType(key: string) {
    const smokingTypePrefix = this.smokingType === SmokingType.CIGARETTE ? 'cig' : 'puff';

    if (key.toLocaleLowerCase().includes(smokingTypePrefix)) {
      return onboardingTypes[key];
    }

    return null;
  }

  onboardingName(name) {
    return ONBOARDING_LIST_NAME_MAP[name] || name;
  }

  get onboardingsList() {
    const uniqueValuesSet = new Set();

    return this.searchedOnboardings.filter(onboarding => {
      if (!onboarding?.header || onboarding.hideInHelpScreen) {
        return false;
      }

      if (this.config.programDPPorWL() && onboarding?.dppwlProgram === false) {
        return false;
      }

      const isPresentInSet = uniqueValuesSet.has(onboarding.header);
      uniqueValuesSet.add(onboarding.header);

      return !isPresentInSet;
    });
  }

  openOnboarding(onboarding) {
    if (onboarding.type === 'introduceGears') {
      return this.store.select(getIntroduceGearsVideo)
        .pipe(
          take(1)
        )
        .subscribe((introduceGearsVideo) => {
          this.store.dispatch(new navigationActions.ShowOnboarding({
            page: 'OnboardingPage',
            params: {
              type: onboarding.type,
              video: introduceGearsVideo
            }
          }));
        });
    }

    this.store.dispatch(new navigationActions.ShowOnboarding({
      page: 'OnboardingPage',
      params: {
        type: onboarding.type
      }
    }));
  }

  search() {
    this.searchedOnboardings = [];
    this.onboardingTypes.forEach(
      (onboarding) => {
        if (onboarding && onboarding.header) {
          this.translate.get([
            this.onboardingName(onboarding.header),
            onboarding.header,
            onboarding.body
          ])
            .subscribe(translations => {
              const result = Object.keys(translations)
                .map((key) => {
                  const text = translations[key];

                  if (this.searchValue) {
                    return text.toLowerCase()
                      .indexOf(this.searchValue.toLowerCase()) > -1;
                  } else {
                    return true;
                  }
                })
                .some(res => res);

              if (result) {
                this.searchedOnboardings.push(onboarding);
              }
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
