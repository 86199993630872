import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { extractPostId } from 'src/app/utils/posts';

@Component({
  selector: 'cl-truncated-post',
  styleUrls: ['truncated-post.component.scss'],
  template: `
    <div class="truncated-post-wrapper" *ngIf="text">
      <div *ngIf="!isALongText" [innerHTML]="text"></div>
      <div *ngIf="truncated && isALongText">
        <span class="truncated-content" [innerHTML]="text | truncate:limit"></span>
        <span class="show-more" (click)="truncated = false">
          {{'social.posts.read_more' | translate}}
        </span>
        
      </div>
      <div class="full-content" *ngIf="!truncated && isALongText" [innerHTML]="text"></div>
    </div>
  `
})

export class TruncatedPostComponent implements OnInit, OnDestroy {
  @Input() text: string;
  @Input() limit = 30;
  @Output() textClick = new EventEmitter();

  truncated = true;
  isALongText: boolean;
  renderListener: () => void;
  
  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.isALongText = this.text.split(' ').length > this.limit;

    this.renderListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      const post = extractPostId(event);
      this.textClick.emit(post);
    });
  }

  ngOnDestroy() {
    if (this.renderListener) {
      this.renderListener();
    }
  }
  
}
