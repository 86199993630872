import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-intensity-selector',
  styleUrls: ['intensity-selector.component.scss'],
  template: `
    <div class="slider-block" [class.disabled]="disabled">
      <div class="hide-overlay"></div>
      <ion-row>
        <ion-col class="slider-content" size="10">
          <ion-row [ngClass]="{'label-selected': hideNumberLabel}">
            <ion-col class="label"
                     [attr.col-8]="showNumberLabel" [attr.col-12]="hideNumberLabel" [class.selected]="isValue100()">
              <p>{{ highestIntensityLabel | translate}}</p></ion-col>
            <ion-col *ngIf="showNumberLabel"
                     class="value value-100" size="4" [class.selected]="isValue100()">
              <p>
                {{ highestIntensityScale }}
              </p>
            </ion-col>
          </ion-row>
          <ion-row [ngClass]="{'label-selected': hideNumberLabel}">
            <ion-col class="label"
                     [attr.col-8]="showNumberLabel" [attr.col-12]="hideNumberLabel" [class.selected]="isValue75()">
              <p>{{ highIntensityLabel | translate }} </p></ion-col>
            <ion-col *ngIf="showNumberLabel"
                     class="value" size="4" [class.selected]="isValue75()">
              <p>
                {{ highIntensityScale }}
              </p>
            </ion-col>
          </ion-row>
          <ion-row [ngClass]="{'label-selected': hideNumberLabel}">
            <ion-col class="label"
                     [attr.col-8]="showNumberLabel" [attr.col-12]="hideNumberLabel" [class.selected]="isValue50()">
              <p>{{ mediumIntensityLabel | translate}}</p></ion-col>
            <ion-col *ngIf="showNumberLabel"
                     class="value" size="4" [class.selected]="isValue50()">
              <p>
                {{ mediumIntensityScale }}
              </p>
            </ion-col>
          </ion-row>
          <ion-row [ngClass]="{'label-selected': hideNumberLabel}">
            <ion-col class="label"
                     [attr.col-8]="showNumberLabel" [attr.col-12]="hideNumberLabel" [class.selected]="isValue25()">
              <p>{{ lowIntensityLabel | translate }}</p></ion-col>
            <ion-col *ngIf="showNumberLabel"
                     class="value" size="4" [class.selected]="isValue25()">
              <p>
                {{ lowIntensityScale }}
              </p>
            </ion-col>
          </ion-row>
          <ion-row [ngClass]="{'label-selected': hideNumberLabel}">
            <ion-col class="label"
                     [attr.col-8]="showNumberLabel" [attr.col-12]="hideNumberLabel" [class.selected]="isValue0()">
              <p>{{ lowestIntensityLabel | translate}}</p></ion-col>
            <ion-col *ngIf="showNumberLabel"
                     class="value value-0" size="4" [class.selected]="isValue0()">
              <p>
                {{ lowestIntensityScale }}
              </p>
            </ion-col>
          </ion-row>
        </ion-col>

        <ion-col class="slider-scale" size="2" *ngIf="showNumbers">
          <cl-vertical-slider
            [value]="sliderValue"
            [disabled]="disabled"
            (slide)="onSlide($event)">
          </cl-vertical-slider>
        </ion-col>

        <ion-col class="slider-scale" size="2" *ngIf="!showNumbers">
          <cl-vertical-slider
            [min]="1"
            [max]="5"
            [step]="1"
            [disabled]="disabled"
            [value]="sliderValue"
            (slide)="onSlide($event)">
          </cl-vertical-slider>
        </ion-col>
      </ion-row>
    </div>
  `
})
export class IntensitySelectorComponent {

  @Input() sliderValue = null;
  @Input() showNumbers = true;
  @Input() lowestIntensityLabel = 'wizards.not_at_all';
  @Input() lowIntensityLabel = '';
  @Input() mediumIntensityLabel = 'wizards.somewhat';
  @Input() highIntensityLabel = '';
  @Input() highestIntensityLabel = 'wizards.strongest_ever';
  @Input() disabled = false;

  @Input() lowestIntensityScale = '0';
  @Input() lowIntensityScale = '25';
  @Input() mediumIntensityScale = '50';
  @Input() highIntensityScale = '75';
  @Input() highestIntensityScale = '100';

  @Output() intensity = new EventEmitter();

  get showNumberLabel() {
    return this.showNumbers ? true : null;
  }

  get hideNumberLabel() {
    return this.showNumbers ? null : true;
  }

  onSlide(value) {
    this.sliderValue = value;
    this.intensity.emit(this.sliderValue);
  }

  isValue0() {
    return this.showNumbers ? this.sliderValue <= 15 : this.sliderValue === 1;
  }

  isValue25() {
    return this.showNumbers
      ? this.sliderValue > 15 && this.sliderValue <= 35
      : this.sliderValue === 2;
  }

  isValue50() {
    return this.showNumbers
      ? this.sliderValue > 35 && this.sliderValue <= 60
      : this.sliderValue === 3;
  }

  isValue75() {
    return this.showNumbers
      ? this.sliderValue > 60 && this.sliderValue <= 85
      : this.sliderValue === 4;
  }

  isValue100() {
    return this.showNumbers
      ? this.sliderValue > 85
      : this.sliderValue === 5;
  }

}
