import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  LiveLesson
} from '../../../../../store/session/selectors/program.selectors';
import { PerformService } from '../../../services/perform.service';
import { Store } from '@ngrx/store';
import { State } from '../../../../../store/state.reducer';

@Component({
  selector: 'cl-week-review',
  styleUrls: ['week-review.component.scss'],
  template: `
    <ion-row class="step step1 lateral-padding">
      <ion-col *ngIf="step === 1">
        <h4>{{model?.intro}}</h4>
        <cl-list-multiple-select
          [items]="model?.questions"
          (selectItem)="setAnswers($event)">
        </cl-list-multiple-select>
      </ion-col>

      <ion-col *ngIf="step === 2 && !isShowCongrats">
        <h4>{{"perform.part_2_title" | translate}}</h4>

        <div *ngFor="let exercise of exercises" class="item">
          <p class="title" *ngIf="exercise.number">{{"common.module" | translate}} {{exercise.number}}</p>
          <p class="details" *ngIf="exercise.record?.title">{{exercise.record.title}}</p>

          <p class="title" *ngIf="!exercise.number">{{"perform.exercise" | translate}}</p>
          <p class="details" *ngIf="exercise.title">{{exercise.title}}</p>
        </div>
      </ion-col>

      <ion-col *ngIf="step === 2 && isShowCongrats">
        <h3>{{model.congrats}}</h3>
      </ion-col>
    </ion-row>
  `
})

export class WeekReviewComponent implements OnChanges, AfterViewInit {
  @Input() lesson: LiveLesson;
  @Input() step: number;

  @Output() next = new EventEmitter();
  @Output() init = new EventEmitter();

  model: any;

  answers: any[];
  exercises = [];
  isShowCongrats = false;

  constructor(
    private performService: PerformService,
    public store: Store<State>
  ) {
  }

  ngOnChanges() {
    if (this.step === 1) {
      this.model = this.performService.processExerciseCaption(this.lesson.record.exercise.caption);
    }
    else if (this.step === 2) {
      // force scroll to top
      this.init.emit();

      this.exercises = [];
      this.model.questions.forEach(question => {
        const lesson = this.answers && this.answers.length && this.answers.find(answer => answer.name === question.name);

        if (!lesson) {
          question.modules.length
            ? this.exercises = this.exercises.concat(question.modules)
            : this.exercises = this.exercises.concat(question.exercises);
        }
      });

      if (this.exercises.length === 0) {
        this.isShowCongrats = true;
      }
    }
  }

  ngAfterViewInit() {
    this.init.emit();
  }

  setAnswers(answers) {
    this.answers = answers;
  }

}
