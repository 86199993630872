import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { BrowserService } from '../../../services/browser.service';
import { ClarityConfig } from '../../../config/clarity.config';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionState } from '../../../store/session/session.reducers';
import { WizardConfig } from '../wizard';
import { AfterCompleteLessonOptions } from 'src/app/store/session/actions/program.actions';

@Component({
  selector: 'cl-wizard-component',
  template: ''
})
export class WizardComponent {
  @Output() enableNext = new EventEmitter();
  @Output() disableNext = new EventEmitter();
  @Output() disableBack = new EventEmitter();
  @Output() hideControls = new EventEmitter();
  @Output() showControls = new EventEmitter();
  @Output() nextStep = new EventEmitter();
  @Output() moveTrowStep = new EventEmitter();
  @Output() stepChange = new EventEmitter();
  @Output() wizardComplete = new EventEmitter();
  @Output() resetConfig = new EventEmitter<Partial<WizardConfig>>();
  @Output() showStepsProgress = new EventEmitter<boolean>();

  public currentStep = null;

  protected service: any;

  // this will be overloaded by extending class
  protected wizardConfig: WizardConfig = {
    title: 'Wizard',
    totalSteps: 1,
    initialStep: 1
  };

  constructor(
    protected store: Store<SessionState>,
    protected browser: BrowserService,
    protected injector: Injector,
    public config: ClarityConfig,
    protected analyticsService: AnalyticsService,
    protected translate: TranslateService
  ) {
  }

  init() {
    // call child init and return the wizard config
    return this._init()
      .then(() => this.wizardConfig);
  }

  afterInit() {
    return this._afterInit();
  }

  onMoveTrowStep() {
    return this.isCurrentStepValid()
      .then((stepIsValid) => {
        if (!stepIsValid) {
          throw Error('Current step is not valid!');
        }

        return stepIsValid;
      })
      .then(() => this.initStep(this.currentStep + 2))
      .then(() => {
        this.stepChange.emit({
          step: this.currentStep
        });
      })
      .catch((error) => console.log(error));
  }

  onNextStep() {
    return this.isCurrentStepValid()
      .then((stepIsValid) => {
        if (!stepIsValid) {
          throw Error('Current step is not valid!');
        }

        return stepIsValid;
      })
      .then(() => this.initStep(this.currentStep + 1))
      .then(() => {
        this.stepChange.emit({
          step: this.currentStep
        });
      })
      .catch((error) => console.log(error));
  }

  onPrevStep() {
    return this.initStep(this.currentStep - 1)
      .then(() => {
        this.stepChange.emit({
          step: this.currentStep
        });
      });
  }

  onStepChange(data) { }

  public initStep(step) {
    // avoid going over the
    this.currentStep = step < 0
      ? 0
      : step > this.wizardConfig.totalSteps
        ? this.wizardConfig.totalSteps
        : step;

    return this._initStep(step);
  }

  isCurrentStepValid() {
    return this._isCurrentStepValid();
  }

  onEnableNext() {
    this.enableNext.emit();
  }

  onDisableNext() {
    this.disableNext.emit();
  }

  onDisableBack() {
    this.disableBack.emit();
  }

  onDone(afterCompleteOptions: AfterCompleteLessonOptions) {
    this.wizardComplete.emit();
  }

  protected _init() {
    return Promise.resolve();
  }

  protected _afterInit() {
    return Promise.resolve();
  }

  protected _initStep(step) {
    if (this[`initStep${step}`]) {
      this[`initStep${step}`]();
    }

    return Promise.resolve();
  }

  protected _isCurrentStepValid() {
    let state = true;

    if (this[`isStepValid${this.currentStep}`]) {
      state = this[`isStepValid${this.currentStep}`]();
    }

    return Promise.resolve(state);
  }

}
