// program reducer

import * as userProgramActions from '../actions/user-program.actions';

export interface UserProgramState {
  updating: boolean;
}

export const initialUserProgramState: UserProgramState = {
  updating: false
};

export function userProgramReducer(
  state = initialUserProgramState,
  action: userProgramActions.UserProgramActions
): UserProgramState {
  switch (action.type) {
    case userProgramActions.UPDATE_USER_PROGRAM:
    case userProgramActions.UPDATE_USER_PROGRAM_LANGUAGE:
    case userProgramActions.UPDATE_USER_PROGRAM_SMOKING_TYPE: {
      return {
        ...state,
        updating: true
      };
    }
    case userProgramActions.UPDATE_USER_PROGRAM_SUCCESS:
    case userProgramActions.UPDATE_USER_PROGRAM_FAIL:
    case userProgramActions.UPDATE_USER_PROGRAM_LANGUAGE_SUCCESS:
    case userProgramActions.UPDATE_USER_PROGRAM_LANGUAGE_FAIL:
    case userProgramActions.UPDATE_USER_PROGRAM_SMOKING_TYPE_SUCCESS:
    case userProgramActions.UPDATE_USER_PROGRAM_SMOKING_TYPE_FAIL: {
      return {
        ...state,
        updating: false
      };
    }
  }

  return state;
}

export const userProgramUpdating = (state: UserProgramState) => state && state.updating ? true : false;
