<h4 class="title" [translate]="smokingTypeService.isCigarette() ? 'tracker.today_i_smoked' : 'tracker.today_i_vaped'"></h4>

<div class="count-container">
  <ion-button icon-only fill="outline" (click)="changeCigCount(-1)">
    <ion-icon name="remove-circle"></ion-icon>
  </ion-button>

  <div class="count">{{cigCount}}</div>

  <ion-button icon-only fill="outline" (click)="changeCigCount(1)">
    <ion-icon name="add-circle"></ion-icon>
  </ion-button>
</div>

<p class="left-today" *ngIf="!(alreadyQuit$ | async)">
  <span translate="tracker.i_have"></span>
  <b> {{getLeftCig()}} </b>
  <span [translate]="smokingTypeService.isCigarette() ? 'tracker.cigarettes_left_today' : 'tracker.puffs_left_today'"></span>
</p>

<div class="btn">
  <cl-action-button
    *ngIf="!isSmokeFree && !(alreadyQuit$ | async)"
    label="{{ (smokingTypeService.isCigarette() ? 'tracker.smokefree_today_question' : 'tracker.vapefree_today_question') | translate }}"
    [canClick]="true"
    (action)="changeCigCount(-cigCount)">
  </cl-action-button>

  <cl-action-button
    *ngIf="isSmokeFree || (alreadyQuit$ | async)"
    icon="checkmark-circle-outline"
    [canClick]="true"
    label="{{ (smokingTypeService.isCigarette() ? 'tracker.smokefree_today' : 'tracker.vapefree_today') | translate }}"
    color="success"
  ></cl-action-button>
</div>
