// sync selectors

import { createSelector } from '@ngrx/store';
import * as downloadReducer from '../reducers/download.reducer';
import { getDownloadedMedia } from '../../persistent/media/media.selectors';
import { getDownloadState } from '../../../../app/store';
import { getProgramModules } from '../../normalized/selectors/program-bootstrap.selectors';
import { getNormalizedMediaFiles, getMediaFilesByModule } from '../../normalized/selectors/mediafiles.selectors';
import {
  getAvailableBonusExercisesIds,
  getMediaFilesByBonusExercise
} from '../../../../app/store/normalized/selectors/exercises.selectors';

export const getDownloadQueue = createSelector(
  getDownloadState,
  downloadReducer.downloadQueue
);

export const getCurrentDownloadMediaFile = createSelector(
  getDownloadState,
  downloadReducer.currentMediaFile
);

export const getQueuedMediaFiles = createSelector(
  getDownloadQueue,
  getNormalizedMediaFiles,
  (queue, normalizedMediaFiles) => queue.map((id) => normalizedMediaFiles[id])
);

export const isDownloading = createSelector(
  getDownloadState,
  downloadReducer.downloading
);

export const getOfflineStorageStatus = createSelector(
  getDownloadState,
  downloadReducer.offlineStorageStatus
);

export const getDownloadedCoreModules = createSelector(
  getDownloadedMedia,
  getProgramModules,
  getMediaFilesByModule,
  ((downloadedMedia, modules, mediaByModule) => {
    const downloadedCoreModules = [];
    if (!modules || !modules.length) {
      return downloadedCoreModules;
    }

    for (let i = 0; i < modules.length; i++) {
      if (arrayContainsArray(downloadedMedia, mediaByModule[modules[i].number])) {
        downloadedCoreModules.push(modules[i].number);
      }
    }

    return downloadedCoreModules;
  })
);

export const getDownloadedBonusExercises = createSelector(
  getDownloadedMedia,
  getProgramModules,
  getAvailableBonusExercisesIds,
  getMediaFilesByBonusExercise,
  ((downloadedMedia, modules, bonusExercisesIds, mediaByExercise) => {
    const downloadedBonusExercises = [];
    if (!modules || !modules.length) {
      return downloadedBonusExercises;
    }

    for (let i = 0; i < bonusExercisesIds.length; i++) {
      if (arrayContainsArray(downloadedMedia, mediaByExercise[bonusExercisesIds[i]])) {
        downloadedBonusExercises.push('e' + bonusExercisesIds[i]);
      }
    }

    return downloadedBonusExercises;
  })
);

export const getDownloadedModules = createSelector(
  getDownloadedCoreModules,
  getDownloadedBonusExercises,
  ((downloadedCoreModules, downloadedBonusExercises) => downloadedCoreModules.concat(downloadedBonusExercises)));

function arrayContainsArray(superset, subset) {
  return subset && subset.length && subset.every((value) => (superset.indexOf(value) >= 0));
}
