import { Action } from '@ngrx/store';
import { Checkin } from '../models/checkin.model';
import { CravingTool, SmokeCravingTool } from '../../normalized/schemas/craving-tool.schema';
import { StressMeter } from '../models/stress-meter.model';
import { StressTest } from '../models/stress-test.model';
import { WorryTool } from '../models/worry-tool.model';

// checkins
export const SAVE_CHECKIN = '[tools] Save Checkin';
export const TRACK_CHECKIN = '[tools] Track Checkin';

export const SAVE_NIGHT_REFLECTION = '[tools] Save Night Reflection';
export const SAVE_MORNING_MOTIVATION = '[tools] Save Morning Motivation';

// stress tests
export const COMPLETE_STRESS_TEST = '[tools] Complete Stress Test';
export const SAVE_STRESS_TEST = '[tools] Save Stress Test';
export const TRACK_STRESS_TEST = '[tools] Track Stress Test';
export const STOP_STRESS_TEST = '[tools] Stop Stress Test';

// skip intro
export const TRACK_SKIP_INTRO = '[tools] Track Skip Intro';

// stress meters
export const SAVE_STRESS_METER = '[tools] Save Stress Meter';
export const TRACK_STRESS_METER = '[tools] Track Stress Meter';
export const START_STRESS_METER = '[tools] Start Stress Meter';
export const STOP_STRESS_METER = '[tools] Stop Stress Meter';
export const RETURN_STRESS_METER = '[tools] Return Stress Meter';

export const SAVE_CRAVING_TOOL = '[tools] Save Craving Tool';
export const SAVE_SMOKING_CRAVING_TOOL = '[tools] Save Smoking Craving Tool';
export const SCHEDULE_CRAVING_TOOL = '[tools] Schedule Craving Tool';
export const CLEAR_CRAVING_TOOL_NOTIFICATIONS = '[tools] Clear Craving Tool Notifications';
export const TRACK_CRAVING_TOOL = '[tools] Track Craving Tool';
export const TRACK_MY_COACH = '[tools] Track My Coach';
export const TRACK_SMOKING_CRAVING_TOOL = '[tools] Track Smoking Craving Tool';

export const TRACK_AWARENESS_QUIZ = '[tools] Track Self Assessment Part 1';

export const TRACK_BREATHE_EXERCISE = '[tools] Track Breathe Exercise';

export const WORRY_TOOL_COMPLETED = '[tools] Worry Tool Completed';

export class SaveCheckin implements Action {
  readonly type = SAVE_CHECKIN;

  constructor(public payload: Checkin) {
  }
}

export class TrackCheckin implements Action {
  readonly type = TRACK_CHECKIN;

  constructor(public payload: Checkin, public checkinType: 'checkin' | 'night-reflection' | 'morning-motivation') {
  }
}

export class SaveNightReflection implements Action {
  readonly type = SAVE_NIGHT_REFLECTION;

  constructor(public payload: Checkin) {
  }
}

export class SaveMorningMotivation implements Action {
  readonly type = SAVE_MORNING_MOTIVATION;

  constructor(public payload: Checkin) {
  }
}

export class CompleteStressTest implements Action {
  readonly type = COMPLETE_STRESS_TEST;

  constructor(public payload: StressTest) {
  }
}

export class SaveStressTest implements Action {
  readonly type = SAVE_STRESS_TEST;

  constructor(public payload: StressTest) {
  }
}

export class TrackStressTest implements Action {
  readonly type = TRACK_STRESS_TEST;

  constructor(public payload: StressTest) {
  }
}

export class SaveStressMeter implements Action {
  readonly type = SAVE_STRESS_METER;

  constructor(public payload: StressMeter) {
  }
}

export class TrackStressMeter implements Action {
  readonly type = TRACK_STRESS_METER;

  constructor(public payload: StressMeter) {
  }
}

export class SaveCravingTool implements Action {
  readonly type = SAVE_CRAVING_TOOL;

  constructor(public payload: CravingTool, public options: {
    silent?: boolean;
  }) {
  }

}

export class SaveSmokingCravingTool implements Action {
  readonly type = SAVE_SMOKING_CRAVING_TOOL;

  constructor(public payload: SmokeCravingTool) {
  }

}

export class ScheduleCravingTool implements Action {
  readonly type = SCHEDULE_CRAVING_TOOL;

  constructor(public payload: CravingTool, public options: {
    inMinutes?: number;
  }) {
  }

}

export class ClearCravingToolNotifications implements Action {
  readonly type = CLEAR_CRAVING_TOOL_NOTIFICATIONS;
}

export class TrackCravingTool implements Action {
  readonly type = TRACK_CRAVING_TOOL;

  constructor(public payload: any, public done = false) {
  }
}

export class TrackSmokingCravingTool implements Action {
  readonly type = TRACK_SMOKING_CRAVING_TOOL;

  constructor(public payload: any) {
  }
}

export class StartStressMeter implements Action {
  readonly type = START_STRESS_METER;
}

export class StopStressMeter implements Action {
  readonly type = STOP_STRESS_METER;
}

export class StopStressTest implements Action {
  readonly type = STOP_STRESS_TEST;
}

export class ReturnStressMeter implements Action {
  readonly type = RETURN_STRESS_METER;

  constructor(public payload: number) {
  }
}

export class TrackAwarenessQuiz implements Action {
  readonly type = TRACK_AWARENESS_QUIZ;

  constructor(public payload: any) {
  }
}

export class TrackBreatheExercise implements Action {
  readonly type = TRACK_BREATHE_EXERCISE;

  constructor(public payload: any) {
  }
}

export class WorryToolCompleted implements Action {
  readonly type = WORRY_TOOL_COMPLETED;

  constructor(public payload: WorryTool) {}
}

/**
 * @deprecated
 *
 * this shouldn't be here. Should probably be inside FlagsAction or somewhere else
 */
export class TrackSkipIntro implements Action {
  readonly type = TRACK_SKIP_INTRO;
}

export type ToolsActions =
  | SaveCheckin
  | TrackCheckin
  | SaveNightReflection
  | SaveMorningMotivation
  | SaveStressTest
  | CompleteStressTest
  | TrackStressTest
  | SaveStressMeter
  | TrackStressMeter
  | ReturnStressMeter
  | StartStressMeter
  | SaveCravingTool
  | SaveSmokingCravingTool
  | TrackCravingTool
  | TrackSmokingCravingTool
  | TrackAwarenessQuiz
  | TrackBreatheExercise
  | TrackSkipIntro
  | StopStressMeter
  | StopStressTest
  | WorryToolCompleted;
