import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/state.reducer';
import {
  getLikeFailPost,
  getLikePostLoading,
  getNewLikeOnPost,
  getPostById
} from '../../store/session/selectors/social.selectors';
import { merge, Observable } from 'rxjs';
import { Comment, Post } from '../../store/normalized/schemas/social.schema';
import {
  deletedCommentId,
  failToggleLikeComment,
  getAllCommentsLoaded,
  getAllCommentsLoading,
  getCommentSending,
  getCommentSent,
  getCommentsFromFeed,
  getCommentsLoaded,
  getCommentsLoading,
  getCommentsTotalCountFromFeed,
  getLikeCommentLoading,
  getNewComment,
  getNewLikeOnComment,
  getPostLoading
} from '../../store/session/selectors/post.selectors';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CleanCurrentCommentFeed,
  ClosePost,
  DeleteComment,
  LoadAllComments,
  ReloadPost,
  ResetLikeFailComment,
  ResetNewCommentLike,
  SaveComment,
  ToggleLikeComment
} from '../../store/session/actions/post.actions';
import { AlertController, IonContent } from '@ionic/angular';
import { CloseModal, OpenModal } from '../../store/session/actions/navigation.actions';
import { OpenPostById, ResetLikeFailPost, ToggleLikePost } from 'src/app/store/session/actions/social.actions';
import { getCurrentProfileId, isUserProfileComplete } from '../../store/normalized/selectors/user-profile.selectors';
import { filter, first } from 'rxjs/operators';
import { SocialService } from '../../services/social.service';
import { ClarityConfig } from '../../config/clarity.config';
import { ConnectivityService } from '../../services/connectivity.service';
import { getResumedAt } from '../../store/session/selectors/sync.selectors';
import { DeletePost } from '../../store/session/actions/social.actions';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-post',
  templateUrl: './post.html',
  styleUrls: ['./post.scss']
})
export class PostPage implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('commentFormWrapper') formWrapper: ElementRef;

  modal;

  postId;
  commentForm: FormGroup;
  textAreaHasFocus = false;
  localComments: Comment[] = [];
  canLoadAllComments = false;
  remainingComments = 0;

  post$: Observable<Post> = this.store.select(getPostById);
  post = null;
  commentsTotalCount$: Observable<number> = this.store.select(getCommentsTotalCountFromFeed);
  commentsTotalCount: number;
  comments$: Observable<Comment[]> = this.store.select(getCommentsFromFeed);
  postLoading$: Observable<boolean> = this.store.select(getPostLoading);
  commentsLoading$: Observable<boolean> = this.store.select(getCommentsLoading);
  commentsLoaded$: Observable<boolean> = this.store.select(getCommentsLoaded);
  allCommentsLoading$: Observable<boolean> = this.store.select(getAllCommentsLoading);
  allCommentsLoaded$: Observable<boolean> = this.store.select(getAllCommentsLoaded);
  commentSending$: Observable<boolean> = this.store.select(getCommentSending);
  commentSent$: Observable<boolean> = this.store.select(getCommentSent);
  isUserProfileComplete$: Observable<boolean> = this.store.select(isUserProfileComplete);
  currentUserProfileId$: Observable<string> = this.store.select(getCurrentProfileId);
  likePostLoading$: Observable<boolean> = this.store.select(getLikePostLoading);
  likePostLoading = false;
  likeCommentLoading$: Observable<boolean> = this.store.select(getLikeCommentLoading);
  likeCommentLoading = false;
  deletedCommentId$: Observable<string> = this.store.select(deletedCommentId);
  failToggleLikeComment$: Observable<string> = this.store.select(failToggleLikeComment);
  likeFailPost$: Observable<string> = this.store.select(getLikeFailPost);
  postTitle$: Observable<string>;
  newLikeOnComment$: Observable<any> = this.store.select(getNewLikeOnComment);
  newComment$: Observable<any> = this.store.select(getNewComment);
  newLikeOnPost$: Observable<any> = this.store.select(getNewLikeOnPost);
  resumedAt$: Observable<number> = this.store.select(getResumedAt);

  private subscriptions = [];

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private alertCtrl: AlertController,
    private socialService: SocialService,
    private connnectivity: ConnectivityService,
    public store: Store<State>,
    public config: ClarityConfig,
    private changeDetector: ChangeDetectorRef
  ) {
    this.commentForm = this.formBuilder.group({
      content: ['', Validators.required]
    });
  }

  @HostListener('document:click', ['$event'])
  andClickEvent(event) {
    if (this.formWrapper && !this.formWrapper.nativeElement.contains(event.target)) {
      this.textAreaHasFocus = false;
    }
  }

  // canLoadAllComments() {
  //   debugger;
  //   return this.localComments.length < this.commentsTotalCount;
  // }
  //
  // get remainingComments() {
  //   return this.commentsTotalCount - this.localComments.length;
  // }

  openOtherPost(event) {
    this.modal.onDidDismiss()
      .then(() => {
        setTimeout(() => {
          this.store.dispatch(new OpenPostById(event.id));
        });
      });
    this.modal.dismiss();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.post$
      .subscribe(post => {
        if (post) {
          this.post = {
            ...post,
            likes: {
              ...post.likes,
              list: post.likes.list.slice()
            }
          };

          this.changeDetector.detectChanges();
        }
      })
    );

    this.subscriptions.push(this.likePostLoading$
      .subscribe(likePostLoading => {
        this.likePostLoading = likePostLoading;

        this.changeDetector.detectChanges();
      }));

    this.subscriptions.push(this.likeCommentLoading$
      .subscribe(likeCommentLoading => {
        this.likeCommentLoading = likeCommentLoading;

        this.changeDetector.detectChanges();
      }));

    this.subscriptions.push(this.post$.subscribe(post => {
      if (!post) return;

      this.postId = post.id;
      this.postTitle$ = this.translateService.get('social.posts.users_post', {username: post.username});

      this.changeDetector.detectChanges();
    }));

    this.subscriptions.push(this.likeFailPost$
      .subscribe(post => {
        if (!post) {
          return;
        }

        this.socialService.toggleLike(this.post);
        this.store.dispatch(new ResetLikeFailPost());

        this.changeDetector.detectChanges();
      }));

    this.subscriptions.push(this.commentSent$
      .subscribe(async sent => {
        if (sent) {
          this.textAreaHasFocus = false;

          setTimeout(async () => {
            await this.content.scrollToBottom(1000);
          });

          this.resetForm();
          this.changeDetector.detectChanges();
        }
      }));

    // refresh local comments list after loading
    this.subscriptions.push(merge(
      this.commentsLoaded$,
      this.allCommentsLoaded$,
      this.commentSent$
    )
      .pipe(filter(done => done))
      .subscribe(() => {
        this.refreshLocalComments();
      }));

    // update comments total count
    this.subscriptions.push(this.commentsTotalCount$.subscribe((commentsTotalcount) => {
      this.commentsTotalCount = commentsTotalcount;
      this.checkIfMoreComments();

      this.changeDetector.detectChanges();
    }));

    // remove local comment after deletion
    this.subscriptions.push(this.deletedCommentId$.subscribe((commentId) => {
      if (this.localComments && commentId) {
        this.localComments.splice(this.localComments.findIndex(comment => comment.id === commentId), 1);

        this.changeDetector.detectChanges();
      }
    }));

    // toggle like comment back if like failed
    this.subscriptions.push(this.failToggleLikeComment$.subscribe((commentId) => {
      if (!commentId) {
        return;
      }

      this.socialService.toggleLike(this.localComments.find(c => c.id === commentId));
      this.store.dispatch(new ResetLikeFailComment());
      this.refreshLocalComments();
      this.changeDetector.detectChanges();
    }));

    this.subscriptions.push(this.newLikeOnComment$.subscribe(params => {
      if (!params) return;
      const commentIndex = this.localComments.findIndex(comment => params.comment_id === comment.id);
      if (commentIndex !== -1) {
        this.localComments.splice(commentIndex, 1, {
          ...this.localComments[commentIndex],
          likes: {
            list: params.avatars.map(avatar => ({ avatar, profile_id: null, id: null })),
            total_count: params.likes_count
          }
        } as Comment);
        this.store.dispatch(new ResetNewCommentLike());
        this.changeDetector.detectChanges();
      }
    }));

    this.subscriptions.push(
      this.newComment$.pipe(filter(comment => comment !== null))
        .subscribe((comment) => {
          comment.isNew = true;

          setTimeout(async () => {
            await this.content.scrollToBottom(1000);
          });

          this.refreshLocalComments();

          this.changeDetector.detectChanges();
        })
    );

    this.subscriptions.push(
      this.newLikeOnPost$.pipe(filter(params => params !== null))
        .subscribe(params => {

          if (this.postId !== params.post_id) {
            return;
          }

          this.post = {
            ...this.post,
            likes: {
              list: params.avatars.map(avatar => ({
                avatar,
                profile_id: null,
                id: null
              })),
              total_count: params.likes_count
            }
          };

          this.changeDetector.detectChanges();
        })
    );

    this.subscriptions.push(this.resumedAt$.subscribe(resumedAt => {
      const currentTimestamp = new Date().getTime();

      if (resumedAt && (currentTimestamp - resumedAt) <= 500) {
        this.store.dispatch(new ReloadPost(this.postId));
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadAllComments() {
    if (!this.postId) return;

    this.store.dispatch(new LoadAllComments(this.postId));
  }

  addComment() {
    if (this.connnectivity.preventAccessWhenOffline()) {
      return;
    }

    this.store.dispatch(new SaveComment({...this.commentForm.value, postId: this.postId}));
  }

  onCommentFocus() {
    this.textAreaHasFocus = true;
  }

  close() {
    this.store.dispatch(new CleanCurrentCommentFeed());
    this.store.dispatch(new ClosePost());
    this.store.dispatch(new CloseModal({modalId: this.modal.id}));
  }

  async onCloseClick() {
    if (this.commentForm.value.content) {
      if (await this.showUnsavedChangesAlert()) {
        this.commentForm.reset();
        this.close();
      }
    } else {
      this.close();
    }
  }

  showUnsavedChangesAlert() {
    return new Promise((resolve, reject) => {
      this.translateService.get([
        'common.post_unsaved',
        'common.post_unsaved_data_close',
        'common.no',
        'common.yes'
      ])
        .subscribe(async (translations) => {
          const alert = await this.alertCtrl.create({
            header: translations['common.post_unsaved'],
            message: translations['common.post_unsaved_data_close'],
            buttons: [
              {
                text: translations['common.yes'],
                handler: () => {
                  alert.dismiss(true);
                  resolve(true);

                  return false;
                }
              },
              {
                text: translations['common.no'],
                handler: () => {
                  alert.dismiss(false);
                  resolve(false);

                  return false;
                }
              }
            ]
          });

          await alert.present();
        });
    });
  }

  resetForm() {
    this.commentForm.reset();
  }

  openIncompleteProfilePopup() {
    this.store.dispatch(new OpenModal('ProfileCompletionPage'));
  }

  onLikePostClick(post: Post) {
    if (this.likePostLoading || this.connnectivity.preventAccessWhenOffline()) {
      return;
    }

    this.socialService.toggleLike(post);
    this.store.dispatch(new ToggleLikePost(post.id));
  }

  onLikeCommentClick(comment: Comment) {
    if (this.likeCommentLoading || this.connnectivity.preventAccessWhenOffline()) {
      return;
    }

    this.socialService.toggleLike(comment);
    this.store.dispatch(new ToggleLikeComment(comment.id));
  }

  onDeleteClick(event) {
    if (this.connnectivity.preventAccessWhenOffline()) {
      return;
    }

    if (event.post_id) {
      this.store.dispatch(new DeleteComment({commentId: event.id, postId: event.post_id}));
    } else {
      this.store.dispatch(new DeletePost(event.id));
      this.close();
    }
  }

  onProfileOpen() {
    this.store.dispatch(new CloseModal({modalId: this.modal.id}));
  }

  private checkIfMoreComments() {
    if (!this.localComments) return;

    this.remainingComments = this.commentsTotalCount - this.localComments.length;
    this.canLoadAllComments = this.remainingComments > 0;
  }

  private refreshLocalComments() {
    this.comments$.pipe(first())
      .subscribe((comments) => {
        this.localComments = comments;
        this.checkIfMoreComments();

        this.changeDetector.detectChanges();
      });
  }
}
