import { Platform } from '@ionic/angular';

export class PlatformReady {
  public promise: Promise<Platform>;

  private _resolve: any;
  private _reject: any;

  constructor() {
    console.log('init platform ready');
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  resolve(value: Platform) {
    this._resolve(value);
  }

  reject(error: any) {
    this._reject(error);
  }
}

export const platformReady = new PlatformReady();
