import { format, parseISO } from 'date-fns';

/**
 * The input is provided in the event
 * payload from the `ionChange` event.
 *
 * The value is an ISO-8601 date string.
 */
export function dateFormatter(date: string): string {
  return format(parseISO(date), 'yyyy-MM-dd');
}
