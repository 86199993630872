<ion-row class="step step1 lateral-padding"
         *ngIf="(currentStep === 1 && config.program.programCode === 'ern') ||
         (currentStep === 2 && config.program.programCode === 'ctq')">
  <ion-col class="ion-text-center">
    <h1 class="step-intro">{{'wizards.how_did_you_do_today' | translate}}</h1>
    <h3>{{'wizards.night_reflection_title' | translate}}</h3>
  </ion-col>
</ion-row>

<ion-row class="step step1 lateral-padding" *ngIf="currentStep === 1 && config.program.programCode === 'ua'">
  <ion-col class="ion-text-center">
    <h1 class="step-intro">{{'wizards.how_are_you_feeling' | translate}}</h1>
    <cl-list-single-select
      [items]="feelings"
      [selected]="feelingChoice"
      (selectItem)="onFeelingSelected($event)">
    </cl-list-single-select>
  </ion-col>
</ion-row>

<ion-row class="step step1 lateral-padding" *ngIf="currentStep === 1 && config.program.programCode === 'ctq'">
  <ion-col class="ion-text-center">
    <h4 class="step-intro ctq-title">

      <ng-container *ngIf="smokingType === 'cigarette'; else youHaveVaped">
        {{'wizards.the_tracker_says_you_have_smoked' | translate}}
        <span class="cig-count">{{cigCount}}</span>
        {{(cigCount === 1 ? 'wizards.cigarette' : 'wizards.cigarettes') | translate}}
      </ng-container>

      <ng-template #youHaveVaped>
        {{'wizards.the_tracker_says_you_have_vaped' | translate}}
        <span class="cig-count">{{cigCount}}</span>
        {{(cigCount === 1 ? 'wizards.puff' : 'wizards.puffs') | translate}}
      </ng-template>

      {{'wizards.adjust_your_tracker_below_if_needed' | translate}}

    </h4>
    <h4 class="step-intro ctq-title">{{'wizards.this_helps_bring_awareness' | translate}}</h4>

    <cl-cig-tracker></cl-cig-tracker>
  </ion-col>
</ion-row>

<ion-row class="step step2 lateral-padding"
         *ngIf="(currentStep === 2 && config.program.programCode === 'ern') ||
            (currentStep === 3 && config.program.programCode === 'ctq')">
  <ion-col class="ion-text-center">
    <h1 class="step-intro">{{'wizards.good_night' | translate}}</h1>
    <h3>
      <ng-container *ngIf="config.program.programCode !== 'ctq'; else ctqTimeToWrite">
        {{'wizards.great_time_to_write' | translate}}
      </ng-container>

      <ng-template #ctqTimeToWrite>
          {{'wizards.ctq_great_time_to_write' | translate}}
      </ng-template>
    </h3>

    <cl-action-button
      *ngIf="config.program.programCode !== 'ctq'"
      class="journal-btn"
      label="{{'community.open_journal' | translate}}"
      [square]="true"
      [fullWidth]="true"
      [canClick]="true" (action)="openJournal()">
    </cl-action-button>

    <exercises-group
      [exercises]="exercises$ | async"
      (playExercise)="playExercise($event)"
      [isWizard]="true"
      [isLive]="true">
    </exercises-group>
  </ion-col>
</ion-row>

<ion-row class="step step2 lateral-padding" *ngIf="currentStep === 2 && config.program.programCode === 'ua'">
  <ion-col class="ion-text-center">
    <h1 class="step-intro">{{'wizards.how_anxious_are_you_feeling_right_now' | translate}}</h1>
    <cl-anxious-feeling (valueChanged)="setAnxiousFeelingChoice($event)" [sliderValue]="anxiousFeeling">
    </cl-anxious-feeling>
  </ion-col>
</ion-row>

<ion-row class="step step3 lateral-padding"
         *ngIf="(currentStep === 3 && config.program.programCode === 'ern') ||
            (currentStep === 4 && config.program.programCode === 'ctq')">
  <ion-col class="ion-text-center">
    <cl-play-exercise
      [isWizard]="true"
      [exercise]="exerciseToPlay">
    </cl-play-exercise>
  </ion-col>
</ion-row>

<ion-row class="step step3 lateral-padding" *ngIf="currentStep === 3 && config.program.programCode === 'ua'">
  <ion-col class="ion-text-center">
    <h1 class="step-intro">{{'wizards.how_was_your_day' | translate}}</h1>
    <p class="reflection-instructions">{{'wizards.take_a_moment_to_reflect' | translate}}</p>
  </ion-col>
</ion-row>
