/**
 * @author WoltersKluwerPL <https://github.com/WoltersKluwerPL/ng-spin-kit>
 * @license MIT
 * @overview
 *
 * SpinKit (http://tobiasahlin.com/spinkit/) spinners for Angular.
 *
 * Copied directly from ng-spin-kit npm package because it looses support to angular 9,
 * and we only use one spinner of it
 */

import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'cl-spinner',
  template: ''
})
export class SpinnerComponent implements OnDestroy {
  public visible = true;
  public timeout: any;
  public baseClass = 'chasing-dots-spinner';
  public childClass = 'dot';
  public numItems = 2;

  @Input()
  public delay = 0;

  @Input()
  public color = '#333';

  @Input()
  public set isRunning(value: boolean) {
    if (!value) {
      this.cancel();
      this.visible = false;

      return;
    }

    if (this.timeout) {
      return;
    }

    this.timeout = setTimeout(() => {
      this.visible = true;
      this.cancel();
    }, this.delay);
  }

  private cancel(): void {
    clearTimeout(this.timeout);
    this.timeout = undefined;
  }

  public get items() {
    return Array(this.numItems);
  }

  ngOnDestroy(): any {
    this.cancel();
  }
}

export const spinnerTemplate = `
  <div [hidden]="!visible" [ngClass]="baseClass">
      <div *ngFor="let item of items; let i = index" [ngClass]="childClass + (i+1)" [style.backgroundColor]="color"></div>
  </div>
`;
