import * as notificationActions from '../actions/notifications.actions';

export interface NotificationState {
  notificationsLoading: boolean;
  notificationsLoaded: boolean;
  allNotificationsLoading: boolean;
  allNotificationsLoaded: boolean;
  unreadNotificationsCommunity: number;
  unreadNotificationsMyCoach: number;
  unreadAllNotifications: number;
}

export const initialNotificationState: NotificationState = {
  notificationsLoading: false,
  notificationsLoaded: false,
  allNotificationsLoaded: false,
  allNotificationsLoading: false,
  unreadNotificationsCommunity: null,
  unreadNotificationsMyCoach: null,
  unreadAllNotifications: null
};

export function notificationReducer(state = initialNotificationState, action: notificationActions.NotificationsActions): NotificationState {
  switch (action.type) {
    case notificationActions.MARK_COACH_NOTIFICATIONS_AS_READ_SUCCESS: {
      return {
        ...state,
        unreadNotificationsMyCoach: 0,
        unreadAllNotifications: state.unreadNotificationsCommunity
      };
    }
    case notificationActions.MARK_COMMUNITY_NOTIFICATIONS_AS_READ_SUCCESS: {
      return {
        ...state,
        unreadNotificationsCommunity: 0,
        unreadAllNotifications: state.unreadNotificationsMyCoach
      };
    }
    case notificationActions.LOAD_NOTIFICATIONS: {
      return {
        ...state,
        notificationsLoading: true,
        notificationsLoaded: false,
        allNotificationsLoaded: false,
        allNotificationsLoading: false
      };
    }

    case notificationActions.LOAD_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notificationsLoading: false,
        notificationsLoaded: true,
        allNotificationsLoaded: false,
        allNotificationsLoading: false
      };
    }

    case notificationActions.LOAD_NOTIFICATIONS_FAILURE:
    case notificationActions.LOAD_ALL_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        notificationsLoading: false,
        notificationsLoaded: false,
        allNotificationsLoaded: false,
        allNotificationsLoading: false
      };
    }

    case notificationActions.LOAD_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notificationsLoading: false,
        notificationsLoaded: false,
        allNotificationsLoaded: false,
        allNotificationsLoading: true
      };
    }

    case notificationActions.LOAD_ALL_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notificationsLoading: false,
        notificationsLoaded: false,
        allNotificationsLoaded: true,
        allNotificationsLoading: false
      };
    }
  }

  return state;
}

export const notificationsAreLoading = (state: NotificationState) => state.notificationsLoading;
export const notificationsAreLoaded = (state: NotificationState) => state.notificationsLoaded;
export const allNotificationsAreLoading = (state: NotificationState) => state.allNotificationsLoading;
export const allNotificationsAreLoaded = (state: NotificationState) => state.allNotificationsLoaded;
export const unreadNotificationsCommunity = (state: NotificationState) => state.unreadNotificationsCommunity;
export const unreadAllNotifications = (state: NotificationState) => state.unreadAllNotifications;
export const unreadCoachNotifications = (state: NotificationState) => state.unreadNotificationsMyCoach;
export const notificationFeedId = () => 'current-notification-feed';
