import { Injectable } from '@angular/core';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { LoggerService } from 'src/app/services/logger.service';
import { first, tap } from 'rxjs/operators';
import { ScriptLoaderService, ScriptModel } from 'src/app/services/script-loader.service';

export enum GtmCustomEvent {
  UserSignup = 'user-signup',
  SignupTracking = 'signup-tracking',
  SubscriptionPurchased = 'subscription-purchased',
  SubscriptionLicensed = 'subscription-licensed',
  SubscriptionCanceled = 'subscription-canceled'
}

@Injectable({providedIn: 'root'})
export class GtmService {

  public isLoaded = false;

  private resolveScriptLoad: any;
  private rejectScriptLoad: any;

  // keep track of GTM setup to delay events tracking
  private readyPromise: Promise<any> = new Promise((resolve, reject) => {
    this.resolveScriptLoad = resolve;
    this.rejectScriptLoad = reject;
  });

  constructor(
    private logger: LoggerService,
    private config: ClarityConfig,
    private scriptLoader: ScriptLoaderService
  ) {
  }

  public initialize(): Promise<ScriptModel> {
    this.logger.debug('Initializing Google Tag Manager');

    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });

    let containerId = this.config.program.gtm.containerId;

    // TODO: Refactor this to a better implementation, maybe use one container ID per program? per env?
    if (!this.config.onProd()) {
      containerId = 'GTM-KXZC2C7'; // development container
    }

    return this.scriptLoader.load({
      src: `https://www.googletagmanager.com/gtm.js?id=${containerId}&l=dataLayer`,
      async: true
    })
      .pipe(
        first(),
        tap(() => this.isLoaded = true),
        tap(() => this.resolveScriptLoad())
      )
      .toPromise()
      .catch((error) => {
        this.rejectScriptLoad();

        return error;
      });
  }

  public triggerEvent(event: string, data = {}) {
    this.readyPromise.then(() => {
      if (!this.config.gtmEnabled() || !this.isLoaded) {
        return false;
      }

      window['dataLayer'].push({
        event,
        ...data
      });

    });
  }
}
