import { Injectable } from '@angular/core';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddData, RemoveData } from 'ngrx-normalizr';

import * as userFavsActions from '../actions/user-favorites.actions';
import { LoadingService } from '../../../services/loading.service';
import { UserFavoritesProvider } from '../../../providers/user-favorites.provider';
import { TranslateService } from '@ngx-translate/core';
import { UserFavorite, userFavoriteSchema } from '../../normalized/schemas/user.schema';
import { UserFavoritesState } from '../reducers/user-favorites.reducer';
import { isUpdatingUserFavs } from '../selectors/user-favorites.selectors';
import { ToastService } from '../../../services/toast.service';
import { ConnectivityService } from '../../../services/connectivity.service';

@Injectable({providedIn: 'root'})
export class UserFavEffects {

  addUserFav$ = createEffect(() => this.actions$.pipe(ofType(userFavsActions.CREATE_USER_FAVORITE),
    map((action: userFavsActions.CreateUserFav) => action.payload),
    tap(() => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUpdatingUserFavs),
        this.translate.get('common.adding_favorite')
      );
    }),
    switchMap((userFav) => this.userFavProvider.createUserFavorite(userFav)
      .pipe(
        map((userFavorite: UserFavorite) => {
          this.normalizedStore.dispatch(new AddData<UserFavorite>({
            data: [userFavorite],
            schema: userFavoriteSchema
          }));

          return new userFavsActions.CreateUserFavSuccess(userFavorite);
        }),
        catchError((error) => of(new userFavsActions.CreateUserFavFail(error)))
      ))
  ));


  addUserFavSuccess$ = createEffect(() => this.actions$.pipe(ofType(userFavsActions.CREATE_USER_FAVORITE_SUCCESS),
    tap(() => {
      this.loadingService.hideLoadingOverlay();
    })
  ), {dispatch: false});

  
  addUserFavFail$ = createEffect(() => this.actions$.pipe(ofType(userFavsActions.CREATE_USER_FAVORITE_FAIL),
    tap((error: any) => {
      if (error.payload.status === 422) {
        this.toastService.error(this.translate.get('errors.favorites.already_exist'));
      } else {
        this.toastService.error(this.translate.get('errors.common.generic_error_please_retry'));
      }
    })
  ), {dispatch: false});

  
  loadUserFav$ = createEffect(() => this.actions$.pipe(ofType(userFavsActions.LOAD_USER_FAVORITES),
    switchMap(() => this.userFavProvider.loadUserFavorites()
      .pipe(
        map((userFav: UserFavorite) => {
          this.normalizedStore.dispatch(new AddData<UserFavorite>({
            data: [userFav],
            schema: userFavoriteSchema
          }));

          return new userFavsActions.LoadUserFavsSuccess();
        }),
        catchError((error) => of(new userFavsActions.LoadUserFavsFail(error)))
      ))
  ));

  deleteUserFav$ = createEffect(() => this.actions$.pipe(ofType(userFavsActions.DELETE_USER_FAVORITE),
    map((action: any) => action.payload),
    tap(() => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUpdatingUserFavs),
        this.translate.get('common.removing_favorite')
      );
    }),
    switchMap((userFavId) => this.userFavProvider.deleteUserFavorite(userFavId)
      .pipe(
        map(() => {
          this.store.dispatch(new RemoveData({
            id: `${userFavId}`,
            schema: userFavoriteSchema
          }));

          return new userFavsActions.DeleteUserFavsSuccess(userFavId);
        }),
        catchError((error) => of(new userFavsActions.DeleteUserFavsFail(error)))
      ))
  ));

  
  deleteUserFavSuccess$ = createEffect(() => this.actions$.pipe(ofType(userFavsActions.DELETE_USER_FAVORITE_SUCCESS),
    tap(() => {
      this.loadingService.hideLoadingOverlay();
    })
  ), {dispatch: false});

  
  deleteUserFavFail$ = createEffect(() => this.actions$.pipe(ofType(userFavsActions.DELETE_USER_FAVORITE_FAIL),
    tap(() => {
      if (this.connectivity.isOnline()) {
        this.toastService.translateError('errors.common.generic_error_please_retry');
      } else {
        this.toastService.translateError('errors.common.network_error');
      }
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<UserFavoritesState>,
    private normalizedStore: Store<UserFavorite>,
    private userFavProvider: UserFavoritesProvider,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private connectivity: ConnectivityService
  ) {}

}
