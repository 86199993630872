import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, timeout } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { HttpProvider, HttpRequestOptions } from 'src/app/providers/http/http.provider';

@Injectable({providedIn: 'root'})
export class AudioSubtitlesProvider {


  constructor(
    private http: HttpClient,
    private httpProvider: HttpProvider
  ) {
  }

  public loadSubtitle(src: string): Observable<any> {
    return this.get(src)
      .pipe(
        catchError((error) => {
          console.log('error retrieving audio subtitle', error);

          return observableThrowError(error);
        })
      );
  }

  private get<T>(endPoint: string, options?: HttpRequestOptions): Observable<any> {
    return this.http.get(endPoint, {responseType: 'text'})
      .pipe(
        timeout(HttpProvider.DEFAULT_CONNECTION_TIMEOUT),
        catchError((error) => this.httpProvider.handleTimeoutError(error))
      );
  }

  public post<T>(endPoint: string, params: object, options?: HttpRequestOptions): Observable<any> {
    return this.http.post<T>(endPoint, params, options)
      .pipe(
        timeout(HttpProvider.DEFAULT_CONNECTION_TIMEOUT),
        catchError((error) => this.httpProvider.handleTimeoutError(error))
      );
  }
}
