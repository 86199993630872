import { Injectable } from '@angular/core';

import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddData } from 'ngrx-normalizr';

import * as userProgramActions from '../actions/user-program.actions';
import * as syncActions from '../actions/sync.actions';
import { ToastService } from '../../../../app/services/toast.service';
import { LoadingService } from '../../../../app/services/loading.service';
import { UserProgramProvider } from '../../../../app/providers/user-program.provider';
import { TranslateService } from '@ngx-translate/core';
import { UserProgram, userProgramSchema } from '../../normalized/schemas/user.schema';
import { isUpdatingUserProgram } from '../selectors/user-program.selectors';
import { UserProgramState } from '../reducers/user-program.reducer';
import * as accountActions from '../actions/account.actions';

@Injectable({providedIn: 'root'})
export class UserProgramEffects {


  updateUserProgram$ = createEffect(() => this.actions$.pipe(ofType(userProgramActions.UPDATE_USER_PROGRAM),
    tap(() => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUpdatingUserProgram),
        this.translate.get('common.saving_settings')
      );
    }),
    map((action: userProgramActions.UpdateUserProgram) => action.payload),
    switchMap((userProgramPatch) => this.userProgramProvider.updateUserProgram(userProgramPatch)
      .pipe(
        map(() => new userProgramActions.UpdateUserProgramSuccess()),
        catchError(error => of(new userProgramActions.UpdateUserProgramFail(error)))
      ))
  ));


  updateUserProgramLanguage$ = createEffect(() => this.actions$.pipe(ofType(userProgramActions.UPDATE_USER_PROGRAM_LANGUAGE),
    tap(() => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUpdatingUserProgram),
        this.translate.get('common.saving_settings')
      );
    }),
    map((action: userProgramActions.UpdateUserProgramLanguage) => action.payload),
    switchMap((userProgramPatch) => this.userProgramProvider.updateUserProgram({language_code: userProgramPatch.language_code})
      .pipe(
        map(() => new userProgramActions.UpdateUserProgramLanguageSuccess(userProgramPatch.language_code)),
        catchError(error => of(new userProgramActions.UpdateUserProgramLanguageFail(error)))
      ))
  ));


  updateUserProgramSmokingType$ = createEffect(() => this.actions$.pipe(
    ofType(userProgramActions.UPDATE_USER_PROGRAM_SMOKING_TYPE),
    tap(() => {
      this.loadingService.useLoadingObservable(
        this.store.select(isUpdatingUserProgram),
        this.translate.get('common.saving_settings')
      );
    }),
    switchMap(({smokingType}: userProgramActions.UpdateUserProgramSmokingType) =>
      this.userProgramProvider.updateUserProgram({smoking_type: smokingType})
        .pipe(
          map(() => (new userProgramActions.UpdateUserProgramSmokingTypeSuccess())),
          catchError(() => of(new userProgramActions.UpdateUserProgramSmokingTypeFail()))
        ))
  ));


  updateUserProgramLanguageSuccess$ = createEffect(() => this.actions$.pipe(ofType(userProgramActions.UPDATE_USER_PROGRAM_LANGUAGE_SUCCESS),
    map((action: userProgramActions.UpdateUserProgramLanguageSuccess) =>
      // set new language
      this.translate.use(action.languageCode)
        .pipe(take(1))
        .toPromise()
        .then(() => {
          this.store.dispatch(new accountActions.SetSelectedSubMenu(null));
          if (!action.silent) {
            this.toastService.confirm(this.translate.get('common.saved'));
          }
          this.store.dispatch(new syncActions.SyncEverything());
        })
    )
  ), {dispatch: false});


  updateUserProgramSmokingTypeSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(userProgramActions.UPDATE_USER_PROGRAM_SMOKING_TYPE_SUCCESS),
    tap(() => this.store.dispatch(new syncActions.SyncEverything()))
  ), {dispatch: false});


  updateUserProgramLanguageFail$ = createEffect(() => this.actions$.pipe(
    ofType(
      userProgramActions.UPDATE_USER_PROGRAM_LANGUAGE_FAIL,
      userProgramActions.UPDATE_USER_PROGRAM_SMOKING_TYPE_FAIL
    ),
    map(() => {
      this.toastService.error(this.translate.get('errors.something_wrong'));
    })
  ), {dispatch: false});


  loadUserProgram$ = createEffect(() => this.actions$.pipe(ofType(userProgramActions.LOAD_USER_PROGRAM),
    switchMap(() => this.userProgramProvider.getUserProgram()
      .pipe(
        map((userProgram: UserProgram) => {
          this.normalizedStore.dispatch(new AddData<UserProgram>({
            data: [userProgram],
            schema: userProgramSchema
          }));

          return new userProgramActions.LoadUserProgramSuccess();
        }),
        catchError(error => of(new userProgramActions.LoadUserProgramFail(error)))
      ))
  ));


  updateUserProgramFail$ = createEffect(() => this.actions$.pipe(ofType(userProgramActions.UPDATE_USER_PROGRAM_FAIL),
    tap(() => {
      this.toastService.error(this.translate.get('errors.something_wrong'));
    })
  ), { dispatch: false });


  updateUserProgramSuccess$ = createEffect(() => this.actions$.pipe(ofType(userProgramActions.UPDATE_USER_PROGRAM_SUCCESS),
    map(() => {
      this.toastService.confirm(this.translate.get('common.saved'));

      return new userProgramActions.LoadUserProgram();
    })
  ));

  constructor(
    private actions$: Actions,
    private store: Store<UserProgramState>,
    private normalizedStore: Store<UserProgram>,
    private userProgramProvider: UserProgramProvider,
    private toastService: ToastService,
    private translate: TranslateService,
    private loadingService: LoadingService
  ) {

  }

}
