import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  QueryList,
  ViewChild,
  HostBinding,
  ViewChildren
} from '@angular/core';

import { IonContent, NavController } from '@ionic/angular';

import { Store } from '@ngrx/store';

import * as programActions from '../../store/session/actions/program.actions';
import * as navigationActions from '../../store/session/actions/navigation.actions';

import { getPlayModule } from '../../store/session/selectors/program.selectors';
import { LessonCardComponent } from './components/lesson-card.component';
import { OnboardingService } from '../../services/onboarding.service';
import { Subscription } from 'rxjs';
import { SessionState } from '../../store/session/session.reducers';
import { ClarityConfig } from '../../config/clarity.config';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-lessons',
  styleUrls: ['lessons.scss'],
  templateUrl: 'lessons.html'
})
export class LessonsPage implements AfterViewInit {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;
  @HostBinding('class.ern') isErn = this.config.program.programCode === 'ern';
  @HostBinding('class.ctq') isCtq = this.config.program.programCode === 'ctq';
  @HostBinding('class.ua') isUa = this.config.program.programCode === 'ua';

  @ViewChild(IonContent, { static: true }) content: IonContent;
  @ViewChildren(LessonCardComponent) lessonCards: QueryList<LessonCardComponent>;

  module$ = this.store.select(getPlayModule);

  scrolledOnce = false;

  modulesSubscription: Subscription;

  constructor(
    public navCtrl: NavController,
    private onboardingService: OnboardingService,
    private config: ClarityConfig,
    private store: Store<SessionState>
  ) {
  }

  ngAfterViewInit() {
    this.scrollToCurrentLesson();
  }

  ionViewDidEnter() {
    this.onboardingService.checkShowingOnboarding({type: 'program_lessons'});

    if (!this.scrolledOnce) {
      this.scrollToCurrentLesson();
    }
  }

  ionViewWillLeave() {
    this.modulesSubscription && this.modulesSubscription.unsubscribe();
  }

  scrollToCurrentLesson() {
    this.lessonCards.some((lessonCard) => {
      if (lessonCard.lesson.isCurrent && lessonCard.lesson.position > 1) {
        // const y = lessonCard.element.nativeElement.offsetTop;
        // setTimeout(() => {
        //   console.log('TODO: migrate - ionContent scroll to doesnt exists', y);
        // this.content.scrollTo(0, y - 20);
        // }, 500);

        return true;
      }
    });

    this.scrolledOnce = true;
  }

  onPlayLesson(lesson) {
    if (!lesson.record || !lesson.record.id) {
      return false;
    }
    this.store.dispatch(new programActions.TryToPlayLesson(lesson));
  }

  onClose() {
    this.store.dispatch(new navigationActions.CloseAllModals());
  }

}
