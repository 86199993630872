import { Component, Injector } from '@angular/core';
import { WizardComponent } from '../wizard.component';
import * as navigationActions from '../../../../store/session/actions/navigation.actions';
import { StressTestService } from '../../../../services/wizards/stress-test.service';
import { getExerciseByTagsStressTest } from '../../../../store/normalized/selectors/exercises.selectors';
import { getCurrentModule } from '../../../../store/session/selectors/program.selectors';
import * as exercisesActions from '../../../../store/session/actions/exercises.actions';
import { take, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Exercise } from '../../../../store/normalized/schemas/exercise.schema';
import { getAcceleratedTo } from '../../../../store/normalized/selectors/user-bootstrap.selectors';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../../store/session/session.reducers';
import { BrowserService } from '../../../../services/browser.service';
import { ClarityConfig } from '../../../../config/clarity.config';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cl-stress-test-wizard',
  styleUrls: ['../../wizard.scss', 'stress-test-wizard.component.scss'],
  templateUrl: 'stress-test-wizard.component.html',
  providers: []
})
export class StressTestWizardComponent extends WizardComponent {

  public feelings = [];
  public feelingChoice = [];
  public hoursAgo = 3;
  public feelingNow = 50;
  public header: string;
  public playerController = new Subject<string>();

  public exercises$ = this.store.select(getExerciseByTagsStressTest);
  public exerciseToPlay = null;

  constructor(
    protected store: Store<SessionState>,
    protected browser: BrowserService,
    protected injector: Injector,
    public config: ClarityConfig,
    protected analyticsService: AnalyticsService,
    protected translate: TranslateService
  ) {
    super(store, browser, injector, config, analyticsService, translate);
  }

  protected wizardConfig = {
    title: 'wizards.stress_test',
    totalSteps: 5,
    initialStep: 1
  };

  initStep3() {
    this.feelingChoice = this.service.getFeelingChoice();
    this.onEnableNext();
  }

  initStep4() {
    this.getRecommendedExercises();
    this.onDisableNext();
  }

  initStep5() {
    this.onEnableNext();
  }

  protected _init() {
    this.service = this.injector.get<StressTestService>(StressTestService);

    return this.service.initModel()
      .then(() => {
        this.feelings = this.service.getFeelingsList();
      });
  }

  getRecommendedExercises() {
    const diagnose = this.service.getDiagnose();
    this.header = diagnose.text;
    this.store.dispatch(new exercisesActions.GetExercisesByTags(diagnose.tags));
    this.store.select(getExerciseByTagsStressTest);
  }

  onFeelingSelected(feelings) {
    this.service.setFeelingChoice(feelings);
    feelings.length ? this.onEnableNext() : this.onDisableNext();
  }

  onDone() {
    this.service.save();
    this.playerController.next('pause');
    this.wizardComplete.emit();
  }

  playExercise(exercise: Exercise) {
    if (exercise.tags.find((tag) => tag.name === 'rain')) {
      this.store.select(getCurrentModule)
        .pipe(withLatestFrom(this.store.select(getAcceleratedTo)))
        .pipe(take(1))
        .subscribe(([currentModule, acceleratedTo]) => {
          if (currentModule.number >= 6 || acceleratedTo >= 6) {
            this.exerciseToPlay = exercise;
            this.service.setExercise(exercise.id);
            this.nextStep.emit();
          }
          else {
            this.store.dispatch(
              new navigationActions.ShowInterstitial({
                page: 'InterstitialPage',
                params: {
                  type: 'locked',
                  title: 'interstitials.rain_locked_title',
                  notes: 'interstitials.rain_locked_module_6_ern'
                }
              })
            );
          }
        });
    }
    else {
      this.exerciseToPlay = exercise;
      this.service.setExercise(exercise.id);
      this.nextStep.emit();
    }
  }

  onEatAgo(hours: number) {
    this.service.setSinceEat(hours);
    this.hoursAgo = hours * 10;
  }

}
