import { Component, Input, HostBinding } from '@angular/core';
import { Store } from '@ngrx/store';
import { BrowserService } from '../../services/browser.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { SessionState } from '../../store/session/session.reducers';
import { ClarityConfig } from '../../config/clarity.config';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';

@Component({
  selector: 'cl-posts',
  styleUrls: ['posts.component.scss'],
  template: `
    <p *ngIf="type === 'communityPosts' && posts && posts[type].length === 0" class="no-posts-info">
      {{'community.no_community_posts' | translate}}
    </p>

    <ion-list class="flex-container container">
      <ion-item class="community-card" cl-box-shadow [class.only-one]="posts && posts[type].length === 1"
                *ngFor="let post of posts && posts[type]" (click)="openPost(post.topic_url)" tappable>

        <ion-avatar item-start>
          <ion-label>
            <img [src]="post.avatar_template" *ngIf="post?.avatar_template">
          </ion-label>
        </ion-avatar>

        <ion-grid>
          <ion-row>
            <ion-col class="journal-info">
              <h2>{{post.title}}</h2>
              <!-- THIS MUST BE ON ONE LINE -- we use pre-wrap for white space to correctly display long category names -->
              <div class="category-name" cl-uppercase>
                <div class="circle-icon" [style.background-color]="'#' + post.category?.color"></div>
                {{post.category?.name}}
              </div>
            </ion-col>
            <ion-col class="journal-stats">
              <div class="posts-count">{{post.posts_count}}</div>
              <div class="last-activity">{{post.last_posted_at | processDateInLocal: true}}</div>
            </ion-col>
          </ion-row>

        </ion-grid>

      </ion-item>
    </ion-list>
  `
})

export class PostsComponent {

  @Input() type: string;

  @Input() posts: any;

  @HostBinding('class.desktop') get isDesktop() {
    return this.config.usingDesktopOnWeb;
  }

  constructor(
    public store: Store<SessionState>,
    public config: ClarityConfig,
    private browser: BrowserService,
    private analyticsService: AnalyticsService
  ) {
  }

  openPost(postUrl) {
    const url = postUrl.split('/');
    url.splice(0, 3);
    const urlEncoded = encodeURIComponent('/' + url.join('/'));

    this.analyticsService.trackEvent(AnalyticsEvents.CommunityOpenedPost, { url });

    this.browser.openCommunityPath(urlEncoded);
  }
}
