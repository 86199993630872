import { Component, Input } from '@angular/core';
import { ClarityConfig } from '../../config/clarity.config';

@Component({
  selector: 'cl-download-links',
  styleUrls: ['download-links.component.scss'],
  template: `
    <p *ngIf="showSubtitle && config.program.programCode === 'ctq'">
      {{'subscriptions.account_is_active_sub_title_ctq' | translate}}
    </p>

    <p *ngIf="showSubtitle && config.program.programCode !== 'ctq'">
      {{'subscriptions.account_is_active_sub_title_any' | translate}}
    </p>

    <p *ngIf="sharecareDownloadReminder" [innerHTML]="'subscriptions.sharecare_download_reminder' | translate"></p>

    <ion-row class="appstore-buttons-wrapper" [ngClass]="{'mini-version': miniVersion}">
      <ion-col size="6">
        <a href="{{config.program.appStoreUrl}}" target="_blank">
          <img src="assets/imgs/stores/appstore.png">
        </a>
      </ion-col>
      <ion-col size="6">
        <a href="{{config.program.googlePlayUrl}}" target="_blank">
          <img src="assets/imgs/stores/googleplay.png">
        </a>
      </ion-col>
    </ion-row>
  `
})

export class DownloadLinksComponent {

  @Input() sharecareDownloadReminder = false;
  @Input() showSubtitle = true;
  @Input() miniVersion = false;

  constructor(
    public config: ClarityConfig
  ) {

  }
}
