import { Injectable } from '@angular/core';
import { CountByDay } from 'src/app/store/normalized/schemas/count-by-day.schema';
import { WorryTool } from 'src/app/store/session/models/worry-tool.model';

import { HttpProvider } from '../http/http.provider';
import { OfflineHttpProvider } from '../http/offline-http.provider';

@Injectable({providedIn: 'root'})
export class WorryToolProvider {

  private worryToolEndpoint = '/worry_tools';

  constructor(
    protected http: HttpProvider,
    private offlineHttp: OfflineHttpProvider
  ) {

  }

  public saveWorryTool(worryTool: WorryTool) {
    return this.offlineHttp.post(this.worryToolEndpoint, worryTool);
  }

  public getWorryToolCountByDay() {
    return this.http.get<CountByDay>(this.worryToolEndpoint + '/count_by_day' + '?tz=' + this.getTZ());
  }


  private getTZ() {
    let offset = new Date().getTimezoneOffset() / 60;
    let ret = 'GMT';
    if (offset > 0) {
      ret += '-' + offset;
    } else if (offset < 0) {
      offset = -offset;
      ret += '%2B' + offset;
    }

    return ret;
  }
}
