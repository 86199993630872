import { Component, HostBinding, ViewChild, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, IonContent } from '@ionic/angular';
import { Store } from '@ngrx/store';

import * as navigationActions from '../../store/session/actions/navigation.actions';
import * as syncActions from '../../store/session/actions/sync.actions';
import * as accountActions from '../../store/session/actions/account.actions';
import { FileService } from '../../services/files/file.service';
import * as programActions from '../../store/session/actions/program.actions';
import { OnboardingService } from '../../services/onboarding.service';
import { ClarityConfig } from '../../config/clarity.config';
import { LogoutAlert } from 'src/app/store/sensitive/actions/auth.actions';
import { SessionState } from '../../store/session/session.reducers';
import { getAllowHealthFitIntegration, getCurrentUserProgram } from 'src/app/store/normalized/selectors/user.selectors';
import { map, take } from 'rxjs/operators';
import { MyStatsComponent } from './components/my-stats.component';
import { WeightTrackComponent } from './components/weight-track.component';
import { ProfileWebComponent } from './components/profile-web.component';
import { ChangePasswordComponent } from './components/change-password.component';
import { RemindersComponent } from './components/reminders.component';
import { IntegrationsComponent } from './components/integrations.component';
import { SubscriptionSettingsComponent } from './components/subscription-settings.component';
import { SettingsWebComponent } from './components/settings-web.component';
import { GoalSettingsComponent } from './components/goal-settings.component';
import { LanguageComponent } from './components/language.component';
import { AboutComponent } from './components/about.component';
import { HelpComponent } from './components/help.component';
import { AboutWebComponent } from './components/about-web.component';
import { ActivityComponent } from './components/activity.component';
import { UserProgram } from '../../store/normalized/schemas/user.schema';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { CancellableEvent, Tab, TabbedViewComponent } from 'src/app/components/tabbed-view/tabbed-view.component';
import { MenuItem, accountMenuItems } from './account-menu';
import { HelpWebComponent } from './components/help-web.component';
import { DownloadComponent } from './components/download.component';
import { PrivacyScreenService } from 'src/app/services/privacy-screen.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-account',
  styleUrls: ['account.scss'],
  templateUrl: 'account.html'
})
export class AccountPage implements OnInit, OnDestroy {
  showLogout = true;
  accountMenu: MenuItem = null;
  tabs: Tab[] = [];

  @HostBinding('class.desktop') isDesktop = true;
  @ViewChild('tabbedNav', { static: true }) public tabbedNav: TabbedViewComponent;
  @ViewChild(IonContent) ionContent;

  usingSSO$ = this.store.select(getCurrentUserProgram)
    .pipe(
      map((userProgram) => !!userProgram.sso_provider)
    );

  userProgramRegistered$ = this.store.select(getCurrentUserProgram)
    .pipe(
      map((userProgram: UserProgram) => {
        const before = moment('2019-12-25')
          .startOf('day');
        const registered = moment(userProgram.created_at);

        return registered.diff(before, 'days') < 0;
      })
    );

  accountSubscription: Subscription;

  private allowHealthFitIntegration$: Observable<boolean> = this.store.select(getAllowHealthFitIntegration);
  private paramsSubscription: Subscription;

  constructor(
    public navCtrl: NavController,
    private store: Store<SessionState>,
    public file: FileService,
    private onboardingService: OnboardingService,
    public config: ClarityConfig,
    private route: ActivatedRoute,
    private privacyScreenService: PrivacyScreenService
  ) { }

  async ngOnInit() {
    // Set account menu
    // If ERN, check if healthfit integration is enabled
    if(this.config.currentProgramCode === 'ern') {
      this.accountSubscription = this.allowHealthFitIntegration$.subscribe(allowHealthFitIntegration => {
        this.setAccountMenu(allowHealthFitIntegration ? 'ernHealthfit':'ern');
      });
    } else {
      this.setAccountMenu(this.config.currentProgramCode);
    }

    this.tabbedNav.scrollToTop.subscribe(() => {
      this.ionContent.getScrollElement()
        .then((el) => {
          // don't call scroll action if already at the top
          if (el.scrollTop !== 0) {
            this.ionContent.scrollToPoint(0, 0, 0);
          }
        });
    });
  }

  ngOnDestroy() {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }

  private setAccountMenu(program: string) {
    this.accountMenu = accountMenuItems[program];

    this.store.dispatch(new accountActions.SetRootAccountMenu(this.accountMenu));
    this.tabs = this.getKeysFiltered()
      .map((option) => ({
        title: option.title,
        componentRef: this.componentNameFactory(option.template),
        componentProps: option.componentProps,
        extraComponentRef: this.componentNameFactory(option.extraComponentRef),
        extraComponentProps: option.extraCompProps,
        onlyMobile: option.onlyMobile,
        hideOnSSO: option.hideOnSSO,
        hasBadgeSlot: Boolean(option.hasBadgeSlot),
        route: option.key
      }))
      .filter(opt => opt.componentRef);
  }

  openCommunityModal() {
    this.store.dispatch(new navigationActions.OpenModal('ModalComponent', {
      header: 'account_menu.account.ctq_community',
      headline: 'account_menu.account.old_community_question',
      content: 'account_menu.account.old_community_info',
      icon: 'desktop',
      actionText: 'account_menu.account.old_community_open_text',
      actionCb: () => {
        this.store.dispatch(new programActions.OpenCommunity('/'));
      }
    }));
  }

  // TODO: Add loading screen while the logout is performed
  onLogout() {
    this.store.dispatch(new LogoutAlert());

    this.closeSettings();
  }

  ionViewWillEnter() {
    this.store.dispatch(new syncActions.SyncConnectedApplications());
  }

  ionViewDidEnter() {
    this.onboardingService.checkShowingOnboarding({type: `${this.config.program.programCode}_account`});

    this.paramsSubscription = this.handleMenuNavigation();
  }

  handleMenuNavigation() {
    return this.route.params.subscribe(params => {
      // for example tabs/account/devices tabs/account/weight
      if (params?.menu) {
        this.forceTabSelection(params.menu);
      }
    });
  }

  ionViewDidLeave() {
    this.privacyScreenService.disable();
    this.paramsSubscription.unsubscribe();
  }

  closeSettings() {
    this.store.dispatch(new navigationActions.CloseAllModals());
  }

  componentNameFactory(name: string) {
    const componentMap = {
      AboutComponent,
      AboutWebComponent,
      HelpComponent,
      HelpWebComponent,
      ProfileWebComponent,
      ChangePasswordComponent,
      WeightTrackComponent,
      RemindersComponent,
      ActivityComponent,
      IntegrationsComponent,
      SettingsWebComponent,
      GoalSettingsComponent,
      LanguageComponent,
      SubscriptionSettingsComponent,
      MyStatsComponent,
      DownloadComponent
    };

    return componentMap[name];
  }

  onTabChanged(event: CancellableEvent<Tab>) {
    const tab = event?.detail;

    if (tab?.route) {
      event.preventDefault();

      this.store.dispatch(new navigationActions.RootGoTo({ route: `tabs/account/${tab.route}` }));
    }
  }

  private forceTabSelection(menuPath: string) {
    const selected = this.tabbedNav.tabs.findIndex(({ route }) => route === menuPath);

    if (selected >= 0) {
      this.tabbedNav.selectTab(selected);
    }
  }

  private getKeysFiltered() {
    const menu = this.accountMenu && this.accountMenu.options ? this.accountMenu.options : {};
    const tabs: MenuItem[] = [];
    Object.keys(menu)
      .forEach((key) => {

        if (menu[key] && !menu[key].disabled) {
          tabs.push({ ...menu[key], key });
        }
      });

    return tabs.sort((a, b) => a.order - b.order);
  }
}
