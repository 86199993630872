import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';

import * as mantraActions from '../../../store/session/actions/user-program-session.actions';
import { UserProgramSession } from '../../../store/normalized/schemas/user.schema';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LiveLesson } from '../../../store/session/selectors/program.selectors';
import { ConnectivityService } from '../../../services/connectivity.service';
import { getCurrentUserProgramSession } from '../../../store/normalized/selectors/user.selectors';
import { SessionState } from '../../../store/session/session.reducers';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-mantra-edit-lesson',
  styleUrls: ['mantra-edit-lesson.component.scss'],
  template: `
  <div class="mantra-wrapper" [class.desktop]="isDesktop">
    <form [formGroup]="mantraForm" autocomplete="off" (submit)="doSubmit()">
      <cl-input
        [controlForm]="mantraForm.get('mantra')"
        type="text"
        name="mantra"
        [errorMessages]="validations.mantra.errors"
        placeHolder="{{'mantra.insert_mantra' | translate}}">
      </cl-input>

      <ion-row class="action-button outsider">
        <ion-col class="ion-text-center" auto>
          <cl-action-button class="outsider"
            [canClick]="mantraForm.valid"
            label="{{buttonLabelText | translate}}">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </form>
  </div>
  `
})
export class MantraEditLessonComponent {
  @Input() isDesktop = false;

  mantraForm: FormGroup;
  mantraData: UserProgramSession;

  @Input() lesson: LiveLesson;
  @Output() action = new EventEmitter();

  readonly validations = {
    mantra: {
      validators: {
        validators: Validators.compose([
          Validators.required
        ])
      },
      errors: {
        required: 'mantra.required'
      }
    }
  };

  constructor(
    private store: Store<SessionState>,
    private connection: ConnectivityService,
    private formBuilder: FormBuilder
  ) {
    this.store.select(getCurrentUserProgramSession)
      .subscribe((userMantra) => {
        this.mantraData = userMantra;
        this.mantraForm = this.formBuilder.group({

          mantra: new FormControl(this.mantraData.mantra || '', this.validations.mantra.validators)
        });
      });
  }

  get buttonLabelText() {
    return 'mantra.save_and_next_lesson';
  }

  doSubmit() {
    const mantraFormValue = this.mantraForm.value;

    if (mantraFormValue.mantra === this.mantraData.mantra) {
      return this.action.emit();
    }

    if (this.connection.preventAccessWhenOffline()) {
      return false;
    }

    const newData = {
      ...this.mantraData,
      mantra: mantraFormValue.mantra
    };

    this.store.dispatch(new mantraActions.UpdateUserMantra({
      data: newData,
      lesson: this.lesson
    }));

    // this.action.emit();
  }

}
