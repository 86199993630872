import { Component } from '@angular/core';

@Component({
  selector: 'cl-im-feeling-lucky-button',
  styleUrls: ['im-feeling-lucky-button.component.scss'],
  template: `
    <button class="stress-test">
      <div class="icon">
        <img [src]="'assets/imgs/arrow.png'"/>
      </div>
      <span>{{'wizards.im_feeling_lucky' | translate}}</span>
    </button>
  `
})
export class ImFeelingLuckyButtonComponent {
}
