import { Injectable } from '@angular/core';

import { HttpProvider } from './http/http.provider';
import { UserFavorite, UserFavoriteCreate } from '../store/normalized/schemas/user.schema';

@Injectable({providedIn: 'root'})
export class UserFavoritesProvider {

  private userFavsEndpoint = '/favourites';

  constructor(protected http: HttpProvider) {}

  public loadUserFavorites() {
    return this.http.get<UserFavorite[]>(this.userFavsEndpoint);
  }

  public createUserFavorite(favorite: UserFavoriteCreate) {
    return this.http.post(this.userFavsEndpoint, favorite);
  }

  public deleteUserFavorite(favoriteId: number) {
    return this.http.delete(this.userFavsEndpoint + '/' + favoriteId);
  }
}
