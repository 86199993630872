import { schema } from 'normalizr';

export class CheckinExercise {
  id: number;
  title: string;
  instructions: string;
}

export class Checkin {
  id: number;
  user_program_session_id: number;
  activity_id: number;
  feeling_id: number;
  check_in_question_id: number;
  check_in_exercise_id: number;
  feel_before: number;
  feel_now: number;
  created_at: string;
  updated_at: string;
  anxiety: number;
  tag?: string;
  date: string;
}

export class CheckinQuestion {
  id: number;
  question: string;
  range_low_label: string;
  range__high_label: string;
}

export const checkinExerciseSchema = new schema.Entity<CheckinExercise>('checkinExercises');
export const checkinQuestionSchema = new schema.Entity<CheckinQuestion>('checkinQuestions');
