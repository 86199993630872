import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  OnInit
} from '@angular/core';
import { SmokeCravingTool } from '../../../store/normalized/schemas/craving-tool.schema';
import { getCigCountToday } from '../../../store/session/selectors/cig-tracker.selectors';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TrackerService } from '../../../services/tracker.service';
import { SessionState } from '../../../store/session/session.reducers';
import { getCurrentUserQuitAndEndDate } from '../../../store/normalized/selectors/user.selectors';
import { map } from 'rxjs/operators';
import { SmokingType } from 'src/app/store/normalized/schemas/user.schema';

@Component({
  selector: 'cl-after-smoking-satisfaction-step',
  styleUrls: ['../craving-tool-ctq.scss', 'after-smoking-satisfaction-step.component.scss'],
  template: `
    <cl-waves-background wizard-bg bg-position-bottom>
      <ion-content>
        <ion-header>
          <cl-header-nav-buttons
            class="page-header"
            color="primary"
            (closeClick)="triggerClose()"
            [backButton]="false">
          </cl-header-nav-buttons>
        </ion-header>

        <div class="simulation lateral-padding">
          <h2 class="step-intro intro-question">
            {{'wizards.check_in_with_your_body_how_satisfied_content_do_you_feel' | translate}}
          </h2>

          <p class="step-intro" *ngIf="!(alreadyQuit$ | async)">
            {{(isVaping
              ? 'wizards.you_have_X_puffs_left_today'
              : 'wizards.you_have_X_cigarettes_left_today')
                | translate: {cigarettesLeft: cigarettesLeftParam.cigarettesLeft} }}
            .
          </p>

          <cl-intensity-selector
            [sliderValue]="sliderValue"
            highestIntensityLabel="wizards.very_satisfied"
            mediumIntensityLabel=""
            lowestIntensityLabel="wizards.very_dissatisfied"
            (intensity)="onAfterSatisfactionChange($event)"
            lowestIntensityScale="-10"
            lowIntensityScale="-5"
            mediumIntensityScale="0"
            highIntensityScale="+5"
            highestIntensityScale="+10">
          </cl-intensity-selector>
        </div>
      </ion-content>
    </cl-waves-background>

    <div class="controls-holder padding-bottom-iphone-x">
      <ion-row class="buttons-holder lateral-padding">
        <ion-col size="6" offset="3">
          <cl-next-button
            [ngClass]="{'dark-bg': darkBackground}"
            label="common.done"
            [canClick]="true"
            (action)="triggerDone()">
          </cl-next-button>
        </ion-col>
      </ion-row>
    </div>
  `
})
export class AfterSmokingSatisfactionStepComponent implements OnInit, OnDestroy {
  @Input() currentCravingTool: SmokeCravingTool;
  @Input() darkBackground = false;

  @Output() done = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  alreadyQuit$ = this.store.select(getCurrentUserQuitAndEndDate)
    .pipe(
      map((dates) => !!dates.quit_date)
    );

  trackerSubscription: Subscription;

  public cigarettesLeftParam = {cigarettesLeft: 2};

  constructor(private store: Store<SessionState>, private trackerService: TrackerService, private cdr: ChangeDetectorRef) {}

  get sliderValue() {
    const value = this.currentCravingTool.satisfaction_after_smoking || 0;

    return (value / 2 + 0.5) * 100;
  }

  get isVaping() {
    return this.currentCravingTool.smokingType === SmokingType.VAPING;
  }

  onAfterSatisfactionChange($event) {
    this.currentCravingTool.satisfaction_after_smoking = (($event - 50) * 2) / 100;
  }

  triggerDone() {
    this.done.emit();
  }

  ngOnDestroy() {
    this.trackerSubscription.unsubscribe();
  }

  ngOnInit() {
    this.trackerSubscription = this.store.select(getCigCountToday)
      .subscribe((smokedToday) => {
        this.trackerService.calculateCanSmokeToDate()
          .then((canSmoke) => {
            this.cigarettesLeftParam.cigarettesLeft = Math.max(0, canSmoke - smokedToday);
            this.cdr.detectChanges();
          });
      });
  }

  triggerClose() {
    this.closeClick.emit();
  }
}
