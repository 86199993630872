import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { getCompletedThemeWeekIds, getStartedThemeWeekIds } from '../../store/session/selectors/program.selectors';
import { getThemeWeeks } from '../../store/normalized/selectors/theme-week.selectors';
import { State } from '../../store/state.reducer';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { take, withLatestFrom } from 'rxjs/operators';
import { AlertsService } from '../../services/alerts.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-select-theme',
  styleUrls: ['select-theme.scss'],
  templateUrl: 'select-theme.html'
})
export class SelectThemePage {
  modal;
  public themeWeeks$: Observable<any> = this.store.select(getThemeWeeks);

  // we'll show the theme weeks that have already been started so we encourage users to start new ones, instead of restarting
  // restarting however is still possible, this is just about what we display
  public completedThemeWeeks$: Observable<any> = this.store.select(getCompletedThemeWeekIds);
  public startedThemeWeeks$: Observable<any> = this.store.select(getStartedThemeWeekIds);

  constructor(
    public store: Store<State>,
    private alerts: AlertsService
  ) {

  }

  onStartWeek(week) {
    // by default any week can be started
    let canStart = true;

    this.completedThemeWeeks$
      .pipe(withLatestFrom(this.startedThemeWeeks$))
      .pipe(take(1))
      .subscribe(([completedWeekIds, startedThemeWeekIds]) => {
        // users cannot (re)start theme weeks already in progress
        if (startedThemeWeekIds.indexOf(week.id) > -1 && completedWeekIds.indexOf(week.id) <= -1) {
          canStart = false;
        }
      });

    if (!canStart) {
      this.alerts.customError(null, 'myjourney.cannot_start_theme_week', 'myjourney.theme_week_not_completed');
    }
    else {
      this.store.dispatch(new navigationActions.OpenModal('NewThemeWeekPage', {week}));
    }
  }

  onBack() {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }
}
