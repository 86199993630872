// timestamps selectors
import { createSelector } from '@ngrx/store';
import * as timestampsReducer from './timestamps.reducer';
import { getTimestampsState } from '../../../../app/store';

export const getMobileAppPopupLastSeenAt = createSelector(
  getTimestampsState,
  timestampsReducer.getMobileAppPopupLastSeenAt
);

