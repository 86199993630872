import { Injectable } from '@angular/core';
import { State } from '../store';
import { Store } from '@ngrx/store';
import { getCurrentUserProgram } from '../store/normalized/selectors/user.selectors';
import { SmokingType } from '../store/normalized/schemas/user.schema';

@Injectable({providedIn: 'root'})
export class SmokingTypeService {
  private userProgram$ = this.store.select(getCurrentUserProgram);
  private _smokingType: SmokingType;

  constructor(private store: Store<State>) {
    this.userProgram$
      .subscribe((userProgram) => {
        this._smokingType = userProgram.smoking_type;
      });
  }

  public getSmokingType() {
    return this._smokingType;
  }

  public isVaping() {
    return this._smokingType === SmokingType.VAPING;
  }

  public isCigarette() {
    return this._smokingType === SmokingType.CIGARETTE;
  }
}
