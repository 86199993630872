import {
  ChangeDetectionStrategy,
  Component,
  Input, OnDestroy, OnInit
} from '@angular/core';
import {
  AddManualWeightActivity,
  RemoveManualWeightActivity
} from '../../../store/session/actions/integrations.actions';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { WeightActivity } from 'src/app/store/normalized/schemas/weight-activity.schema';
import { format, isToday, isYesterday } from 'date-fns';
import { combineLatest, Subscription } from 'rxjs';
import {
  getIsWeightActivityAddedToday,
  getIsWeightActivityAddedYesterday
} from 'src/app/store/normalized/selectors/weight-activity.selectors';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-weight-activity-card',
  styleUrls: ['weight-activity-card.component.scss'],
  template: `
      <ion-grid  >
        <ion-row class="row" (click)="displayWeightInfo()">
          <ion-col class="ion-text-left">
            {{ activity.value | number:'1.1-1' }} {{ activity | weightUnit }},
            {{ activityDateTime }}
          </ion-col>
          <ion-col  class="ion-text-right ion-align-self-center remove-col" >
            <div class="remove-container" *ngIf="activity.source === 'manual' && deletionAllowed" >
              <ion-icon name="trash" class="trash" (click)="deleteActivity()"></ion-icon>
            </div>
            <div *ngIf="isNonManualTodayActivity" class="health-image {{activity.source}}"></div>
          </ion-col>
        </ion-row>
      </ion-grid>
  `
})
export class WeightActivityCardComponent implements OnInit, OnDestroy {
  @Input() activity: WeightActivity;

  deletionAllowed = false;
  recentActivitySubscription: Subscription = undefined;

  get activityDateTime() {
    return format(new Date(this.activity.activity_at),'PP');
  }

  get isNonManualTodayActivity() {
    return isToday(new Date(this.activity.activity_at)) && this.activity.source !== 'manual';
  }

  constructor(
    private translate: TranslateService,
    public alertController: AlertController,
    private store: Store<State>,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    const activityDate = new Date(this.activity.activity_at);

    if (!isToday(activityDate) && !isYesterday(activityDate)) {
      this.deletionAllowed = false;

      return;
    }

    this.recentActivitySubscription = combineLatest([
      this.store.select(getIsWeightActivityAddedYesterday),
      this.store.select(getIsWeightActivityAddedToday)
    ]).subscribe(([yesterdayWeightActivityAdded, todayWeightActivityAdded]) => {
      if(isToday(activityDate)) {
        this.deletionAllowed = true;
      } else {
        this.deletionAllowed = !todayWeightActivityAdded;
      }
    });
  }

  ngOnDestroy() {
    if (this.recentActivitySubscription) {
      this.recentActivitySubscription.unsubscribe();
    }
  }

  retryAddAction() {
    this.translate.get(
      ['my_activity.error', 'my_activity.retry' ,'my_activity.error_message_add',
        'my_activity.error_message_delete','my_activity.retry_delete', 'common.cancel']
    ).subscribe(async (translations) => {
      const alert = await this.alertController.create({
        header: translations['my_activity.error'],
        message: translations[this.activity.localState.action === 'delete' ? 'my_activity.error_message_delete':'my_activity.error_message_add'],
        buttons: [
          {
            text: translations['common.cancel']
          },
          {
            text: translations['my_activity.retry'],
            handler: () => {
              if(this.activity.localState.action === 'delete') {
                this.store.dispatch(new RemoveManualWeightActivity(this.activity));
              } else {
                this.store.dispatch(new AddManualWeightActivity(
                  this.activity.value, this.activity.unit, new Date(this.activity.activity_at), this.activity.id));
              }
            }
          }]
      });

      await alert.present();
    });
  }

  displayWeightInfo() {
    if(this.deletionAllowed) {
      return;
    }

    this.translate.get(
      ['weight_tracking.entry_added','weight_tracking.added_by.' + this.activity.source]
    ).subscribe(async (translations) => {
      this.toastService.confirm(translations['weight_tracking.entry_added'] + translations['weight_tracking.added_by.' + this.activity.source]);
    });
  }

  deleteActivity() {
    this.translate.get(
      ['weight_tracking.remove_activity_weight', 'common.remove','common.cancel','common.yes']
    ).subscribe(async (translations) => {
      const alert = await this.alertController.create({
        header: translations['common.remove'],
        message: translations['weight_tracking.remove_activity_weight'],
        buttons: [
          {
            text: translations['common.cancel']
          },
          {
            text: translations['common.yes'],
            handler: () => this.store.dispatch(new RemoveManualWeightActivity(this.activity))
          }]
      });

      await alert.present();
    });
  }
}
