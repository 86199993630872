import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input, OnChanges, OnDestroy, OnInit, Output
} from '@angular/core';

import { ClarityConfig } from '../../config/clarity.config';
import { BrightcovePlayer, MediaMetaData, VideoPlayerState } from 'capacitor-brightcove-player';
import { LoggerService } from '../../services/logger.service';
import { getFormattedTimeInMinutesAndSeconds } from '../../utils/format-time';
import { Observable, Subject, Subscription } from 'rxjs';
import { delay, take, takeUntil, tap } from 'rxjs/operators';
import { getSubtitlesLanguage } from '../../store/persistent/media/media.selectors';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AlertsService } from '../../services/alerts.service';
import { ConnectivityService } from '../../services/connectivity.service';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SetSubtitles } from 'src/app/store/persistent/media/media.actions';
import { PLAYER_ERROR_CODES } from './player-errors.enum';

@Component({
  selector: 'cl-video-player-brightcove',
  styleUrls: ['video-player-brightcove.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="player-container">
      <div class="player-placeholder" (click)="onPlayVideo()" *ngIf="posterUrl || displayDefaultBackground" >
        <img class="video-poster" [src]="posterUrl" alt="" *ngIf="!displayDefaultBackground" />
        <img class="video-play-icon" src="assets/imgs/brightcove-play-icon.svg" />
        <div class="progress" [ngStyle]="{width: currentProgress }" ></div>
        <div class="video-length" *ngIf="displayTotalLength">{{displayTotalLength}}</div>
      </div>
    </div>
  `
})
export class VideoPlayerBrightcoveComponent implements OnInit, OnDestroy, OnChanges {
  readonly MINIMUM_PLAYED_SECS = 1;
  @Input() brightcove_key: string;
  @Input() brightcove_poster_url: string = null;

  @Input() controls: Observable<string>;
  @Input() autoplay: boolean;
  @Input() autoSkip: boolean;
  @Input() autoPauseAfter: number;

  @Output() canPlay = new EventEmitter();
  @Output() playedMinimum = new EventEmitter();
  @Output() completed = new EventEmitter();
  @Output() autoSkipped = new EventEmitter();
  @Output() autoPaused = new EventEmitter();
  @Output() closePlayer = new EventEmitter();
  @Output() position = new EventEmitter();

  metadata: MediaMetaData = null;
  displayDefaultBackground = false;
  displayTotalLength: string = null;
  private playedMinimumEmitted = false;
  private progress: string = null;
  private shouldPauseVideo = true;
  private currentMillis = 0;
  private isVideoPlaying = false;
  private isVideoLoadingOrRunning = false;
  private checkIfMediaIsAvailable = true;

  private userLanguage: string = null;

  private videoListeners: Array<PluginListenerHandle> = [];
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  public posterUrl: string | SafeUrl = this.brightcove_poster_url;

  subtitleLanguageSubscription: Subscription;

  constructor(
    private config: ClarityConfig,
    protected changeDetector: ChangeDetectorRef,
    private loggerService: LoggerService,
    private store: Store<State>,
    private screenOrientation: ScreenOrientation,
    private alertsService: AlertsService,
    private connectivityService: ConnectivityService,
    private sanitizer: DomSanitizer
  ) {
    this.subtitleLanguageSubscription = this.store.select(getSubtitlesLanguage)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((subtitlesLanguage) => {
        this.userLanguage = subtitlesLanguage;
      });
  }

  get currentProgress(): string {
    return this.progress ? this.progress : '0%';
  }

  ngOnChanges(changes) {
    if (changes.brightcove_key) {
      const { brightcove_key } = changes;
      const hasBrightcoveKeyChanges = brightcove_key && brightcove_key.currentValue !== brightcove_key.previousValue && !brightcove_key.firstChange;

      if (!hasBrightcoveKeyChanges) {
        return;
      }

      if (brightcove_key && brightcove_key.currentValue) {
        this.loadVideo();
      }
    }
  }

  async ngOnInit() {
    this.setListeners();
    this.loadVideo();

    if (this.controls) {
      this.controls.pipe(takeUntil(this.unsubscribe$)).subscribe((action) => this.parentPlayControl(action));
    }
  }

  async loadVideo() {
    this.progress = null;
    this.currentMillis = 0;
    this.playedMinimumEmitted = false;
    this.displayDefaultBackground = false;

    if(!await this.isMediaAvailable()) {
      return;
    }

    BrightcovePlayer.getMetadata({fileId: this.brightcove_key})
      .then((data: { metadata: MediaMetaData }) => {
        this.metadata = data.metadata;
        // If the media is downloaded, we get the local filepath of the poster url
        // This local filepath need to be converted to use the local HTTP server.
        // See https://ionicframework.com/docs/core-concepts/webview#file-protocol for further details
        this.posterUrl = this.metadata.downloaded ?
          this.sanitizer.bypassSecurityTrustUrl(Capacitor.convertFileSrc(this.metadata.posterUrl)) : this.metadata.posterUrl;

        this.displayDefaultBackground = !this.posterUrl;

        this.displayTotalLength = getFormattedTimeInMinutesAndSeconds(data.metadata.totalMillis / 1000);
        this.changeDetector.detectChanges();

        this.initializeVideoPlayer();
      })
      .catch(error => {
        this.loggerService.error('BrightcovePlayerError', error, 'BrightcovePlayer.getMetadata() call failed');
        this.alertsService.playerError('technical', error?.code);
      });
  }

  ngOnDestroy() {
    this.videoListeners.map(listener => listener.remove());
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  async initializeVideoPlayer() {
    if(!this.hasBrightcoveKey()) {
      return;
    }

    if (this.autoplay && !this.autoSkip) {
      this.canPlay.emit(true);
      this.onPlayVideo();
    }

    if (this.autoSkip) {
      this.autoSkipped.emit();
      this.playedMinimum.emit(0);
    }
  }

  async onPlayVideo(): Promise<void> {
    if(!this.metadata?.downloaded && this.connectivityService.isOffline()) {
      return this.alertsService.playerError('network');
    }

    if(!this.hasBrightcoveKey()) {
      return;
    }

    this.isVideoLoadingOrRunning = true;

    // Try to run video with non existing subtitles.
    // This is just an error for sentry. This error won't break the app
    if(this.userLanguage && this.metadata.subtitles.filter(sub => sub.language === this.userLanguage).length === 0) {
      this.loggerService.error(
        'BrightcoveVideoPlayerError',
        `Brightcove video player - ${this.userLanguage} subtitle is not available for media : ${this.brightcove_key}`,
        'Subtitles in selected language are not available for this media'
      );
    }

    BrightcovePlayer.playVideo({ fileId: this.brightcove_key, local: true, position: this.currentMillis, subtitle: this.userLanguage })
      .then(() => {
        if (this.config.isAndroid) {
          this.screenOrientation.unlock();
        }

        this.isVideoPlaying = true;
      })
      .catch(error => {
        this.loggerService.error('BrightcoveVideoPlayerError', error, 'Error on video playing');
        this.alertsService.playerError('technical', error?.code);
      });

  }

  private async isMediaAvailable(): Promise<boolean> {
    if(!this.checkIfMediaIsAvailable ||
      this.connectivityService.isOnline() ||
      (await BrightcovePlayer.isMediaAvailableLocally({fileId: this.brightcove_key})).value) {
      return true;
    } else {
      this.displayDefaultBackground = true;
      this.changeDetector.detectChanges();
      this.loggerService.error('BrightcoveVideoPlayerError', 'Media is not available locally');
      this.alertsService.playerError(
        this.connectivityService.isOnline() ? 'technical' : 'network',
        this.connectivityService.isOnline() ? PLAYER_ERROR_CODES.TECHNICAL_ERROR : PLAYER_ERROR_CODES.NO_INTERNET_CONNECTION
      );

      return false;
    }
  }

  private hasPlayedMinimum(currentTimer: number): void {
    if(currentTimer > this.MINIMUM_PLAYED_SECS * 1000 && !this.playedMinimumEmitted) {
      this.playedMinimumEmitted = true;
      this.playedMinimum.emit(this.MINIMUM_PLAYED_SECS);
    }
  }

  private async hasCompletedVideo(): Promise<void> {
    if(this.isVideoPlaying) {
      await BrightcovePlayer.closeVideo();
    }
    this.completed.emit();
  }

  private updatePlayerState(playerState: VideoPlayerState): void {
    this.hasPlayedMinimum(playerState.currentMillis);
    this.updateProgress(playerState);
    this.currentMillis = playerState.currentMillis;
    this.position.emit(this.currentMillis);
    this.changeDetector.detectChanges();
  }

  private async autoPause(playerState: VideoPlayerState): Promise<void> {
    if(this.autoPauseAfter && this.shouldPauseVideo) {
      if(playerState.currentMillis > this.autoPauseAfter * 1000) {
        this.shouldPauseVideo = false;
        await BrightcovePlayer.closeVideo();
        this.autoPaused.emit(this.autoPauseAfter);
      }
    }
  }

  private updateProgress(data: VideoPlayerState): void {
    this.progress = `${Math.round((Number(data.currentMillis) / Number(data.totalMillis)) * 100).toString()}%`;
  }

  private hasBrightcoveKey(): boolean {
    if(this.brightcove_key) {
      return true;
    } else {
      this.loggerService.error('BrightcovePlayerError', 'Video player requested, but no Brightcove key provided');
      this.alertsService.playerError('technical', PLAYER_ERROR_CODES.MISSING_POLICYKEY);

      return false;
    }
  }

  private async parentPlayControl(action) {
    switch (action) {
      case 'play':
        return this.resumePlay();
      case 'pause':
        return this.pausePlay();
      default:
        return false;
    }
  }

  private async resumePlay(): Promise<void> {
    if(this.isVideoPlaying) {
      await BrightcovePlayer.playVideo({})
        .then(() => {
          if (this.config.isAndroid) {
            this.screenOrientation.unlock();
          }
        })
        .catch(error => this.loggerService.error('BrightcoveVideoPlayerError', error, 'Error on brightcove video resume'));
    }
  }

  private async pausePlay(): Promise<void> {
    if(this.isVideoPlaying) {
      await BrightcovePlayer.pauseVideo()
        .catch(error => this.loggerService.error('BrightcoveVideoPlayerError', error, 'Error on brightcove video pause'));
    }
  }

  private setListeners(): void {
    // Try to reload the video if the user is online again
    this.connectivityService.isConnectedObservable
      .pipe((takeUntil(this.unsubscribe$)))
      // This delay is necessary because in some cases, the networkStatusChange event
      // is called too early and the internet is not yet available for the brightcove plugin
      .pipe(delay(1000))
      .subscribe(connected => {
        if(connected) {
          this.autoplay = false;
          this.isVideoLoadingOrRunning = false;
          this.checkIfMediaIsAvailable = false;
          this.loadVideo();
        } else {
          this.checkIfMediaIsAvailable = true;
        }
      });

    this.videoListeners.unshift(BrightcovePlayer.addListener('videoPositionChange', (playerState: VideoPlayerState) => {
      this.updatePlayerState(playerState);
      this.autoPause(playerState);
    }));

    this.videoListeners.unshift(BrightcovePlayer.addListener('closeVideo', (playerState: VideoPlayerState & {completed: boolean; subtitle: string}) => {
      this.isVideoPlaying = false;
      this.isVideoLoadingOrRunning = false;
      playerState.completed && this.hasCompletedVideo();

      this.store.dispatch(new SetSubtitles(playerState.subtitle));
    }));

    this.videoListeners.unshift(BrightcovePlayer.addListener('beforeCloseVideo', () => {
      if (this.config.isAndroid) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }
    }));

    if (this.config.isAndroid) {
      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }
}
