<ion-header>
  <cl-header-nav-buttons
    class="page-header"
    color="blank"
    [backButton]="false"
    (closeClick)="onCloseClick()"
    [title]="postTitle$ | async"
    [class.desktop]="config.usingDesktopOnWeb">
  </cl-header-nav-buttons>
</ion-header>

<ion-content [class.text-focused]="textAreaHasFocus"
             [class.private]="post && post.private"
             [scrollEvents]="true"
             #content>

  <div *ngIf="(postLoading$ | async) || (commentsLoading$ | async); then initialLoading else postAndComments"></div>

  <ng-template #initialLoading>
    <div class="loading-wrapper">
      <ion-spinner></ion-spinner>
    </div>
  </ng-template>

  <ng-template #postAndComments>
    <div *ngIf="post">
      <cl-entry-card
        [separator]="post.private ? '' : 'bottom'"
        class="post"
        [isTruncatable]="false"
        [type]="'post'"
        [entry]="post"
        [commentable]="false"
        [canCopyUrl]="true"
        (openClick)="openOtherPost($event)"
        [currentProfileId]="currentUserProfileId$ | async"
        [isUserProfileComplete]="isUserProfileComplete$ | async"
        (delete)="onDeleteClick($event)"
        (like)="onLikePostClick($event)"
        (openProfile)="onProfileOpen()">
      </cl-entry-card>

      <ion-button expand="block" fill="clear" *ngIf="canLoadAllComments && !(allCommentsLoading$ | async)"
                  (click)="loadAllComments()" color="action">
        {{'social.posts.load_all_comments' | translate:{count: remainingComments} }}
      </ion-button>

      <div class="loading-wrapper" *ngIf="(allCommentsLoading$ | async)">
        <ion-spinner></ion-spinner>
      </div>

      <ion-list *ngIf="!post.private">
        <cl-entry-card
          *ngFor="let comment of localComments; first as isFirst"
          [separator]="!isFirst?'top':''"
          class="comment"
          [entry]="comment"
          [type]="'comment'"
          [isTruncatable]="false"
          [commentable]="false"
          (openClick)="openOtherPost($event)"
          (like)="onLikeCommentClick($event)"
          (delete)="onDeleteClick($event)"
          (openProfile)="onProfileOpen()"
          [currentProfileId]="currentUserProfileId$ | async"
          [isUserProfileComplete]="isUserProfileComplete$ | async">
        </cl-entry-card>
      </ion-list>

    </div>
  </ng-template>

</ion-content>

<div *ngIf="post && !post.private">
  <ion-footer>
    <div class="spin" *ngIf="(commentSending$ | async)">
      <ion-spinner></ion-spinner>
    </div>

    <ion-toolbar>
      <div #commentFormWrapper>
        <form [formGroup]="commentForm" (ngSubmit)="addComment()">
          <ion-textarea
            (ionFocus)="onCommentFocus()"
            autocapitalize="on"
            placeholder="{{'social.posts.write_your_comment' | translate}}"
            formControlName="content"
            [class.large]="textAreaHasFocus">
          </ion-textarea>

          <div *ngIf="textAreaHasFocus">
            <ion-button expand="block" type="submit" [disabled]="!commentForm.valid"
                        *ngIf="isUserProfileComplete$ | async">
              {{('social.journal.post' | translate | uppercase)}}
            </ion-button>

            <ion-button expand="block" *ngIf="!(isUserProfileComplete$ | async)" (click)="openIncompleteProfilePopup()">
              {{('social.journal.next' | translate | uppercase)}}
            </ion-button>
          </div>
        </form>
      </div>
    </ion-toolbar>

  </ion-footer>
</div>
