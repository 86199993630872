import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmokeCravingTool } from '../../../store/normalized/schemas/craving-tool.schema';
import { SmokingType } from 'src/app/store/normalized/schemas/user.schema';

@Component({
  selector: 'cl-smoke-or-ride-capture-step',
  styleUrls: ['../craving-tool-ctq.scss', 'smoke-or-ride-capture.component.scss'],
  template: `
    <cl-waves-background wizard-bg bg-position-bottom>
      <ion-content>
        <ion-header>
          <cl-header-nav-buttons
            class="page-header"
            color="primary"
            (closeClick)="triggerClose()"
            [backButton]="false">
          </cl-header-nav-buttons>
        </ion-header>

        <div class="simulation lateral-padding">
          <h2
            class="step-intro intro-question" [class.disabled]="skipped">
            {{'wizards.how_strong_is_your_craving_compared_to_before' | translate}}
          </h2>
          <div class="side-padding">
            <cl-intensity-selector
              [sliderValue]="sliderValue"
              highestIntensityLabel="wizards.a_lot_stronger"
              mediumIntensityLabel="wizards.same_as_before"
              lowestIntensityLabel="wizards.a_lot_weaker"
              (intensity)="onAfterSimulationIntensityChange($event)"
              lowestIntensityScale="-10"
              lowIntensityScale="-5"
              mediumIntensityScale="0"
              highIntensityScale="+5"
              highestIntensityScale="+10"
              [disabled]="skipped">
            </cl-intensity-selector>
          </div>
        </div>
      </ion-content>
    </cl-waves-background>

    <div class="controls-holder padding-bottom-iphone-x">
      <p class="smoke-question">
        {{(isVaping ? 'wizards.do_you_want_to_vape_now' : 'wizards.do_you_want_to_smoke_now') | translate}}
      </p>

      <ion-row class="buttons-holder lateral-padding">
        <ion-col size="6">
          <cl-prev-button
            label="{{isVaping ? 'wizards.vape' : 'wizards.smoke'}}"
            [canClick]="true"
            (action)="triggerSmoke()">
          </cl-prev-button>
        </ion-col>

        <ion-col size="6">
          <cl-next-button
            label="wizards.ride"
            [canClick]="true"
            (action)="triggerRide()">
          </cl-next-button>
        </ion-col>
      </ion-row>
    </div>
  `
})
export class SmokeOrRideCaptureStepComponent {
  @Input() currentCravingTool: SmokeCravingTool;
  @Input() skipped: boolean;

  @Output() smoke = new EventEmitter();
  @Output() ride = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  get sliderValue() {
    const value = this.currentCravingTool.satisfaction_before_smoking || 0;

    return (value / 2 + 0.5) * 100;
  }

  get isVaping() {
    return this.currentCravingTool.smokingType === SmokingType.VAPING;
  }

  onAfterSimulationIntensityChange($event) {
    this.currentCravingTool.satisfaction_before_smoking = (($event - 50) * 2) / 100;
  }

  triggerSmoke() {
    this.smoke.emit();
  }

  triggerRide() {
    this.ride.emit();
  }

  triggerClose() {
    this.closeClick.emit();
  }
}
