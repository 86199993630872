import { Injectable } from '@angular/core';

import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';
import { ClarityConfig } from '../config/clarity.config';

@Injectable({providedIn: 'root'})
export class TapticService {

  constructor(
    private config: ClarityConfig
  ) {}

  impact() {
    this.impactLight();
  }

  impactMedium() {
    this.hapticsImpact(ImpactStyle.Medium);
  }

  impactLight() {
    this.hapticsImpact(ImpactStyle.Light);
  }

  impactHeavy() {
    this.hapticsImpact(ImpactStyle.Heavy);
  }

  notification() {
    this.hapticsNotifiation();
  }

  notificationWarning() {
    this.hapticsNotifiation(NotificationType.Warning);
  }

  notificationError() {
    this.hapticsNotifiation(NotificationType.Error);
  }

  vibrate() {
    Haptics.vibrate();
  }

  selectionStart() {
    Haptics.selectionStart();
  }

  selectionChanged() {
    Haptics.selectionChanged();
  }

  selectionEnd() {
    Haptics.selectionEnd();
  }

  private hapticsImpact(style = ImpactStyle.Heavy) {
    if (!this.config.isDevice) {
      return false;
    }

    Haptics.impact({style});
  }

  private hapticsNotifiation(type = NotificationType.Success) {
    if (!this.config.isDevice) {
      return false;
    }

    Haptics.notification({type});
  }
}
