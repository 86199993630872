import { createAnimation, Animation } from '@ionic/angular';

export const ENTER_DURATION = 280;

export namespace IonicModalAnimations {
  // eslint-disable-next-line no-unused-expressions
  'use strict';

  const modalTransform = {
    web: {
      default: 'translate(-50%, -50%)', // based on src/app/app.scss styling of .modal-wrapper
      yTranslated: 'translate(-50%, calc(-50% + 40px))', // 40px below, to animate up<->down
      xTranslated: 'translate(50%, -50%)' // to animate slide
    },
    notweb: {
      default: 'translate(0px, 0px)',
      yTranslated: 'translate(0px, 40px)',
      xTranslated: 'translate(100%, 1px)'
    }
  };

  // web-modal class is added in web mode (check OPEN_MODAL action for more detals)
  // which adds custom translate style (check app.scss) so we need to work around it
  const hasWebStyles = (baseEl: HTMLElement) => baseEl.classList.contains('web-modal');

  // From bottom to top - Android modal like
  export function mdEnterAnimation(baseEl: HTMLElement): Animation {
    const shadowRootEl = baseEl.shadowRoot;
    const baseAnimation = createAnimation();
    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(shadowRootEl.querySelector('ion-backdrop'));

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(shadowRootEl.querySelector('.modal-wrapper'));

    const transform = hasWebStyles(baseEl) ? modalTransform.web : modalTransform.notweb;

    wrapperAnimation
      .fromTo('opacity', 0.01, 1)
      .fromTo('transform', transform.yTranslated, transform.default);

    backdropAnimation.fromTo('opacity', 0.01, 0.70);

    return baseAnimation
      .addElement(baseEl)
      .easing('cubic-bezier(0.36,0.66,0.04,1)')
      .duration(ENTER_DURATION)
      .beforeAddClass('show-modal')
      .addAnimation([backdropAnimation, wrapperAnimation]);
  }

  export function mdLeaveAnimation(baseEl: HTMLElement, opts?: any): Animation {
    const shadowRootEl = baseEl.shadowRoot;

    const baseAnimation = createAnimation();
    const backdropAnimation = createAnimation();
    const wrapperAnimation = createAnimation();
    const wrapperEl = shadowRootEl.querySelector('.modal-wrapper');

    backdropAnimation
      .addElement(shadowRootEl.querySelector('ion-backdrop'))
      .fromTo('opacity', 'var(--backdrop-opacity)', 0.0);

    const transform = hasWebStyles(baseEl) ? modalTransform.web : modalTransform.notweb;

    wrapperAnimation
      .addElement(wrapperEl)
      .fromTo('opacity', 0.99, 0)
      .fromTo('transform', transform.default, transform.yTranslated);

    return baseAnimation
      .addElement(baseEl)
      .easing('cubic-bezier(0.47,0,0.745,0.715)')
      .duration(200)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  }

  // Slide - page navigation like
  export function slideEnterAnimation(baseEl: HTMLElement): Animation {
    const shadowRootEl = baseEl.shadowRoot;

    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(shadowRootEl.querySelector('ion-backdrop'));

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(shadowRootEl.querySelector('.modal-wrapper'));

    const transform = hasWebStyles(baseEl) ? modalTransform.web : modalTransform.notweb;

    wrapperAnimation
      .duration(ENTER_DURATION)
      .fromTo('transform', transform.xTranslated, transform.default)
      .fromTo('opacity', 0.01, 1);

    backdropAnimation.fromTo('opacity', 0.01, 0.70);

    return baseAnimation
      .addElement(baseEl)
      .easing('cubic-bezier(0.36,0.66,0.04,1)')
      .duration(ENTER_DURATION)
      .beforeAddClass('show-modal')
      .addAnimation([backdropAnimation, wrapperAnimation]);
  }

  export function slideLeaveAnimation(baseEl: HTMLElement): Animation {
    const shadowRootEl = baseEl.shadowRoot;

    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(shadowRootEl.querySelector('ion-backdrop'));

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(shadowRootEl.querySelector('.modal-wrapper'));

    const transform = hasWebStyles(baseEl) ? modalTransform.web : modalTransform.notweb;

    wrapperAnimation
      .duration(200)
      .fromTo('transform', transform.default, transform.xTranslated)
      .afterClearStyles(['transform'])
      .fromTo('opacity', 1, 0);

    backdropAnimation.fromTo('opacity', 0.70, 0);

    return baseAnimation
      .addElement(baseEl)
      .easing('cubic-bezier(0.36,0.66,0.04,1)')
      .duration(200)
      .beforeAddClass('show-modal')
      .addAnimation([backdropAnimation, wrapperAnimation]);
  }

  export function quickLeaveAnimation(baseEl: HTMLElement, opts?: any): Animation {
    const shadowRootEl = baseEl.shadowRoot;

    const baseAnimation = createAnimation();

    const backdropAnimation = createAnimation();
    backdropAnimation.addElement(shadowRootEl.querySelector('ion-backdrop'));

    const wrapperAnimation = createAnimation();
    wrapperAnimation.addElement(shadowRootEl.querySelector('.modal-wrapper'));

    const transform = hasWebStyles(baseEl) ? modalTransform.web : modalTransform.notweb;

    wrapperAnimation
      .fromTo('opacity', 0.99, 0)
      .fromTo('transform', transform.default, transform.yTranslated);

    backdropAnimation.fromTo('opacity', 0.7, 0.0);

    return baseAnimation
      .addElement(baseEl)
      .easing('cubic-bezier(0.47,0,0.745,0.715)')
      .duration(0)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  }

}
