import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map, take } from 'rxjs/operators';

import { SmokingTypeService } from '../../services/smoking-type.service';
import { TrackerService } from '../../services/tracker.service';
import { getCigarettesByDay, getForToday } from '../../store/normalized/selectors/count-by-day.selectors';
import { getCurrentUserQuitAndEndDate } from '../../store/normalized/selectors/user.selectors';
import * as cigTrackerActions from '../../store/session/actions/cig-tracker.actions';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { State } from '../../store/state.reducer';

@Component({
  selector: 'cl-cig-tracker',
  templateUrl: 'cig-tracker.html',
  styleUrls: ['cig-tracker.scss']
})
export class CigTrackerComponent implements OnInit, OnDestroy {
  modal;

  cigCount = 0;
  cigsPerDay = 0;
  isSmokeFree = false;

  alreadyQuit$ = this.store.select(getCurrentUserQuitAndEndDate)
    .pipe(
      map((dates) => !!dates.quit_date)
    );

  private cigCountChanges = new Subject();

  private countTrackerSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private trackerService: TrackerService,
    private ref: ChangeDetectorRef,
    public smokingTypeService: SmokingTypeService
  ) {
  }

  ngOnInit() {
    this.trackerService.calculateCanSmokeToDate()
      .then((canSmoke) => {
        this.cigsPerDay = canSmoke;
      });

    this.store.select(getCigarettesByDay)
      .pipe(take(1))
      .subscribe((cigCounts) => {
        const cigCount = getForToday(cigCounts);
        this.cigCount = (cigCount && cigCount.count) ? cigCount.count : 0;
        this.setIsSmokeFree();
      });

    this.countTrackerSubscription =
      this.cigCountChanges.pipe(
        debounceTime(2000)
      )
        .subscribe(() => {
          const data = {
            date: moment()
              .format('YYYY-MM-DD'),
            count: this.cigCount,
            smokefree: this.cigCount === 0 ? true : false
          };

          this.store.dispatch(new cigTrackerActions.TrackCigCount(data));
        });
  }

  ngOnDestroy() {
    setTimeout(() => this.countTrackerSubscription.unsubscribe(), 2000);
  }

  changeCigCount(diff: number) {
    if (this.cigCount <= 0 && diff < 0) {
      return true;
    }

    this.cigCount += diff;
    this.setIsSmokeFree();

    this.store.dispatch(new cigTrackerActions.SetCigCount(this.cigCount));
    this.cigCountChanges.next(this.cigCount);

    this.ref.detectChanges();
  }
  private setIsSmokeFree(): void {
    this.isSmokeFree = this.cigCount === 0;
  }

  getLeftCig() {
    return this.cigsPerDay - this.cigCount > 0 ? this.cigsPerDay - this.cigCount : 0;
  }

  close() {
    console.log('TODO: CloseModal should accept animation changes');
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }
}
