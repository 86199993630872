import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LiveWeek } from '../../store/session/selectors/program.selectors';
import { ClarityConfig } from '../../config/clarity.config';
import { Store } from '@ngrx/store';
import { State } from '../../store';

@Component({
  selector: 'cl-program-weeks',
  styleUrls: ['program-weeks.component.scss'],
  template: `

    <ng-template #loadingWeeks>
      <ion-spinner color="light"></ion-spinner>
    </ng-template>

    <ng-template #journeyThemeWeek>
      <div class="flex-wrapper">
        <div class="flex-column">
          <cl-theme-journey-week-card-web
            *ngFor="let week of _themeColumnOne; let first = first; let last = last;"
            [class.first-week]="first"
            [class.last-week]="last"
            [class.current-week]="week.isCurrent"
            [week]="week"
            [selectedModules]="selectedModules"
            [expanded]="isWeekExpanded(week)"
            (weekExpanded)="onWeekExpanded($event)"
            (weekCollpased)="onWeekCollapsed($event)"
            (playModule)="onPlayModule($event)">
          </cl-theme-journey-week-card-web>
        </div>

        <div class="flex-column">
          <cl-theme-journey-week-card-web
            *ngFor="let week of _themeColumnTwo; let first = first; let last = last;"
            [class.first-week]="false"
            [class.last-week]="false"
            [class.current-week]="week.isCurrent"
            [week]="week"
            [selectedModules]="selectedModules"
            [expanded]="isWeekExpanded(week)"
            (weekExpanded)="onWeekExpanded($event)"
            (weekCollpased)="onWeekCollapsed($event)"
            (playModule)="onPlayModule($event)">
          </cl-theme-journey-week-card-web>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="!isLoading; else loadingWeeks">
      <ng-container *ngIf="!showingThemeWeeks; else journeyThemeWeek">
        <cl-journey-week-card-web
          *ngFor="let week of _weeks; let first = first; let last = last;"
          [class.first-week]="first"
          [class.last-week]="last"
          [isCustomPath]="showingCreateCustomPath"
          [week]="week"
          [selectedModules]="selectedModules"
          [expanded]="isWeekExpanded(week)"
          (weekExpanded)="onWeekExpanded($event)"
          (weekCollpased)="onWeekCollapsed($event)"
          (playModule)="onPlayModule($event)">
        </cl-journey-week-card-web>
      </ng-container>
    </ng-container>
  `
})
export class ProgramWeeksComponent {

  static readonly SCOPE_CORE_WEEKS = 'core_weeks';
  static readonly SCOPE_THEME_WEEKS = 'theme_weeks';
  static readonly SCOPE_CUSTOM_WEEKS = 'custom_weeks';
  static readonly SCOPE_CREATE_CUSTOM_PATH = 'create_custom_path';

  public _scope: string;

  _themeColumnOne = [];
  _themeColumnTwo = [];
  expandedWeeks = [];

  // @Input() startedThemeWeeks: LiveWeek[];
  @Input() selectedModules = [];
  @Input() isLoading = false;
  @Output() playModule = new EventEmitter();

  public themeWeeks: any[];

  @Input()
  set scope(value: string) {
    this._scope = value;

    if (this.showingThemeWeeks) {
      this.handleThemesTwoColumns();
    }
  }

  public _startedThemeWeeks: LiveWeek[];

  @Input()
  set startedThemeWeeks(value) {
    this._startedThemeWeeks = value;

    if (this.showingThemeWeeks) {
      this.handleThemesTwoColumns();
    }
  }

  public _weeks: any[];

  @Input()
  set weeks(value) {
    this._weeks = value;

    if (this.showingThemeWeeks) {
      this.handleThemesTwoColumns();
    }
  }

  constructor(
    public config: ClarityConfig,
    public store: Store<State>
  ) {

  }

  get showingThemeWeeks() {
    return this._scope === ProgramWeeksComponent.SCOPE_THEME_WEEKS;
  }

  get showingCustomWeeks() {
    return this._scope === ProgramWeeksComponent.SCOPE_CUSTOM_WEEKS;
  }

  get showingCreateCustomPath() {
    return this._scope === ProgramWeeksComponent.SCOPE_CREATE_CUSTOM_PATH;
  }

  onPlayModule($event) {
    this.playModule.emit($event);
  }

  onWeekExpanded(weekNumber) {
    this.expandedWeeks.push(weekNumber);
  }

  onWeekCollapsed(weekNumber) {
    const index = this.expandedWeeks.indexOf(weekNumber);

    if (index > -1) {
      this.expandedWeeks.splice(index, 1);
    }
  }

  isWeekExpanded(week) {
    // when used in creating a custom week all must be expanded
    if (this.showingCreateCustomPath) {
      return true;
    }

    return this.expandedWeeks.indexOf(week.number) > -1
      ? true
      : null;
  }

  private handleThemesTwoColumns() {
    if (!this._weeks || !this._weeks.length || !this._startedThemeWeeks) {
      return;
    }

    this._mergeStartedWeeksIntoThememWeeks();
    this._splitThemesTwoColumns();
  }

  private _mergeStartedWeeksIntoThememWeeks() {
    this.themeWeeks = this._weeks.map((week) => {
      const started = this._startedThemeWeeks
        .slice()
        .reverse()
        .find(startedThemeWeek => startedThemeWeek.record.id === week.id);

      // console.log(`matched ${week.id} with ${started.recordId}`);

      return started
        // if theme week was started, append the live week
        ? {
          ...week,
          ...started
        }
        // if not started, just use the theme week as the record to extract names, etc.
        : {
          ...week,
          record: {
            ...week
          }
        };

    });
  }

  private _splitThemesTwoColumns() {
    this._themeColumnOne = this.themeWeeks.slice(0, Math.ceil(this.themeWeeks.length / 2));
    this._themeColumnTwo = this.themeWeeks.slice(Math.ceil(this.themeWeeks.length / 2), this.themeWeeks.length);
  }

}
