
import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpProvider } from './http/http.provider';
import { catchError } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ThemeWeeksProvider {

  private endpoint = '/theme_weeks';

  constructor(
    private http: HttpProvider
  ) {

  }

  public loadData() {
    return this.http.get(this.endpoint)
      .pipe(
        catchError((error) => {
          console.log('error retrieving program', error);

          return observableThrowError(error);
        })
      );
  }
}
