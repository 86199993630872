import { Component } from '@angular/core';
import { SettingsComponent } from './settings.component';
import { ClarityConfig } from '../../../config/clarity.config';

@Component({
  selector: 'cl-help-center',
  styleUrls: ['help-center.component.scss'],
  template: `
    <h2 class="title">{{"account_menu.help.subtitle" | translate}}</h2>
    <h3 class="sub-title">{{"account_menu.help.text" | translate}}</h3>

    <ion-row class="link">
      <ion-col class="ion-text-center">
        <a href="mailto:{{config.program.supportEmail}}">{{config.program.supportEmail}}</a>
      </ion-col>
    </ion-row>
    <ng-content></ng-content>
  `
})

export class HelpCenterComponent implements SettingsComponent {

  constructor(
    public config: ClarityConfig
  ) {

  }

}
