import { Injectable } from '@angular/core';

import { HttpProvider } from './http/http.provider';
import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export interface Release {
  env: string;
  app_version: string;
  app_build: number;
  mandatory: boolean;
  official: boolean;

  program_id: number;
  platform: string;
}

@Injectable({providedIn: 'root'})
export class ReleasesProvider {

  private releasesEndpoint = '/releases';

  constructor(
    private http: HttpProvider
  ) {

  }

  public loadReleases(environment: string, version: string, build: number): Observable<Release[]> {
    return this.http.get(this.releasesEndpoint + `?env=${environment}&v=${version}&b=${build}`)
      .pipe(
        catchError((error) => {
          // silently discard any errors
          console.log('Cannot load releases', error);

          return of([]);
        })
      );
  }
}
