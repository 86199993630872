import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DottedStepsIndicatorStatus } from './dotted-steps.model';

@Component({
  selector: 'cl-dotted-steps-indicator',
  styleUrls: ['dotted-steps-indicator.component.scss'],
  template: `
    <div class="dot" [class.active]="i === activeDot" *ngFor="let dot of [].constructor(dots); index as i"></div>
  `
})

export class DottedStepsIndicatorComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject<void>();
  @Input()
  status$: Observable<DottedStepsIndicatorStatus>;

  @Input()
  dots: number;

  @Input()
  activeDot: number;

  ngOnInit() {
    if (this.status$) {
      this.status$.pipe(takeUntil(this.destroyed$)).subscribe(status => {
        this.dots = status?.dots;
        this.activeDot = status?.activeDot;
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
