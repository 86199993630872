import { Injectable } from '@angular/core';
import { NrtProductType, NrtService } from './nrt.service';
import { TranslateService, TranslateParser } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export enum NrtDosage {
  HIGH = 'higher_dosage',
  LOW = 'lower_dosage'
}

export const gumPrescription = {
  lower_dosage: '2mg',
  higher_dosage: '4mg'
};

export const lozengePrescription = {
  lower_dosage: '2mg',
  higher_dosage: '4mg'
};

export const patchPrescription = {
  lower_dosage: [
    { dosage: '14mg', weeksCount: '6', stepNumber: '2' },
    { dosage: '7mg', weeksCount: '2', stepNumber: '3' }
  ],
  higher_dosage: [
    { dosage: '21mg', weeksCount: '6', stepNumber: '1' },
    { dosage: '14mg', weeksCount: '2', stepNumber: '2' },
    { dosage: '7mg', weeksCount: '2', stepNumber: '3' }
  ]
};

@Injectable({providedIn: 'root'})
export class NrtDosageService {

  constructor(
    private translate: TranslateService,
    private parser: TranslateParser,
    private nrtService: NrtService
  ) {}

  private calculateDosage(product, survey) {
    switch (product) {
      case NrtProductType.PATCH:
        return this.getPatchDosage(survey);

      case NrtProductType.GUM:
      case NrtProductType.LOZENGE:
        return this.getGumLozengeDosage(survey);
    }
  }

  private getPatchDosage(survey) {
    return survey.more_than_ten_cigs.result ? NrtDosage.HIGH : NrtDosage.LOW;
  }

  private getGumLozengeDosage(survey) {
    const isFirstCigAfterThirtyMin = this.nrtService.isFirstCigAfterThirtyMin(survey.first_cig_after_30_min.result);

    return isFirstCigAfterThirtyMin ? NrtDosage.LOW : NrtDosage.HIGH;
  }

  private getPatchPrescription(patchPrescriptionText: string, dosageType) {
    const formatPrescription = ({dosage, weeksCount, stepNumber}) => this.parser.interpolate(patchPrescriptionText + ` ${dosage}`, { weeksCount, stepNumber });

    return patchPrescription[dosageType].map(formatPrescription);
  }

  public getPrescription(product, survey): Observable<string[]> {
    if (!product || !survey) {
      throw Error('missing required params @ getPrescription');
    }

    return new Observable<string[]>((observer) => {
      const dosage = this.calculateDosage(product, survey);

      switch (product) {
        case NrtProductType.GUM:
          this.translate.get('nrt.product_selection.gum')
            .subscribe(text => {
              observer.next([`${gumPrescription[dosage]} ${text}`]);
            });
          break;
        case NrtProductType.LOZENGE:
          this.translate.get('nrt.product_selection.lozenge')
            .subscribe(text => {
              observer.next([`${lozengePrescription[dosage]} ${text}`]);
            });
          break;
        case NrtProductType.PATCH:
          this.translate.get('nrt.prescriptions.patch.dosage')
            .subscribe(patchPrescriptionText => {
              observer.next(this.getPatchPrescription(patchPrescriptionText, dosage));
            });
          break;
        default:
          throw Error(`${product} product or ${dosage} dosage not found @ updatePrescription`);
      }
    });
  }
}
