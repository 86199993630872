import { Injectable } from '@angular/core';

import { HttpProvider } from './http/http.provider';

@Injectable({providedIn: 'root'})
export class RemindersProvider {

  private remindersEndpoint = '/reminders';

  constructor(
    private http: HttpProvider
  ) {

  }

  public loadReminders() {
    return this.http.get(this.remindersEndpoint);
  }
}
