import { Action } from '@ngrx/store';
import { WeightActivity } from '../../normalized/schemas/weight-activity.schema';

export const ADD_USER_WEIGHT = '[weight activities] Add Weight';
export const ADD_USER_WEIGHT_SUCCESS = '[weight activities] Add Weight Success';
export const ADD_USER_WEIGHT_FAIL = '[weight activities] Add Weight Fail';
export const REMOVE_USER_WEIGHT = '[weight activities] Remove Weight';
export const REMOVE_USER_WEIGHT_SUCCESS = '[weight activities] Remove Weight Success';
export const REMOVE_USER_WEIGHT_FAIL = '[weight activities] Remove Weight Fail';

export class AddUserWeight implements Action {
  readonly type = ADD_USER_WEIGHT;

  constructor(public payload: { weightActivity: WeightActivity }) {}
}

export class AddUserWeightSuccess implements Action {
  readonly type = ADD_USER_WEIGHT_SUCCESS;

  constructor(public payload: { weightActivities: WeightActivity }) {}
}

export class AddUserWeightFail implements Action {
  readonly type = ADD_USER_WEIGHT_FAIL;
}

export class RemoveUserWeight implements Action {
  readonly type = REMOVE_USER_WEIGHT;

  constructor(public payload: { id: number }) {}
}

export class RemoveUserWeightSuccess implements Action {
  readonly type = REMOVE_USER_WEIGHT_SUCCESS;

  constructor(public payload: { id: number }) {}
}

export class RemoveUserWeightFail implements Action {
  readonly type = REMOVE_USER_WEIGHT_FAIL;
}

export type WeightActivitiesActions =
  | AddUserWeight
  | AddUserWeightSuccess
  | AddUserWeightFail
  | RemoveUserWeight
  | RemoveUserWeightSuccess
  | RemoveUserWeightFail;
