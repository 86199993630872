import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'cl-bottom-sheet',
  styleUrls: ['bottom-sheet.component.scss'],
  template: `
    <div class="upper-transparency" (click)="onClose()"></div>

    <div class="content">
      <img class="close-button" src="assets/imgs/close.png" (click)="onClose()"/>

      <ng-content></ng-content>
    </div>
  `
})
export class BottomSheetComponent {
  @Output() closeClick = new EventEmitter<boolean>();

  onClose() {
    this.closeClick.emit();
  }
}
