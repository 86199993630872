
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpProvider, HttpRequestOptions } from './http.provider';
import { Store } from '@ngrx/store';
import * as offlineQueueActions from '../../store/session/actions/offline-queue.actions';
import { catchError } from 'rxjs/operators';
import { OfflineQueueService } from '../../services/offline-queue.service';
import { ConnectivityService } from '../../services/connectivity.service';
import { State } from '../../store/state.reducer';

export interface OfflineRequestQueueOptions {
  ignoreHttpResponseCode?: Array<number>;
}

@Injectable({providedIn: 'root'})
export class OfflineHttpProvider {

  readonly QUEUEABLE_ENDPOINTS = [
    '/user_activities',
    '/check_ins',
    '/stress_tests',
    '/craving_meters',
    '/breaths',
    '/anxiety_quizzes',
    '/awareness_quizzes',
    '/cig_counts',
    '/user_reminders/', // we cannot queue POSTs because we don't know what ID will be assigned,
    '/craving_tools',
    // '/user_program_session' // we don't support updating the mantra (through the session) while offline
    '{iridium}/events',
    '{iridium}/users'
  ];

  constructor(
    private http: HttpProvider,
    private store: Store<State>,
    private offlineQueueService: OfflineQueueService,
    private connection: ConnectivityService
  ) {

  }

  public post<T>(endPoint: string, params: object, options?: HttpRequestOptions, queueOptions?: OfflineRequestQueueOptions): Observable<T> {
    if (this.connection.isOffline() && this.canQueue(endPoint)) {
      return this.queuePost(endPoint, params, options, 'offline', queueOptions);
    }

    return this.http.post(endPoint, params, options)
      .pipe(
        catchError((error) => {
          if (!this.canQueue(endPoint) || queueOptions?.ignoreHttpResponseCode?.includes(error.status)) {
            return observableThrowError(error);
          }

          return this.queuePost(endPoint, params, options, error);
        })
      );
  }

  public patch<T>(endPoint: string, params: object, options?: HttpRequestOptions): Observable<T> {
    if (this.connection.isOffline() && this.canQueue(endPoint)) {
      return this.queuePatch(endPoint, params, options, 'offline');
    }

    return this.http.patch(endPoint, params, options)
      .pipe(
        catchError((error) => {
          if (!this.canQueue(endPoint)) {
            return observableThrowError(error);
          }

          return this.queuePatch(endPoint, params, options, error);
        })
      );
  }

  // public delete<T>(endPoint: string, options: HttpRequestOptions = undefined): Observable<T> {
  //   if (this.connection.isOffline() && this.canQueue(endPoint)) {
  //     return this.queueDelete(endPoint, options, 'offline');
  //   }
  //
  //   return this.http.delete(endPoint, options)
  //     .pipe(
  //       catchError((error) => {
  //         if (!this.canQueue(endPoint)) {
  //           return Observable.throw(error);
  //         }
  //
  //         return this.queueDelete(endPoint, options, error);
  //       })
  //     );
  // }

  private queuePost(endPoint, params, options, error, queueOptions?: OfflineRequestQueueOptions) {
    this.store.dispatch(new offlineQueueActions.QueueRequest({
      type: 'post',
      endpoint: endPoint,
      payload: params,
      options,
      ignoreHttpResponseCode: queueOptions?.ignoreHttpResponseCode,
      lastError: error.status || undefined
    }));

    return of(this.simulateApiResponse(endPoint, params));
  }

  private queuePatch(endPoint, params, options, error) {
    this.store.dispatch(new offlineQueueActions.QueueRequest({
      type: 'patch',
      endpoint: endPoint,
      payload: params,
      options,
      lastError: error.status || undefined
    }));

    return of(params);
  }

  // private queueDelete(endPoint, options, error) {
  //   if (this.canSimulateDelete(endPoint)) {
  //     return of(null);
  //   }
  //
  //   this.store.dispatch(new offlineQueueActions.QueueRequest({
  //     type: 'delete',
  //     endpoint: endPoint,
  //     payload: null,
  //     options: options,
  //     lastError: error.status || undefined
  //   }));
  //
  //   return of(null);
  // }

  private canQueue(endpoint) {
    if (!this.offlineQueueService.canQueue()) {
      return false;
    }

    const canQueue = this.QUEUEABLE_ENDPOINTS
      .filter((queueablEndpoint) => queueablEndpoint.indexOf(endpoint) === 0);

    return canQueue ? true : false;
  }

  private simulateApiResponse(endpoint, params): any {
    // FAIL - the ids are unknown
    // // weight tracking
    // if (endpoint === '/user_activities' && params['kind'] && params['kind'] === 'weight_track') {
    //   return {
    //     activity_at: new Date().toISOString(),
    //     activityable_id: null,
    //     activityable_type: null,
    //     created_at: new Date().toISOString(),
    //     data: params.data,
    //     id: this.generateUniqId(),
    //     kind: 'weight_track',
    //     name: 'Weight Track',
    //     user_program_session_id: null
    //   };
    // }

    return null;
  }

  // private canSimulateDelete(endpoint) {
  //   // tracked weights can just be discarded and they will be re-synced from server
  //   if (endpoint.indexOf('/user_activities') === 0) {
  //     const match = endpoint.match(/user_activities\/(\w*)/);
  //
  //     // extract the id and if it's not a number, it was generate locally
  //     if (match && match[1] && isNaN(match[1])) {
  //       return true;
  //     }
  //   }
  //
  //   return false;
  // }
}
