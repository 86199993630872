import { FormArray, FormControl } from '@angular/forms';

/**
 * These classes are an extension to FormControl and FormArray to facilitate
 * form validation and management for checkboxes.
 *
 * The reason why plain FormArray with FormControl doesn't work for us,
 * is because FormControl only handles primitive values, it means, for our case
 * it can holds only true or false. So when accessing the FormArray of the checkbox group,
 * we'll get an array of boolean values, which is not very useful.
 *
 * We need an array of strings, representing the VALUES of the selected (true) checkboxes.
 * More details in my answer of this stackoverflow: https://stackoverflow.com/a/69637536/3317037
 */

export type FormValueType = string;

export class CheckboxItemControl {
  label: string; // value to be shown in the UI
  value: FormValueType; // value to be saved in backend

  control: FormControl;

  constructor({ label, value, defaultValue = false }: { label: string; value: string; defaultValue?: boolean }) {
    this.label = label;
    this.value = value;

    this.control = new FormControl(defaultValue || false);
  }

  get selected(): boolean {
    return Boolean(this.control.value);
  }
}

export class CheckboxGroupControl {
  name?: string; // name of the checkbox group

  items: CheckboxItemControl[];
  control: FormArray;

  constructor(name: string, items: CheckboxItemControl[]) {
    this.name = name;
    this.items = items;

    this.control = new FormArray(this.getAllItemsControls(), CheckboxGroupControl.emptyArrayFormValidator);
  }

  get value(): FormValueType[] {
    return this.selectedItems.map(item => item.value);
  }

  private get selectedItems(): CheckboxItemControl[] {
    return this.items.filter(item => item.selected);
  }

  private getAllItemsControls(): FormControl[] {
    return this.items.map(item => item.control);
  }

  private static emptyArrayFormValidator(control: FormControl) {
    const valid = (control.value as boolean[]).some(Boolean);

    return valid ? null : {
      error: 'empty'
    };
  }
}
