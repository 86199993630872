import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationStrategy } from '@angular/common';
import { Store } from '@ngrx/store';
import { IonContent, ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject, forkJoin, Observable, of, Subject, timer } from 'rxjs';
import { filter, map, mapTo, switchMap, take, takeUntil } from 'rxjs/operators';

import { StepValidationSlideComponent } from 'src/app/components/slides/step-validation-slide';
import { User } from 'src/app/store/normalized/schemas/user.schema';
import { getCurrentUser, getIsScaleRedemptionEnabled } from 'src/app/store/normalized/selectors/user.selectors';
import { SessionState } from 'src/app/store/session';
import { CheckboxGroupControl, CheckboxItemControl } from 'src/app/utils/checkbox-form-control';
import * as Questions from './account-setup-questions';
import * as accountActions from '../../../store/session/actions/account.actions';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { US_PHONE_NUMBER_REGEX, US_ZIP_REGEX } from '@mindsciences/utils';
import { BrowserService } from 'src/app/services/browser.service';
import { AccountDppSetupPayload } from 'src/app/store/session/models/account-setup.model';
import { getGoals } from 'src/app/store/normalized/selectors/list-items.selectors';
import { ExercisesProvider } from 'src/app/providers/exercises.provider';
import { Exercise } from 'src/app/store/normalized/schemas/exercise.schema';
import { DottedStepsIndicatorStatus } from 'src/app/components/dotted-steps-indicator/dotted-steps.model';
import { DppSetupConfirmExitModalComponent } from './confirm-exit-modal';
import { FetchWeightScaleOrders, OrderWeightScale } from 'src/app/store/session/actions/health-devices.actions';
import { SyncGoals } from 'src/app/store/session/actions/sync.actions';
import { WeightScaleOrder } from 'src/app/providers/health-devices.provider';
import { getWeightScaleOrders, getWeightScaleOrderStatus } from 'src/app/store/session/selectors/health-devices.selectors';
import { onlyDigits, usPhoneMask, usZipMask } from 'src/app/utils/form-masks';
import usStates from 'states-us';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';

@Component({
  selector: 'page-dpp-account-setup',
  styleUrls: ['dpp-account-setup.scss'],
  templateUrl: 'dpp-account-setup.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DppAccountSetupPage extends StepValidationSlideComponent implements OnInit, AfterViewInit, OnDestroy {
  public countries = [{ name: 'United States of America', code: 'US' }]; // for now we allow US shipment only
  public states = usStates; // for now we allow US shipment only

  private readonly destroyed$ = new Subject<void>();
  readonly TEXTAREA_CHARACTER_LIMIT = 500;

  @ViewChild('ionContent', { read: ElementRef, static: true })
  public ionContent: ElementRef<IonContent>;
  public form: FormGroup;

  public weightScaleOrders$ = this.store.select(getWeightScaleOrders);
  public weightScaleOrderStatus$ = this.store.select(getWeightScaleOrderStatus);
  public weightScaleOrderStatus = '';

  public isScaleRedemptionEnabled = false;

  public goals$ = this.store.select(getGoals);
  goalsModel: CheckboxGroupControl;
  motivationModel: CheckboxGroupControl;
  doctorMotivationOptions = Questions.doctorMotivation;
  educationOptions = Questions.education;
  receivedScaleOptions = Questions.receivedScale;

  goalsElse: string;
  aboutYou: string;
  nickname: string;
  phoneNumber: string;

  userData: User;
  exercise: Exercise;

  readonly SCALE_ORDER_PRE_START = 'scale-order-pre-start';
  readonly SCALE_ORDER_INITIAL_STEP = 'scale-order-initial-step';
  readonly SCALE_ORDER_PROGRESS = 'scale-order-progress';
  readonly SCALE_ORDER_RESULT = 'scale-order-result';

  readonly VIDEO_ELEMENT_ID = 'video-demo';
  selectedSlideId$ = this.selectedSlideElement$.pipe(
    map(element => element?.querySelector('section[id]')?.id)
  );

  playerController$ = this.selectedSlideId$.pipe(
    filter(selectedSlideId => selectedSlideId === this.VIDEO_ELEMENT_ID),
    mapTo('play')
  );

  transitionController$ = this.selectedSlideNumber$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(selectedSlide => {
      this.animateImageTransitions(selectedSlide);
    });
  imageDidAnimate = [false, false, false];

  dottedStepsStatus$: Observable<DottedStepsIndicatorStatus> = this.selectedSlideNumber$.pipe(
    takeUntil(this.destroyed$),
    map(selectedSlide => {
      switch (selectedSlide) {
        // goals section
        case 1:
          return { dots: 3, activeDot: 0 };

        case 2:
          return { dots: 3, activeDot: 1 };

        case 3:
          return { dots: 3, activeDot: 2 };

        // motivation section
        case 4:
          return { dots: 4, activeDot: 0 };

        case 5:
          return { dots: 4, activeDot: 1 };

        case 6:
          return { dots: 4, activeDot: 2 };

        case 7:
          return { dots: 4, activeDot: 3 };

        // about you section
        case 8:
          return { dots: 3, activeDot: 0 };

        case 9:
          return { dots: 3, activeDot: 1 };

        case 10:
          return { dots: 3, activeDot: 2 };

        // scale section
        case 11:
          return null;

        case 12:
          return { dots: 4, activeDot: 0 };

        case 13:
          return { dots: 4, activeDot: 1 };

        case 14:
          return { dots: 4, activeDot: 2 };

        case 15:
          return null;

        case 16:
          return { dots: 4, activeDot: 3 };

        default:
          return null;
      }
    })
  );

  shouldShowDottedStep(selectedSlide: number, selectedSlideId: string) {
    return selectedSlide !== 0
      && selectedSlideId !== this.SCALE_ORDER_PRE_START
      && selectedSlideId !== this.SCALE_ORDER_PROGRESS
      && selectedSlideId !== this.VIDEO_ELEMENT_ID;
  }

  isSlideScrolledToBottom$ = new BehaviorSubject(false);
  @ViewChild('scrolledToBottomObserver', { read: ElementRef, static: false })
  scrolledToBottomObserver: ElementRef<HTMLElement>;
  observer: IntersectionObserver;

  private insideDppSetup = false;

  constructor(
    public config: ClarityConfig,
    public platform: Platform,
    private store: Store<SessionState>,
    private browser: BrowserService,
    private locationStrategy: LocationStrategy,
    private exercisesProvider: ExercisesProvider,
    private modalCtrl: ModalController,
    private analyticsService: AnalyticsService
  ) {
    super();

    this.store.select(getIsScaleRedemptionEnabled)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(isScaleRedemptionEnabled => {
        this.isScaleRedemptionEnabled = isScaleRedemptionEnabled;
      });

    history.pushState(null, null, location.href);

    this.locationStrategy.onPopState(() => {
      // need this check because there's no way to unsubscribe to locationStrategy listeners
      if (this.insideDppSetup) {
        history.pushState(null, null, location.href);
      }
    });

    this.swiperInit$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.swiper.on('slideChangeTransitionStart', () => {
        (this.swiper.slides[this.swiper.activeIndex] as HTMLElement).style.zIndex = `${this.swiper.activeIndex}`;

        setTimeout(() => {
          this.observer.unobserve(this.scrolledToBottomObserver.nativeElement);
          this.ionContent.nativeElement.scrollToTop();
        }, 0);
      });

      this.swiper.on('slideChangeTransitionEnd', () => {
        setTimeout(() => {
          this.observer.observe(this.scrolledToBottomObserver.nativeElement);
        }, 0);
      });
    });

    this.selectedSlideId$.pipe(
      takeUntil(this.destroyed$),
      filter(selectedSlideId => selectedSlideId === this.SCALE_ORDER_PRE_START),
      switchMap(() => this.weightScaleOrders$.pipe(take(1)))
    ).subscribe((scaleOrders) => {
      if (!this.isScaleRedemptionEnabled) {
        return setTimeout(() => this.navigateToNamedSlide(this.VIDEO_ELEMENT_ID), 1);
      }

      // skip this step every time we enter it and there's no scale order
      if (!scaleOrders?.length) {
        setTimeout(() => this.navigateToNamedSlide(this.SCALE_ORDER_INITIAL_STEP), 1);
      }
    });
  }

  nextWeightPreStart() {
    const option = this.form.get('receivedScaleOption').value;

    switch(option) {
      case 'do_have_scale':
        // go to the end
        return this.navigateToNamedSlide(this.VIDEO_ELEMENT_ID);

      case 'dont_have_scale':
        return this.tryNavigateToNext();
    }
  }

  ionViewWillEnter() {
    this.insideDppSetup = true;

    this.exercisesProvider.loadExercisesByTag('dpp_onboarding').pipe(take(1))
      .subscribe(exercises => {
        this.exercise = exercises[0];
      });

    this.addScrollInputIntoViewListener();
  }

  animateImageTransitions(targetSlide: number) {
    switch (targetSlide) {
      case 1:
        this.imageDidAnimate[0] = true;
        break;
      case 2:
        this.imageDidAnimate[1] = true;
        break;
      case 5:
        this.imageDidAnimate[2] = true;
        break;
    }
  }

  ionViewWillLeave() {
    this.insideDppSetup = false;
    this.removeScrollInputIntoViewListener();
  }

  private addScrollInputIntoViewListener() {
    window.addEventListener('ionKeyboardDidShow', this.scrollDownWhenKeyboardAppears.bind(this));
  }

  private removeScrollInputIntoViewListener() {
    window.removeEventListener('ionKeyboardDidShow', this.scrollDownWhenKeyboardAppears.bind(this));
  }

  private scrollDownWhenKeyboardAppears() {
    const isDppOnBoardingGoals = (activeElement) =>
      activeElement?.tagName?.toLowerCase() === 'textarea' && activeElement?.parentElement?.parentElement?.classList?.contains('onboarding-goals');

    if (!isDppOnBoardingGoals(document?.activeElement)) {
      return;
    }

    if (this.config.isDevice) {
      setTimeout(() => {
        document.activeElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
      }, 100);
    } else {
      // Mobile browsers automatically attempt to scroll a focused input into view,
      // however in this onboarding we have to do it ourselves
      // because of an ionic issue: https://github.com/ionic-team/ionic-framework/issues/24647
      this.ionContent.nativeElement.scrollToBottom();
      // Scroll down just enough so the textarea is shown.
      window.scrollBy(0, 150);
    }
  };

  ngOnInit() {
    this.store.dispatch(new SyncGoals());
    this.store.dispatch(new FetchWeightScaleOrders());

    this.form = this.createFormInputs();
    this.addFormMasks();
    this.patchFormWithAsyncValues();
  }

  private createFormInputs() {
    let motivationControlArray: CheckboxItemControl[];
    if (this.config.programDPP()) {
      motivationControlArray = Questions.dppMotivation.map(item => new CheckboxItemControl(item));
    } else if (this.config.programWL()) {
      motivationControlArray = Questions.wlMotivation.map(item => new CheckboxItemControl(item));
    }
    this.motivationModel = new CheckboxGroupControl('motivation', motivationControlArray);

    return new FormGroup({
      receivedScaleOption: new FormControl('', Validators.required),

      motivation: this.motivationModel.control,

      doctorMotivation: new FormControl('', Validators.required),
      education: new FormControl('', Validators.required),
      goalsElse: new FormControl(''),
      aboutYou: new FormControl(''),
      nickname: new FormControl(''),

      shippingAddress: new FormGroup({
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        streetAddress: new FormControl('', Validators.required),
        streetAddress2: new FormControl(''),
        city: new FormControl('', Validators.required),
        zip: new FormControl('', Validators.compose([
          Validators.required,
          Validators.pattern(US_ZIP_REGEX)
        ])),
        country: new FormControl(this.countries[0].code, Validators.required),
        state: new FormControl('', Validators.required),
        phoneNumber: new FormControl('', {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(US_PHONE_NUMBER_REGEX)
          ])
        })
      })
    });
  }

  private addFormMasks() {
    const zipControl = this.form.get('shippingAddress').get('zip');
    const phoneNumberControl = this.form.get('shippingAddress').get('phoneNumber');

    // add mask whenever values changes

    zipControl.valueChanges.subscribe(
      (value) => {
        const newValue = usZipMask(value);
        if (newValue !== value) zipControl.setValue(newValue);
      }
    );

    phoneNumberControl.valueChanges.subscribe(
      (value) => {
        const newValue = usPhoneMask(value);
        if (newValue !== value) phoneNumberControl.setValue(newValue);
      }
    );
  }

  private async patchFormWithAsyncValues() {
    this.userData = await this.getUserData();
    this.form.get('nickname').setValue(this.userData.first_name);

    this.form.get('shippingAddress')
      .get('firstName')
      .setValue(this.userData.first_name);
    this.form.get('shippingAddress')
      .get('lastName')
      .setValue(this.userData.last_name);

    const goals = await this.goals$
      .pipe(
        filter(goalsFromStore => Boolean(goalsFromStore.length)),
        take(1)
      ).toPromise();
    this.goalsModel = new CheckboxGroupControl(
      'goals',
      goals.map(item => new CheckboxItemControl({ value: String(item.id), label: item.name }))
    );
    this.form.addControl('goals', this.goalsModel.control);
  }

  private async getUserData(): Promise<User> {
    return this.store.select(getCurrentUser)
      .pipe(take(1))
      .toPromise();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.observer = new IntersectionObserver(entries => {
      this.isSlideScrolledToBottom$.next(!entries[0].isIntersecting);
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0
    });

    setTimeout(() => this.observer.observe(this.scrolledToBottomObserver.nativeElement), 300);
  }

  get currentEmail() {
    return this.userData?.email || '';
  }

  openTerms() {
    this.browser.goTo(this.config.privacyTermsUrl);
  }

  async confirmWantsToSkip() {
    const modal = await this.modalCtrl.create({
      component: DppSetupConfirmExitModalComponent,
      breakpoints: [0, 0.5],
      initialBreakpoint: 0.5,
      cssClass: 'bottom-sheet-modal'
    });

    modal.onDidDismiss().then((args) => {
      const dismissWith = args?.data;

      if (dismissWith === 'confirm') {
        this.swiper.allowSlideNext = true;
        this.swiper.slideTo(this.swiper.slides.length - 1);
      }
    });

    await modal.present();
  }

  isStepInvalid(step: string) {
    if (!this.form || !step) return false;

    const stepControl = this.form.get(step);

    if (!stepControl) {
      console.log('warning. not found step control. step: ', step);

      return false;
    }

    return stepControl.invalid;
  }

  isStepValid() {
    return true; // true because button will be disabled in html with isStepInvalid
  }

  async slideFinished() {
    const formResult: AccountDppSetupPayload = this.getFormResult();

    this.store.dispatch(new accountActions.AccountDppSetupProcess(formResult));
    this.analyticsService.trackEvent(AnalyticsEvents.CompletedDppOnboarding, { formResult });
  }

  tryAgainOrderScale() {
    // return to the beggining of the process
    this.navigateToNamedSlide(this.SCALE_ORDER_INITIAL_STEP);
  }

  postOrderScale() {
    const address: AccountDppSetupPayload['shippingAddress'] = this.form.get('shippingAddress').value || {};

    // @todo move this to a better place
    const order: WeightScaleOrder = {
      provider: 'fitbit',
      address1: address.streetAddress,
      address2: address.streetAddress2,
      postal_code: address.zip,
      city: address.city,
      state: address.state,
      country: address.country,
      phone: onlyDigits(address.phoneNumber),
      first_name: address.firstName,
      last_name: address.lastName,

      scale_sku: '203BK' // we only allow black for now
    };


    this.store.dispatch(new OrderWeightScale(order));

    this.navigateToNamedSlide(this.SCALE_ORDER_PROGRESS);

    this.weightScaleOrderStatus = 'progress';
    const orderResult$ = this.weightScaleOrderStatus$.pipe(
      filter(status => status !== 'progress'),
      take(1)
    );

    // wait for some seconds at least, in case the Order gets completed too fast
    forkJoin([
      orderResult$,
      timer(2000)
    ]).subscribe(([orderResult]) => {
      this.weightScaleOrderStatus = orderResult;

      this.navigateToNamedSlide(this.SCALE_ORDER_RESULT);
    });
  }

  getFormResult(): AccountDppSetupPayload {
    let goals: number[] = [];
    if (this.goalsModel.value.length) {
      goals = this.goalsModel.value.map(id => Number(id));
    }

    return {
      goals,
      goalsElse: this.form.get('goalsElse').value,
      motivations: this.motivationModel.value,
      doctorsMotivations: this.form.get('doctorMotivation').value,
      aboutYou: this.form.get('aboutYou').value,
      nickname: this.form.get('nickname').value,
      education: this.form.get('education').value,

      shippingAddress: this.form.get('shippingAddress').value
    };
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
