import { Injectable } from '@angular/core';
import {
  HealthFit,
  HealthFitAuthorizationDataType,
  HealthFitAuthorizationType,
  HealthFitResultType
} from 'capacitor-health-fit';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { ClarityConfig } from '@mindsciences/clarity-core/app/config/clarity.config';
import { SessionState } from '@mindsciences/clarity-core/app/store/session';
import {
  isGoogleFitConnected,
  isHealthKitConnected
} from '@mindsciences/clarity-core/app/store/session/selectors/integrations.selectors';
import * as integrationsActions from '@mindsciences/clarity-core/app/store/session/actions/integrations.actions';
import { HealthFitMultipleHistory } from 'capacitor-health-fit/dist/esm/definitions';
import { take } from 'rxjs/operators';
import { getAllowHealthFitIntegration } from '@mindsciences/clarity-core/app/store/normalized/selectors/user.selectors';

@Injectable()
export class HealthFitService {
  isHealthKitConnected$ =  this.store.select(isHealthKitConnected);
  isGoogleFitConnected$ = this.store.select(isGoogleFitConnected);

  private allowHealthFitIntegration$: Observable<boolean> = this.store.select(getAllowHealthFitIntegration);

  constructor(
    private store: Store<SessionState>,
    private platform: Platform,
    private clarityConfig: ClarityConfig
  ) {
    this.allowHealthFitIntegration$.pipe((take(1))).subscribe(allowHealthFitIntegration => {
      if(!this.clarityConfig.programDPPorWL() && !allowHealthFitIntegration) {
        return;
      }

      this.startSynchronization();

      this.platform.resume.subscribe(async () => {
        this.startSynchronization();
      });
    });

    // Healthkit & Google fit available for DPPwl & ERN with healthfit integration
  }

  startSynchronization() {
    combineLatest([
      this.isHealthKitConnected$,
      this.isGoogleFitConnected$
    ]).pipe(take(1))
      .toPromise()
      .then(([hkConnected, fitConnected]) => {
        if (hkConnected || fitConnected) {
          this.store.dispatch(new integrationsActions.GetHealthDataSinceLastSync());
        }
      });
  }
  isAvailable(): Observable<boolean> {
    return from(HealthFit.isAvailable().then(result => result.available)) as Observable<boolean>;
  }

  openHealthApp(): Observable<void> {
    return from(HealthFit.openHealth()) as Observable<void>;
  }

  requestAuthorizations(read: Array<HealthFitAuthorizationDataType> = [], write: Array<HealthFitAuthorizationDataType> = []): Observable<void> {
    return from(HealthFit.requestAuthorization({
      read,
      write
    })) as Observable<void>;
  }

  isMultipleTypesAuthorized(types: Array<HealthFitAuthorizationDataType>, mode: HealthFitAuthorizationType): Observable<boolean> {
    const promises = [];
    types.forEach(type => {
      promises.push(HealthFit.isAuthorized({type, mode}));
    });

    return from(Promise.all(promises).then((results: Array<{ authorized: boolean }>) =>  results.every(result => result.authorized === true)));
  }

  readData(types: Array<HealthFitAuthorizationDataType>, start: Date, end: Date, result: HealthFitResultType): Observable<HealthFitMultipleHistory> {
    return from(HealthFit.readMultiple({types, start, end, result})) as Observable<HealthFitMultipleHistory>;
  }
}
