<ion-content>

  <ion-header>
    <cl-header-nav-buttons
      color="blank"
      [title]="'perform.breathe_exercise' | translate"
      (closeClick)="onClose()"
      [backButton]="false"
      class="page-header"
    >
    </cl-header-nav-buttons>
  </ion-header>

  <div class="page-wrapper">

    <div class="exercise-wrapper" *ngIf="view === 'breathe'">
      <ion-row class="lateral-padding task-text">
        <ion-col>
          <h4>{{"perform.take_a_moment_to_identify" | translate}}</h4>
        </ion-col>
      </ion-row>

      <ion-row class="tap-instructions">
        <ion-col class="lateral-padding task">
          <h4 *ngIf="step === 1">{{"perform.tap_when_you_begin" | translate}}</h4>
          <h4 *ngIf="step === 2">{{"perform.hold_breath_in_area" | translate}}</h4>
          <h4 *ngIf="step === 3">{{"perform.tap_when_you_begin" | translate}}</h4>
        </ion-col>
      </ion-row>

      <div class="animate-button-container">
        <div
          [class.animate-button-initial]="step === 1"
          [class.animate-button-expand]="step === 2"
          [class.animate-button-contract]="step === 3"
          class="animate-button">
        </div>

        <ion-button class="round-button" (click)="nextStep()">
          <div class="step-counter">
            <ion-icon name="add-circle"></ion-icon>
          </div>
        </ion-button>
      </div>

      <div class="controls-holder padding-bottom-iphone-x lateral-padding" *ngIf="round >= 2">
        <ion-row class="buttons-holder">
          <ion-col size="3"></ion-col>
          <ion-col size="6">
            <cl-prev-button
              [label]="'perform.done' | translate"
              canClick="true" (action)="showResults()">
            </cl-prev-button>
          </ion-col>
          <ion-col size="3"></ion-col>
        </ion-row>
      </div>

    </div>

    <div class="exercise-wrapper" *ngIf="view === 'results'">
      <ion-row class="title-holder lateral-padding results-title">
        <ion-col>
          <h4>{{"perform.your_results" | translate}}</h4>
        </ion-col>
      </ion-row>

      <div class="result-container">
        <div class="indicator-container">
          <div class="indicator-base indicator-img"></div>
          <div class="indicator indicator-img" [style.transform]="'rotate(' + getRotateIndicator() + 'deg)'"></div>
        </div>
      </div>

      <ion-row class="title-holder lateral-padding">
        <ion-col class="results-text">
          <h4 *ngIf="breathsPerMin > 18">{{"perform.fast" | translate}}</h4>
          <h4 *ngIf="breathsPerMin >= 12 && breathsPerMin <= 18">{{"perform.normal" | translate}}</h4>
          <h4 *ngIf="breathsPerMin < 12">{{"perform.slow" | translate}}</h4>
          <h4>{{getRoundedBreathesPerMin()}} {{"perform.breaths_minute" | translate}}</h4>
        </ion-col>
      </ion-row>

      <div class="controls-holder padding-bottom-iphone-x lateral-padding">
        <ion-row class="buttons-holder">
          <ion-col size="8">
            <cl-prev-button [label]="'perform.do_again' | translate"
                            canClick="true" (action)="onRepeat()"></cl-prev-button>
          </ion-col>
          <ion-col size="4">
            <cl-next-button [label]="'perform.next' | translate"
                            canClick="true" (action)="goToWellDone()"></cl-next-button>
          </ion-col>
        </ion-row>
      </div>

    </div>

    <div class="exercise-wrapper" *ngIf="view === 'well-done'">
      <ion-row class="title-holder lateral-padding results-title ion-padding">
        <ion-col>
          <h4>{{"perform.well_done" | translate}}</h4>
          <h4 class="checkin-exercise-intro">{{"perform.lets_to_another_mindfulness_exercise" | translate}}</h4>
        </ion-col>
      </ion-row>

      <ion-row class="slider-holder welldone-options-holder lateral-padding ion-padding">
        <ion-col>
          <cl-action-button
            label="{{'perform.do_again' | translate}}"
            [whiteBg]="true"
            [square]="true"
            [fullWidth]="true"
            [canClick]="true" (action)="onRepeat()">
          </cl-action-button>

          <cl-action-button
            class="review-btn" (action)="onImFeelingLucky()"
            [whiteBg]="true"
            [square]="true"
            [fullWidth]="true"
            label="{{ 'perform.im_feeling_lucky' | translate }}"
            [canClick]="true">
          </cl-action-button>
        </ion-col>
      </ion-row>

      <div class="controls-holder padding-bottom-iphone-x lateral-padding">
        <ion-row class="buttons-holder">
          <ion-col size="3"></ion-col>
          <ion-col size="6">
            <cl-next-button
              [label]="'perform.done' | translate"
              canClick="true"
              (action)="complete()">
            </cl-next-button>
          </ion-col>
          <ion-col size="3"></ion-col>
        </ion-row>
      </div>
    </div>

    <div class="exercise-wrapper" *ngIf="view === 'checkin-exercise'">
      <ion-row class="title-holder lateral-padding results-title ion-padding">
        <ion-col>
          <h4 class="checkin-exercise-intro">{{"perform.lets_to_another_mindfulness_exercise" | translate}}</h4>
        </ion-col>
      </ion-row>

      <ion-row class="slider-holder checkin-exercise-holder lateral-padding ion-padding">
        <ion-col>
          <cl-checkin-exercise
            [graphic]="checkinExercise.graphic"
            [title]="checkinExercise.title"
            [instructions]="checkinExercise.instructions">
          </cl-checkin-exercise>
        </ion-col>
      </ion-row>

      <div class="controls-holder padding-bottom-iphone-x lateral-padding">
        <ion-row class="buttons-holder">
          <ion-col size="3"></ion-col>
          <ion-col size="6">
            <cl-next-button
              [label]="'perform.done' | translate"
              canClick="true"
              (action)="onClose()">
            </cl-next-button>
          </ion-col>
          <ion-col size="3"></ion-col>
        </ion-row>
      </div>
    </div>

    <div class="waves-bg"></div>
  </div>
</ion-content>
