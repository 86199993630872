// sync reducer

import * as syncActions from '../actions/sync.actions';

export const getLastSyncItemAtStateByProperty =
  (property: string, state: SyncState, syncTime = null) =>
    Object.assign({}, state.lastSyncTimestamps, {[property]: syncTime || new Date().toISOString()});

const currentDayToLocal = () => {
  const date = new Date();
  const local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  return local.toJSON()
    .slice(0, 10);
};

export interface SyncState {
  fullSynced: boolean;
  userGoalsSyncing: boolean;
  userFavsSyncing: boolean;
  userProgramSyncing: boolean;
  programSyncing: boolean;
  iridiumTokenSyncing: boolean;
  syncing: boolean;
  currentDay: string;
  lastSyncEverythingAt: string;
  lastSyncTimestamps: SyncStateLastItemAt;
  resumedAt: number;
}

export interface SyncStateLastItemAt {
  userBootstrap: string;
  programBonusExercises: string;
  userProgram: string;
  userProgress: string;
  userReminders: string;
  userGoals: string;
  userFavs: string;
  userAccounts: string;
  programs: string;
  subscriptions: string;
  communityPosts: string;
  communityBookmarkedPosts: string;
  communityJournal: string;
  checkIns: string;
  stressTests: string;
  cravingMeters: string;
  disOMeters: string;
  awarenessQuizzes: string;
  anxietyQuizzes: string;
  videoChatSchedules: string;
  themeWeeks: string;
  weightActivities: string;
  worryTool: string;
  programDays: string;
  exercises: string;
}

export const initialSyncState: SyncState = {
  fullSynced: false,
  userGoalsSyncing: false,
  userProgramSyncing: false,
  userFavsSyncing: false,
  programSyncing: false,
  iridiumTokenSyncing: false,
  syncing: false,
  currentDay: currentDayToLocal(),
  lastSyncEverythingAt: null,
  lastSyncTimestamps: {
    userBootstrap: null,
    programBonusExercises: null,
    userProgram: null,
    userProgress: null,
    userReminders: null,
    userGoals: null,
    userFavs: null,
    userAccounts: null,
    programs: null,
    subscriptions: null,
    communityPosts: null,
    communityBookmarkedPosts: null,
    communityJournal: null,
    checkIns: null,
    stressTests: null,
    cravingMeters: null,
    disOMeters: null,
    awarenessQuizzes: null,
    anxietyQuizzes: null,
    videoChatSchedules: null,
    themeWeeks: null,
    weightActivities: null,
    worryTool: null,
    programDays: null,
    exercises: null
  },
  resumedAt: null
};

export function syncReducer(state = initialSyncState, action: syncActions.SyncActions): SyncState {
  switch (action.type) {
    case syncActions.SYNC_EVERYTHING: {
      return {
        ...state,
        syncing: true,
        lastSyncEverythingAt: new Date().toISOString(),
        lastSyncTimestamps: initialSyncState.lastSyncTimestamps
      };
    }

    case syncActions.SYNC_EVERYTHING_FAIL: {
      return {
        ...state,
        syncing: false,
        lastSyncEverythingAt: null,
        lastSyncTimestamps: initialSyncState.lastSyncTimestamps
      };
    }

    case syncActions.SYNC_EVERYTHING_COMPLETE: {
      return {
        ...state,
        fullSynced: true,
        syncing: false
      };
    }

    case syncActions.SYNC_USER_BOOTSTRAP: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('userBootstrap', state, action.payload)
      };
    }

    case syncActions.SYNC_USER_PROGRAM: {
      return {
        ...state,
        userProgramSyncing: true,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('userProgram', state, action.payload)
      };
    }

    case syncActions.SYNC_USER_PROGRAM_COMPLETE: {
      return {
        ...state,
        userProgramSyncing: false
      };
    }

    case syncActions.SYNC_USER_PROGRESS: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('userProgress', state, action.payload)
      };
    }

    case syncActions.SYNC_BONUS_EXERCISES: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('programBonusExercises', state, action.payload)
      };
    }

    case syncActions.SYNC_USER_REMINDERS: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('userReminders', state, action.payload)
      };
    }

    case syncActions.SYNC_USER_GOALS: {
      return {
        ...state,
        userGoalsSyncing: true,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('userGoals', state, action.payload)
      };
    }

    case syncActions.SYNC_USER_GOALS_COMPLETE: {
      return {
        ...state,
        userGoalsSyncing: false
      };
    }

    case syncActions.SYNC_USER_ACCOUNT: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('userAccounts', state, action.payload)
      };
    }

    case syncActions.SYNC_USER_FAVS: {
      return {
        ...state,
        userFavsSyncing: true,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('userFavs', state, action.payload)
      };
    }

    case syncActions.SYNC_USER_FAVS_COMPLETE: {
      return {
        ...state,
        userFavsSyncing: false
      };
    }
    case syncActions.SYNC_PROGRAM: {
      return {
        ...state,
        programSyncing: true,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('programs', state, action.payload)
      };
    }

    case syncActions.SYNC_PROGRAM_SUCCESS:
    case syncActions.SYNC_PROGRAM_FAIL: {
      return {
        ...state,
        programSyncing: false
      };
    }

    case syncActions.SYNC_SUBSCRIPTION: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('subscriptions', state, action.payload)
      };
    }

    case syncActions.SYNC_COMMUNITY_POSTS: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('communityPosts', state, action.payload)
      };
    }

    case syncActions.SYNC_COMMUNITY_BOOKMARKED_POSTS: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('communityTrackingPosts', state, action.payload)
      };
    }

    case syncActions.SYNC_COMMUNITY_JOURNAL: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('communityJournal', state, action.payload)
      };
    }

    case syncActions.SYNC_CHECKINS: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('checkIns', state, action.payload)
      };
    }

    case syncActions.SYNC_STRESS_TESTS: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('stressTests', state, action.payload)
      };
    }

    case syncActions.SYNC_CRAVING_METERS: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('cravingMeters', state, action.payload)
      };
    }

    case syncActions.SYNC_DIS_O_METERS: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('disOMeters', state, action.payload)
      };
    }

    case syncActions.SYNC_AWARENESS_QUIZZES: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('awarenessQuizzes', state, action.payload)
      };
    }

    case syncActions.SYNC_ANXIETY_QUIZZES: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('anxietyQuizzes', state, action.payload)
      };
    }

    case syncActions.SYNC_VIDEO_CHAT_SCHEDULES: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('videoChatSchedules', state, action.payload)
      };
    }

    case syncActions.SYNC_THEME_WEEKS: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('themeWeeks', state, action.payload)
      };
    }

    case syncActions.SYNC_WEIGHT_ACTIVITIES: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('weightActivities', state, action.payload)
      };
    }

    case syncActions.SYNC_PROGRAM_DAYS: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('programDays', state, action.receivedTimestamp)
      };
    }

    case syncActions.SYNC_EXERCISES: {
      return {
        ...state,
        lastSyncTimestamps: getLastSyncItemAtStateByProperty('exercises', state, action.receivedTimestamp)
      };
    }

    case syncActions.SYNC_IRIDIUM_TOKEN: {
      return {
        ...state,
        iridiumTokenSyncing: true
      };
    }
    case syncActions.SYNC_IRIDIUM_TOKEN_COMPLETE: {
      return {
        ...state,
        iridiumTokenSyncing: false
      };
    }

    case syncActions.RESET_CURRENT_DAY: {
      const newCurrentDay = action.payload;

      return {
        ...state,
        currentDay: newCurrentDay
      };
    }

    case syncActions.RESUME_APP: {
      return {
        ...state,
        resumedAt: new Date().getTime()
      };
    }

  }

  return state;
}

export const fullSynced = (state: SyncState) => state.fullSynced;
export const syncing = (state: SyncState) => state.syncing;
export const programSyncing = (state: SyncState) => state.programSyncing;
export const userProgramSyncing = (state: SyncState) => state.userProgramSyncing;
export const userGoalsSyncing = (state: SyncState) => state.userGoalsSyncing;
export const userFavsSyncing = (state: SyncState) => state.userFavsSyncing;
export const iridiumTokenSyncing = (state: SyncState) => state.iridiumTokenSyncing;
export const currentDay = (state: SyncState) => state.currentDay;
export const resumedAt = (state: SyncState) => state.resumedAt;
export const lastSyncEverythingAt = (state: SyncState) => state.lastSyncEverythingAt;
export const lastSyncExercises = (state: SyncState) => state.lastSyncTimestamps.exercises;
