<ion-content #ionContent>
  <form [formGroup]="form" *ngIf="{ selectedSlide: selectedSlideNumber$ | async } as asyncs">
    <swiper
    #swiper
    [allowSlideNext]="false"
    [autoHeight]="true"
    [speed]="200"
    [resizeObserver]="true"
    effect="fade"
    [fadeEffect]="{ crossFade: true }"
    [allowTouchMove]="false">

      <ng-template swiperSlide>
        <section id="intro">
          <div class="content">
            <ng-container *ngTemplateOutlet="intro"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section id="programPaths">
          <div class="content">
            <ng-container *ngTemplateOutlet="programPaths"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section id="mainGoal">
          <div class="content">
            <ng-container *ngTemplateOutlet="mainGoal"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section id="contentEngagement">
          <div class="content">
            <ng-container *ngTemplateOutlet="contentEngagement"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section id="weightTracking">
          <div class="content">
            <ng-container *ngTemplateOutlet="weightTracking"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>

      <ng-template swiperSlide>
        <section id="recommendedPrograms">
          <div class="content">
            <ng-container *ngTemplateOutlet="recommendedPrograms"></ng-container>
          </div>
          <div class="footer-placeholder"></div>
        </section>
      </ng-template>
    </swiper>
  </form>
  <div class="scrolled-to-bottom-observer" [class.is-android]="platform.is('android')" #scrolledToBottomObserver></div>
</ion-content>

<ion-footer
  *ngIf="{
    selectedSlide: selectedSlideNumber$ | async,
    selectedSlideId: selectedSlideId$ | async,
    isSlideScrolledToBottom: isSlideScrolledToBottom$ | async
  } as asyncs"
  [class.single-element]="!shouldShowDottedStep(asyncs.selectedSlideId)"
  [class.sticky]="asyncs.isSlideScrolledToBottom"
>
  <div class="footer">
    <cl-dotted-steps-indicator
      *ngIf="shouldShowDottedStep(asyncs.selectedSlideId)"
      [status$]="dottedStepsStatus$"
    ></cl-dotted-steps-indicator>
    <ng-container *ngIf="asyncs.selectedSlideId === slides.Intro">
      <ng-container *ngTemplateOutlet="buttonNext; context: { label: 'common.next' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlideId === slides.ProgramPaths">
      <ng-container *ngTemplateOutlet="buttonNext; context: { label: 'account_setup.ern_diagnostic_onboarding.buttons.start_with_questions' }"></ng-container>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlideId === slides.MainGoal">
      <ion-button
        color="action"
        (click)="trackEventAndNavigateToNextSlide()"
        [disabled]="isStepInvalid(slides.MainGoal)"
      >
        {{ 'common.next' | translate }}
        <ion-icon name="chevron-forward-outline"></ion-icon>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-button>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlideId === slides.ContentEngagement">
      <ion-button
        color="action"
        (click)="handleFoodEngamentNextSlide()"
        [disabled]="isStepInvalid(slides.ContentEngagement)"
      >
        {{ 'common.next' | translate }}
        <ion-icon name="chevron-forward-outline"></ion-icon>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-button>
    </ng-container>
    <ng-container *ngIf="asyncs.selectedSlideId === slides.WeightTracking">
      <ion-button
        color="action"
        (click)="trackEventAndNavigateToNextSlide()"
        [disabled]="isStepInvalid(slides.WeightTracking)"
      >
        {{ 'common.next' | translate }}
      <ion-icon name="chevron-forward-outline"></ion-icon>
      <ion-ripple-effect></ion-ripple-effect>
    </ion-button>
    </ng-container>
  </div>
</ion-footer>

<ng-template #intro>
  <h2>{{'account_setup.ern_diagnostic_onboarding.slides.welcome_to_ern' | translate}}</h2>
  <img class="intro-image" src="assets/imgs/account-setup/intro_image.svg" alt="Eat Right Now" />
  <p [innerHTML]="'account_setup.ern_diagnostic_onboarding.slides.excited_to_work_with_you' | translate"></p>
</ng-template>

<ng-template #programPaths>
  <h2>{{'account_setup.ern_diagnostic_onboarding.slides.program_paths' | translate}}</h2>

  <div class="path-paragraph">
    <div class="title">
      <img src="assets/imgs/account-setup/mindful_eating.svg" alt="Mindful Eating" />
      <h4>{{'account_setup.ern_diagnostic_onboarding.slides.mindful_eating_path' | translate}}</h4>
    </div>
    <div class="text" [innerHTML]="'account_setup.ern_diagnostic_onboarding.slides.mindful_eating_path_description' | translate"></div>
  </div>

  <div class="path-paragraph">
    <div class="title">
      <img src="assets/imgs/account-setup/weight_loss.svg" alt="Weight Loss" />
      <h4>{{'account_setup.ern_diagnostic_onboarding.slides.weight_loss_path' | translate}}</h4>
    </div>
    <div class="text" [innerHTML]="'account_setup.ern_diagnostic_onboarding.slides.weight_loss_path_description' | translate"></div>
  </div>
</ng-template>

<ng-template #mainGoal>
  <h2>{{'account_setup.ern_diagnostic_onboarding.slides.what_is_your_main_goal' | translate}}</h2>
  <div class="flex-container">
    <div class="custom-checkbox-container" *ngFor="let option of mainGoalModel.items">
      <input class="custom-checkbox" type="checkbox" [id]="'option-' + option.value" [formControl]="option.control">
      <label class="custom-checkbox-label" (click)="optionSelected(option.control)">{{ option.label | translate }}</label>
    </div>
    <h4>{{'account_setup.ern_diagnostic_onboarding.slides.other' | translate}}</h4>
    <ion-textarea
      [rows]="6"
      [maxlength]="TEXTAREA_CHARACTER_LIMIT"
      name="customGoal"
      class="customGoal"
      [formControl]="form.get('customGoal')"
    ></ion-textarea>
    <div class="character-counter">{{ TEXTAREA_CHARACTER_LIMIT - form.get('customGoal').value.length }} / {{TEXTAREA_CHARACTER_LIMIT}}</div>
  </div>
</ng-template>

<ng-template #contentEngagement>
  <h2>{{'account_setup.ern_diagnostic_onboarding.slides.how_do_you_feel' | translate}}</h2>
  <div class="flex-container">
    <div class="custom-checkbox-container" *ngFor="let option of contentEngagementModel.items">
      <input class="custom-checkbox" type="checkbox" [id]="'option-' + option.value" [formControl]="option.control">
      <label class="custom-checkbox-label" (click)="optionSelected(option.control, true)">{{ option.label | translate }}</label>
    </div>
  </div>
</ng-template>

<ng-template #weightTracking>
  <h2>{{'account_setup.ern_diagnostic_onboarding.slides.how_comfortable_are_you_with_tracking' | translate}}</h2>
  <p [innerHTML]="'account_setup.ern_diagnostic_onboarding.slides.using_a_scale' | translate"></p>
  <div class="flex-container">
    <div class="custom-checkbox-container" *ngFor="let option of weightTrackingModel.items">
      <input class="custom-checkbox" type="checkbox" [id]="'option-' + option.value" [formControl]="option.control">
      <label class="custom-checkbox-label" (click)="optionSelected(option.control, true)">{{ option.label | translate }}</label>
    </div>
  </div>
</ng-template>

<ng-template #recommendedPrograms>
  <h2>{{'account_setup.ern_diagnostic_onboarding.slides.thank_you_for_reponses' | translate}}</h2>
  <p [innerHTML]="'account_setup.ern_diagnostic_onboarding.slides.based_on_your_answers' | translate"></p>
  <div class="flex-container">
    <cl-program-path
      *ngFor="let path of recommendedPaths; let i = index"
      [data]="path"
      [showRecommendedBadge]="i === 0"
      [showLanguageWarning]="userProgramPayload?.language_code === 'es' && path.value === ErnContentProgram.WeightLoss"
      [colorInversed]="i !== 0"
      [buttonLabel]="i === 0 ? path.mainButtonLabel : path.alternativeButtonLabel"
      (pathSelected)="onPathSelected($event)"
      (descriptionExtended)="onDescriptionExtended()"
      >
    </cl-program-path>
  </div>
</ng-template>

<ng-template #buttonNext let-step="step" let-label="label">
  <ion-button
    color="action"
    (click)="tryNavigateToNext()"
    [disabled]="step && isStepInvalid(step)"
  >
    {{ label || 'common.next' | translate }}
    <ion-icon name="chevron-forward-outline"></ion-icon>
    <ion-ripple-effect></ion-ripple-effect>
  </ion-button>
</ng-template>
