import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CravingTool } from '../../../store/normalized/schemas/craving-tool.schema';

type CravingType = 'type' | 'amount';

@Component({
  selector: 'cl-simulation-step',
  styleUrls: ['../craving-tool.scss', 'simulation-step.component.scss'],
  template: `
    <ion-content>
      <div class="first-steps lateral-padding">
        <ion-row class="switch-buttons-row">
          <ion-col class="ion-text-center">
            <ion-button expand="block" size="normal"
                    [color]="typeSelected ? 'action' : 'white'"
                    [ngClass]="{ 'button-active' : typeSelected, 'button-inactive': !typeSelected }"
                    (click)="selectCravingType('type')">
              <b> {{ 'wizards.type' | translate }}</b>
            </ion-button>
          </ion-col>

          <ion-col size="1"></ion-col>

          <ion-col class="ion-text-center">
            <ion-button expand="block" size="normal"
                    [color]="amountSelected ? 'action' : 'white'"
                    [ngClass]="{ 'button-active' : amountSelected, 'button-inactive': !amountSelected }"
                    (click)="selectCravingType('amount')">
              <b> {{ 'wizards.amount' | translate }}</b>
            </ion-button>
          </ion-col>
        </ion-row>
        <div class="use-grid">
          <cl-inner-item *ngFor="let step of simulationSteps; index as stepNumber"
                         [active]="simulationStep === stepNumber"
                         [completed]="simulationStep > stepNumber"
                         (changeItem)="completeSimulationStep(stepNumber)"
                         [label]="step | translate">
          </cl-inner-item>
        </div>
      </div>
    </ion-content>

    <ion-footer mode="ios">
      <div class="controls-holder padding-bottom-iphone-x">
        <ion-row class="buttons-holder lateral-padding">
          <ion-col size="6" offset="3">
            <cl-next-button
              [ngClass]="{'dark-bg': darkBackground}"
              label="common.next"
              [canClick]="simulationNextEnabled"
              (action)="finish()">
            </cl-next-button>
          </ion-col>
        </ion-row>
      </div>
    </ion-footer>
  `
})
export class SimulationStepComponent {
  @Input() currentCravingTool: CravingTool;
  @Input() darkBackground = false;
  @Output() next = new EventEmitter();

  simulationStepsType = [
    'craving_tool.bring_to_mind_the_food_you_are_struggling_with_type',
    'craving_tool.imagine_eating_what_you_usually_eat_type',
    'craving_tool.let_it_settle_in_your_stomach',
    'craving_tool.lets_focus_on_what_it_feels_like_in_your_stomach',
    'craving_tool.notice_your_thoughts_and_emotions'
  ];

  simulationStepsAmount = [
    'craving_tool.bring_to_mind_the_food_you_are_struggling_with_quantity',
    'craving_tool.imagine_eating_what_you_usually_eat_quantity',
    'craving_tool.let_it_settle_in_your_stomach',
    'craving_tool.lets_focus_on_what_it_feels_like_in_your_stomach',
    'craving_tool.notice_your_thoughts_and_emotions'
  ];

  simulationStep = 0;

  get simulationNextEnabled() {
    return this.simulationStep >= this.simulationSteps.length;
  }

  get simulationSteps() {
    if (this.typeSelected) {
      return this.simulationStepsType;
    }

    if (this.amountSelected) {
      return this.simulationStepsAmount;
    }
  }

  get typeSelected() {
    return this.currentCravingTool.craving_type === 'type';
  }

  get amountSelected() {
    return this.currentCravingTool.craving_type === 'amount';
  }

  nextSimulationStep() {
    this.simulationStep += 1;
  }

  completeSimulationStep(step) {
    if (step > this.simulationStep) {
      return;
    }
    this.simulationStep = this.simulationStep > step ? step : step + 1;
  }

  finish() {
    this.next.emit();
  }

  selectCravingType(type: CravingType) {
    if (this.currentCravingTool.craving_type === type) {
      return;
    } else {
      this.simulationStep = 0;
      this.currentCravingTool.craving_type = type;
    }
  }
}
