import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getCurrentProfileAvatar } from '../store/normalized/selectors/user-profile.selectors';
import { Store } from '@ngrx/store';
import { State } from '../store';

@Injectable({providedIn: 'root'})
export class SocialService {
  private userAvatar$: Observable<string> = this.store.select(getCurrentProfileAvatar);
  private userAvatar = '';
  
  constructor(
    private store: Store<State>
  ) {
    this.userAvatar$
      .subscribe(avatar => {
        this.userAvatar = avatar;
      });
  }
  
  toggleLike(entry) {
    if (!entry) { return; }

    if (entry.current_user_liked) {
      entry.likes = {
        total_count: entry.likes ? entry.likes.total_count - 1 : 0,
        list: entry.likes.list.filter(likes => likes.avatar !== this.userAvatar)
      };      
    } else {
      entry.likes = {
        total_count: entry.likes ? entry.likes.total_count + 1 : 0,
        list: entry.likes.list.slice()
      };
      entry.likes.list.unshift({
        avatar: this.userAvatar,
        id: null,
        profile_id: null
      });
    }
    entry.current_user_liked = !entry.current_user_liked;
  
    return entry;
  }
}
