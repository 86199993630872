import { createSchemaSelectors } from 'ngrx-normalizr';
import {
  CommunityPost,
  Journal,
  VideoChat,
  bookmarkedPostSchema,
  communityPostSchema,
  journalSchema,
  videoChatsSchema
} from '../schemas/community.schema';

import { createSelector } from '@ngrx/store';

const communityPostsSchemaSelector = createSchemaSelectors<CommunityPost>(communityPostSchema);
export const getCommunityPosts = communityPostsSchemaSelector.getEntities;

const bookmarkedPostsSchemaSelector = createSchemaSelectors<CommunityPost>(bookmarkedPostSchema);
export const getBookmarkedPosts = bookmarkedPostsSchemaSelector.getEntities;

const videoChatsSchemaSelector = createSchemaSelectors<VideoChat>(videoChatsSchema);
export const getVideoChats = videoChatsSchemaSelector.getEntities;

const journalSchemaSelector = createSchemaSelectors<Journal>(journalSchema);
export const getJournals = journalSchemaSelector.getEntities;

export const getJournal = createSelector(
  getJournals,
  (journals) => {
    if (!journals) {
      return null;
    }

    return journals[0];
  }
);

export const getCommunityPostsSorted = createSelector(
  getCommunityPosts,
  (posts) => {
    if (!posts) {
      return [];
    }

    return posts.sort((a, b) => new Date(b.last_posted_at).getTime() - new Date(a.last_posted_at).getTime());
  }
);

export const getBookmarkedPostsSorted = createSelector(
  getBookmarkedPosts,
  (posts) => {
    if (!posts) {
      return [];
    }

    return posts.sort((a, b) => new Date(b.last_posted_at).getTime() - new Date(a.last_posted_at).getTime());
  }
);

export const getAllCommunityPosts = createSelector(
  getCommunityPostsSorted,
  getBookmarkedPostsSorted,
  (communityPosts, bookmarkedPosts) => {
    if (!communityPosts) {
      communityPosts = [];
    }

    if (!bookmarkedPosts) {
      bookmarkedPosts = [];
    }

    const bookmarkedPostsCount = bookmarkedPosts.length;
    const bookmarkedPostsIndex = bookmarkedPosts.map((post) => post && post.id ? post.id : 0);

    // remove duplicate posts if they are already included in the bookmarked ones
    const filteredCommunityPosts = communityPosts.filter((post) => post && post.id ? bookmarkedPostsIndex.indexOf(post.id) === -1 : true);

    // display at most 5 bookmarked posts and up to 10 comunity posts, but never more than 10 in total
    const allPosts = {
      bookmarkedPosts:  bookmarkedPosts.slice(0, 6),
      communityPosts: filteredCommunityPosts.slice(0, Math.max(4, 10 - bookmarkedPostsCount))
    };

    return allPosts;
  }
);
