import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { MinutesActivity } from '../../../store/normalized/schemas/minutes-activity.schema';
import {
  AddManualMinutesActivity,
  RemoveManualMinutesActivity
} from '../../../store/session/actions/integrations.actions';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { format } from 'date-fns';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-minutes-activity-card',
  styleUrls: ['minutes-activity-card.component.scss'],
  template: `
    <ion-grid>
      <ion-row class="row">
        <ion-col class="ion-text-left">
          {{ activityTime }}
        </ion-col>
        <ion-col class="ion-text-right value-minutes">
          {{activity.minutes + ' ' + ((activity.minutes > 1 ? 'my_activity.mins':'my_activity.min') | translate)}}
        </ion-col>
        <ion-col  class="ion-text-right ion-align-self-center retry-col" *ngIf="activity.localState?.status === 'failed'">
          <div class="retry-container">
            <ion-icon class="retry-icon" name="alert-circle-outline" (click)="retryAddAction()" ></ion-icon>
          </div>
        </ion-col>
        <ion-col class="ion-text-right ion-align-self-center remove-col">
          <div class="remove-container">
            <ion-icon name="trash" class="remove" (click)="deleteActivity()"></ion-icon>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  `
})
export class MinutesActivityCardComponent {
  @Input() activity: MinutesActivity;

  get activityDate() {
    return format(new Date(this.activity.activity_at),'lll')
      .replace(',','');
  }

  get activityTime() {
    return format(new Date(this.activity.activity_at),'p')
      .replace(',','');
  }

  get activityDateTime() {
    return format(new Date(this.activity.activity_at),'lll')
      .replace(',','');
  }

  constructor(
    private translate: TranslateService,
    public alertController: AlertController,
    private stateStore: Store<State>
  ) {}

  retryAddAction() {
    this.translate.get(
      ['my_activity.error', 'my_activity.retry' ,'my_activity.error_message_add',
        'my_activity.error_message_delete','my_activity.retry_delete', 'common.cancel']
    ).subscribe(async (translations) => {
      const alert = await this.alertController.create({
        header: translations['my_activity.error'],
        message: translations[this.activity.localState.action === 'delete' ? 'my_activity.error_message_delete':'my_activity.error_message_add'],
        buttons: [
          {
            text: translations['common.cancel']
          },
          {
            text: translations['my_activity.retry'],
            handler: () => {
              if(this.activity.localState.action === 'delete') {
                this.stateStore.dispatch(new RemoveManualMinutesActivity(this.activity));
              } else {
                this.stateStore.dispatch(new AddManualMinutesActivity(this.activity.minutes, new Date(this.activity.activity_at), this.activity.id));
              }
            }
          }]
      });

      await alert.present();
    });
  }

  deleteActivity() {
    this.translate.get(
      ['my_activity.remove_activity_minutes', 'common.remove','common.yes', 'common.cancel']
    ).subscribe(async (translations) => {
      const alert = await this.alertController.create({
        header: translations['common.remove'],
        message: translations['my_activity.remove_activity_minutes'],
        buttons: [
          {
            text: translations['common.cancel']
          },
          {
            text: translations['common.yes'],
            handler: () => this.stateStore.dispatch(new RemoveManualMinutesActivity(this.activity))
          }]
      });

      await alert.present();
    });
  }
}
