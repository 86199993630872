import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription, interval, of } from 'rxjs';
import { catchError, map, take, startWith } from 'rxjs/operators';

import { ClarityConfig } from '../config/clarity.config';
import { offlineQueueSettings } from '../config/offline-queue.const';
import { ConnectivityService } from './connectivity.service';
import { HttpProvider } from '../providers/http/http.provider';

import {
  getOfflineQueue,
  getOfflineQueueCount
} from '../store/session/selectors/offline-queue.selectors';
import * as offlineQueueActions from '../store/session/actions/offline-queue.actions';
import { OfflineRequest } from '../store/session/reducers/offline-queue.reducer';
import { State } from '../store/state.reducer';
import { EventsService } from './events.service';

const getMinRetryTimestamp = (retries) => new Date().getTime() - (offlineQueueSettings.RETRY_DELAY * Math.pow(2, retries));

@Injectable({providedIn: 'root'})
export class OfflineQueueService {

  private offlineQueue$ = this.store.select(getOfflineQueue);
  private inProcessQueue: string[] = [];

  private queueingEnabled = false;
  private replayingEnabled = false;

  private replaySubscription: Subscription;

  constructor(
    private config: ClarityConfig,
    private store: Store<State>,
    private events: EventsService,
    private connection: ConnectivityService,
    private http: HttpProvider
  ) {

  }

  public initialize() {
    this.events.subscribe(this.config.events.connection + 'online', () => {
      this.replayingEnabled = true;

      this.checkQueue();
    });

    this.events.subscribe(this.config.events.connection + 'offline', () => {
      this.replayingEnabled = false;
      this.stopReplay();
    });

    this.events.subscribe(this.config.events.logout, () => {
      this.disableQueueing();

      // clear internal queue also on logout
      this.inProcessQueue = [];

      this.replayingEnabled = false;
      this.stopReplay();
    });

    return Promise.resolve();
  }

  public enableQueueing() {
    console.log('OfflineQueue - Enabling offline queueing');
    this.queueingEnabled = true;
    this.replayingEnabled = true;
    this.checkQueue();
  }

  public checkQueue() {
    if (!this.connection.isOnline()) {
      return false;
    }

    if (!this.replayingEnabled) {
      return false;
    }

    this.store.select(getOfflineQueueCount)
      .pipe(take(1))
      .subscribe((queueCount) => {
        if (!queueCount) {
          console.log('OfflineQueue - Offline queue is empty!');

          return false;
        }

        // delay starting the queue in case there are multiple requests to be queued
        setTimeout(() => {
          this.replayQueue();
        }, 1000);
      });
  }

  public sendRequests(idsToSend) {
    this.inProcessQueue = idsToSend;

    console.log('OfflineQueue - Scheduling offline requests', idsToSend);

    this.offlineQueue$.pipe(take(1))
      .subscribe((queue) => {
        const requests = queue.filter((request) => idsToSend.indexOf(request.id) > -1);

        requests.forEach((request) => {
          this.sendRequest(request);
        });
      });
  }

  public queueIsReplaying() {
    return this.replaySubscription && !this.replaySubscription.closed
      ? true
      : false;
  }

  public forceQueueProcess() {
    this.processQueue(true);
  }

  public canQueue() {
    return this.queueingEnabled;
  }

  private replayQueue() {
    // make sure the queue is not already running
    if (this.queueIsReplaying()) {
      return false;
    }

    this.replaySubscription = interval(offlineQueueSettings.CHECK_INTERVAL)
      .pipe(
        startWith(0)
      )
      .subscribe(() => {
        this.store.select(getOfflineQueueCount)
          .pipe(take(1))
          .subscribe((queueCount) => {
            if (!queueCount) {
              console.log('OfflineQueue - Queue is empty!');

              return this.stopReplay();
            }

            console.log('OfflineQueue - Requesting queue process', this.replaySubscription);
            this.processQueue();
          });
      });
  }

  private processQueue(force = false) {
    // pull retryable queue and fill the process queue
    this.offlineQueue$.pipe(take(1))
      .subscribe((queue) => {
        // if it's a new request try immediately, this could be when internet gets back or just use the regular delay
        let retryQueue = queue.filter((request) => request.retries === 0 || request.lastRetriedAt < getMinRetryTimestamp(request.retries));

        // for debugging skip the retry delay
        if (force) {
          retryQueue = queue;
        }

        console.log('OfflineQueue - Processing offline queue', retryQueue);

        const slice = retryQueue.slice(0, Math.max(0, offlineQueueSettings.RETRIES_PER_RUN - this.inProcessQueue.length));
        const toSchedule = slice.map((request) => request.id);

        // make sure we pickup any leftovers - i.e. internet goes offline while processing
        if (this.inProcessQueue.length) {
          toSchedule.push(...this.inProcessQueue);
        }

        if (!toSchedule.length) {
          return false;
        }

        this.store.dispatch(new offlineQueueActions.SendRequests(toSchedule));
      });
  }

  private sendRequest(offlineRequest: OfflineRequest) {
    // if the internet goes offline mid queue, stop processing
    if (!this.replayingEnabled) {
      console.log('Cannot replay!');

      return false;
    }

    const request = offlineRequest.request;
    const method = request.type.toLowerCase();

    console.log('OfflineQueue - Sending offlineRequest', offlineRequest);

    this.removeFromInProcessQueue(offlineRequest.id);

    this.http[method](request.endpoint, request.payload || undefined, request.options || undefined)
      .pipe(
        map((result) => {
          console.log('OfflineQueue - Offline request fulfilled', result);

          this.store.dispatch(new offlineQueueActions.RequestFulfilled({
            requestId: offlineRequest.id,
            status: result && result['status'] || null
          }));
        }),
        catchError((error) => {
          console.log('OfflineQueue - Offline request failed', error);
          if(request?.ignoreHttpResponseCode?.includes(error.status)) {
            this.store.dispatch(new offlineQueueActions.RequestFulfilled({
              requestId: offlineRequest.id,
              status: null
            }));

            return of(null);
          }

          this.store.dispatch(new offlineQueueActions.RequestFailed({
            requestId: offlineRequest.id,
            status: Number.isInteger(error['status']) ? error['status'] : null,
            error: error['message'] || 'n/a'
          }));

          return of(null);
        })
      )
      // force request execution
      .pipe(take(1))
      .toPromise();
  }

  private removeFromInProcessQueue(requestId) {
    this.inProcessQueue = this.inProcessQueue.filter((id) => id !== requestId);
  }

  private disableQueueing() {
    console.log('OfflineQueue - Disabling offline queueing');

    this.queueingEnabled = false;
  }

  private stopReplay() {
    this.replaySubscription && this.replaySubscription.unsubscribe();

    console.log('OfflineQueue - Stopped queue replaying!');
  }
}
