import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { ProgramTypeService } from '../services/program-type.service';

declare const config: any; // clarity.constants.js

@Injectable({providedIn: 'root'})
export class ClarityConfig {

  public static readonly DEFAULT_LANGUAGE = 'en';

  // build config environment options based on the environment - config.env
  public env = Object.assign(
    {},
    config.common,
    config.environment
  );

  public program = config.program;

  public events = Object.assign({}, config.events);

  public buildPlatform = config.buildPlatform;

  public isDevice: boolean = null;

  public isIos: boolean = null;
  public isAndroid: boolean = null;
  public isWebApp: boolean = null;

  public runningOnIonicDevApp: boolean = null;
  public runningOnSafari: boolean = null;

  public usingMobileOnWeb: boolean = null;
  public usingDesktopOnWeb: boolean = null;

  public deviceTimezone = 'Etc/GMT';

  public forceDpp = false;
  public forceWl = false;

  constructor(
    private platform: Platform,
    private programTypeService: ProgramTypeService
  ) {
    // device detection helper
    this.isDevice = this.platform.is('cordova');

    // platform helpers
    // starging with iOS 14.6 on ipads, platform is no longer detecting ios for some reason, will ignore until capacitor upgrade
    this.isIos = (this.isDevice && this.platform.is('ios')) || this.buildPlatform === 'ios';
    this.isAndroid = this.isDevice && this.platform.is('android');
    this.isWebApp = config.buildPlatform === 'web';

    // browser helpers
    this.runningOnSafari = navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0;

    this.resetViewHelpers();


    // force DPP when deployed to dweatrightnow.com
    if (this.usesDppwlDomain()) {
      this.forceDpp = true;
    }
  }

  // prefer using `currentProgramCode` instead
  public programCode = () => this.program.programCode;

  // prefer using `currentProgramCode` instead
  public currentProgram = () => this.program.programCode.toLowerCase();

  public currentEnv = () => this.env.environment;

  public isCTQ = () => this.currentProgram() === 'ctq';
  public isERN = () => this.currentProgram() === 'ern';
  public isUA = () => this.currentProgram() === 'ua';

  // ERN app supports 3 different programs
  public programDPP = () => this.programTypeService.isDPP() || this.forceDpp;
  public programWL = () => this.programTypeService.isWL() || this.forceWl;
  public programDPPorWL = () => this.programDPP() || this.programWL();
  public programERN = () => this.isERN() && !this.programDPPorWL();

  public onDev = () => this.currentEnv() === 'dev';
  public onUat = () => this.currentEnv() === 'uat';
  public onProd = () => this.currentEnv() === 'prod';
  public onLocal = () => this.currentEnv() === 'local';

  public getOsVersion = () => this.env.device.device_version;
  public getAppVersion = () => this.env.app.app_version;
  public getBuildNumber = () => this.env.app.app_build;
  public getPackageName = () => this.env.app.package_name;

  public getLanguageOptions = () => this.program.languages
    .map((lang: string) => ({value: lang, label: `auth.${lang}`}));

  public iridiumAllowed = () => this.env.useIridium && this.program.iridium.enabled;
  public jwplayerEnabled = () => this.isWebApp && this.program.jwplayer;

  public isBrightcoveWebEnabled = () => this.isWebApp;
  public isBrightcoveEnabled = () => !this.isWebApp;
  // We should enable download on iOS only as long as https://jira.mindsciences.net/browse/CLARITY-956 is not fixed
  public isBrightcoveDownloadEnabled = () => this.isBrightcoveEnabled() && this.isIos;


  public isGoogleAuthEnabled = () => !this.onProd(); // Disabled on production until we are ready to ship it

  // we have a dev a GTM container defined in the GtmService so we can enabled on all web apps
  public gtmEnabled = () => this.isWebApp;

  public stripeWebEnabled = () => this.buildPlatform === 'web' && this.program.stripe.enabled;

  public usesDppwlDomain = () => Boolean(window.location.host.match(new RegExp(this.program.dppwlDomain)));

  get currentProgramCode() {
    return this.programDPPorWL()
      ? this.program.dppwlProgramCode
      : this.program.programCode;
  }

  get appUrl() {
    if (this.programDPPorWL()) {
      return this.program.dppwlUrl;
    }

    return this.program.appUrl;
  }

  get weeklyCallsEndpoint() {
    if (this.programDPPorWL()) {
      return this.program.dppwlWeeklyCallsEndpoint;
    }

    return this.program.weeklyCallsEndpoint;
  }

  get appDomain() {
    if (this.programDPPorWL()) {
      return this.program.dppwlDomain;
    }

    return this.program.appDomain;
  }

  get apiHost() {
    // work-around for localhost development
    if (this.onLocal()) {
      return `${this.env.apiHostname}`;
    }

    return `${this.env.apiHostname}.${this.appDomain}`;
  }

  get webappHost() {
    return `${this.env.webappHostname}.${this.appDomain}`;
  }

  get redirectUri() {
    const hostname = !this.isDevice
      ? (window as any).location.hostname
      : this.webappHost;

    return `https://${hostname}/authorize`;
  }

  get adminUrl() {
    let subdomain;
    switch (this.env.environment) {
      case 'dev':
        subdomain = 'admin.dev';
        break;
      case 'uat':
        subdomain = 'admin.uat';
        break;
      case 'prod':
        subdomain = 'admin';
        break;
      default:
        subdomain = 'admin.dev';
        break;
    }

    return `https://${subdomain}.mindsciences.net`;
  }

  get versionTrackingVars() {
    return {
      app_version: this.env.app.app_version,
      app_build: this.env.app.app_build,
      env: this.env.environment
    };
  }

  get logo() {
    return 'assets/imgs/logo_' + this.program.programCode + '.svg';
  }

  get privacyTermsUrl() {
    return this.appUrl + this.env.privacyTermsUri;
  }

  get stripeApiKey() {
    if (!this.stripeWebEnabled() || !this.program.stripe.key) {
      return;
    }

    return this.program.stripe.key;
  }

  initialize() {
    // atempt to detect timezone
    if (window['Intl'] && typeof window['Intl'] === 'object' && window['Intl'].DateTimeFormat) {
      this.deviceTimezone = window['Intl'].DateTimeFormat()
        .resolvedOptions()
        .timeZone;
    }

    // replace default values only on real devices - browser has not values
    if (!this.isDevice) {
      this.env.device.device_available = false;
      this.env.device.device_cordova = null;
      this.env.device.device_isVirtual = false;
      this.env.device.device_manufacturer = window.navigator.vendor;
      this.env.device.device_serial = window.navigator.appVersion;
      this.env.device.device_uuid = this.generateBrowserUniqueId();

      const browserInfo = this.getBrowserInfo();

      // these props are important for tech support!
      this.env.device.device_model = browserInfo.name; // Chrome, Safari, Firefox
      this.env.device.device_version = browserInfo.version;
      this.env.device.device_platform = `web ${window.navigator.platform}`; // macintel

      return Promise.resolve();
    }

    return Promise.all([
      Device.getInfo(),
      Device.getId(),
      App.getInfo()
    ])
      .then(([deviceInfo, deviceId, appInfo]) => {
        this.env.app.package_name = appInfo.id;
        this.env.app.app_version = appInfo.version;
        this.env.app.app_build = Number(appInfo.build);

        // Ion DevApp doesn't have all the plugins so we need to keep track of that
        this.runningOnIonicDevApp = this.env.app.package_name === 'io.ionic.devapp';

        if (this.runningOnIonicDevApp) {
          this.program.intercom.enabled = false;
          this.env.firebase.enabled = false;
          this.env.mixpanel.enabled = false;
        }

        this.env.device.device_available = true;
        // this.env.device.device_cordova = this.device.cordova; // NOT AVAILABLE
        this.env.device.device_isVirtual = deviceInfo.isVirtual;
        this.env.device.device_manufacturer = deviceInfo.manufacturer;
        this.env.device.device_model = deviceInfo.model;
        this.env.device.device_platform = deviceInfo.platform;
        // this.env.device.device_serial = this.device.serial; // NOT AVAILABLE
        this.env.device.device_uuid = deviceId.uuid;
        this.env.device.device_version = deviceInfo.osVersion;
      });
  }

  enforceDppProgram(forced) {
    if (!forced && !this.usesDppwlDomain()) {
      return false;
    }

    this.forceDpp = true;
    this.forceWl = false;
  }

  enforceWlProgram(forced) {
    if (!forced) {
      return false;
    }

    this.forceDpp = false;
    this.forceWl = true;
  }

  private getBrowserInfo() {
    // from https://stackoverflow.com/a/16938481/390564

    const ua = navigator.userAgent;
    let match = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    let tem;

    if (/trident/i.test(match[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];

      return {
        name: 'IE',
        version: (tem[1] || '')
      };
    }

    if (match[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);

      if (tem != null) {

        return {
          name: 'Opera',
          version: tem[1]
        };
      }
    }

    match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?'];

    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      match.splice(1, 1, tem[1]);
    }

    return {
      name: match[0],
      version: match[1]
    };
  }

  private generateBrowserUniqueId() {
    const nav = window.navigator;
    const screen = window.screen;

    let guid = nav.mimeTypes.length.toString();

    guid += nav.userAgent.replace(/\D+/g, '');
    guid += nav.plugins.length;
    guid += screen.height || '';
    guid += screen.width || '';
    guid += screen.pixelDepth || '';

    return guid;
  }

  public resetViewHelpers() {
    this.usingMobileOnWeb = this.isWebApp && this.viewIsSmall();
    this.usingDesktopOnWeb = this.isWebApp && this.viewIsLarge();
  }

  private viewIsSmall() {
    return document.documentElement.clientWidth <= 767;
  }

  private viewIsLarge() {
    return document.documentElement.clientWidth > 767;
  }
}
