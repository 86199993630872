import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-label',
  styleUrls: ['./label.component.scss'],
  template: `
      <span *ngIf="text" class="cl-label">{{text}}</span>
  `
})

export class LabelComponent {
  @Input() text: string;
}
