import { Action } from '@ngrx/store';

export const SET_CIG_COUNT = '[cig-tracker] Set Cig Count';
export const TRACK_CIG_COUNT = '[cig-tracker] Track Cig Count';
export const LOAD_CIG_COUNT = '[cig-tracker] Load Cig Count';

export const SET_SMOKE_FREE = '[cig-tracker] Set Smoke Free';
export const SET_QUIT_DATE = '[cig-tracker] Set Quit Date';

export class LoadCigCount implements Action {
  readonly type = LOAD_CIG_COUNT;
}

export class SetCigCount implements Action {
  readonly type = SET_CIG_COUNT;

  constructor(public payload: number) {
  }
}

export interface TrackCigCountData {
  date: string;
  count: number;
  smokefree: boolean;
}

export class TrackCigCount implements Action {
  readonly type = TRACK_CIG_COUNT;

  constructor(public payload: TrackCigCountData) {
  }
}

export class SetSmokeFree implements Action {
  readonly type = SET_SMOKE_FREE;

  constructor(public payload: boolean) {
  }
}

export type CigTrackerActions =
  | SetCigCount
  | TrackCigCount
  | LoadCigCount
  | SetSmokeFree;
