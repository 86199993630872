import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';

@Injectable({providedIn: 'root'})
export class UserBootstrapProvider {

  private userBootstrapEndpoint = '/user_bootstrap';

  constructor(
    private http: HttpProvider
  ) {

  }

  getUserBootstrap() {
    return this.http.get<any>(this.userBootstrapEndpoint);
  }

}
