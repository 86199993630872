import { schema } from 'normalizr';

export class Category {
  color: string;
  name: string;
  text_color: string;
}

export class CommunityPost {
  id: number;
  avatar_template: string;
  bumped_at: string;
  category_id: number;
  category: Category;
  posts_count: number;
  topic_url: string;
  last_posted_at: string;
  reply_count: number;
  title: string;
}

class LatestJournalPost {
  id: number;
  cooked: string;
  created_at: string;
  name: string;
  reply_count: number;
  username: string;
}

export class Journal {
  avatar_url: string;
  created_at: string;
  id: number;
  journal_url: string;
  last_posted_at: string;
  latest_post: LatestJournalPost;
  posts_count: number;
  reply_count: number;
  slug: string;
  title: string;
  user_id: number;
  views: number;
}

export class VideoChat {
  chat_url: string;
  created_at: string;
  id: number;
  meeting_notes: string;
  starts_at: string;
  starts_at_with_timezone: string;
  timezone: string;
  updated_at: string;
}

export const communityCategorySchema = new schema.Entity<Category>('communityCategories');

export const communityPostSchema = new schema.Entity<CommunityPost>('posts');
export const bookmarkedPostSchema = new schema.Entity<CommunityPost>('bookmarkedPosts');

export const latestPostSchema = new schema.Entity<LatestJournalPost>('latestPosts', {category: communityCategorySchema});
export const journalSchema = new schema.Entity<Journal>(
  'journals',
  {latest_post: latestPostSchema},
  {idAttribute: () => 'my'}
);

export const videoChatsSchema = new schema.Entity<VideoChat>('videoChats');
