import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { SharedModule } from '../../../shared';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ProfileCompletionPage } from './profile-completion';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    ProfileCompletionPage
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{path: '', component: ProfileCompletionPage}]),
    TranslateModule.forChild(translateConfig),
    SharedModule,
    ReactiveFormsModule,
    ImageCropperModule
  ]
})
export class ProfileCompletionModule {}
