// media selectors

import { createSelector } from '@ngrx/store';

import * as mediaReducer from './media.reducer';
import { getMediaState } from '../../../../app/store';

export const getDownloadedMedia = createSelector(
  getMediaState,
  mediaReducer.downloaded
);

export const getDefaultMediaForExercise = createSelector(
  getMediaState,
  (defaultMedia) => mediaReducer.defaultMediaForExercises(defaultMedia) || {}
);

export const getPlayedMedia = createSelector(
  getMediaState,
  (mediaState) => mediaReducer.played(mediaState) || []
);

export const getGoogleStorageDeleted = createSelector(
  getMediaState,
  mediaReducer.googleStorageDeleted
);

export const getSubtitlesLanguage = createSelector(
  getMediaState,
  (mediaState) => mediaReducer.subtitlesLanguage(mediaState)
);

export const hasSubtitlesEnabled = createSelector(
  getSubtitlesLanguage,
  (subtitlesLanguage) => !!subtitlesLanguage
);
