import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { GearsReviewPage } from './gears-review';
import { SharedModule } from '../../../shared/index';
import { translateConfig } from '../../config/translate.config';
import { AccordionGearsComponent } from './components/accordeon-gears.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    GearsReviewPage,
    AccordionGearsComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: GearsReviewPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ]
})

export class GearsReviewPageModule {}
