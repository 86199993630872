import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { Subscription, timer } from 'rxjs';

import { SessionState } from '../store/session';
import * as syncActions from '../store/session/actions/sync.actions';
import { isAuthenticated } from '../store/sensitive/selectors/auth.selectors';

import moment from 'moment-timezone';

@Injectable()
export class TimeService {

  isLoggedIn$ = this.store.select(isAuthenticated);

  dayWatchSubscription: Subscription;

  constructor(
    private store: Store<SessionState>
  ) {

  }

  public initialize() {
    this.isLoggedIn$
      .subscribe((isLoggedIn) => {
        // handle logouts
        if (!isLoggedIn) {
          return this.dayWatchSubscription && this.dayWatchSubscription.unsubscribe();
        }

        // we already have a subscription
        if (this.dayWatchSubscription && !this.dayWatchSubscription.closed) {
          return true;
        }

        // make sure we have a valid day set
        this.checkCurrentDay();

        // trigger day chnaged at midnight + 1 second
        this.dayWatchSubscription = timer((this.secondsToMidnight() + 1) * 1000, 86400 * 1000)
          .subscribe(() => {
            console.log('DAY CHANGED!');

            this.triggerDayReset();
          });
      });

    return Promise.resolve(true);
  }

  public checkCurrentDay() {
    this.triggerDayReset();
  }

  private triggerDayReset(today = null) {
    if (!today) {
      today = this.getToday();
    }

    console.log('Resetting current day', today);

    this.store.dispatch(new syncActions.ResetCurrentDay(today));
  }

  private getToday() {
    const date = new Date();
    const local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    return local.toJSON()
      .slice(0, 10);
  }

  private secondsToMidnight() {
    return moment()
      .endOf('day')
      .diff(moment(), 'seconds');

  }
}
