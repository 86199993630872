import { Injectable } from '@angular/core';

import { HttpProvider } from '../http/http.provider';
import { OfflineHttpProvider } from '../http/offline-http.provider';

@Injectable({providedIn: 'root'})
export class StressTestProvider {

  private stressTestEndpoint = '/stress_tests';

  constructor(
    protected http: HttpProvider,
    private offlineHttp: OfflineHttpProvider
  ) {

  }

  public trackStressTest(stressTest) {
    return this.offlineHttp.post(this.stressTestEndpoint, stressTest);
  }

  public getCountByDay() {
    return this.http.get(this.stressTestEndpoint + '/count_by_day' + '?tz=' + this.getTZ());
  }

  getTZ() {
    let offset = new Date().getTimezoneOffset() / 60;
    let ret = 'GMT';
    if (offset > 0) {
      ret += '-' + offset;
    } else if (offset < 0) {
      offset = -offset;
      ret += '%2B' + offset;
    }

    return ret;
  }
}
