import { createSchemaSelectors } from 'ngrx-normalizr';
import { MediaFile, Subtitle, mediaFileSchema, subtitleSchema } from '../schemas/mediaFile.schema';
import { createSelector } from '@ngrx/store';
import { getProgramModules } from './program-bootstrap.selectors';
import equal from 'fast-deep-equal';

const mediaFilesSchemaSelector = createSchemaSelectors<MediaFile>(mediaFileSchema);

const subtitlesSchemaSelector = createSchemaSelectors<Subtitle>(subtitleSchema);

let normalizedMediaFilesCopy: any = {};

export const getNormalizedMediaFiles = createSelector(
  mediaFilesSchemaSelector.getNormalizedEntities,
  (entities) => {
    if (!equal(entities.mediaFiles, normalizedMediaFilesCopy)) {
      normalizedMediaFilesCopy = entities.mediaFiles;
    }

    return normalizedMediaFilesCopy;
  }
);

export const getNormalizedSubtitles = createSelector(
  mediaFilesSchemaSelector.getNormalizedEntities,
  (entities) => entities.subtitles
);

export const getMediaFiles = mediaFilesSchemaSelector.getEntities;
export const getSubtitles = subtitlesSchemaSelector.getEntities;

export const getMediaFilesByModule = createSelector(
  getProgramModules,
  (modules) => {
    const normalizedModules = {};

    if (!modules || !modules.length) {
      return normalizedModules;
    }

    for (let i = 0; i < modules.length; i++) {
      normalizedModules[modules[i].number] = [];

      for (let j = 0; j < modules[i].program_daily_exercises.length; j++) {
        if (
          modules[i].program_daily_exercises[j].exercise &&
          modules[i].program_daily_exercises[j].exercise.media_file) {
          const mediaFile = modules[i].program_daily_exercises[j].exercise.media_file;

          normalizedModules[modules[i].number].push(
            mediaFile.data_fingerprint || mediaFile.jw_key
          );
        }
      }
    }

    console.log('$$$ SELECTOR getMediaFilesByModule');

    return normalizedModules;
  }
);
