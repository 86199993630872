import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NextStepsPage } from './next-steps';
import { ArrowCardComponent } from './components/arrow-card/arrow-card';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    NextStepsPage,
    ArrowCardComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: NextStepsPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ]
})
export class NextStepsPageModule {}
