export function extractPostId(event) {
  let post = null;

  if (event.target.className.includes('link-to-post')) {
    let postId: any = Array.from(event.target.classList)
      .find((className: string) => className.includes('post-')
      );
    if (postId) {
      postId = postId.match(/([0-9]+)/g);

      if (!postId) {
        return false;
      }

      post = {id: postId[0]};
    }
  }

  return post;
}
