import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-feeling-now-vertical-selector',
  styleUrls: ['feeling-now-vertical-selector.component.scss'],
  template: `
    <div class="slider-block">
      <ion-row>
        <ion-col class="slider-content" size="10">
          <ion-row>
            <ion-col class="label" size="8" [class.selected]="isValue100()"><p>{{'wizards.much_better' | translate}}</p></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="label" size="8" [class.selected]="isValue66()"><p>{{'wizards.a_little_better' | translate}}</p></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="label" size="8" [class.selected]="isValue33()"><p>{{'wizards.same' | translate}}</p></ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="label" size="8" [class.selected]="isValue0()"><p>{{'wizards.worse' | translate}}</p></ion-col>
          </ion-row>
        </ion-col>

        <ion-col class="slider-scale" size="2">
          <cl-vertical-slider [value]="sliderValue" (slide)="onSlide($event)"></cl-vertical-slider>
        </ion-col>
      </ion-row>
    </div>
  `
})
export class FeelingNowVerticalSelectorComponent {

  @Input() sliderValue = null;

  @Output() feeling = new EventEmitter();

  onSlide(value) {
    this.sliderValue = value;
    this.feeling.emit(this.getCorrectValue());
  }

  isValue0() {
    return this.getCorrectValue() <= 25;
  }

  isValue33() {
    return this.getCorrectValue() > 25 && this.getCorrectValue() <= 50;
  }

  isValue66() {
    return this.getCorrectValue() > 50 && this.getCorrectValue() <= 75;
  }

  isValue100() {
    return this.getCorrectValue() > 75;
  }

  getCorrectValue() {
    return this.sliderValue;
  }

}
