import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { SmokingCravingToolPage } from './craving-tool-ctq';
import { SharedModule } from '../../../shared/index';
import { InnerItemComponent } from './components/inner-step.component';
import { SmokingSimulationStepComponent } from './components/smoking-simulation-step.component';
import { SmokeOrRideCaptureStepComponent } from './components/smoke-or-ride-capture.component';
import { MindfulSmokingExerciseStepComponent } from './components/mindful-smoking-exercise-step.component';
import { AfterSmokingSatisfactionStepComponent } from './components/after-smoking-satisfaction-step.component';
import { RainExerciseStepComponent } from './components/rain-exercise-step.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    SmokingCravingToolPage,
    InnerItemComponent,
    SmokingSimulationStepComponent,
    SmokeOrRideCaptureStepComponent,
    MindfulSmokingExerciseStepComponent,
    AfterSmokingSatisfactionStepComponent,
    RainExerciseStepComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: SmokingCravingToolPage }]),
    TranslateModule.forChild(),
    SharedModule
  ],
  exports: [
    SmokingSimulationStepComponent
  ]
})

export class CravingToolModule {}
