import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'processTime'
})
export class ProcessTimePipe implements PipeTransform {

  transform(value: number): any {
    const time = moment()
      .hour(0)
      .minutes(0)
      .second(0)
      .add(value, 'seconds');
    const hours = +time.format('HH') ? `${time.format('HH')}:` : '';

    return `${hours}${time.format('mm')}:${time.format('ss')}`;
  }

}
