import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cl-circles-background',
  styleUrls: ['circles-background.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="background-circle big-circle" [class.center-content]="centerContent"></div>
    <div class="background-circle small-circle"></div>
  `
})

export class CirclesBackgroundComponent {
  @Input() centerContent: boolean;
}
