import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { ClarityConfig } from '../config/clarity.config';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'processDateInLocal'
})
export class ProcessDateInLocalPipe implements PipeTransform {

  private currentLanguage = this.translate.currentLang;

  constructor(
    public config: ClarityConfig,
    private translate: TranslateService
  ) {
    moment.locale(this.currentLanguage);
  }

  transform(value: number, fromNow: boolean, valueTimezone = 'Etc/GMT', capitalizeWeekday = false): string {
    if (capitalizeWeekday) {
      this._capitalizeWeekday();
    }

    if (fromNow) {
      let timeDifferenceInMins = moment()
        .diff(moment(value), 'minute');

      timeDifferenceInMins = Math.max(0, timeDifferenceInMins);

      if (timeDifferenceInMins <= 50) {
        return `${timeDifferenceInMins}m`;
      }
      else if (timeDifferenceInMins / 60 <= 18) {
        return `${Math.round(timeDifferenceInMins / 60)}h`;
      }
      else {
        return `${Math.round(timeDifferenceInMins / 60 / 24)}d`;
      }
    }

    try {
      return moment.tz(value, valueTimezone)
        .tz(this.config.deviceTimezone)
        .format('dddd hA z');
    } catch {
      
      return moment.tz(value, valueTimezone)
        .format('dddd hA z');
    }
  }

  private _capitalizeWeekday() {
    moment.updateLocale(this.currentLanguage, {
      weekdays: moment.weekdays()
        .map((weekdayName: string) => weekdayName[0].toUpperCase() + weekdayName.substr(1))
    });
  }

}
