import { Injectable } from '@angular/core';

import { HttpProvider } from '../http/http.provider';
import { OfflineHttpProvider } from '../http/offline-http.provider';

@Injectable({providedIn: 'root'})
export class StressMeterProvider {

  private stressMeterEndpoint = '/craving_meters';

  constructor(
    protected http: HttpProvider,
    private offlineHttp: OfflineHttpProvider
  ) {

  }

  public trackStressMeter(stressMeter) {
    return this.offlineHttp.post(this.stressMeterEndpoint, stressMeter);
  }

  public getRecent() {
    return this.http.get(this.stressMeterEndpoint + '/recent');
  }

  public getCountByDay() {
    return this.http.get(this.stressMeterEndpoint + '/count_by_day' + '?tz=' + this.getTZ());
  }

  getTZ() {
    let offset = new Date().getTimezoneOffset() / 60;
    let ret = 'GMT';
    if (offset > 0) {
      ret += '-' + offset;
    } else if (offset < 0) {
      offset = -offset;
      ret += '%2B' + offset;
    }

    return ret;
  }
}
