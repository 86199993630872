export enum ProgramCode {
  CTQ = 'CTQ',
  ERN = 'ERN',
  UFS = 'UFS',
  DPPWL = 'DPPWL',
  UA = 'UA'
}

export function getProgramName(programCode: ProgramCode): string {
  switch (programCode) {
    case ProgramCode.CTQ:
      return 'Craving To Quit';
    case ProgramCode.ERN:
    case ProgramCode.DPPWL:
      return 'Eat Right Now';
    case ProgramCode.UA:
      return 'Unwinding Anxiety';
    case ProgramCode.UFS:
      return 'Unwinding by Sharecare';
    default:
      console.error('Program not found');
  }
}
