import { createSelector } from '@ngrx/store';
import { getIridiumState } from '../../state.reducer';
import * as iridiumReducer from '../reducers/iridium.reducer';

export const isIridiumActivated = createSelector(
  getIridiumState,
  iridiumReducer.isActivated
);

export const getInAppMessages = createSelector(
  getIridiumState,
  iridiumReducer.inAppMessages
);
