import * as offlineQueueReducer from '../reducers/offline-queue.reducer';
import { createSelector } from '@ngrx/store';
import { getOfflineQueueState } from '../../../../app/store';

export const getOfflineQueue = createSelector(
  getOfflineQueueState,
  offlineQueueReducer.offlineQueue
);

export const getOfflineQueueCount = createSelector(
  getOfflineQueue,
  (queue) => queue.length
);
