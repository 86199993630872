import { AfterViewInit, ElementRef, ViewChild, Component, Input, OnDestroy, HostBinding } from '@angular/core';
import { Chart } from 'chart.js';
import moment from 'moment';
import Color from 'color';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { CountByDay } from '../store/normalized/schemas/count-by-day.schema';
import * as navigationActions from '../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { getCurrentUserStartDate } from '../store/normalized/selectors/user.selectors';
import { getCigarettesByDay } from '../store/normalized/selectors/count-by-day.selectors';
import { SessionState } from '../store/session/session.reducers';
import { ClarityConfig } from '../config/clarity.config';
import { SmokingTypeService } from '../services/smoking-type.service';

@Component({
  selector: 'cl-cigarettes-graph',
  styleUrls: ['cigarettes-graph.component.scss'],
  template: `
  <div class="graph-container">
    <div class="help-container" (click)="showHelp()" tappable>
      <span>{{ getTranslationKey('what_is_this') | translate }}</span>
    </div>
    <div [hidden]="hasData" class="graph-overlay">
      {{ getTranslationKey('no_data') | translate }}
    </div>
    <div [hidden]="!chart" style="width:100%; height:300px;" [ngClass]="{'graph-blurred': !hasData}" class="graph-canvas">
      <canvas #AG >{{chart}}</canvas>
    </div>

    <div *ngIf="controls" class="controls-wrapper">
      <ion-row class="control-buttons-row">
        <ion-col class="ion-text-right">
          <ion-button size="small" expand="block" color="action"
            [disabled]="!hasPrevious"
            (click)="toPreviousWeek()">
            <ion-icon name='arrow-back'></ion-icon>
            {{ 'daily_graph.prev' | translate | uppercase }}
          </ion-button>
        </ion-col>

        <ion-col class="ion-text-left">
          <ion-button size="small" expand="block" color="action"
            [disabled]="!hasNext"
            (click)="toNextWeek()">
            {{ 'daily_graph.next' | translate | uppercase }}
            <ion-icon name='arrow-forward'></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
  `
})

export class CigarettesGraphComponent implements AfterViewInit, OnDestroy {

  constructor(
    private store: Store<SessionState>,
    private translate: TranslateService,
    private config: ClarityConfig,
    private smokingTypeService: SmokingTypeService
  ) { }

  get hasData() {
    return this.cigarettesByDays.length > 0;
  }

  get hasNext() {
    const currentToday = this.today.clone();
    const realToday = moment()
      .startOf('day');
    if (currentToday.add(1, 'week') > realToday) {
      return false;
    }

    return true;
  }

  get hasPrevious() {
    const currentToday = this.today.clone();
    const startDateClone = this.startDate.clone();
    if (currentToday.subtract(1, 'week') < startDateClone.subtract(1, 'week')) {
      return false;
    }

    return true;
  }

  readonly FETCH_BACK_DAYS = 7;
  readonly WEEK_JUMP = 1;
  readonly FETCH_BACK_PERIOD_START = this.FETCH_BACK_DAYS - 1;

  @Input() controls = true;
  chart: Chart = null;

  ctx: any = null;
  cigarettesByDays: CountByDay[] = [];
  currChunk = 0;
  startDate: moment.Moment = moment('2010-01-01')
    .startOf('day');

  today: moment.Moment = moment()
    .startOf('day');

  private initialDay$ = this.store.select(getCurrentUserStartDate);

  translationPrefix: string;

  dataSubscription: Subscription = undefined;
  translationSubscription: Subscription = undefined;

  private cigarettesByDays$: Observable<CountByDay[]> = this.store.select(getCigarettesByDay);

  colorForDataSet = {
    cigarettes: 'rgb(244, 67, 54)'
  };

  chartLabels = {};

  chartLabelsNoTrans = {
    cigarettes: this.smokingTypeService.isVaping() ? 'chart.vaping' : 'chart.cigarettes'
  };

  pathForDataSet = {
    cigarettes: 0
  };

  @ViewChild('AG', { static: true }) chartElement: ElementRef;

  showHelp() {
    this.store.dispatch(new navigationActions.ShowOnboarding({
      page: 'OnboardingPage',
      params: {
        type: this.smokingTypeService.isVaping() ? 'vapingGraphWhatIsThis' : 'cigarettesGraphWhatIsThis'
      }
    }));
  }

  transparentize(color, opacity) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;

    return Color(color)
      .alpha(alpha)
      .rgb()
      .string();
  }

  toPreviousWeek() {
    if (this.hasPrevious) {
      this.today.subtract(this.WEEK_JUMP, 'week');
      this.updateData();
    }
  }

  toNextWeek() {
    if (this.hasNext) {
      this.today.add(this.WEEK_JUMP, 'week');
      this.updateData();
    }
  }

  gradientBackgroundGenerator(color) {
    return Color(color)
      .alpha(0.6)
      .rgb()
      .string();
  }

  generateLabels() {
    const WEEKDAY_FORMAT = 'MM/DD';
    const today = this.today.clone()
      .subtract(this.FETCH_BACK_PERIOD_START, 'days');
    const labels = [];
    for (let i = 0; i < this.FETCH_BACK_DAYS; i++) {
      labels.push(today.format(WEEKDAY_FORMAT));
      today.add(1, 'days');
    }

    return labels;
  }

  generateLabelsColor(chart) {
    chart.legend.afterFit = () => {
      chart.legend.lineWidths = chart.legend.lineWidths.map(() => chart.width - (chart.width * .15));
    };

    const data = chart.data;
    if (data.labels.length && data.datasets.length) {
      return data.datasets.map((dataset, i) => ({
        text: dataset.label,
        lineWidth: 0,
        fillStyle: dataset.pointBackgroundColor,
        datasetIndex: i
      }));
    }

    return [];
  }

  fillWith(filler, array: any[]) {
    for (let i = array.length; i < 2; i++) {
      array.unshift(filler);
    }

    return array;
  }

  getDataSet(key, data) {
    const color = this.colorForDataSet[key];

    return {
      data: this.fillWith(0, this.getData(key, data)),
      label: this.chartLabels[key],
      backgroundColor: this.gradientBackgroundGenerator(color),
      pointBackgroundColor: color,
      borderColor: this.transparentize(color, 1),
      pointRadius: 0,
      borderWidth: 0
    };
  }

  getData(key, vanillaKeyData: CountByDay[]= []) {
    const dataDict = {};
    vanillaKeyData.forEach((dataPoint: CountByDay) => {
      const { date, count } = dataPoint;
      dataDict[date] = count;
    });
    const DATE_FORMAT = 'YYYY-MM-DD';
    const today = this.today.clone()
      .subtract(this.FETCH_BACK_PERIOD_START, 'days');
    const data = [];
    for (let i = 0; i < this.FETCH_BACK_DAYS; i++) {
      const date_key = today.format(DATE_FORMAT);
      let value = dataDict[date_key];
      if (!value) {
        value = 0;
      }
      data.push(+value);
      today.add(1, 'days');
    }

    return data;
  }

  updateData(data?: CountByDay[]) {
    if (!this.ctx || !this.chart) {
      return;
    }
    if (data) {
      this.cigarettesByDays = data.sort((a, b) => moment(a.date)
        .diff(moment(b.date)));
    }
    this.chart.data.datasets = [
      this.getDataSet('cigarettes', this.cigarettesByDays)
    ];
    this.chart.data.labels = this.generateLabels();
    this.chart.update();
  }

  generateEmptyDataSets(label) {
    return this.getDataSet(label, []);
  }

  getFreshData() {
    this.dataSubscription = combineLatest([
      this.cigarettesByDays$
    ])
      .subscribe(([data]) => {
        this.updateData(data);
      });
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
  }

  signToTranslations() {
    this.translationSubscription = this.translate.onLangChange
      .subscribe(() => {
        this.translateLabels()
          .then(() => this.updateData());
      });
  }

  translateLabels() {
    return Promise.all(
      Object.keys(this.chartLabelsNoTrans)
        .map(key => {
          this.translate.get(this.chartLabelsNoTrans[key])
            .toPromise()
            .then((translation) => {
              this.chartLabels[key] = translation;
            });
        })
    );
  }

  getTranslationKey(msgKey) {
    return `${this.translationPrefix}.${msgKey}`;
  }

  ngAfterViewInit() {
    this.initialDay$
      .pipe(
        take(1)
      )
      .subscribe((startDate) => {
        this.startDate = moment(startDate)
          .startOf('day');
      });

    this.translationPrefix = this.smokingTypeService.isVaping() ? 'vaping_graph' : 'cigarettes_graph';

    const labels = this.generateLabels();
    this.ctx = this.chartElement.nativeElement.getContext('2d');
    const config = {
      type: 'bar',
      data: {
        labels,
        datasets: [
          this.generateEmptyDataSets('cigarettes')
        ]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            generateLabels: this.generateLabelsColor
          }
        },
        responsive: true,
        title: {
          display: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            stacked: true,
            display: true,
            gridLines: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              offsetGridLines: true
            },
            scaleLabel: {
              display: false
            }
          }],
          yAxes: [{
            // display: false,
            stacked: true,
            gridLines: {
              drawBorder: false
            },
            ticks: {
              beginAtZero: true,
              callback(value) { if (Number.isInteger(value)) { return value; } }
            },
            scaleLabel: {
              display: false,
              labelString: 'Value'
            }
          }]
        }
      }
    };

    this.signToTranslations();
    this.translateLabels()
      .then(() => {
        this.chart = new Chart(this.ctx, config);
        this.getFreshData();
      });
  }
}
