import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-back-button',
  styleUrls: ['back-button.component.scss'],
  template: `
    <ion-buttons slot="secondary">
      <ion-button (click)="onClick()" icon-only color="{{color}}" tappable>
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
  `
})
export class BackButtonComponent {
  @Input() color: string;

  @Output() back = new EventEmitter();

  onClick() {
    this.back.emit();
  }
}
