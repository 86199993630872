import { schema } from 'normalizr';
import { WeeklyPriceRoundDirection } from 'src/app/utils/subscription-utils';

export class StripePlan {
  id: number;
  name: string;
  currency: string;
  discounted_price_cents: number;
  full_price_cents: number;
  interval: string;
  interval_count: number;
}

export class StripeProduct extends StripePlan{
  type: string;
  length: string;
  priceFormatted: string;
  fullPriceFormatted: string;
  pricePerWeekFormatted: string;
  pricePerWeekRounded?: WeeklyPriceRoundDirection;
  priceMicros: number;
  savePercent: number;
}

export const stripePlanSchema = new schema.Entity<StripePlan>('stripePlans');
