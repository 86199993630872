import { Injectable } from '@angular/core';

import { ClarityConfig } from '../config/clarity.config';
import { HttpProvider } from './http/http.provider';
import { OfflineHttpProvider } from './http/offline-http.provider';

@Injectable({providedIn: 'root'})
export class UserActivitiesProvider {
  private userActivitiesEndpoint = '/user_activities';
  private userActivitiesEndpointCountBy = this.userActivitiesEndpoint + '/count_by_day';

  constructor(
    protected http: HttpProvider,
    private offlineHttp: OfflineHttpProvider,
    public config: ClarityConfig
  ) {
  }

  setQuitDate(data) {
    const quitDate = {
      activity_at: new Date().toISOString(),
      data,
      kind: 'quit',
      name: 'Quit',
      ...this.config.versionTrackingVars
    };

    return this.http.post(this.userActivitiesEndpoint, quitDate);
  }

  // can be handled offline
  trackCompletedModule(payload) {
    return this.baseTrack(payload);
  }

  // can be handled offline
  trackCompletedLesson(payload) {
    return this.baseTrack(payload);
  }

  // can be handled offline
  trackCompletedBonusExercise(payload) {
    return this.baseTrack(payload);
  }

  // can be handled offline
  trackActivity(payload) {
    return this.baseTrack(payload);
  }

  trackCoachingActivity(name: string) {
    const payload = {
      kind: 'coaching',
      activity_at: new Date(),
      name,
      data: {}
    };

    return this.baseTrack(payload);
  }

  private baseTrack(payload: Object) {
    return this.offlineHttp.post(
      this.userActivitiesEndpoint,
      {
        ...payload,
        ...this.config.versionTrackingVars
      });
  }

  getTZ() {
    let offset = new Date().getTimezoneOffset() / 60;
    let ret = 'GMT';
    if (offset > 0) {
      ret += '-' + offset;
    } else if (offset < 0) {
      offset = -offset;
      ret += '%2B' + offset;
    }

    return ret;
  }

  getCompleteActivityByDay(activity) {
    return this.http.get(
      this.userActivitiesEndpointCountBy +
        `?kind=complete&activityable_type=${activity}&tz=` +
        this.getTZ()
    );
  }

  getProgramDaysByDay() {
    return this.getCompleteActivityByDay('ProgramDay');
  }

  getUserWeekDaysByDay() {
    return this.getCompleteActivityByDay('UserWeekDay');
  }

}
