// auth effects

import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as exercisesActions from '../actions/exercises.actions';
import { ExercisesProvider } from '../../../../app/providers/exercises.provider';

@Injectable({providedIn: 'root'})
export class ExercisesEffects {

  getExercisesList$ = createEffect(() => this.actions$.pipe(ofType(exercisesActions.EXERCISES),
    switchMap(() => this.exercisesProvider.loadBonusExercises()
      .pipe(
        map(action => new exercisesActions.GetBonusExercisesListSuccess(action)),
        catchError(error => of(new exercisesActions.GetBonusExercisesListFail(error)))
      ))
  ));

  constructor(
    private actions$: Actions,
    private exercisesProvider: ExercisesProvider
  ) {
  }
}
