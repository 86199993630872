import * as nrtActions from '../actions/nrt.actions';

export interface NrtStepsCounter {
  total: number;
  current?: number;
}

export interface NrtState {
  accessLoading: boolean;
  accessGranted: boolean;
  ungrantedMessage: string;
  addressValidating: boolean;
  addressValidated: boolean;
  addressValidationFailed: boolean;
  ordering: boolean;
  ordered: boolean;
  orderedFailed: boolean;
}

export const initialNrtState: NrtState = {
  accessLoading: false,
  accessGranted: false,
  ungrantedMessage: '',
  addressValidating: false,
  addressValidated: false,
  addressValidationFailed: false,
  ordering: false,
  ordered: false,
  orderedFailed: false
};

export function nrtReducer(state = initialNrtState, action: nrtActions.NrtActions): NrtState {
  switch (action.type) {
    case nrtActions.GET_ACCESS: {
      return {
        ...state,
        accessLoading: true,
        ungrantedMessage: '',
        accessGranted: false
      };
    }

    case nrtActions.GET_ACCESS_SUCCESS: {
      return {
        ...state,
        accessLoading: false,
        accessGranted: true,
        ungrantedMessage: ''
      };
    }

    case nrtActions.GET_ACCESS_FAIL: {
      return {
        ...state,
        accessLoading: false,
        accessGranted: false,
        ungrantedMessage: action.payload.message
      };
    }
  }

  return state;
}

export const accessLoading = (state: NrtState) => state.accessLoading;
export const accessGranted = (state: NrtState) => state.accessGranted;
export const ungrantedMessage = (state: NrtState) => state.ungrantedMessage;
