import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import { SubscriptionsProvider } from '../../../../app/providers/subscriptions/subscriptions.provider';
import { AlertsService } from '../../../../app/services/alerts.service';
import { LoadingService } from '../../../../app/services/loading.service';
import { ToastService } from '../../../../app/services/toast.service';
import { ConnectivityService } from '../../../../app/services/connectivity.service';
import { AnalyticsService } from '../../../../app/services/analytics/analytics.service';

import * as accountActions from '../actions/account.actions';
import * as subscriptionActions from '../actions/subscription.actions';
import * as syncActions from '../actions/sync.actions';
import * as navigationActions from '../actions/navigation.actions';
import { getLiveSubscription } from '../../normalized/selectors/subscription.selectors';
import { getSyncState } from '../../../../app/store';
import { SessionState } from '../session.reducers';
import { SetData } from 'ngrx-normalizr';
import { StripePlan, stripePlanSchema } from '../../normalized/schemas/stripe-plan.schema';
import { isAccountSetupStarted } from '../selectors/account.selectors';
import { UserProgramProvider } from '../../../providers/user-program.provider';
import { getCouponCode } from '../selectors/subscription.selectors';
import { SubscriptionsService } from '../../../services/subscriptions/subscriptions.service';
import { GtmCustomEvent, GtmService } from '../../../services/analytics/gtm.service';
import { SubscriptionEvents } from 'src/app/services/analytics/analytics.events';

@Injectable({providedIn: 'root'})
export class SubscriptionEffects {


  subscriptionRecheck$ = createEffect(() => this.actions$.pipe(ofType<subscriptionActions.SubscriptionRecheck>(subscriptionActions.SUBSCRIPTION_RECHECK),
    switchMap(() => of([])
      .pipe(
        withLatestFrom(
          this.store.select(getSyncState),
          this.store.select(getLiveSubscription),
          (blank, syncState, liveSubscription) => {
            // only dispatch when online
            if (this.connectivity.isOffline()) {
              return false;
            }

            // recheck subscription every 30 minutes when it's already valid
            const recheckDelay = liveSubscription && liveSubscription.isActive
              ? 30 * 60 * 1000
              : 0;

            if (new Date(syncState.lastSyncTimestamps.subscriptions).getTime() < new Date().getTime() - recheckDelay) {
              this.store.dispatch(new syncActions.SyncSubscription());
            }
          })
      )
    )
  ), {dispatch: false});


  subscriptionPurchased$ = createEffect(() => this.actions$.pipe(ofType<subscriptionActions.SubscriptionPurchased>(subscriptionActions.SUBSCRIPTION_PURCHASED),
    map((action) => action.payload),
    tap(({subscription, product}) => {
      this.analyticsService.trackSubscription(SubscriptionEvents.Purchased, subscription, product);
      this.gtmService.triggerEvent(GtmCustomEvent.SubscriptionPurchased);
    }),
    map((subscription) => new subscriptionActions.SubscriptionActivated(subscription))
  ));


  subscriptionRestored$ = createEffect(() => this.actions$.pipe(ofType<subscriptionActions.SubscriptionRestored>(subscriptionActions.SUBSCRIPTION_RESTORED),
    map((action) => action.payload),
    tap((subscription) => {
      this.analyticsService.trackSubscription(SubscriptionEvents.Restored, subscription);
    }),
    map((subscription) => new subscriptionActions.SubscriptionActivated(subscription))
  ));


  subscriptionLicensed$ = createEffect(() => this.actions$.pipe(ofType<subscriptionActions.SubscriptionLicensed>(subscriptionActions.SUBSCRIPTION_LICENSED),
    map((action) => action.payload),
    tap((code) => {
      this.analyticsService.trackSubscription(SubscriptionEvents.Licensed, {code});
      this.gtmService.triggerEvent(GtmCustomEvent.SubscriptionLicensed);
    }),
    map(() => new subscriptionActions.SubscriptionActivated())
  ));


  cancelSubscription$ = createEffect(() => this.actions$.pipe(ofType(subscriptionActions.CANCEL_SUBSCRIPTION),
    switchMap(() => {
      this.loading.showLoadingOverlay(this.translate.get('loading.canceling'));

      return this.subscriptionsProvider.cancelSubscription()
        .pipe(
          map(() => {
            this.loading.hideLoadingOverlay();
            this.toasts.confirm(this.translate.get('subscriptions.canceled_successfully'));

            this.analyticsService.trackSubscription(SubscriptionEvents.Canceled);
            this.gtmService.triggerEvent(GtmCustomEvent.SubscriptionCanceled);
          }),
          catchError((error) => {
            switch (error.status) {
              case 403:
                this.alerts.customError(error, 'common.error', 'errors.subscriptions.canceling_not_supported');

                return of(null);

              default:
                this.alerts.customError(error, 'common.error', 'errors.subscriptions.cannot_cancel_subscription');

                return of(null);
            }
          })
        );
    })
  ), {dispatch: false});


  subscriptionActivated$ = createEffect(() => this.actions$.pipe(ofType(subscriptionActions.SUBSCRIPTION_ACTIVATED),
    switchMap(() => [
      new syncActions.SyncCommunityJournal(),
      new syncActions.SyncCommunityPosts(),
      new syncActions.SyncCommunityBookmarkedPosts()
    ])
  ));

  accountSetupStart$ = createEffect(() => this.actions$.pipe(ofType(accountActions.ACCOUNT_SETUP_START),
    map(() => new syncActions.SyncSubscription())
  ));


  loadStripePlans$ = createEffect(() => this.actions$.pipe(ofType<subscriptionActions.LoadStripePlans>(subscriptionActions.LOAD_STRIPE_PLANS),
    concatMap(action => of(action)
      .pipe(withLatestFrom(this.store.select(getCouponCode)))
    ),
    tap(() => {
      this.subscriptionsService.loadStripeScript();
    }),
    switchMap(([action, code]) => this.subscriptionsProvider.loadStripePlans(false, code)
      .pipe(
        map((plans) => new subscriptionActions.LoadStripePlansSuccess(plans)),
        catchError(error => of(new subscriptionActions.LoadStripePlanFail(error)))
      ))
  ));


  loadStripePlansSuccess$ = createEffect(() => this.actions$.pipe(
    ofType<subscriptionActions.LoadStripePlansSuccess>(subscriptionActions.LOAD_STRIPE_PLANS_SUCCESS),
    map((action) => action.payload),
    map((plans) => new SetData<StripePlan>({
      data: plans,
      schema: stripePlanSchema
    }))
  ));


  checkProgramCode$ = createEffect(() => this.actions$.pipe(
    ofType<subscriptionActions.CheckProgramCode>(subscriptionActions.CHECK_PROGRAM_CODE),
    map((action) => action.payload),
    switchMap((code) =>
    // this.loading.showLoadingOverlay();
    // this.code = codeI;
    // this.code = this.codeForm.value.license_code.toUpperCase();

      this.subscriptionsProvider.getCodeInfo(code)
        .pipe(
          map((codeInfo) => {
            if (codeInfo.type === 'coupon') { // ERNCR50
              return this.store.dispatch(new subscriptionActions.ApplyCouponCode(code));
            }

            if (codeInfo.type === 'license') {
              return this.store.dispatch(new subscriptionActions.RedeemLicenseCode(code, true));
            }
          }),
          catchError((error) => {
            this.loading.hideLoadingOverlay();
            if (error.status === 404) {
              this.alerts.customError(error, 'subscriptions.invalid_code', 'subscriptions.license_code_is_not_valid');

              return of(null);
            }
            else if (error.status === 409) {
              this.alerts.customError(error, 'common.error', 'subscriptions.license_already_redeemed');

              return of(null);
            }
            else {
              this.alerts.customError(error, 'errors.common.unknown_error', 'errors.common.generic_error_please_retry');

              return of(null);
            }
          })
        )
    )
  ), {dispatch: false});


  applyCouponCode$ = createEffect(() => this.actions$.pipe(
    ofType<subscriptionActions.ApplyCouponCode>(subscriptionActions.APPLY_COUPON_CODE),
    map(() => new subscriptionActions.LoadStripePlans())
  ));


  redeemLicenseCode$ = createEffect(() => this.actions$.pipe(
    ofType<subscriptionActions.RedeemLicenseCode>(subscriptionActions.REDEEM_LICENSE_CODE),
    switchMap((action: subscriptionActions.RedeemLicenseCode) => {
      const code = action.payload.toUpperCase();
      const closeModals = action.closeModals;

      this.loading.showLoadingOverlay(this.translate.get('loading.validating'));

      return this.userProgramProvider.updateUserProgram(
        {
          license_code: code
        })
        .pipe(
          withLatestFrom(this.store.select(isAccountSetupStarted)),
          map(([_, accountSetupStarted]) => {
            this.loading.hideLoadingOverlay();

            this.toasts.confirm(this.translate.get('subscriptions.license_redeemed_successfully'));

            this.store.dispatch(new subscriptionActions.SubscriptionLicensed(code));

            if (closeModals) {
              this.store.dispatch(new navigationActions.CloseLastModal());
            }

            if (accountSetupStarted) {
              this.store.dispatch(new accountActions.SignupComplete());
            }
          }),
          catchError((error) => {
            this.loading.hideLoadingOverlay();

            if (error.status === 404) {
              this.alerts.customError(error, 'subscriptions.invalid_code', 'subscriptions.license_code_is_not_valid');

              return of(null);
            }
            else if (error.status === 409) {
              this.alerts.customError(error, 'common.error', 'subscriptions.license_already_redeemed');

              return of(null);
            }
            else {
              this.alerts.customError(error, 'errors.common.unknown_error', 'errors.common.generic_error_please_retry');

              return of(null);
            }
          })
        );
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private store: Store<SessionState>,
    private subscriptionsProvider: SubscriptionsProvider,
    private subscriptionsService: SubscriptionsService,
    private userProgramProvider: UserProgramProvider,
    private alerts: AlertsService,
    private loading: LoadingService,
    private translate: TranslateService,
    private toasts: ToastService,
    private connectivity: ConnectivityService,
    private analyticsService: AnalyticsService,
    private gtmService: GtmService
  ) {
  }
}
