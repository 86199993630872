import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

export interface ScriptModel {
  src: string;
  async: boolean;
  defer?: boolean;
  loaded?: boolean;
}

@Injectable()
export class ScriptLoaderService {
  readonly timeBetweenTries = 3 * 1000; // 3 seconds

  private scripts: ScriptModel[] = [];

  public load(script: ScriptModel): Observable<ScriptModel> {
    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      const existingScript = this.scripts.find(s => s.src === script.src);

      // complete if already loaded
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();

        return;
      }

      // attempt loading
      this.scripts = [...this.scripts, script];

      this.tryLoad(script)
        // successfully loaded
        .then((loadedScript) => {
          observer.next(loadedScript);
          observer.complete();

          return loadedScript;
        })
        // load failed
        .catch((loadError) => {
          console.log('Script load failed:', loadError);

          this.scripts = this.scripts.filter((s) => s.src !== script.src);

          // retry once
          return this.delay(this.timeBetweenTries)
            .then(() => this.tryLoad(script))
            .then((loadedScript) => {
              observer.next(loadedScript);
              observer.complete();

              return loadedScript;
            })
            .catch((retryError) => {
              console.log('Script retry failed:', retryError);

              // throw final error
              observer.error(retryError);
              observer.complete();

              return retryError;
            });
        });
    });
  }

  private tryLoad(script: ScriptModel): Promise<ScriptModel> {
    return new Promise((resolve, reject) => {
      // load the script
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.src = script.src;
      scriptElement.async = script.async;

      // uncomment to test network errors (TODO: remove after PR merge)
      // scriptElement.src = Math.random() < 0.8 ? 'fake-src' : script.src;

      scriptElement.onload = () => {
        script.loaded = true;
        resolve(script);
      };

      scriptElement.onerror = () => {
        scriptElement.remove();
        reject(`couldn't load script ${script.src}`);
      };

      const firstScriptElt = document.getElementsByTagName('script')[0];
      firstScriptElt.parentNode.insertBefore(scriptElement, firstScriptElt);
    });
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), ms);
    });
  }
}
