import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { CORE_MODULE_IMPORTS, CORE_MODULE_PROVIDERS } from '@mindsciences/clarity-core/app/services';
import { HealthFitService, IntegrationsEffects } from '@mindsciences/health-integrations';

@NgModule({
  imports: [
    ...CORE_MODULE_IMPORTS,
    EffectsModule.forFeature([IntegrationsEffects])
  ],
  providers: [
    ...CORE_MODULE_PROVIDERS,
    HealthFitService
  ],
  declarations: [
  ],
  exports: [
  ]
})
export class CoreModule {

}
