import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../store/state.reducer';
import { isAuthenticated } from '../store/sensitive/selectors/auth.selectors';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { ClarityConfig } from '../config/clarity.config';
import { isFirstLoginCompleted, isWelcomeTourViewed } from '../store/persistent/flags/flags.selectors';
import { isAccountSetup } from '../store/session/selectors/account.selectors';
import { buildAuthorizationUrl } from '@mindsciences/authorization-util';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private nav: NavController,
    private store: Store<State>,
    private config: ClarityConfig) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('||||| AuthGuard');

    // hydration is important! not just for obvious reasons, but also to avoid showing the welcome screen once in a while ;)
    return this.store.select('hydrated')
      .pipe(
        filter((hydrated) => hydrated === true),
        switchMap(() => combineLatest([
          this.store.select(isAuthenticated),
          this.store.select(isWelcomeTourViewed),
          this.store.select(isFirstLoginCompleted),
          this.store.select(isAccountSetup)
        ])
          .pipe(map(([isLoggedIn, welcomeTourViewed, firstLoginCompleted, isAccountSetupComplete]) => {

            if (!isLoggedIn && (window.location.pathname === this.config.env.sharecareInitPath)) {
              console.log('|||| AuthGuard | Sharecare Init - navigateRoot(provider/sharecare)');
              this.nav.navigateRoot('provider/sharecare');

              return false;

            } else if (!isLoggedIn && (window.location.pathname === this.config.env.authorizePath)) {
              console.log('|||| AuthGuard | SSO Authorize from URL - navigateRoot(code/:code)');

              const searchParams = new URLSearchParams(document.location.search);

              const code = searchParams.get('code');
              const offeringCode = searchParams.get('offeringCode');
              const sponsor = searchParams.get('sponsor');

              const authorizationUrl = buildAuthorizationUrl({ code, offeringCode, sponsor });

              this.nav.navigateRoot(authorizationUrl);

              return false;

            } else if (isLoggedIn) {
              return true;

            } else if (!welcomeTourViewed && this.config.isDevice) {
              console.log('|||| AuthGuard || navigateRoot(welcome-tour)');

              this.nav.navigateRoot('/welcome-tour');

              return false;

            } else if (!firstLoginCompleted) {
              console.log('||||| AuthGuard || navigateRoot(login)');

              this.nav.navigateForward('login');

              return false;
            }

            // default -- send user to login
            console.log('|||| AuthGuard || not logged in -> navigateRoot(login)');

            this.nav.navigateBack('login');

            return false;
          })))
      );
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(next, state);
  }

  // private setRedirectUrl(state) {
  //   if (localStorage === null) {
  //     return;
  //   }
  //
  //   const params = state.url.split('?') ? state.url.split('?')[1] : false;
  //
  //   let url = params ? state.url.split('?')[0] : state.url;
  //   url = this.applyConfigToUrl(url);
  //
  //   localStorage.setItem('redirectUrl', JSON.stringify({
  //     route: url,
  //     params: params ? this.getQueryParams(params) : params
  //   }));
  // }

  // private getQueryParams(params) {
  //   const decodedURI = decodeURI(params)
  //     .replace(/"/g, '\\"')
  //     .replace(/&/g, '","')
  //     .replace(/=/g, '":"');
  //
  //   return JSON.parse('{"' + decodedURI + '"}');
  // }
  //
  // private applyConfigToUrl(url) {
  //   if (this.config.program.programCode === 'ctq' && url === '/tabs/dashboard') {
  //     return '/tabs/home';
  //   }
  //
  //   return url;
  // }
}
