import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { getRainExercise } from '../../store/normalized/selectors/exercises.selectors';
import { map } from 'rxjs/operators';
import { CloseAllModals } from '../../store/session/actions/navigation.actions';
import { State } from '../../store/state.reducer';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rain-exercise-page',
  styleUrls: ['rain-exercise.scss'],
  templateUrl: 'rain-exercise.html'
})
export class RainExercisePage {
  rainExercise$ = this.store.select(getRainExercise)
    .pipe(
      map(exercises => exercises[0])
    );

  constructor(
    public store: Store<State>
  ) {
  }

  done() {
    this.store.dispatch(new CloseAllModals());
  }

}
