import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';

import { ThemeWeek } from '../../store/normalized/schemas/theme-week.schema';
import { canStartThemeWeeks } from '../../store/session/selectors/program.selectors';
import { State } from '../../store/state.reducer';
import * as programActions from '../../store/session/actions/program.actions';
import * as navigationActions from '../../store/session/actions/navigation.actions';

@Component({
  selector: 'page-new-theme-week',
  styleUrls: ['new-theme-week.scss'],
  templateUrl: 'new-theme-week.html'
})
export class NewThemeWeekPage {
  modal;

  public week: ThemeWeek;
  public canStartThemeWeeks = this.store.select(canStartThemeWeeks);

  constructor(
    public store: Store<State>,
    public navCtrl: NavController,
    public navParams: NavParams
  ) {
    this.week = this.navParams.get('week');
  }

  get isModal() {
    return true;
    // return this.modalCtrl.isOverlay;
  }

  onBack() {
    if (this.isModal) {
      this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
    }
    else {
      this.navCtrl.pop();
    }
  }

  onNext() {
    this.store.dispatch(new programActions.StartThemeWeek(this.week));
  }
}
