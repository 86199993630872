import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  AfterViewInit
} from '@angular/core';
import { LiveLesson } from '../../../store/session/selectors/program.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-lesson-capture',
  styleUrls: ['../play.scss', 'lesson-capture.component.scss'],
  template: `
    <ion-grid>
      <div class="title-holder">
        <div>
          <h1>{{lesson.record.exercise.title}}</h1>
          <p
            *ngIf="isQuittingPactLesson"
            class="ion-padding subtitle-holder lateral-padding"
            [innerHtml]="'lessons.you_can_visit_quitting_pact_on_account' | translate">
          </p>
        </div>
      </div>

      <div
        class="lateral-padding"
        [class.padding]="isGoalsLesson || isMantraLesson || isQuittingPactLesson"
      >
        <cl-shadow-wrapper
          class="edit-lesson player-holder"
          [class.review]="isSensationCaptureLesson || isTriggerCaptureLesson || isWeightCaptureLesson">

          <cl-goals-edit-lesson
            *ngIf="isGoalsLesson"
            (action)="nextLesson()"
            [lesson]="lesson">
          </cl-goals-edit-lesson>

          <cl-quitting-pact-lesson
            *ngIf="isQuittingPactLesson"
            (action)="nextLesson()"
            [lesson]="lesson">
          </cl-quitting-pact-lesson>

          <cl-mantra-edit-lesson
            *ngIf="isMantraLesson"
            (action)="nextLesson()"
            [lesson]="lesson">
          </cl-mantra-edit-lesson>

          <cl-sensation-capture-lesson
            *ngIf="isSensationCaptureLesson"
            (enableNext)="onEnableNext()"
            (disableNext)="onDisableNext()">
          </cl-sensation-capture-lesson>

          <cl-trigger-capture-lesson
            *ngIf="isTriggerCaptureLesson"
            (enableNext)="onEnableNext()"
            (disableNext)="onDisableNext()">
          </cl-trigger-capture-lesson>

          <cl-weight-capture-lesson
            *ngIf="isWeightCaptureLesson"
            [lesson]="lesson"
            (enableNext)="onEnableNext()"
            (disableNext)="onDisableNext()">
          </cl-weight-capture-lesson>
        </cl-shadow-wrapper>

        <ion-row
          class="action-buttons-holder bottom-button"
          *ngIf="!isGoalsLesson && !isMantraLesson"
          [class.sticky]="nextEnabled && isSensationCaptureLesson"
        >
          <ion-col auto>
            <cl-next-lesson-button
              [lesson]="lesson"
              (next)="nextLesson()"
              [enabled]="nextEnabled">
            </cl-next-lesson-button>
          </ion-col>
        </ion-row>
      </div>
    </ion-grid>
  `
})
export class LessonCaptureComponent implements AfterViewInit {
  @Input() lesson: LiveLesson;
  @Input() playExercise: boolean;

  @Output() next = new EventEmitter<LiveLesson>();
  @Output() init = new EventEmitter<void>();

  nextEnabled = true;

  get isMantraLesson() {
    return this.lesson.record.exercise.action === 'set_mantra';
  }

  get isGoalsLesson() {
    return this.lesson.record.exercise.action === 'set_goals';
  }

  get isQuittingPactLesson() {
    return this.lesson.record.exercise.action === 'quitting_pact';
  }

  get isSensationCaptureLesson() {
    return this.lesson.record.exercise.action === 'try_stress_test';
  }

  get isTriggerCaptureLesson() {
    return this.lesson.record.exercise.action === 'try_stress_meter';
  }

  get isWeightCaptureLesson() {
    return this.lesson.record.exercise.action === 'check_weight';
  }

  ngAfterViewInit() {
    this.init.emit();
  }

  nextLesson() {
    this.next.emit(this.lesson);
  }

  onEnableNext() {
    this.nextEnabled = true;
  }

  onDisableNext() {
    this.nextEnabled = false;
  }
}
