import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { OnboardingPage } from './onboarding';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    OnboardingPage
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: OnboardingPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ]
})
export class OnboardingPageModule {}
