<img src="{{waves}}" *ngIf="waves"/>

<div class="footer-section-holder">
  <ng-container *ngIf="config.isWebApp">
    <ul>
      <li>
        {{ 'sections.navigation' | translate }}
      </li>
      <li>
        <a (click)="config.program.programCode === 'ctq' ? navigateTo('tabs/home') : navigateTo('tabs/dashboard')">
          <ng-container *ngIf="config.program.programCode === 'ctq'; else dashboardText">
            {{ 'tabs.home' | translate }}
          </ng-container>
          <ng-template #dashboardText>
            {{ 'tabs.dashboard' | translate }}
          </ng-template>
        </a>
      </li>
      <li>
        <a (click)="navigateTo('tabs/lessons')">
          {{ 'sections.lessons' | translate }}
        </a>
      </li>
      <li>
        <a (click)="navigateTo('tabs/tools')">
          {{ 'tabs.tools' | translate }}
        </a>
      </li>
      <li *ngIf="config.program.programCode !== 'ctq'">
        <a (click)="navigateTo('tabs/community')">
          {{ 'sections.community' | translate }}
        </a>
      </li>
      <li *ngIf="config.program.programCode === 'ctq'">
        <a (click)="navigateTo('tabs/inbox')">
          {{ 'tabs.inbox' | translate }}
        </a>
      </li>
      <li>
        <a (click)="navigateTo('tabs/account')">
          {{ 'sections.account' | translate }}
        </a>
      </li>
    </ul>

    <div class="logo">
      <img src="./assets/imgs/sharecare-logo.png"/>

      <cl-download-links [showSubtitle]="false" [miniVersion]="true"></cl-download-links>
    </div>

    </ng-container>
</div>
<div class="footer-section-bottom-holder">
  <ng-container *ngIf="isDesktop" class="container">
    <span>
      Sharecare © {{ currentYear }}
    </span>
  </ng-container>
</div>
