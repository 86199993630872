import {
  Component,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  ComponentRef,
  Input,
  Type,
  OnDestroy,
  EventEmitter, Output, ChangeDetectorRef, OnInit, ElementRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../store/state.reducer';
import { LogoutAlert } from '../../store/sensitive/actions/auth.actions';
import { CloseAllModals } from '../../store/session/actions/navigation.actions';
import { shouldHideSubscription } from '../../store/normalized/selectors/subscription.selectors';
import { TabsOpened } from 'src/app/store/persistent/tabs/tabs.actions';
import { forcedTabsOpenCount } from 'src/app/store/persistent/tabs/tabs.selectors';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { animate, style, transition, trigger } from '@angular/animations';
import { getWeightActivityActionsPending } from 'src/app/store/normalized/selectors/weight-activity.selectors';

export class Tab {
  title: string;
  componentRef: Type<any>;
  componentProps: any;
  extraComponentRef: Type<any>;
  extraComponentProps: any;
  onlyMobile: boolean;
  hideOnSSO: boolean;
  hasBadgeSlot: boolean;
  route?: string;
  hasDisabledInnerPadding?: boolean;
}

export class CancellableEvent<T> extends CustomEvent<T> {
  constructor(detail: T) {
    super('cancellableEvent', {
      detail,
      cancelable: true
    });
  }
}

@Component({
  selector: 'cl-tabbed-view',
  templateUrl: 'tabbed-view.component.html',
  styleUrls: ['tabbed-view.component.scss'],
  animations: [
    trigger('delayed', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms 100ms ease-in-out', style({ opacity: 1 }))
      ])
    ])
  ]
})
export class TabbedViewComponent implements OnDestroy, OnInit {

  @Input() header;
  @Input() logoutBtn;
  @Input() forceTabsOpen;
  @Output() scrollToTop = new EventEmitter();
  @Output() tabChanged = new EventEmitter<CancellableEvent<Tab>>(false);
  @ViewChild('tabsHeader', {read: ElementRef, static: true}) tabsHeader: ElementRef<HTMLDivElement>;
  @ViewChild('tabContainer', {read: ViewContainerRef, static: true}) tabContainer: ViewContainerRef;
  @ViewChild('tabExtraContainer', {read: ViewContainerRef, static: true}) tabExtraContainer: ViewContainerRef;

  componentRef: ComponentRef<any>;
  extraComponentRef: ComponentRef<any>;
  isMenuExpanded = false;
  activeTab = 0;

  // we cannot keep track which ones were opened, so we just opened all of them maximum 10 times
  private maxForcedTabsOpen = 10;

  shouldHideSubscription$ = this.store.select(shouldHideSubscription);
  weightActivityActionsPending$ = this.store.select(getWeightActivityActionsPending);
  forcedTabsOpenSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private resolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef,
    public config: ClarityConfig
  ) {
  }

  private _tabs: Array<Tab>;

  @Input()
  set tabs(tabs: Tab[]) {
    this._tabs = tabs;
    this.loadComponent();
  }

  get tabs() {
    return this._tabs;
  }

  ngOnInit() {
    this.handleTabsOpen();

    // this is not needed since it will be trigger by the Input
    // this.loadComponent();
  }

  onLogout() {
    this.closeSettings();
    this.store.dispatch(new LogoutAlert());
  }

  closeSettings() {
    this.store.dispatch(new CloseAllModals());
  }

  getActiveComponent() {
    return this._tabs[this.activeTab]?.componentRef || null;
  }

  getComponentProperties() {
    return this._tabs[this.activeTab]?.componentProps || null;
  }

  getExtraComponent() {
    return this._tabs[this.activeTab]?.extraComponentRef || null;
  }

  getExtraComponentProps() {
    return this._tabs[this.activeTab]?.extraComponentProps || null;
  }

  clickTab(index: number) {
    if (this.activeTab === index) {
      this.isMenuExpanded = !this.isMenuExpanded;

      return;
    }

    const event = this.onTabChanged(this.tabs[index]);

    if (event.defaultPrevented) {
      console.log('change tab was defaultPrevented. Stopping flow.');

      return;
    }

    this.selectTab(index);
  }

  selectTab(index: number) {
    // need to scroll to top before collapsing menu
    const tabsHeader = this.tabsHeader?.nativeElement;
    if (tabsHeader) {
      tabsHeader.scrollTop = 0;
    }

    this.activeTab = index;
    this.loadComponent();
    this.isMenuExpanded = false;

    this.changeDetector.detectChanges();
  }

  loadComponent() {
    this.handleMainComponent();
    this.handleExtraComponent();
  }

  createComponent(properties: any = false, component = this.getActiveComponent(), container = this.tabContainer) {
    if (!component) {
      return;
    }
    const componentFactory = this.resolver.resolveComponentFactory<any>(component);

    const compRef = container.createComponent(componentFactory);

    if (properties) {
      Object.keys(properties)
        .forEach((key) => {
          if (key !== 'selector') {
            compRef.instance[key] = properties[key];
          }
        });

      if (compRef.instance.createSelector && properties.selector) {
        compRef.instance.createSelector(properties.selector);
      }
      if (properties.setMenu && compRef.instance._setMenu) {
        compRef.instance._setMenu();
      }
    }

    if (compRef.instance.scrollTop) {
      compRef.instance.scrollTop.subscribe(() => {
        this.scrollToTop.emit();
      });
    }

    return compRef;
  }

  private isCurrentTabActive(currentTabIndex: number) {
    return this.activeTab === currentTabIndex;
  }

  private doesActiveTabHaveBadgeSlot() {
    return !this.isMenuExpanded && Boolean(this.tabs.find(tab => tab.hasBadgeSlot));
  }

  doesCurrentTabHaveBadgeSlot(currentTab: Tab, currentTabIndex: number) {
    return (this.isCurrentTabActive(currentTabIndex) && this.doesActiveTabHaveBadgeSlot()) || currentTab.hasBadgeSlot;
  }

  private onTabChanged = (tab: Tab) => {
    const event = new CancellableEvent(tab);
    this.tabChanged.emit(event);

    return event;
  };

  ngOnDestroy() {
    this.forcedTabsOpenSubscription && this.forcedTabsOpenSubscription.unsubscribe();
    this.componentRef && this.componentRef.destroy();
    this.extraComponentRef && this.extraComponentRef.destroy();
  }

  private handleTabsOpen() {
    if (!this.forceTabsOpen) {
      return;
    }

    this.forcedTabsOpenSubscription = this.store.select(forcedTabsOpenCount)
      .pipe(take(1))
      .subscribe(openedCount => {
        if (openedCount >= this.maxForcedTabsOpen) {
          return this.forcedTabsOpenSubscription && this.forcedTabsOpenSubscription.unsubscribe();
        }

        this.isMenuExpanded = true;
        this.changeDetector.detectChanges();
        this.store.dispatch(new TabsOpened());
      });
  }

  private handleMainComponent() {
    const componentProps = this.getComponentProperties();
    if (this.componentRef) {
      if (this.componentRef.instance.end) {
        this.componentRef.instance.end.subscribe(() => {
          this.componentRef && this.componentRef.destroy();
          this.componentRef = this.createComponent(componentProps);

          this.changeDetector.detectChanges();
        });

        this.componentRef.instance.closeComponent();

        return;
      }
    }

    this.componentRef && this.componentRef.destroy();
    this.componentRef = this.createComponent(componentProps);
  }

  private handleExtraComponent() {
    const extraComponent = this.getExtraComponent();
    if (!extraComponent) {
      return;
    }

    if (this.extraComponentRef) {
      if (this.extraComponentRef.instance.end) {
        this.extraComponentRef.instance.end.subscribe(() => {
          this.extraComponentRef && this.extraComponentRef.destroy();
          this.extraComponentRef = this.createComponent(this.getExtraComponentProps(), extraComponent, this.tabExtraContainer);
        });
        this.extraComponentRef.instance.closeComponent();

        this.changeDetector.detectChanges();

        return;
      }
    }

    this.extraComponentRef && this.extraComponentRef.destroy();
    this.extraComponentRef = this.createComponent(this.getExtraComponentProps(), extraComponent, this.tabExtraContainer);
  }
}
