import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { UserProgramProvider } from './user-program.provider';
import { UserGoal, UserProgram } from '../store/normalized/schemas/user.schema';
import { ConnectionHandlerProvider } from './http/connection-handler.provider';
import { OfflineProvider } from './offline.provider';
import { Observable } from 'rxjs';

// TODO: How is this different from a normalized record? Maybe move it to models at least
class Goal {
  name: string;
}

@Injectable({providedIn: 'root'})
export class GoalsProvider extends OfflineProvider {

  private userGoalsEndpoint = '/user_goals';
  private userProgramEndpoint = '/user_program';

  constructor(
    private userProgramProvider: UserProgramProvider,
    protected connectionHandler: ConnectionHandlerProvider,
    protected http: HttpProvider
  ) {
    super(connectionHandler, http);
  }

  updateDefaultGoals(userProgramWithNewGoals: UserProgram) {
    const userProgramCopy = userProgramWithNewGoals;
    delete userProgramCopy.license_code;

    return this.http.patch(this.userProgramEndpoint, userProgramCopy);
  }

  loadDefaultGoals() {
    return this.userProgramProvider.getUserProgram();
  }

  createCustomGoal(goal: Goal) {
    return this.http.post(
      this.userGoalsEndpoint,
      goal
    );
  }

  deleteCustomGoal(goalId: number) {
    return this.http.delete(this.userGoalsEndpoint + '/' + goalId);
  }

  loadUserGoals(): Observable<UserGoal[]> {
    return this.http.get<UserGoal[]>(this.userGoalsEndpoint);
  }

}
