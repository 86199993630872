// reminders actions

import { Action } from '@ngrx/store';
import { UserReminder } from '../../normalized/schemas/reminder.schema';

// reminders
export const LOAD_REMINDERS_SUCCESS = '[reminders] Load Reminders Success';

// user reminders
export const LOAD_USER_REMINDERS_SUCCESS = '[reminders] Load User Reminders Success';

export class LoadRemindersSuccess implements Action {
  readonly type = LOAD_REMINDERS_SUCCESS;

  constructor(public payload: any) {
  }
}

export class LoadUserRemindersSuccess implements Action {
  readonly type = LOAD_USER_REMINDERS_SUCCESS;

  constructor(public payload: UserReminder[]) {
  }
}

export type RemindersActions =
  | LoadRemindersSuccess
  | LoadUserRemindersSuccess;
