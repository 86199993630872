import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LiveLesson } from '../../../store/session/selectors/program.selectors';

@Component({
  selector: 'cl-next-lesson-button',
  styleUrls: ['next-lesson-button.component.scss'],
  template: `
    <ion-row>
      <ion-col auto class="ion-text-center">
        <cl-action-button
          label="{{getLabel() | translate}}"
          [canClick]="enabled"
          (action)="onAction()">
        </cl-action-button>
      </ion-col>
    </ion-row>
  `
})

export class NextLessonButtonComponent {
  @Input() lesson: LiveLesson;
  @Input() enabled: boolean;
  @Input() playExercise: boolean;
  @Input() isExercise: boolean;
  @Input() label: string;

  @Output() next = new EventEmitter();

  onAction() {
    this.next.emit({lesson: this.lesson});
  }

  getLabel(): string {
    if (this.label) { return this.label; }

    if (this.isExercise) {
      return 'common.next';
    }

    if (this.playExercise) {
      return 'lessons.done';
    }

    return this.lesson && this.lesson.isLast ? 'lessons.complete_module' : 'lessons.next';
  }
}
