import { Action } from '@ngrx/store';
import { InAppMessage } from '../../normalized/schemas/my-coach.schema';

export const INIT_WEBSOCKET = '[iridium] Init WebSocket';

export const LOAD_INAPP_MESSAGES = '[iridium] Load InApp Messages';
export const LOAD_INAPP_MESSAGES_SUCCESS = '[iridium] Load InApp Messages Success';
export const LOAD_INAPP_MESSAGES_FAIL = '[iridium] Load InApp Messages Fail';

export const MARK_INAPP_MESSAGE_AS_READ = '[iridium] Mark InApp Message As Read';
export const MARK_INAPP_MESSAGE_AS_READ_SUCCESS = '[iridium] Mark InApp Message As Read Success';
export const MARK_INAPP_MESSAGE_AS_READ_FAIL = '[iridium] Mark InApp Message As Read Fail';

export const ACTIVATE = '[iridium] Activate';
export const DEACTIVATE = '[iridium] Deactivate';

export class InitWebSocket implements Action {
  readonly type = INIT_WEBSOCKET;
}

export class MarkInAppMessageAsRead implements Action {
  readonly type = MARK_INAPP_MESSAGE_AS_READ;

  constructor(public payload: number) {
  }
}

export class MarkInAppMessageAsReadSuccess implements Action {
  readonly type = MARK_INAPP_MESSAGE_AS_READ_SUCCESS;

  constructor(public payload: number) {
  }
}

export class MarkInAppMessageAsReadFail implements Action {
  readonly type = MARK_INAPP_MESSAGE_AS_READ_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadInAppMessages implements Action {
  readonly type = LOAD_INAPP_MESSAGES;
}

export class LoadInAppMessagesSuccess implements Action {
  readonly type = LOAD_INAPP_MESSAGES_SUCCESS;

  constructor(public payload: InAppMessage[]) {
  }
}

export class LoadInAppMessagesFail implements Action {
  readonly type = LOAD_INAPP_MESSAGES_FAIL;

  constructor(public payload: any) {
  }
}

export class Activate implements Action {
  readonly type = ACTIVATE;
}

export class Deactivate implements Action {
  readonly type = DEACTIVATE;
}

export type IridiumActions =
  | InitWebSocket
  | MarkInAppMessageAsRead
  | MarkInAppMessageAsReadSuccess
  | MarkInAppMessageAsReadFail
  | LoadInAppMessages
  | LoadInAppMessagesSuccess
  | LoadInAppMessagesFail
  | Activate
  | Deactivate;
