import { createSchemaSelectors } from 'ngrx-normalizr';
import {
  CountByDay,
  checkinsByDaySchema,
  cigarettesByDaySchema,
  cravingToolsByDaySchema,
  programDaysByDaySchema,
  stressTestsByDaySchema,
  userWeekDaysByDaySchema,
  wantOMeterByDaySchema,
  worryToolByDaySchema
} from '../schemas/count-by-day.schema';
import { createSelector } from '@ngrx/store';
import * as moment from 'moment';

export function getForToday(listOfDays: CountByDay[] = []): CountByDay {
  const date = moment()
    .format('YYYY-MM-DD');
  const todayCount = listOfDays.find((countByDay) => countByDay.date === date);

  return todayCount || { date, count: 0 };
}

const checkinsByDaySchemaSelector = createSchemaSelectors<CountByDay>(checkinsByDaySchema);
const cigarettesByDaySchemaSelector = createSchemaSelectors<CountByDay>(cigarettesByDaySchema);
const stressTestsByDaySchemaSelector = createSchemaSelectors<CountByDay>(stressTestsByDaySchema);
const wantOMetersByDaySchemaSelector = createSchemaSelectors<CountByDay>(wantOMeterByDaySchema);
const cravingToolsByDaySchemaSelector = createSchemaSelectors<CountByDay>(cravingToolsByDaySchema);
const programDaysByDaySchemaSelector = createSchemaSelectors<CountByDay>(programDaysByDaySchema);
const userWeekDaysByDaySchemaSelector = createSchemaSelectors<CountByDay>(userWeekDaysByDaySchema);
const worryToolByDaySchemaSelector = createSchemaSelectors<CountByDay>(worryToolByDaySchema);

export const getCheckinsByDay = checkinsByDaySchemaSelector.getEntities;
export const getCigarettesByDay = cigarettesByDaySchemaSelector.getEntities;
export const getStressTestByDay = stressTestsByDaySchemaSelector.getEntities;
export const getWantOMetersByDay = wantOMetersByDaySchemaSelector.getEntities;
export const getCravingToolsByDay = cravingToolsByDaySchemaSelector.getEntities;
export const getProgramDaysByDay = programDaysByDaySchemaSelector.getEntities;
export const getUserWeekDaysByDay = userWeekDaysByDaySchemaSelector.getEntities;
export const getWorryToolByDay = worryToolByDaySchemaSelector.getEntities;
export const getModulesByDay = createSelector(
  getProgramDaysByDay,
  getUserWeekDaysByDay,
  (programDayExercisesByDay = [], userWeekDayExercisesByDay = []) => {
    const exercisesByDay = {};
    programDayExercisesByDay.forEach(countByDay => {
      if (!exercisesByDay[countByDay.date]) {
        exercisesByDay[countByDay.date] = 0;
      }
      exercisesByDay[countByDay.date] += countByDay.count;
    });
    userWeekDayExercisesByDay.forEach(countByDay => {
      if (!exercisesByDay[countByDay.date]) {
        exercisesByDay[countByDay.date] = 0;
      }
      exercisesByDay[countByDay.date] += countByDay.count;
    });

    return Object.keys(exercisesByDay)
      .map(date => {
        const count = exercisesByDay[date];

        return { date, count };
      });
  }
);

export const getCompletedModulesForToday = createSelector(
  getUserWeekDaysByDay,
  getProgramDaysByDay,
  (userWeekDays, programDays) => ({
    userWeekDay: getForToday(userWeekDays),
    programDay: getForToday(programDays)
  })
);

export const getCheckinsForToday = createSelector(
  getCheckinsByDay,
  getForToday
);

export const getCigarettesForToday = createSelector(
  getCigarettesByDay,
  getForToday
);

export const getStressTestForToday = createSelector(
  getStressTestByDay,
  getForToday
);

export const getWantOMeterForToday = createSelector(
  getWantOMetersByDay,
  getForToday
);

export const getCravingToolsForToday = createSelector(
  getCravingToolsByDay,
  getForToday
);

export const getProgramDayExercisesForToday = createSelector(
  getProgramDaysByDay,
  getForToday
);

export const getUserWeekDayExercisesForToday = createSelector(
  getUserWeekDaysByDay,
  getForToday
);

export const getWorryToolForToday = createSelector(
  getWorryToolByDay,
  getForToday
);
