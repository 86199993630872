import { AfterViewInit, ElementRef, ViewChild, Component, Input, OnDestroy, HostBinding } from '@angular/core';
import { Chart } from 'chart.js';
import moment from 'moment';
import Color from 'color';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { AwarenessQuiz } from '../store/normalized/schemas/awareness-quiz.schema';
import { getAwarenessQuizzes } from '../store/normalized/selectors/awareness-quiz.selectors';
import * as navigationActions from '../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { ClarityConfig } from '../config/clarity.config';
import { TranslateService } from '@ngx-translate/core';
import { SessionState } from '../store/session/session.reducers';

@Component({
  selector: 'cl-awareness-graph',
  styleUrls: ['awareness-graph.component.scss'],
  template: `
  <div class="graph-container">
    <div class="help-container">
      <span class="left">{{ 'awareness_graph.awareness' | translate }}</span>
      <span class="right" (click)="showHelp()" tappable>
        {{ 'awareness_graph.what_is_this' | translate }}
      </span>
    </div>
    <div [hidden]="hasData" class="graph-overlay">
      {{ 'awareness_graph.no_data' | translate }}
    </div>
    <div [hidden]="!chart" style="width:100%; height:300px;" [ngClass]="{'graph-blurred': !hasData}" class="graph-canvas">
      <canvas #AG >{{chart}}</canvas>
    </div>
    <div *ngIf="controls" class="controls-wrapper">
      <ion-row class="control-buttons-row">
        <ion-col class="ion-text-right">
          <ion-button size="small" expand="block" color="action"
            [disabled]="!hasPrevious"
            (click)="toPreviousWeek()">
            <ion-icon name='arrow-back'></ion-icon>
            {{ 'awareness_graph.prev' | translate | uppercase }}
          </ion-button>
        </ion-col>

        <ion-col class="ion-text-left">
          <ion-button size="small" expand="block" color="action"
            [disabled]="!hasNext"
            (click)="toNextWeek()">
            {{ 'awareness_graph.next' | translate | uppercase }}
            <ion-icon name='arrow-forward'></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
  `
})
export class AwarenessGraphComponent implements AfterViewInit, OnDestroy {
  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig,
    private translate: TranslateService
  ) {
  }

  get hasData() {
    return this.awarenessQuizzes.length > 0;
  }

  get hasNext() {
    return this.currChunk > 0;
  }

  get hasPrevious() {
    return this.currChunk < this.numChunks - 1;
  }

  get numChunks() {
    return Math.ceil(this.awarenessQuizzes.length / this.chunkSize);
  }

  @Input() controls = true;
  chart: Chart = null;

  readonly chunkSize = 7;

  ctx: any = null;
  awarenessQuizzes: AwarenessQuiz[] = [];
  currChunk = 0;

  dataSubscription: Subscription = undefined;
  translationSubscription: Subscription = undefined;

  private awarenessQuizzes$: Observable<AwarenessQuiz[]> = this.store.select(getAwarenessQuizzes);

  colorForDataSet = {
    focus: 'rgb(146, 220, 246)',
    learning: '#dca4d9',
    trust: '#fda533'
  };

  chartLabels = {};

  chartLabelsNoTrans = {
    focus: 'chart.focus',
    learning: 'chart.learning',
    trust: 'chart.trust'
  };

  pathForDataSet = {
    focus: 0,
    learning: 1,
    trust: 2
  };

  @ViewChild('AG', { static: true }) chartElement: ElementRef;

  showHelp() {
    const programCode = this.config
      .currentProgram()
      .toUpperCase();

    const type = `awarenessGraphWhatIsThis${programCode}`;

    this.store.dispatch(new navigationActions.ShowOnboarding({
      page: 'OnboardingPage',
      params: {
        type
      }
    }));
  }

  transparentize(color, opacity) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;

    return Color(color)
      .alpha(alpha)
      .rgb()
      .string();
  }

  toPreviousWeek() {
    if (this.hasPrevious) {
      this.currChunk += 1;
      this.updateData();
    }
  }

  toNextWeek() {
    if (this.hasNext) {
      this.currChunk -= 1;
      this.updateData();
    }
  }

  getDataChunk() {
    // on last chunk we either fill with less data or
    // we adjust the slice
    const data = [...this.awarenessQuizzes].reverse();
    const chunkStart = this.currChunk * this.chunkSize;
    const chunkEnd = chunkStart + this.chunkSize;

    return data.slice(chunkStart, chunkEnd)
      .reverse();
  }

  gradientBackgroundGenerator(color) {
    return Color(color)
      .alpha(0.6)
      .rgb()
      .string();
  }

  generateLabels() {
    const dataLabels = this.getDataChunk()
      .map((data) => {
        const dateTokens = data.date.split('-');

        return `${dateTokens[1]}/${dateTokens[2]}`;
      });

    return this.fillWith('', dataLabels);
  }

  generateLabelsColor(chart) {
    chart.legend.afterFit = () => {
      chart.legend.lineWidths = chart.legend.lineWidths.map(() => chart.width - (chart.width * .15));
    };

    const data = chart.data;
    if (data.labels.length && data.datasets.length) {
      return data.datasets.map((dataset, i) => ({
        text: dataset.label,
        lineWidth: 0,
        fillStyle: dataset.pointBackgroundColor,
        datasetIndex: i
      }));
    }

    return [];
  }

  fillWith(filler, array: any[]) {
    for (let i = array.length; i < 2; i++) {
      array.unshift(filler);
    }

    return array;
  }

  getDataSet(key) {
    const color = this.colorForDataSet[key];
    const dataChunk = this.getDataChunk();

    return {
      data: this.fillWith(0, this.getData(key, dataChunk)),
      label: this.chartLabels[key],
      backgroundColor: this.gradientBackgroundGenerator(color),
      pointBackgroundColor: color,
      borderColor: this.transparentize(color, 1),
      pointRadius: 0,
      fill: 'origin',
      borderWidth: 0
    };
  }

  getData(key, vanillaKeyData: AwarenessQuiz[]= []) {
    const data = vanillaKeyData.map((dataPoint: AwarenessQuiz) => dataPoint.scores[this.pathForDataSet[key]]);

    return data;
  }

  updateData(data?: AwarenessQuiz[]) {
    if (!this.ctx || !this.chart) {
      return;
    }
    if (data) {
      this.awarenessQuizzes = data.sort((a, b) => moment(a.date)
        .diff(moment(b.date)));
    }
    this.chart.data.datasets = [
      this.getDataSet('focus'),
      this.getDataSet('learning'),
      this.getDataSet('trust')
    ];
    this.chart.data.labels = this.generateLabels();
    this.chart.update();
  }

  getFreshData() {
    this.dataSubscription = combineLatest([
      this.awarenessQuizzes$
    ])
      .subscribe(([data]) => {
        this.updateData(data);
      });
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
  }

  signToTranslations() {
    this.translationSubscription = this.translate.onLangChange
      .subscribe(() => {
        this.translateLabels()
          .then(() => this.updateData());
      });
  }

  translateLabels() {
    return Promise.all(
      Object.keys(this.chartLabelsNoTrans)
        .map(key => {
          this.translate.get(this.chartLabelsNoTrans[key])
            .toPromise()
            .then((translation) => {
              this.chartLabels[key] = translation;
            });
        })
    );
  }

  ngAfterViewInit() {
    const labels = this.generateLabels();
    this.ctx = this.chartElement.nativeElement.getContext('2d');
    const config = {
      type: 'bar',
      data: {
        labels,
        datasets: [
          this.getDataSet('focus'),
          this.getDataSet('learning'),
          this.getDataSet('trust')
        ]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            padding: 14,
            usePointStyle: true,
            generateLabels: this.generateLabelsColor
          }
        },
        responsive: true,
        title: {
          display: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            stacked: true,
            display: true,
            gridLines: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              offsetGridLines: true
            },
            scaleLabel: {
              display: false
            }
          }],
          yAxes: [{
            // display: false,
            stacked: true,
            gridLines: {
              drawBorder: false
            },
            ticks: {
              beginAtZero: true,
              callback(value) { if (Number.isInteger(value)) { return value; } }
            },
            scaleLabel: {
              display: false,
              labelString: 'Value'
            }
          }]
        }
      }
    };

    this.signToTranslations();
    this.translateLabels()
      .then(() => {
        this.chart = new Chart(this.ctx, config);
        this.getFreshData();
      });
  }
}
