import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpProvider } from './http/http.provider';
import { map } from 'rxjs/operators';

export interface WeightScaleOrder {
  provider: 'fitbit';
  address1: string;
  address2: string;
  postal_code: string;
  city: string;
  state: string;
  country: string;
  scale_sku: '203BK' /* Aria air black */ | '203WT' /* Aria air write */;
  phone: string;
  first_name: string;
  last_name: string;
}

@Injectable({providedIn: 'root'})
export class HealthDevicesProvider {
  constructor(
    private readonly http: HttpProvider
  ) {}

  // --- Weight Scale endpoints
  private weightScaleOrderEndpoint = '/weight_scale_orders';

  // Activity tracker endpoints
  private activityTrackerOrderEndpoint = '/activity_tracker_orders/fitbit_promo';

  createWeightScaleOrder(weightScaleOrder: WeightScaleOrder): Observable<WeightScaleOrder> {
    return this.http.post(this.weightScaleOrderEndpoint, weightScaleOrder);
  }

  fetchWeightScaleOrders(): Observable<WeightScaleOrder[]> {
    return this.http.get(this.weightScaleOrderEndpoint);
  }

  fetchFitBitTrackerOrderUrl(): Observable<string> {
    return this.http.get(this.activityTrackerOrderEndpoint)
      .pipe(map(response => response.url));
  }
}
