<cl-waves-background
  class="interstitial-waves-background"
  [ngClass]="'interstitial-' + (navParams.get('color') || interstitialSettings.color) + (navParams.get('hideBadge') ? ' no-badge' : '')">

  <ion-content (click)="onClick()">

    <ion-header>
      <cl-header-nav-buttons
        class="page-header"
        color="blank"
        [backButton]="false">
      </cl-header-nav-buttons>
    </ion-header>

    <ion-grid class="page-wrapper">
      <ion-row class="icon-holder" *ngIf="navParams.get('hideBadge') !== true">
        <ion-col class="ion-text-center ion-no-padding">
          <img [src]="'assets/imgs/badge/' + (navParams.get('badge') || interstitialSettings.badge) + '.png'">
        </ion-col>
      </ion-row>

      <div class="content">
        <ion-grid fixed>
          <ion-row class="title-holder lateral-padding ion-padding" *ngIf="navParams.get('title') || interstitialSettings.title"
                    [ngClass]="{'large-text': navParams.get('largeText'), 'no-vertical-padding': navParams.get('bonusExercisesLink')}">
            <ion-col class="ion-no-padding">
              <h1>{{navParams.get('title') || interstitialSettings.title | translate}}</h1>

              <h2>{{ navParams.get('subTitle') | translate }}</h2>
            </ion-col>
          </ion-row>

          <ion-row class="link-holder ion-padding-horizontal" *ngIf="navParams.get('reminderSettingsLink')">
            <ion-col>
              <p class="tap-link" (click)="updateReminderSettings($event)">{{'interstitials.tap_to_update_reminders' | translate}}</p>
            </ion-col>
          </ion-row>

          <ion-row class="link-holder ion-padding-horizontal" *ngIf="navParams.get('goal')">
            <ion-col>
              <p>{{ navParams.get('goal') | translate }}</p>
            </ion-col>
          </ion-row>

          <ion-row class="link-holder ion-padding-horizontal" *ngIf="navParams.get('communityLink')">
            <ion-col>
              <p class="tap-link small-top-padding" (click)="openJournal($event)">
                {{'interstitials.tap_to_go_to_journal' | translate}}
              </p>
            </ion-col>
          </ion-row>

          <ion-row class="link-holder ion-padding-horizontal" *ngIf="navParams.get('bonusExercisesLink')">
            <ion-col>
              <p class="no-bottom-padding">{{'interstitials.you_received_bonus_exercises' | translate}}</p>
              <p class="tap-link small-top-padding" (click)="openBonusExercise($event)">
                {{'interstitials.tap_to_get_bonus_exercise' | translate}}
              </p>
            </ion-col>
          </ion-row>

          <ion-row class="instructions-holder lateral-padding ion-padding-horizontal">
            <ion-col>
              <p [innerHTML]="navParams.get('notes') | translate"></p>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <ion-row class="exit-info padding-bottom-iphone-x">
        <ion-col class="ion-text-center">
          <p data-e2e="dismiss-btn" class="dismiss-btn" (click)="buttonAction($event)">
            {{navParams.get('buttonText') || 'interstitials.continue' | translate}}
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>

  </ion-content>
</cl-waves-background>
