// program actions

import { Action } from '@ngrx/store';

import { SmokingType } from '../../normalized/schemas/user.schema';

// update user program
export const UPDATE_USER_PROGRAM = '[user program] Update';
export const UPDATE_USER_PROGRAM_FAIL = '[user program] Update Fail';
export const UPDATE_USER_PROGRAM_SUCCESS = '[user program] Update Success';

// update user program language
export const UPDATE_USER_PROGRAM_LANGUAGE = '[user program] Update Language';
export const UPDATE_USER_PROGRAM_LANGUAGE_FAIL = '[user program] Update Language Fail';
export const UPDATE_USER_PROGRAM_LANGUAGE_SUCCESS = '[user program] Update Language Success';

// update user program smoking type
export const UPDATE_USER_PROGRAM_SMOKING_TYPE = '[user program] Update Smoking Type';
export const UPDATE_USER_PROGRAM_SMOKING_TYPE_FAIL = '[user program] Update Smoking Type Fail';
export const UPDATE_USER_PROGRAM_SMOKING_TYPE_SUCCESS = '[user program] Update Smoking Type Success';

// load user program
export const LOAD_USER_PROGRAM = '[user program] Load';
export const LOAD_USER_PROGRAM_FAIL = '[user program] Load Fail';
export const LOAD_USER_PROGRAM_SUCCESS = '[user program] Load Success';

export class UpdateUserProgram implements Action {
  readonly type = UPDATE_USER_PROGRAM;

  constructor(public payload: any) {
  }
}

export class UpdateUserProgramSuccess implements Action {
  readonly type = UPDATE_USER_PROGRAM_SUCCESS;
}

export class UpdateUserProgramFail implements Action {
  readonly type = UPDATE_USER_PROGRAM_FAIL;

  constructor(public payload: any) {
  }
}

export class UpdateUserProgramLanguage implements Action {
  readonly type = UPDATE_USER_PROGRAM_LANGUAGE;

  constructor(public payload: any) {
  }
}

export class UpdateUserProgramLanguageSuccess implements Action {
  readonly type = UPDATE_USER_PROGRAM_LANGUAGE_SUCCESS;

  constructor(public languageCode: string, public silent?: boolean) {}
}

export class UpdateUserProgramLanguageFail implements Action {
  readonly type = UPDATE_USER_PROGRAM_LANGUAGE_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadUserProgram implements Action {
  readonly type = LOAD_USER_PROGRAM;
}

export class LoadUserProgramFail implements Action {
  readonly type = LOAD_USER_PROGRAM_FAIL;

  constructor(public payload: any) {
  }
}

export class LoadUserProgramSuccess implements Action {
  readonly type = LOAD_USER_PROGRAM_SUCCESS;
}

export class UpdateUserProgramSmokingType implements Action {
  readonly type = UPDATE_USER_PROGRAM_SMOKING_TYPE;

  constructor(public smokingType: SmokingType) {
  }
}

export class UpdateUserProgramSmokingTypeSuccess implements Action {
  readonly type = UPDATE_USER_PROGRAM_SMOKING_TYPE_SUCCESS;
}

export class UpdateUserProgramSmokingTypeFail implements Action {
  readonly type = UPDATE_USER_PROGRAM_SMOKING_TYPE_FAIL;
}

export type UserProgramActions =
  | UpdateUserProgram
  | UpdateUserProgramFail
  | UpdateUserProgramSuccess
  | UpdateUserProgramLanguage
  | UpdateUserProgramLanguageFail
  | UpdateUserProgramLanguageSuccess
  | LoadUserProgram
  | LoadUserProgramFail
  | LoadUserProgramSuccess
  | UpdateUserProgramSmokingType
  | UpdateUserProgramSmokingTypeSuccess
  | UpdateUserProgramSmokingTypeFail;
