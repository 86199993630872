import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
  private currentLanguage = this.translate.currentLang;

  constructor(
    private translate: TranslateService
  ) {
    moment.locale(this.currentLanguage);
  }

  getRelativeTime(time: moment.MomentInput, calendarMode = false): string {
    const date = moment.utc(time);
    const local_date = date.local();

    return calendarMode ? local_date.calendar(null, {sameElse: 'LLLL'}) : local_date.fromNow();
  }

  transform(time: moment.MomentInput, calendar = false): string {
    return this.getRelativeTime(time, calendar);
  }

}
