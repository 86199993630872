import { Injectable } from '@angular/core';
import { cordova, IonicNativePlugin } from '@ionic-native/core';

@Injectable()
export class IRootPlugin extends IonicNativePlugin {
  static plugin = 'cordova-plugin-iroot';
  static pluginName = 'IRoot';
  static pluginRef = 'IRoot';
  static platforms = ['Android', 'iOS'];

  isRooted(success: (result: boolean) => any, failure: (error: string) => any) {
    return cordova(this, 'isRooted', {}, [success, failure]);
  }
}
