import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommunityPost } from '../store/normalized/schemas/community.schema';

@Injectable({providedIn: 'root'})
export class CommunityProvider {

  private journalEndpoint = '/community/journal';
  private communityPostsEndpoint = '/community/posts';
  private bookmarkedPostsEndpoint = '/community/tracked_posts';
  private videoChatEndpoint = '/video_chat_schedules';

  constructor(
    private http: HttpProvider
  ) {

  }

  loadJournal() {
    return this.http.get(this.journalEndpoint)
      .pipe(
        catchError((error) => {
          // 403 means user has no access to community features
          if (error.status === 403) {
            return of(null);
          }

          throw error;
        })
      );
  }

  loadCommunityPosts() {
    return this.http.get<CommunityPost[]>(this.communityPostsEndpoint)
      .pipe(
        catchError((error) => {
          // 403 means user has no access to community features
          if (error.status === 403) {
            return of({});
          }

          throw error;
        })
      );
  }

  loadBookmarkedPosts() {
    return this.http.get<CommunityPost[]>(this.bookmarkedPostsEndpoint)
      .pipe(
        catchError((error) => {
          // 403 means user has no access to community features
          if (error.status === 403) {
            return of({});
          }

          throw error;
        })
      );
  }

  // TODO: Add authorization on API
  loadVideoChat() {
    return this.http.get(this.videoChatEndpoint)
      .pipe(
        catchError((error) => {
          // 403 means user has no access to community features
          if (error.status === 403) {
            return of({});
          }

          throw error;
        })
      );
  }
}
