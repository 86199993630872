import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClarityConfig } from '../../config/clarity.config';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

// eslint-disable-next-line no-var
declare var window;

@Component({
  selector: 'cl-vertical-slider',
  styleUrls: ['vertical-slider.component.scss'],
  template: `
    <input type="range"
           min="{{min}}"
           max="{{max}}"
           step="{{step}}"
           snaps="true"
           color="secondary"
           class="vertical-slider-input"
           [(ngModel)]="value"
           [disabled]="disabled"
           [class.disabled]="disabled"
           (ngModelChange)="onValueChanged()">

    <div class="range-bar">
      <div class="range-knob"></div>
    </div>
  `
})
export class VerticalSliderComponent {

  @Input() value = null;
  @Input() min = 0;
  @Input() max = 100;
  @Input() step = 1;
  @Input() disabled = false;

  @Output() slide = new EventEmitter();

  constructor(
    private config: ClarityConfig
  ) {

  }

  onValueChanged() {
    if (this.config.isDevice) {
      Haptics.impact({ style: ImpactStyle.Light })
        .catch(error => console.log(error));
    }

    this.slide.emit(this.value);
  }

}
