import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import * as offlineQueueActions from '../../session/actions/offline-queue.actions';

import { OfflineQueueService } from '../../../../app/services/offline-queue.service';
import * as programActions from '../actions/program.actions';

@Injectable({providedIn: 'root'})
export class OfflineQueueEffects {

  
  loadLiveProgram$ = createEffect(() => this.actions$.pipe(ofType(programActions.LOAD_LIVE_PROGRAM),
    tap(() => {
      // enable queueing
      this.queueService.enableQueueing();
    })
  ), {dispatch: false});

  
  queueRequest$ = createEffect(() => this.actions$.pipe(ofType<offlineQueueActions.QueueRequest>(offlineQueueActions.QUEUE_REQUEST),
    tap(() => {
      this.queueService.checkQueue();
    })
  ), {dispatch: false});

  
  sendRequests$ = createEffect(() => this.actions$.pipe(ofType<offlineQueueActions.SendRequests>(offlineQueueActions.SEND_REQUESTS),
    map((action) => action.payload),
    tap((requestIds) => {
      this.queueService.sendRequests(requestIds);
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private queueService: OfflineQueueService
  ) {
  }
}
