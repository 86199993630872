import * as HealthDevicesActions from '../actions/health-devices.actions';


/**
 * Inspirations:
 *
 * @see https://www.hl7.org/fhir/device.html
 * @see https://schema.org/MedicalDevice
 */
export interface WeightScale {
  orderingStatus: null | 'progress' | 'failed' | 'succeed';
}

export interface HealthDevicesState {
  weightScale: WeightScale;
}

export const initialHealthDevicesState: HealthDevicesState = {
  weightScale: {
    orderingStatus: null
  }
};

export function healthDevicesReducer(state = initialHealthDevicesState, action: HealthDevicesActions.HealthDevicesActions): HealthDevicesState {
  switch (action.type) {
    case HealthDevicesActions.ORDER_WEIGHT_SCALE:
      return {
        ...state,
        weightScale: {
          ...state.weightScale,
          orderingStatus: 'progress'
        }
      };
    case HealthDevicesActions.ORDER_WEIGHT_SCALE_SUCCESS:
      return {
        ...state,
        weightScale: {
          ...state.weightScale,
          orderingStatus: 'succeed'
        }
      };
    case HealthDevicesActions.ORDER_WEIGHT_SCALE_FAIL:
      return {
        ...state,
        weightScale: {
          ...state.weightScale,
          orderingStatus: 'failed'
        }
      };

    case HealthDevicesActions.RESET_FETCH_WEIGHT_SCALE_ORDERS:
      return {
        ...state,
        weightScale: {
          ...state.weightScale
        }
      };
    default: {
      return state;
    }
  }

}
