import { Injectable } from '@angular/core';

import { HttpProvider } from '../http/http.provider';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Subscription } from '../../store/normalized/schemas/subscription.schema';

@Injectable({providedIn: 'root'})
export class SubscriptionsProvider {

  private stripePlansEndpoint = '/stripe_plans';
  private stripePlansUpgradeEndpoint = '/stripe_plans/upgrade';
  private stripeCardsEndpoint = '/stripe_cards';

  private codeCheckEndpoint = '/codes/check';

  private subscriptionsEndpoint = '/subscription';
  private restoreSubscriptionsEndpoint = this.subscriptionsEndpoint + '/restore';

  private productsEndpoint = '/store_products';

  constructor(
    private http: HttpProvider
  ) {

  }

  public loadSubscriptions() {
    return this.http.get<Subscription>(this.subscriptionsEndpoint)
      .pipe(
        catchError((error) => {
          // not found means the user has no subscription, so technically this is not an error
          if (error.status === 404) {
            return of(new Subscription());
          }

          throw error;
        })
      );
  }

  public loadStoreProducts() {
    return this.http.get<{ name: string; name_alias: string }[]>(this.productsEndpoint);
  }

  public initializeSubscription() {
    return this.http.post(this.subscriptionsEndpoint, {});
  }

  public savePurchase(data) {
    return this.http.patch(this.subscriptionsEndpoint, data);
  }

  public restoreSubscription(data) {
    return this.http.post(this.restoreSubscriptionsEndpoint, data);
  }

  public cancelSubscription() {
    return this.http.delete(this.subscriptionsEndpoint);
  }

  public purchaseSubscription(card, stripe_plan_id, coupon_code = null) {
    return this.http.post(this.subscriptionsEndpoint, {
      provider: 'stripe',
      stripe_plan_id,
      coupon_code,
      card
    });
  }

  public saveStripeCard(token) {
    return this.http.post(this.stripeCardsEndpoint, {token});
  }

  public loadStripePlans(upgrade, code) {
    let endpoint = this.stripePlansEndpoint;

    if (upgrade) {
      endpoint = this.stripePlansUpgradeEndpoint;
    }

    if (code) {
      endpoint = `${endpoint}?promo_code=${code}`;
    }

    return this.http.get(endpoint);
  }

  public getCodeInfo(code: string) {
    return this.http.get(`${this.codeCheckEndpoint}?program_code=${code}`);
  }
}
