import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'cl-nrt-navigation',
  styleUrls: [
    'nrt-navigation.component.scss',
    'nrt.component.scss'
  ],
  template: `
      <ion-row class="buttons-holder ion-justify-content-center">
        <ion-col class="previous-col" *ngIf="!hidePrevious">
          <ion-button
            class="prev-step-btn"
            type="submit"
            [disabled]="disablePrevious"
            (click)="onPreviousClick()">
            {{ previousText }}
          </ion-button>
        </ion-col>
        <ion-col class="next-col" *ngIf="!hideNext">
          <ion-button
            color="action"
            class="next-step-btn"
            type="submit"
            [disabled]="disableNext"
            (click)="onNextClick()">
            {{ nextText }}
          </ion-button>
        </ion-col>
      </ion-row>
  `,
  encapsulation: ViewEncapsulation.Emulated
})
export class NrtNavigationComponent {

  constructor(private translateService: TranslateService) {
    if (!this.previousText) {
      this.translateService.get('common.back')
        .pipe(first())
        .subscribe((text) => this.previousText = text);
    }
  }

  @Input() hidePrevious = false;
  @Input() disablePrevious = false;
  @Input() previousText = null;

  @Input() hideNext = false;
  @Input() disableNext = false;
  @Input() nextText = null;

  @Output() previous = new EventEmitter();
  @Output() next = new EventEmitter();

  onPreviousClick() {
    this.previous.emit();
  }

  onNextClick() {
    this.next.emit();
  }
}
