
<div class="floating-background-container"></div>

<div class="header container" *ngIf="header">
  <h1 [innerHTML]="header.title | translate"></h1>
  <p [innerHTML]="header.paragraph | translate"></p>

  <a class="logout" *ngIf="logoutBtn" (click)="onLogout()">{{'auth.logout' | translate}}</a>
</div>

<div class="tabs-wrapper container">
  <div class="tabs-header" [class.open]="isMenuExpanded" #tabsHeader>
    <ng-container *ngFor="let tab of tabs; let i = index">
      <div *ngIf="!(tab.hideOnSSO && (shouldHideSubscription$ | async))"
           class="label"
           [class.only-mobile]="tab.onlyMobile"
           [class.active]="activeTab === i"
           [class.has-badge-slot]="tab.hasBadgeSlot"
           (click)="clickTab(i)">
           <span class="title">
            {{ tab.title | translate }}
              <cl-badge [@delayed] color="danger"
                        *ngIf="config.programDPP() && doesCurrentTabHaveBadgeSlot(tab, i) && (weightActivityActionsPending$ | async) > 0">
                {{ weightActivityActionsPending$ | async }}
              </cl-badge>
           </span>
      </div>
    </ng-container>
  </div>

  <div
    class="tabs-content"
    [class.has-disabled-inner-padding]="tabs[this.activeTab]?.hasDisabledInnerPadding"
    [class.last-one]="tabs[this.activeTab] && !tabs[this.activeTab].extraComponentRef"
  >
    <div #tabContainer></div>
  </div>

  <div class="tabs-extra-content" [hidden]="tabs[this.activeTab] && !tabs[this.activeTab].extraComponentRef">
    <div #tabExtraContainer></div>
  </div>
</div>
