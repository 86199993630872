import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-prev-button',
  styleUrls: ['prev-button.component.scss'],
  template: `
    <ion-button
      expand="full"
      class="prev-btn button-round"
      type="submit"
      color="white"
      (click)="onClick()"
      [disabled]="!canClick">
      {{label | translate}}
    </ion-button>
  `
})

export class PrevButtonComponent {

  @Input() canClick = true;
  @Input() label = 'common.prev';

  @Output() action = new EventEmitter();

  constructor() {}
  
  onClick() {
    if (!this.canClick) {
      return false;
    }

    this.action.emit();
  }

}
