import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  EventEmitter,
  Output
} from '@angular/core';
import { Store } from '@ngrx/store';
import { getSensations } from '../../../store/normalized/selectors/list-items.selectors';
import { State } from '../../../store/state.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-sensation-capture-lesson',
  styleUrls: ['sensation-capture-lesson.component.scss'],
  template: `
    <cl-pick-sensation
      [sensations]="sensations$ | async"
      [fromLesson]="true"
      (selectItem)="doAction($event)"
      [selected]="sensationChoice">
    </cl-pick-sensation>
  `
})
export class SensationCaptureLessonComponent implements OnInit {

  sensationChoice: any;
  @Output() enableNext = new EventEmitter();
  @Output() disableNext = new EventEmitter();
  sensations$ = this.store.select(getSensations);

  constructor(
    private store: Store<State>
  ) {
  }

  ngOnInit() {
    this.disableNext.emit();
  }

  doAction(event) {
    this.sensationChoice = event;
    this.enableNext.emit();
  }
}
