import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { InterstitialPage } from './interstitial';
import { SharedModule } from '../../../shared';
import { translateConfig } from '../../config/translate.config';
import { CommonModule } from '@angular/common';
import { InterstitialWeightCapturePage } from './interstitial-weight-capture';
import { InterstitialDppCongratsPage } from './interstitial-dpp-congrats';
import { InterstitialDppLockPage } from './interstitial-dpp-lock';
import { InterstitialLockedPage } from './interstitial-locked';
import { InterstitialDefaultPage } from './interstitial-default';
import { InterstitialLessonNotSupportedPage } from './interstitial-lesson-not-supported';
import { InterstitialDppFitbitTrackerPage } from 'src/app/pages/interstitial/interstitial-dpp-fitbit-tracker';
import { InterstitialErnWeightLossDiagnosticPage } from 'src/app/pages/interstitial/interstitial-ern-weight-loss-diagnostic';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    InterstitialPage,
    InterstitialDefaultPage,
    InterstitialLockedPage,
    InterstitialWeightCapturePage,
    InterstitialDppCongratsPage,
    InterstitialDppFitbitTrackerPage,
    InterstitialDppLockPage,
    InterstitialLessonNotSupportedPage,
    InterstitialErnWeightLossDiagnosticPage
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: InterstitialPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule,
    ReactiveFormsModule,
    SwiperModule,
    FormsModule
  ]
})
export class InterstitialPageModule {}
