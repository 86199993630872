import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import { TranslateService } from '@ngx-translate/core';
import { NrtService, NrtSteps, ValidateShippingDataResponse } from '../../services/nrt.service';
import { first } from 'rxjs/operators';
import { ToastService } from '../../services/toast.service';
import { Observable } from 'rxjs';
import { getAccess, getAccessLoading } from '../../store/session/selectors/nrt.selectors';
import { ClarityConfig } from '../../config/clarity.config';
import { LoadingService } from '../../services/loading.service';
import { NrtStepsCounterService } from 'src/app/services/nrt-steps-counter.service';
import { getCurrentUserProgram } from '../../store/normalized/selectors/user.selectors';

@Component({
  selector: 'page-nrt',
  templateUrl: './nrt.html',
  styleUrls: ['./nrt.scss'],
  providers: [NrtStepsCounterService]
})
export class NrtPage implements OnInit, AfterViewInit {
  modal;
  currentStep: NrtSteps;
  currentTitle = '';
  disableNext = false;
  disablePrevious = false;
  hidePrevious = false;
  NRT_STEPS = NrtSteps;
  nextText: string = null;
  questionIndex = 1;
  nextStep: NrtSteps;
  counters: {};
  smokingType: string;

  accessLoading$: Observable<boolean> = this.store.select(getAccessLoading);
  access$: Observable<{ success: boolean; message: string }> = this.store.select(getAccess);
  userProgram$ = this.store.select(getCurrentUserProgram);

  constructor(
    public store: Store<State>,
    private translateService: TranslateService,
    public nrtService: NrtService,
    private nrtStepsCounterService: NrtStepsCounterService,
    private toastService: ToastService,
    public config: ClarityConfig,
    private loading: LoadingService
  ) {
    this.userProgram$.subscribe((userProgram) => {
      this.smokingType = userProgram.smoking_type;
    });
    this.changeStep(NrtSteps.INTRO);
    this.toggleButtonsEnabling();
  }

  ngOnInit(): void {
    this.nrtService.getNrtAccess();
  }

  ngAfterViewInit() {
    this.nrtStepsCounterService.surveyStepsCounter$.subscribe(stepsCounter => this.counters = stepsCounter);
  }

  async changeStep(step): Promise<any> {
    const previousStep = this.currentStep;
    this.currentStep = step;

    this.translateService.get([`nrt.${this.currentStep}`, 'common.next', 'common.order'])
      .subscribe((trads) => {
        const stepTrads = trads[`nrt.${this.currentStep}`];
        this.currentTitle = stepTrads.title;

        this.nextText =
          (previousStep === NrtSteps.PRODUCT && step === NrtSteps.SHIPPING && !this.nrtService.hasAssertions())
          || previousStep === NrtSteps.ASSERTIONS && step === NrtSteps.PROVIDER
            ? trads['common.order']
            : stepTrads.button || trads['common.next'];
      });
  }

  onClose(): void {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
    this.nrtService.reinitData();
  }

  toggleButtonsEnabling() {
    // selected click handler (answer clicked)
    // also called from onNext()
    switch (this.currentStep) {
      case NrtSteps.INTRO:
      case NrtSteps.DOCTOR_SUCCESS:
      case NrtSteps.ORDER_SUCCESS:
        this.hidePrevious = this.disablePrevious = true;
        this.disableNext = false;
        break;

      case NrtSteps.MEDICAL_CONDITION_SURVEY:
        const counter = this.counters[NrtSteps.MEDICAL_CONDITION_SURVEY];
        this.hidePrevious = this.disablePrevious = !counter || counter.current === 1;
        this.disableNext = false;
        break;

      case NrtSteps.PRODUCT_SURVEY:
        this.hidePrevious = this.disablePrevious = false;
        this.disableNext = false;
        break;

      case NrtSteps.PRODUCT:
        this.hidePrevious = this.disablePrevious = false;
        this.disableNext = false;
        break;

      case NrtSteps.FLAVOR_SELECTION:
        this.hidePrevious = this.disablePrevious = false;
        this.disableNext = this.nrtService.gumFlavor === null;
        break;

      case NrtSteps.SHIPPING:
        this.hidePrevious = this.disablePrevious = false;
        this.disableNext = !this.nrtService.shippingValid;
        break;

      case NrtSteps.PROVIDER:
        this.hidePrevious = this.disablePrevious = false;
        this.disableNext = !this.nrtService.providerValid;
        break;
    }
  }

  onPrevious() {
    switch (this.currentStep) {
      case NrtSteps.MEDICAL_CONDITION_SURVEY:
        this.nrtStepsCounterService.back(NrtSteps.MEDICAL_CONDITION_SURVEY);
        break;

      case NrtSteps.PRODUCT_SURVEY:
        const counter = this.counters[NrtSteps.PRODUCT_SURVEY];
        if (counter.current > 1) {
          this.nrtStepsCounterService.back(NrtSteps.PRODUCT_SURVEY);
        } else {
          this.changeStep(NrtSteps.MEDICAL_CONDITION_SURVEY);
        }
        break;

      case NrtSteps.PRODUCT:
        this.changeStep(NrtSteps.PRODUCT_SURVEY);
        break;

      case NrtSteps.FLAVOR_SELECTION:
        this.changeStep(NrtSteps.PRODUCT);
        break;

      case NrtSteps.SHIPPING:
        this.changeStep(this.nrtService.productIsGum ? NrtSteps.FLAVOR_SELECTION : NrtSteps.PRODUCT);
        break;

      case NrtSteps.ASSERTIONS:
        this.changeStep(NrtSteps.SHIPPING);
        break;

      case NrtSteps.PROVIDER:
        this.changeStep(NrtSteps.ASSERTIONS);
        break;
    }

    this.toggleButtonsEnabling();
  }

  onNext() {
    let counter;

    // next btn click handler
    switch (this.currentStep) {
      case NrtSteps.INTRO:
        this.changeStep(NrtSteps.MEDICAL_CONDITION_SURVEY);
        this.disableNext = true;
        break;

      case NrtSteps.MEDICAL_CONDITION_SURVEY:
        this.disableNext = true;
        counter = this.counters[NrtSteps.MEDICAL_CONDITION_SURVEY];

        if (counter.current < counter.total) {
          this.nrtStepsCounterService.next(NrtSteps.MEDICAL_CONDITION_SURVEY);
        } else {
          this.store.dispatch(new navigationActions.ShowInterstitial({
            page: 'InterstitialPage',
            params: {
              type: 'nrtThanks',
              notes: this.smokingType === 'cigarette' ? 'nrt.survey_2_introduction.description' : 'nrt.survey_2_introduction.description_vaping',
              closeAllOnDismiss: false
            }
          }));

          setTimeout(() => this.changeStep(NrtSteps.PRODUCT_SURVEY), 500);
        }

        break;

      case NrtSteps.PRODUCT_SURVEY:
        this.disableNext = true;
        counter = this.counters[NrtSteps.PRODUCT_SURVEY];

        if (counter.current < counter.total) {
          this.nrtStepsCounterService.next(NrtSteps.PRODUCT_SURVEY);
        } else {
          this.changeStep(NrtSteps.PRODUCT);
        }
        break;

      case NrtSteps.PRODUCT:
        this.changeStep(this.nrtService.productIsGum ? NrtSteps.FLAVOR_SELECTION : NrtSteps.SHIPPING);
        break;

      case NrtSteps.FLAVOR_SELECTION:
        this.changeStep(NrtSteps.SHIPPING);
        break;

      case NrtSteps.SHIPPING:
        this.nrtService.validateShippingData()
          .pipe(first())
          .subscribe((validation: ValidateShippingDataResponse) => {
            if (validation.isValid) {
              this.orderIfNeeded();
            } else {
              this.toastService.translateError('errors.nrt.address_invalid');
            }
          });
        break;

      case NrtSteps.ASSERTIONS:
        this.changeStep(NrtSteps.PROVIDER);
        break;

      case NrtSteps.PROVIDER:
        this.loading.showLoadingOverlay();
        this.nrtService.order()
          .pipe(first())
          .subscribe(
            _ => {
              this.showEndInterstitial();
            },
            _ => {
              this.loading.hideLoadingOverlay();
              this.toastService.translateError('errors.nrt.order_error');
            }
          );
        break;
    }

    this.toggleButtonsEnabling();
  }

  private showEndInterstitial() {
    this.loading.hideLoadingOverlay();

    const noteType = this.nrtService.hasAssertions() ? 'doctorSuccess' : 'orderSuccess';

    this.store.dispatch(new navigationActions.ShowInterstitial({
      page: 'InterstitialPage',
      params: {
        type: 'nrtComplete',
        notes: `nrt.${noteType}.description`,
        buttonText: 'common.done'
      }
    }));

    this.nrtService.reinitData();
  }

  private orderIfNeeded() {
    // if there are assertions, we don't order for now
    if (this.nrtService.hasAssertions()) {
      this.changeStep(NrtSteps.ASSERTIONS);

      return;
    }

    this.loading.showLoadingOverlay();

    // if there are no assertions we order right away
    this.nrtService.order()
      .pipe(first())
      .subscribe(
        _ => {
          this.showEndInterstitial();
        },
        _ => {
          this.loading.hideLoadingOverlay();
          this.toastService.translateError('errors.nrt.order_error');
        }
      );
  }
}
