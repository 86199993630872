export const exerciseTitleToImg = (language: string, title: string): string => ({
  en: {
    'Noticing Movement': 'note.png',
    'Noting Practice': 'note.png',
    'Hearing Practice': 'head-scan.png',
    'Awareness of Thinking': 'head-scan.png',
    'Head Scan': 'head-scan.png',
    'Body Awareness': 'body.png',
    '3-Center Check-In': 'body.png',
    '3 Breath Awareness': '3-breath.png',
    'Arms Up': '3-breath.png',
    'Hand Awareness': 'hand.png',
    'Feet Awareness': 'feet.png',
    'Stand Up Awareness': 'stand-up.png',
    'Smiling Breath': 'smile-breath.png',
    'Breathing to Your Toes': 'toes.png'
  },
  es: {
    'Observar el movimiento': 'note.png',
    'Práctica de observación': 'note.png',
    'Práctica auditiva': 'head-scan.png',
    'Conciencia de pensar': 'head-scan.png',
    'Escaneo de la cabeza': 'head-scan.png',
    'Conciencia corporal': 'body.png',
    'Revisión en 3 centros': 'body.png',
    '3 respiraciones conscientes': '3-breath.png',
    'Brazos arriba': '3-breath.png',
    'Conciencia de la mano': 'hand.png',
    'Conciencia de los pies': 'feet.png',
    'Conciencia de pie': 'stand-up.png',
    'Aliento sonriente': 'smile-breath.png',
    'Respirar con tus dedos de los pies': 'toes.png'
  }
}[language][title]);
