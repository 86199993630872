import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../store/session';
import * as fromAuth from '../../../store/sensitive/selectors/auth.selectors';
import { Md5 } from 'ts-md5';
import { ClarityConfig } from '../../../config/clarity.config';

@Injectable({providedIn: 'root'})
export class MediaFilesInterceptor implements HttpInterceptor {

  private clarionToken: string = null;

  // list of endpoints that return media files
  // -- we'll include the direct_url key when requesting from mobile to get the links to GCS instead of JW
  private mediaFilesEndpoints = [
    '/programs/bootstrap',
    '/program_days/',
    '/user_week_days/',
    '/exercises',
    '/bonus_exercises'
  ];

  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig
  ) {
    // subscribe to auth token changes
    this.store.select(fromAuth.getAuthToken)
      .subscribe((token) => {
        this.clarionToken = token;
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // skip adding direct key if no token availabble, if jwplayer is enabled or if request is for endpoint without media files
    if (
      !this.clarionToken ||
      this.config.jwplayerEnabled() ||
      !this.mediaFilesEndpoints.some(endpoint => request.url.includes(endpoint))
    ) {
      return next.handle(request);
    }

    const glue = request.url.includes('?') ? '&' : '?';

    return next.handle(
      request.clone({
        url: `${request.url}${glue}direct_url_key=${this.generateDirectUrlKey()}`
      })
    );
  }

  private generateDirectUrlKey() {
    const stringToHash = this.clarionToken + '-' + this.config.env.urlVideoSalt;

    return new Md5()
      .appendStr(stringToHash)
      .end();
  }
}
