import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-expanded-option-header',
  styleUrls: ['../../wizard.scss', 'expanded-option-header.component.scss'],
  template: `
    <div class="title">
      <div class="title-text">{{header}}</div>
      <div class="arrow">
        <ion-icon name="arrow-forward" *ngIf="!isExpanded"></ion-icon>
        <ion-icon name="arrow-down" *ngIf="isExpanded"></ion-icon>
      </div>
    </div>
  `
})

export class ExpandedOptionHeaderComponent {

  @Input() header: string;
  @Input() isExpanded: boolean;
}
