import { ErnContentProgram } from 'src/app/store/normalized/schemas/user.schema';

export interface ProgramPathData {
  label: string;
  value: ErnContentProgram;
  description: string;
  extendedDescription: string;
  icon: string;
  mainButtonLabel: string;
  alternativeButtonLabel: string;
}

export const ernPaths: ProgramPathData[] = [
  {
    label: 'account_setup.ern_diagnostic_onboarding.path_description.mindful_eating.label',
    value: ErnContentProgram.MindfulEating,
    description: 'account_setup.ern_diagnostic_onboarding.path_description.mindful_eating.description',
    extendedDescription: 'account_setup.ern_diagnostic_onboarding.path_description.mindful_eating.extended_description',
    icon: 'theme-week-icon',
    mainButtonLabel: 'account_setup.ern_diagnostic_onboarding.path_description.mindful_eating.lets_go_mindful_eating',
    alternativeButtonLabel: 'account_setup.ern_diagnostic_onboarding.path_description.mindful_eating.take_me_mindful_earing'
  },
  {
    label: 'account_setup.ern_diagnostic_onboarding.path_description.weight_loss.label',
    value: ErnContentProgram.WeightLoss,
    description: 'account_setup.ern_diagnostic_onboarding.path_description.weight_loss.description',
    extendedDescription: 'account_setup.ern_diagnostic_onboarding.path_description.weight_loss.extended_description',
    icon: 'weight-loss-icon',
    mainButtonLabel: 'account_setup.ern_diagnostic_onboarding.path_description.weight_loss.lets_go_weight_loss',
    alternativeButtonLabel: 'account_setup.ern_diagnostic_onboarding.path_description.weight_loss.take_me_weight_loss'
  }
];
