<cl-waves-background wizard-bg bg-position-bottom>
  <ion-content no-bounce>
    <ion-header [class.desktop]="config.usingDesktopOnWeb">
      <cl-header-nav-buttons
        class="page-header"
        color="blank"
        [backButton]="false"
        (closeClick)="onClose()"
        [title]="currentTitle">
      </cl-header-nav-buttons>
    </ion-header>

    <div class="access-loading" *ngIf="accessLoading$ | async">
      <ion-spinner></ion-spinner>
    </div>

    <ion-grid class="page-wrapper" *ngIf="!((access$ | async).success) && !(accessLoading$ | async)">
      <cl-nrt-message
        [errorMessage]="'nrt.errors.' + (access$ | async).message">
      </cl-nrt-message>
    </ion-grid>

    <ion-grid class="page-wrapper"  *ngIf="(access$ | async).success" [ngSwitch]="currentStep">
      <cl-nrt-message
        *ngSwitchCase="NRT_STEPS.INTRO"
        messageType="introduction">
      </cl-nrt-message>

      <cl-nrt-survey
        *ngSwitchCase="NRT_STEPS.MEDICAL_CONDITION_SURVEY"
        [questions]="nrtService.medicalConditionQuestions"
        [nrtStep]="NRT_STEPS.MEDICAL_CONDITION_SURVEY"
        [showProgress]="true"
        (selected)="toggleButtonsEnabling()">
      </cl-nrt-survey>

      <cl-nrt-survey
        *ngSwitchCase="NRT_STEPS.PRODUCT_SURVEY"
        [questions]="nrtService.productQuestions"
        [nrtStep]="NRT_STEPS.PRODUCT_SURVEY"
        (selected)="toggleButtonsEnabling()">
      </cl-nrt-survey>

      <cl-nrt-product-selection
        *ngSwitchCase="NRT_STEPS.PRODUCT"
        [data]="nrtService.productQuestions">
      </cl-nrt-product-selection>

      <cl-nrt-gum-flavor
        *ngSwitchCase="NRT_STEPS.FLAVOR_SELECTION"
        (selected)="toggleButtonsEnabling()">
      </cl-nrt-gum-flavor>

      <cl-nrt-shipping-address
        *ngSwitchCase="NRT_STEPS.SHIPPING"
        (validityChange)="toggleButtonsEnabling()">
      </cl-nrt-shipping-address>

      <cl-nrt-assertions *ngSwitchCase="NRT_STEPS.ASSERTIONS"
                         [questions]="nrtService.getAssertions()">
      </cl-nrt-assertions>

      <cl-nrt-provider-information
        *ngSwitchCase="NRT_STEPS.PROVIDER"
        (validityChange)="toggleButtonsEnabling()">
      </cl-nrt-provider-information>

    </ion-grid>
  </ion-content>

</cl-waves-background>

<div class="controls-holder padding-bottom-iphone-x">
  <div class="controls-wrapper">
    <cl-nrt-navigation
      *ngIf="(access$ | async).success"
      (previous)="onPrevious()"
      (next)="onNext()"
      [nextText]="nextText"
      [disableNext]="disableNext"
      [disablePrevious]="disablePrevious"
      [hideNext]="false"
      [hidePrevious]="hidePrevious"
    ></cl-nrt-navigation>
  </div>
</div>

