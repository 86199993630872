import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { NrtPage } from './nrt';
import { PlayPageModule } from '../play/play.module';
import { SharedModule } from '../../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { NrtSurveyComponent } from './components/nrt-survey.component';
import { NrtProductSelectionComponent } from './components/nrt-product-selection.component';
import { NrtShippingAddressComponent } from './components/nrt-shipping-address.component';
import { NrtAssertionsComponent } from './components/nrt-assertions.component';
import { NrtProviderComponent } from './components/nrt-provider.component';
import { NrtMessageComponent } from './components/nrt-message.component';
import { NrtNavigationComponent } from './components/nrt-navigation.component';
import { NrtGumFlavorComponent } from './components/nrt-gum-flavor';

const routes: Routes = [
  {
    path: '',
    component: NrtPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    PlayPageModule,
    SharedModule,
    TranslateModule
  ],
  declarations: [
    NrtPage,
    NrtSurveyComponent,
    NrtMessageComponent,
    NrtProductSelectionComponent,
    NrtShippingAddressComponent,
    NrtAssertionsComponent,
    NrtProviderComponent,
    NrtNavigationComponent,
    NrtGumFlavorComponent
  ]
})
export class NrtPageModule {}
