import * as userProfileActions from '../actions/user-profile.actions';

export interface UserProfileState {
  updating: boolean;
  updated: boolean;
}

export const initialRemindersState: UserProfileState = {
  updating: false,
  updated: false
};

export function userProfileReducer(state = initialRemindersState, action: userProfileActions.UserProfileActions): UserProfileState {
  switch (action.type) {

    case userProfileActions.UPDATE_USER_PROFILE: {
      return {
        ...state,
        updating: true,
        updated: false
      };
    }

    case userProfileActions.UPDATE_USER_PROFILE_FAIL: {
      return {
        ...state,
        updating: false,
        updated: false
      };
    }

    case userProfileActions.UPDATE_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        updating: false,
        updated: true
      };
    }

    case userProfileActions.SET_USER_PROFILE_UPDATED: {
      return {
        ...state,
        updated: action.payload
      };
    }
  }

  return state;
}

export const getUserProfile = (state: UserProfileState) => state;
export const getProfileUpdating = (state: UserProfileState) => state && state.updating ? true : false;
export const getProfileUpdated = (state: UserProfileState) => state && state.updated ? true : false;
