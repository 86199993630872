import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cl-shadow-wrapper',
  styleUrls: ['shadow-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <ng-content></ng-content>
  `
})

export class ShadowWrapperComponent {
}
