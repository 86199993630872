import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'cl-icon-title-container',
  styleUrls: ['icon-title-container.scss'],
  template: `
    <div class="icon-container">
      <img src="{{icon}}">
    </div>
    <div class="title-container" [class.show-company]="showCompany">
      <h1 *ngIf="subHeader" class="sub-header">{{subHeader}}</h1>
      <h2>{{intro}}</h2>
      <p class="sub-intro" *ngIf="subIntro" [innerHTML]="subIntro"></p>
    </div>`
})

export class IconTitleContainerComponent {
  @Input() icon: string;
  @Input() header: string;
  @Input() subHeader: string;
  @Input() intro: string;
  @Input() showCompany = true;
  @Input() subIntro = null;
  @HostBinding('class.desktop') @Input() desktop = false;
}
