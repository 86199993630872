import * as myCoachActions from '../actions/my-coach.actions';
import * as programActions from '../actions/program.actions';
import { Conversation, Message } from '../../normalized/schemas/my-coach.schema';

export interface MyCoachState {
  conversationsLoading: boolean;
  conversationLoaded: boolean;
  conversationSuppression: string;
  conversationCreation: Conversation;
  conversationReaded: string;
  conversationsFilter: any;
  newConversationId: string;
  messagesLoading: boolean;
  messageSuppression: string;
  messageCreation: Message;
  messageSended: boolean;
  openConversationId: string;
  unreadMessagesCount: number;
  page: number;
  notificationsLoading: boolean;
  notificationsLoaded: boolean;
  allNotificationsLoading: boolean;
  allNotificationsLoaded: boolean;
}

export const initialMyCoachState: MyCoachState = {
  conversationsLoading: false,
  conversationLoaded: false,
  conversationSuppression: null,
  conversationCreation: null,
  conversationReaded: null,
  conversationsFilter: 'all',
  newConversationId: null,
  messagesLoading: false,
  messageSuppression: null,
  messageCreation: null,
  messageSended: false,
  openConversationId: null,
  unreadMessagesCount: 0,
  page: 1,
  notificationsLoading: false,
  notificationsLoaded: false,
  allNotificationsLoaded: false,
  allNotificationsLoading: false
};

export function myCoachReducer(
  state = initialMyCoachState, action: myCoachActions.MyCoachActions | programActions.InitLiveProgram
): MyCoachState {
  switch (action.type) {
    case myCoachActions.POST_MESSAGE:
      return {
        ...state,
        messageSended: false
      };
    case myCoachActions.POST_MESSAGE_SUCCESS:
      return {
        ...state,
        messageSended: true
      };
    case myCoachActions.LOAD_ALL_MESSAGES:
    case myCoachActions.LOAD_MESSAGES:
      return {
        ...state,
        messagesLoading: true,
        openConversationId: action.payload.toString()
      };
    case myCoachActions.LOAD_ALL_MESSAGES_FAIL:
    case myCoachActions.LOAD_MESSAGES_FAIL:
      return {
        ...state,
        messagesLoading: false
      };
    case myCoachActions.LOAD_ALL_MESSAGES_SUCCESS:
    case myCoachActions.LOAD_MESSAGES_SUCCESS:
      return {
        ...state,
        messagesLoading: false
      };
    case myCoachActions.LOAD_UNREAD_MESSAGES_COUNT_SUCCESS:
      return {
        ...state,
        unreadMessagesCount: action.payload
      };
    case programActions.INIT_LIVE_PROGRAM:
    case myCoachActions.CLOSE_CONVERSATION_MODAL: {
      return {
        ...state,
        openConversationId: null
      };
    }
    case myCoachActions.NEW_CONVERSATION_ID:
      return {
        ...state,
        newConversationId: action.payload
      };
    case myCoachActions.RESET_NEW_CONVERSATION_ID:
      return {
        ...state,
        newConversationId: null
      };
    case myCoachActions.MARK_CONVERSATION_AS_READ:
      return {
        ...state,
        conversationReaded: action.payload
      };
    case myCoachActions.RESET_CONVERSATION_SUPPRESSION:
      return {
        ...state,
        conversationSuppression: null
      };
    case myCoachActions.RESET_CONVERSATION_MARK_AS_READ:
      return {
        ...state,
        conversationReaded: null
      };
    case myCoachActions.RESET_CONVERSATION_CREATION:
      return {
        ...state,
        conversationCreation: null
      };
    case myCoachActions.DELETE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversationSuppression: action.payload
      };
    case myCoachActions.CREATE_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversationCreation: action.payload
      };

    case myCoachActions.REFRESH_CONVERSATIONS:
      return {
        ...state,
        conversationsLoading: true,
        conversationLoaded: false,
        page: 1
      };
    case myCoachActions.LOAD_CONVERSATIONS:
      return {
        ...state,
        conversationsLoading: true,
        conversationLoaded: false
      };
    case myCoachActions.LOAD_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        conversationLoaded: true,
        conversationsLoading: false,
        page: state.page + 1
      };
    case myCoachActions.LOAD_CONVERSATIONS_FAIL:
      return {
        ...state,
        conversationsLoading: false
      };
    case myCoachActions.CHANGE_CONVERSATIONS_FILTER:
      return {
        ...state,
        conversationsFilter: action.payload,
        conversationsLoading: true,
        conversationLoaded: false,
        page: 1
      };
    case myCoachActions.LOAD_NOTIFICATIONS: {
      return {
        ...state,
        notificationsLoading: true,
        notificationsLoaded: false,
        allNotificationsLoaded: false,
        allNotificationsLoading: false
      };
    }
    case myCoachActions.LOAD_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notificationsLoading: false,
        notificationsLoaded: true,
        allNotificationsLoaded: false,
        allNotificationsLoading: false
      };
    }
    case myCoachActions.LOAD_NOTIFICATIONS_FAILURE:
    case myCoachActions.LOAD_ALL_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        notificationsLoading: false,
        notificationsLoaded: false,
        allNotificationsLoaded: false,
        allNotificationsLoading: false
      };
    }
    case myCoachActions.LOAD_ALL_NOTIFICATIONS: {
      return {
        ...state,
        notificationsLoading: false,
        notificationsLoaded: false,
        allNotificationsLoaded: false,
        allNotificationsLoading: true
      };
    }
    case myCoachActions.LOAD_ALL_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notificationsLoading: false,
        notificationsLoaded: false,
        allNotificationsLoaded: true,
        allNotificationsLoading: false
      };
    }
  }

  return state;
}

export const conversationLoaded = (state: MyCoachState) => state.conversationLoaded;
export const openConversationId = (state: MyCoachState) => state.openConversationId;
export const conversationsLoading = (state: MyCoachState) => state.conversationsLoading;
export const messagesLoading = (state: MyCoachState) => state.messagesLoading;
export const conversationSuppression = (state: MyCoachState) => state.conversationSuppression;
export const conversationCreation = (state: MyCoachState) => state.conversationCreation;
export const conversationReaded = (state: MyCoachState) => state.conversationReaded;
export const conversationsFilter = (state: MyCoachState) => state.conversationsFilter;
export const newConversationId = (state: MyCoachState) => state.newConversationId;
export const messageSended = (state: MyCoachState) => state.messageSended;
export const unreadMessagesCount = (state: MyCoachState) => state.unreadMessagesCount;
export const conversationsPage = (state: MyCoachState) => state.page;
export const conversationFeedId = () => 'current-conversation-feed';
export const messageFeedId = () => 'current-message-feed';
export const myCoachNotificationsAreLoading = (state: MyCoachState) => state.notificationsLoading;
export const myCoachNotificationsAreLoaded = (state: MyCoachState) => state.notificationsLoaded;
export const myCoachAllNotificationsAreLoading = (state: MyCoachState) => state.allNotificationsLoading;
export const myCoachAllNotificationsAreLoaded = (state: MyCoachState) => state.allNotificationsLoaded;
export const myCoachNotificationFeedId = () => 'current-mycoach-notification-feed';
