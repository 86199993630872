import { ChangeDetectorRef, Component, Injector } from '@angular/core';
import { WizardComponent } from '../wizard.component';
import * as navigationActions from '../../../../store/session/actions/navigation.actions';
import { StressTestUAService } from '../../../../services/wizards/stress-test-ua.service';
import { Exercise } from '../../../../store/normalized/schemas/exercise.schema';
import { ListItem } from '../../../../store/normalized/schemas/list-item.schema';

import { getBreathingExercises } from '../../../../store/normalized/selectors/exercises.selectors';
import { getCurrentModule } from '../../../../store/session/selectors/program.selectors';
import { take, withLatestFrom } from 'rxjs/operators';

import { getCheckinExercises } from '../../../../store/normalized/selectors/checkin.selectors';
import { exerciseTitleToImg } from '../../../../constants/checkins.constants';
import { getAcceleratedTo } from '../../../../store/normalized/selectors/user-bootstrap.selectors';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../../store/session/session.reducers';
import { BrowserService } from '../../../../services/browser.service';
import { ClarityConfig } from '../../../../config/clarity.config';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { AfterCompleteLessonOptions } from 'src/app/store/session/actions/program.actions';

@Component({
  selector: 'cl-stress-test-ua-wizard',
  styleUrls: ['../../wizard.scss', 'stress-test-ua-wizard.component.scss'],
  templateUrl: 'stress-test-ua-wizard.component.html',
  providers: []
})
export class StressTestUAWizardComponent extends WizardComponent {

  public feelings = [];
  public feelingChoice = [];
  public hoursAgo = 3;
  public feelingNow = 35;
  public sensationIntensity = 50;
  public header: string;
  public breatheExercise$ = this.store.select(getBreathingExercises);
  public exerciseToPlay = null;
  public bodyPartChoice;
  public sensationChoice;
  public bodySideChoice;
  public showSpinner = true;
  public checkinExercise = false;
  public bodyParts: ListItem[];
  public bodySides: ListItem[];
  public sensations: ListItem[];

  public showExerciseStep = false;

  module2Completed = false;

  protected service: StressTestUAService;

  protected wizardConfig = {
    title: 'wizards.stress_test',
    totalSteps: 5,
    initialStep: 1
  };

  constructor(
    protected store: Store<SessionState>,
    protected browser: BrowserService,
    protected injector: Injector,
    public config: ClarityConfig,
    protected analyticsService: AnalyticsService,
    protected translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {
    super(store, browser, injector, config, analyticsService, translate);
  }

  initStep1() {
    if (!this.bodyPartChoice) {
      this.onDisableNext();
    } else {
      this.onEnableNext();
    }
  }

  initStep2() {
    if (!this.sensationChoice) {
      this.onDisableNext();
    } else {
      this.onEnableNext();
    }
  }

  initStep3() {
    this.onEnableNext();
    this.loadSensationIntensity();
  }

  initStep4() {
    if (!this.bodySideChoice) {
      this.showSpinner = true;
      this.onDisableNext();
      this.changeDetector.detectChanges();

      setTimeout(() => {
        this.showSpinner = false;

        try {
          this.changeDetector.detectChanges();
        }
        catch (error) {
          console.log('Cannot detect changes!');
        }
      }, 10 * 1000);
    }
    else {
      this.onEnableNext();
    }
  }

  initStep5() {
    this.onEnableNext();
  }

  initStep6() {
    this.onEnableNext();
    this.checkinExercise = false;
    this.changeDetector.detectChanges();
  }

  initStep7() {
    if (!this.exerciseToPlay) {
      // ToDo:: remove setTimeout
      setTimeout(() => this.onDisableBack(), 200);
    }
  }

  onBodyPartSelected(event) {
    this.bodyPartChoice = event;
    this.bodyParts.forEach(bodyPart => {
      if (bodyPart.id === event) {
        this.service.setBodyPart(bodyPart);
        this.onEnableNext();
      }
    });
  }

  onBodySideSelected(event) {
    this.bodySideChoice = event;
    this.bodySides.forEach(bodySide => {
      if (bodySide.id === event) {
        this.service.setBodySide(bodySide);
        this.onEnableNext();
      }
    });
  }

  onSensationSelected(event) {
    this.sensationChoice = event;
    this.sensations.forEach(sensation => {
      if (sensation.id === event) {
        this.service.setSensation(sensation);
        this.onEnableNext();
      }
    });
  }

  onIntensityChange(event) {
    this.service.setSensationIntensity(event);
  }

  onFeelingSelected(feelings) {
    this.service.setFeelingChoice(feelings);
    feelings.length ? this.onEnableNext() : this.onDisableNext();
  }

  onDone(afterCompleteOptions: AfterCompleteLessonOptions) {
    this.service.save(afterCompleteOptions);
    this.wizardComplete.emit();
  }

  playExercise(exercise: Exercise) {
    if (exercise.tags.find((tag) => tag.name === 'rain')) {
      this.store.select(getCurrentModule)
        .pipe(withLatestFrom(this.store.select(getAcceleratedTo)))
        .pipe(take(1))
        .subscribe(([currentModule, acceleratedTo]) => {
          if (currentModule.number >= 12 || acceleratedTo >= 12) {
            this.exerciseToPlay = exercise;
            this.service.setExercise(exercise.id);
            this.nextStep.emit();
          }
          else {
            this.store.dispatch(
              new navigationActions.ShowInterstitial({
                page: 'InterstitialPage',
                params: {
                  type: 'locked',
                  title: 'interstitials.rain_locked_title',
                  notes: 'interstitials.rain_locked_module_12_ua'
                }
              })
            );
          }
        });
    }
    else if (exercise.tags.find((tag) => tag.name === 'breathe_into_anxiety')) {
      this.store.dispatch(new navigationActions.ShowBreatheExercise());
    }
    else {
      this.exerciseToPlay = exercise;
      this.service.setExercise(exercise.id);
      this.nextStep.emit();
    }
  }

  loadSensationIntensity() {
    this.sensationIntensity = this.service.getSensationIntensity();
  }

  imFeelingLucky() {
    this.store.select(getCheckinExercises)
      .pipe(take(1))
      .subscribe((checkinExercises) => {
        this.exerciseToPlay = checkinExercises[Math.floor(Math.random() * checkinExercises.length)];
        this.checkinExercise = true;
        this.exerciseToPlay.graphic = exerciseTitleToImg(this.translate.currentLang, this.exerciseToPlay.title);
        this.showExerciseStep = true;
      });
  }

  protected _init() {
    this.service = this.injector.get<StressTestUAService>(StressTestUAService);

    this.store.select(getCurrentModule)
      .pipe(withLatestFrom(this.store.select(getAcceleratedTo)))
      .pipe(take(1))
      .subscribe(([currentModule, acceleratedTo]) => {
        if ((currentModule.number === 2 && currentModule.isCompleted)
          || currentModule.number > 2 || acceleratedTo >= 2) {
          this.module2Completed = true;
        }
      });

    return this.service.initModel()
      .then(() => {
        this.sensations = this.service.getSensations();
        this.bodyParts = this.service.getBodyParts();
        this.bodySides = this.service.getBodySides();
      });
  }

}
