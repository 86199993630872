import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CravingTool } from '../../../store/normalized/schemas/craving-tool.schema';

@Component({
  selector: 'cl-after-eating-satisfaction-step',
  styleUrls: ['../craving-tool.scss', 'after-eating-exercise-satisfaction.component.scss'],
  template: `
    <ion-content>
      <div class="simulation lateral-padding">
        <h2
          class="step-intro intro-question">{{'wizards.check_in_with_your_body_how_satisfied_content_do_you_feel' | translate}}</h2>
          <cl-intensity-selector
            [sliderValue]="sliderValue"
            highestIntensityLabel="wizards.very_satisfied"
            mediumIntensityLabel=""
            lowestIntensityLabel="wizards.very_dissatisfied"
            (intensity)="onAfterSatisfactionChange($event)"
            lowestIntensityScale="-10"
            lowIntensityScale="-5"
            mediumIntensityScale="0"
            highIntensityScale="+5"
            highestIntensityScale="+10"
          >
          </cl-intensity-selector>
      </div>
    </ion-content>

    <ion-footer>
      <div class="controls-holder padding-bottom-iphone-x">
        <ion-row class="buttons-holder lateral-padding">
          <ion-col size="6" offset="3">
            <cl-next-button
              [ngClass]="{'dark-bg': darkBackground}"
              label="common.next"
              [canClick]="true"
              (action)="triggerNext()">
            </cl-next-button>
          </ion-col>
        </ion-row>
      </div>
    </ion-footer>
  `
})
export class AfterEatingExerciseSatisfactionComponent {
  @Input() currentCravingTool: CravingTool;
  @Input() darkBackground = false;
  @Output() next = new EventEmitter();

  onAfterSatisfactionChange($event) {
    this.currentCravingTool.satisfaction_after_eating = (($event - 50) * 2) / 100;
  }

  get sliderValue() {
    const value = this.currentCravingTool.satisfaction_after_eating || 0;

    return (value / 2 + 0.5) * 100;
  }

  triggerNext() {
    this.next.emit();
  }
}
