// integrations selectors

import { createSelector } from '@ngrx/store';

import * as integrationsReducer from '../reducers/integrations.reducer';
import { getIntegrationsState } from '../../../../app/store';

export const integrations = createSelector(
  getIntegrationsState,
  integrationsReducer.integrations
);

export const isHealthKitConnected = createSelector(
  getIntegrationsState,
  integrationsReducer.healthKitConnected
);

export const isGoogleFitConnected = createSelector(
  getIntegrationsState,
  integrationsReducer.googleFitConnected
);

export const isFitbitConnected = createSelector(
  getIntegrationsState,
  integrationsReducer.fitbitConnected
);

export const isIntegrationConnected = createSelector(
  getIntegrationsState,
  integrationsReducer.integrationConnected
);

export const getLastSynchronizationDate = createSelector(
  getIntegrationsState,
  integrationsReducer.lastSynchronizationDate
);


export const isGoogleFitAuthorizationsNotDetermined = createSelector(
  getIntegrationsState,
  integrationsReducer.googleFitAuthorizationsNotDetermined
);

export const getShowInformationDialog = createSelector(
  getIntegrationsState,
  integrationsReducer.showInformationDialog
);
