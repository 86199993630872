import { Component, Input, HostBinding } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cl-range-select',
  styleUrls: ['range-select.component.scss'],
  template: `
    <ion-item mode="ios" no-lines [class.input-has-value]="!!(controlForm.value)">
      <ion-label>{{labelName | translate}}</ion-label>
      <ion-select [formControl]="controlForm" [interfaceOptions]="selectOptions" [selectedText]="getSelectedText()" [value]="controlForm.value" id="{{id}}">
        <ion-select-option *ngFor="let option of _options" [value]="option.value">
          {{option.label | translate}}
        </ion-select-option>
      </ion-select>
    </ion-item>
  `
})

export class RangeSelectComponent {
  @Input() controlForm: FormControl;

  _options: any[] = null;
  @Input()
  set options(values) {
    values.forEach(element => {
      element.label = element.label && typeof element.label === 'number' ? element.label.toString() : element.label;
    });
    this._options = values;
  }

  @Input() disabled = false;
  @Input() labelName = 'select';
  @Input() id: string;

  @HostBinding('class.disabled') get isDisabled() {
    return this.disabled;
  }

  selectOptions = {
    cssClass: 'cl-range-select'
  };

  constructor(private translate: TranslateService) {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getSelectedText() {
    const selectedValue = this.controlForm && this.controlForm.value;
    const selectedOption = this._options.find(({value}) => value === selectedValue);

    return selectedOption && selectedOption.label ? this.translate.instant(selectedOption.label) : '';
  }

}
