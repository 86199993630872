import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-switch',
  styleUrls: ['switch-field.component.scss'],
  template: `
    <div>
      <ion-segment mode="ios" [formControl]="controlForm" color="{{_color}}">
        <ion-segment-button *ngFor="let option of options" value="{{option.value}}">
          <ion-label>
            {{ option.name | translate }}
          </ion-label>
        </ion-segment-button>
      </ion-segment>
    </div>
  `
})

export class SwitchFieldComponent {

  @Input() controlForm;

  _color;
  @Input()
  set switchColor(value: string) {
    this._color = value;
  }
  @Input() options: [any] = null;
  @Input() disabled = false;

  constructor() {}

  // Allows Angular to disable the input.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
