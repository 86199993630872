import { Action } from '@ngrx/store';
import { AllowedTimestamps } from './timestamps.reducer';

// timestamps actions

export const SET_TIMESTAMP = '[timestamps] Set';
export const UNSET_TIMESTAMP = '[timestamps] Unset';

export class SetTimestamp implements Action {
  readonly type = SET_TIMESTAMP;

  constructor(public name: AllowedTimestamps, public date: Date) {

  }
}

export class UnsetTimestamp implements Action {
  readonly type = UNSET_TIMESTAMP;

  constructor(public name: AllowedTimestamps) {

  }
}

export type TimestampsActions =
  | SetTimestamp
  | UnsetTimestamp;
