import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { ThemeWeek } from '../store/normalized/schemas/theme-week.schema';
import { UserWeek } from '../store/normalized/schemas/user-weeks.schema';
import { map, switchMap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class UserWeeksProvider {

  private userWeeksEndpoint = '/user_weeks';

  constructor(
    private http: HttpProvider
  ) {

  }

  loadUserWeeks() {
    return this.http.get<any>(this.userWeeksEndpoint + '?kind=any');
  }

  startUserWeek(week) {
    return this.http.post<UserWeek>(this.userWeeksEndpoint, week);
  }

  startThemeWeek(themeWeekId) {
    return this.http.post<ThemeWeek>(this.userWeeksEndpoint, {from_theme_week: themeWeekId});
  }

  // this should not be used anymore, we no longer delete theme weeks
  deleteLastThemeWeek() {
    throw Error('DEPRECATED: We no longer delete theme weeks, use deleteLastCustomWeek() instead');

    // return this.http.get<any>(this.userWeeksEndpoint + '?kind=any')
    //   .pipe(
    //     map((userWeeks) => userWeeks.sort((a, b) => b.week_number - a.week_number)),
    //     map((userWeeks) => userWeeks[0]),
    //     switchMap((lastWeek) => {
    //       return this.http.delete(this.userWeeksEndpoint + '/' + lastWeek.id);
    //     })
    //   );
  }

  deleteLastCustomWeek() {
    return this.http.get<any>(this.userWeeksEndpoint + '?kind=custom')
      .pipe(
        map((userWeeks) => userWeeks.sort((a, b) => b.week_number - a.week_number)),
        map((userWeeks) => userWeeks[0]),
        switchMap((lastWeek) => this.http.delete(this.userWeeksEndpoint + '/' + lastWeek.id))
      );

  }

}
