import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { PrivacyScreenService } from 'src/app/services/privacy-screen.service';
import { scaleUpDown } from '@mindsciences/utils';

import { ClarityConfig } from '../../../config/clarity.config';
import { ConnectivityService } from '../../../services/connectivity.service';
import { User } from '../../../store/normalized/schemas/user.schema';
import { getCurrentUser, getCurrentUserSSOProvider } from '../../../store/normalized/selectors/user.selectors';
import * as accountActions from '../../../store/session/actions/account.actions';
import * as syncActions from '../../../store/session/actions/sync.actions';
import { isAuthenticating } from '../../../store/sensitive/selectors/auth.selectors';
import { SessionState } from '../../../store/session/session.reducers';
import { State } from '../../../store/state.reducer';
import { SettingsComponent } from './settings.component';
import { getLastSyncEverythingAt } from 'src/app/store/session/selectors/sync.selectors';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';

@Component({
  selector: 'cl-account-profile-web',
  styleUrls: ['profile-web.component.scss'],
  animations: [scaleUpDown],
  template: `
    <div
      class="sso-alert"
      *ngIf="SSOProvider === 'sharecare'"
      [innerHtml]="'auth.update_on_provider' | translate : {provider: SSOProvider}">
    </div>

    <form [formGroup]="profileForm" autocomplete="off" *ngIf="currentUser$ | async" (submit)="onSubmit()"
      [@state]="visibility" (@state.done)="animationDone($event)">
      <div class="flex-row">
        <cl-input
          [controlForm]="profileForm.get('first_name')"
          type="text"
          name="first_name"
          [errorMessages]="validations.first_name.errors"
          placeHolder="{{'auth.first_name' | translate}}">
        </cl-input>

        <cl-input
          [controlForm]="profileForm.get('last_name')"
          type="text"
          name="last_name"
          [errorMessages]="validations.last_name.errors"
          placeHolder="{{'auth.last_name' | translate}}">
        </cl-input>

        <cl-input
          [controlForm]="profileForm.get('email')"
          type="text"
          name="email"
          [errorMessages]="validations.email.errors"
          placeHolder="{{'auth.email' | translate}}">
        </cl-input>
      </div>

      <div class="gender-wrapper">
        <p class="section-header">{{'auth.gender' | translate}}</p>
        <cl-range-select
                ngDefaultControl
                [controlForm]="profileForm.get('gender')"
                [options]="GENDER_OPTIONS"
                [disabled]="SSOProvider !== null"
                labelName="{{ 'auth.gender' | translate }}"
                name="gender">
        </cl-range-select>
      </div>

      <div class="weight-wrapper" *ngIf="profileForm.get('weight') && !this.config.programDPPorWL()" >
        <p class="section-header">{{'auth.weight' | translate}}</p>
        <cl-input
          label="{{ 'auth.start_weight' | translate }}"
          [controlForm]="profileForm.get('weight')"
          [errorMessages]="validations.weight.errors"
          type="number"
          class="weight"
          stacked
          name="weight">
        </cl-input>


        <cl-switch
          [options]="WEIGHT_UNIT_OPTIONS"
          [controlForm]="profileForm.get('weight_unit')"
          [switchColor]="'primary'"
          name="weight_unit">
        </cl-switch>
      </div>

      <div class="sync-wrapper">
        <cl-action-button
          type="button"
          color="white"
          [icon]="'sync-outline'"
          label="{{ 'auth.sync_account' | translate }}"
          [canClick]="true"
          (action)="syncAccount()">
        </cl-action-button>
        <p class="sync-date">
          {{ 'auth.last_sync_account_date' | translate: { date: (lastSyncEverythingAt$ | async) } }}
        </p>
      </div>

      <ion-row class="action-button" *ngIf="shouldDisplaySaveProfileButton()">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
            label="{{'auth.save_profile' | translate}}"
            [canClick]="canSave && hasChanges && profileForm.valid && ! (authenticating$ | async)">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </form>
  `
})

export class ProfileWebComponent implements SettingsComponent, OnDestroy, OnInit {

  @Output() endAnimation = new EventEmitter();
  visibility = 'visible';

  public authenticating$: Observable<boolean> = this.store.select(isAuthenticating);
  public currentUser$: Observable<User>;

  lastSyncEverythingAt$ = this.store.select(getLastSyncEverythingAt);

  SSOProvider: string = null;

  userData: any;
  profileForm: FormGroup;

  canSave = true;

  public readonly WEIGHT_UNIT_OPTIONS = [
    {
      name: 'common.lb',
      value: 'lb'
    },
    {
      name: 'common.kg',
      value: 'kg'
    }
  ];
  
  public readonly options = this.config.getLanguageOptions();

  public validations = {
    first_name: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.first_name_required'
      }
    },
    last_name: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.last_name_required'
      }
    },
    age: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.age_required'
      }
    },
    // TODO: Add min weight requirement
    weight: {
      validators: Validators.compose([this.weightConditionalValidator()]),
      errors: {
        required: 'errors.user.weight_required'
      }
    },
    weight_unit: {
      validators: Validators.compose([this.weightConditionalValidator()]),
      errors: {
        required: 'errors.user.weight_unit_required'
      }
    },
    gender: {
      validators: Validators.compose([
        Validators.required
      ]),
      errors: {
        required: 'errors.user.gender_required'
      }
    },
    email: {
      validators: Validators.compose([
        Validators.required,
        Validators.email
      ]),
      errors: {
        required: 'errors.user.email_required',
        email: 'errors.user.email_invalid'
      }
    }
  };

  public readonly GENDER_OPTIONS = [
    {
      label: 'auth.male',
      value: 'male'
    },
    {
      label: 'auth.female',
      value: 'female'
    },
    {
      label: 'auth.other',
      value: 'other'
    }
  ];

  private readonly _fieldsHandledOnSSO = ['first_name', 'last_name', 'email', 'gender'];

  minDate: string;
  maxDate: string;

  formChangeSubscription: Subscription;

  ageOptions = [];

  initialProfileSettings;

  constructor(
    private sessionStore: Store<SessionState>,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private connectivity: ConnectivityService,
    private store: Store<State>,
    public config: ClarityConfig,
    private privacyScreenService: PrivacyScreenService,
    private analyticsService: AnalyticsService
  ) {
    for (let allowedAge = 13; allowedAge < 100; allowedAge++) {
      this.ageOptions.push({
        value: allowedAge,
        label: allowedAge
      });
    }

    this.minDate = new Date().toISOString();
    this.maxDate = new Date('2100').toISOString();

    this.currentUser$ = this.sessionStore.select(getCurrentUser);
    combineLatest([
      this.currentUser$,
      this.sessionStore.select(getCurrentUserSSOProvider)
    ])
      .pipe(take(1))
      .subscribe(([userData, ssoProvider]) => {
        this.userData = userData;
        this.SSOProvider = ssoProvider;

        const formGroup = this.generateFormGroup(this.config.program.programCode);
        this.profileForm = this.formBuilder.group(formGroup);
        this.initialProfileSettings = {...this.profileForm.value};

        if (this.SSOProvider && this.SSOProvider === 'sharecare') {
          this._disablePersonalInfoFields();
        }

        // Disable email field to make it non-editable for all users until email change feature is revisited.
        // Currently backend does not support `email` field updates via API.
        this.profileForm.get('email').disable();

        this.unsubSub();
      });
  }

  get hasChanges() {
    const hasChanges = Object.keys(this.initialProfileSettings)
      .map((key) => {
        if (this.initialProfileSettings[key] !== this.profileForm.value[key]) {
          return true;
        }

        return false;
      })
      .some(el => el);

    return hasChanges;
  }

  weightConditionalValidator() {
    return (formControl => {
      if(this.config.programDPPorWL()) {
        return null;
      } else {
        return Validators.required(formControl);
      }
    });
  }

  generateFormGroup(program) {
    const formGroupData = {
      ern: {
        first_name: {},
        last_name: {},
        email: {},
        weight: {},
        weight_unit: {
          defaultValue: 'lb'
        },
        age: {},
        gender: {}
      },
      ua: {
        first_name: {},
        last_name: {},
        email: {},
        age: {},
        gender: {}
      },
      ctq: {
        first_name: {},
        last_name: {},
        email: {},
        age: {},
        gender: {}
      }
    };

    const formGroupProgram = formGroupData[program];
    const formGroup = {};

    Object.keys(formGroupProgram)
      .forEach((key) => {
        const data = formGroupProgram[key];
        const formValue = this.userData[key] || data.defaultValue;
        const formValidator = data.validators || this.validations[key].validators;
        formGroup[key] = new FormControl(formValue, formValidator);
      });

    return formGroup;
  }

  unsubSub() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
    this.formChangeSubscription = this.profileForm.valueChanges.subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  ngOnInit() {
    this.privacyScreenService.enable();
  }

  ngOnDestroy() {
    this.privacyScreenService.disable();

    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
  }

  onSubmit() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    // we assign userData to toSubmit and override with the form data
    const toSubmit = {
      ...this.userData,
      ...this.profileForm.value
    };

    this.sessionStore.dispatch(
      new accountActions.UpdateUserAccount(toSubmit)
    );
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }

  syncAccount() {
    this.analyticsService.trackEvent(AnalyticsEvents.UserTriggeredSync);

    this.sessionStore.dispatch(new syncActions.SyncEverything());
  }

  // on CTQ when using
  shouldDisplaySaveProfileButton() {
    if (this.config.isCTQ() && this.SSOProvider) {
      return false;
    }

    if (this.config.isUA() && this.SSOProvider) {
      return false;
    }

    return true;
  }

  private _disablePersonalInfoFields() {
    for (const field of this._fieldsHandledOnSSO) {
      this.profileForm.get(field)
        .disable();
    }
  }
}
