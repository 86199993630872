// reminders actions

import { Action } from '@ngrx/store';
import { UserReminder } from '../../normalized/schemas/reminder.schema';

export const UPDATE_USER_REMINDER = '[user-reminders] Update Reminder';
export const UPDATE_USER_REMINDER_SUCCESS = '[user-reminders] Update Reminder Success';
export const UPDATE_USER_REMINDER_FAIL = '[user-reminders] Update Reminder Fail';

export const CREATE_USER_REMINDER = '[user-reminders] Create Reminder';
export const CREATE_USER_REMINDER_SUCCESS = '[user-reminders] Create Reminder Success';
export const CREATE_USER_REMINDER_FAIL = '[user-reminders] Create Reminder Fail';

export class UpdateUserReminder implements Action {
  readonly type = UPDATE_USER_REMINDER;

  constructor(public payload: UserReminder) {
  }
}

export class UpdateUserReminderSuccess implements Action {
  readonly type = UPDATE_USER_REMINDER_SUCCESS;
}

export class UpdateUserReminderFail implements Action {
  readonly type = UPDATE_USER_REMINDER_FAIL;
}

export class CreateUserReminder implements Action {
  readonly type = CREATE_USER_REMINDER;

  constructor(public payload: UserReminder) {
  }
}

export class CreateUserReminderSuccess implements Action {
  readonly type = CREATE_USER_REMINDER_SUCCESS;
}

export class CreateUserReminderFail implements Action {
  readonly type = CREATE_USER_REMINDER_FAIL;
}

export type UserRemindersActions =
  | UpdateUserReminder
  | UpdateUserReminderSuccess
  | UpdateUserReminderFail
  | CreateUserReminder
  | CreateUserReminderSuccess
  | CreateUserReminderFail;
