import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeightActivity } from '../store/normalized/schemas/weight-activity.schema';
import { HttpProvider } from './http/http.provider';
import { OfflineHttpProvider } from './http/offline-http.provider';

@Injectable({providedIn: 'root'})
export class WeightActivitiesProvider {
  private weightActivitiesEndpoint = '/weight_activities';
  private bulkWeightActivitiesEndpoint = '/weight_activities/bulk';

  constructor(
    private readonly http: HttpProvider,
    private offlineHttpProvider: OfflineHttpProvider
  ) {}

  loadWeightActivities(): Observable<WeightActivity[]> {
    return this.http.get<WeightActivity[]>(this.weightActivitiesEndpoint);
  }

  createWeightActivity(weightActivity: WeightActivity): Observable<WeightActivity> {
    return this.http.post(this.weightActivitiesEndpoint, weightActivity);
  }

  deleteWeightActivity(id: number): Observable<void> {
    return this.http.delete(`${this.weightActivitiesEndpoint}/${id}`);
  }

  createBulkWeightActivity(weightActivities: WeightActivity[]): Observable<WeightActivity[]> {
    return this.offlineHttpProvider.post(this.bulkWeightActivitiesEndpoint, {
      weight_activities: weightActivities
    });
  }

  removeWeightActivity(id) {
    return this.http.delete(this.weightActivitiesEndpoint + '/' + id);
  }
}
