<ion-content class="ion-padding-top">

  <ion-header>
    <cl-header-nav-buttons
      class="page-header"
      color="primary"
      title="DEBUG"
      (closeClick)="onClose()"
      [backButton]="false">
    </cl-header-nav-buttons>
  </ion-header>

  <div class="page-wrapper">
    <ion-list *ngIf="config.program.programCode === 'ern'">
      <ion-list-header>
        <ion-label>
          User Program Info
        </ion-label>
      </ion-list-header>

      <ion-item>
        <pre>
          <code>{{ userProgramInfo$ | async | json }}</code>
        </pre>
      </ion-item>
    </ion-list>

    <ion-list>
      <ion-list-header>
        <ion-label>
          Offline Queue
        </ion-label>
      </ion-list-header>

      <ion-item>
        <ion-label>Queue is Replaying</ion-label>
        <ion-toggle color="primary" [(ngModel)]="offlineQueueIsReplaying"></ion-toggle>
      </ion-item>

      <ion-item>
        <ion-label>Queued Requests</ion-label>
        <ion-note item-end>{{(offlineQueueCount$ | async)}}</ion-note>
      </ion-item>

      <ion-item *ngFor="let request of (offlineQueue$ | async)" (click)="showOfflineRequest(request)">
        <ion-label>
          {{request.request.type}} {{request.request.endpoint}}
        </ion-label>
        <ion-note item-end>{{request.retries}} retries</ion-note>
      </ion-item>

      <ion-item (click)="forceOfflineQueueProcess()">
        <ion-label>Force Queue Process</ion-label>
      </ion-item>
    </ion-list>

    <ion-list>
      <ion-list-header>
        <ion-label>
          Download Queue
        </ion-label>
      </ion-list-header>

      <ion-item>
        <ion-label>Download in Progress</ion-label>
        <ion-toggle color="primary" [(ngModel)]="downloadInProgress"></ion-toggle>
      </ion-item>

      <ion-item>
        <ion-label>Queued download files</ion-label>
        <ion-note item-end>{{(downloadQueue$ | async).length}}</ion-note>
      </ion-item>

      <ion-item>
        <ion-label>Downloaded modules</ion-label>
        <ion-note item-end>{{(downloadedModules$ | async).length}}</ion-note>
      </ion-item>
    </ion-list>

    <ion-list>
      <ion-list-header>
        <ion-label>
          Offline Media
        </ion-label>
      </ion-list-header>

      <ion-item>
        <ion-label>Downloaded media files</ion-label>
        <ion-note item-end>0</ion-note>
      </ion-item>
    </ion-list>
  </div>

</ion-content>
