<cl-waves-background intro-bg [ngClass]="{'desktop signup': isDesktop}">

  <ion-header>
    <cl-header-nav-buttons
      [closeButton]="false"
      (back)="goToLogin()"
      color="{{ !isDesktop ? 'primary' : 'white' }}"
      class="page-header"
    >
    </cl-header-nav-buttons>
  </ion-header>

  <ion-content>
    <cl-registration-wrapper class="lateral-padding" [class.container]="isDesktop">

      <ion-row class="logo">
        <ion-col class="padding-top-iphone-x">
          <cl-icon-title-container
            [icon]="config.logo"
            header="{{config.program.name}}"
            intro="{{ 'auth.account_setup_intro' | translate }}">
          </cl-icon-title-container>
        </ion-col>
      </ion-row>

      <ion-row class="fields-box">
        <ion-col>
          <cl-shadow-wrapper>
            <form [formGroup]="accountSetupForm" (ngSubmit)="onSetupAccount()" autocomplete="off"
                  *ngIf="userData">

              <div formGroupName="userData" class="account-setup-form-holder">
                <cl-input
                  label="{{'auth.first_name' | translate}}"
                  name="first_name"
                  type="text"
                  [controlForm]="accountSetupForm.get('userData').get('first_name')"
                  [errorMessages]="validations.first_name.errors">
                </cl-input>
                <cl-input
                  label="{{'auth.last_name' | translate}}"
                  name="last_name"
                  type="text"
                  [controlForm]="accountSetupForm.get('userData').get('last_name')"
                  [errorMessages]="validations.last_name.errors">
                </cl-input>

                <ion-row class="age-gender-fields">
                  <ion-col class="age-select" size="4">
                    <cl-range-select
                      [options]="ageOptions"
                      [controlForm]="accountSetupForm.get('userData').get('age')"
                      labelName="{{ 'auth.age' | translate }}"
                      name="age"
                      id="age">
                    </cl-range-select>
                  </ion-col>

                  <ion-col class="gender-select" size="8">
                    <cl-range-select
                      ngDefaultControl
                      [controlForm]="accountSetupForm.get('userData').get('gender')"
                      [options]="GENDER_OPTIONS"
                      labelName="{{ 'auth.gender' | translate }}"
                      name="gender"
                      id="gender">
                    </cl-range-select>
                  </ion-col>
                </ion-row>

                <ion-row class="age-gender-fields" *ngIf="accountSetupForm.get('userData.smoking_type')">
                  <ion-col>
                    <cl-range-select
                      ngDefaultControl
                      [controlForm]="smokingType"
                      [options]="SMOKING_TYPE"
                      (ionChange)="onChangeSmokingType($event)"
                      labelName="{{ 'auth.program_type' | translate }}"
                      name="smoking"
                      id="smoking">
                    </cl-range-select>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="accountSetupForm.get('userData.weight')">
                  <ion-col>
                    <cl-input
                      label="{{ 'auth.current_weight' | translate }}"
                      [controlForm]="accountSetupForm.get('userData').get('weight')"
                      [errorMessages]="validations.weight.errors"
                      type="number"
                      name="weight"
                      (click)="selectInputOnFocus($event)">
                    </cl-input>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="accountSetupForm.get('userData.weight_unit')" class="weight-unit-fields">
                  <ion-col class="weight-unit-select" size="12">
                    <cl-switch
                      [options]="WEIGHT_UNIT_OPTIONS"
                      [controlForm]="accountSetupForm.get('userData').get('weight_unit')"
                      name="weight_unit">
                    </cl-switch>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="accountSetupForm.get('userData.cigs_per_day') && isSelectedProgramType()">
                  <ion-col>
                    <cl-input
                      label="{{ (isVaping() ? 'auth.puffs_per_day' : 'auth.cigs_per_day') | translate }}"
                      [controlForm]="accountSetupForm.get('userData').get('cigs_per_day')"
                      [errorMessages]="isVaping() ? validations.puffs_per_day.errors : validations.cigs_per_day.errors"
                      type="number"
                      format="integer"
                      name="cigs_per_day"
                      (click)="selectInputOnFocus($event)">
                    </cl-input>
                  </ion-col>
                </ion-row>

                <ion-row
                  *ngIf="false && accountSetupForm.get('userData.cig_pack_currency') && !isVaping()"
                  class="weight-unit-fields">
                  <cl-range-select
                    [options]="currencyOptions"
                    labelName="{{ 'auth.cig_pack_currency' | translate }}"
                    [controlForm]="accountSetupForm.get('userData').get('cig_pack_currency')"
                    name="cig_pack_currency">
                  </cl-range-select>
                </ion-row>

                <ion-row *ngIf="cigPackCostControl && isSelectedProgramType() && !isVaping()">
                  <ion-col>
                    <cl-input
                      label="{{ 'auth.cig_pack_cost' | translate }}"
                      [controlForm]="cigPackCostControl"
                      [errorMessages]="validations.cig_pack_cost.errors"
                      type="number"
                      format="float"
                      name="cig_pack_cost"
                      (click)="selectInputOnFocus($event)">
                    </cl-input>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="accountSetupForm.get('userData.end_date')">
                  <ion-col class="date-time">
                    <ion-item id="account-setup-end-date">
                      <cl-input
                        label="{{ 'auth.end_date' | translate }}"
                        [controlForm]="quittingDate"
                        [readonly]="true">
                      </cl-input>
                    </ion-item>

                    <ion-modal class="date-time-picker" trigger="account-setup-end-date">
                      <ng-template>
                        <ion-content>
                          <ion-datetime
                            (ionChange)="setQuittingDate($event)"
                            [showDefaultButtons]="true"
                            formControlName="end_date"
                            presentation="date"
                            min={{minDate}}
                            max={{maxDate}}>
                          </ion-datetime>
                        </ion-content>
                      </ng-template>
                    </ion-modal>
                  </ion-col>
                </ion-row>

                <div class="language-label">
                  {{'auth.language' | translate}}
                </div>

                <ion-col class="profile-lang-select">
                  <ion-label position="stacked" class="transparent-label">{{'auth.language' | translate}}</ion-label>
                  <ion-item no-lines class="language-item" mode="ios">
                    <ion-select
                      formControlName="language_code"
                      name="language_code"
                      mode="ios"
                      [value]="translate.currentLang">
                      <ion-select-option
                        *ngFor="let option of LANGUAGE_OPTIONS"
                        [value]="option.value">
                        {{ option.label | translate }}
                      </ion-select-option>
                    </ion-select>
                  </ion-item>
                </ion-col>

                <ion-row>
                  <ion-col>
                    <p class="free-modules-info">{{'auth.first_X_modules_are_free' | translate: { trialModules: config.program.trialModules } }}</p>
                  </ion-col>
                </ion-row>

                <ion-row class="action-button">
                  <ion-col class="ion-text-center" auto>
                    <div class="terms" *ngIf="isSharecareSso">
                      <p class="terms-link"
                        [innerHTML]="'auth.agree_to_terms' | translate : {programName: config.program.name}"
                        (click)="openTerms()">
                      </p>
                    </div>
                    <cl-action-button
                      label="{{ 'auth.continue' | translate }}"
                      [canClick]="accountSetupForm.valid && ! (updating$ | async)">
                    </cl-action-button>
                  </ion-col>
                </ion-row>
              </div>
            </form>

            <p class="ion-text-center privacy-guarantee">{{'auth.privacy_guarantee' | translate}}</p>
          </cl-shadow-wrapper>
        </ion-col>
      </ion-row>

    </cl-registration-wrapper>
  </ion-content>
</cl-waves-background>
