import { Injectable } from '@angular/core';


import { ClarityConfig } from 'src/app/config/clarity.config';
import { LoggerService } from 'src/app/services/logger.service';
import { UpdateUserDataType, UserAnalyticsInterface } from '../analytics.interface';

@Injectable({providedIn: 'root'})
export class AdobeAnalyticsService implements UserAnalyticsInterface {

  public SERVICE_NAME = 'adobe-analytics';

  constructor(
    public config: ClarityConfig,
    public logger: LoggerService
  ) {
  }

  public initialize(): Promise<any> {
    return Promise.resolve();
  }

  public async resetService(): Promise<any> {
    // @todo

    return Promise.resolve();
  }

  public registerUser(data: UpdateUserDataType): Promise<any> {
    // @todo

    return Promise.resolve();
  }

  public async updateUser(data: UpdateUserDataType): Promise<any> {
    // @todo

    return Promise.resolve();
  }

  async trackEvent(eventName: string, data = {}) {
    this.logger.debug('Adobe tracking event', eventName);

    try {
      (window as any)._satellite.track(eventName, data);
    } catch (error) {
      this.logger.error('Error caught tracking event to Adobe', error, this.constructor.name);

      return Promise.resolve(false);
    }
  }
}
