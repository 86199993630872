import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  HostBinding,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  OnChanges,
  ChangeDetectionStrategy
} from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { FileService } from '../../services/files/file.service';
import { ClarityConfig } from '../../config/clarity.config';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { JWPlayerService } from '../../services/jwplayer.service';
import { LoggerService } from '../../services/logger.service';
import { AlertsService } from '../../services/alerts.service';

@Component({
  selector: 'cl-audio-player-jwplayer',
  styleUrls: ['audio-player-jwplayer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div [class.card-bg]="cardBg">
      <ion-row class="audio-row-wrapper ion-justify-content-around">
        <ion-col>
          <div class="audio-wrapper" #audio>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col *ngIf="!isDesktop"></ion-col>
        <ion-col>
          <ion-button mode="ios" *ngIf="player" type="button" (click)="backwardFifteenSec()" fill="outline" size="small" color="white">
            <ion-icon name="play-back"></ion-icon>
          </ion-button>
          <span>{{'common.15_sec' | translate}}</span>
        </ion-col>
        <ion-col>
          <ion-button mode="ios" *ngIf="player" type="button" (click)="stopped ? resumePlay() : pausePlay()" fill="outline" size="small"
                  color="white">
            <ion-icon name="{{playIcon}}"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button mode="ios" *ngIf="player" type="button" (click)="forwardFifteenSec()" fill="outline" size="small" color="white">
            <ion-icon name="play-forward"></ion-icon>
          </ion-button>
          <span>{{'common.15_sec' | translate}}</span>
        </ion-col>
        <ion-col *ngIf="!isDesktop"></ion-col>
      </ion-row>
    </div>
  `
})
export class AudioPlayerJWPlayerComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @HostBinding('class.desktop') get isDesktop() {
    return this.config.isWebApp || this.forceDesktop;
  }

  @Input() title: string;
  @Input() controls: Observable<string>;
  @Input() cardBg: boolean;
  @Input() autoplay = true;
  @Input() forceDesktop = false;
  @Input() jw_key: string;

  @Output() canPlay = new EventEmitter();
  @Output() canPlayThrough = new EventEmitter();
  @Output() playedMinimum = new EventEmitter();
  @Output() completed = new EventEmitter();

  @ViewChild('audio', { static: true }) playerElement: ElementRef;

  player: any;
  private playerIsPaused = true;

  playingStarted = false;
  controlsSubscription: Subscription;

  constructor(
    private config: ClarityConfig,
    private insomnia: Insomnia,
    protected changeDetector: ChangeDetectorRef,
    private backgroundMode: BackgroundMode,
    private fileService: FileService,
    private jwPlayerService: JWPlayerService,
    private loggerService: LoggerService,
    private alertsService: AlertsService
  ) {
    if (!this.jwPlayerService.isPlayerLoaded()) {
      this.jwPlayerService.forcePlayerLoad();
    }
  }

  get playIcon() {
    return this.stopped ? 'play' : 'pause';
  }

  get stopped() {
    return this.playerIsPaused;
  }

  ngOnInit() {
    this.jwPlayerService.playerLoadPromise
      .then(() => {
        this.player = this.jwPlayerService.getPlayerElement(this.playerElement.nativeElement);

        if (this.controls) {
          this.controlsSubscription = this.controls.subscribe((action) => this.parentPlayControl(action));
        }
      });
  }

  ngAfterViewInit() {
    if (!this.jw_key) {
      this.loggerService.error('JWAudioPlayerError', 'Audio player requested, but no JW key provided');

      return this.alertsService.playerError();
    }

    this.jwPlayerService.playerLoadPromise
      .then(() => this.setSrc());
  }

  ngOnChanges(changes) {
    if (changes.jw_key) {
      const {jw_key} = changes;
      const hasJwKeyChanges = jw_key && jw_key.currentValue !== jw_key.previousValue && !jw_key.firstChange;

      if (!hasJwKeyChanges) {
        return;
      }

      if (jw_key && jw_key.currentValue) {
        this.setSrc();
      }
    }
  }

  ngOnDestroy() {
    if (this.config.isDevice) {
      this.insomnia.allowSleepAgain();
      this.backgroundMode.disable();
    }

    this.player && this.player.remove();
    this.controlsSubscription && this.controlsSubscription.unsubscribe();
  }

  parentPlayControl(action) {
    switch (action) {
      case 'play':
        return this.resumePlay();

      case 'pause':
        return this.pausePlay();

      default:
        return false;
    }
  }

  pausePlay() {
    if (this.config.isDevice) {
      this.insomnia.allowSleepAgain();
      this.backgroundMode.disable();
    }

    this.player.pause();
  }

  resumePlay() {
    if (this.config.isDevice) {
      this.insomnia.keepAwake();
      this.backgroundMode.enable();
    }

    setTimeout(() => {
      const ret = this.player.play();

      // on some older Android devices, this will not return a promise
      if (ret && ret.catch) {
        ret.catch((error) => {
          // sometimes the error will not be available so this will throw an exception
          // console.log('Player error occurred', error, this.player.error.code ? this.player.error.code : 'Unknown error code');

          this.handleError(error);
        });
      }
    });
  }

  handleError(error) {
    if (this.config.isDevice) {
      this.insomnia.allowSleepAgain();
      this.backgroundMode.disable();
    }

    this.loggerService.error('JWAudioPlayerError', error);
    this.alertsService.playerError();
  }

  getCurrentTime() {
    return this.player.getPosition();
  }

  setCurrentTime(newTime) {
    this.player.seek(newTime);
  }

  forwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(this.getCurrentTime() + 15);
    this.resumePlay();
  }

  backwardFifteenSec() {
    this.pausePlay();
    this.setCurrentTime(Math.max(this.getCurrentTime() - 15, 0));
    this.resumePlay();
  }

  handlePlaying() {
    console.log('audio - playing');

    this.playerIsPaused = false;

    this.changeDetector.detectChanges();

    if (!this.playingStarted && this.playedMinimum.observers.length > 0) {
      setTimeout(() => {
        this.playedMinimum.emit(10);
      }, 1000);
    }

    this.playingStarted = true;
  }

  handlePause() {
    console.log('audio - pause');
    this.playerIsPaused = true;
    this.changeDetector.detectChanges();
  }

  handleEnded() {
    console.log('audio - ended');
    this.changeDetector.detectChanges();
    this.completed.emit();
  }

  handleCanPlayThrough(buffer) {
    if (this.autoplay && buffer.bufferPercent === 100) {
      console.log('audio - canplaythrough');
      this.canPlayThrough.emit(true);
    }
  }

  handleCanPlay() {
    console.log('audio - canplay');
    if (this.autoplay) {
      this.canPlay.emit(true);
      this.resumePlay();
    }
  }

  setSrc() {
    this.playingStarted = false;

    const mediaConfig = {
      playlist: `https://cdn.jwplayer.com/v2/media/${this.jw_key}`,
      height: 40,
      width: 'auto'
    };

    this.player.setup(mediaConfig);
    setTimeout(this.listenPlayerEvents.bind(this));

    this.handleCanPlay();
  }

  private listenPlayerEvents() {
    this.player.on('pause', this.handlePause.bind(this));
    this.player.on('play', this.handlePlaying.bind(this));
    this.player.on('complete', this.handleEnded.bind(this));
    this.player.on('bufferChange', this.handleCanPlayThrough.bind(this));
    this.player.on('error', this.handleError.bind(this));
  }
}
