<ng-container *ngIf="{
  loading: conversationsLoading$ | async,
  conversations: conversations$ | async,
  conversationsFilter: conversationsFilter$ | async,
  userProfile: currentUserProfile$ | async,
  isCoach: isCoach$ | async
  } as data">

  <ion-header>
    <cl-header-nav-buttons
      *ngIf="!data.isCoach"
      class="page-header"
      color="blank"
      [backButton]="false"
      (closeClick)="onClose()"
      [title]="'my-coach.title' | translate"
      [class.desktop]="config.usingDesktopOnWeb">
    </cl-header-nav-buttons>

    <cl-header-nav-buttons
      *ngIf="data.isCoach"
      class="page-header"
      color="blank"
      [backButton]="false"
      (closeClick)="onClose()"
      [title]="'my-coach.coach_admin_title' | translate"
      [class.desktop]="config.usingDesktopOnWeb">
    </cl-header-nav-buttons>
  </ion-header>

  <ion-content>

    <ion-row class="section-explainer ion-text-center" *ngIf="!data.isCoach">
      <ion-col>
        <p>{{ (config.isERN() ? "my-coach.conversation_title_ern" : "my-coach.conversation_title") | translate}}</p>
      </ion-col>
    </ion-row>

    <ion-row class="section-explainer ion-text-center" *ngIf="data.isCoach">
      <ion-col>
        <ion-chip>
          <ion-avatar class="avatar">
            <img src="{{data.userProfile.avatar}}" alt="">
          </ion-avatar>
          <ion-label>{{"my-coach.coach_admin_conversation_title" | translate : {username: data.userProfile.username} }}</ion-label>
        </ion-chip>
      </ion-col>
    </ion-row>

    <div *ngIf="!data.isCoach && coachOnline" class="section-coach-online">{{ "my-coach.coach_online" | translate}}
      <div class="greendot"></div>
    </div>

    <ion-row class="filter-holder ion-text-center" *ngIf="data.isCoach">
      <ion-col>
        <ion-item>
          <ion-label>Conversations</ion-label>
          <ion-select
            placeholder="Select filter"
            interface="popover"
            [value]="data.conversationsFilter"
            (ionChange)="onFilterChange($event)">
            <ion-select-option value="new">{{'my-coach.filters.new' | translate}}</ion-select-option>
            <ion-select-option value="mine_open">{{'my-coach.filters.mine_open' | translate}}</ion-select-option>
            <ion-select-option value="mine">{{'my-coach.filters.mine_open_answered' | translate}}</ion-select-option>
            <ion-select-option value="mine_closed">{{'my-coach.filters.mine_closed' | translate}}</ion-select-option>
            <ion-select-option value="all_open">{{'my-coach.filters.all_open' | translate}}</ion-select-option>
            <ion-select-option value="all">{{'my-coach.filters.all_open_answered' | translate}}</ion-select-option>
            <ion-select-option value="all_closed">{{'my-coach.filters.all_closed' | translate}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>

    <div *ngIf="!paginating && data.loading; then initialLoading else conversations"></div>

    <ng-template #initialLoading>
      <div class="loading-wrapper">
        <ion-spinner></ion-spinner>
      </div>
    </ng-template>

    <ng-template #conversations>
      <div class="conversation-container" *ngIf="data.conversations.length > 0">
        <cl-conversation-card
          *ngFor="let conversation of conversations$ | async;"
          [entry]="conversation"
          [isCoach]="data.isCoach"
          (openClick)="onOpenClick($event)"
          (closeClick)="onCloseConversationClick($event)"
          (delete)="onDeleteClick($event)">
        </cl-conversation-card>

        <ion-infinite-scroll threshold="100px" (ionInfinite)="loadConversations($event)" *ngIf="data.isCoach && !(isFullListLoaded$ | async)">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            loadingText="{{ 'social.posts.load_more' | translate}}">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>

        <div class="no-more-conversations" *ngIf="data.isCoach &&  (isFullListLoaded$ | async) && data.conversations.length > 0">
          {{'my-coach.no-more-conversations' | translate}}
        </div>
      </div>

      <div *ngIf="!data.isCoach && data.conversations.length === 0" class="no-conversations">
        {{"my-coach.no-conversations" | translate}}
      </div>
    </ng-template>

  </ion-content>

  <ion-footer *ngIf="!data.isCoach && data.conversations.length <= 0">
    <ion-toolbar>
      <ion-button expand="block" *ngIf="!config.isCTQ() || (isUserProfileComplete$ | async)" (click)="onNewConversationClick()">
        {{('my-coach.new_conversation' | translate | uppercase)}}
      </ion-button>

      <ion-button expand="block" *ngIf="config.isCTQ() && !(isUserProfileComplete$ | async)" (click)="openIncompleteProfilePopup()">
        {{('social.journal.next' | translate | uppercase)}}
      </ion-button>
    </ion-toolbar>
  </ion-footer>

</ng-container>
