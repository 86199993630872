import { Component, Injector } from '@angular/core';
import { WizardComponent } from '../wizard.component';
import { CheckinsService } from '../../../../services/wizards/checkins.service';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../../store/session/session.reducers';
import { BrowserService } from '../../../../services/browser.service';
import { ClarityConfig } from '../../../../config/clarity.config';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'cl-checkin-wizard',
  styleUrls: [ '../../wizard.scss', 'checkin-wizard.component.scss' ],
  templateUrl: 'checkin-wizard.component.html'
})
export class CheckinWizardComponent extends WizardComponent {

  public feelingChoice = null;

  protected wizardConfig = {
    title: 'wizards.checkin',
    totalSteps: 2,
    initialStep: 1
  };

  public feelings = [];
  public checkinExercise = null;
  public feelNow = 30;
  public anxiousFeeling = 0;

  public service: CheckinsService;

  constructor(
    protected store: Store<SessionState>,
    protected browser: BrowserService,
    protected injector: Injector,
    public config: ClarityConfig,
    protected analyticsService: AnalyticsService,
    protected translate: TranslateService,
    public navParams: NavParams
  ) {
    super(store, browser, injector, config, analyticsService, translate);
  }

  initStep1() {
    this.loadFeelingChoice();
  }


  isStepValid1() {
    return this.service.isFeelingValid();
  }

  onFeelingSelected(key) {
    this.service.setFeeling(key);
    this.loadFeelingChoice();
  }

  onDone() {
    this.service.saveCheckin();
    this.wizardComplete.emit();
  }

  protected _init() {
    this.service = this.injector.get<CheckinsService>(CheckinsService);

    return this.service.initModel(this.navParams.get('userReferrer'))
      .then(() => {
        this.feelings = this.service.getFeelingsList();
        this.checkinExercise = this.service.getCheckinExercise();
      });
  }

  private loadFeelingChoice() {
    this.feelingChoice = this.service.getFeeling();

    if (this.feelingChoice) {
      this.onEnableNext();
    }
    else {
      this.onDisableNext();
    }
  }
}
