import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CreateUserFav, DeleteUserFavs } from '../../../store/session/actions/user-favorites.actions';
import { Store } from '@ngrx/store';
import { State } from '../../../store/state.reducer';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-module-title',
  styleUrls: ['module-title.component.scss'],
  template: `
    <h1>
      {{module.record.title}}
    </h1>

    <h2 *ngIf="module.type === 'program'">
      <ng-container *ngIf="module.isFav; else addToFav">
        <ion-icon name="heart" (click)="removeFromFavorites()"></ion-icon>
      </ng-container>
      <ng-template #addToFav>
        <ion-icon name="heart-outline" (click)="markAsFavorite()"></ion-icon>
      </ng-template>

      {{'dashboard.week' | translate}} {{module.weekNumber}}
      {{'dashboard.module' | translate}} {{module.record.number}}
    </h2>

    <h2 *ngIf="module.type === 'custom'">
      {{'dashboard.week' | translate}} {{module.weekNumber}}
      {{'dashboard.module' | translate}} {{module.positionInWeek}}
    </h2>

    <h2 *ngIf="module.type === 'theme'">
      {{'dashboard.week' | translate}} {{module.weekNumber}}
      {{'dashboard.module' | translate}} {{module.positionInWeek}}
    </h2>

    <h3 class="module-info">
      {{module.lessons.length}} {{'common.lessons' | translate}},
      {{calcTotalMinutes(module.lessons)}} {{'common.minutes' | translate}}
    </h3>

    <h2>{{'dashboard.todays_goal' | translate}}</h2>
    <h3 class="goal">
      {{module.record.goal}}
    </h3>
  `
})
export class ModuleTitleComponent {

  @Input() module;

  constructor(private store: Store<State>) {}

  markAsFavorite() {
    this.store.dispatch(new CreateUserFav({
      record_id: this.module.recordId,
      type: 'module'
    }));
  }

  removeFromFavorites() {
    this.store.dispatch(new DeleteUserFavs(this.module.isFav.id));
  }

  calcWeekNumber(value) {
    if (value === 0) {
      return 1;
    }

    return Math.floor((value + 6) / 7);
  }

  calcTotalMinutes(lessons) {
    let total = 0;

    lessons.forEach((lesson) => {
      // it's not clear why exercise doesn't exist here -- most probably we need guards since this data can refresh
      if (lesson && lesson.record && lesson.record.exercise) {
        total += lesson.record.exercise.length;
      }
    });

    return Math.ceil(total / 60);
  }

}
