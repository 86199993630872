import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as mediaActions from './media.actions';
import {concatMap, tap, withLatestFrom} from 'rxjs/operators';
import { FileService } from '../../../services/files/file.service';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { LoggerService } from 'src/app/services/logger.service';
import { getGoogleStorageDeleted } from 'src/app/store/persistent/media/media.selectors';

@Injectable({providedIn: 'root'})
export class MediaEffects {

  constructor(
    private actions$: Actions,
    private fileService: FileService,
    private store: Store<State>,
    private loggerService: LoggerService
  ) {}


  removeAllFiles$ = createEffect(() => this.actions$.pipe(ofType<mediaActions.RemoveAllFiles>(mediaActions.REMOVE_ALL_FILES),
    tap(() => {
      this.fileService.removeAllDownloadedFiles();
    })
  ), { dispatch: false });

  removeAllGoogleFiles$ = createEffect(() => this.actions$.pipe(ofType(mediaActions.REMOVE_ALL_GOOGLE_FILES),
    concatMap(action => of(action)
      .pipe(withLatestFrom(
        this.store.select(getGoogleStorageDeleted)))
    ),
    tap(([_, googleStorageDeleted]) => {
      if(!googleStorageDeleted) {
        this.fileService.deleteGoogleDownloadedFiles()
          .then(() => {
            this.store.dispatch(new mediaActions.RemoveAllGoogleFilesSuccess());
          })
          .catch((error) => {
            this.loggerService.error('Error deleting non brightcove downloads', error);
            throw error;
          });
      }

    })
  ), { dispatch: false });
}
