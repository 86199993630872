import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ClarityConfig } from '../config/clarity.config';
import { HttpProvider } from '../providers/http/http.provider';

const maintenanceServiceEndpoint = {
  PROD: 'https://dd7dcba3slypyu77w7emldhrba0poydr.lambda-url.us-east-1.on.aws/',
  SANDBOX: 'https://bjspbezoos7e4ooptvhuhn7st40xtqqf.lambda-url.us-east-1.on.aws/'
};

@Injectable({providedIn: 'root'})
export class MaintenanceService {

  constructor(
    private http: HttpProvider,
    private translate: TranslateService,
    private alertController: AlertController,
    public config: ClarityConfig
  ) {
  }

  initialize() {
    const lambdaUrl = this.config.onProd() ? maintenanceServiceEndpoint.PROD : maintenanceServiceEndpoint.SANDBOX;

    this.http.get(lambdaUrl)
      .subscribe(response => {
        if (response.maintenance) {
          this.showMaintenanceAlert(response.message);
        }
      });

    return Promise.resolve(true);
  }

  showMaintenanceAlert(message: string) {
    this.translate.get([
      'common.ok',
      'common.important'
    ])
      .toPromise()
      .then(async translations => {
        const alert = await this.alertController.create({
          subHeader: translations['common.important'],
          message,
          buttons: [translations['common.ok']]
        });

        await alert.present();
      });
  }
}
