
import { throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpProvider } from './http/http.provider';
import { catchError } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ProgramProvider {

  private readonly programBootstrapEndpoint = '/programs/bootstrap';

  constructor(
    private http: HttpProvider
  ) {

  }

  public loadProgramBootstrap() {
    return this.http.get(this.programBootstrapEndpoint)
      .pipe(
        catchError((error) => {
          console.log('error retrieving program', error);

          return observableThrowError(error);
        })
      );
  }

}
