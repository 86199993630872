import { Injectable } from '@angular/core';

import { HttpProvider } from './http/http.provider';
import { UserReminder } from '../store/normalized/schemas/reminder.schema';
import { OfflineHttpProvider } from './http/offline-http.provider';

@Injectable({providedIn: 'root'})
export class UserRemindersProvider {

  private userRemindersEndpoint = '/user_reminders';

  constructor(
    protected http: HttpProvider,
    private offlineHttp: OfflineHttpProvider
  ) {

  }

  public loadUserReminders() {
    return this.http.get<UserReminder[]>(this.userRemindersEndpoint);
  }

  // private reminderToPatch(reminder: UserReminder) {
  //
  //   const timings = {
  //     first_at: reminder.first_at,
  //     last_before: reminder.max_daily_count === 1 ? reminder.first_at : reminder.last_before
  //   };
  //
  //   return {
  //     ...reminder,
  //     ...timings,
  //     days: [
  //       reminder.day_sun,
  //       reminder.day_mon,
  //       reminder.day_tue,
  //       reminder.day_wed,
  //       reminder.day_thu,
  //       reminder.day_fri,
  //       reminder.day_sat
  //     ]
  //   };
  // }

  // private removeForbiddenItems(reminder: UserReminder) {
  //   const newReminder = {...reminder};
  //   delete newReminder['program_id'];
  //   delete newReminder['created_at'];
  //   delete newReminder['updated_at'];
  //   delete newReminder['name'];
  //   delete newReminder['max_daily_count'];
  //   delete newReminder['tag'];
  //   delete newReminder['user_reminder'];
  //   delete newReminder['id'];
  //
  //   return newReminder;
  // }

  public createUserReminder(reminder: UserReminder) {
    return this.http.post(this.userRemindersEndpoint, reminder);
  }

  public updateUserReminder(reminder: UserReminder) {
    return this.offlineHttp.patch(this.userRemindersEndpoint + '/' + reminder.id, reminder);
  }
}
