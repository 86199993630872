import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { ActionDialogMediaConfig } from '../action-dialog.interface';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-action-dialog-media',
  styleUrls: ['action-dialog-media.component.scss'],
  template: `
    <ng-container [ngSwitch]="media?.type">
      <div *ngSwitchCase="'video'">
        <cl-video-player-brightcove
          *ngIf="useBrightcovePlayer"
          [brightcove_key]="media?.source.brightcove_key"
          [brightcove_poster_url]="media?.source.brightcove_poster_url">
        </cl-video-player-brightcove>

        <cl-video-player-brightcove-web
          *ngIf="useBrightcovePlayerWeb"
          [brightcove_key]="media?.source.brightcove_key">
        </cl-video-player-brightcove-web>

        <cl-video-player-jwplayer
          *ngIf="useJWPlayer"
          [showControls]="false"
          [autoplay]="false"
          [jw_key]="media?.source.jw_key"
          (playedMinimum)="onMediaPlayed($event)">
        </cl-video-player-jwplayer>

        <cl-video-player
          *ngIf="useVideoPlayer"
          [showControls]="false"
          [autoplay]="false"
          [src]="media?.source.data"
          [subtitle]="media?.source.subtitle"
          (playedMinimum)="onMediaPlayed($event)">
        </cl-video-player>
      </div>
    </ng-container>
  `
})
export class ActionDialogMediaComponent implements OnInit {

  @Input() media: ActionDialogMediaConfig;
  @Output() mediaPlayed = new EventEmitter<void>();

  get video() {
    return this.media.source;
  }

  get useBrightcovePlayerWeb() {
    return this.config.isBrightcoveWebEnabled() && this.video && this.video.brightcove_key;
  }

  get useBrightcovePlayer() {
    return this.config.isBrightcoveEnabled() && this.video && this.video.brightcove_key;
  }

  get useJWPlayer() {
    return !this.useBrightcovePlayerWeb && !this.useBrightcovePlayer && this.config.jwplayerEnabled() && this.video && this.video.jw_key;
  }

  get useVideoPlayer() {
    return !this.useBrightcovePlayerWeb && !this.useBrightcovePlayer && !this.useJWPlayer;
  }

  constructor(
    public config: ClarityConfig
  ) {}

  ngOnInit(): void {
    console.log(this.media);
  }

  onMediaPlayed(seconds: number) {
    if (seconds > 0) {
      this.mediaPlayed.emit();
    }
  }
}
