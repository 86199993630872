import { Action } from '@ngrx/store';

export const SET_FIRST_ACTION_COMPLETED = '[interstitials] Set First Action Completed';

export class SetFirstActionCompleted implements Action {
  readonly type = SET_FIRST_ACTION_COMPLETED;

  constructor(public payload: string) {
  }
}

export type InterstitialsActions =
  | SetFirstActionCompleted;
