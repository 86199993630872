import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';

import { Router } from '@angular/router';
import { IAPProduct } from '@ionic-native/in-app-purchase-2';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ClarityConfig } from 'src/app/config/clarity.config';
import { LoadingService } from 'src/app/services/loading.service';
import { SubscriptionsService } from 'src/app/services/subscriptions/subscriptions.service';
import { State } from 'src/app/store';
import { StripeProduct } from 'src/app/store/normalized/schemas/stripe-plan.schema';
import { getAudioExercisesCount, getMindfulnessExercisesCount } from 'src/app/store/normalized/selectors/exercises.selectors';
import { getStripeProducts } from 'src/app/store/normalized/selectors/stripe-plans.selectors';
import { getThemeWeeksCount } from 'src/app/store/normalized/selectors/theme-week.selectors';
import { getLessonsCount } from 'src/app/store/normalized/selectors/user-bootstrap.selectors';
import { OpenModal, UpgradeSource } from 'src/app/store/session/actions/navigation.actions';
import { LoadStripePlans } from 'src/app/store/session/actions/subscription.actions';
import { isLoadingStripePlans } from 'src/app/store/session/selectors/subscription.selectors';
import { getInAppPricePerWeek, getInAppPricePerWeekRounded, getInAppSavePercent,
  getProductType, WeeklyPriceRoundDirection } from 'src/app/utils/subscription-utils';
import { StripeFormComponent } from '../subscribe/components/stripe-form.component';
import * as navigationActions from 'src/app/store/session/actions/navigation.actions';
import { ReleaseService } from 'src/app/services/release.service';
import * as moment from 'moment';
import { LiveSubscription, getLiveSubscription } from 'src/app/store/normalized/selectors/subscription.selectors';

enum GeneralProductType {
  STRIPE = 'stripe',
  APPLE = 'apple',
  GOOGLE = 'google'
}
type GeneralProductId = string | number;
interface GeneralProduct {
  type: GeneralProductType;
  id: GeneralProductId;
  name: string;
  savePercent: number;
  wholePrice: string;
  fractionPrice: string;
  priceFormatted: string;
  pricePerWeekFormatted: string;
  pricePerWeekRounded?: WeeklyPriceRoundDirection;
}

@Component({
  selector: 'page-premium',
  styleUrls: ['premium.scss'],
  templateUrl: 'premium.html'
})
export class PremiumPage implements OnInit, AfterViewInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  modal;
  public showCloseButton = false; // We show close button only if the page is opened as a modal
  get layoutType() {
    return Boolean(this.modal) ? 'modal' : 'page';
  }

  lessonCount$ = this.store.select(getLessonsCount);
  themeWeekCount$ = this.store.select(getThemeWeeksCount);
  exerciseCount$ = this.store.select(getMindfulnessExercisesCount);
  audioExerciseCount$ = this.store.select(getAudioExercisesCount);

  observer: IntersectionObserver;
  @ViewChild('scrolledToBottomObserverElement', { read: ElementRef, static: false })
  scrolledToBottomObserverElement: ElementRef<HTMLElement>;
  isScrolledToBottom$ = new BehaviorSubject(false);

  @ViewChild('headerObserverElement', { read: ElementRef, static: false })
  headerObserverElement: ElementRef<HTMLElement>;
  isHeaderVisible$ = new BehaviorSubject(true);

  generalProducts$ = new BehaviorSubject<GeneralProduct[]>([]);
  currentGeneralProductType = this.config.isIos ? GeneralProductType.APPLE
    : this.config.isAndroid ? GeneralProductType.GOOGLE
      : GeneralProductType.STRIPE;
  defaultGeneralProductIds: { [programCode: string]: { [productType in GeneralProductType]: GeneralProductId } } = {
    ua: {
      stripe: 18,
      apple: 'UA_6Months',
      google: 'ua.6months'
    },
    ctq: {
      stripe: 12,
      apple: 'CTQ_Monthly',
      google: 'ctq.monthly'
    },
    ern: {
      stripe: 20,
      apple: 'ERN_6Months',
      google: 'ern.6months'
    },
    dppwl: {
      stripe: 20,
      apple: 'ERN_6Months',
      google: 'ern.6months'
    }
  };
  selectedGeneralProduct: GeneralProduct;

  private stripeProducts: StripeProduct[];
  private isOfficialRelease = false;
  private subscription$: Observable<LiveSubscription> = this.store.select(getLiveSubscription);
  private liveSubscription: LiveSubscription;


  constructor(
    public config: ClarityConfig,
    private store: Store<State>,
    private subscriptionsService: SubscriptionsService,
    private loading: LoadingService,
    private router: Router,
    private releaseService: ReleaseService
  ) {}

  ngOnInit() {
    if (this.config.stripeWebEnabled()) {
      this.store.dispatch(new LoadStripePlans());

      this.store.select(getStripeProducts).pipe(takeUntil(this.destroyed$))
        .subscribe(stripeProducts => {
          this.stripeProducts = stripeProducts;

          const generalProducts = stripeProducts.sort((a, b) => a.priceMicros - b.priceMicros)
            .map(stripeProduct => ({
              type: GeneralProductType.STRIPE,
              id: stripeProduct.id,
              name: stripeProduct.name,
              savePercent: stripeProduct.savePercent,
              wholePrice: stripeProduct.priceFormatted.split('.')[0],
              fractionPrice: stripeProduct.priceFormatted.split('.')[1],
              priceFormatted: stripeProduct.priceFormatted,
              pricePerWeekFormatted: stripeProduct.pricePerWeekFormatted,
              pricePerWeekRounded: stripeProduct?.pricePerWeekRounded
            }));

          this.generalProducts$.next(generalProducts);

          this.selectedGeneralProduct = this.findGeneralProductById(this.getDefaultGeneralProductId());
        });

      this.store.select(isLoadingStripePlans)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(isLoading => {
          if (isLoading && this.isThisPageActive) {
            this.loading.showLoadingOverlay();
          } else {
            setTimeout(() => this.loading.hideLoadingOverlay()); // doesn't work well without the setTimeout
          }
        });

      this.releaseService.getReleaseObserver()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((isOfficialRelease) => {
          this.isOfficialRelease = isOfficialRelease;
        });

      this.subscription$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((liveSubscription: LiveSubscription) => {
          this.liveSubscription = liveSubscription;
        });
    }

    if (this.config.isDevice) {
      this.subscriptionsService.initialize(true).then(() => {
        // TODO: SubscriptionsService handles loading indicators buggy,
        // as sometimes they are not visible when they should be,

        this.subscriptionsService.whenReadyObservable.pipe(takeUntil(this.destroyed$)).subscribe(() => {
          const generalProducts = this.subscriptionsService.getAvailableStoreProducts().map((appStoreProduct, _, appStoreProducts) => ({
            type: this.config.isIos ? GeneralProductType.APPLE : GeneralProductType.GOOGLE,
            id: appStoreProduct.id,
            name: this.getTranslationKey(this.getAppStoreProductName(appStoreProduct)),
            wholePrice: appStoreProduct.currency === 'USD' ? appStoreProduct.price.split('.')[0] : appStoreProduct.price,
            fractionPrice: appStoreProduct.currency === 'USD' ? appStoreProduct.price.split('.')[1] : null,
            savePercent: getInAppSavePercent(appStoreProduct, appStoreProducts),
            pricePerWeekFormatted: getInAppPricePerWeek(appStoreProduct),
            pricePerWeekRounded: getInAppPricePerWeekRounded(appStoreProduct),
            priceFormatted: appStoreProduct.price
          }));

          this.generalProducts$.next(generalProducts);

          this.selectedGeneralProduct = this.findGeneralProductById(this.getDefaultGeneralProductId());
          this.loading.hideLoadingOverlay();
        });
      });
    }

    console.log(this);
  }

  dismissPremiumPage() {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }

  getDefaultGeneralProductId() {
    return this.defaultGeneralProductIds[this.config.currentProgramCode][this.currentGeneralProductType];
  }

  private getAppStoreProductName(appStoreProduct: IAPProduct) {
    return getProductType(appStoreProduct.id);
  }

  private getTranslationKey(productName: string) {
    return 'subscriptions.premium_tab.common.' + productName;
  }

  onLicenseCode() {
    this.store.dispatch(new OpenModal('LicensePage', {
      fromPremiumTab: true
    }));
  }

  onGeneralProductSelected(id: GeneralProductId) {
    this.selectedGeneralProduct = this.findGeneralProductById(id);
  }

  buyProduct() {
    if (this.config.stripeWebEnabled()) {
      const selectedStripeProduct = this.stripeProducts.find(stripeProduct => stripeProduct.id === this.selectedGeneralProduct.id);

      this.subscriptionsService.selectStripePlan(selectedStripeProduct);

      this.store.dispatch(new OpenModal('EmptyModalComponent', {
        header: 'stripe.subscription',
        embedComponent: StripeFormComponent,
        componentProps: {
          headline: 'stripe.full_access',
          source: UpgradeSource.PREMIUM_TAB
        },
        forceBackdrop: true
      }));
    } else if (this.config.isDevice) {
      this.subscriptionsService.orderProduct(this.selectedGeneralProduct.id as string);
    }
  }

  ngAfterViewInit() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (!this.isThisPageActive) {
          return;
        }

        if (entry.target.tagName === 'H1') {
          this.isHeaderVisible$.next(entry.isIntersecting);
        }

        if (entry.target.tagName === 'DIV') {
          this.isScrolledToBottom$.next(entry.isIntersecting);
        }
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0
    });

    setTimeout(() => this.observer.observe(this.scrolledToBottomObserverElement.nativeElement), 300);
    setTimeout(() => this.observer.observe(this.headerObserverElement.nativeElement), 400);
  }

  private findGeneralProductById(id: GeneralProductId) {
    return this.generalProducts$.value.find(generalProduct => generalProduct.id === id);
  }

  private get isThisPageActive() {
    return this.router.url === '/tabs/premium' || this.layoutType === 'modal';
  }

  public get canUseLicense() {
    // will check if this build is official or minimum delay has passed since build (see constants)

    if (!this.isOfficialRelease && !this.config.onDev() && this.config.env.releaseId && !this.config.isWebApp) {
      const generatedAt = Number(this.config.env.releaseId);
      const now = Number(moment().format('x'));

      if (generatedAt && now < generatedAt) {
        return false;
      }
    }

    return !this.liveSubscription || (!this.liveSubscription.isActive && this.liveSubscription.canLicense);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();

    console.log('destroyed');
  }
}
