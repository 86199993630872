import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostBinding,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-program-path',
  styleUrls: ['program-path.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="program-path-wrapper">
      <div
        class="program-path-recommended-badge"
        *ngIf="showRecommendedBadge">{{ 'account_setup.ern_diagnostic_onboarding.slides.recommended' | translate }}
      </div>
      <h4 class="program-path-heading">
        <img *ngIf="data.icon" [src]="iconSrc"  alt="" />
        <div>
          {{data.label | translate}}
        </div>
      </h4>
      <div
        class="program-path-language-warning"
        *ngIf="showLanguageWarning"
      >
        <ion-icon name="warning"></ion-icon>
        <span>{{ 'account_setup.ern_diagnostic_onboarding.path_description.only_available_in_english' | translate }}</span>
      </div>
      <div
        class="program-path-description"
        [ngClass]="{extended: !boxExtended}"
        [innerHTML]='(boxExtended ? data.extendedDescription : data.description) | translate'
      >
      </div>
      <a class="program-path-read-more-link" (click)="toggleDescriptionBox()" *ngIf="data.extendedDescription">
        {{ (boxExtended ?'common.show_less' : 'common.show_more') | translate }}
        <ion-icon *ngIf="boxExtended" name="chevron-up-outline"></ion-icon>
        <ion-icon *ngIf="!boxExtended" name="chevron-down-outline"></ion-icon>
      </a>
      <ion-button
        class="program-path-button"
        color="{{colorInversed ? 'white' : 'action'}}"
        (click)="selectPath(data.value)"
      >
        {{ (buttonLabel ? buttonLabel : 'common.continue') | translate }}
        <ion-ripple-effect></ion-ripple-effect>
      </ion-button>
    </div>
  `
})

export class ProgramPathComponent {
  @HostBinding('class.extended') boxExtended = false;
  @HostBinding('class.inversed') @Input() colorInversed = false;

  @Input() data: {
    label: string;
    description: string;
    extendedDescription?: string;
    icon?: string;
    value?: string;
  };

  @Input() showRecommendedBadge: boolean;
  @Input() showLanguageWarning: boolean;
  @Input() extendableDescription?: boolean = true;
  @Input() buttonLabel?: string;

  @Output() pathSelected = new EventEmitter();
  @Output() descriptionExtended = new EventEmitter();

  get iconSrc() {
    const assetsDirectoryPath = 'assets/imgs/';

    return assetsDirectoryPath + this.data.icon + (this.colorInversed ? '-white' : '') + '.svg';
  }

  toggleDescriptionBox() {
    this.boxExtended = !this.boxExtended;

    if (this.extendableDescription) {
      this.descriptionExtended.emit();
    }
  }

  selectPath($event) {
    this.pathSelected.emit($event);
  }
}
