import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Browser } from '@capacitor/browser';

import { ClarityConfig } from '../config/clarity.config';
import { getJournal } from '../store/normalized/selectors/community.selectors';
import { SessionState } from '../store/session/session.reducers';
import { TemporaryTokenProvider } from '../providers/temporary-token.provider';

@Injectable({providedIn: 'root'})
export class BrowserService {
  constructor(
    public config: ClarityConfig,
    private store: Store<SessionState>,
    private translate: TranslateService,
    private temporaryTokenProvider: TemporaryTokenProvider
  ) {
  }

  openCommunityPath(path: string) {
    // for ERN when Spanish is selected, open the special spanish category instead of home
    if (this.config.isERN() && path === '/' && this.translate.currentLang === 'es') {
      path = '/c/ern-spanish';
    }
    if (this.config.programDPPorWL() && path === '/') {
      path = '/c/diabetes-prevention-program/49';
    }

    const urlRoot = this.config.env.apiUrl + this.config.program.communityAuthEndpoint;
    const urlSearchParams = new URLSearchParams(`temporary_token=PLACEHOLDER&redirect_to=${path}`);

    this.safeOpenWithTemporaryToken(urlRoot, urlSearchParams);
  }

  openCommunityJournal() {
    this.store.select(getJournal)
      .pipe(take(1))
      .subscribe(journal => {
        if (!journal) {
          this.openCommunityPath('/');

          return;
        }

        const url = journal.journal_url.split('/');
        url.splice(0, 3);

        this.openCommunityPath(encodeURIComponent('/' + url.join('/')));
      });
  }

  goTo(url: string) {
    console.log('Opening URL in Capacitor Browser...', url);

    Browser.open({ url });
  }

  openWeeklyCalls() {
    const urlRoot = 'https://' + this.config.apiHost + this.config.weeklyCallsEndpoint;
    const urlSearchParams = new URLSearchParams('temporary_token=PLACEHOLDER');

    this.safeOpenWithTemporaryToken(urlRoot, urlSearchParams);
  }

  safeOpenWithTemporaryToken(urlRoot: string, urlSearchParams: URLSearchParams) {
    let newTab: Window;

    if (!this.config.isDevice) {
      newTab = window.open('');
    }

    this.temporaryTokenProvider.generateTemporaryToken()
      .pipe(take(1))
      .subscribe(temporaryToken => {
        urlSearchParams.set('temporary_token', temporaryToken);
        const url = `${urlRoot}?${urlSearchParams.toString()}`;

        if (this.config.isDevice) {
          this.goTo(url);
        } else {
          newTab.location.href = url;
        }
      });
  }

  openInCurrentTab(url: string) {
    if (this.config.isDevice) {
      return this.goTo(url);
    }

    window.location.replace(url);
  }
}
