import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

// GetBonusExercisesList
export const EXERCISES = '[exercises] Get Bonus Exercises List';
export const EXERCISE_BY_ID = '[exercises] Get Bonus Exercises By Id';
export const EXERCISES_SUCCESS = '[exercises] Get Bonus Exercises List Success';
export const EXERCISES_BY_TAGS = '[exercises] Get Exercises By Tags';

export const EXERCISES_FAIL = '[exercises] Get Bonus Exercises List Fail';

export class GetBonusExercisesList implements Action {
  readonly type = EXERCISES;
}

export class GetBonusExercisesListSuccess implements Action {
  readonly type = EXERCISES_SUCCESS;

  constructor(public payload: any) {
  }
}

export class GetBonusExercisesListFail implements Action {
  readonly type = EXERCISES_FAIL;

  constructor(public payload: HttpErrorResponse) {
  }
}

export class GetBonusExerciseById implements Action {
  readonly type = EXERCISE_BY_ID;

  constructor(public payload: number) {
  }
}

export class GetExercisesByTags implements Action {
  readonly type = EXERCISES_BY_TAGS;

  constructor(public payload: string[]) {
  }
}
export type ExercisesActions =
  | GetBonusExercisesList
  | GetBonusExerciseById
  | GetBonusExercisesListSuccess
  | GetExercisesByTags
  | GetBonusExercisesListFail;
