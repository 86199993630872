<ion-content *ngIf="inAppMessage" >
  <div [ngClass]="{'content-wrapper': true, 'web': config.usingDesktopOnWeb, 'mobile': !config.usingDesktopOnWeb}">

    <div [ngClass]="{'avatar-content': true,'web': config.usingDesktopOnWeb, 'mobile': !config.usingDesktopOnWeb}">
      <ion-avatar>
        <img class="coach-avatar" [src]="inAppMessage.authorAvatar" alt="" />
      </ion-avatar>
    </div>

    <div [ngClass]="{'message-content': true, 'web': config.usingDesktopOnWeb}">
      <ion-row>
        <ion-col size="11">
          <div class="coach-name-wrapper">
            <span class="coach-name">{{inAppMessage.authorName}}</span>
            {{ ' ' +('common.from' | translate) + ' ' + (('programs.'+ config.programCode()) | translate) }}
          </div>
        </ion-col>

        <ion-col size="1">
          <div class="close-message-wrapper">
            <cl-close-button color="primary"  (closeClick)="closeModal()" icon="close"> </cl-close-button>
          </div>
        </ion-col>
      </ion-row>

      <div class="message-wrapper" [innerHTML]="inAppMessage.content | stripHtml : acceptedHtmlTags">
      </div>
    </div>
  </div>
</ion-content>


