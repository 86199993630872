export function getYScrollLocation(target, parent) {
  const targetPosition = target.getBoundingClientRect(),
    parentPosition = parent.getBoundingClientRect(),
    targetHeight = targetPosition.height,
    offsetTop = targetPosition.top - (parentPosition.top - parent.scrollTop);

  const y = (offsetTop + (targetHeight * 0.5) - parent.clientHeight * 0.5);

  return y;
}

export function scrollToElement(scrollableParent, target, duration = 500) {
  const Y = getYScrollLocation(target, scrollableParent);
  scrollableParent.scrollToPoint(0, Y, duration);
}
