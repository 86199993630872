<ion-content #ionContent>
  <div class="content-wrapper">
    <cl-tabbed-view [tabs]="tabs" (tabChanged)="onTabChanged($event)" #tabbedNav></cl-tabbed-view>

    <div class="container only-web lateral-padding">
      <cl-account-help-web></cl-account-help-web>
      <cl-account-about-web></cl-account-about-web>
    </div>

    <ng-container *ngIf="showLogout">
      <ion-row class="main-holder">
        <ion-col class="ion-text-center">
          <cl-action-button
            class="logout"
            label="{{'auth.logout' | translate}}"
            color="white"
            [canClick]="true"
            (click)="onLogout()">
          </cl-action-button>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="config.onProd()">
        <ion-col class="ion-text-center">
          <strong>v{{config.env.app.app_version}}</strong>
        </ion-col>
      </ion-row>

      <ion-row class="app-info" *ngIf="!config.onProd()">
        <ion-col class="ion-text-center">
          Version: <strong>{{config.env.app.app_version}}</strong>, Build: <strong>{{config.env.app.app_build}}</strong>
        </ion-col>
      </ion-row>

      <ion-row class="app-info" *ngIf="!config.onProd()">
        <ion-col class="ion-text-center">Environment: <strong>{{config.currentEnv()}}</strong></ion-col>
      </ion-row>

    </ng-container>

    <cl-footer [waves]="'./assets/imgs/bg/footer_waves.svg'"></cl-footer>
  </div>
</ion-content>
