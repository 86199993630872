import { Component } from '@angular/core';

@Component({
  selector: 'cl-reset-password-link',
  styleUrls: ['reset-password-link.component.scss'],
  template: `
    <a>{{ 'auth.reset_password' | translate }}</a>
  `
})

export class ResetPasswordLinkComponent {

}
