import { HttpProvider } from './http/http.provider';
import { ConnectionHandlerProvider } from './http/connection-handler.provider';
import { Observable, of } from 'rxjs';

export class OfflineProvider {

  constructor(
    protected connectionHandler: ConnectionHandlerProvider,
    protected http: HttpProvider
  ) {

  }

  readonly startOf2010 = Date.parse('2010');

  ok<T>(param?: T) {
    return of<T>(param);
  }

  get id() {
    // consecutive IDs is a bad idea, generating almost unique id's locally its better.
    // time since 2010 in ms padded with a random integer between 1 and 10k
    const id = - Math.round(((Date.now() - this.startOf2010) + Math.random()) * 10000);

    return id;
  }

  cleanEndpoint(endpoint: string) {
    return {
      endpoint
    };
  }

  handlePost<T>(endpoint: string, payload: object, schema: string, sequential = false): Observable<T> {
    const id = this.id;
    this.connectionHandler.post(
      endpoint, payload, {
        ...this.cleanEndpoint(endpoint),
        id,
        schema
      },
      sequential
    );
    const transformedInput = this.transformPayload<T>(payload, id);

    return this.ok<T>(transformedInput);
  }

  handlePut<T>(endpoint: string, id: any, payload: object, schema: string, sequential = false): Observable<T> {
    this.connectionHandler.put(
      endpoint, payload, {
        ...this.cleanEndpoint(endpoint),
        id,
        schema
      },
      sequential
    );
    const transformedInput = this.transformPayload<T>(payload, id);

    return this.ok<T>(transformedInput);
  }

  handlePatch<T>(endpoint: string, id: any, payload: object, schema: string, sequential = false): Observable<T> {
    this.connectionHandler.patch(
      endpoint, payload, {
        ...this.cleanEndpoint(endpoint),
        id,
        schema
      },
      sequential
    );
    const transformedInput = this.transformPayload<T>(payload, id);

    return this.ok<T>(transformedInput);
  }

  handleDelete<T>(endpoint: string, id: any, schema: string, sequential = false): Observable<T> {
    this.connectionHandler.delete(
      endpoint, {
        ...this.cleanEndpoint(endpoint),
        id,
        schema
      },
      sequential
    );

    return this.ok(({} as T));
  }

  // to be implemented in each subclass
  transformPayload<T>(param: any, id: number) {
    if (param.id) {
      return param;
    }

    return {...param, id} as T;
  }

}
