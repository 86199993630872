<cl-waves-background intro-bg [class.desktop]="isDesktop">

  <ion-header>
    <cl-header-nav-buttons
      [closeButton]="false"
      [backButton]="true"
      (back)="goBack()"
      color="{{ isDesktop ? 'white' : 'primary' }}"
      class="page-header"
    >
    </cl-header-nav-buttons>
  </ion-header>

  <ion-content>
    <cl-registration-wrapper class="lateral-padding" [class.container]="isDesktop">

      <ion-row class="logo">
        <ion-col class="padding-top-iphone-x">
          <cl-icon-title-container
            [icon]="config.logo"
            header="{{config.program.name}}" desktop="{{isDesktop}}"
            intro="{{ 'auth.login_intro_' + config.program.programCode | translate }}">
          </cl-icon-title-container>
        </ion-col>
      </ion-row>

      <ion-row class="fields-box">
        <ion-col>
          <cl-shadow-wrapper>
            <form [formGroup]="loginForm" autocomplete="off" (submit)="onLogin()" (keyup.enter)="onLogin()">

              <cl-input
                [controlForm]="loginForm.get('email')"
                type="email"
                name="email"
                data-e2e="login-email-input"
                [errorMessages]="validations.email.errors"
                icon="assets/imgs/email-icon.png"
                label="{{'auth.email' | translate}}">
              </cl-input>

              <cl-input
                class="password"
                [preventCopyPaste]="true"
                [controlForm]="loginForm.get('password')"
                type="password"
                name="password"
                data-e2e="login-password-input"
                (changeInput)="loginForm.controls['password'].setValue($event.target.value)"
                [errorMessages]="validations.password.errors"
                icon="assets/imgs/password-icon.png"
                label="{{'auth.password' | translate}}">
              </cl-input>

              <cl-recaptcha
                captchaElementId="loginCaptcha"
                formControlName="captcha">
              </cl-recaptcha>

              <ion-row class="action-button">
                <ion-col class="ion-text-center" auto>
                  <cl-action-button
                    data-e2e="login-button"
                    label="{{'auth.login' | translate}}"
                    [canClick]="!(authenticating$ | async)">
                  </cl-action-button>
                </ion-col>
              </ion-row>

              <ion-row class="reset-password">
                <ion-col class="ion-text-center">
                  <cl-reset-password-link (click)="goToResetPassword()"></cl-reset-password-link>
                </ion-col>
              </ion-row>
            </form>
          </cl-shadow-wrapper>
        </ion-col>
      </ion-row>

      <ion-row class="signup-link" *ngIf="!config.usesDppwlDomain()">
        <ion-col class="ion-text-center">
          <a (click)="goToSignup()">{{ 'auth.create_account' | translate }}</a>
        </ion-col>
      </ion-row>
      
      <cl-language-picker></cl-language-picker>

      <ion-row class="version-number" *ngIf="!config.onProd()">
        <ion-col class="ion-text-center">
          <p>v{{config.getAppVersion()}}</p>
          <p>{{config.env.environment}}</p>
        </ion-col>
      </ion-row>
    </cl-registration-wrapper>
  </ion-content>

</cl-waves-background>
