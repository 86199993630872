import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { interstitialTypes } from './interstitial-types.const';
import * as programActions from '../../store/session/actions/program.actions';
import { SessionState } from '../../store/session/session.reducers';
import { ClarityConfig } from '../../config/clarity.config';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'page-interstitial-locked',
  styleUrls: ['interstitial-locked.scss'],
  templateUrl: 'interstitial-locked.html'
})
export class InterstitialLockedPage implements OnInit, OnDestroy {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  interstitialSettings = interstitialTypes.locked;
  lockedSubType = this.navParams.get('lockedSubType');

  bonusExercise = this.navParams.get('bonusExercise');
  playModule = this.navParams.get('playModule');
  playModuleTitle = this.navParams.get('playModuleTitle');
  currentModuleTitle = this.navParams.get('currentModuleTitle');
  showLockDetails = this.navParams.get('showLockDetails');

  showGoToCurrentModule = this.lockedSubType === 'module_in_progress' || this.lockedSubType === 'accelerated_module_available';

  private onDestroy$ = new Subject();
  hours: string;
  minutes: string;

  constructor(
    private store: Store<SessionState>,
    private config: ClarityConfig,
    public navParams: NavParams
  ) {
  }

  ngOnInit(): void {
    this.initTimer();
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  initTimer() {
    const midnight = new Date().setHours(24, 0, 0, 0);

    timer(0, 1000)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(_ => {
        const secondsUntilMidnight = Math.floor((midnight - new Date().getTime()) / 1000);

        const date = new Date(null);
        date.setSeconds(secondsUntilMidnight);

        this.hours = date.toISOString().substr(11, 2);
        this.minutes = date.toISOString().substr(14, 2);
      });
  }

  stopTimer() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onLearnMore($event) {
    $event.stopPropagation();

    this.store.dispatch(new navigationActions.CloseAllModals());
    this.store.dispatch(new navigationActions.ShowOnboarding({
      page: 'OnboardingPage',
      params: {
        type: 'locked_reason'
      }
    }));
  }

  goToCurrentModule($event) {
    $event.stopPropagation();

    return this.store.dispatch(new programActions.PlayCurrentModule());
  }

  onClick() {
    this.stopTimer();

    this.store.dispatch(new navigationActions.CloseInterstitial());
  }

}
