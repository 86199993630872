import { Component, Input } from '@angular/core';

@Component({
  selector: 'cl-data-section',
  styleUrls: ['data-section.component.scss'],
  template: `
    <ion-row>
      <div class="section-title">
        {{title}}
      </div>
      <ion-item class="section-content" no-lines>
        <ion-label>
          {{content}}
        </ion-label>
      </ion-item>
    </ion-row>
  `
})

export class DataSectionComponent {
  @Input() title: string;
  @Input() content: string;
}
