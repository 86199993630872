<cl-waves-background intro-bg [ngClass]="{'desktop signup': isDesktop}">

  <ion-header>
    <cl-header-nav-buttons
      class="page-header"
      [closeButton]="false"
      (back)="goBack()"
      color="{{ !isDesktop ? 'primary' : 'white' }}">
    </cl-header-nav-buttons>
  </ion-header>

  <ion-content class="ion-padding-top">
    <cl-registration-wrapper class="lateral-padding" [class.container]="isDesktop">

      <ion-row class="logo">
        <ion-col class="padding-top-iphone-x">
          <cl-icon-title-container
            [icon]="config.logo"
            header="{{config.program.name}}&reg;"
            intro="{{ 'subscriptions.license_codes_intro' | translate }}">
          </cl-icon-title-container>
        </ion-col>
      </ion-row>


      <cl-shadow-wrapper>
        <ion-row class="fields-box">
          <ion-col>
            <p>{{'subscriptions.enter_license_code_instructions' | translate}}</p>

            <form [formGroup]="licenseForm" autocomplete="off" (submit)="onValidate()">
              <ion-row>
                <ion-col>
                  <cl-input
                    name="license_code"
                    [errorMessages]="validations.license_code.errors"
                    placeHolder="00-00-00"
                    type="text"
                    [uppercase]="true"
                    [controlForm]="licenseForm.get('license_code')">
                  </cl-input>
                </ion-col>
              </ion-row>

              <ion-row class="action-button">
                <ion-col class="ion-text-center" auto>
                  <cl-action-button
                    label="{{'subscriptions.validate' | translate }}"
                    [canClick]="licenseForm.valid">
                  </cl-action-button>
                </ion-col>
              </ion-row>
            </form>

          </ion-col>
        </ion-row>
      </cl-shadow-wrapper>

    </cl-registration-wrapper>
  </ion-content>
</cl-waves-background>
