import { Component, EventEmitter, Output, Injector, OnDestroy } from '@angular/core';
import { WizardComponent } from '../wizard.component';
import * as navigationActions from '../../../../store/session/actions/navigation.actions';
import * as programActions from '../../../../store/session/actions/program.actions';
import { getWorryToolExercises } from '../../../../store/normalized/selectors/exercises.selectors';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../../store/session/session.reducers';
import { BrowserService } from '../../../../services/browser.service';
import { ClarityConfig } from '../../../../config/clarity.config';
import { AnalyticsService } from '../../../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { LiveBonusExercise } from 'src/app/store/session/selectors/program.selectors';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Exercise } from 'src/app/store/normalized/schemas/exercise.schema';
import { SetFlag } from 'src/app/store/persistent/flags/flags.actions';
import { WorryTool } from 'src/app/store/session/models/worry-tool.model';
import { WorryToolCompleted } from 'src/app/store/session/actions/tools.actions';

function safelyScrollToElement($event) {
  setTimeout(() => {
    try {
      $event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } catch (error) {}
  }, 100);
}

type WorryTypeItem = {
  [key: string]: any;
  value: WorryTool['worry_type'];
};

type KeepWorryingItem = {
  [key: string]: any;
  value: WorryTool['keep_worrying'];
};

@Component({
  selector: 'cl-worry-tool-wizard',
  styleUrls: ['../../wizard.scss', 'worry-tool-wizard.component.scss'],
  templateUrl: 'worry-tool-wizard.component.html'
})
export class WorryToolWizardComponent extends WizardComponent implements OnDestroy {
  public model: WorryTool = {
    after_exercise_strength: null,
    strength: null,
    created_at: new Date().toISOString(),
    keep_worrying: null,
    worry_type: null,
    exercise_id: null
  };

  public worryTypes: WorryTypeItem[] = [
    { id: 0, name: 'wizard_items.Anxiety', value: 'anxiety'  },
    { id: 1, name: 'wizard_items.Worry', value: 'worry'  }
  ];
  public selectedWorryType: WorryTypeItem;

  public keepWorryingOptions: KeepWorryingItem[] = [
    { id: 0, name: 'common.yes', value: 'yes'  },
    { id: 1, name: 'wizards.worry_tool_cant_seem_to_stop', value: 'not_really_but_cannot_stop'  },
    { id: 2, name: 'common.no', value: 'no'  }
  ];
  public selectedKeepWorryingOption: KeepWorryingItem;

  public worryMindfulnessSteps1 = [
    'wizards.worry_tool_mindfulness_step1',
    'wizards.worry_tool_mindfulness_step2',
    'wizards.worry_tool_mindfulness_step3',
    'wizards.worry_tool_mindfulness_step4',
    'wizards.worry_tool_mindfulness_step5'
  ];
  public worryMindfulnessStep1 = 0;

  public worryMindfulnessSteps2 = [
    'wizards.worry_tool_mindfulness2_step1',
    'wizards.worry_tool_mindfulness2_step2',
    'wizards.worry_tool_mindfulness2_step3',
    'wizards.worry_tool_mindfulness2_step4',
    'wizards.worry_tool_mindfulness2_step5',
    'wizards.worry_tool_mindfulness2_step6'
  ];
  public worryMindfulnessStep2 = 0;

  public liveExercises$: Observable<LiveBonusExercise[]> = this.store.select(getWorryToolExercises);
  public exercises$: Observable<Exercise[]> = this.liveExercises$.pipe(map(liveExercises => liveExercises.map(({ exercise }) => exercise)));

  @Output() nextStep = new EventEmitter();

  initialTotalSteps = 6;
  protected wizardConfig = {
    title: 'wizards.worry_tool',
    totalSteps: this.initialTotalSteps,
    initialStep: 1
  };

  constructor(
    protected store: Store<SessionState>,
    protected browser: BrowserService,
    protected injector: Injector,
    public config: ClarityConfig,
    protected analyticsService: AnalyticsService,
    protected translate: TranslateService
  ) {
    super(store, browser, injector, config, analyticsService, translate);
  }

  protected _init() {
    setTimeout(
      () => this.store.dispatch(new SetFlag('worryToolViewed')),
      200
    );

    return Promise.resolve();
  }

  onWorryTypeSelected(selectedId) {
    this.selectedWorryType = this.worryTypes.find(({ id }) => id === selectedId);
    this.model.worry_type = this.selectedWorryType.value;

    this.onEnableNext();
  }

  onKeepWorryingOptionSelected(selectedId) {
    this.selectedKeepWorryingOption = this.keepWorryingOptions.find(({ id }) => id === selectedId);
    this.model.keep_worrying = this.selectedKeepWorryingOption.value;

    this.onEnableNext();
  }

  completeWorryMindfulness(step, property, $event) {
    const [stepProperty, stepsCollection] = ({
      worryMindfulnessStep1: ['worryMindfulnessStep1', 'worryMindfulnessSteps1'],
      worryMindfulnessStep2: ['worryMindfulnessStep2', 'worryMindfulnessSteps2']
    })[property];

    if (!stepProperty || !stepsCollection) {
      throw new Error(`Invalid property "${property}" in completeWorryMindfulnessStep`);
    }

    if (step > this[stepProperty]) {
      return;
    }
    this[stepProperty] = this[stepProperty] > step ? step : step + 1;

    if (this[stepProperty] >= this[stepsCollection].length) {
      this.onEnableNext();
    } else {
      this.onDisableNext();
    }

    safelyScrollToElement($event);
  }

  completeWorryMindfulnessStep1(step, $event) {
    return this.completeWorryMindfulness(step, 'worryMindfulnessStep1', $event);
  }

  completeWorryMindfulnessStep2(step, $event) {
    return this.completeWorryMindfulness(step, 'worryMindfulnessStep2', $event);
  }

  initStep1() {
    this.resetConfig.emit({ totalSteps: this.initialTotalSteps });
    this.showStepsProgress.emit(false);
    this.selectedWorryType = undefined;
  }

  initStep2() {
    if (this.isAnxietyFlow) {
      this.resetConfig.emit({ totalSteps: 3 });
    }
    this.showStepsProgress.emit(true);

    this.worryMindfulnessStep1 = 0;
    this.onDisableNext();
  }

  initStep3() {
    this.model.strength = 0.5;
    this.model.after_exercise_strength = 0.5;
    this.onEnableNext();
  }

  initStep4() {
    this.selectedKeepWorryingOption = undefined;
    this.onDisableNext();
  }

  initStep5() {
    this.worryMindfulnessStep2 = 0;
    this.onDisableNext();
  }

  initStep6() {
    this.onEnableNext();
  }

  get isWorryFlow() {
    return this.selectedWorryType && this.selectedWorryType.value === 'worry';
  }

  get isAnxietyFlow() {
    return this.selectedWorryType && this.selectedWorryType.value === 'anxiety';
  }

  startStressTest() {
    // this.model.exercise_id = 'stress_test'; // ?
    this.delayedMoveToNextStep(); // @todo actually we should check if the exercise was completed before move to next step

    this.store.dispatch(new navigationActions.OpenWizard({
      type: 'stresstest-ua',
      afterCompleteOptions: { skipInterstitial: true, closeModalStrategy: 'closeLastModal' }
    }));
  }

  playExercise(exercise: Exercise) {
    this.model.exercise_id = exercise.id;
    this.delayedMoveToNextStep(); // @todo actually we should check if the exercise was completed before move to next step

    if (exercise.tags.find((tag) => tag.name === 'breathe_into_anxiety')) {
      this.store.dispatch(new programActions.PlayBreatheExercise());
    } else {
      // we need the live execise to dispatch the PlayBonusExercise
      // but we use the plain exercise in html because it suits better in the `exercise-group`
      // so we need to get liveExercise back from plain exercise
      this.liveExercises$
        .pipe(take(1))
        .subscribe(liveExercises => {
          const liveExercise = liveExercises.find(ex => ex.exercise.id === exercise.id);
          this.store.dispatch(new programActions.PlayBonusExercise(
            liveExercise,
            { skipInterstitial: true, closeModalStrategy: 'closeLastModal' }
          ));
        });
    }
  }

  onSelectWorryingStrength($event) {
    this.model.strength = $event / 100;
  }

  onSelectWorryingStrengthAfterExercise($event) {
    this.model.after_exercise_strength = $event / 100;
  }

  onDone(afterCompleteOptions: programActions.AfterCompleteLessonOptions) {
    this.wizardComplete.emit();

    this.store.dispatch(new WorryToolCompleted(this.model));
  }

  delayedMoveToNextStep() {
    // delay a little bit so the user doesnt see the next step before it opens a new popup
    setTimeout(() => {
      this.nextStep.emit();
    }, 300);
  }

  ngOnDestroy() { }
}
