import { Component, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';

import { SettingsComponent } from './settings.component';
import * as navigationActions from '../../../store/session/actions/navigation.actions';
import * as subscriptionActions from '../../../store/session/actions/subscription.actions';
import { AlertsService } from '../../../services/alerts.service';
import { take } from 'rxjs/operators';
import { ClarityConfig } from '../../../config/clarity.config';
import { TranslateService } from '@ngx-translate/core';
import { ConnectivityService } from '../../../services/connectivity.service';
import { getLiveSubscription } from '../../../store/normalized/selectors/subscription.selectors';
import { SessionState } from '../../../store/session/session.reducers';
import { SubscriptionsService } from '../../../services/subscriptions/subscriptions.service';
import { slideInOut } from '@mindsciences/utils';

@Component({
  selector: 'cl-subscription-settings',
  styleUrls: ['subscription-settings.component.scss'],
  animations: [slideInOut],
  template: `
  <div [@state]="visibility"
    (@state.done)="animationDone($event)">

    <cl-subscription-details
      [subscription]="subscription$ | async"
      (openAppStore)="goToAppStore()">
    </cl-subscription-details>

    <ion-row class="action-button outsider" *ngIf="(subscription$ | async)?.canBuy">
      <ion-col class="ion-text-center" auto>
        <cl-action-button
          class="outsider"
          label="{{'common.upgrade' | translate}}"
          [canClick]="true"
          (click)="onUpgrade()">
        </cl-action-button>
      </ion-col>
    </ion-row>


    <ion-row class="action-button outsider" *ngIf="(subscription$ | async)?.canCancel">
      <ion-col class="ion-text-center" auto>
        <cl-action-button
          class="outsider"
          label="{{'subscriptions.cancel_subscription' | translate}}"
          [whiteBg]="true"
          [canClick]="true"
          (click)="onCancel()">
        </cl-action-button>
      </ion-col>
    </ion-row>
  </div>
  `
})

export class SubscriptionSettingsComponent implements SettingsComponent {
  @Output() endAnimation = new EventEmitter();
  visibility = 'visible';

  public subscription$ = this.store.select(getLiveSubscription);

  constructor(
    public config: ClarityConfig,
    private store: Store<SessionState>,
    private alerts: AlertsService,
    private translate: TranslateService,
    private connectivity: ConnectivityService,
    private subscriptionsService: SubscriptionsService
  ) {
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }

  onUpgrade() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    this.store.dispatch(new navigationActions.ShowUpgradePopup());
  }

  onCancel() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    this.subscription$
      .pipe(take(1))
      .toPromise()
      .then((subscription) => {
        if (!subscription.canCancel) {
          this.alerts.customError(null, 'common.error', 'errors.subscriptions.cannot_be_canceled');
        }

        switch (subscription.provider) {
          case 'stripe':
          case 'googleplay':
            return this.cancelSubscription();

          case 'itunes':
            return this.cancelAppStoreSubscription();
        }
      });
  }

  public goToAppStore() {
    // this no longer works
    // window.open(this.config.env.linkToItunesSubscriptions, '_system');

    // the purchase plugin has a method for this
    this.subscriptionsService.openAppStore();
  }

  private cancelAppStoreSubscription() {
    this.translate.get(
      [
        'common.dismiss',
        'subscriptions.itunes_subscription',
        'subscriptions.subscription_managed_through_itunes_app_store',
        'subscriptions.itunes_app_store'
      ])
      .pipe(take(1))
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alerts.alertController.create({
          header: translations['subscriptions.itunes_subscription'],
          message: translations['subscriptions.subscription_managed_through_itunes_app_store'],
          buttons: [
            {
              text: translations['subscriptions.itunes_app_store'],
              handler: () => {
                this.goToAppStore();
              }
            },
            {
              text: translations['common.dismiss'],
              role: 'cancel'
            }
          ]
        });

        await alert.present();
      });
  }

  private cancelSubscription() {
    this.translate.get(
      [
        'subscriptions.cancel_subscription',
        'subscriptions.cancel_confirmation_message',
        'common.no',
        'common.yes'
      ])
      .pipe(take(1))
      .toPromise()
      .then(async (translations) => {
        const alert = await this.alerts.alertController.create({
          header: translations['subscriptions.cancel_subscription'],
          message: translations['subscriptions.cancel_confirmation_message'],
          buttons: [
            {
              text: translations['common.no'],
              role: 'cancel'
            },
            {
              text: translations['common.yes'],
              handler: () => {
                this.store.dispatch(new subscriptionActions.CancelSubscription());
              }
            }
          ]
        });

        await alert.present();
      });
  }
}
