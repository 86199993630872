import { Injectable, OnDestroy } from '@angular/core';
import { ClarityFileError, FileService } from './file.service';
import { Filesystem } from '@capacitor/filesystem';
import { ClarityConfig } from '../../config/clarity.config';
import { ConnectivityService } from '../connectivity.service';
import { Store } from '@ngrx/store';
import { SessionState } from '../../store/session/session.reducers';
import { combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

import { getCurrentModule } from '../../store/session/selectors/program.selectors';
import { getAvailableBonusExercisesIds } from '../../store/normalized/selectors/exercises.selectors';
import { MediaFile } from '../../store/normalized/schemas/mediaFile.schema';
import { getModulesNumbers } from '../../store/normalized/selectors/program-bootstrap.selectors';
import {
  getDownloadedModules,
  isDownloading,
  getDownloadQueue,
  getOfflineStorageStatus, getDownloadedCoreModules, getDownloadedBonusExercises
} from '../../store/session/selectors/download.selectors';
import { EventsService } from '../events.service';
import { BrightcovePlayer, DownloadStateMediaInfo } from 'capacitor-brightcove-player';
import * as downloadActions from '../../store/session/actions/download.actions';
import { LoggerService } from '../logger.service';

@Injectable({providedIn: 'root'})
export class DownloadService implements OnDestroy {

  MAX_MODULES_TO_DOWNLOAD = 40;
  MAX_BONUS_EXERCISES_TO_DOWNLOAD = 30;

  static DEBUGGING = true;

  // can be used while running in development for debugging purposes
  static FORCE_DOWNLOAD_START = false;

  maxTries = 3;

  downloadQueue$ = this.store.select(getDownloadQueue);
  isDownloading$ = this.store.select(isDownloading);

  private offlineStorage$ = this.store.select(getOfflineStorageStatus);
  private offlineStorage: boolean;

  downloadSubscription: Subscription;

  debugMessage = (...data) => {
    if (!DownloadService.DEBUGGING) {
      return false;
    }

    console.log(...data);
  };

  constructor(
    private store: Store<SessionState>,
    public config: ClarityConfig,
    private fileService: FileService,
    private connection: ConnectivityService,
    private events: EventsService,
    private loggerService: LoggerService
  ) {}

  public initialize() {
    this.offlineStorage$.subscribe(offlineStorage => {
      this.offlineStorage = offlineStorage;
      offlineStorage ? this.startWatchingQueue() : this.stopWatchingQueue();
    });
    this.events.subscribe(this.config.events.connection + 'wifi-on', () => this.startWatchingQueue());
    this.events.subscribe(this.config.events.connection + 'wifi-off', () => this.stopWatchingQueue());
    this.events.subscribe(this.config.events.connection + 'offline', () => this.stopWatchingQueue());
    this.events.subscribe(this.config.events.logout, () => this.stopWatchingQueue());

    if(this.config.isBrightcoveDownloadEnabled()) {
      this.initializeBrightcoveDownloadEvents();
    }

    this.startWatchingQueue();

    if (this.config.onProd()) {
      DownloadService.DEBUGGING = false;
    }
  }

  private initializeBrightcoveDownloadEvents() {
    BrightcovePlayer.addListener('downloadStateChange', (downloadState: DownloadStateMediaInfo) => {
      switch (downloadState.status) {
        case 'COMPLETED':
          this.store.dispatch(new downloadActions.DownloadMediaFileComplete(downloadState.mediaId));
          break;
        case 'FAILED':
          this.deleteBrightcoveFailedDownload(downloadState.mediaId);

          this.loggerService.error(`Brightcove download error : ${JSON.stringify(downloadState)}`);
          this.store.dispatch(new downloadActions.DownloadMediaFileFail(
            new ClarityFileError({
              message: 'error downloading file', error: downloadState?.reason, errorType: ClarityFileError.errorTypes.BRIGHTCOVE_DOWNLOAD_ERROR
            })
          ));
          break;
      }
    });
  }

  public generateQueue(): Promise<any[]> {
    if(this.config.isBrightcoveEnabled() && !this.config.isBrightcoveDownloadEnabled()) {
      return Promise.resolve([]);
    }

    return combineLatest([
      this.store.select(getCurrentModule),
      this.store.select(getDownloadedModules),
      this.store.select(getDownloadedCoreModules),
      this.store.select(getDownloadedBonusExercises),
      this.store.select(getModulesNumbers),
      this.store.select(getAvailableBonusExercisesIds)
        .pipe(filter((modules) => modules.length > 0))
    ])
      .pipe(take(1))
      .toPromise()
      .then(([
        currentModule,
        downloadedModules,
        downloadedCoreModules,
        downloadedBonusExercises,
        modulesNumbers,
        exercisesIds
      ]) => {
        if (!currentModule) {
          return [];
        }

        // exit if all modules were downloaded
        if (modulesNumbers === downloadedModules) {
          return [];
        }

        const coreModulesToQueue = [];
        const bonusExercisesToQueue = [];

        // download module 0 if one exists
        if (modulesNumbers.indexOf(0) > -1 && downloadedModules.indexOf(0) === -1) {
          coreModulesToQueue.push(0);
        }

        // always download module 1
        if (downloadedModules.indexOf(1) === -1) {
          coreModulesToQueue.push(1);
        }

        // add bonus exercises
        exercisesIds.forEach((exerciseId) => {
          if (downloadedModules.indexOf('e' + exerciseId) === -1) {
            bonusExercisesToQueue.push('e' + exerciseId);
          }
        });

        // download trial modules if first module is completed
        if ((currentModule.number === 1 && currentModule.isCompleted) || currentModule.number > 1) {
          for (let i = 2; i <= Number(this.config.program.trialModules); i++) {
            if (downloadedModules.indexOf(i) === -1) {
              coreModulesToQueue.push(i);
            }
          }

          // TODO: Enable subscription check when subscriptions are implemented
          for (let j = Number(this.config.program.trialModules) + 1; j <= Number(modulesNumbers[modulesNumbers.length - 1]); j++) {
            if (downloadedModules.indexOf(j) === -1) {
              coreModulesToQueue.push(j);
            }
          }
        }

        console.log('core modules to queue');
        console.log(coreModulesToQueue);
        console.log('bonus exercises to queue');
        console.log(bonusExercisesToQueue);

        const modulesToQueue = this.limitModulesForDownload(coreModulesToQueue, downloadedCoreModules,
          bonusExercisesToQueue, downloadedBonusExercises);

        console.log(modulesToQueue);

        this.debugMessage('Generated Download Queue');

        return modulesToQueue;
      });
  }

  limitModulesForDownload(coreModulesToQueue: Array<any>, downloadedCoreModules: Array<any>,
    bonusExercisesToQueue: Array<any>, downloadedBonusExercises: Array<any>): any {
    const coreModuleDownloadLimit = Math.max(this.MAX_MODULES_TO_DOWNLOAD - downloadedCoreModules.length, 0);
    const bonusExercisesDownloadLimit = Math.max(this.MAX_BONUS_EXERCISES_TO_DOWNLOAD - downloadedBonusExercises.length, 0);

    const coreModules = coreModulesToQueue.slice(0, coreModuleDownloadLimit);
    const bonusExercises = bonusExercisesToQueue.slice(0, bonusExercisesDownloadLimit);

    return coreModules.concat(bonusExercises);
  }

  ngOnDestroy() {
    this.stopWatchingQueue();
  }

  async isDownloading() {
    this.isDownloading$
      .pipe(
        take(1)
      )
      .toPromise();
  }

  async downloadMediaFile(downloadInfo: MediaFile) {
    if (!await this.isOnWifi()) {
      this.debugMessage('Not on wifi, cannot download');

      throw new ClarityFileError({
        message: 'no wifi connection',
        errorType: ClarityFileError.errorTypes.NO_WIFI
      });
    }

    // check if permissions are granted
    try {
      const hasPermission = this.hasPermissions();

      if (!hasPermission) {
        this.debugMessage('No permissions for storage, canceling...');

        throw new ClarityFileError({
          message: 'no permissions',
          errorType: ClarityFileError.errorTypes.NO_PERMISSIONS
        });
      }
    }
    catch (error) {
      this.debugMessage('cannot check permissions, continuing anyway...');
    }

    // check if the file exists already
    try {
      await this.fileExists(downloadInfo);
      this.debugMessage('already have file, skipping download');

      // file already downloaded
      return this.config.isBrightcoveDownloadEnabled() ? downloadInfo.brightcove_key : downloadInfo.data_fingerprint;
    } catch (error) {
      this.debugMessage(error, 'file not found, will download now');
    }

    // check if there's enough space
    try {
      const hasEnoughSpace = await this.hasEnoughSpace(downloadInfo);

      if (!hasEnoughSpace) {
        this.debugMessage('not enough space');
        // TODO throw exception
        throw new ClarityFileError({
          message: 'not enough space',
          errorType: ClarityFileError.errorTypes.NOT_ENOUGH_SPACE
        });
      }
    }
    catch (error) {
      this.debugMessage('cannot check free space, continuing anyway', error);
    }

    // start downloading
    let tries = 0;
    let lastError = null;

    while (tries < this.maxTries) {
      tries++;

      try {
        this.debugMessage(`downloading (attempt #${tries})`);

        if(this.config.isBrightcoveDownloadEnabled()) {
          // it looks like Brightcome has an anti-spam mechanism that blocks all request for some minutes
          // if do a lot of requests in sequence. So by adding a 2-seconds delay for each request
          // we prevent this mechanism to quick-off
          await new Promise(resolve => setTimeout(resolve, 2000));

          await BrightcovePlayer.downloadMedia({ fileId: downloadInfo.brightcove_key });

          this.debugMessage('brightcove download started successfuly');

          // Download is asynchronous here, so we don't have acces to result right away to return.
          // This will be handled somewhere else by `BrightcovePlayer.addListener('downloadStateChange', ...)`
          return '';

        } else {
          const result = await this.fileService.downloadByUrl(
            downloadInfo.data,
            downloadInfo.data_file_name,
            downloadInfo.data_fingerprint
          );

          if (result) {
            this.debugMessage('download successful');

            // download completed successfully
            return downloadInfo.data_fingerprint;
          }
        }
      } catch (error) {
        this.debugMessage('download error', error);
        lastError = error;
      }
    }

    // max retries reached
    throw lastError;
  }

  async hasEnoughSpace(mediaInfo: MediaFile) {
    if (!this.config.isDevice) {
      this.debugMessage('Not running on device, hasEnoughSpace will always be true');

      return Promise.resolve(true);
    }

    const space = await this.fileService.getFreeDiskSpace();
    this.debugMessage('free space', space);

    if (space > +mediaInfo.data_file_size) {
      return true;
    }

    return false;
  }

  async hasPermissions() {
    if (!this.config.isDevice) {
      this.debugMessage('Not running on device, pemissions are always granted');

      return true;
    }

    if (this.config.isIos) {
      this.debugMessage('Running on iOS, permissions are always granted');

      return true;
    }

    const permissions = await Filesystem.checkPermissions();

    return permissions.publicStorage === 'granted';
  }

  async isOnWifi() {
    try {
      return this.connection.isOnWifi();
    }
    catch (error) {
      this.debugMessage('cannot check wifi, canceling...');

      throw new ClarityFileError({
        message: 'unknown error',
        errorType: ClarityFileError.errorTypes.UNKNOWN_ERROR
      });
    }
  }

  async fileExists(downloadInfo: MediaFile) {
    if(this.config.isBrightcoveDownloadEnabled()) {
      if (!(await BrightcovePlayer.isMediaAvailableLocally({fileId: downloadInfo.brightcove_key})).value) {
        throw new ClarityFileError({
          message: 'brightcove file not found',
          errorType: ClarityFileError.errorTypes.FILE_ERROR
        });
      }
    } else {
      return this.fileService.checkFileSize(
        downloadInfo.data,
        downloadInfo.data_file_size
      );
    }
  }

  stopDownloads() {
    this.fileService.abortAllDownloads();
  }

  public startWatchingQueue() {
    // Prevent downloads if user disable option in settings
    if(!this.offlineStorage) {
      return;
    }

    // only start if not already watching
    if (this.downloadSubscription && !this.downloadSubscription.closed) {
      return false;
    }

    this.downloadSubscription = this.downloadQueue$
      .pipe(
        switchMap((downloadQueue) => this.isDownloading$
          .pipe(
            filter((downloading) => !downloading),
            distinctUntilChanged(),
            map(() => downloadQueue)
          ))
      )
      .subscribe((queue) => {
        if (queue.length <= 0) {
          return false;
        }

        this.debugMessage('Download queue found, starting download...');

        if ((!this.config.isDevice || !this.config.onProd()) && !DownloadService.FORCE_DOWNLOAD_START) {
          return console.log('Not running on device or env not production, downloading will not be started');
        }

        this.downloadStart();
      });
  }

  public downloadStart() {
    this.store.dispatch(new downloadActions.DownloadStart());
  }

  public stopWatchingQueue() {
    this.downloadSubscription && this.downloadSubscription.unsubscribe();
    this.stopDownloads();
  }

  private async deleteBrightcoveFailedDownload(fileId: string) {
    try {
      // In some cases (when the download is canceled for example) the media is automatically deleted by the plugin
      // That's why we check that we have a downloaded file before trying to delete it
      const hasFileToDelete = (await BrightcovePlayer.getDownloadedMediasState()).medias.some(media => media.mediaId === fileId);
      hasFileToDelete && BrightcovePlayer.deleteDownloadedMedia({ fileId });

    } catch(error) {
      this.loggerService.error('Error deleting downloaded media', error);
      throw error;
    }
  }
}
