import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { LiveModule, LiveWeek } from '../../store/session/selectors/program.selectors';
import { ClarityConfig } from '../../config/clarity.config';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-todays-lesson-card',
  styleUrls: ['todays-lesson-card.component.scss'],
  template: `
    <div class="today-lesson-header ion-text-center">
      <span
        [ngClass]="{'heading': !config.usingDesktopOnWeb, 'heading-dashboard': config.usingDesktopOnWeb}"
        class="ion-text-uppercase"
        *ngIf="module?.type !== 'extend'">
          {{'dashboard.todays_module' | translate}}
      </span>
      <span
        [ngClass]="{'heading': !config.usingDesktopOnWeb, 'heading-dashboard': config.usingDesktopOnWeb}"
        class="ion-text-uppercase" *ngIf="module?.type === 'extend'">
          {{'dashboard.next_step' | translate}}
      </span>
    </div>

    <div class="lesson-info-wrapper">
      <h2 class="lesson-title" *ngIf="loading">
        <ion-spinner color="white"></ion-spinner>
      </h2>
      <h2 class="lesson-title" *ngIf="!loading && module?.type !== 'extend'">
        {{module?.record.title}}
      </h2>

      <h2 class="lesson-title" *ngIf="!loading && module?.type === 'extend'">
        You completed all modules!
      </h2>

      <h4 class="lesson-number ion-text-uppercase" *ngIf="!loading && module?.type !== 'extend'">
        <span *ngIf="week?.type === 'program' && config.program.programCode !== 'ctq'">
          {{'dashboard.core_training' | translate }}
          <br/>
          {{'dashboard.week' | translate }} {{week.number}}
          :
          {{'dashboard.module' | translate}} {{module?.number}}
        </span>

        <span *ngIf="week?.type === 'program' && config.program.programCode === 'ctq'">
          {{'dashboard.week' | translate }} {{week.number}}
          :
          {{'dashboard.lesson' | translate}} {{module?.number}}
        </span>

        <span *ngIf="week?.type === 'custom'">
          {{'dashboard.custom_week' | translate}}
          <br/>
          {{'dashboard.module' | translate}} {{module?.positionInWeek}}
        </span>

        <span *ngIf="week?.type === 'theme' && week?.record !== null">
          {{week.record.title}}
          <br/>
          {{'dashboard.module' | translate}} {{module?.positionInWeek}}
        </span>
      </h4>
    </div>

    <div class="button-wrapper">
      <div class="left-arrow-holder" (click)="slidePrev()" *ngIf="module && !module.isFirst">
        <ion-icon name="arrow-back"></ion-icon>
      </div>

      <div class="right-arrow-holder" (click)="slideNext()" *ngIf="module && !module.isLast">
        <ion-icon name="arrow-forward"></ion-icon>
      </div>

      <ion-button class="play-btn" slot="start" color="light" (click)="onBegin(module)" tappable
              *ngIf="!loading && !module?.isAvailable">
        <ion-icon class="icon-button" name="lock-closed"></ion-icon>
        <span class="text">{{'dashboard.locked' | translate}}</span>
      </ion-button>

      <ion-button class="play-btn" slot="start" color="light" id="begin-lesson-btn" (click)="onBegin(module)" tappable
              *ngIf="!loading && module?.isAvailable && !module?.isCompleted && module?.type !== 'extend'">

        <ng-container *ngIf="module.isInProgress">
          <ion-icon class="icon-button" src="/assets/icon/partially_completed.svg"></ion-icon>
          <span class="text">{{'dashboard.in_progress' | translate}}</span>
        </ng-container>

        <ng-container *ngIf="!module.isInProgress">
          <ion-icon class="icon-button play" name="play"></ion-icon>
          <span class="text">{{'dashboard.begin' | translate}}</span>
        </ng-container>
      </ion-button>

      <ion-button class="play-btn" slot="start" color="light" (click)="onBegin(module)" tappable
              *ngIf="!loading && module?.isAvailable && module?.isCompleted && module?.type !== 'extend'">
        <ion-icon class="icon-button" name="checkmark-circle"></ion-icon>
        <span class="text">{{'dashboard.completed' | translate}}</span>
      </ion-button>

      <ion-button class="play-btn" slot="start" color="light" (click)="startNewWeek()" tappable
              *ngIf="!loading && module?.type === 'extend'">
        <ion-icon class="icon-button reverted" src="assets/imgs/icon-my-journey.svg"></ion-icon>
        <span class="text">{{'dashboard.start_next_week' | translate}}</span>
      </ion-button>
    </div>
  `
})
export class TodaysLessonCardComponent {
  @HostBinding('class.desktop') isDesktop = this.config.usingDesktopOnWeb;

  @Input() week: LiveWeek;
  @Input() module: LiveModule;

  @Output() begin = new EventEmitter<LiveModule>();
  @Output() newWeek = new EventEmitter<void>();

  @Output() next = new EventEmitter<void>();
  @Output() prev = new EventEmitter<void>();

  constructor(public config: ClarityConfig) {}

  get loading() {
    return !this.module?.record;
  }

  onBegin(module: LiveModule) {
    this.begin.emit(module);
  }

  startNewWeek() {
    this.newWeek.emit();
  }

  slidePrev() {
    this.prev.emit();
  }

  slideNext() {
    this.next.emit();
  }

}
