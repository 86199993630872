/* eslint-disable no-shadow, @typescript-eslint/no-shadow */
import * as conHandlerActions from '../actions/connection-handler.actions';
import { PostponedChange } from '../../../providers/http/connection-handler.provider';

export interface ConnectionHandlerState {
  processing: boolean;
  idTranslator: any;
  sequentialChanges: PostponedChange[];
  stashedChanges: PostponedChange[];
  nonSequentialChanges: PostponedChange[];
  currentAction: PostponedChange;
  processingNonSequentialActions: boolean;
}

export const initialConnectionHandlerState: ConnectionHandlerState = {
  processing: false,
  idTranslator: {},
  sequentialChanges: [],
  stashedChanges: [],
  currentAction: null,
  nonSequentialChanges: [],
  processingNonSequentialActions: false
};

function getFirstActionFromQueue(state: ConnectionHandlerState) {
  const { sequentialChanges } = state;
  if (sequentialChanges.length === 0) {
    return null;
  }
  const currentAction = sequentialChanges[0];

  return currentAction;
}

function updateIdTranslator(
  idTranslator: any,
  currentAction: PostponedChange,
  payload: conHandlerActions.SuccessPayload
) {
  if (!payload) {
    return idTranslator;
  }
  if (currentAction.method === 'post') {
    const { requestHandlers } = currentAction;
    const { initialId, finalId } = payload;
    if (initialId !== finalId) {
      let endpointMap = idTranslator[requestHandlers.endpoint];
      if (!endpointMap) {
        endpointMap = {};
        idTranslator[requestHandlers.endpoint] = endpointMap;
      }
      endpointMap[initialId] = finalId;
    }
  }

  return idTranslator;
}

const sequentialEndpoints = new Set([
  '/user_reminders',
  '/user_goals'
]);

export function connectionHandlerReducer(
  state = initialConnectionHandlerState,
  action: conHandlerActions.ConnectionHandlerActions): ConnectionHandlerState {
  switch (action.type) {
    case conHandlerActions.QUEUE_ACTION: {
      const { stashedChanges = [], sequentialChanges = [], nonSequentialChanges = [] } = state;
      if (stashedChanges.length > 0) {
        stashedChanges.forEach((change) => {
          if (sequentialEndpoints.has(change.endpoint)) {
            // sequentialChanges.push(change);
          } else {
            nonSequentialChanges.push(change);
          }
        });
      }

      if (action.payload.sequential) {
        const newSequentialChanges = [...(sequentialChanges || []), action.payload];

        return {
          ...state,
          stashedChanges: [],
          nonSequentialChanges,
          sequentialChanges: newSequentialChanges
        };
      } else {
        const newNonSequentialChanges = [...(nonSequentialChanges || []), action.payload];

        return {
          ...state,
          stashedChanges: [],
          sequentialChanges,
          nonSequentialChanges: newNonSequentialChanges
        };
      }
    }
    case conHandlerActions.CONNECTION_HANDLER_NON_SEQ_HANDLER_START: {
      const { stashedChanges = [], sequentialChanges = [], nonSequentialChanges = [] } = state;

      if (stashedChanges.length > 0) {
        stashedChanges.forEach((change) => {
          if (sequentialEndpoints.has(change.endpoint)) {
            // sequentialChanges.push(change);
          } else {
            nonSequentialChanges.push(change);
          }
        });
      }

      return {
        ...state,
        sequentialChanges,
        nonSequentialChanges,
        stashedChanges: [],
        processing: true
      };
    }
    case conHandlerActions.CONNECTION_HANDLER_SEQ_HANDLER_START: {
      const { stashedChanges = [], sequentialChanges = [], nonSequentialChanges = [] } = state;

      if (stashedChanges.length > 0) {
        stashedChanges.forEach((change) => {
          if (sequentialEndpoints.has(change.endpoint)) {
            // sequentialChanges.push(change);
          } else {
            nonSequentialChanges.push(change);
          }
        });
      }

      return {
        ...state,
        sequentialChanges,
        nonSequentialChanges,
        stashedChanges: []
      };
    }
    case conHandlerActions.CONNECTION_HANDLER_PROCESS_ACTION: {
      const currentAction = getFirstActionFromQueue(state);

      return {
        ...state,
        currentAction
      };
    }
    case conHandlerActions.CONNECTION_HANDLER_PROCESS_ACTION_COMPLETE: {
      const idTranslator = updateIdTranslator(
        state.idTranslator,
        action.payload.action,
        action.payload.idDiff
      );

      const sequentialChanges = state.sequentialChanges.filter(ac => ac.uuid !== state.currentAction.uuid);

      return {
        ...state,
        sequentialChanges,
        currentAction: null,
        processing: false,
        idTranslator
      };
    }
    case conHandlerActions.CONNECTION_HANDLER_PROCESS_ACTION_FAIL: {
      const stashedChange = state.sequentialChanges.find(ac => ac.uuid === state.currentAction.uuid);
      if (stashedChange) {
        stashedChange.tries++;
      }

      return {
        ...state,
        sequentialChanges: [...state.sequentialChanges],
        currentAction: null,
        processing: false
      };
    }
    case conHandlerActions.CONNECTION_HANDLER_PROCESS_NON_SEQ_ACTIONS: {
      return {
        ...state,
        processingNonSequentialActions: true
      };
    }
    case conHandlerActions.CONNECTION_HANDLER_PROCESS_NON_SEQ_ACTIONS_COMPLETE: {
      const { nonSequentialChanges } = state;
      const actionsResult = action.payload;
      const completedActionsUUIDs = actionsResult
        .filter(action => !action.failed)
        .map(action => action.action.uuid);
      const goodActionsUUIDs = new Set(completedActionsUUIDs);
      const newNonSequentialChanges = nonSequentialChanges.filter((action) => !goodActionsUUIDs.has(action.uuid));

      return {
        ...state,
        nonSequentialChanges: newNonSequentialChanges,
        processingNonSequentialActions: false
      };
    }
    case conHandlerActions.CONNECTION_HANDLER_QUEUE_CLEARED: {
      return {
        ...state,
        idTranslator: {}
      };
    }

  }

  return state;
}

export const currentAction = (state: ConnectionHandlerState) => state.currentAction;
export const processing = (state: ConnectionHandlerState) => state.processing;
export const sequentialChanges = (state: ConnectionHandlerState) => state.sequentialChanges;
export const translationMap = (state: ConnectionHandlerState) => state.idTranslator;
