import { NgModule } from '@angular/core';

import { AppComponent } from '@mindsciences/clarity-core/app/app.component';
import { APP_MODULE_IMPORTS } from '@mindsciences/clarity-core';
import { APP_MODULE_PROVIDERS } from '@mindsciences/clarity-core/app/app.module';
import { CoreModule } from './core.module';

@NgModule({
  imports: [
    ...APP_MODULE_IMPORTS,
    CoreModule
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    ...APP_MODULE_PROVIDERS
  ]
})

export class AppModule {
}
