import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ClarityConfig } from '../../../config/clarity.config';
import * as fromAuth from '../../../store/sensitive/selectors/auth.selectors';
import { Store } from '@ngrx/store';
import { SessionState } from '../../../store/session';

@Injectable({providedIn: 'root'})
export class ApiUrlInterceptor implements HttpInterceptor {

  private iridiumHost: string = null;

  constructor(
    public config: ClarityConfig,
    private store: Store<SessionState>
  ) {
    this.store.select(fromAuth.getIridiumHost)
      .subscribe((host) => {
        if (!host) {
          return false;
        }

        this.iridiumHost = host.replace(/\/+$/, '');
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const {
      apiUseSsl,
      apiEndpointPrefix,

      communityApiHost,
      communityApiEndpointPrefix,

      iridiumApiUseSsl,
      iridiumApiEndpointPrefix
    } = this.config.env;

    const apiHost = this.config.apiHost;
    const isCommunityCall = request.url.startsWith('{community}');
    const isIridiumCall = request.url.startsWith('{iridium}');
    const isBrightcoveCall = request.url.startsWith('https://bc');
    const isAwsCall = request.url.endsWith('on.aws/');

    let path, requestUrl;
    const protocol = apiUseSsl ? 'https://' : 'http://';

    if (isCommunityCall) {
      path = `${communityApiHost}${communityApiEndpointPrefix}`;
      requestUrl = request.url.replace(/\{community\}/, '');
    }
    else if (isIridiumCall) {
      path = `${this.iridiumHost}${iridiumApiEndpointPrefix}`;
      requestUrl = request.url.replace(/\{iridium\}/, '');
    }
    else {
      path = `${apiHost}${apiEndpointPrefix}`;
      requestUrl = request.url;
    }

    const url = `${isIridiumCall ? '' : protocol}${path}${requestUrl}`;

    // TODO: Figure out a better way to avoid this scenario
    // add host to url if it doesn't start with a dot (local request i.e. ngx-translate)
    if (request.url[0] === '.') {
      return next.handle(request);
    }

    // update url
    request = request.clone({
      url: (isBrightcoveCall || isAwsCall) ? request.url : url
    });

    return next.handle(request);
  }
}
