import jwt_decode from 'jwt-decode';

export interface JWTToken {
  externalId: string; // '123456'; this is the clarion's userId
  type: 'user';
  exp: number; // 1516239022; utc timestamp for expiration in seconds
}

export function isJWTTokenValid(jwtToken: string) {
  let decoded: JWTToken;

  try {
    decoded = jwt_decode(jwtToken);
  } catch {
    return false;
  }

  if (!decoded.exp) {
    return false;
  }

  const utcTimeInSeconds = Math.floor((new Date()).getTime() / 1000);

  // 10 second buffer to prevent errors when the token expires during the network request
  if (decoded.exp < utcTimeInSeconds - 10) {
    return false;
  }

  return true;
}
