<cl-waves-background wizard-bg bg-position-bottom>
  <ion-content>
    <ion-header>
      <cl-header-nav-buttons
        class="page-header"
        color="primary"
        (closeClick)="done()"
        [backButton]="false"
        [title]="'welcome_tour.sliders.video.text' | translate : {program: config.program.name}">
      </cl-header-nav-buttons>
    </ion-header>

    <div class="exercise-wrapper lateral-padding">
      <div class="play-wrapper">
        <cl-play-exercise
          [showTitle]="false"
          [exercise]="exercise"
          [playerHolder]="false"
          [inlineSrc]="true"
          [autoplay]="true">
        </cl-play-exercise>
      </div>
    </div>
  </ion-content>

  <ion-footer>
    <div class="lateral-padding">
      <ion-row>
        <ion-col size="6" offset="3" class="ion-text-center">
          <cl-action-button
            label="{{'common.done' | translate}}"
            [canClick]="true"
            (action)="done()">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </div>
  </ion-footer>
</cl-waves-background>
