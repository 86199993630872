import { AfterViewInit, ElementRef, ViewChild, Component, Input, OnDestroy, HostBinding } from '@angular/core';
import { Chart } from 'chart.js';
import moment from 'moment';
import Color from 'color';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { CheckinAnxietyByDay } from '../store/normalized/schemas/checkin-anxiety-by-day.schema';
import * as navigationActions from '../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { getCurrentUserStartDate } from '../store/normalized/selectors/user.selectors';
import { getCheckinAnxietyByDays } from '../store/normalized/selectors/checkin-anxiety-by-day.selectors';
import { SessionState } from '../store/session/session.reducers';
import { ClarityConfig } from '../config/clarity.config';

@Component({
  selector: 'cl-daily-graph',
  styleUrls: ['daily-graph.component.scss'],
  template: `
  <div class="graph-container">
    <div class="help-container">
      <span class="left">{{ 'daily_graph.anxiety' | translate }}</span>
      <span class="right" (click)="showHelp()" tappable>
        {{ 'daily_graph.what_is_this' | translate }}
      </span>
    </div>
    <div [hidden]="hasData" class="graph-overlay">
      {{ 'daily_graph.no_data' | translate }}
    </div>
    <div [hidden]="!chart" style="width:100%; height:300px;" [ngClass]="{'graph-blurred': !hasData}" class="graph-canvas">
      <canvas #AG >{{chart}}</canvas>
    </div>
    <div *ngIf="controls" class="controls-wrapper">
      <ion-row class="control-buttons-row">
        <ion-col class="ion-text-right">
          <ion-button size="small" expand="block" color="action"
            [disabled]="!hasPreviousWeek"
            (click)="toPreviousWeek()">
            <ion-icon name='arrow-back'></ion-icon>
            {{ 'daily_graph.prev' | translate | uppercase }}
          </ion-button>
        </ion-col>

        <ion-col class="ion-text-left">
          <ion-button size="small" expand="block" color="action"
            [disabled]="!hasNextWeek"
            (click)="toNextWeek()">
            {{ 'daily_graph.next' | translate | uppercase }}
            <ion-icon name='arrow-forward'></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>
  `
})

export class DailyGraphComponent implements AfterViewInit, OnDestroy {

  constructor(
    private store: Store<SessionState>,
    private translate: TranslateService,
    private config: ClarityConfig
  ) { }

  get hasData() {
    return this.checkinAnxietyByDays.length > 0;
  }

  get hasNextWeek() {
    const currentToday = this.today.clone();
    const realToday = moment()
      .startOf('day');
    if (currentToday.add(1, 'week') > realToday) {
      return false;
    }

    return true;
  }

  get hasPreviousWeek() {
    const currentToday = this.today.clone();
    const startDateClone = this.startDate.clone();
    if (currentToday.subtract(1, 'week') < startDateClone.subtract(1, 'week')) {
      return false;
    }

    return true;
  }

  @Input() controls = true;
  chart: Chart = null;

  readonly chunkSize = 7;

  ctx: any = null;
  checkinAnxietyByDays: CheckinAnxietyByDay[] = [];
  currChunk = 0;
  startDate: moment.Moment = moment('2010-01-01')
    .startOf('day');

  today: moment.Moment = moment()
    .startOf('day');

  private initialDay$ = this.store.select(getCurrentUserStartDate);

  dataSubscription: Subscription = undefined;
  translationSubscription: Subscription = undefined;

  private checkinAnxietyByDays$: Observable<CheckinAnxietyByDay[]> = this.store.select(getCheckinAnxietyByDays);

  colorForDataSet = {
    morning: 'rgb(146, 220, 246)',
    day: '#dca4d9',
    evening: '#fda533'
  };

  chartLabels = {};

  chartLabelsNoTrans = {
    morning: 'chart.morning',
    day: 'chart.day',
    evening: 'chart.evening'
  };

  pathForDataSet = {
    morning: 0,
    day: 1,
    evening: 2
  };

  @ViewChild('AG', { static: true }) chartElement: ElementRef;

  showHelp() {
    this.store.dispatch(new navigationActions.ShowOnboarding({
      page: 'OnboardingPage',
      params: {
        type: 'dailyGraphWhatIsThis'
      }
    }));
  }

  transparentize(color, opacity) {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity;

    return Color(color)
      .alpha(alpha)
      .rgb()
      .string();
  }

  toPreviousWeek() {
    if (this.hasPreviousWeek) {
      this.today.subtract(1, 'week');
      this.updateData();
    }
  }

  toNextWeek() {
    if (this.hasNextWeek) {
      this.today.add(1, 'week');
      this.updateData();
    }
  }

  gradientBackgroundGenerator(color) {
    return Color(color)
      .alpha(0.6)
      .rgb()
      .string();
  }

  generateLabels() {
    const WEEKDAY_FORMAT = 'MM/DD';
    const today = this.today.clone()
      .subtract(6, 'days');
    const labels = [''];
    for (let i = 0; i < 7; i++) {
      labels.push(today.format(WEEKDAY_FORMAT));
      today.add(1, 'days');

    }
    labels.push('');

    return labels;
  }

  generateLabelsColor(chart) {
    chart.legend.afterFit = () => {
      chart.legend.lineWidths = chart.legend.lineWidths.map(() => chart.width - (chart.width * .15));
    };

    const data = chart.data;
    if (data.labels.length && data.datasets.length) {
      return data.datasets.map((dataset, i) => ({
        text: dataset.label,
        lineWidth: 0,
        fillStyle: dataset.pointBackgroundColor,
        datasetIndex: i
      }));
    }

    return [];
  }

  fillWith(filler, array: any[]) {
    for (let i = array.length; i < 2; i++) {
      array.unshift(filler);
    }

    return array;
  }

  getDataSet(key, data) {
    const color = this.colorForDataSet[key];

    return {
      data: this.fillWith(0, this.getData(key, data)),
      label: this.chartLabels[key],
      backgroundColor: this.gradientBackgroundGenerator(color),
      pointBackgroundColor: color,
      borderColor: this.transparentize(color, 1),
      pointRadius: 0,
      borderWidth: 0
    };
  }

  getData(key, vanillaKeyData: CheckinAnxietyByDay[]= []) {
    const keyToTag = {
      morning: 'morning_motivation',
      day: null,
      evening: 'night_reflection'
    };
    const keyData = vanillaKeyData.map((dataPoint: CheckinAnxietyByDay) => {

      const { date, checkins } = dataPoint;
      const response = {};
      const checkinsForKey = checkins.filter((checkin) => checkin.tag === keyToTag[key]);
      let score = 0;
      if (checkinsForKey.length !== 0) {
        checkinsForKey.forEach(checkin => {
          score += checkin.anxiety;
        });

        score /= checkinsForKey.length;
      }
      response[date] = score;

      return response;
    });
    const dataDict = {};
    keyData.forEach(d => Object.assign(dataDict, d));
    const DATE_FORMAT = 'YYYY-MM-DD';
    const today = this.today.clone()
      .subtract(7, 'days');
    const data = [];
    for (let i = 0; i < 8; i++) {
      const date_key = today.format(DATE_FORMAT);
      let value = dataDict[date_key];
      if (!value) {
        value = 0;
      }
      data.push((+value) * 10);
      today.add(1, 'days');
    }
    // data.reverse();
    data.push(0);

    return data;
  }

  updateData(data?: CheckinAnxietyByDay[]) {
    if (!this.ctx || !this.chart) {
      return;
    }
    if (data) {
      this.checkinAnxietyByDays = data.sort((a, b) => moment(a.date)
        .diff(moment(b.date)));
    }
    this.chart.data.datasets = [
      this.getDataSet('morning', this.checkinAnxietyByDays),
      this.getDataSet('day', this.checkinAnxietyByDays),
      this.getDataSet('evening', this.checkinAnxietyByDays)
    ];
    this.chart.data.labels = this.generateLabels();
    this.chart.update();
  }

  generateEmptyDataSets(label) {
    return this.getDataSet(label, []);
  }

  getFreshData() {
    this.dataSubscription = combineLatest([
      this.checkinAnxietyByDays$
    ])
      .subscribe(([data]) => {
        this.updateData(data);
      });
  }

  ngOnDestroy() {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
  }

  signToTranslations() {
    this.translationSubscription = this.translate.onLangChange
      .subscribe(() => {
        this.translateLabels()
          .then(() => this.updateData());
      });
  }

  translateLabels() {
    return Promise.all(
      Object.keys(this.chartLabelsNoTrans)
        .map(key => {
          this.translate.get(this.chartLabelsNoTrans[key])
            .toPromise()
            .then((translation) => {
              this.chartLabels[key] = translation;
            });
        })
    );
  }

  ngAfterViewInit() {
    this.initialDay$
      .pipe(
        take(1)
      )
      .subscribe((startDate) => {
        this.startDate = moment(startDate)
          .startOf('day');
      });

    const labels = this.generateLabels();
    this.ctx = this.chartElement.nativeElement.getContext('2d');
    const config = {
      type: 'bar',
      data: {
        labels,
        datasets: [
          this.generateEmptyDataSets('morning'),
          this.generateEmptyDataSets('day'),
          this.generateEmptyDataSets('evening')
        ]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            generateLabels: this.generateLabelsColor
          }
        },
        responsive: true,
        title: {
          display: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            stacked: true,
            display: true,
            gridLines: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              offsetGridLines: true
            },
            scaleLabel: {
              display: false
            }
          }],
          yAxes: [{
            // display: false,
            stacked: true,
            gridLines: {
              drawBorder: false
            },
            ticks: {
              beginAtZero: true,
              callback(value) { if (Number.isInteger(value)) { return value; } }
            },
            scaleLabel: {
              display: false,
              labelString: 'Value'
            }
          }]
        }
      }
    };

    this.signToTranslations();
    this.translateLabels()
      .then(() => {
        this.chart = new Chart(this.ctx, config);
        this.getFreshData();
      });
  }
}
