import { schema } from 'normalizr';
import { IntegrationSource } from '../../session/models/integration.model';

export interface MinutesActivity {
  id?: number;
  pending?: boolean;
  localState?: {
    action: 'add' | 'delete';
    status: 'pending' | 'offline' | 'failed';
  };
  minutes: number;
  source: IntegrationSource;
  activity_at: string;
  comment: string;
}

export const minutesActivitySchema = new schema.Entity<MinutesActivity>('minutesActivities');
