import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { WizardPage } from './wizard';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared';
import { CheckinWizardComponent } from './components/checkin/checkin-wizard.component';
import { StressTestWizardComponent } from './components/stress-test/stress-test-wizard.component';
import { StressTestUAWizardComponent } from './components/stress-test/stress-test-ua-wizard.component';
import { StressMeterWizardComponent } from './components/stress-meter/stress-meter-wizard.component';
import { WantOMeterERNWizardComponent } from './components/stress-meter/want-o-meter-ern-wizard.component';
import { WantOMeterCTQWizardComponent } from './components/stress-meter/want-o-meter-ctq-wizard.component';
import { WizardComponent } from './components/wizard.component';
import { NightReflectionComponent } from './components/night-reflection/night-reflection.component';
import { MorningMotivationComponent } from './components/morning-motivation/morning-motivation.component';
import { ExpandedOptionHeaderComponent } from './components/expanded-option-header/expanded-option-header.component';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DoubleBounceSpinnerComponent } from 'src/app/components/spinners/double-bounce.component';
import { WorryToolWizardComponent } from './components/worry-tool/worry-tool-wizard.component';

@NgModule({
  declarations: [
    WizardPage,
    WizardComponent,
    CheckinWizardComponent,
    StressTestWizardComponent,
    StressTestUAWizardComponent,
    StressMeterWizardComponent,
    WantOMeterCTQWizardComponent,
    WorryToolWizardComponent,
    WantOMeterERNWizardComponent,
    NightReflectionComponent,
    MorningMotivationComponent,
    ExpandedOptionHeaderComponent,
    DoubleBounceSpinnerComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: WizardPage }]),
    TranslateModule.forChild(translateConfig),
    SharedModule
  ]
})
export class WizardPageModule {}
