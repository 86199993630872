import { schema } from 'normalizr';
import { Tag, tagSchema } from './tag.schema';
import { MediaFile, mediaFileSchema } from './mediaFile.schema';
import { CoverImage, coverImageSchema } from './coverImage.schema';

export class Exercise {
  id: number;
  program_id: number;
  exercise_category_id: number;
  category_name: string;
  action: string;
  kind: string;
  title: string;
  length: number;
  caption: string;
  special_caption: string;
  tags: Tag[];
  media_file: MediaFile;
  alternative_media_files: MediaFile[];
  cover_image: CoverImage;
  isLocked: boolean;
  isNew: boolean;
  exercise: Exercise;
}

export class BonusExercise {
  id: number;
  position: number;
  module: number;
  exercise: Exercise;
}

export const exerciseSchema = new schema.Entity<Exercise>(
  'exercises',
  {
    tags: [tagSchema],
    media_file: mediaFileSchema,
    cover_image: coverImageSchema,
    alternative_media_files: [mediaFileSchema]
  }
);

export const bonusExerciseSchema = new schema.Entity<BonusExercise>('bonusExercises', {exercise: exerciseSchema});
