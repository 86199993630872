import * as fromAccount from './reducers/account.reducer';
import * as fromTools from './reducers/tools.reducer';
import * as fromDownload from './reducers/download.reducer';
import * as fromConnectionHandler from './reducers/connection-handler.reducer';
import * as fromProgram from './reducers/program.reducer';
import * as fromSync from './reducers/sync.reducer';
import * as fromExercises from './reducers/exercises.reducer';
import * as fromUserProfile from './reducers/user-profile.reducer';
import * as fromUserFavs from './reducers/user-favorites.reducer';
import * as fromUserProgram from './reducers/user-program.reducer';
import * as fromUserGoals from './reducers/user-goals.reducer';
import * as fromUserActivities from './reducers/user-activities.reducer';
import * as fromUserReminders from './reducers/user-reminders.reducer';
import * as fromCigTraker from './reducers/cig-tracker.reducer';
import * as fromOfflineQueue from './reducers/offline-queue.reducer';
import * as fromSocial from './reducers/social.reducer';
import * as fromSubscription from './reducers/subscription.reducer';
import * as fromPost from './reducers/post.reducer';
import * as fromNotifications from './reducers/notifications.reducer';
import * as fromNrt from './reducers/nrt.reducer';
import * as fromMyCoach from './reducers/my-coach.reducer';
import * as fromIridium from './reducers/iridium.reducer';
import * as fromIntegrations from './reducers/integrations.reducer';
import * as fromWeightActivities from './reducers/weight-activities.reducer';
import * as fromHealthDevices from './reducers/health-devices.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface SessionState {
  account: fromAccount.AccountState;
  connectionHandler: fromConnectionHandler.ConnectionHandlerState;
  download: fromDownload.DownloadState;
  exercises: fromExercises.ExercisesState;
  now: fromTools.ToolsNowState; // this property should be called toolsNow, but renaming it would break current users
  program: fromProgram.ProgramState;
  offlineQueue: fromOfflineQueue.OfflineQueueState;
  userActivities: fromUserActivities.UserActivitiesState;
  userGoals: fromUserGoals.UserGoalsState;
  userFavs: fromUserFavs.UserFavoritesState;
  userProgram: fromUserProgram.UserProgramState;
  userProfile: fromUserProfile.UserProfileState;
  userReminders: fromUserReminders.UserRemindersState;
  sync: fromSync.SyncState;
  cigTraker: fromCigTraker.CigTrakerState;
  social: fromSocial.SocialState;
  subscription: fromSubscription.SubscriptionState;
  post: fromPost.PostState;
  notifications: fromNotifications.NotificationState;
  nrt: fromNrt.NrtState;
  myCoach: fromMyCoach.MyCoachState;
  iridium: fromIridium.IridiumState;
  integrations: fromIntegrations.IntegrationsState;
  weightActivities: fromWeightActivities.WeightActivitiesState;
  healthDevices: fromHealthDevices.HealthDevicesState;
}

export const sessionReducers: ActionReducerMap<SessionState> = {
  account: fromAccount.accountReducer,
  connectionHandler: fromConnectionHandler.connectionHandlerReducer,
  download: fromDownload.downloadReducer,
  exercises: fromExercises.exercisesReducer,
  now: fromTools.toolsReducer,
  program: fromProgram.programReducer,
  offlineQueue: fromOfflineQueue.offlineQueueReducer,
  userActivities: fromUserActivities.userActivitiesReducer,
  userGoals: fromUserGoals.userGoalsReducer,
  userFavs: fromUserFavs.userFavoritesReducer,
  userProgram: fromUserProgram.userProgramReducer,
  userProfile: fromUserProfile.userProfileReducer,
  userReminders: fromUserReminders.userRemindersReducer,
  sync: fromSync.syncReducer,
  cigTraker: fromCigTraker.cigTrackerReducer,
  social: fromSocial.socialReducer,
  subscription: fromSubscription.subscriptionReducer,
  post: fromPost.postReducer,
  notifications: fromNotifications.notificationReducer,
  nrt: fromNrt.nrtReducer,
  myCoach: fromMyCoach.myCoachReducer,
  iridium: fromIridium.iridiumReducer,
  integrations: fromIntegrations.integrationsReducer, // @todo maybe rename to healthIntegrations
  weightActivities: fromWeightActivities.weightActivitiesReducer, // @todo maybe rename & scope it to healthMeasures
  healthDevices: fromHealthDevices.healthDevicesReducer
};
