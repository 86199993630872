import { Injectable } from '@angular/core';
import { RateApp } from 'capacitor-rate-app';

import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { getFlagsState } from '../store';
import { State } from '../store/state.reducer';

@Injectable({providedIn: 'root'})
export class RateService {

  constructor(
    public store: Store<State>
  ) {

  }

  rate() {
    this.store.select(getFlagsState)
      .pipe(take(1))
      .subscribe((flags) => {
        // this is only valid for users that already interacted with the old rate plugin
        if (flags.ratingCompleted || flags.ratingRejected) {
          return false;
        }

        RateApp.requestReview();
      });
  }

}
