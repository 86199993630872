import { Action } from '@ngrx/store';

export const START_TRACKING_ANALYTICS = '[tracking] Analytics Start';

export class StartTrackingAnalytics implements Action {
  readonly type = START_TRACKING_ANALYTICS;
}

export type TrackingActions =
  | StartTrackingAnalytics;
