/**
 * @author WoltersKluwerPL <https://github.com/WoltersKluwerPL/ng-spin-kit>
 * @license MIT
 * @overview
 *
 * SpinKit (http://tobiasahlin.com/spinkit/) spinners for Angular.
 *
 * Copied directly from ng-spin-kit npm package because it looses support to angular 9,
 * and we only use one spinner of it
 */

import { Component } from '@angular/core';
import { SpinnerComponent, spinnerTemplate } from './spinner.component';

@Component({
  selector: 'cl-double-bounce',
  styles: [`
    .double-bounce-spinner {
      position: relative;
      margin: 25px auto;
      width: 40px;
      height: 40px;
    }

    .double-bounce1,
    .double-bounce2 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0.6;

      -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
      animation: sk-bounce 2.0s infinite ease-in-out;
    }

    .double-bounce2 {
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s;
    }

    @-webkit-keyframes sk-bounce {
      0%, 100% {
        -webkit-transform: scale(0.0);
      }
      50% {
        -webkit-transform: scale(1.0);
      }
    }

    @keyframes sk-bounce {
      0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
      }
      50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
      }
    }
  `],
  template: spinnerTemplate
})

export class DoubleBounceSpinnerComponent extends SpinnerComponent {
  public baseClass = 'double-bounce-spinner';
  public childClass = 'double-bounce';
  public numItems = 2;
}
