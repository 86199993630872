import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  EventEmitter,
  Output, ApplicationRef
} from '@angular/core';
import { SettingsHostDirective } from '../directives/settings-host.directive';
import { LanguageComponent } from './language.component';
import { QuittingPlanSettingsComponent } from './quitting-plan-settings/quitting-plan-settings.component';
import { RemindersComponent } from './reminders.component';
import { GoalSettingsComponent } from './goal-settings.component';
import { WeightTrackComponent } from './weight-track.component';
import { SubscriptionSettingsComponent } from './subscription-settings.component';
import { MyStatsComponent } from './my-stats.component';
import { HelpComponent } from './help.component';
import { AboutComponent } from './about.component';
import { ProfileWebComponent } from './profile-web.component';
import { ChangePasswordComponent } from './change-password.component';
import {
  getMenuObjectBasedOnSelectedTab,
  getRootAccountMenu,
  getSelectedMenuTab
} from '../../../store/session/selectors/account.selectors';
import { take } from 'rxjs/operators';
import * as navigationActions from '../../../store/session/actions/navigation.actions';
import { WeeklyVideoComponent } from '../../community/components/weekly-video.component';
import * as accountActions from '../../../store/session/actions/account.actions';
import { Store } from '@ngrx/store';
import { State } from '../../../store';
import { LoggerService } from '../../../services/logger.service';
import { DownloadComponent } from './download.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-option-card-web',
  styleUrls: ['option-card-web.component.scss'],
  template: `
      <div class="title-row" (click)="selectMenu()">
        <span class="title">{{title | translate}}</span>
      </div>
      <ng-template clSettingsHost></ng-template>
  `
})
export class OptionCardWebComponent implements OnInit, OnChanges {
  @Input() key: string;
  @Input() title: string;
  @Input() icon: string;
  @Input() optionComponent = null;
  @Input() componentProps = null;
  @Input() disabled = false;
  @Output() scrollTop = new EventEmitter();
  @HostBinding('class.expanded') @Input() expanded = false;
  @HostBinding('class.hidden') @Input() hidden = false;
  @ViewChild(SettingsHostDirective, { static: true }) settingsHost: SettingsHostDirective;

  viewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private store: Store<State>,
    private logger: LoggerService,
    private appRef: ApplicationRef
  ) {
  }

  get showOptions() {
    const willShowOptions = this.optionComponent && this.expanded && !this.disabled;

    return willShowOptions;
  }

  loadComponent() {
    const componentToAttach = this.componentNameFactory(this.optionComponent);
    if (!componentToAttach) {
      console.error(`Component ${this.optionComponent} is not a valid type for the OptionCardWebComponent`);

      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      componentToAttach
    );
    this.viewContainerRef = this.settingsHost.viewContainerRef;
    this.viewContainerRef.clear();
    this.viewContainerRef = this.viewContainerRef.createComponent(componentFactory);

    if (this.componentProps) {
      Object.keys(this.componentProps)
        .forEach((key) => {
          this.viewContainerRef.instance[key] = this.componentProps[key];
        });
    }

    if (this.viewContainerRef.instance.scrollTop) {
      this.viewContainerRef.instance.scrollTop.subscribe((val) => {
        this.scrollTop.emit(val);
      });
    }
  }

  closeComponent() {
    const viewContainerRef = this.settingsHost.viewContainerRef;
    // debugger;
    // this.viewContainerRef.instance && this.viewContainerRef.instance.closeComponent && this.viewContainerRef.instance.closeComponent();
    // this.viewContainerRef && this.viewContainerRef.destroy();
    // t.clear();
    // setTimeout(() => {
    viewContainerRef.clear();
    // }, 75);
  }

  ngOnInit() {
    if (this.showOptions) {
      this.loadComponent();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const {expanded} = changes;
    if (!expanded) {
      return;
    }
    if (expanded.currentValue && !expanded.previousValue) {
      this.loadComponent();
    }
    if (expanded.previousValue && !expanded.currentValue) {
      this.closeComponent();
    }
  }

  componentNameFactory(name) {
    const componentMap = {
      ProfileWebComponent,
      ChangePasswordComponent,
      LanguageComponent,
      WeightTrackComponent,
      GoalSettingsComponent,
      RemindersComponent,
      SubscriptionSettingsComponent,
      MyStatsComponent,
      HelpComponent,
      AboutComponent,
      QuittingPlanSettingsComponent,
      DownloadComponent
    };

    return componentMap[name];
  }

  async selectMenu() {
    const menu = await this.store.select(getRootAccountMenu).pipe(take(1))
      .toPromise();

    const selectedMenuTab = await this.store.select(getSelectedMenuTab).pipe(take(1))
      .toPromise();

    const menuObjectBasedOnSelectedTab = await this.store.select(getMenuObjectBasedOnSelectedTab).pipe(take(1))
      .toPromise();

    let menuToOpen = (menuObjectBasedOnSelectedTab).options[this.key];
    if (!menuToOpen) {
      menuToOpen = menu.options[this.key];
    }

    if (!menuToOpen) {
      return this.logger.error(`Could find menu to open with key ${this.key} and stack`, selectedMenuTab, 'openMenu');
    }

    if (menuToOpen.action) {
      switch(menuToOpen.action) {
        case 'openMyCoach':
          this.store.dispatch(new navigationActions.OpenMyCoach());
          break;

        case 'openWeeklyVideo':
          this.store.dispatch(new navigationActions.OpenModal('ModalComponent', {
            header: 'community.weekly_video',
            headline: 'community.join_video_jud',
            embedComponent: WeeklyVideoComponent,
            showBackButton: false,
            icon: 'videocam'
          }));
          break;

        case 'openCommunityProfileForm':
          this.store.dispatch(new navigationActions.OpenModal('ProfileCompletionPage'));
          break;

        case 'openNrtPage':
          this.store.dispatch(new navigationActions.OpenModal('NrtPage'));
          break;
      }

      return;
    }

    this.store.dispatch(new accountActions.SetSelectedSubMenu(this.key));

    this.appRef.tick();
  }

}
