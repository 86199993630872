import { Injectable } from '@angular/core';

// import { App } from '@ionic/angular';

import { Action, Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import * as navigationActions from '../store/session/actions/navigation.actions';
import * as programActions from '../store/session/actions/program.actions';

import { getOnboardingState } from '../store';
import { ClarityConfig } from '../config/clarity.config';
import { TranslateService } from '@ngx-translate/core';
import { State } from '../store/state.reducer';

@Injectable({providedIn: 'root'})
export class OnboardingService {

  private fastOnboardingEnabled = false;
  private programRestarted = false;

  constructor(
    public store: Store<State>,
    // private app: App, // TODO: migrate - find alternative
    private config: ClarityConfig,
    private translateService: TranslateService
  ) {
  }

  checkShowingOnboarding(settings: any) {
    // bypass regular onboarding in special cases
    const { forceOnboarding, type: settingsType, video } = settings;

    if (settingsType === `${this.config.currentProgramCode}_dashboard` && this.programRestarted) {
      this.programRestarted = false;

      return this.store.dispatch(new navigationActions.ShowOnboarding({
        page: 'OnboardingPage',
        params: {
          type: 'program_restarted'
        }
      }));
    }

    this.store.select(getOnboardingState)
      .pipe(
        take(1),
        filter(shown => !shown[settingsType] || forceOnboarding)
      )
      .subscribe(() => {
        this.store.dispatch(new navigationActions.ShowOnboarding({
          page: 'OnboardingPage',
          params: {
            type: settingsType,
            video
          }
        }));
      });
  }

  dismissCurrentOnboarding() {
    // loop through all the navs and find the onboarding page
    // TODO: migrate - find alternative
    // this.app.getActiveNavs()
    //   .forEach((nav) => {
    //     nav.getViews()
    //       .forEach((view) => {
    //         if (view.instance && view.instance instanceof OnboardingPage) {
    //           view.instance.viewCtrl.dismiss();
    //         }
    //       });
    //   });
  }

  setupSkipLessonAction(lesson, noSkip = false, position?: number): Promise<Action> {
    const options = {
      page: 'OnboardingPage',
      params: {
        type: 'custom',
        onboardingSettings: {
          size: 'half',
          header: lesson.record.exercise.title,
          body: lesson.record.exercise.special_caption
        }
      }
    };

    return this.translateService.get(['common.skip', 'common.continue'])
      .pipe(take(1))
      .toPromise()
      .then((translations) => {
        if (noSkip) {
          options.params.onboardingSettings = Object.assign(options.params.onboardingSettings, {
            button: translations['common.skip'],
            actionOnButton: new programActions.SkipLesson(lesson),
            actAsConfirm: true
          });
        }
        else {
          options.params.onboardingSettings = Object.assign(options.params.onboardingSettings, {
            button: translations['common.continue'],
            link: translations['common.skip'],
            actionOnButton: new programActions.ResumeLesson({lesson, position}),
            actionOnLink: new programActions.EnableFastOnboarding(lesson),
            actAsConfirm: true
          });
        }

        return new navigationActions.ShowOnboarding(options);
      });
  }

  enableFastOnboarding() {
    this.fastOnboardingEnabled = true;
  }

  disableFastOnboarding() {
    this.fastOnboardingEnabled = false;
  }

  isFastOnboardingEnabled() {
    return this.fastOnboardingEnabled;
  }

  enableProgramRestartedOnboarding() {
    this.programRestarted = true;
  }
}
