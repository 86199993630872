  <ion-content>
    <ion-header>
      <cl-header-nav-buttons
        class="page-header"
        color="blank"
        [backButton]="showBackButton"
        title="{{ 'my-stats.title' | translate }}"
        (closeClick)="closeButtonAction()"
        (back)="backButtonAction()">
      </cl-header-nav-buttons>
    </ion-header>

    <cl-my-stats></cl-my-stats>
  </ion-content>
