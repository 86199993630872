import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SharedModule } from '../../../shared';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { translateConfig } from '../../config/translate.config';
import { InAppMessagePage } from './inapp-message';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    RouterModule.forChild([{path: '', component: InAppMessagePage}]),
    TranslateModule.forChild(translateConfig)
  ],
  declarations: [InAppMessagePage]
})
export class InappMessageModule {}
