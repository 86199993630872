import { Pipe, PipeTransform } from '@angular/core';
import * as stripHtml from 'string-strip-html';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlTagsPipe implements PipeTransform {

  transform(message: string, ignores: Array<string> = []): string {
    return stripHtml(message, {
      ignoreTags: ignores
    });
  }

}
