import { ChangeDetectorRef, Component, EventEmitter, HostBinding, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { SettingsComponent } from './settings.component';
import { UserReminder } from '../../../store/normalized/schemas/reminder.schema';
import { Subscription } from 'rxjs';
import { ClarityConfig } from '../../../config/clarity.config';
import { BrowserService } from '../../../services/browser.service';
import { getUserRemindersOrDefaults } from '../../../store/normalized/selectors/reminders.selectors';
import { SessionState } from '../../../store/session';
import { NotificationsService } from '../../../services/notification.service';
import { slideInOut } from '@mindsciences/utils';

@Component({
  selector: 'cl-account-reminders',
  styleUrls: ['reminders.component.scss'],
  animations: [slideInOut],
  template: `
    <div [@state]="visibility"
         (@state.done)="animationDone($event)" [@.disabled]="!isDesktop">

      <cl-header-nav-buttons
        *ngIf="isDesktop && selectedReminder"
        color="primary"
        title="{{ 'reminders.' + selectedReminder.tag | translate }}"
        [closeButton]="false"
        (back)="closeUserReminder()"
        [backButton]="!!selectedReminder">
      </cl-header-nav-buttons>

      <div class="reminders-disable-wrapper" *ngIf="!selectedReminder">
        <div class="reminders-title" *ngIf="notificationsAllowed && backgroundModeAllowed">
          {{'reminders.customize_your_reminders' | translate}}
        </div>

        <div class="reminders-warning-header" *ngIf="!notificationsAllowed || !backgroundModeAllowed">
          <ion-icon name="warning"></ion-icon>
          <p>{{'reminders.warning_header' | translate}}</p>
        </div>

        <div class="reminders-warning-body" *ngIf="!notificationsAllowed">
          <p>{{'reminders.warning_notifications' | translate }}</p>
          <cl-action-button
            [label]="'reminders.enable_notifications' | translate"
            [canClick]="true"
            (action)="enableReminders()">
          </cl-action-button>
        </div>

        <div class="reminders-warning-body" *ngIf="notificationsAllowed && !backgroundModeAllowed">
          <p>{{'reminders.warning_background_mode' | translate : {programName: config.program.name} }}</p>
          <cl-action-button
            [label]="'reminders.disable_battery_optimization' | translate"
            [canClick]="true"
            (action)="disableBatteryOptimization()">
          </cl-action-button>
        </div>
      </div>

      <div *ngIf="notificationsAllowed && backgroundModeAllowed">
        <p class="desktop-warning" *ngIf="notificationsNotAvailable">
          {{ 'reminders.need_mobile_app' | translate }}
        </p>

        <ng-container *ngIf="(userReminders$ | async) as userReminders">
          <ion-list *ngIf="!selectedReminder && !isDesktop; else remindersListDesktop">
            <ion-item *ngFor="let userReminder of userReminders"
                      (click)="editUserReminder(userReminder)"
                      class="reminder-option" no-lines>
              <ion-label>
                {{ 'reminders.' + userReminder.tag | translate }}
              </ion-label>
            </ion-item>
          </ion-list>

          <ng-template #remindersListDesktop>
            <div *ngIf="!selectedReminder"
                 class="flex-wrapper">
              <div *ngFor="let userReminder of userReminders"
                   (click)="editUserReminder(userReminder)"
                   class="reminder-option">

              <ion-icon name="calendar" *ngIf="userReminder.tag === 'goal'"></ion-icon>
              <ion-icon src="/assets/icon/checkin_icon.svg" *ngIf="userReminder.tag === 'check_in'"></ion-icon>
              <ion-icon src="/assets/icon/night_reflection.svg" *ngIf="userReminder.tag === 'night_reflection'"></ion-icon>
              <ion-icon src="/assets/icon/morning_motivation.svg"
                        *ngIf="userReminder.tag === 'motivation' || userReminder.tag === 'morning_motivation'"></ion-icon>

                <span>{{ 'reminders.' + userReminder.tag | translate }}</span>
              </div>
            </div>
          </ng-template>
        </ng-container>

        <p class="reminders-help-android" *ngIf="showAndroidHelp" (click)="openAndroidHelpArticle()">
          <b>{{'reminders.note' | translate}}</b>
          {{'reminders.android_reminders_disclaimer' | translate}}
        </p>

        <cl-edit-reminder *ngIf="selectedReminder"
                          [reminder]="selectedReminder"
                          [isDesktop]="isDesktop"
                          (reminderUpdated)="closeUserReminder()"
                          [reminderName]="'reminders.' + selectedReminder.tag">
        </cl-edit-reminder>
      </div>
    </div>`
})
export class RemindersComponent implements SettingsComponent, OnDestroy {
  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  @Output() endAnimation = new EventEmitter();

  visibility = 'visible';

  userReminders$ = this.sessionStore.select(getUserRemindersOrDefaults);

  selectedReminder: UserReminder;
  backSubscription: Subscription;

  notificationsNotAvailable = this.config.isWebApp;

  public notificationsAllowed = true;
  public backgroundModeAllowed = true;

  private checksInterval: any;

  constructor(
    private sessionStore: Store<SessionState>,
    private cdr: ChangeDetectorRef,
    private browser: BrowserService,
    public config: ClarityConfig,
    private notifications: NotificationsService
  ) {
    this.checkNotificationsSupport();
  }

  ngOnDestroy(): void {
    this.stopChecksInterval();
  }

  get showAndroidHelp() {
    return !this.selectedReminder && this.config.isAndroid;
  }

  openAndroidHelpArticle() {
    this.browser.goTo('https://claritasmindsciences.zendesk.com/hc/en-us/articles/360015741834-Reminders-not-working-on-Android-');
  }

  editUserReminder(userReminder: UserReminder) {
    this.selectedReminder = {...userReminder};

  }

  closeUserReminder() {
    this.selectedReminder = null;
  }

  animationDone(event) {
    if (event.toState === 'hidden') {
      this.endAnimation.emit(true);
    }
  }

  closeComponent() {
    this.visibility = 'hidden';
  }

  enableReminders() {
    if (!this.config.isDevice) {
      console.log('Enable reminders called while not running on a device!');
    }

    this.startCheckingNotificationsSupport();

    this.notifications.openNativeAppSettings();
  }

  disableBatteryOptimization() {
    if (!this.config.isAndroid) {
      console.log('Disable battery optimization called while not running on an Android!');

      return false;
    }

    this.startCheckingNotificationsSupport();

    this.notifications.requestBatteryOptimizationIgnore();
  }

  private checkNotificationsSupport() {
    return Promise.all([
      this.notifications.hasPermission()
        .then((permission) => this.notificationsAllowed = permission),
      this.notifications.isBatteryOptimizationIgnored()
        .then((allowed) => this.backgroundModeAllowed = allowed)
    ]);
  }

  private startCheckingNotificationsSupport() {
    this.stopChecksInterval();

    this.checksInterval = setInterval(() => {
      this.checkNotificationsSupport();

      this.cdr.detectChanges();

      if (this.notificationsAllowed && this.backgroundModeAllowed) {
        this.stopChecksInterval();
      }

    }, 500);
  }

  private stopChecksInterval() {
    if (!this.checksInterval) {
      return false;
    }

    clearInterval(this.checksInterval);
    this.checksInterval = null;

    this.cdr.detectChanges();

    console.log('canceled notifications interval', this.checksInterval);
  }
}
