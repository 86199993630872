import { schema } from 'normalizr';

export class User {
  id: number;
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  gender: string;
  age: number;
  weight: number;
  weight_unit: string;
  cigs_per_day: number;
  cig_pack_currency: string;
  cig_pack_cost: number;
  nrt_available: boolean;
  start_date: Date;
  end_date: Date;
  quit_date: Date;
  complete: UserProgram | false;
  dpp_wl_type: 'dpp' | 'wl';
  sso_provider: string;
  provider_type: string;
  sc_consumer: boolean;
  user_programs: Array<Partial<UserProgram>>;
}

export class UserProgram {
  id: number;
  user_id: number;
  program_id: number;
  program_code: string;
  agree_to_tos: boolean;
  coupon_code: string;
  accelerate: number;
  license_code: string;
  referer_code: string;
  language_code: string;
  user_name: string;
  inapp_coaching: boolean; // legacy flag. Should be replaced in the future by `user_program_info.isCoachingTabEnabled`
  /** @deprecated inapp_messaging flag is not used anymore */
  inapp_messaging: boolean;
  program_restarted_at: string;
  goal_ids: number[];
  trigger_ids: any[]; // @deprecated
  exclude_tracking: string;
  sso_provider: string;
  provider_type: string;
  created_at: string;
  smoking_type?: SmokingType;
  dpp_wl_type?: ProgramType;
  program_module_number_allow_user_week?: string; // after this module user can start custom/theme week
  program_module_number_allow_order_fitbit_tracker?: string; // after this module, user can order fitbit tracker
  user_program_info: UserProgramInfo;
}

export class UserProgramSession {
  id: number;
  user_program_id: number;
  active: boolean;
  mantra: string;
}

export interface UserProgramInfo {
  enrollment_motivation?: string;
  enrollment_source?: string;
  coach_info?: string;
  friendly_name?: string;
  contact_phone?: string;
  education?: string;
  complete?: string;
  content_program?: ErnContentProgram;
  healthfit_integration_enabled?: boolean;
  program_extension_enabled?: boolean;

  custom_attributes?: {
    isScaleRedemptionEnabled?: boolean;
    isTrackerRedemptionEnabled?: boolean;
    isCoachingTabEnabled?: boolean;
  };
}

export class UserGoal {
  id: number;
  name: string;
}

export enum SmokingType {
  CIGARETTE = 'cigarette',
  VAPING = 'vaping'
}

// Applies to ERN only to differentiate between normal users and DPP or WL ones
// @todo: ERN has 6 different content paths, maybe try to merge these two enums together (ProgramType + ErnContentProgram)
export enum ProgramType {
  ERN = 'ern',
  DPP = 'dpp',
  WL = 'wl'
}

// Used by ERN consumer flow
export enum ErnContentProgram {
  MindfulEating = 'ERN_ME',
  WeightLoss = 'ERN_WL',
  MindfulEatingWLExtension = 'ERN_ME_WL',
}

// @todo rename to CommunityProfile
export class UserProfile {
  username: string;
  id: string;
  avatar: string;
  roles?: string[];
  external_id?: number;
  quit_date: string;
  standalone_client?: string;
  terms_agreed?: boolean;
  firstName?: string;
  lastName?: string;
  avatarUrl?: string;
}

export class UserAgent extends UserProfile {
  bio?: string;
  name?: string;
}

export class UserFavoriteCreate {
  id?: number;
  type: string;
  record_id: number;
  record?: any;
}

export class UserFavorite {
  id: number;
  favourable_type: string;
  favourable_record?: any;
  favourable_id: number;
  user_program_id: number;
  created_at: string;
  updated_at: string;
}
export const userFavoriteSchema = new schema.Entity<UserFavorite>(
  'userFavs'
);

export const userGoalsSchema = new schema.Entity<UserGoal>(
  'userGoals'
);

export const userProgramSchema = new schema.Entity<UserProgram>(
  'userPrograms',
  {},
  {idAttribute: () => 'current'}
);

export const userProgramSessionSchema = new schema.Entity<UserProgramSession>(
  'userProgramSession',
  {},
  {idAttribute: () => 'current'}
);

export const userSchema = new schema.Entity<User>(
  'users',
  {},
  {idAttribute: () => 'current'}
);

// @todo rename to communityProfileSchema
export const userProfileSchema = new schema.Entity<UserProfile>(
  'userProfile',
  {},
  {idAttribute: () => 'current'}
);
