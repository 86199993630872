import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as navigationActions from '../store/session/actions/navigation.actions';
import { State } from '../store/state.reducer';
import { ClarityConfig } from '../config/clarity.config';
import { SmokingTypeService } from './smoking-type.service';

// **************** **************** **************** **************** ****************
//
//   Popups information is stored on each exercise's special_caption field
//     format should match the following convention:
//        <POPUP_TYPE>$$<PROGRAM>$$<TEXT>
//     for example, this popup will only appear on CTQ vaping after an excercise:
//        POPUP_AFTER$$CTQ_VAP$$This text should be in a popup!
//
// **************** **************** **************** **************** ****************

const POPUP_TYPE_AFTER = 'POPUP_AFTER';
const POPUP_TYPE_BEFORE = 'POPUP_BEFORE';

const MSG_DELIMITER = '$$';

enum PopupProgramCode {
  CTQ_CIG = 'CTQ_CIG',
  CTQ_VAP = 'CTQ_VAP',
  UA = 'UA',
  ERN = 'ERN'
}

@Injectable({providedIn: 'root'})
export class ExcercisePopUpService {

  popupProgram: PopupProgramCode;

  constructor(
    public store: Store<State>,
    private config: ClarityConfig,
    private smokingTypeService: SmokingTypeService
  ) {
    this.popupProgram = this.getPopupProgram();
  }

  getPopupProgram() {
    switch (this.config.programCode()) {
      case 'ua':
        return PopupProgramCode.UA;
      case 'ern':
        return PopupProgramCode.ERN;
      case 'ctq':
        if (this.smokingTypeService.isVaping()) {
          return PopupProgramCode.CTQ_VAP;
        } else {
          return PopupProgramCode.CTQ_CIG;
        }
    }
  }

  hasPopUpAfter(lesson) {
    return this._hasPopUp(lesson, POPUP_TYPE_AFTER);
  }

  hasPopUpBefore(lesson) {
    return this._hasPopUp(lesson, POPUP_TYPE_BEFORE);
  }

  private _hasPopUp(lesson, popUpType) {
    const specialCaption = lesson.record.exercise.special_caption;
    const hasPopupForType = specialCaption && specialCaption.indexOf(popUpType) !== -1;
    const hasPopupForProgram = specialCaption && this._hasPopupForProgram(specialCaption);

    return hasPopupForType && hasPopupForProgram;
  }

  private _hasPopupForProgram(caption: string) {
    const captionCode = (caption.match(/\$\$(\w+)\$\$/) || [])[1];

    return captionCode && this.popupProgram.indexOf(captionCode) !== -1;
  }

  showPopUp(lesson) {
    const specialCaption = lesson.record.exercise.special_caption;
    const msg = specialCaption.split(MSG_DELIMITER)[2];

    return new Promise((resolve) => {
      this.store.dispatch(new navigationActions.ShowOnboarding({
        page: 'OnboardingPage',
        params: {
          type: 'custom',
          onboardingSettings: {
            size: 'full',
            header: '',
            button: 'Continue',
            body: msg,
            actAsConfirm: true,
            callbackOnButton: () => resolve(null)
          }
        }
      }));
    });
  }
}
