export interface HttpError {
  type: string;
  httpStatus: number;
  httpError?: any;
  httpMessage?: any;
}

export enum HttpErrorCode {
  SharecareEmailTaken = 'sharecare_email_taken',
  EmailTaken = 'email_taken',
  PasswordUnsafe = 'password_unsafe'
}
