import { Exercise } from 'src/app/store/normalized/schemas/exercise.schema';
import { LiveBonusExercise, LiveLesson, LiveModule } from 'src/app/store/session/selectors/program.selectors';
import { flatten } from 'flat';

const getLeanExercise = (exercise: Exercise) => ({
  id: exercise?.id,
  action: exercise?.action,
  kind: exercise?.kind,
  title: exercise?.title,
  isLocked: exercise?.isLocked,
  isNew: exercise?.isNew
});

const getLeanLesson = (lesson: LiveLesson) => ({
  isFirst: lesson?.isFirst,
  isLast: lesson?.isLast,
  isAllPrevDone: lesson?.isAllPrevDone,
  completedAt: lesson?.completedAt,
  isCompleted: lesson?.isCompleted,
  isAvailable: lesson?.isAvailable,
  isAccelerated: lesson?.isAccelerated,
  isCurrent: lesson?.isCurrent,

  position: lesson?.position,
  weekNumber: lesson?.weekNumber,
  moduleNumber: lesson?.moduleNumber,

  record: {
    id: lesson?.record?.id,
    program_day_id: lesson?.record?.program_day_id,
    position: lesson?.record?.position,

    exercise: getLeanExercise(lesson?.record?.exercise)
  }
});

const getLeanModule = (module: LiveModule) => ({
  isFirst: module?.isFirst,
  isLast: module?.isLast,
  isAllPrevDone: module?.isAllPrevDone,
  isCompleted: module?.isCompleted,
  isInProgress: module?.isInProgress,
  isAvailable: module?.isAvailable,
  isAccelerated: module?.isAccelerated,
  isCurrent: module?.isCurrent,
  isFav: module?.isFav,
  positionInWeek: module?.positionInWeek,
  isFirstInWeek: module?.isFirstInWeek,
  willUnlockAt: module?.willUnlockAt,

  record: {
    id: module?.record?.id,
    program_id: module?.record?.program_id,
    title: module?.record?.title,
    number: module?.record?.number
  }
});

const getLeanBonusExercise = (exercise: LiveBonusExercise) => ({
  moduleNumber: exercise?.moduleNumber,
  isLocked: exercise?.isLocked,
  isNew: exercise?.isNew,
  title: exercise?.title,
  length: exercise?.length,

  id: exercise?.id,
  position: exercise?.position,
  module: exercise?.module,

  exercise: getLeanExercise(exercise?.exercise)
});

export const adaptLessonToAnalytics = (lesson: LiveLesson) => flatten({ lesson: getLeanLesson(lesson) });

export const adaptModuleToAnalytics = (module: LiveModule) => flatten({ module: getLeanModule(module) });

export const adaptBonusExerciseToAnalytics = (exercise: LiveBonusExercise) => flatten({ exercise: getLeanBonusExercise(exercise) });
