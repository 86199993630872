import { Component, HostBinding } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

import { exerciseTitleToImg } from '../../constants/checkins.constants';
import { getCheckinExercises } from '../../store/normalized/selectors/checkin.selectors';
import { State } from '../../store/state.reducer';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import * as toolsActions from '../../store/session/actions/tools.actions';
import { ClarityConfig } from '../../config/clarity.config';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

// eslint-disable-next-line no-var
declare var window;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'breathe-exercise-page',
  styleUrls: ['breathe-exercise.scss'],
  templateUrl: 'breathe-exercise.html'
})
export class BreatheExercisePage {

  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  modal;

  readonly BREATHE_RESULTS_FACTOR = 6; // 6 * 15 breaths/min = 90 + -90deg = 0deg (angle of breathe meter)

  view = 'breathe';
  step = 1;
  round = 1;

  breatheInStartTime = null;
  lastBreathLength = null;
  breathsPerMin = null;

  breaths = [];
  timings = [];
  startingFeelNow = 66; // slider is reversed - results in 'same' value
  choice = '';
  checkinExercise = null;

  constructor(
    public store: Store<State>,
    private translate: TranslateService,
    private config: ClarityConfig
  ) {
    this.loadCheckinExercise();
  }

  loadCheckinExercise() {
    this.store.select(getCheckinExercises)
      .pipe(take(1))
      .toPromise()
      .then((checkinExercises) => {
        this.checkinExercise = checkinExercises[Math.floor(Math.random() * checkinExercises.length)];
        this.checkinExercise.graphic = exerciseTitleToImg(this.translate.currentLang, this.checkinExercise.title);
      });
  }

  onClose() {
    this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
  }

  nextStep() {
    if (this.config.isDevice) {
      Haptics.impact({ style: ImpactStyle.Light })
        .catch(error => console.log(error));
    }

    this.step++;

    // measure breath
    switch (this.step) {
      case 2:
        this.startBreathe();
        break;

      case 4:
        this.endBreathe();
        break;
    }

    // reset setp
    if (this.step >= 4) {
      this.step = 2;
      this.round++;
    }
  }

  onRepeat() {
    this.view = 'breathe';
    this.step = 1;
    this.round = 1;
    this.breaths = [];
    this.choice = 'repeat';
  }

  startBreathe() {
    this.breatheInStartTime = Date.now();
  }

  calculateBreathsPerMinute(length) {
    return Math.round(60 / (length / 1000));
  }

  endBreathe() {
    this.lastBreathLength = Date.now() - this.breatheInStartTime;

    this.timings.push(this.lastBreathLength);
    this.breaths.push(this.calculateBreathsPerMinute(this.lastBreathLength));
  }

  calculateAverageBreaths() {
    return this.breaths.reduce((sum, current) => sum + current) / this.breaths.length;
  }

  showResults() {
    this.view = 'results';

    this.breathsPerMin = this.calculateAverageBreaths();
  }

  getRotateIndicator() {
    const degree = -90 + (this.BREATHE_RESULTS_FACTOR * this.breathsPerMin);

    return degree < -90
      ? -90
      : degree > 90
        ? 90
        : degree;
  }

  getRoundedBreathesPerMin() {
    return Math.round(this.breathsPerMin);
  }

  goToQuestion() {
    this.view = 'question';
  }

  goToWellDone() {
    this.view = 'well-done';
  }

  onImFeelingLucky() {
    this.view = 'checkin-exercise';
    this.choice = 'checkin';
    this.complete(false);
  }

  complete(close = true) {
    const datePipe = new DatePipe('en-US');

    const data = {
      date: datePipe.transform(new Date(), 'yyyy-MM-dd'),
      recorded_at: new Date().toISOString(),
      anxiety: null,
      timings: this.timings,
      feel_now: null,
      choice: this.choice
    };

    this.store.dispatch(new toolsActions.TrackBreatheExercise(data));

    if (close) {
      this.onClose();
    }
  }

}
