import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../../shared';
import { translateConfig } from '../../config/translate.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AccountDeletionPage } from './account-deletion';
import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AccountDeletionPage
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{path: '', component: AccountDeletionPage}]),
    TranslateModule.forChild(translateConfig),
    SharedModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class AccountDeletionModule {}
