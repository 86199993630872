import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit
} from '@angular/core';

import { SmokingTypeService } from '../../../services/smoking-type.service';
import { ListItem } from '../../../store/normalized/schemas/list-item.schema';
import { ConnectivityService } from '../../../services/connectivity.service';
import { ClarityConfig } from '../../../config/clarity.config';

class SelectedListItem extends ListItem {
  checked: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'cl-goals-overview',
  styleUrls: ['goal-overview.component.scss'],
  template: `
    <ion-row class="goals-section top-row" *ngIf="!!startDate">
      <div class="goal-title">
        {{ 'goals_menu.started_on' | translate }}
      </div>
      <ion-item class="goal-description" no-lines>
        <ion-label>
          {{startDate}}
        </ion-label>
      </ion-item>
    </ion-row>

    <ion-row class="goals-section" *ngIf="config.isCTQ() && !!endDate">
      <div class="goal-title">
        {{ 'goals_menu.will_end_at' | translate }}
      </div>
      <ion-item class="goal-description" no-lines>
        <ion-label>
          {{endDate}}
        </ion-label>
      </ion-item>
    </ion-row>

    <ion-row class="goals-section" *ngIf="config.isCTQ() && smokingTypeService.isCigarette()">
      <div class="goal-title">
        {{ 'goals_menu.will_save' | translate }}
      </div>
      <ion-item class="goal-description" no-lines>
        <ion-label>
          <!-- &#36; equals dolar sign -->&#36;{{cigaretteSavings | number:'1.0-1'}} {{ 'goals_menu.per_year' | translate }}
        </ion-label>
      </ion-item>
    </ion-row>

    <ion-row class="goals-section">
      <div class="goal-title">
        {{ 'goals_menu.my_goals' | translate }}
      </div>
      <div class="goals-wrapper" *ngIf="!goalsSyncing; else loadingTemplate">
        <ion-item [button]="enableEdit && config.isCTQ()" (click)="changeGoals()" class="goal-description" no-lines
                  *ngIf="checkedDefaultGoals.length === 0 && customGoals.length === 0">
          <ion-label>
            <i>{{'goals_menu.no_goals_so_far' | translate}}</i>
          </ion-label>
        </ion-item>
        <ion-item [button]="enableEdit && config.isCTQ()" (click)="changeGoals()" class="goal-description" no-lines
                  *ngFor="let defaultGoal of checkedDefaultGoals">
          <ion-label>
            {{defaultGoal.name}}
          </ion-label>
        </ion-item>
        <ion-item [button]="enableEdit && config.isCTQ()" (click)="changeGoals()" class="goal-description" no-lines
                  *ngFor="let userGoal of customGoals">
          <ion-label>
            {{userGoal.name}}
          </ion-label>
        </ion-item>
      </div>
    </ion-row>

    <!-- This is no longer tracked from Want-O-Meter -->
<!--    <ion-row class="goals-section">-->
<!--      <div class="goal-title">-->
<!--        {{ 'goals_menu.recent_trigger' | translate }}-->
<!--      </div>-->
<!--      <ion-item class="goal-description item-padding">-->
<!--        <ion-label [innerHtml]="lastTrigger | translate">-->
<!--        </ion-label>-->
<!--      </ion-item>-->
<!--    </ion-row>-->

    <ion-row class="goals-section last">
      <div class="goal-title">
        {{ 'goals_menu.i_got_this' | translate }}
      </div>
      <ion-item class="goal-description item-padding">
        <ion-label [innerHtml]="mantra">
        </ion-label>
      </ion-item>
    </ion-row>

    <ion-row class="action-button" *ngIf="enableEdit && !this.config.isCTQ()">
      <ion-col class="ion-text-center" auto>
        <cl-action-button
          (action)="toEdit()"
          [canClick]="true"
          label="{{ 'goals_menu.update_goals' | translate }}">
        </cl-action-button>
      </ion-col>
    </ion-row>

    <ng-template #loadingTemplate>
      <ion-col class="loading-overlay ion-text-center ion-margin-vertical"><ion-spinner></ion-spinner></ion-col>
    </ng-template>
  `
})
export class GoalOverviewComponent {
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() cigaretteSavings: number;
  @Input() checkedDefaultGoals: SelectedListItem[] = [];
  @Input() customGoals = [];
  @Input() mantra;
  @Input() lastTrigger: string;
  @Input() goalsSyncing: boolean;
  @Input() enableEdit = true;
  @Output() edit = new EventEmitter();

  constructor(
    private connectivityService: ConnectivityService,
    public config: ClarityConfig,
    public smokingTypeService: SmokingTypeService
  ) {
  }

  changeGoals() {
    if (this.config.isCTQ()) {
      this.toEdit();
    }
  }

  toEdit() {
    if (!this.connectivityService.preventAccessWhenOffline()) {
      this.edit.emit();
    }
  }
}
