// goals actions

import { Action } from '@ngrx/store';
import { LiveLesson } from '../selectors/program.selectors';

export const UPDATE_USER_MANTRA = '[user program session] Update User Mantra';
export const UPDATE_USER_MANTRA_SUCCESS = '[user program session] Update User Mantra Success';
export const UPDATE_USER_MANTRA_FAIL = '[user program session] Update Mantra Fail';

export class UserMantraUpdate {
  data: any;
  lesson?: LiveLesson;
}

export class UpdateUserMantra implements Action {
  readonly type = UPDATE_USER_MANTRA;

  constructor(public payload: UserMantraUpdate) {

  }
}

export class UpdateUserMantraSuccess implements Action {
  readonly type = UPDATE_USER_MANTRA_SUCCESS;
}

export class UpdateUserMantraFail implements Action {
  readonly type = UPDATE_USER_MANTRA_FAIL;
}

export type UserMantrasActions =
  | UpdateUserMantra
  | UpdateUserMantraSuccess
  | UpdateUserMantraFail;
