import { schema } from 'normalizr';
import { Tag, tagSchema } from './tag.schema';
import { Exercise, exerciseSchema } from './exercise.schema';

export class ProgramBootstrap {
  id: number;
  name: string;
  code: string;
  program_days: ProgramDay[];
  available_locales: string[];
}

export class ProgramDay {
  id: number;
  program_id: number;
  title: string;
  tags: Tag[];
  overview: string;
  goal: string;
  bonus: string;
  number: number;
  program_daily_exercises: ProgramDayExercise[];
}

export class ProgramDayExercise {
  id: number;
  program_day_id: number;
  exercise_id: number;
  position: number;
  exercise: Exercise;

}

export const programDayExerciseSchema = new schema.Entity<ProgramDayExercise>('programDayExercises', {exercise: exerciseSchema});
export const programDaySchema = new schema.Entity<ProgramDay>('programDays', {
  program_daily_exercises: [programDayExerciseSchema],
  tags: [tagSchema]
});
export const programBootstrapSchema = new schema.Entity<ProgramBootstrap>(
  'programBootstraps',
  {program_days: [programDaySchema]},
  {idAttribute: () => 'synced'}
);
