import { Injectable } from '@angular/core';

import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of , forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AddData, RemoveData } from 'ngrx-normalizr';

import * as userGoalsActions from '../actions/user-goals.actions';
import * as fromNormalizedCore from '../../../../app/store';
import { ToastService } from '../../../../app/services/toast.service';
import { LoadingService } from '../../../../app/services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { GoalsProvider } from '../../../../app/providers/goals.provider';
import { AnalyticsService } from '../../../../app/services/analytics/analytics.service';
import { UserGoal, UserProgram, userGoalsSchema, userProgramSchema } from '../../normalized/schemas/user.schema';
import { getCurrentUserProgram } from '../../normalized/selectors/user.selectors';
import { CompleteLesson } from '../actions/program.actions';
import { isUpdatingUserGoals } from '../selectors/user-goals.selectors';
import { SessionState } from '../session.reducers';

@Injectable({providedIn: 'root'})
export class UserGoalsEffects {


  updateUserCustomGoals$ = createEffect(() => this.actions$.pipe(ofType(userGoalsActions.UPDATE_USER_GOALS),
    map((action: userGoalsActions.UpdateUserGoals) => action.payload),
    tap((payload) => {
      if ((!payload.lesson && !payload.hideLoader)) {
        this.loadingService.useLoadingObservable(
          this.store.select(isUpdatingUserGoals),
          this.translate.get('goals_menu.saving_goals')
        );
      }
    }),
    concatMap(newGoals => of(newGoals)
      .pipe(withLatestFrom(this.store.select(getCurrentUserProgram)))
    ),
    switchMap(([newGoals, currentProgram]) => {
      const {add, remove, defaults, lesson} = newGoals;
      const newUserProgram = {
        ...currentProgram,
        goal_ids: defaults
      };

      const addObservables = add.map(goal => this.goalsProvider.createCustomGoal(goal));

      const defaultsObservables = [];

      if (defaults) {
        defaultsObservables.push(
          this.goalsProvider.updateDefaultGoals(newUserProgram)
        );
      }

      const removeObservables = remove.map(
        goal => this.goalsProvider.deleteCustomGoal(goal.id)
          .pipe(
            map(() => this.normalizedStore.dispatch(new RemoveData({
              id: `${goal.id}`,
              schema: userGoalsSchema
            })))
          )
      );

      const goalObservables = [...addObservables, ...removeObservables, ...defaultsObservables];

      // forkJoin doesn't emit a value for empty list
      const observablesToJoin = goalObservables.length > 0 ? goalObservables : [of(null)];

      return forkJoin(observablesToJoin)
        .pipe(
          map((res: any[]) => {
            const error = res.find((result) => result && result.error !== undefined);
            if (error) {
              // some goal creations may fail, we check for those here
              return new userGoalsActions.UpdateUserGoalsFail(lesson);
            }

            return new userGoalsActions.UpdateUserGoalsSuccess(lesson);
          }),
          catchError((error) => of(new userGoalsActions.UpdateUserGoalsFail(lesson)))
        );
    })
  ));


  updateUserGoalSuccess$ = createEffect(() => this.actions$.pipe(ofType(userGoalsActions.UPDATE_USER_GOALS_SUCCESS),
    map((action: userGoalsActions.UpdateUserGoalsSuccess) => action.payload),
    map((lesson) => {
      if (lesson) {
        this.normalizedStore.dispatch(new CompleteLesson(lesson));
      }
    })
  ), {dispatch: false});


  updateUserGoalFail$ = createEffect(() => this.actions$.pipe(ofType(userGoalsActions.UPDATE_USER_GOALS_FAIL),
    map((action: userGoalsActions.UpdateUserGoalsSuccess) => action.payload),
    map((lesson) => {
      this.toastService.error(this.translate.get('errors.common.network_error'));
    })
  ), {dispatch: false});


  loadUserGoals$ = createEffect(() => this.actions$.pipe(ofType(userGoalsActions.LOAD_USER_GOALS),
    switchMap(() => forkJoin([this.goalsProvider.loadUserGoals(), this.goalsProvider.loadDefaultGoals()])
      .pipe(
        map(([goals, userProgram]: [UserGoal[], UserProgram]) => {
          this.normalizedStore.dispatch(new AddData<UserGoal>({
            data: goals,
            schema: userGoalsSchema
          }));
          this.normalizedStore.dispatch(new AddData<UserProgram>({
            data: [userProgram],
            schema: userProgramSchema
          }));

          return new userGoalsActions.LoadUserGoalsSuccess();
        }),
        catchError((error) => of(new userGoalsActions.LoadUserGoalsFail(error)))
      ))
  ));


  loadUserGoalsSuccess$ = createEffect(() => this.actions$.pipe(ofType(userGoalsActions.LOAD_USER_GOALS_SUCCESS),
    tap(() => this.toastService.confirm(this.translate.get('common.saved')))
  ), {dispatch: false});


  loadUserGoalsFail$ = createEffect(() => this.actions$.pipe(ofType(userGoalsActions.LOAD_USER_GOALS_FAIL),
    tap(() => this.toastService.confirm(this.translate.get('common.problem_completing_request')))
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private goalsProvider: GoalsProvider,
    private normalizedStore: Store<fromNormalizedCore.State>,
    private loadingService: LoadingService,
    private store: Store<SessionState>,
    private toastService: ToastService,
    private translate: TranslateService,
    private analyticsService: AnalyticsService
  ) {
  }
}
