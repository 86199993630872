import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StepValidationSlideComponent } from 'src/app/components/slides/step-validation-slide';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonContent } from '@ionic/angular';
import * as accountActions from '../../store/session/actions/account.actions';
import { Store } from '@ngrx/store';
import { SessionState } from 'src/app/store/session';
import { DottedStepsIndicatorStatus } from 'src/app/components/dotted-steps-indicator/dotted-steps.model';
import { ErnContentProgram } from 'src/app/store/normalized/schemas/user.schema';
import * as navigationActions from 'src/app/store/session/actions/navigation.actions';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';
import { getUserProgramLanguage } from 'src/app/store/normalized/selectors/user.selectors';

enum ErnWeightLossInterstitialSlides {
  Introduction = 'introduction',
  QuestionWLContent = 'questionWeightLossContent',
  QuestionTrackWeight = 'questionTrackWeight',
  recommendMindfulEating = 'recommendMindfulEating',
  RecommendWeightLoss = 'RecommendWeightLoss'
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-interstitial-ern-weight-loss-diagnostic',
  styleUrls: ['interstitial-ern-weight-loss-diagnostic.scss'],
  templateUrl: 'interstitial-ern-weight-loss-diagnostic.html'
})
export class InterstitialErnWeightLossDiagnosticPage extends StepValidationSlideComponent implements OnInit, OnDestroy {
  @ViewChild('ionContent', { read: ElementRef, static: true }) ionContent: ElementRef<IonContent>;
  selectedSlideId: number = null;
  form: FormGroup;
  formValues = [{label: 'common.yes', value: true},{label: 'common.no', value: false}];
  ernContentProgram = ErnContentProgram;
  ernWeightLossInterstitialSlides = ErnWeightLossInterstitialSlides;

  private readonly destroyed$ = new Subject<void>();
  userProgramLanguage$: Observable<string> = this.store.select(getUserProgramLanguage);

  dottedStepsStatus$: Observable<DottedStepsIndicatorStatus> = this.selectedSlideNumber$.pipe(
    takeUntil(this.destroyed$),
    map(selectedSlide => {
      switch (this.getSlideName(selectedSlide)) {
        case ErnWeightLossInterstitialSlides.QuestionWLContent:
          return { dots: 2, activeDot: 0 };
        case ErnWeightLossInterstitialSlides.QuestionTrackWeight:
          return { dots: 2, activeDot: 1 };
        default:
          return null;
      }
    })
  );

  constructor(
    private analyticsService: AnalyticsService,
    private store: Store<SessionState>
  ) {
    super();

    this.swiperInit$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.swiper.on('slideChangeTransitionStart', () => {
        (this.swiper.slides[this.swiper.activeIndex] as HTMLElement).style.zIndex = `${this.swiper.activeIndex}`;
      });
    });
  }

  get showFooter() {
    return this.swiper &&
      (this.getSlideName() === ErnWeightLossInterstitialSlides.QuestionWLContent ||
        this.getSlideName() === ErnWeightLossInterstitialSlides.QuestionTrackWeight);
  }

  ngOnInit() {
    this.form = new FormGroup({
      content: new FormControl('', Validators.required),
      weight: new FormControl('', Validators.required)
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  conditionalNavigateNext() {
    switch(this.getSlideName()) {
      case ErnWeightLossInterstitialSlides.Introduction:
        this.tryNavigateToNext();
        break;
      case ErnWeightLossInterstitialSlides.QuestionWLContent:
        if(this.form.get('content').value) {
          this.tryNavigateToNext();
        } else {
          this.navigateToNamedSlide(ErnWeightLossInterstitialSlides.recommendMindfulEating);
        }
        break;
      case ErnWeightLossInterstitialSlides.QuestionTrackWeight:
        if(this.form.get('weight').value) {
          this.navigateToNamedSlide(ErnWeightLossInterstitialSlides.RecommendWeightLoss);
        } else {
          this.navigateToNamedSlide(ErnWeightLossInterstitialSlides.recommendMindfulEating);
        }
        break;
      default:
        console.error('Slide name not found');
    }
  }

  isStepValid() {
    return true; // true because button will be disabled in html with isStepInvalid
  }

  isStepInvalid(step: string) {
    if (!step) return false;

    switch(this.getSlideName()) {
      case ErnWeightLossInterstitialSlides.QuestionWLContent:
        return this.form.get('content').invalid;
      case ErnWeightLossInterstitialSlides.QuestionTrackWeight:
        return this.form.get('weight').invalid;
      default:
        return false;
    }
  }

  onPathSelected(contentProgramId: ErnContentProgram) {
    this.store.dispatch(new accountActions.DefineContentProgram(contentProgramId));
    this.store.dispatch(new navigationActions.CloseAllModals());

    this.analyticsService.trackEvent(AnalyticsEvents.CompletedErnExtensionAfterMatch, {
      path: contentProgramId,
      confortableFoodContent: this.form.get('content').value,
      confortableTrackWeight: this.form.get('weight').value
    });
  }

  goToThemeWeeks() {
    this.store.dispatch(new navigationActions.OpenModal('SelectThemePage', {
      enterAnimation: 'IonicModalAnimations.slideEnterAnimation',
      leaveAnimation: 'IonicModalAnimations.slideLeaveAnimation'
    }));

    this.analyticsService.trackEvent(AnalyticsEvents.CompletedErnExtensionAfterMatch, {
      path: 'theme-weeks',
      confortableFoodContent: this.form.get('content').value,
      confortableTrackWeight: this.form.get('weight').value
    });
  }
}
