import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NrtSteps, NrtQuestion } from '../../../services/nrt.service';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { NrtStepsCounter } from 'src/app/store/session/reducers/nrt.reducer';
import { NrtStepsCounterService } from 'src/app/services/nrt-steps-counter.service';

@Component({
  selector: 'cl-nrt-survey',
  styleUrls: [
    'nrt.component.scss',
    'nrt-survey.component.scss'
  ],
  template: `
    <ion-grid class="component-content-grid">
      <ion-row>
        <ion-col size="12">
          <ion-row *ngIf="showProgress">
            <ion-col>
              <cl-simple-progress-bar
                [steps]="steps.total"
                [current]="steps.current">
              </cl-simple-progress-bar>
            </ion-col>
          </ion-row>
          <ion-row class="question-holder lateral-padding">
            <ion-col>
              <p [innerHTML]="this.currentQuestion.statement"></p>
            </ion-col>
          </ion-row>
          <ion-row class="lateral-padding">
            <ion-col class="content-wrapper">
              <form>
                <ion-radio-group>
                  <div class="select-button-item" >
                    <cl-radio-list-item
                      *ngFor="let answerKey of currentAnswersKeys"
                      [checked]="this.currentQuestion.resultKey === answerKey"
                      [label]="this.currentQuestion.answers[answerKey]"
                      (click)="onSelected(answerKey)">
                    </cl-radio-list-item>
                  </div>
                </ion-radio-group>
              </form>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  `
})
export class NrtSurveyComponent implements OnInit {

  @Input() questions: NrtQuestion[];
  @Input() nrtStep: NrtSteps;
  @Input() showProgress: Boolean = false;
  @Output() selected = new EventEmitter();

  currentQuestion: any = {};
  currentAnswersKeys: string[] = [];

  steps: NrtStepsCounter;

  constructor(
    public store: Store<State>,
    private nrtStepsCounterService: NrtStepsCounterService
  ) {}

  ngOnInit(): void {
    this.nrtStepsCounterService.init(this.nrtStep, { total: Object.keys(this.questions).length });
    this.nrtStepsCounterService.surveyStepsCounter$.subscribe((stepsCounter) => {
      this.steps = stepsCounter[this.nrtStep];
      this.showQuestion(this.steps.current);
    });
  }

  private showQuestion(currentStep) {
    const currentQuestionKey = Object.keys(this.questions)[currentStep - 1];
    this.currentQuestion = this.questions[currentQuestionKey];
    this.currentAnswersKeys = Object.keys(this.currentQuestion.answers);
  }

  onSelected(value: string) {
    this.currentQuestion.resultKey = value;

    switch (value.toLowerCase()) {
      case 'true':
        this.currentQuestion.result = true;
        break;
      case 'false':
        this.currentQuestion.result = false;
        break;
      default:
        this.currentQuestion.result = value;
        break;
    }
    this.selected.emit();
  }
}
