export const dppMotivation = [
  {
    label: 'account_setup.questions.my_healthcare_professional', value: 'my_healthcare_professional'
  },
  {
    label: 'account_setup.questions.blood_test_results', value: 'blood_test_results'
  },
  {
    label: 'account_setup.questions.prediabetes_risk_test', value: 'prediabetes_risk_test'
  },
  {
    label: 'account_setup.questions.someone_from_church_or_community', value: 'someone_from_church_or_community'
  },
  {
    label: 'account_setup.questions.employer_wellness_plan', value: 'employer_wellness_plan'
  },
  {
    label: 'account_setup.questions.health_insurance_plan', value: 'health_insurance_plan'
  },
  {
    label: 'account_setup.questions.media_ads', value: 'media_ads'
  },
  {
    label: 'account_setup.questions.friends_family', value: 'friends_family'
  }
];

export const wlMotivation = [
  {
    label: 'account_setup.questions.improve_overall_health', value: 'improve_overall_health'
  },
  {
    label: 'account_setup.questions.enjoy_better_sleep', value: 'enjoy_better_sleep'
  },
  {
    label: 'account_setup.questions.feel_more_energetic', value: 'feel_more_energetic'
  },
  {
    label: 'account_setup.questions.boost_sex_drive', value: 'boost_sex_drive'
  },
  {
    label: 'account_setup.questions.reduce_or_eliminate_pain', value: 'reduce_or_eliminate_pain'
  },
  {
    label: 'account_setup.questions.enhance_mobility_and_strength', value: 'enhance_mobility_and_strength'
  },
  {
    label: 'account_setup.questions.improve_mood_lower_stress', value: 'improve_mood_lower_stress'
  },
  {
    label: 'account_setup.questions.feel_confident_in_my_own_skin', value: 'feel_confident_in_my_own_skin'
  }
];

export const doctorMotivation = [
  {
    label: 'account_setup.questions.yes_a_doctors_office', value: 'yes_a_doctors_office'
  },
  {
    label: 'account_setup.questions.yes_a_pharmacist', value: 'yes_a_pharmacist'
  },
  {
    label: 'account_setup.questions.yes_other_healthcare_professional', value: 'yes_other_healthcare_professional'
  },
  {
    label: 'account_setup.questions.no_healthcare_professional_asked_me_to_join', value: 'no_healthcare_professional_asked_me_to_join'
  }
];

export const education = [
  {
    label: 'account_setup.questions.less_than_grade_12', value: 'less_than_grade_12'
  },
  {
    label: 'account_setup.questions.grade_12_or_ged', value: 'grade_12_or_ged'
  },
  {
    label: 'account_setup.questions.some_college_or_technical_school', value: 'some_college_or_technical_school'
  },
  {
    label: 'account_setup.questions.college_or_tech_school_graduate_or_higher', value: 'college_or_tech_school_graduate_or_higher'
  }
];

export const receivedScale = [
  {
    label: 'account_setup.questions.do_have_scale', value: 'do_have_scale'
  },
  {
    label: 'account_setup.questions.dont_have_scale', value: 'dont_have_scale'
  }
];
