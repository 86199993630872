// social actions
import { Action } from '@ngrx/store';
import { Post, PostFeed } from '../../normalized/schemas/social.schema';
import { PostsUserFilter } from '../models/social.model';

export const INIT_ACTION_CABLE = '[social] Init Action Cable';

// load one post (when new post is created)
export const LOAD_POST_BY_ID = '[social] Load Post By Id';
export const LOAD_POST_BY_ID_SUCCESS = '[social] Load Post By Id Success';

// load posts
export const LOAD_POSTS = '[social] Load Posts';
export const LOAD_POSTS_SUCCESS = '[social] Load Posts Success';
export const LOAD_POSTS_FAIL = '[social] Load Posts Fail';

// reload after resuming
export const RELOAD_ON_RESUME = '[social] Reload Posts On Resume';
export const RELOAD_ON_RESUME_SUCCESS = '[social] Reload Posts On Resume Success';

// reload posts
export const RELOAD_POSTS = '[social] Reload Posts';
export const RELOAD_POSTS_SUCCESS = '[social] Reload Posts Success';
export const RELOAD_POSTS_FAIL = '[social] Reload Posts Fail';

// save post
export const SAVE_POST = '[social] Save Post';
export const SAVE_POST_SUCCESS = '[social] Save Post Success';
export const SAVE_POST_FAIL = '[social] Save Post Fail';

export const POST_BY_ID = '[social] Get Post By Id';
export const OPEN_POST = '[social] Open Post';

// posts filters
export const SET_POSTS_FILTER = '[social] Set Posts Filter';
export const SET_POSTS_USER_FILTER = '[social] Set Posts User Filter';

export const SET_POSTS_PAGE = '[social] Set Posts Page';

export const TOGGLE_LIKE_POST = '[social] Toggle Like Post';
export const TOGGLE_LIKE_POST_SUCCESS = '[social] Toggle Like Post Success';
export const TOGGLE_LIKE_POST_FAIL = '[social] Toggle Like Post Fail';

// save temp post
export const SAVE_TEMP_POST = '[social] Save temp Post';
export const SAVE_TEMP_POST_SUCCESS = '[social] Save temp Post Success';
export const SAVE_TEMP_POST_FAIL = '[social] Save temp Post Fail';

// delete post
export const DELETE_POST = '[social] Delete Post';
export const DELETE_POST_SUCCESS = '[social] Delete Post Success';
export const DELETE_POST_FAIL = '[social] Delete Post Fail';

export const RESET_POST_CREATION = '[social] Reset Post Creation';
export const RESET_POST_SUPPRESSION = '[social] Reset Post Suppression';
export const RESET_LIKE_POST = '[social] Reset Like Post';
export const RESET_LIKE_FAIL_POST = '[social] Reset Like Fail Post';

export const NOTIFY_NEW_POSTS = '[social] Notify New Posts';
export const NOTIFY_POST_LIKE = '[social] Notify Post Like';

export const UPDATE_FEED_AFTER_RESUME = '[social] Update Feed After Resume';

export class InitActionCable implements Action {
  readonly type = INIT_ACTION_CABLE;
}

export class LoadPosts implements Action {
  readonly type = LOAD_POSTS;
}

export class ReloadPosts implements Action {
  readonly type = RELOAD_POSTS;
}

export class ReloadOnResume implements Action {
  readonly type = RELOAD_ON_RESUME;
}

export class ReloadOnResumeSuccess implements Action {
  readonly type = RELOAD_ON_RESUME_SUCCESS;

  constructor(public payload: PostFeed) {}
}

export class ReloadPostsSuccess implements Action {
  readonly type = RELOAD_POSTS_SUCCESS;
}

export class ReloadPostsFail implements Action {
  readonly type = RELOAD_POSTS_FAIL;
  constructor(public payload: any) {}
}

export class SaveTempPost implements Action {
  readonly type = SAVE_TEMP_POST;
  constructor(public payload: any) {}
}

export class SaveTempPostSuccess implements Action {
  readonly type = SAVE_TEMP_POST_SUCCESS;
  constructor(public payload: any) {}
}

export class SaveTempPostFail implements Action {
  readonly type = SAVE_TEMP_POST_FAIL;
  constructor(public payload: any) {}
}

export class SavePost implements Action {
  readonly type = SAVE_POST;
  constructor(public payload: any) {}
}

export class SavePostSuccess implements Action {
  readonly type = SAVE_POST_SUCCESS;
  constructor(public payload: Post) {}
}

export class SavePostFail implements Action {
  readonly type = SAVE_POST_FAIL;
  constructor(public payload: any) {}
}

export class LoadPostsSuccess implements Action {
  readonly type = LOAD_POSTS_SUCCESS;
}

export class LoadPostsFail implements Action {
  readonly type = LOAD_POSTS_FAIL;
  constructor(public payload: any) {}
}

export class SetPostsFilter implements Action {
  readonly type = SET_POSTS_FILTER;

  constructor(public payload: string) {}
}

export class SetPostsUserFilter implements Action {
  readonly type = SET_POSTS_USER_FILTER;

  constructor(public payload: PostsUserFilter) {}
}

export class SetPostsPage implements Action {
  readonly type = SET_POSTS_PAGE;

  constructor(public payload: number) {}
}

export class GetPostById implements Action {
  readonly type = POST_BY_ID;

  constructor(public payload: string) {
  }
}

export class OpenPostById implements Action {
  readonly type = OPEN_POST;

  constructor(public payload: string, public redirectOnClose?: string) {}
}

export class ToggleLikePost implements Action {
  readonly type = TOGGLE_LIKE_POST;

  constructor(public payload: string) {
  }
}

export class ToggleLikePostSuccess implements Action {
  readonly type = TOGGLE_LIKE_POST_SUCCESS;

  constructor(public payload: boolean) {}
}

export class ToggleLikePostFail implements Action {
  readonly type = TOGGLE_LIKE_POST_FAIL;

  constructor(public payload: any, public error: any) {}
}

export class DeletePost implements Action {
  readonly type = DELETE_POST;

  constructor(public payload: string) {
  }
}

export class DeletePostSuccess implements Action {
  readonly type = DELETE_POST_SUCCESS;

  constructor(public payload: string) {}
}

export class DeletePostFail implements Action {
  readonly type = DELETE_POST_FAIL;

  constructor(public payload: any) {
  }
}

export class ResetPostCreation implements Action {
  readonly type = RESET_POST_CREATION;
}

export class ResetPostSuppression implements Action {
  readonly type = RESET_POST_SUPPRESSION;
}

export class ResetLikePost implements Action {
  readonly type = RESET_LIKE_POST;
}

export class ResetLikeFailPost implements Action {
  readonly type = RESET_LIKE_FAIL_POST;
}

export class NotifyNewPosts implements Action {
  readonly type = NOTIFY_NEW_POSTS;

  constructor(public payload: any) {}
}

export class NotifyPostLike implements Action {
  readonly type = NOTIFY_POST_LIKE;
  constructor(public payload: any) {}
}

export class LoadPostById implements Action {
  readonly type = LOAD_POST_BY_ID;

  constructor(public payload: number) {}
}

export class LoadPostByIdSuccess implements Action {
  readonly type = LOAD_POST_BY_ID_SUCCESS;

  constructor(public payload: Post) {}
}

export class UpdateFeedAfterResume implements Action {
  readonly type = UPDATE_FEED_AFTER_RESUME;
}

export type SocialActions =
  | InitActionCable
  | ResetLikeFailPost
  | ResetLikePost
  | ResetPostCreation
  | ResetPostSuppression
  | ReloadPosts
  | ReloadPostsSuccess
  | ReloadPostsFail
  | SaveTempPost
  | SaveTempPostSuccess
  | SaveTempPostFail
  | SavePost
  | SavePostSuccess
  | SavePostFail
  | LoadPosts
  | LoadPostsSuccess
  | LoadPostsFail
  | SetPostsFilter
  | SetPostsUserFilter
  | SetPostsPage
  | GetPostById
  | ToggleLikePost
  | ToggleLikePostSuccess
  | ToggleLikePostFail
  | OpenPostById
  | DeletePostSuccess
  | NotifyNewPosts
  | NotifyPostLike
  | LoadPostById
  | LoadPostByIdSuccess
  | ReloadOnResume
  | ReloadOnResumeSuccess
  | UpdateFeedAfterResume;
