<ion-row class="step step1 lateral-padding" *ngIf="currentStep === 1">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{'wizards.stress_test_header_1' | translate}}</h2>
    <p  class="ion-text-left">{{'wizards.please_select_all_the_apply' | translate}}</p>

    <cl-list-multiple-select
      [items]="feelings"
      [checkboxStyle]="'white'"
      (selectItem)="onFeelingSelected($event)"
      [selected]="feelingChoice">
    </cl-list-multiple-select>
  </ion-col>
</ion-row>

<ion-row class="step step2 lateral-padding" *ngIf="currentStep === 2">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{'wizards.how_many_hours_ago' | translate}}</h2>

    <cl-hours-ago-selector (eatAgo)="onEatAgo($event)" [sliderValue]="hoursAgo"></cl-hours-ago-selector>
  </ion-col>
</ion-row>

<ion-row class="step step3 lateral-padding" *ngIf="currentStep === 3">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{'wizards.learn_about_your' | translate}} {{feelingChoice.length}}
      {{(feelingChoice.length > 1 ? 'wizards.feelings' : 'wizards.feeling') | translate}}</h2>

    <cl-accordion [items]="feelingChoice"></cl-accordion>
  </ion-col>
</ion-row>

<ion-row class="step lateral-padding" *ngIf="currentStep === 4">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{header | translate}}</h2>
    <div class="exercises-list ion-text-left">
      <exercises-group
        [exercises]="(exercises$ | async).recommended"
        [title]="'wizards.recommended' | translate"
        (playExercise)="playExercise($event)"
        [isWizard]="true">
      </exercises-group>

      <exercises-group
        [exercises]="(exercises$ | async).other"
        (playExercise)="playExercise($event)"
        [title]="'wizards.other_exercises' | translate"
        class="stress-meter"
        [isWizard]="true">
      </exercises-group>
    </div>
  </ion-col>
</ion-row>

<ion-row class="step lateral-padding" *ngIf="currentStep === 5">
  <ion-col class="ion-text-center">
    <cl-play-exercise
      [exercise]="exerciseToPlay"
      [isWizard]="true"
      [playerController]="playerController">
    </cl-play-exercise>
  </ion-col>
</ion-row>
