import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  OnChanges,
  ViewChild } from '@angular/core';

import { IonContent } from '@ionic/angular';
import { scrollToElement } from '../../../utils';

@Component({
  selector: 'cl-inner-item',
  styleUrls: ['inner-step.component.scss'],
  template: `
    <button #actionButton (click)="onClick($event)" [class.active]="active">
      <div class="check-item" [class.checked]="completed">
        <i></i>
      </div>
      <span [innerHTML]="label | translate"></span>
    </button>
  `
})
export class InnerItemComponent implements OnChanges {

  @HostBinding('class.active') @Input() active = false;
  @ViewChild('actionButton', { static: true }) nav;

  @Input() label: string;
  @Input() completed: boolean;
  @Output() changeItem = new EventEmitter();

  constructor(private content: IonContent) { }

  onClick($event) {
    $event.stopPropagation();
    this.changeItem.emit();
  }

  scrollTo() {
    if (!this.content || (this.content && !this.content['el'])) {
      return;
    }
    scrollToElement(this.content['el'], this.nav.nativeElement);
  }

  ngOnChanges(changes) {
    if (changes.active) {
      const { currentValue, previousValue } = changes.active;
      if (currentValue !== previousValue && currentValue === true) {
        this.scrollTo();
      }
    }
  }

}
