import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { OfflineHttpProvider } from './http/offline-http.provider';
import { AnxietyQuiz } from '../store/normalized/schemas/anxiety-quiz.schema';

@Injectable({providedIn: 'root'})
export class AnxietyQuizzesProvider {

  private anxietyQuizzesEndpoint = '/anxiety_quizzes';

  constructor(
    private offlineHttp: OfflineHttpProvider,
    protected http: HttpProvider
  ) {

  }

  public trackAnxietyQuiz(anxietyQuiz) {
    return this.offlineHttp.patch(this.anxietyQuizzesEndpoint + '/' + anxietyQuiz.date, anxietyQuiz);
  }

  loadAnxietyQuizzes() {
    return this.http.get<AnxietyQuiz[]>(this.anxietyQuizzesEndpoint);
  }
}
