import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthToken } from '../store/session/models/auth-token.model';
import { HttpProvider } from './http/http.provider';

interface TemporaryTokenResponse {
  token: string;
}

@Injectable({providedIn: 'root'})
export class TemporaryTokenProvider {
  private temporaryTokenEndpoint = '/temporary_tokens';
  private iridiumTemporaryTokenEndpoint = '/iridium/token';

  constructor(private http: HttpProvider) {}

  /**
   * These temporary tokens safely authenticate the user on other domains
   *
   * @param payload HttpProvider params
   * @returns Observable<string>
   */
  generateTemporaryToken(payload = {}): Observable<string> {
    return this.http.post<TemporaryTokenResponse>(this.temporaryTokenEndpoint, payload)
      .pipe(map(response => response.token));
  }

  /**
   * These temporary tokens authenticates the user in Iridium
   *
   * @returns Observable<AuthToken>
   */
  getTemporaryTokenAndUriForIridium(): Observable<AuthToken> {
    const params = new HttpParams().set('type', 'temporary_token');

    return this.http.get<AuthToken>(this.iridiumTemporaryTokenEndpoint, { params });
  }
}
