import { Injectable } from '@angular/core';
import { ClarityConfig } from '../../config/clarity.config';
import {BrightcovePlayer, DownloadStatus} from 'capacitor-brightcove-player';
import { LoggerService } from '../logger.service';
import * as mediaActions from '../../store/persistent/media/media.actions';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';


@Injectable({providedIn: 'root'})
export class BrightcoveNativePlayerLoaderService {

  constructor(
    private config: ClarityConfig,
    private loggerService: LoggerService,
    private store: Store<State>
  ) {}

  async initialize() {
    if (!this.config.isBrightcoveEnabled()) {
      return Promise.resolve(true);
    }

    if (!this.config.isDevice) {
      this.loggerService.error('BrightcovePlayerService: ERROR Trying to load native player on a web context');

      return Promise.reject();
    }

    if (this.config.isAndroid && this.config.isBrightcoveDownloadEnabled()) {
      BrightcovePlayer.setDownloadNotifications({
        enabled: false
      }).catch(error => {
        this.loggerService.error('Error setting brightcove download notifications', error);
        throw error;
      });


      // if for any reason, some brightcove media failed to download and have the FAILED status, we delete them directly.
      // This is done from CLARITY-1299, but this method is necessary for users who already have files with the status FAILED.
      const downloads = await BrightcovePlayer.getDownloadedMediasState()
        .catch(error => {
          this.loggerService.error('Error getting downloaded medias state at app startup', error);
          throw error;
        });

      downloads.medias.map(media => {
        if(media.status === DownloadStatus.FAILED) {
          BrightcovePlayer.deleteDownloadedMedia({fileId: media.mediaId})
            .catch(error => {
              this.loggerService.error('Error deleting failed downloaded medias at app startup', error);
              throw error;
            });
        }
      });
    }

    // Delete non brightcove downloads.
    // This action & related effects & reducers can be removed once Brightcove is fully implemented
    this.store.dispatch(new mediaActions.RemoveAllGoogleFiles());

    BrightcovePlayer.updateBrightcoveAccount({
      accountId: this.config.env.brightcove.accountId,
      policyKey: this.config.env.brightcove.policyKey
    }).catch(error => {
      this.loggerService.error('Error update brightcove account', error);
      throw error;
    });

    return Promise.resolve(true);
  }
}
