import * as cigTrakerActions from '../actions/cig-tracker.actions';

// tracks the current day's count and smokefree state.
export interface CigTrakerState {
  count: number;
  smokeFree: boolean;
}

export const initialCigTrackerState: CigTrakerState = {
  count: 0,
  smokeFree: false
};

export function cigTrackerReducer(
  state = initialCigTrackerState,
  action: cigTrakerActions.CigTrackerActions): CigTrakerState {

  switch (action.type) {
    case cigTrakerActions.SET_SMOKE_FREE: {
      return {
        ...state,
        smokeFree: action.payload
      };
    }
  }

  return state;
}

export const cigCount = (state: CigTrakerState) => state.count;
export const smokeFree = (state: CigTrakerState) => state.smokeFree;
