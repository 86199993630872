import { Component } from '@angular/core';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { SessionState } from '../../store/session/session.reducers';
import { NavController, NavParams } from '@ionic/angular';
import { addMonths, format } from 'date-fns';

@Component({
  selector: 'page-interstitial-dpp-lock',
  styleUrls: ['interstitial-dpp-lock.scss'],
  templateUrl: 'interstitial-dpp-lock.html'
})
export class InterstitialDppLockPage {
  accessDate: string;

  constructor(
    private store: Store<SessionState>,
    private nav: NavController,
    public navParams: NavParams
  ) {
    this.accessDate =  format(this.navParams.get('accessDate'),'EEEE MMM do');
  }

  onClick() {
    this.store.dispatch(new navigationActions.CloseAllModals());
  }

  goToCoaching() {
    this.store.dispatch(new navigationActions.CloseAllModals());
    this.nav.navigateRoot('tabs/coaching');
  }
}
