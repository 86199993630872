import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cl-waves-background',
  styleUrls: ['waves-background.component.scss'],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="background-image">
      <ng-content></ng-content>
    </div>
  `
})

export class WavesBackgroundComponent {
}
