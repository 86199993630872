import { Injectable } from '@angular/core';
import { NrtProvider } from '../providers/nrt.provider';
import { catchError, map } from 'rxjs/operators';
import { of, throwError, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { State } from '../store';
import { Store } from '@ngrx/store';
import { GetAccess } from '../store/session/actions/nrt.actions';
import { getCurrentUserProgram } from '../store/normalized/selectors/user.selectors';
import { SmokingType } from '../store/normalized/schemas/user.schema';

export interface ValidateShippingDataResponse {
  isValid: boolean;
  data: any;
}

export enum NrtSteps {
  INTRO = 'introduction',
  MEDICAL_CONDITION_SURVEY = 'medical_condition_survey',
  PRODUCT_SURVEY = 'product_survey',
  PRODUCT = 'product_selection',
  FLAVOR_SELECTION = 'flavor_selection',
  SHIPPING = 'shipping',
  ORDER_SUCCESS = 'orderSuccess',
  ASSERTIONS = 'assertions',
  PROVIDER = 'provider',
  DOCTOR_SUCCESS = 'doctorSuccess'
}

export enum Flavors {
  CINNAMON = 'cinnamon',
  FRUIT = 'fruit',
  MINT = 'spearmint',
  ICE = 'ice'
}

export enum NrtProductType {
  PATCH = 'patch',
  GUM = 'gum',
  LOZENGE = 'lozenge'
}

export interface NrtQuestion {
  statement: string;
  answers: any;
  result?: string;
  resultKey?: string;
  assertion?: string;
}

@Injectable({providedIn: 'root'})
export class NrtService {

  private readonly _initialShippingData = {
    address1: null,
    address2: null,
    city: null,
    email: null,
    first_name: null,
    last_name: null,
    phone: null,
    state: 'AL',
    zipCode: null
  };

  private readonly _initialProviderData = {
    provider_full_name: null,
    provider_fax: null,
    provider_phone: null
  };

  userProgram$ = this.store.select(getCurrentUserProgram);
  smokingType: string;

  constructor(
    private nrtProvider: NrtProvider,
    private translateService: TranslateService,
    private store: Store<State>
  ) {
    this.userProgram$.subscribe((userProgram) => {
      this.smokingType = userProgram.smoking_type;
      this.reinitData();
      this.getSurveyTranslations();
    });
  }

  private _gumFlavor: Flavors = null;

  public get gumFlavor() {
    return this._gumFlavor;
  }

  public set gumFlavor(flavor: Flavors) {
    this._gumFlavor = flavor;
  }

  private _shippingData;

  public get shippingData() {
    return this._shippingData;
  }

  public set shippingData(data) {
    this._shippingData = {...data};
  }

  private _providerData;

  public get providerData() {
    return this._providerData;
  }

  public set providerData(data) {
    this._providerData = {...data};
  }

  private _shippingValid: boolean;

  public get shippingValid(): boolean {
    return this._shippingValid;
  }

  public set shippingValid(valid: boolean) {
    this._shippingValid = valid;
  }

  private _providerValid: boolean;

  public get providerValid(): boolean {
    return this._providerValid;
  }

  public set providerValid(valid: boolean) {
    this._providerValid = valid;
  }

  private _medicalConditionQuestions;

  public get medicalConditionQuestions() {
    return this._medicalConditionQuestions;
  }

  private _productQuestions;

  public get productQuestions() {
    return this._productQuestions;
  }

  public get productValid() {
    // used in nrt.ts
    return this.productQuestions.type.result;
  }

  public get productIsGum() {
    return this._productQuestions.type.result === NrtProductType.GUM;
  }

  private get orderData() {
    return {
      order: {
        first_name: this.shippingData.first_name,
        last_name: this.shippingData.last_name,
        phone_number: this.shippingData.phone,
        email: this.shippingData.email,
        date_of_birth: null,
        address: {
          address1: this.shippingData.address1,
          address2: this.shippingData.address2,
          city: this.shippingData.city,
          state_or_province: this.shippingData.state,
          postal_code: this.shippingData.zipCode
        }
      },
      survey: {
        med_condition: {
          pregnancy: this._medicalConditionQuestions.pregnancy.result,
          heart_disease: this._medicalConditionQuestions.heart_disease.result,
          high_blood_pressure: this._medicalConditionQuestions.high_blood_pressure.result,
          stop_smoking_drug: this._medicalConditionQuestions.stop_smoking_drug.result,
          asthma: this._medicalConditionQuestions.asthma.result,
          blood_thinners: this._medicalConditionQuestions.blood_thinners.result,
          mental_illness: this._medicalConditionQuestions.mental_illness.result,
          diabetes: this._medicalConditionQuestions.diabetes.result,
          stomach_ulcer: this._medicalConditionQuestions.stomach_ulcer.result,
          nrt_different_types_when_quit: this._medicalConditionQuestions.nrt_different_types_when_quit.result
        },
        product: {
          type: this._productQuestions.type.result,
          more_than_ten_cigs: this._productQuestions.more_than_ten_cigs.result,
          first_cig_after_30_min: this.isFirstCigAfterThirtyMin(this._productQuestions.first_cig_after_30_min.result),
          time_to_first_use: this._productQuestions.first_cig_after_30_min.answers[this._productQuestions.first_cig_after_30_min.result],
          flavor: this._gumFlavor
        },
        provider: this.providerData
      }
    };
  }

  public reinitData() {
    this.reinitSurvey1();
    this.reinitSurvey2();
    this.reinitShippingData();
    this.reinitProviderData();
    this.gumFlavor = null;
  }

  public reinitSurvey1() {
    if (!this._medicalConditionQuestions) {
      return;
    }
    Object.values(this._medicalConditionQuestions)
      .forEach((q: NrtQuestion) => q.result = q.resultKey = null);
  }

  public reinitSurvey2() {
    if (!this._productQuestions) {
      return;
    }
    Object.values(this._productQuestions)
      .forEach((q: NrtQuestion) => q.result = q.resultKey = null);
  }

  public reinitShippingData() {
    this._shippingData = {...this._initialShippingData};
  }

  public reinitProviderData() {
    this._providerData = {...this._initialProviderData};
  }

  public getAssertions() {
    return Object.values(this._medicalConditionQuestions)
      .filter((q: NrtQuestion) => q.result && q.assertion);
  }

  public hasAssertions() {
    return !!this.getAssertions().length;
  }

  public validateShippingData(): Observable<ValidateShippingDataResponse> {
    return this.nrtProvider.validateAddress(this.shippingData)
      .pipe(
        map((data) => ({
          isValid: true,
          data
        })),
        catchError((error) => {
          // 400 status is invalid address
          if (error.status === 400) {
            return of({
              isValid: false,
              data: error
            });
          }

          return throwError(error);
        })
      );
  }

  public order() {
    return this.nrtProvider.order(this.orderData);
  }

  public getNrtAccess() {
    this.store.dispatch(new GetAccess());
  }

  public isFirstCigAfterThirtyMin(timeToFirstUse) {
    return timeToFirstUse === 'sixtyMinutes' || timeToFirstUse === 'moreThanSixtyMinutes';
  }

  private getSurveyTranslations() {
    this.translateService.get('nrt.medical_condition_survey.questions')
      .subscribe((translations) => {
        this._medicalConditionQuestions = translations;
      });
    
    this.translateService.get(this.smokingType === SmokingType.CIGARETTE ? 'nrt.product_survey.questions' : 'nrt.product_survey.questions_vape')
      .subscribe(async (translations) => {
        this._productQuestions = translations;
      });
  }
}
