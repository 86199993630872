import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { CravingToolPage } from './craving-tool';
import { SharedModule } from '../../../shared/index';
import { InnerItemComponent } from './components/inner-step.component';
import { SimulationStepComponent } from './components/simulation-step.component';
import { AfterSimulationStepComponent } from './components/after-simulation.component';
import { MindfulEatingExerciseComponent } from './components/mindful-eating-exercise.component';
import { AfterEatingExerciseSatisfactionComponent } from './components/after-eating-exercise-satisfaction.component';
import { AfterEatingExerciseQuantityComponent } from './components/after-eating-exercise-quantity.component';
import { AfterMinutesCheckinStepComponent } from './components/after-minutes-checkin-step.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    CravingToolPage,
    InnerItemComponent,
    SimulationStepComponent,
    AfterSimulationStepComponent,
    MindfulEatingExerciseComponent,
    AfterEatingExerciseSatisfactionComponent,
    AfterEatingExerciseQuantityComponent,
    AfterMinutesCheckinStepComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule.forChild([{ path: '', component: CravingToolPage }]),
    TranslateModule.forChild(),
    SharedModule
  ]
})

export class CravingToolModule {}
