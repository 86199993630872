// integrations reducer

import * as integrationsActions from '../actions/integrations.actions';

export interface IntegrationsState {
  healthKit: boolean;
  googleFit: boolean;
  fitbit: boolean;
  lastSynchronizationDate: string;
  googleFitAuthorizationsNotDetermined: boolean;
  showInformationDialog: {
    healthKit: boolean;
    googleFit: boolean;
    fitbit: boolean;
  };
}

export const initialIntegrationsState: IntegrationsState = {
  healthKit: false,
  googleFit: false,
  fitbit: false,
  lastSynchronizationDate: null,
  googleFitAuthorizationsNotDetermined: false,
  showInformationDialog: {
    healthKit: false,
    googleFit: false,
    fitbit: false
  }
};

export function integrationsReducer(state = initialIntegrationsState, action: integrationsActions.IntegrationsActions): IntegrationsState {
  switch (action.type) {
    case integrationsActions.CONNECT_HEALTHKIT_SUCCESS : {
      return {
        ...state,
        healthKit: true,
        showInformationDialog: {
          healthKit: true,
          googleFit: false,
          fitbit: false
        }
      };
    }

    case integrationsActions.CONNECT_HEALTHKIT_FAIL : {
      return {
        ...state,
        healthKit: false
      };
    }

    case integrationsActions.DISCONNECT_HEALTHKIT : {
      return {
        ...state,
        healthKit: false,
        showInformationDialog: {
          ...state.showInformationDialog,
          healthKit: false
        }
      };
    }

    case integrationsActions.CONNECT_GOOGLE_FIT_SUCCESS : {
      return {
        ...state,
        googleFit: true,
        googleFitAuthorizationsNotDetermined: false,
        showInformationDialog: {
          healthKit: false,
          googleFit: true,
          fitbit: false
        }
      };
    }

    case integrationsActions.CONNECT_GOOGLE_FIT_FAIL : {
      return {
        ...state,
        googleFit: false
      };
    }

    case integrationsActions.DISCONNECT_GOOGLE_FIT : {
      return {
        ...state,
        googleFit: false,
        googleFitAuthorizationsNotDetermined: false,
        showInformationDialog: {
          ...state.showInformationDialog,
          googleFit: false
        }
      };
    }

    case integrationsActions.CONNECT_FITBIT_SUCCESS : {
      return {
        ...state,
        fitbit: true,
        showInformationDialog: {
          healthKit: false,
          googleFit: false,
          fitbit: true
        }
      };
    }

    case integrationsActions.CONNECT_FITBIT_FAIL : {
      return {
        ...state,
        fitbit: false
      };
    }

    case integrationsActions.DISCONNECT_FITBIT_SUCCESS : {
      return {
        ...state,
        fitbit: false,
        showInformationDialog: {
          ...state.showInformationDialog,
          fitbit: false
        }
      };
    }

    case integrationsActions.GOOGLE_FIT_AUTHORIZATIONS_NOT_DETERMINED : {
      return {
        ...state,
        googleFitAuthorizationsNotDetermined: true
      };
    }
    case integrationsActions.GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE_SUCCESS : {
      return {
        ...state,
        fitbit: action.isConnected
      };
    }

    case integrationsActions.GET_FITBIT_CONNECTION_STATUS_AND_NAVIGATE_FAIL : {
      return {
        ...state,
        fitbit: false
      };
    }

    case integrationsActions.POST_HEALTH_DATA_SUCCESS : {
      return {
        ...state,
        lastSynchronizationDate: action.payload.newSynchronizationDate
      };
    }

    case integrationsActions.SHOW_INTEGRATION_STATUS_MODAL : {
      if(action.integrationResult.type === 'information') {
        return {
          ...state,
          showInformationDialog: {
            healthKit: false,
            googleFit: false,
            fitbit: false
          }
        };
      } else {
        return state;
      }
    }
  }

  return state;
}

export const integrations = (state: IntegrationsState) => state;
export const healthKitConnected = (state: IntegrationsState) => state.healthKit;
export const googleFitConnected = (state: IntegrationsState) => state.googleFit;
export const fitbitConnected = (state: IntegrationsState) => state.fitbit;
export const integrationConnected = (state: IntegrationsState) => state.fitbit || state.googleFit || state.healthKit;
export const lastSynchronizationDate = (state: IntegrationsState) => state.lastSynchronizationDate;
export const googleFitAuthorizationsNotDetermined = (state: IntegrationsState) => state.googleFitAuthorizationsNotDetermined;
export const showInformationDialog = (state: IntegrationsState) => state.showInformationDialog;
