export enum AudioPlayerStatus {
  ERROR,
  NONE,
  MEDIA_LOADING,
  MEDIA_LOADED,
  POSITION,
  STARTING,
  RUNNING,
  PAUSED,
  STOPPED,
  ENDED
}
