import { createSchemaSelectors } from 'ngrx-normalizr';
import { RecentStressMeter, recentStressMeterSchema } from '../schemas/recent-stress-meter.schema';
import { createSelector } from '@ngrx/store';
import { getTriggers } from './list-items.selectors';

const recentStressMeterSchemaSelectors = createSchemaSelectors<RecentStressMeter>(recentStressMeterSchema);

export const getRecentStressMeters = recentStressMeterSchemaSelectors.getEntities;

export const getMostRecentStressMeter = createSelector(
  getRecentStressMeters,

  (recentStressMeters) => {

    if (!recentStressMeters || recentStressMeters.length === 0) {
      return ({} as RecentStressMeter);
    }

    return recentStressMeters[0];
  }
);

export const getRecentTriggerName = createSelector(
  getTriggers,
  getMostRecentStressMeter,
  (triggers = [], recentTrigger) => {
    const { trigger_id } = recentTrigger;
    const resolvedRecentTrigger = triggers.find((trigger) => trigger.id === trigger_id);
    if (!trigger_id || !resolvedRecentTrigger) {
      return null;
    }

    return `${resolvedRecentTrigger.name}`;
  }
);
