import { createSchemaSelectors, getNormalizedEntities } from 'ngrx-normalizr';
import { createSelector } from '@ngrx/store';
import { UserFavorite, userFavoriteSchema } from '../../normalized/schemas/user.schema';
import { getUserFavoritesState } from '../../state.reducer';
import * as userFavoritesReducer from '../reducers/user-favorites.reducer';
import { getLiveProgram } from './program.selectors';

const userFavsSchemaSelector = createSchemaSelectors<UserFavorite>(userFavoriteSchema);

const getUserFavsSelector = userFavsSchemaSelector.getEntities;

export const getUserFavs = createSelector(
  getUserFavsSelector,
  getLiveProgram,
  (userFavs, liveProgram) => {
    console.log('$$$ SELECTOR getUserFavs');

    if (!userFavs) {
      return [];
    }
    let modules = liveProgram && liveProgram.weeks ? liveProgram.weeks.map(w => w.modules) : [];
    modules = [].concat.apply([], modules);

    for (const favorite of userFavs) {
      if (favorite.favourable_type === 'ProgramDay' && modules.length) {
        favorite.favourable_record = modules.find((m: any) => m.recordId === favorite.favourable_id);
      }
    }

    return userFavs;
  }
);

export const getUserFavsSorted = createSelector(
  getUserFavs,
  (userFavs) => {
    if (!userFavs) {
      return [];
    }
    const filteredFavs = userFavs.filter((a) => a.favourable_record);

    return filteredFavs.sort((a, b) => (a.favourable_record.number > b.favourable_record.number) ? 1 : -1);
  }
);

export const isUpdatingUserFavs = createSelector(
  getUserFavoritesState,
  userFavoritesReducer.userFavsUpdating
);
