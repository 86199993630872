<!-- step 1 all programs -->
<ion-row class="step step1 lateral-padding" *ngIf="currentStep === 1">
  <ion-col class="ion-text-center">
    <h2 class="step-intro intro-question">{{'wizards.how_are_you_feeling' | translate}}</h2>

    <cl-list-single-select
      [items]="feelings"
      [selected]="feelingChoice"
      (selectItem)="onFeelingSelected($event)">
    </cl-list-single-select>
  </ion-col>
</ion-row>

<!-- step 2 for all programs -->
<ion-row class="step step2 lateral-padding"
         *ngIf="currentStep === 2">
  <ion-col class="ion-text-center">
    <h2 class="checkin-exercise-intro">{{'wizards.checkin_exercises_intro' | translate}}</h2>

    <cl-checkin-exercise
      [graphic]="checkinExercise.graphic"
      [title]="checkinExercise.title"
      [instructions]="checkinExercise.instructions">
    </cl-checkin-exercise>
  </ion-col>
</ion-row>
