import { Component } from '@angular/core';
import { SettingsComponent } from './settings.component';
import { ClarityConfig } from '../../../config/clarity.config';
import { BrowserService } from '../../../services/browser.service';
import { ConnectivityService } from '../../../services/connectivity.service';

@Component({
  selector: 'cl-account-help-web',
  styleUrls: ['help-web.component.scss'],
  template: `
    <h2>{{ 'account_menu.help.title' | translate }}</h2>

    <cl-help-center>
      <ion-row class="action-button help-center-btn">
        <ion-col class="ion-text-center" auto>
          <cl-action-button
                  (action)="openHelpCenter()"
                  class="open_help"
                  [label]="'account_menu.help.open_help_center' | translate"
                  [canClick]="true">
          </cl-action-button>
        </ion-col>
      </ion-row>
    </cl-help-center>

    <p class="welcome-msg">{{ 'account_menu.help.check_welcome_screen' | translate}}</p>
    <cl-onboardings-web-list></cl-onboardings-web-list>
  `
})

export class HelpWebComponent implements SettingsComponent {

  constructor(
    public config: ClarityConfig,
    private browser: BrowserService,
    private connectivity: ConnectivityService
  ) {
  }

  openHelpCenter() {
    if (this.connectivity.preventAccessWhenOffline()) {
      return false;
    }

    this.browser.goTo(this.config.env.helpCenterUrl);
  }
}
