import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-next-button',
  styleUrls: ['next-button.component.scss'],
  template: `
    <ion-button
      color="action"
      expand="full"
      class="next-btn disable-hover button-round"
      type="submit"
      (click)="onClick()"
      [disabled]="!canClick">
      {{label | translate}}
    </ion-button>
  `
})
export class NextButtonComponent {

  @Input() canClick: boolean;
  @Input() label = 'common.next';

  @Output() action = new EventEmitter(); 

  constructor() {}

  onClick() {
    if (!this.canClick) {
      return false;
    }

    this.action.emit();
  }

}
