import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { ClarityConfig } from '../../../config/clarity.config';

@Injectable({providedIn: 'root'})
export class AccountSetupDppGuard implements CanActivate {

  constructor(
    private nav: NavController,
    private config: ClarityConfig
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let response: boolean;

    if (this.config.programDPPorWL()) {
      response = true;
    } else {
      response = false;
      this.nav.navigateRoot('account-setup');
    }

    return response;
  }
}
