import * as toolsActions from '../actions/tools.actions';

export interface ToolsNowState {
  isStressMeterActive: boolean;
  exerciseId: number;
}

export const initialToolsNowState: ToolsNowState = {
  isStressMeterActive: false,
  exerciseId: 0
};

export function toolsReducer(state = initialToolsNowState, action: toolsActions.ToolsActions): ToolsNowState {
  switch (action.type) {
    case toolsActions.START_STRESS_METER: {
      return {
        ...state,
        isStressMeterActive: true
      };
    }

    case toolsActions.STOP_STRESS_METER: {
      return {
        ...state,
        isStressMeterActive: false
      };
    }

    case toolsActions.SAVE_STRESS_METER: {
      return {
        ...state,
        isStressMeterActive: false
      };
    }

    case toolsActions.RETURN_STRESS_METER: {
      return {
        ...state,
        exerciseId: action.payload
      };
    }
  }

  return state;
}

export const isStressMeterActive = (state: ToolsNowState) => state && state.isStressMeterActive ? true : false;
export const exerciseId = (state: ToolsNowState) => state && state.exerciseId ? state.exerciseId : 0;
