import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'daysQuitRelativeTime'
})
export class DaysQuitRelativeTimePipe implements PipeTransform {
  
  constructor(private translate: TranslateService) {}
  
  getDaysQuitRelativeTime(time): string {

    if (!time) return;

    if (typeof(time) === 'string') {
      time = moment(time);
    }

    const date = moment.utc(time);
    const local_date = date.local();
    const now = moment();
    const daysInterval = now.diff(local_date.format(), 'days');
    
    return daysInterval > 1 ?
      `${daysInterval} ${this.translate.instant('social.posts.days_quit')}` :
      `${daysInterval} ${this.translate.instant('social.posts.day_quit')}`;
  }
  
  transform(time): string {
    return this.getDaysQuitRelativeTime(time);
  }
  
}
