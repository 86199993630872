<ion-content>

  <ion-header>
    <cl-header-nav-buttons
      color="blank"
      [autoClose]="true"
      [backButton]="!isModal"
      (back)="onBack()"
      class="page-header"
    ></cl-header-nav-buttons>
  </ion-header>

  <div class="page-background"></div>

  <div class="page-wrapper lateral-padding">
    <h2 class="title">{{'myjourney.create_your_week' | translate}}</h2>

    <cl-shadow-wrapper>
      <ion-row>
        <ion-col class="info-card ion-text-left">
          <h2 class="intro">{{'custom_path.custom_path' | translate}}</h2>
          <p>{{'custom_path.custom_path_details' | translate: {maxModules: maxModules} }}</p>
        </ion-col>
      </ion-row>
    </cl-shadow-wrapper>

    <cl-control-buttons
      [darkBackground]="true"
      nextLabel="common.next"
      [canNext]="isCoreProgramCompleted | async"
      (back)="onBack()"
      (next)="onNext()">
    </cl-control-buttons>

    <p class="not-available-warning"
       *ngIf="!(isCoreProgramCompleted | async)">
      {{'myjourney.feature_available_after_core_program' | translate}}
    </p>
  </div>

  <div class="waves-bg"></div>

  <div class="bottom-bg-background"></div>
</ion-content>
