import { Component, OnDestroy, OnInit, HostBinding, Input } from '@angular/core';

import { NavController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';

import { ClarityConfig } from '../../config/clarity.config';
import { SubscriptionsService } from '../../services/subscriptions/subscriptions.service';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import * as accountActions from '../../store/session/actions/account.actions';
import * as syncActions from '../../store/session/actions/sync.actions';
import { getLiveActiveSubscription, getLiveSubscription } from '../../store/normalized/selectors/subscription.selectors';
import { State } from '../../store/state.reducer';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {
  isCurrentUserShacareConsumer,
  isCurrentUserSharecareStandalone, isSharecareSsoProvider
} from '../../store/normalized/selectors/user.selectors';
import { LoadStripePlans, ResetProgramCode } from '../../store/session/actions/subscription.actions';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';
import { TrackActivity } from 'src/app/store/session/actions/user-activity.actions';

@Component({
  selector: 'page-upgrade',
  styleUrls: ['upgrade.scss'],
  templateUrl: 'upgrade.html'
})
export class UpgradePage implements OnInit, OnDestroy {

  @HostBinding('class.desktop') isDesktop = this.config.isWebApp;

  @Input() forceShowSuccessMessage = false;

  modal;
  liveSubscription$ = this.store.select(getLiveSubscription);

  shouldShowSuccessMessage = this.forceShowSuccessMessage;

  paramsSubscription: Subscription;
  canUseLicense = false;
  fromAccountSetup;

  public isSharecareSso = false;
  public isSharecareStandalone = false;
  public isSharecareConsumer = false;

  constructor(
    public store: Store<State>,
    public config: ClarityConfig,
    public navCtrl: NavController,
    private subscriptionsService: SubscriptionsService,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {
    this.paramsSubscription = this.route.queryParams.subscribe(((params) => {
      this.fromAccountSetup = params.fromAccountSetup || null;
    }));

    this.store.select(isSharecareSsoProvider)
      .pipe(take(1))
      .subscribe((isSharecareSso) => this.isSharecareSso = isSharecareSso);

    this.store.select(isCurrentUserSharecareStandalone)
      .pipe(take(1))
      .subscribe((isSharcareStandalone) => this.isSharecareStandalone = isSharcareStandalone);

    this.store.select(isCurrentUserShacareConsumer)
      .pipe(take(1))
      .subscribe((isConsumer) => this.isSharecareConsumer = isConsumer);

    /**
     * When the user buys a license, the subscription state takes some time to be updated,
     * in this case, the user would see the wrong message for some seconds. For this reason,
     * we can recreate the modal with the forceShowSuccessMessage attribute, to show the
     * success message, even if we don't have any subscriptions loaded in the frontend
     */
    this.store.select(getLiveActiveSubscription)
      .pipe(map(Boolean))
      .subscribe(isActive => this.shouldShowSuccessMessage = this.forceShowSuccessMessage || isActive);
  }

  ngOnInit() {
    if (this.forceShowSuccessMessage) {
      this.shouldShowSuccessMessage = true;
    } else {
      // if success message is shown, nothing else appears. So we don't need to load subscription informations
      this.initSubscription();

      if (this.config.stripeWebEnabled()) {
        this.store.dispatch(new LoadStripePlans());
      }
    }
  }

  ionViewDidEnter() {
    this.store.dispatch(new ResetProgramCode());
  }

  onLicense() {
    if (this.fromAccountSetup) {
      this.navCtrl.navigateForward('license', {queryParams: {fromAccountSetup: this.fromAccountSetup}});
    } else {
      this.store.dispatch(new navigationActions.OpenModal('LicensePage'));
    }
  }

  onSubscribe() {
    if (this.fromAccountSetup) {
      this.navCtrl.navigateForward('subscribe', {queryParams: {fromAccountSetup: this.fromAccountSetup}});
    } else {
      this.store.dispatch(new navigationActions.OpenModal('SubscribePage'));
    }
  }

  onRestoreSubscription() {
    this.subscriptionsService.restorePurchases();
  }

  onTrial() {
    if (!this.fromAccountSetup) {
      return this.dismissUpgradePage();
    }

    this.analyticsService.trackEvent(AnalyticsEvents.ClikedFreeTrial);

    this.store.dispatch(new TrackActivity({
      kind: 'click',
      activity_at: new Date(),
      name: 'Free Trial Button'
    }));

    this.store.dispatch(new accountActions.SignupComplete());
  }

  dismissUpgradePage() {
    if (this.fromAccountSetup) {
      // It was open as a page, with the route /upgrade, after account-setup
      this.navCtrl.navigateBack('/account-setup');
    } else {
      this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
    }
  }

  ngOnDestroy() {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  private initSubscription() {
    this.store.dispatch(new syncActions.SyncSubscription());
    this.liveSubscription$
      .pipe(take(1))
      .subscribe((subscription) => {
        if (subscription) {
          this.canUseLicense = subscription.canLicense;
        }
      }, (error) => {
        this.canUseLicense = false;
      });
  }
}
