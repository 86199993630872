import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ClarityConfig } from '../..//config/clarity.config';
import { AlertsService } from '../../services/alerts.service';
import { OpenModal } from '../../store/session/actions/navigation.actions';
import { State } from '../../store';
import { Store } from '@ngrx/store';
import { Conversation } from '../../store/normalized/schemas/my-coach.schema';
import { UserProfile } from '../../store/normalized/schemas/user.schema';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cl-conversation-card',
  styleUrls: ['./conversation-card.component.scss'],
  template: `
    <div class="post-separator" *ngIf="separator === 'top'"></div>
    <div class="entry-container" [ngClass]="{
    'desktop': config.isWebApp,
    'new': entry.unread_messages_count > 0
    }">
      <div class="conversation-container">
        <ion-grid class="conversation-grid">
          <ion-row center *ngIf="!isCoach">
            <ion-col class="avatar-wrapper ion-align-self-center ion-align-items-center" size="1" (click)="onOpenClick()">
              <ion-avatar class="avatar" [ngStyle]="{'background-image': 'url('+ avatar +')'}">
              </ion-avatar>
            </ion-col>

            <ion-col size="6" class="message-holder" (click)="onOpenClick()">
              <div class="message-title">
                {{ messageName }}
              </div>

              <div class="message" *ngIf="entry.content">
                {{entry.content | stripHtml | truncate:5}}
              </div>
              <div class="no-message" *ngIf="!entry.content">
                {{'my-coach.no-content' | translate}}
              </div>
            </ion-col>

            <ion-col class="last-message-date ion-align-self-center ion-align-items-center" size="3" (click)="onOpenClick()">
              {{entry.last_message_at || entry.updated_at | relativeTime}}
            </ion-col>

            <ion-col size="2" class="entry-actions ion-justify-content-end">
              <ion-button class="more-button ion-align-items-center" fill="clear" (click)="onMoreClick()">
                <ion-icon slot="icon-only" name="ellipsis-horizontal" class="more"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row center *ngIf="isCoach">
            <ion-col class="avatar-wrapper ion-align-self-center ion-align-items-center" size="1" (click)="onOpenClick()">
              <ion-avatar class="avatar" [ngStyle]="{'background-image': 'url('+ avatar +')'}">
              </ion-avatar>
            </ion-col>

            <ion-col size="6" class="message-holder" (click)="onOpenClick()">
              <div class="message-title">
                {{ messageName }}
              </div>

              <div class="message">
                {{entry.content | stripHtml | truncate:10}}
              </div>
            </ion-col>

            <ion-col class="last-message-date ion-align-items-center ion-align-self-center ion-text-end" size="3" (click)="onOpenClick()">
              {{entry.last_message_at || entry.updated_at | relativeTime}}
            </ion-col>

            <ion-col size="2" class="ion-justify-content-end entry-actions">
              <ion-button class="more-button ion-align-items-center" fill="clear" (click)="onMoreClick()">
                <ion-icon slot="icon-only" name="ellipsis-horizontal" class="more"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-center" *ngIf="isCoach">
            <ion-col>
              <cl-label [text]="messageOwnerStandaloneClient"></cl-label>
            </ion-col>
            <ion-col>
              <div class="coaches-avatar" *ngIf="involvedCoaches.length">
                <ion-avatar *ngFor="let profile of involvedCoaches">
                  <img src="{{profile.avatar}}" alt=""/>
                </ion-avatar>
                <div class="avatar-overlap" *ngIf="involvedCoaches.length > 10">...</div>
              </div>
              <div class="ion-text-end" *ngIf="!involvedCoaches.length">
                {{'my-coach.conversations.no_answers_yet' | translate}}
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

      </div>
    </div>
    <div class="post-separator" *ngIf="separator === 'bottom'"></div>
  `
})

export class ConversationCardComponent {
  @Input() entry: Conversation = null;
  @Input() separator = 'bottom';
  @Input() isCoach = false;

  @Output() openClick = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  constructor(
    private actionSheetController: ActionSheetController,
    private alerts: AlertsService,
    private translate: TranslateService,
    private store: Store<State>,
    public config: ClarityConfig
  ) {
  }

  get avatar() {
    return this.entry.avatar;
  }

  get messageName() {
    const author = this.entry.last_message_author;
    if (!author) {
      return this.config.program.name;
    }
    const firstName = this.entry.last_message_author.split(' ')[0];

    return `${firstName} from ${this.config.program.name}`;
  }

  get messageOwnerStandaloneClient() {
    const firstProfile = this.getOwnerProfile();

    if (!firstProfile) {
      return null;
    }

    return firstProfile.standalone_client;
  }

  get involvedCoaches(): UserProfile[] {
    if (!this.entry.agents || this.entry.agents.length === 0 || !this.entry.owner_id) {
      return [];
    }

    return this.entry.agents.filter((profile: UserProfile) => parseInt(profile.id, 10) !== this.entry.owner_id);
  }

  private getFirstAgent() {
    if (!this.entry.agents || this.entry.agents.length === 0 || !this.entry.owner_id) {
      return null;
    }

    return this.entry.agents[0];
  }

  private getOwnerProfile() {
    if (!this.entry.profiles || this.entry.profiles.length === 0 || !this.entry.owner_id) {
      return null;
    }

    return this.entry.profiles.find((profile) => parseInt(profile.id, 10) === this.entry.owner_id);
  }

  onOpenClick() {
    this.openClick.emit(this.entry);
  }

  openIncompleteProfilePopup() {
    this.store.dispatch(new OpenModal('ProfileCompletionPage'));
  }

  private async onMoreClickByCoach(translations) {
    const closeConfirm = await this.alerts.alertController.create({
      header: translations['my-coach.conversations.close_conversation'],
      message: translations['my-coach.conversations.close_conversation_confirm'],
      buttons: [
        {
          text: translations['common.no'],
          role: 'cancel'
        },
        {
          text: translations['common.yes'],
          handler: () => {
            this.closeClick.emit(this.entry);
          }
        }
      ]
    });

    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: translations['common.cancel'],
          role: 'cancel',
          icon: 'close-circle'
        },
        {
          text: translations['my-coach.conversations.close_conversation'],
          role: 'destructive',
          icon: 'checkmark',
          handler: () => {
            closeConfirm.present();
          }
        }
      ],
      mode: 'md'
    });

    await actionSheet.present();
  }

  private async onMoreClickByUser(translations) {
    const deleteConfirm = await this.alerts.alertController.create({
      header: translations['my-coach.conversations.delete_conversation'],
      message: translations['my-coach.conversations.delete_conversation_confirmation_message'],
      buttons: [
        {
          text: translations['common.no'],
          role: 'cancel'
        },
        {
          text: translations['common.yes'],
          handler: () => {
            this.delete.emit(this.entry);
          }
        }
      ]
    });

    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: translations['common.cancel'],
          role: 'cancel',
          icon: 'close-circle'
        },
        {
          text: translations['my-coach.conversations.delete_conversation'],
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            deleteConfirm.present();
          }
        }
      ],
      mode: 'md'
    });

    await actionSheet.present();
  }

  onMoreClick() {
    this.translate.get(
      [
        'common.cancel',
        'common.no',
        'common.yes',
        'my-coach.conversations.close_conversation',
        'my-coach.conversations.close_conversation_confirm',
        'my-coach.conversations.delete_conversation',
        'my-coach.conversations.delete_conversation_confirmation_message'
      ])
      .pipe(take(1))
      .subscribe((translations: any) => {
        if (this.isCoach) {
          this.onMoreClickByCoach(translations);
        } else {
          this.onMoreClickByUser(translations);
        }
      });
  }
}
