import * as postActions from '../actions/post.actions';
import { Comment } from '../../normalized/schemas/social.schema';

export interface PostState {
  postLoading: boolean;
  commentsLoading: boolean;
  commentsLoaded: boolean;
  allCommentsLoading: boolean;
  allCommentsLoaded: boolean;
  sendingComment: boolean;
  commentSent: boolean;
  commentId: string;
  likeCommentLoading: boolean;
  likeCommentLoaded: boolean;
  deletedCommentId: string;
  toggleLikeCommentFail: string;
  openPostId: string;
  commentUpdate: {
    postId: string;
    totalCount: number;
  };
  newLikeOnComment: {
    post_id: number;
    avatars: string[];
    likes_count: number;
  };
  newComment: Comment;
}

export const initialPostState: PostState = {
  postLoading: false,
  allCommentsLoading: false,
  commentsLoading: false,
  commentsLoaded: false,
  allCommentsLoaded: false,
  sendingComment: false,
  commentSent: false,
  commentId: null,
  likeCommentLoading: false,
  likeCommentLoaded: false,
  deletedCommentId: null,
  toggleLikeCommentFail: null,
  openPostId: null,
  commentUpdate: {
    postId: null,
    totalCount: null
  },
  newLikeOnComment: null,
  newComment: null
};

export function postReducer(state = initialPostState, action: postActions.PostActions): PostState {

  switch (action.type) {

    case postActions.LOAD_POST: {
      return {
        ...state,
        postLoading: action.payload
      };
    }

    case postActions.OPEN_POST_SUCCESS:
    case postActions.LOAD_POST_SUCCESS: {
      return {
        ...state,
        postLoading: false,
        openPostId: action.payload
      };
    }

    case postActions.LOAD_POST_FAIL: {
      return {
        ...state,
        postLoading: false,
        openPostId: null
      };
    }

    case postActions.LOAD_LAST_COMMENTS: {
      return {
        ...state,
        commentsLoading: true,
        commentsLoaded: false
      };
    }

    case postActions.LOAD_LAST_COMMENTS_SUCCESS: {
      return {
        ...state,
        commentsLoading: false,
        commentsLoaded: true
      };
    }

    case postActions.LOAD_LAST_COMMENTS_FAIL: {
      return {
        ...state,
        commentsLoading: false,
        commentsLoaded: false
      };
    }

    case postActions.LOAD_ALL_COMMENTS: {
      return {
        ...state,
        allCommentsLoading: true
      };
    }

    case postActions.LOAD_ALL_COMMENTS_SUCCESS: {
      return {
        ...state,
        allCommentsLoading: false,
        commentsLoaded: true,
        allCommentsLoaded: true
      };
    }

    case postActions.LOAD_ALL_COMMENTS_FAIL: {
      return {
        ...state,
        allCommentsLoading: false,
        allCommentsLoaded: false
      };
    }

    case postActions.SAVE_COMMENT: {
      return {
        ...state,
        sendingComment: true,
        commentSent: false,
        newComment: null
      };
    }

    case postActions.RESET_COMMENT_UPDATE: {
      return {
        ...state,
        commentUpdate: {
          postId: null,
          totalCount: null
        }
      };
    }

    case postActions.SAVE_COMMENT_SUCCESS: {
      return {
        ...state,
        commentSent: true,
        sendingComment: false,
        commentUpdate : action.payload
      };
    }

    case postActions.DELETE_COMMENT_SUCCESS: {
      return {
        ...state,
        deletedCommentId: action.payload.deletedCommentId,
        commentUpdate : action.payload.commentUpdate
      };
    }

    case postActions.SAVE_COMMENT_FAIL: {
      return {
        ...state,
        sendingComment: false,
        commentSent: false
      };
    }

    case postActions.TOGGLE_LIKE_COMMENT: {
      return {
        ...state,
        commentId: action.payload,
        likeCommentLoading: true,
        likeCommentLoaded: false
      };
    }

    case postActions.TOGGLE_LIKE_COMMENT_SUCCESS: {
      return {
        ...state,
        likeCommentLoading: false,
        likeCommentLoaded: true
      };
    }

    case postActions.TOGGLE_LIKE_COMMENT_FAIL: {
      return {
        ...state,
        likeCommentLoading: false,
        likeCommentLoaded: false,
        toggleLikeCommentFail: action.payload
      };
    }

    case postActions.CLOSE_POST: {
      return {
        ...state,
        openPostId: null,
        newComment: null
      };
    }

    case postActions.RESET_LIKE_FAIL_COMMENT: {
      return {
        ...state,
        toggleLikeCommentFail: null
      };
    }

    case postActions.NOTIFY_COMMENT_LIKE: {
      return {
        ...state,
        commentId: action.payload.comment_id,
        newLikeOnComment: action.payload
      };
    }

    case postActions.RESET_NEW_COMMENT_LIKE: {
      return {
        ...state,
        newLikeOnComment: null
      };
    }

    case postActions.NOTIFY_NEW_COMMENT: {
      return {
        ...state,
        commentUpdate: {
          postId: action.payload.post_id,
          totalCount: action.payload.comment_count
        },
        newComment: null
      };
    }

    case postActions.LOAD_COMMENT: {
      return {
        ...state
      };
    }

    case postActions.LOAD_COMMENT_SUCCESS: {
      return {
        ...state,
        newComment: action.payload
      };
    }
  }

  return state;
}

export const postIsLoading = (state: PostState) => state.postLoading;
export const openPostId = (state: PostState) => state.openPostId;
export const commentId = (state: PostState) => state.commentId;
export const commentsAreLoading = (state: PostState) => state.commentsLoading;
export const commentsAreLoaded = (state: PostState) => state.commentsLoaded;
export const allCommentsAreLoading = (state: PostState) => state.allCommentsLoading;
export const allCommentsAreLoaded = (state: PostState) => state.allCommentsLoaded;
export const likeCommentLoading = (state: PostState) => state.likeCommentLoading;
export const commentIsSending = (state: PostState) => state.sendingComment;
export const commentIsSent = (state: PostState) => state.commentSent;
export const commentUpdate = (state: PostState) => state.commentUpdate;
export const deletedCommentId = (state: PostState) => state.deletedCommentId;
export const toggleLikeCommentFail = (state: PostState) => state.toggleLikeCommentFail;
export const newLikeOnComment = (state: PostState) => state.newLikeOnComment;
export const newComment = (state: PostState) => state.newComment;
export const commentFeedId = () => 'current-comment-feed';
