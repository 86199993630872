import { Injectable, NgZone } from '@angular/core';
import { ActionCableService } from './action-cable.service';
import { ClarityConfig } from '../../config/clarity.config';
import { Action, Store } from '@ngrx/store';
import { getCurrentProfileId } from '../../store/normalized/selectors/user-profile.selectors';
import { SessionState } from '../../store/session';
import * as notificationActions from '../../store/session/actions/notifications.actions';
import * as myCoachActions from '../../store/session/actions/my-coach.actions';
import { NotifyNewPosts, NotifyPostLike } from '../../store/session/actions/social.actions';
import { NotifyCommentLike, NotifyNewComment } from '../../store/session/actions/post.actions';
import { getResumedAt } from '../../store/session/selectors/sync.selectors';
import { filter, first, map, take } from 'rxjs/operators';
import { LoggerService } from '../logger.service';
import { getAuthToken } from 'src/app/store/sensitive/selectors/auth.selectors';

@Injectable({providedIn: 'root'})
export class CommunityActionCableService {

  private profileId: number;

  private profileChannel: any;
  private applicationChannel: any;

  constructor(
    private cableService: ActionCableService,
    private config: ClarityConfig,
    private store: Store<SessionState>,
    private logger: LoggerService,
    private zone: NgZone
  ) {
    this.setProfileId();
  }

  public async initActionCable() {
    if (!this.profileId) {
      /**
       * we don't need this.profileId anymore, but we're keeping this check
       * only to see if the legacy codeflow has any point of improvement
       */
      this.logger.warning(
        'community-action-cable',
        'calling initActionCable with empty profileId'
      );
    }

    const [token, profileId] = await Promise.all([this.getValidAuthToken(), this.getValidProfileId()]);

    const webSocketUrl = this.getWebSocketUrl(token);

    this.zone.runOutsideAngular(() => {
      this.profileChannel?.unsubscribe();
      this.applicationChannel?.unsubscribe();

      this.profileChannel = this.cableService.createChannel(
        {
          channel: 'ProfileChannel',
          profile_id: profileId
        },
        {
          received: message => {
            if (message.type === 'notification_created') {
              this.dispatchIfNotJustResumed(new notificationActions.LoadNotifications());
            }

            if (message.type === 'message_created') {
              this.dispatchIfNotJustResumed(new myCoachActions.NotifyNewMessage(message));
            }
          },
          disconnected: () => this.logger.warning('community-action-cable', 'action cable disconnected')
        }, webSocketUrl);

      this.applicationChannel = this.cableService.createChannel(
        {
          channel: 'ApplicationChannel'
        },
        {
          received: message => {
            if (message.profile_id === profileId) {
              // we're receiving message from the current user, so we just ignore it
              return;
            }

            if (message.type === 'post_created') {
              this.dispatchIfNotJustResumed(new NotifyNewPosts(message));
            }

            if (message.type === 'like_created') {
              if (typeof message.post_id !== 'undefined') {
                this.dispatchIfNotJustResumed(new NotifyPostLike(message));
              } else if (typeof message.comment_id !== 'undefined') {
                this.dispatchIfNotJustResumed(new NotifyCommentLike(message));
              }
            }

            if (message.type === 'comment_created') {
              this.dispatchIfNotJustResumed(new NotifyNewComment(message));
            }
          },
          disconnected: () => this.logger.warning('community-action-cable', 'action cable disconnected')
        }, webSocketUrl);
    });
  }

  private getWebSocketUrl(token: string): string {
    const {apiUseSsl, webSocketEndpoint, communityCableEndpoint} = this.config.env;

    return `${apiUseSsl ? 'wss://' : 'ws://'}${communityCableEndpoint}${webSocketEndpoint}?token=${token}`;
  }

  private dispatchIfNotJustResumed(action: Action) {
    this.store.select(getResumedAt)
      .pipe(first())
      .subscribe(resumedAt => {
        const currentTimestamp = new Date().getTime();

        if (!resumedAt || currentTimestamp - resumedAt > 1000) {
          this.store.dispatch(action);
        }
      });
  }

  private setProfileId() {
    this.store.select(getCurrentProfileId)
      .subscribe(id => {
        if (id) {
          this.profileId = parseInt(id, 10);
        }
      });
  }

  private getValidAuthToken(): Promise<string> {
    return this.store.select(getAuthToken)
      .pipe(
        filter(t => Boolean(t)), // wait for a valid token
        take(1)
      )
      .toPromise();
  }

  private getValidProfileId(): Promise<number> {
    return this.store.select(getCurrentProfileId)
      .pipe(
        filter(id => Boolean(id)), // wait for a valid profile id
        map((id) => parseInt(id, 10)),
        take(1)
      )
      .toPromise();
  }
}
