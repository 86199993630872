import { Injectable } from '@angular/core';

import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as userProgramSessionActions from '../actions/user-program-session.actions';
import * as programActions from '../actions/program.actions';
import * as syncActions from '../actions/sync.actions';
import * as fromNormalizedCore from '../../../../app/store';
import { ToastService } from '../../../../app/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { UserProgramSessionProvider } from '../../../../app/providers/user-program-session.provider';
import { LoadingService } from '../../../../app/services/loading.service';

@Injectable({providedIn: 'root'})
export class UserProgramSessionEffects {

  
  updateUserMantra$ = createEffect(() => this.actions$.pipe(ofType(userProgramSessionActions.UPDATE_USER_MANTRA),
    map((action: userProgramSessionActions.UpdateUserMantra) => action.payload),
    tap(() => {
      this.loadingService.showLoadingOverlay(this.translate.get('mantra.saving_mantra'));
    }),
    switchMap((userMantraUpdate) => this.userProgramSessionProvider.updateUserProgramSession(userMantraUpdate.data)
      .pipe(
        map(() => {
          this.normalizedStore.dispatch(new syncActions.SyncUserProgramSession());

          if (userMantraUpdate.lesson) {
            this.normalizedStore.dispatch(new programActions.CompleteLesson(userMantraUpdate.lesson));
          }

          return new userProgramSessionActions.UpdateUserMantraSuccess();
        }),
        catchError(() => of(new userProgramSessionActions.UpdateUserMantraFail()))
      ))
  ));

  
  updateUserMantraSuccess$ = createEffect(() => this.actions$.pipe(ofType(userProgramSessionActions.UPDATE_USER_MANTRA_SUCCESS),
    tap(() => {
      this.loadingService.hideLoadingOverlay();
    })
  ), {dispatch: false});

  
  updateUserMantraFail$ = createEffect(() => this.actions$.pipe(ofType(userProgramSessionActions.UPDATE_USER_MANTRA_FAIL),
    tap(() => {
      this.loadingService.hideLoadingOverlay();

      this.toastService.error(this.translate.get('errors.common.network_error'));
    })
  ), {dispatch: false});

  constructor(
    private actions$: Actions,
    private userProgramSessionProvider: UserProgramSessionProvider,
    private normalizedStore: Store<fromNormalizedCore.State>,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {
  }
}
