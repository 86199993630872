import { Injectable } from '@angular/core';
import { MinutesActivity } from '../store/normalized/schemas/minutes-activity.schema';
import { HttpProvider } from './http/http.provider';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class MinutesActivitiesProvider {
  private minutesActivitiesEndpoint = '/minutes_activities';
  private bulkMinutesActivitiesEndpoint = '/minutes_activities/bulk';

  constructor(
    private readonly http: HttpProvider
  ) {}

  loadMinutesActivities(): Observable<MinutesActivity[]> {
    return this.http.get<MinutesActivity[]>(this.minutesActivitiesEndpoint);
  }

  createMinutesActivity(minutesActivity: MinutesActivity): Observable<MinutesActivity[]> {
    return this.http.post(this.minutesActivitiesEndpoint, minutesActivity);
  }

  createBulkMinutesActivity(minutesActivities: MinutesActivity[]): Observable<MinutesActivity[]> {
    return this.http.post(this.bulkMinutesActivitiesEndpoint, {
      minutes_activities: minutesActivities
    });
  }

  deleteMinutesActivity(id: number): Observable<void> {
    return this.http.delete(`${this.minutesActivitiesEndpoint}/${id}`);
  }
}
