import { Injectable } from '@angular/core';
import { HttpProvider } from './http/http.provider';
import { UserProgramSession } from '../store/normalized/schemas/user.schema';

@Injectable({providedIn: 'root'})
export class UserProgramSessionProvider {

  private userProgramSessionEndpoint = '/user_program_session';

  constructor(
    protected http: HttpProvider
  ) {

  }

  updateUserProgramSession(payload) {
    return this.http.patch(this.userProgramSessionEndpoint, payload);
  }

  getUserProgramSession() {
    return this.http.get<UserProgramSession>(this.userProgramSessionEndpoint);
  }

  restartProgram() {
    return this.http.delete(this.userProgramSessionEndpoint);
  }

}
