import { Component } from '@angular/core';

import { NavController } from '@ionic/angular';

import { Store } from '@ngrx/store';

import * as navigationActions from '../../store/session/actions/navigation.actions';
import * as programActions from '../../store/session/actions/program.actions';
import { ClarityConfig } from '../../config/clarity.config';
import { State } from '../../store/state.reducer';

@Component({
  selector: 'page-restart-program',
  styleUrls: ['restart-program.scss'],
  templateUrl: 'restart-program.html'
})
export class RestartProgramPage {
  modal;

  constructor(
    public store: Store<State>,
    public navCtrl: NavController,
    public config: ClarityConfig
  ) {
  }

  get isModal() {
    return true;
    // return this.modalCtrl.isOverlay;
  }

  onBack() {
    if (this.isModal) {
      this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
    }
    else {
      this.navCtrl.pop();
    }
  }

  onNext() {
    this.store.dispatch(new programActions.RestartProgram());
  }
}
