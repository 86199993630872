import {ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { CloseLastModal } from '../../store/session/actions/navigation.actions';
import { Store } from '@ngrx/store';
import { State } from '../../store';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { ConnectivityService } from '../../services/connectivity.service';
import { AlertsService } from '../../services/alerts.service';
import * as authActions from '../../store/sensitive/actions/auth.actions';
import { LoadingService } from '../../services/loading.service';
import { isDeletingAccount } from '../../store/sensitive/selectors/auth.selectors';
import { IonContent, Platform } from '@ionic/angular';
import { ClarityConfig } from '../../config/clarity.config';

export enum DeleteAccountReasons {
  do_not_want_use_anymore = 'do_not_want_use_anymore',
  learned_everything_i_need = 'learned_everything_i_need',
  program_not_effective = 'program_not_effective',
  something_else = 'something_else'
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'page-account-deletion-confirmation',
  styleUrls: ['account-deletion-confirmation.scss'],
  templateUrl: 'account-deletion-confirmation.html'
})
export class AccountDeletionConfirmationPage implements OnInit, OnDestroy {
  form: FormGroup;

  private readonly destroyed$ = new Subject<void>();
  private deletingAccount$: Observable<boolean> = this.store.select(isDeletingAccount);

  isSlideScrolledToBottom$ = new BehaviorSubject(false);
  @ViewChild('scrolledToBottomObserver', { read: ElementRef, static: false })
  scrolledToBottomObserver: ElementRef<HTMLElement>;
  observer: IntersectionObserver;

  @ViewChild('ionContent', { read: ElementRef, static: true })
  public ionContent: ElementRef<IonContent>;

  constructor(
    private store: Store<State>,
    private formBuilder: FormBuilder,
    private connectivityService: ConnectivityService,
    private alertsService: AlertsService,
    private loadingService: LoadingService,
    public config: ClarityConfig,
    public platform: Platform
  ) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      reason_not_used: new FormControl(false, []),
      reason_learned: new FormControl(false, []),
      reason_not_effective: new FormControl(false, []),
      reason_other: new FormControl(false, []),
      other_reason: new FormControl('', [this.textareaConditionalValidator])
    }, {
      validators: this.requireOneControl()
    });

    this.form.get('reason_other').valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.form.get('other_reason').updateValueAndValidity();
      });
  }

  ionViewWillEnter() {
    this.addScrollInputIntoViewListener();
  }

  ionViewWillLeave() {
    this.removeScrollInputIntoViewListener();

  }

  private addScrollInputIntoViewListener() {
    window.addEventListener('ionKeyboardDidShow', this.scrollDownWhenKeyboardAppears.bind(this));
  }

  private removeScrollInputIntoViewListener() {
    window.removeEventListener('ionKeyboardDidShow', this.scrollDownWhenKeyboardAppears.bind(this));
  }

  private scrollDownWhenKeyboardAppears() {
    setTimeout(() => {
      document.activeElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }, 100);
  };

  ionViewDidEnter() {
    this.observer = new IntersectionObserver(entries => {
      console.log(!entries[0].isIntersecting);
      this.isSlideScrolledToBottom$.next(!entries[0].isIntersecting);
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0
    });
    setTimeout(() => this.observer.observe(this.scrolledToBottomObserver.nativeElement), 300);
  }

  requireOneControl() {
    return formGroup => {
      if (formGroup.get('reason_not_used').value === false &&
        formGroup.get('reason_learned').value === false &&
        formGroup.get('reason_not_effective').value === false &&
        formGroup.get('reason_other').value === false) {
        return { required: true };
      }

      return null;
    };
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  closeModal() {
    this.store.dispatch(new CloseLastModal());
  }

  deleteAccount() {
    if(this.form.invalid) {
      return;
    }

    // prevent offline access
    if(this.connectivityService.isOffline()) {
      return this.alertsService.offlineNotification();
    }

    this.loadingService.useLoadingObservable(this.deletingAccount$);

    const reasons = [];
    this.form.get('reason_not_used').value && reasons.unshift(DeleteAccountReasons.do_not_want_use_anymore);
    this.form.get('reason_learned').value && reasons.unshift(DeleteAccountReasons.learned_everything_i_need);
    this.form.get('reason_not_effective').value && reasons.unshift(DeleteAccountReasons.program_not_effective);
    this.form.get('reason_other').value && reasons.unshift(this.form.get('other_reason').value);

    this.store.dispatch(new authActions.DeleteAccount(reasons));
  }

  private textareaConditionalValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get('reason_other').value === true) {
      return Validators.required(formControl);
    }

    return null;
  }
}
