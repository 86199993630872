import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { IonContent, AlertController, NavController } from '@ionic/angular';

import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import {
  LiveModule,
  LiveWeek,
  getCoreTrainingWeeks
} from '../../store/session/selectors/program.selectors';
import { Observable, combineLatest } from 'rxjs';
import { ClarityConfig } from '../../config/clarity.config';
import { TranslateService } from '@ngx-translate/core';
import { State } from '../../store/state.reducer';
import * as navigationActions from '../../store/session/actions/navigation.actions';
import * as programActions from '../../store/session/actions/program.actions';

@Component({
  selector: 'page-custom-path',
  styleUrls: ['custom-path.scss'],
  templateUrl: 'custom-path.html'
})
export class CustomPathPage {
  modal;

  @ViewChild(IonContent, { static: true }) content: any;

  public programWeeks$: Observable<LiveWeek[]> = this.store.select(getCoreTrainingWeeks);

  selectedModules = [];
  maxModules = this.config.isCTQ() ? 7 : 6;
  isSelected = false;

  showIntroQuestion = true;

  constructor(
    public store: Store<State>,
    public navCtrl: NavController,
    private alertCtrl: AlertController,
    private config: ClarityConfig,
    private translate: TranslateService,
    private ref: ChangeDetectorRef
  ) {
  }

  get isModal() {
    return true;
  }

  get title() {
    return this.showIntroQuestion ? '' : 'custom_path.custom_path';
  }

  onBack() {
    if (this.isModal) {
      this.store.dispatch(new navigationActions.CloseModal({modalId: this.modal.id}));
    }
    else {
      this.navCtrl.pop();
    }
  }

  onContinue() {
    this.showIntroQuestion = false;
    this.ref.detectChanges();
  }

  onNext() {
    // TODO: fix scrollToTop()
    // this.content.scrollToTop();
    this.isSelected = true;
    this.ref.detectChanges();
  }

  canNext() {
    return !(this.selectedModules.length < this.maxModules);
  }

  reselectModules() {
    // TODO: fix scrollToTop()
    // this.content.scrollToTop();
    this.isSelected = false;
    this.ref.detectChanges();
  }

  selectModule(module: LiveModule) {
    const position = this.selectedModules.indexOf(module);

    if (position > -1) {
      this.selectedModules.splice(position, 1);
    }
    else {
      if (this.selectedModules.length < this.maxModules) {
        this.selectedModules.push(module);
      }
      else {
        combineLatest([
          this.translate.get('custom_path.you_can_only_select',
            {maxModules: this.maxModules}),
          this.translate.get('custom_path.ok'),
          this.translate.get('custom_path.custom_path')
        ])
          .pipe(take(1))
          .subscribe(async ([text, button, title]) => {
            const alert = await this.alertCtrl.create({
              header: title, subHeader: text, buttons: [button]
            });

            await alert.present();
          });
      }
    }

    this.selectedModules = [].concat(this.selectedModules);

    // TODO: fix scrollTo()
    // workaround to fix issue on iOS - for some reason screen is not updated until scrolling
    // this.content.scrollTo(0, this.content.getScrollElement().scrollTop + 1);
    // this.content.scrollTo(0, this.content.getScrollElement().scrollTop - 1);
    this.ref.detectChanges();
  }

  startCustomWeek(week: programActions.CustomWeek) {
    this.store.dispatch(new programActions.StartCustomWeek(week));
  }
}
