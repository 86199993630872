import { Injectable, OnDestroy } from '@angular/core';
import { ClarityConfig } from 'src/app/config/clarity.config';
import { LoggerService } from 'src/app/services/logger.service';
import { IridiumProvider } from '../iridium/iridium.provider';
import { getIridiumHost, getIridiumToken } from 'src/app/store/sensitive/selectors/auth.selectors';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store';
import { combineLatest, Subscription, zip } from 'rxjs';
import { IridiumWebsocketService } from '../iridium/iridium-websocket.service';
import { filter, take } from 'rxjs/operators';
import * as iridiumActions from 'src/app/store/session/actions/iridium.actions';
import * as syncActions from 'src/app/store/session/actions/sync.actions';
import { isIridiumActivated } from 'src/app/store/session/selectors/iridium.selectors';
import { isFullSynced } from 'src/app/store/session/selectors/sync.selectors';
import { MarketingAnalyticsInterface, UserAnalyticsInterface } from '../analytics.interface';

@Injectable({providedIn: 'root'})
export class IridiumService implements OnDestroy, MarketingAnalyticsInterface, UserAnalyticsInterface {

  public SERVICE_NAME = 'iridium';

  private activeSubscription: Subscription;
  private iridiumActivated$ = this.store.select(isIridiumActivated);
  private iridiumToken$ = this.store.select(getIridiumToken);
  private iridiumHost$ = this.store.select(getIridiumHost);

  private resolveRegistration: any;
  private rejectRegistration: any;

  private registrationPromise: Promise<any> = new Promise((resolve, reject) => {
    this.resolveRegistration = resolve;
    this.rejectRegistration = reject;
  });

  private registrationTriggered = false;
  private currentUserData: any;

  constructor(
    private config: ClarityConfig,
    private logger: LoggerService,
    private store: Store<State>,
    private iridiumProvider: IridiumProvider,
    private websocketService: IridiumWebsocketService
  ) {

  }

  public initialize(): Promise<any> {
    this.activeSubscription = this.iridiumActivated$
      .pipe(filter((activated) => activated))
      .subscribe(() => {
        // display inapp messages
        this.store.dispatch(new iridiumActions.LoadInAppMessages());

        // connect websocket
        zip(this.iridiumToken$, this.iridiumHost$)
          .pipe(take(1))
          .toPromise()
          .then(([token, host]) => {
            if (!token || !host) {
              // older logins do not have a token or host, request them
              this.store.dispatch(new syncActions.SyncIridiumToken());
            }

            this.websocketService.connect(token);
          });
      });

    return Promise.resolve();
  }

  ngOnDestroy() {
    this.activeSubscription && this.activeSubscription.unsubscribe();
  }

  public resetService(): Promise<void> {
    this.currentUserData = null;
    this.registrationTriggered = false;

    this.websocketService.disconnect();

    this.store.dispatch(new iridiumActions.Deactivate());

    return Promise.resolve();
  }

  public registerUser(data): Promise<any> {
    this.currentUserData = data;

    // we should only register once no matter how many times this is called
    if (!this.registrationTriggered) {
      this.registrationTriggered = true;

      // Iridium should only be activated once per login
      this.iridiumActivated$.pipe(take(1))
        .toPromise()
        .then((activated) => {
          console.log('Iridium - Status', activated);

          if (activated) {
            return this.resolveRegistration();
          }

          this.updateUser(data)
            .then(() => this.store.dispatch(new iridiumActions.Activate()))
            .then(() => this.resolveRegistration());
        });
    }

    return this.registrationPromise;
  }

  public async updateUser(data): Promise<any> {
    return this.iridiumReady()
      .then(() => this._updateUser(data));
  }

  public trackSubscription(eventName: string, data = {}, product = {}) {
    return this.trackEvent(eventName, data);
  }

  public trackEvent(eventName, data = {}) {
    this.logger.debug('Iridium tracking event', eventName);

    try {
      return this.iridiumActive()
        .then(() => this.iridiumProvider.logEvent(eventName, data)
          .toPromise()
          .catch((error) => this.logger.error('Cannot track event to Iridium', error, this.constructor.name)));
    } catch (error) {
      this.logger.error('Error caught tracking event to Iridium', error, this.constructor.name);

      return Promise.resolve(false);
    }
  }

  private async _updateUser(data): Promise<any> {
    const customAttributes: any[] = [
      {
        key: 'gender',
        value: data.user.gender
      },
      {
        key: 'age',
        value: data.user.age
      }
    ];

    if (this.config.programERN()) {
      customAttributes.push(...[
        {
          key: 'weight',
          value: Number(data.user.weight) || null
        },
        {
          key: 'weight_unit',
          value: data.user.weight_unit || null
        }
      ]);
    }

    if (this.config.programDPPorWL()) {
      customAttributes.push(...[
        {
          key: 'dpp_wl_type',
          value: data.user.dpp_wl_type
        }
      ]);
    }

    if (this.config.isCTQ()) {
      customAttributes.push(...[
        {
          key: 'ctq_start_date',
          value: data.user.start_date || null
        },
        {
          key: 'ctq_quit_date',
          value: data.user.end_date || null
        },
        {
          key: 'cigs_per_day',
          value: Number(data.user.cigs_per_day) || null
        },
        {
          key: 'cig_pack_cost',
          value: Number(data.user.cig_pack_cost) || null
        }
      ]);
    }

    const userAttributes: any = {
      email: data.user.email,
      firstName: data.user.first_name,
      lastName: data.user.last_name,
      customAttributes: customAttributes.map(attribute => ({
        ...attribute,
        programCode: this.config.currentProgramCode
      })),
      programs: [{code: this.config.currentProgramCode}]
    };

    this.logger.debug('Updating Iridium user');

    return this.iridiumProvider.updateUser(userAttributes, data.user.user_id)
      .toPromise();
  }

  // resolves when Iridium is actually registered and active for tracking
  private iridiumActive() {
    return this.iridiumActivated$.pipe(
      filter((activated) => activated),
      take(1)
    )
      .toPromise();
  }

  // resolves when we can contact Iridium
  private iridiumReady() {
    return combineLatest([
      this.store.select(isFullSynced)
        .pipe(filter(synced => synced)),
      this.iridiumToken$
        .pipe(filter(token => !!token))
    ])
      .pipe(take(1))
      .toPromise();
  }
}
