<ion-content>
  <ion-header>
    <cl-header-nav-buttons
      color="blank"
      [autoClose]="true"
      [backButton]="!isModal"
      (back)="isSelected ? reselectModules() : onBack()"
      class="page-header"
    ></cl-header-nav-buttons>
  </ion-header>

  <div class="page-background"></div>

  <div class="page-wrapper lateral-padding">
    <h2 class="title" *ngIf="!isSelected">{{'custom_path.create_your_custom_path' | translate}}</h2>
    <h2 class="title" *ngIf="isSelected">{{'custom_path.reorder_the_modules' | translate}}</h2>

    <div *ngIf="!isSelected">
      <h4 class="subtitle-custom-path">{{"custom_path.select_modules_that_you" | translate:{maxModules: maxModules} }}</h4>

      <div class="weeks-wrapper">
        <cl-program-weeks
          scope="create_custom_path"
          [weeks]="programWeeks$ | async"
          [selectedModules]="selectedModules"
          (playModule)="selectModule($event)">
        </cl-program-weeks>

        <ion-row class="action-buttons-holder" [class.sticky]="canNext()">
          <ion-col class="ion-text-center" auto>
            <cl-action-button
              label="{{'common.next' | translate}}"
              [canClick]="canNext()"
              (action)="onNext()">
            </cl-action-button>
          </ion-col>
        </ion-row>

      </div>
    </div>

    <cl-custom-path-drag *ngIf="isSelected" [modules]="selectedModules" (startedCustomWeek)="startCustomWeek($event)"></cl-custom-path-drag>
  </div>

  <div class="waves-bg"></div>

  <div class="bottom-bg-background"></div>
</ion-content>
