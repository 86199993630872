import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import SwiperCore, { EffectFade, Swiper } from 'swiper';
SwiperCore.use([ EffectFade ]);
/**
 * This is a helper class to easily manipulate slides steps that needs validation.
 *
 * WARNING: if you define ngAfterViewInit() on your child class, don't forget to call super.ngAfterViewInit()
 *
 * To use it, simply extend this class, add your `<swiper>` to the html template,
 * implement the methods `isStepValid()`, `slideFinished()`, and done, you can call the `tryNavigateToNext()`
 *
 * Example:
 *
 * ```
 * Component({
 *   template: `<swiper #swiper>
 *     <ng-template swiperSlide>slide 1</ng-template>
 *     <ng-template swiperSlide>slide 2</ng-template>
 *     <ion-button (click)="tryNavigateNext()">next</ion-button>
 *   </swiper>`
 * })
 * class MySlideComponent extends StepValidationSlideComponent {
 *   async isStepValid(step: number) {
 *     return true; // will make navigation works at every step
 *   }
 *
 *   async slideFinished() {
 *     console.log('slide finished!!!!');
 *  }
 * }
 *```
 */
@Component({
  selector: 'cl-step-validation-slide',
  template: ''
})
export class StepValidationSlideComponent implements AfterViewInit {
  @ViewChild('swiper', { read: ElementRef })
  swiperRef: ElementRef<{ swiper: Swiper }>;

  get swiper() {
    return this.swiperRef?.nativeElement?.swiper;
  }

  // every <section id="..."> inside a slide, will be put here with the correct index.
  // can be used to navigate, like `this.swiper.slideTo(this.namedSlides.get('my-slide').index)`
  private namedSlides: Map<string, { index: number }> = new Map();

  swiperInit$ = new Subject<void>();

  selectedSlideNumber$ = new BehaviorSubject<number>(0);
  selectedSlideElement$ = this.selectedSlideNumber$.pipe(
    map((number) => this.swiper?.slides[number])
  );

  ngAfterViewInit() {
    if (!this.swiper) {
      throw new Error('[StepValidationSlideComponent] element with #swiper directive not found on html. Please define it');
    }

    this.swiperInit$.next();

    // @todo sometimes this gets called before swiper.slides gets defined.
    // and swiper.on('init') is not working. Try a way to fix that
    this.defineNamedSlides();

    this.swiper.on('slideChangeTransitionStart', () => {
      this.selectedSlideNumber$.next(this.swiper.activeIndex);
    });
  }

  getSlideName(index = this.swiper.activeIndex) {
    return (this.swiper?.slides[index])?.querySelector('section').id;
  }

  private defineNamedSlides() {
    this.swiper.slides.forEach((slide, index) => {
      const namedSection = slide.querySelector('section[id]');
      if (namedSection) {
        this.namedSlides.set(namedSection.id, { index });
      }
    });
  }

  navigateToNamedSlide(name: string, retried = false) {
    const slide = this.namedSlides.get(name);

    if (!slide) {
      // need to retry to fix the hook issue on swiper init
      if (!retried) {
        this.defineNamedSlides();

        return this.navigateToNamedSlide(name, true);
      }

      console.error(`trying to navigate to a non-existend named slide. Name: "${name}"`);

      return;
    }

    this.swiper.allowSlideNext = true;
    this.swiper.slideTo(slide.index);
    this.swiper.allowSlideNext = false;
  }

  public async tryNavigateToNext(skip = false) {
    if (
      skip ||
      this.isCurrentStepValid()
    ) {
      return this.navigateToNext();
    }
  }

  public async tryNavigateToPrevious(skip = false) {
    return this.navigateToPrevious();
  }

  private isCurrentStepValid() {
    const index = this.swiper.activeIndex;

    return this.isStepValid(index);
  }

  private async navigateToNext() {
    if (this.swiper.isEnd) {
      return this.slideFinished();
    }

    // unlock, slide, lock again.
    this.swiper.allowSlideNext = true;
    this.swiper.slideNext();
    this.swiper.allowSlideNext = false;
  }

  private async navigateToPrevious() {
    // unlock, slide, lock again.
    this.swiper.allowSlidePrev = true;
    this.swiper.slidePrev();
    this.swiper.allowSlidePrev = false;
  }

  public isStepValid(step: number) {
    // implement this in child class
    throw new Error('[StepValidationSlideComponent] isStepValid is not implemented. Please implement it in the child class');
  }

  public slideFinished() {
    // implement this in child class
    throw new Error('[StepValidationSlideComponent] slideFinished is not implemented. Please implement it in the child class');
  }
}
