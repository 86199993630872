import { Injectable, EventEmitter } from '@angular/core';
import { isPlatform, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { take, filter, switchMap } from 'rxjs/operators';
import { getMobileAppPopupLastSeenAt } from '../store/persistent/timestamps/timestamps.selectors';
import { State } from '../store/state.reducer';
import { SetTimestamp } from '../store/persistent/timestamps/timestamps.actions';
import { differenceInHours } from 'date-fns';
import { NavigationEnd, Router } from '@angular/router';
import { ActionDialogComponent } from 'src/app/components/action-dialog/action-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ClarityConfig } from '../config/clarity.config';
import { BrowserService } from '../services/browser.service';
import { AnalyticsEvents } from 'src/app/services/analytics/analytics.events';
import { AnalyticsService } from './analytics/analytics.service';

const POPUP_DISPLAY_TIMEOUT_DURATION_IN_HOURS = 24;

@Injectable({providedIn: 'root'})
export class GetMobileAppService {
  constructor(
    private store: Store<State>,
    private router: Router,
    private modalCtrl: ModalController,
    private translateService: TranslateService,
    private config: ClarityConfig,
    private browser: BrowserService,
    private analyticsService: AnalyticsService
  ) {}

  public initialize(): Promise<any> {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap(() => this.store.select(getMobileAppPopupLastSeenAt).pipe(take(1)))
    ).subscribe((popupLastSeenAt: Date) => {
      const hoursSincePopupLastSeen = differenceInHours(new Date(), new Date(popupLastSeenAt));
      if (popupLastSeenAt && (hoursSincePopupLastSeen < POPUP_DISPLAY_TIMEOUT_DURATION_IN_HOURS)) {
        console.log('GetMobileAppPopup will be shown again in :', POPUP_DISPLAY_TIMEOUT_DURATION_IN_HOURS - hoursSincePopupLastSeen, 'hours');

        return;
      } else {
        this.translateService.get([
          'get_mobile_app_popup.header',
          'get_mobile_app_popup.body',
          'get_mobile_app_popup.actionButtonText',
          'get_mobile_app_popup.cancelButtonText'
        ],
        {
          programName: this.config.program.name
        })
          .pipe(take(1))
          .toPromise()
          .then(async (translations) => {
            const mainAction = new EventEmitter();
            const dismiss = new EventEmitter();

            const modal = await this.modalCtrl.create({
              component: ActionDialogComponent,
              componentProps: {
                mainAction,
                dismiss,
                content: {
                  header: translations['get_mobile_app_popup.header'],
                  body: translations['get_mobile_app_popup.body'],
                  actionButton: {
                    label: translations['get_mobile_app_popup.actionButtonText'],
                    type: 'button',
                    size: 'small'
                  },
                  cancelButton: {
                    label: translations['get_mobile_app_popup.cancelButtonText'],
                    type: 'button',
                    size: 'small'
                  }
                },
                settings: {
                  textAlignment: 'center',
                  anchor: 'top'
                }
              },
              cssClass: 'get-mobile-app-popup'
            });

            mainAction.subscribe(() => {
              modal.dismiss();
              this.analyticsService.trackEvent(AnalyticsEvents.AcceptedGetMobileAppPopup);
              isPlatform('ios') ? this.browser.goTo(this.config.program.appStoreUrl) : this.browser.goTo(this.config.program.googlePlayUrl);
            });

            dismiss.subscribe(() => {
              modal.dismiss();
              this.analyticsService.trackEvent(AnalyticsEvents.DismissedGetMobileAppPopup);
            });

            this.store.dispatch(new SetTimestamp('getMobileAppPopupLastSeenAt', new Date()));
            modal.present();
          });
      }
    });

    return Promise.resolve(true);
  }
}
