// connectionHandler actions

import { Action } from '@ngrx/store';
import { PostponedChange } from '../../../providers/http/connection-handler.provider';

export const QUEUE_ACTION = '[connectionHandler] Queue Action';
export const CONNECTION_HANDLER_SEQ_HANDLER_START = '[connectionHandler] Seq Start';
export const CONNECTION_HANDLER_STOP = '[connectionHandler] Stop';
export const CONNECTION_HANDLER_PROCESS_ACTION = '[connectionHandler] Process Action';
export const CONNECTION_HANDLER_PROCESS_ACTION_FAIL = '[connectionHandler] Process Action Fail';
export const CONNECTION_HANDLER_PROCESS_ACTION_COMPLETE = '[connectionHandler] Process Action Complete';
export const CONNECTION_HANDLER_SKIP_ACTION = '[connectionHandler] Action Skip';
export const CONNECTION_HANDLER_QUEUE_CLEARED = '[connectionHandler] Seq Queue Cleared';

export const CONNECTION_HANDLER_NON_SEQ_HANDLER_START = '[connectionHandler] Non Seq Start';
export const CONNECTION_HANDLER_PROCESS_NON_SEQ_ACTIONS = '[connectionHandler] Process Non Seq Actions';
export const CONNECTION_HANDLER_PROCESS_NON_SEQ_ACTIONS_COMPLETE = '[connectionHandler] Process Non Seq Actions Complete';
export const CONNECTION_HANDLER_NON_SEQ_ACTIONS_CLEARED = '[connectionHandler] Non Seq Actions Cleared';

export interface SuccessPayload {
  initialId?: number | string;
  finalId?: number | string;
}

export interface AppliedActionPayload {
  action: PostponedChange;
  idDiff: SuccessPayload;
}

export interface NonSeqActionResult {
  action: PostponedChange;
  failed: boolean;
}

export class QueueAction implements Action {
  readonly type = QUEUE_ACTION;

  constructor(public payload: PostponedChange) {
  }
}

export class ConnectionHandlerSeqActionsStart implements Action {
  readonly type = CONNECTION_HANDLER_SEQ_HANDLER_START;
}

export class ConnectionHandlerNonSeqActionsStart implements Action {
  readonly type = CONNECTION_HANDLER_NON_SEQ_HANDLER_START;
}

export class ConnectionHandlerStop implements Action {
  readonly type = CONNECTION_HANDLER_STOP;
}

export class ConnectionHandlerProcessAction implements Action {
  readonly type = CONNECTION_HANDLER_PROCESS_ACTION;
}

export class ConnectionHandlerProcessActionFail implements Action {
  readonly type = CONNECTION_HANDLER_PROCESS_ACTION_FAIL;
}

export class ConnectionHandlerProcessActionComplete implements Action {
  readonly type = CONNECTION_HANDLER_PROCESS_ACTION_COMPLETE;

  constructor(public payload: AppliedActionPayload) {}
}

export class ConnectionHandlerProcessNonSeqActions implements Action {
  readonly type = CONNECTION_HANDLER_PROCESS_NON_SEQ_ACTIONS;
}

export class ConnectionHandlerProcessNonSeqActionsComplete implements Action {
  readonly type = CONNECTION_HANDLER_PROCESS_NON_SEQ_ACTIONS_COMPLETE;

  constructor(public payload: NonSeqActionResult[]) {}
}

export class ConnectionHandlerSkipAction implements Action {
  readonly type = CONNECTION_HANDLER_SKIP_ACTION;
  constructor(public payload: any) {}
}

export class ConnectionHandlerQueueCleared implements Action {
  readonly type = CONNECTION_HANDLER_QUEUE_CLEARED;
}

export class ConnectionHandlerNonSeqRequestsCleared implements Action {
  readonly type = CONNECTION_HANDLER_NON_SEQ_ACTIONS_CLEARED;
}

export type ConnectionHandlerActions =
  | QueueAction
  | ConnectionHandlerSeqActionsStart
  | ConnectionHandlerNonSeqActionsStart
  | ConnectionHandlerStop
  | ConnectionHandlerProcessAction
  | ConnectionHandlerProcessActionFail
  | ConnectionHandlerProcessActionComplete
  | ConnectionHandlerSkipAction
  | ConnectionHandlerQueueCleared
  | ConnectionHandlerProcessNonSeqActions
  | ConnectionHandlerProcessNonSeqActionsComplete;
