/* eslint-disable no-unused-expressions, @typescript-eslint/no-unused-expressions, no-shadow, @typescript-eslint/no-shadow */
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { enableProdMode } from '@angular/core';

declare const config: any; // clarity.constants.js

export function main(appModule) {
  if (config.environment.environment === 'prod') {
    // Enable in prod
    enableProdMode();
  }

  platformBrowserDynamic()
    .bootstrapModule(appModule, [])
    .then(async (moduleRef) => {
      if (config.environment.environment !== 'prod') {
        const { addDeveloperTools } = await import('./developer-tools');
        await addDeveloperTools(moduleRef);
      }
    })
    .catch(error => {
      console.error(error);
    });
};
