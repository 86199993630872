import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'cl-feeling-now-selector',
  styleUrls: ['feeling-now-selector.component.scss'],
  template: `
    <div class="slider-wrapper">

      <ion-grid>
        <ion-row class="slider-options">
          <ion-col [class.selected]="sliderValue < 20">{{ worseLabel | translate}}</ion-col>
          <ion-col [class.selected]="sliderValue >= 20 && sliderValue < 50">{{ sameLabel | translate}}</ion-col>
          <ion-col [class.selected]="sliderValue >= 50 && sliderValue < 80">{{ littleBetterLabel | translate}}</ion-col>
          <ion-col [class.selected]="sliderValue >= 80">{{ muchBetterLabel | translate}}</ion-col>
        </ion-row>
      </ion-grid>

      <cl-horizontal-slider
        [value]="sliderValue"
        (slide)="onSlide($event)">
      </cl-horizontal-slider>
    </div>
  `
})
export class FeelingNowSelectorComponent {

  @Input() sliderValue = null;
  @Input() worseLabel = 'wizards.worse';
  @Input() sameLabel = 'wizards.same';
  @Input() littleBetterLabel = 'wizards.little_better';
  @Input() muchBetterLabel = 'wizards.much_better';

  @Output() feel = new EventEmitter();

  onSlide(value) {
    this.sliderValue = value;
    this.feel.emit(this.sliderValue);
  }
}
