import { Directive, ElementRef, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Directive({
  selector: '[clTooltip]'
})
export class TooltipDirective {

  @Input() preventPropagation = true;
  @Input() tooltipText: string;

  constructor(
    public element: ElementRef,
    private alertController: AlertController
  ) {
    element.nativeElement.addEventListener('click', (event) => {
      if (this.preventPropagation) {
        event.stopPropagation();
      }

      this.showTooltip();
    });
  }

  async showTooltip() {
    const alert = await this.alertController.create({
      message: this.tooltipText,
      buttons: ['OK']
    });

    await alert.present();
  }

}
