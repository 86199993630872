import { schema } from 'normalizr';

export class Subtitle {
  id: number;
  data?: string;
  file_path?: string;
  language_code: string;
}

export class AudioSubtitle {
  cues: AudioCue[];
  errors: AudioSubtitleError[];
  time: number;
}

export class AudioSubtitleError {
  col: number;
  line: number;
  message: string;
}

export class AudioCue {
  id: string;
  startTime: number;
  endTime: number;
  pauseOnExit: boolean;
  direction: string;
  snapToLines: boolean;
  linePosition: string;
  lineAlign: string;
  textPosition: string;
  positionAlign: string;
  size: number;
  alignment: string;
  text: string;
}

export class MediaFile {
  jw_key?: string;
  brightcove_key?: string;
  brightcove_poster_url?: string;
  data_fingerprint?: string;
  data_file_name: string;
  data_content_type: string;
  data_file_size: number;
  data: string;
  subtitle?: Subtitle;
  length: number;
}

export const subtitleSchema = new schema.Entity<Subtitle>(
  'subtitles'
);

export const mediaFileSchema = new schema.Entity<MediaFile>(
  'mediaFiles',
  {
    subtitle: subtitleSchema
  },
  {
    idAttribute: (mediaFile) => mediaFile.jw_key || mediaFile.data_fingerprint || mediaFile.brightcove_key
  }
);
