import { ClarityConfig } from '../../config/clarity.config';
import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import ActionCable from 'actioncable-headers-extension';
import { SessionState } from '../../store/session/session.reducers';
import { getAuthToken } from '../../store/sensitive/selectors/auth.selectors';
import * as syncActions from '../../store/session/actions/sync.actions';
import * as socialActions from '../../store/session/actions/social.actions';
import { State } from '../../store/state.reducer';
import { EventsService } from '../events.service';
import { Consumer } from './action-cable.interface';
import { LoggerService } from '../logger.service';

export interface ChannelDefinition {
  channel: string;
  [key: string]: any;
}

@Injectable({providedIn: 'root'})
export class ActionCableService {
  private consumers: Map<string, Consumer> = new Map();
  private webSocketUrl: string;
  private token: string;

  constructor(
    private sessionStore: Store<SessionState>,
    public config: ClarityConfig,
    private events: EventsService,
    public store: Store<State>,
    private logger: LoggerService,
    private zone: NgZone
  ) {
  }

  initialize() {
    return Promise.resolve()
      .then(this.setToken.bind(this))
      .then(this.setWebSocketUrl.bind(this))
      .then(() => {
        // disconnect cable on logout and on network events
        this.events.subscribe(
          this.config.events.logout,
          () => this.disconnectCable()
        );

        this.events.subscribe(
          this.config.events.connection + 'offline',
          () => this.disconnectCable()
        );

        this.events.subscribe(
          this.config.events.connection + 'online',
          () => {
            if (this.token) {
              this.store.dispatch(new syncActions.InitActionCable());

              if (this.config.isCTQ()) {
                this.store.dispatch(new socialActions.InitActionCable());
              }
            }
          }
        );
      });
  }

  createChannel(identifier: ChannelDefinition, callbacks: Record<string, Function>, webSocketUrl = this.webSocketUrl) {
    let consumer = this.consumers.get(webSocketUrl);

    if (!consumer) {
      console.log('Creating action cable consumer...');

      if (!this.token) {
        this.logger.error('action-cable', 'starting actioncable with empty token');
      }

      consumer = ActionCable.createConsumer(webSocketUrl, this.token, this.zone);
      this.consumers.set(webSocketUrl, consumer);
    }

    // check if we already have a subscription for this identifier
    if (consumer?.subscriptions?.subscriptions?.length > 0) {
      const jsonIdentifier = JSON.stringify(identifier);
      const subscription = consumer.subscriptions.subscriptions
        .find((subs) => jsonIdentifier === subs.identifier);

      if (subscription) {
        this.logger.warning('action-cable', 'starting action-cable subscription with repeated identifier', 'action-cable', { jsonIdentifier, webSocketUrl });

        return subscription;
      }
    }

    return consumer.subscriptions.create(identifier, callbacks);
  }

  private setWebSocketUrl() {
    const { apiUseSsl, webSocketEndpoint } = this.config.env;

    this.webSocketUrl = `${apiUseSsl ? 'wss://' : 'ws://'}${this.config.apiHost}${webSocketEndpoint}`;

    return Promise.resolve();
  }

  private setToken() {
    this.sessionStore.select(getAuthToken)
      .subscribe(token => this.token = token);

    return Promise.resolve();
  }

  private disconnectCable() {
    if (!this.consumers || this.consumers.size === 0) {
      return true;
    }

    // disconnect all consumers
    this.consumers.forEach(consumer => this.disconnectConsumer(consumer));

    this.consumers.clear();
  }

  private disconnectConsumer(consumer: Consumer) {
    consumer.subscriptions.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    consumer.disconnect();
  }
}
