import { Action } from '@ngrx/store';

// tabs actions

export const TABS_OPENED = '[tabs] Tabs Opened';

export class TabsOpened implements Action {
  readonly type = TABS_OPENED;
}

export type TabsActions = TabsOpened;
